import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import {
  BloodTestResult,
  BloodTestResultItem,
  TestAbnormalFlag,
  TestFailureCode,
  Pathway,
  BloodTestResultItemHistory,
  BloodTestResultWithPathway,
  Lab,
  TestObxCodes,
  PathwayRes,
} from "@deep-consulting-solutions/bmh-constants";

import { useSelector } from "react-redux";

import { zohoSelectors } from "redux/zoho";
import { testResultsRequests } from "redux/testResults";
import Loader from "components/Loader";
import { notifications } from "services";
import { getENText } from "helpers";

import { fetchHistoricalObx } from "redux/testResults/requests";
import {
  countObxForBloodTestResults,
  addObxCountToBloodTestResult,
  addObxCountToNewBloodTestResult,
  LabOrder,
  processLabsOrder,
} from "./TestResults.helpers";
import { LoadingContext } from "./LoadingContext";
import ListView from "./ListView";
import ResultDetailsView from "./ResultDetailsView";
import ComparisonView from "./ComparisonView";
import HistoryView from "./HistoryView";
import ResultForm from "./ResultForm";
import DialogDelete from "./DialogDelete";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(1),
    height: `calc(100vh - ${s(1)}px)`,
    width: `calc(100vw - ${s(1)}px)`,
    background: p.common.white,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    padding: s(2),
  },
  addResultsBtn: {
    marginLeft: s(2),
  },
  addIcon: {
    marginRight: s(1),
  },
  titleActions: {
    marginLeft: "auto",
  },
  deleteBtn: {
    marginRight: s(2),
  },
  tableHeaderCell: {
    color: "#7E9FCE",
  },
}));

export interface TestResultsProps {
  isPathway?: boolean;
  pathwayRecord?: Pathway;
  bloodTestResult?: BloodTestResult | null;
}

const TestResults: FC<TestResultsProps> = ({
  isPathway,
  pathwayRecord,
  bloodTestResult,
}) => {
  const ids = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<BloodTestResultWithPathway[]>([]);
  const obxCounts = useMemo(() => countObxForBloodTestResults(results), [
    results,
  ]);
  const [listItemSelected, setListItemSelected] = useState<{
    [id: string]: true;
  }>({});
  const [
    selectedResult,
    setSelectedResult,
  ] = useState<BloodTestResultWithPathway | null>(bloodTestResult || null);

  const [comparisons, setComparisons] = useState<{
    [id: string]: BloodTestResult;
  }>({});
  const [showComparisonView, setShowComparisonView] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState<string | null>(null);

  const [showResultForm, setShowResultForm] = useState(false);
  const [
    editResult,
    setEditResult,
  ] = useState<BloodTestResultWithPathway | null>(null);

  const [resultsForDelete, setResultsForDelete] = useState<
    BloodTestResult[] | null
  >(null);

  const [observationIDs, setObservationIDs] = useState<string[]>([]);
  const [obxUnits, setObxUnits] = useState<string[]>([]);
  const [abnormalFlags, setAbnormalFlags] = useState<TestAbnormalFlag[]>([]);
  const [failureCodes, setFailureCodes] = useState<TestFailureCode[]>([]);
  const [historyOBXID, setHistoryOBXID] = useState<string | null>(null);
  const [labs, setLabs] = useState<Lab[]>([]);
  const [labsOrder, setLabsOrder] = useState<LabOrder[]>([]);
  const [historyData, setHistoryData] = useState<{
    [obxID: string]: BloodTestResultItemHistory[];
  }>({});

  const [unmappedObxCodesBmhNames, setUnmappedObxCodesBmhNames] = useState<
    TestObxCodes[]
  >([]);

  // This is only available to Client Record, if it is on Blood Test Order / Pathway page, it would be undefined
  const [relatedPathways, setRelatedPathways] = useState<
    PathwayRes[] | undefined
  >();

  const fetchData = useCallback(async () => {
    if (!ids.length) return;

    setLoading(true);

    try {
      if (!isPathway) {
        const [r, related] = await Promise.all([
          testResultsRequests.fetchBloodTestResults(ids[0]),
          testResultsRequests.getRelatedPendingManualResultsForContact({
            contactZohoId: ids[0],
          }),
        ]);
        setResults(r);
        setRelatedPathways(related);
      }

      const [a, f, u, l, o, oids, lo] = await Promise.all([
        testResultsRequests.fetchAbnormalFlags(),
        testResultsRequests.fetchFailureCodes(),
        testResultsRequests.getAllObxCodesUnits(),
        testResultsRequests.getBloodTestingLabs(),
        testResultsRequests.fetchFullObxCodeNames(),
        testResultsRequests.getAllObservationIDs(),
        testResultsRequests.fetchLabsOrder(),
      ]);
      setAbnormalFlags(a);
      setFailureCodes(f);
      setObxUnits(u.units);
      setLabs(l);
      setUnmappedObxCodesBmhNames(o);
      setObservationIDs(oids);
      setLabsOrder(processLabsOrder(lo));
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ids, isPathway]);

  const refetchRelatedBloodTestOrder = useCallback(async () => {
    try {
      const related = await testResultsRequests.getRelatedPendingManualResultsForContact(
        {
          contactZohoId: ids[0],
        }
      );
      setRelatedPathways(related);
    } catch {
      //
    }
  }, [ids]);

  const onRowSelect = useCallback((id: string) => {
    setListItemSelected((l) => {
      const cloned = { ...l };
      if (cloned[id]) {
        delete cloned[id];
      } else {
        cloned[id] = true;
      }
      return cloned;
    });
  }, []);

  const onResultSelect = useCallback(
    (r: BloodTestResult) => {
      setSelectedResult(addObxCountToBloodTestResult(r, obxCounts));
      setListItemSelected({});
    },
    [obxCounts]
  );

  const toListView = useCallback(() => {
    setSelectedResult(null);
  }, []);

  const addToComparisons = useCallback((r: BloodTestResult) => {
    setComparisons((cs) => ({ ...cs, [r.id]: r }));
  }, []);

  const removeFromComparisons = useCallback((id: string) => {
    setComparisons((cs) => {
      const cloned = { ...cs };
      delete cloned[id];
      return cloned;
    });
  }, []);

  const goToComparisonViewFromDetailsView = useCallback(() => {
    setShowComparisonView(true);
  }, []);

  const dismissComparison = useCallback(() => {
    setShowComparisonView(false);
    setComparisons({});

    if (selectedResult) {
      notifications.notifySuccess(getENText("results.comparisonView.dismiss"));
    } else {
      setListItemSelected({});
    }
  }, [selectedResult]);

  const dismissSelectedResultForComparison = useCallback(() => {
    setComparisons({});
  }, []);

  const goToComparisonViewFromListView = useCallback(() => {
    const mapped: { [id: string]: BloodTestResult } = {};

    results.forEach((r) => {
      if (listItemSelected[r.id]) mapped[r.id] = r;
    });

    setComparisons(mapped);
    setShowComparisonView(true);
  }, [listItemSelected, results]);

  const goBackFromComparisonView = useCallback(() => {
    if (!selectedResult) setComparisons({});
    setShowComparisonView(false);
  }, [selectedResult]);

  const goToHistoryView = useCallback(
    async (obx: BloodTestResultItem) => {
      setSelectedHistory(obx.name);

      if (isPathway) {
        if (Object.prototype.hasOwnProperty.call(historyData, obx.id)) {
          setHistoryOBXID(obx.id);
          return;
        }

        setLoading(true);
        try {
          const data = await fetchHistoricalObx(
            pathwayRecord!.contact.zohoId,
            obx.id
          );

          setHistoryData((current) => ({
            ...current,
            [obx.id]: data,
          }));
          setHistoryOBXID(obx.id);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    },
    [historyData, isPathway, pathwayRecord]
  );

  const goBackFromHistoryView = useCallback(() => {
    setSelectedHistory(null);
  }, []);

  const openResultForm = useCallback(() => {
    setShowResultForm(true);
  }, []);

  const closeResultForm = useCallback(
    (newResult?: BloodTestResult) => {
      if (newResult) {
        setResults((rs) => {
          const next: BloodTestResult[] = [];
          let added = false;
          rs.forEach((r) => {
            if (r.id === newResult.id) {
              next.push(newResult);
              added = true;
            } else {
              next.push(r);
            }
          });
          if (!added) next.push(newResult);
          return next;
        });
        setSelectedResult(
          isPathway
            ? newResult
            : addObxCountToNewBloodTestResult(newResult, obxCounts)
        );
      }
      setEditResult(null);
      setShowResultForm(false);
    },
    [isPathway, obxCounts]
  );

  const onResultEdit = useCallback((target: BloodTestResult) => {
    setEditResult(target);
    setShowResultForm(true);
  }, []);

  const openDeleteDialog = useCallback(() => {
    setResultsForDelete(results.filter((r) => !!listItemSelected[r.id]));
  }, [results, listItemSelected]);

  const closeDeleteDialog = useCallback((success?: boolean) => {
    setResultsForDelete(null);
    if (success) {
      setListItemSelected((list) => {
        setResults((rs) => {
          return rs.filter((r) => !list[r.id]);
        });

        return {};
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const classes = useStyle();

  const renderContent = () => {
    if (showResultForm) {
      return (
        <ResultForm
          zohoId={ids[0]}
          closeResultForm={closeResultForm}
          targetResult={editResult}
          obxUnits={obxUnits}
          observationIDs={observationIDs}
          abnormalFlags={abnormalFlags}
          failureCodes={failureCodes}
          isPathway={isPathway}
          pathwayRecord={pathwayRecord}
          labs={labs}
          unmappedObxCodesBmhNames={unmappedObxCodesBmhNames}
          relatedPathways={relatedPathways}
          labsOrder={labsOrder}
          refetchRelatedBloodTestOrder={refetchRelatedBloodTestOrder}
        />
      );
    }

    if (showComparisonView) {
      return (
        <ComparisonView
          comparisons={comparisons}
          goBack={goBackFromComparisonView}
          dismissComparison={dismissComparison}
        />
      );
    }

    if (selectedHistory) {
      if (isPathway && historyOBXID) {
        return (
          <HistoryView
            items={historyData[historyOBXID]}
            goBack={goBackFromHistoryView}
          />
        );
      }
      return (
        <HistoryView
          selected={selectedHistory}
          results={results}
          goBack={goBackFromHistoryView}
        />
      );
    }

    if (selectedResult) {
      return (
        <ResultDetailsView
          result={selectedResult}
          toListView={toListView}
          comparisons={comparisons}
          addToComparisons={addToComparisons}
          removeFromComparisons={removeFromComparisons}
          goToComparison={goToComparisonViewFromDetailsView}
          goToHistory={goToHistoryView}
          onResultEdit={onResultEdit}
          dismissSelectedResultForComparison={
            dismissSelectedResultForComparison
          }
          isPathway={isPathway}
          pathwayStage={
            isPathway ? pathwayRecord?.stage : selectedResult.pathway?.stage
          }
        />
      );
    }

    return (
      <ListView
        results={results}
        selected={listItemSelected}
        onRowSelect={onRowSelect}
        onResultSelect={onResultSelect}
        onCompareClick={goToComparisonViewFromListView}
        openResultForm={openResultForm}
        openDeleteDialog={openDeleteDialog}
        isPathway={isPathway}
        pathwayRecord={pathwayRecord}
      />
    );
  };

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <div className={classes.wrapper}>
        <Loader open={loading} />

        {renderContent()}
      </div>
      {!isPathway && (
        <DialogDelete
          results={resultsForDelete}
          closeDialog={closeDeleteDialog}
        />
      )}
    </LoadingContext.Provider>
  );
};

export default TestResults;
