import {
  Button,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getFieldName,
  getManualMedicines,
  getSpecifyDosingInstructionsMedicines,
  getValueFromObject,
} from "helpers";
import { TreatmentWidgetActions, useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { TreatmentFormValues, ZohoBMHDoctor } from "types";

const useStyles = makeStyles(({ palette: p }) => ({
  danger: {
    color: p.error.main,
    borderColor: p.error.main,
    cursor: "pointer",
  },
  action: {
    width: "100%",
  },
}));

interface Props {
  view: "success" | "initial-block" | "fetch-error" | "form";
  onSubmit: (values: any, finalize?: boolean, draft?: boolean) => Promise<void>;
  doctor: ZohoBMHDoctor | null;
  name?: string;
  createdTreatmentUrl?: string;
  submitButtonText: string;
  otherMedicineTypeId: string;
}

export const CreateTreatmentActions = ({
  view,
  onSubmit,
  doctor,
  name,
  createdTreatmentUrl,
  submitButtonText,
  otherMedicineTypeId,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { handleClose } = useTreatmentWidgetLayout();

  const isDoctor = useMemo(() => {
    return !!doctor?.id;
  }, [doctor?.id]);

  const fieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const manualMedicines = useMemo(() => {
    return getManualMedicines(treatmentAreasValues, otherMedicineTypeId);
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasManualMedicine = useMemo(() => {
    return !!manualMedicines.length;
  }, [manualMedicines.length]);

  const specifyDosingInstructionsMedicines = useMemo(() => {
    return getSpecifyDosingInstructionsMedicines(
      treatmentAreasValues,
      otherMedicineTypeId
    );
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasSpecifyDosingInstruction = useMemo(() => {
    return !!specifyDosingInstructionsMedicines.length;
  }, [specifyDosingInstructionsMedicines.length]);

  const showFinalizationButton = useMemo(() => {
    return !isDoctor && !hasManualMedicine && !hasSpecifyDosingInstruction;
  }, [isDoctor, hasManualMedicine, hasSpecifyDosingInstruction]);

  const onClickCloseForInProgressTreatment = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onClickCancelTreatmentPlan = useCallback(() => {
    handleClose(false, true);
  }, [handleClose]);

  const onGoToFinalize = useCallback(() => {
    onSubmit(values, true);
  }, [onSubmit, values]);

  const onSave = useCallback(() => {
    onSubmit(values, false, true);
  }, [onSubmit, values]);

  const onCreateTreatmentPlan = useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  const handleCloseTreatmentPlanOnSuccess = useCallback(() => {
    if (createdTreatmentUrl) {
      window.open(createdTreatmentUrl, "_blank");
    }
    handleClose(true);
  }, [handleClose, createdTreatmentUrl]);

  return (
    <TreatmentWidgetActions
      containerClass={view === "form" ? classes.action : ""}
    >
      {view === "initial-block" ? (
        <Button
          variant="outlined"
          onClick={onClickCloseForInProgressTreatment}
          color="primary"
        >
          CLOSE
        </Button>
      ) : null}
      {view === "success" ? (
        <Grid container justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickCloseForInProgressTreatment}
              color="primary"
            >
              CLOSE
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseTreatmentPlanOnSuccess}
            >
              VIEW TREATMENT PLAN
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {view === "form" ? (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickCancelTreatmentPlan}
              className={classes.danger}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              {showFinalizationButton ? (
                <Grid item>
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        This will skip the automatic calculation of matching
                        products and packages for this treatment
                      </Typography>
                    }
                  >
                    <span>
                      <Button
                        variant="outlined"
                        onClick={onGoToFinalize}
                        color="primary"
                      >
                        GO TO FINALIZATION
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              ) : null}
              <Grid item>
                <Button variant="outlined" onClick={onSave} color="primary">
                  SAVE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onCreateTreatmentPlan}
                  color="primary"
                  type="submit"
                >
                  {submitButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </TreatmentWidgetActions>
  );
};
