import React, { useContext } from "react";

interface LoadingContextProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadingContext = React.createContext<LoadingContextProps | null>(
  null
);

export const useLoadingContext = () =>
  useContext(LoadingContext) as LoadingContextProps;
