import React, { useCallback, useEffect, useState } from "react";
import { zohoServices } from "services";
import { ukCountries } from "configs";
import { Formik, FormikConfig } from "formik";
import {
  manualOrderValidationSchema,
  prescriptionFormValidationSchema,
} from "helpers";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  createManualPrescription,
  fetchSignedUrlForUpload,
  getAllergiesList,
  getDoctorProductsDetails,
  uploadFileToS3,
} from "services/treatment-orders";
import moment from "moment";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { Box, CircularProgress } from "@material-ui/core";
import { CreateManualPrescriptionForm } from "./CreateManualPrescriptionForm";
import { InitialValues } from "./types";
import { ActionButtons } from "./ActionButtons";
import { emptyProduct, steps } from "./constants";
import { OrderForm } from "./OrderForm";

export const CreateManualPrescription = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getDoctorProductsDetails();
      const allergies = await getAllergiesList();

      const allergiesOptions = allergies
        .sort((a, b) => a.sequence_number - b.sequence_number)
        .map((i) => i.display_value);

      if (res) {
        const initialValuesForOrderForm = {
          country: { title: "", value: "" },
          province: "",
          city: "",
          postalCode: "",
          street: "",
          apartment: "",
          orderItems: [],
        };

        const initValues = {
          requesterName: "",
          requesterEmail: "",
          prescriptionDocument: null,
          numberOfRepetition: undefined,
          externalDoctorName: "",
          doctors: res.doctors,
          products: res.products,
          doctor: null,
          patient: null,
          patients: [],
          patientDob: null,
          patientFirstName: "",
          patientLastName: "",
          patientFoodDrugAllergies: "",
          allergiesOptions,
          patientAllergies: [],
          prescriptionItems: [
            {
              product: emptyProduct,
              prescriptionTotalQuantity: undefined,
              usageNotes: "",
            },
          ],
          ...initialValuesForOrderForm,
        };
        setInitialValues(initValues);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting, setFieldValue }) => {
      try {
        setSubmitData(true);
        let countryLabel = (
          values.patient?.ShippingAddress?.country || ""
        ).toLowerCase();
        countryLabel = ukCountries.includes(countryLabel)
          ? "United Kingdom"
          : countryLabel;
        const country = {
          title: countryLabel,
          value: countryLabel,
        };
        const province = values.patient?.ShippingAddress?.region || "";
        const city = values.patient?.ShippingAddress?.city || "";
        const postalCode = values.patient?.ShippingAddress?.zip || "";
        const street = values.patient?.ShippingAddress?.street || "";
        const apartment = values.patient?.ShippingAddress?.street2 || "";
        const orderItems =
          values.orderItems.length > 0
            ? values.orderItems
            : values.prescriptionItems.map((p) => ({
                id: p.product?.id,
                isSelected: true,
                name: p.product?.title,
                remainingQty: p.prescriptionTotalQuantity,
                quantity: undefined,
                notes: p.usageNotes,
              }));
        if (activeStep === 0) {
          setFieldValue("country", country);
          setFieldValue("province", province);
          setFieldValue("city", city);
          setFieldValue("street", street);
          setFieldValue("postalCode", postalCode);
          setFieldValue("apartment", apartment);
          setFieldValue("orderItems", orderItems);
          handleNext();
          return;
        }
        if (activeStep === 1) {
          const file = (values.prescriptionDocument as [File])[0];
          const signedUrlRes = await fetchSignedUrlForUpload(file.name);
          if (signedUrlRes) {
            const { signedUploadUrl, s3Key } = signedUrlRes;
            await uploadFileToS3(file, signedUploadUrl);
            const prescriptionItemsPayload = values.prescriptionItems.map(
              (p) => {
                const orderItem = values.orderItems.find(
                  (i) => i.id === p.product?.id
                );
                return {
                  itemId: p.product?.id,
                  quantityDispense: p.prescriptionTotalQuantity,
                  usageNotes: p.usageNotes,
                  orderItem: {
                    quantity: orderItem?.quantity,
                    usageNotes: orderItem?.notes,
                  },
                };
              }
            );
            const payload = {
              patientId:
                values.patient?.value === "new" ? null : values.patient?.ZohoID,
              requesterName: values.requesterName,
              requesterEmail: values.requesterEmail,
              firstName: values.patientFirstName,
              lastName: values.patientFirstName,
              dob: values.patientDob
                ? moment(values.patientDob).format("YYYY-MM-DD")
                : null,
              drugAndFoodAllergies: values.patientFoodDrugAllergies,
              allergies: values.patientAllergies,
              doctorId:
                values.doctor?.value === "external"
                  ? null
                  : values.doctor?.value,
              externalDoctorName: values.externalDoctorName,
              noOfRepititions: values.numberOfRepetition,
              street: values.street,
              apartment: values.apartment,
              city: values.city,
              province: values.province,
              postCode: values.postalCode,
              country: values.country.value,
              s3Key,
              filename: file.name,
              items: prescriptionItemsPayload,
            };

            await createManualPrescription(payload);
            closeWidget();
          }
        }
      } catch (error) {
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [activeStep, closeWidget, handleNext]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={
        activeStep === 0
          ? prescriptionFormValidationSchema
          : manualOrderValidationSchema
      }
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle
          isCustomButton
          title="Create Manual Prescription"
        />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading && (
            <Box
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "450px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && <CreateManualPrescriptionForm />}
              {activeStep === 1 && <OrderForm />}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons activeStep={activeStep} goBack={handleBack} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
