import { ZohoUserProfileEnum, ZohoCurrentUser } from "types";

export const convertToArray = <T>(input?: T | T[]) => {
  if (!input) return null;
  const array = Array.isArray(input) ? input : [input];
  if (!array.length) return null;
  return array;
};

export const checkForUserProfiles = (
  user: ZohoCurrentUser | undefined,
  onlyForProfiles: ZohoUserProfileEnum[] | null,
  notForProfiles: ZohoUserProfileEnum[] | null
) => {
  if (!user) return true;
  const { name } = user.profile;
  if (onlyForProfiles) return onlyForProfiles.some((p) => p === name);
  if (notForProfiles) return notForProfiles.every((p) => p !== name);
  return true;
};
