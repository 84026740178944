import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ProductScanner } from "components/Dispensing";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { dispenseDrugsServices, notifications, zohoServices } from "services";
import { AxiosError } from "axios";
import { BarcodeData, ProductType } from "../types";

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const AddProductVariant: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [manufacturer, setManufacturer] = useState("");
  const [notAllowed, setNotAllowed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [scannedProduct, setScannedProduct] = useState<BarcodeData>();

  const [id] = useSelector(zohoSelectors.getIds);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    if (notAllowed) {
      return "not-allowed";
    }
    return "scanProduct";
  }, [fetchError, notAllowed]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [productDetails, allManufacturers] = await Promise.all([
        dispenseDrugsServices.fetchProduct(id),
        dispenseDrugsServices.fetchManufacturers(),
      ]);
      setNotAllowed(
        ![ProductType.SUPPLEMENTARY, ProductType.MEDICATION].includes(
          productDetails.Product_Type
        )
      );
      setManufacturers(allManufacturers);
    } catch (error) {
      setFetchError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const updateScannedProduct = useCallback((data: BarcodeData) => {
    setScannedProduct(data);
    setErrorMessage("");
  }, []);

  const submitDetails = useCallback(async () => {
    try {
      setLoading(true);
      const similarProducts = await dispenseDrugsServices.findSimilarProductVariants(
        {
          Product: id || "",
          Manufacturer: manufacturer,
          Barcode: scannedProduct?.barcode || "",
        }
      );
      const sameManufacturer = similarProducts.variants.find(
        (i) => i.Manufacturer === manufacturer
      );
      let isSameManufacturerAndProduct;
      if (sameManufacturer)
        isSameManufacturerAndProduct = Boolean(
          similarProducts.variants.find(
            (j) => j.Product?.id === id && j.Manufacturer === manufacturer
          )
        );
      const sameBarcode = similarProducts.variants.find(
        (j) => j.Barcode === scannedProduct?.barcode
      );
      let isSameBarcode;
      let errorMsg = "";
      if (sameBarcode)
        isSameBarcode = Boolean(
          similarProducts.variants.find(
            (k) => k.Product?.id === id && k.Barcode === scannedProduct?.barcode
          )
        );

      if (
        sameManufacturer &&
        isSameManufacturerAndProduct &&
        sameBarcode &&
        isSameBarcode
      ) {
        errorMsg =
          "A variant with the same manufacturer and barcode already exists for this product";
      } else if (sameManufacturer && isSameManufacturerAndProduct) {
        errorMsg =
          "A variant with the same manufacturer already exists for this product";
      } else if (sameBarcode && isSameBarcode) {
        errorMsg =
          "A variant with the same barcode already exists for this product";
      } else if (sameBarcode) {
        errorMsg = `A variant with the same barcode already exists for another product (<a href="${sameBarcode.Product?.Link}">${sameBarcode.Product?.Product_Name}</a>).`;
      }

      if (errorMsg) {
        setErrorMessage(errorMsg);
        return;
      }

      await dispenseDrugsServices.saveVariant({
        Product: id || "",
        Manufacturer: manufacturer,
        Barcode: scannedProduct?.barcode || "",
        Barcode_Type: scannedProduct?.barcodeType || "",
        GTIN: scannedProduct?.GTIN || "",
      });
      zohoServices.closePopup(true);
    } catch (error) {
      const err = error as AxiosError;
      notifications.notifyError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, manufacturer, scannedProduct]);

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <TreatmentWidgetLayout defaultWidth={450}>
      <TreatmentWidgetTitle title="Print Packing Slip" isCustomButton />
      <TreatmentWidgetContent>
        <Loader open={loading} />
        {view === "fetch-error" ? (
          <Alert severity="error">
            <AlertTitle>An error occurred while fetching data.</AlertTitle>
            {fetchError}
          </Alert>
        ) : null}
        {view === "not-allowed" ? (
          <Alert severity="error">
            <AlertTitle>
              Scanning is allowed only for The Hormonist medication and
              supplementary products.
            </AlertTitle>
            {fetchError}
          </Alert>
        ) : null}
        {view === "scanProduct" ? (
          <Box minHeight={250}>
            <Box maxWidth={350} mb={2}>
              <TextField
                label="Manufacturer"
                placeholder="Manufacturer"
                margin="dense"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                required
                select
              >
                {manufacturers.map((m) => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <ProductScanner setScannedProduct={updateScannedProduct} />
            {scannedProduct && (
              <Box>
                {scannedProduct.barcode && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Barcode: </strong>
                    {scannedProduct.barcode}
                  </Typography>
                )}
                {scannedProduct.barcodeType && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Barcode Type: </strong>
                    {scannedProduct.barcodeType}
                  </Typography>
                )}
                {scannedProduct.GTIN && (
                  <Typography variant="body2" gutterBottom>
                    <strong>GTIN: </strong>
                    {scannedProduct.GTIN}
                  </Typography>
                )}
              </Box>
            )}
            {errorMessage ? (
              <Box mt={2}>
                <Alert severity="error">
                  <AlertTitle>
                    <Box dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </AlertTitle>
                  {fetchError}
                </Alert>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </TreatmentWidgetContent>
      <TreatmentWidgetActions containerClass={classes.actions}>
        <Box textAlign="right">
          <Button
            onClick={() => zohoServices.closePopup(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            onClick={submitDetails}
            disabled={!manufacturer}
            variant="contained"
            color="primary"
          >
            Confirm and Activate
          </Button>
        </Box>
      </TreatmentWidgetActions>
    </TreatmentWidgetLayout>
  );
};
