import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import React, { useMemo } from "react";
import { FormikNumberField } from "components/FormikNumberField";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { FormValues } from "./types";

export interface RenewalFormProps {
  unfulfilledOrdersCount: number;
  isSelfSupplied: boolean;
  maxNumberOfRepetitions: number;
}

export const RenewalForm = ({
  unfulfilledOrdersCount,
  isSelfSupplied,
  maxNumberOfRepetitions,
}: RenewalFormProps) => {
  const { values } = useFormikContext<FormValues>();

  const isGenerateWithoutRegularCheckUp = useMemo(() => {
    return values.generateWithoutRegularCheckUp === "yes";
  }, [values.generateWithoutRegularCheckUp]);

  const disableNumberOfRepetitions = useMemo(() => {
    return !(unfulfilledOrdersCount - 1 > 0);
  }, [unfulfilledOrdersCount]);

  const showFields = useMemo(() => {
    return !isSelfSupplied && isGenerateWithoutRegularCheckUp;
  }, [isSelfSupplied, isGenerateWithoutRegularCheckUp]);

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Box maxWidth="80%">
          <Alert severity="info">
            A prescription will be generated to fulfil the order
          </Alert>
        </Box>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item>
            <Typography>
              Can the prescription be generated for multiple repeats without
              completing the regular check-up?
            </Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <Field
                component={RadioGroup}
                name="generateWithoutRegularCheckUp"
              >
                <Grid container spacing={1} wrap="nowrap" alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value="yes"
                      label={<Typography>Yes</Typography>}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value="no"
                      label={<Typography>No</Typography>}
                    />
                  </Grid>
                </Grid>
              </Field>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {showFields ? (
        <Box>
          <Box mb={2}>
            <Box maxWidth="80%">
              <Alert severity="info">
                Click renew to generate a new prescription. If you want to pause
                the treatment on a specific date to stop fulfilling orders on a
                specific date if the client is still overdue for checkup, enter
                the pause Date.
              </Alert>
            </Box>
          </Box>
          <Box mb={2} maxWidth={200}>
            <Field
              component={FormikNumberField}
              name="numberOfRepetitions"
              label="Number of Repetitions"
              required
              type="integer"
              size="small"
              min={0}
              max={maxNumberOfRepetitions}
              disabled={disableNumberOfRepetitions}
              numberFormatProps={{
                fixedDecimalScale: true,
                decimalScale: 0,
              }}
            />
          </Box>
          <Box mb={2} maxWidth={200}>
            <Field
              component={FormikDatePicker}
              name="pauseDate"
              label="Pause Date"
              required
              disablePast
              placeholder="DD/MM/YYYY"
              size="small"
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
