import React, { useMemo } from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { InitialValues } from "./types";
import { specialPurposeOfConsultationValues } from "./constants";

type ActionButtonsProps = {
  activeStep: number;
  goBack: () => void;
};

export const ActionButtons = ({ activeStep, goBack }: ActionButtonsProps) => {
  const {
    isValid,
    submitForm,
    isSubmitting,
    values,
  } = useFormikContext<InitialValues>();

  const purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn = specialPurposeOfConsultationValues.includes(
    values.purposeOfLastConsultation
  );

  const isRecentBloodTestAcceptable =
    values.isRecentBloodTestAcceptable === "Yes";
  const shouldSendDoctorInvite = values.shouldSendDoctorInvite === "Yes";

  const mainButtonText = useMemo(() => {
    if (activeStep === 0) {
      return "Next";
    }
    if (activeStep === 1) {
      if (!purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn) {
        return "Save";
      }
      if (
        purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
        !isRecentBloodTestAcceptable
      ) {
        return "Request Client to Repeat Blood Test";
      }
      if (
        purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
        isRecentBloodTestAcceptable &&
        shouldSendDoctorInvite
      ) {
        return "Next";
      }
      if (
        purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
        isRecentBloodTestAcceptable &&
        !shouldSendDoctorInvite
      ) {
        return "Save";
      }
    }
    if (activeStep === 2) {
      if (shouldSendDoctorInvite) {
        return "Send Booking Link";
      }
      return "OK";
    }
    if (activeStep === 3) {
      return "OK";
    }
    return "Save";
  }, [
    activeStep,
    isRecentBloodTestAcceptable,
    purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn,
    shouldSendDoctorInvite,
  ]);

  const hideMainButton =
    activeStep === 1 &&
    purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
    !values.isRecentBloodTestAcceptable;

  return (
    <>
      {activeStep === 0 || values.checkupActionType === "update-details" ? (
        <Box
          marginY={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridColumnGap="8px"
        >
          {[1, 2].includes(activeStep) && (
            <Button
              style={{
                textTransform: "capitalize",
                minWidth: "110px",
              }}
              color="primary"
              variant="outlined"
              onClick={goBack}
              disabled={isSubmitting}
            >
              Back
            </Button>
          )}
          {!hideMainButton && (
            <Button
              style={{
                textTransform: "capitalize",
                minWidth: "110px",
              }}
              color="primary"
              variant="contained"
              onClick={submitForm}
              disabled={!isValid || isSubmitting}
              endIcon={activeStep === 0 && <ArrowForwardIcon />}
            >
              {mainButtonText}
            </Button>
          )}
        </Box>
      ) : null}
    </>
  );
};
