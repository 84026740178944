import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MainItem, Scanner, SupplementaryItem } from "components/Dispensing";
import React from "react";
import clsx from "clsx";
import { TitleValue } from "components/TitleValue";
import { ParentOrderItem, TreatmentOrderItem } from "../types";

interface ConfirmDispensingProps {
  mainItems: TreatmentOrderItem[];
  supplementaryItems: TreatmentOrderItem[];
  manufacturers: string[];
}

export const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
  },
  mb2: {
    marginBottom: spacing(2),
  },
  heading: {
    fontWeight: 500,
  },
  text: {
    "& .MuiInputBase-root": {
      fontSize: "0.875rem",
    },
  },
  subTitle: {
    fontSize: "0.875rem",
    color: p.grey[600],
  },
  checkbox: {
    color: p.primary.main,
  },
}));

export const ConfirmDispensing: React.FC<ConfirmDispensingProps> = ({
  mainItems,
  supplementaryItems,
  manufacturers,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={2}>
        <Scanner
          mainItems={mainItems}
          supplementaryItems={supplementaryItems}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="h5" color="primary">
          Order Items
        </Typography>
        {mainItems.map((orderItem, index) => (
          <Accordion
            defaultExpanded
            className={clsx(classes.wrapper, classes.mb2)}
            key={orderItem.id}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid justify="space-between" alignItems="center" container>
                <Grid item>
                  <Typography
                    className={classes.heading}
                    variant="h5"
                    color="primary"
                  >
                    {orderItem.productItem?.Medicine_Type?.name}
                  </Typography>
                  <Typography>
                    {orderItem.medicineItem?.treatmentArea?.Treatment_Area
                      ?.name ||
                      orderItem.medicineItem?.treatmentArea
                        ?.Other_Treatment_Area}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <MainItem
                  item={orderItem as ParentOrderItem}
                  fieldName={`mainItems.${index}`}
                  manufacturers={manufacturers}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        {!!supplementaryItems.length && (
          <Typography variant="h5" color="primary">
            Supplementary Products
          </Typography>
        )}
        {supplementaryItems.map((orderItem, index) => (
          <Accordion
            defaultExpanded
            className={clsx(classes.wrapper, classes.mb2)}
            key={orderItem.id}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TitleValue
                    title="Name"
                    value={orderItem.productItem?.Name_For_Prescription}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TitleValue title="Quantity" value={orderItem.Quantity} />
                </Grid>
                <Grid item xs={4}>
                  <TitleValue
                    title="Description"
                    value={orderItem.productItem?.Description}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <SupplementaryItem
                  item={orderItem as ParentOrderItem}
                  fieldName={`supplementaryItems.${index}`}
                  manufacturers={manufacturers}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};
