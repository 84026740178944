import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import {
  AutoCompleteOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoTreatmentArea,
} from "types";
import { MedicineFields } from "./MedicineFields";

interface Props {
  name?: string;
  treatmentArea: ZohoTreatmentArea;
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}

export const MedicinesFields = ({
  name,
  treatmentArea,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
}: Props) => {
  const { values } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("medicinesSelected", name), [
    name,
  ]);

  const medicinesSelectedValues: AutoCompleteOption[] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  return (
    <Box>
      {medicinesSelectedValues.map((medicineSelectedValues, index) => (
        <MedicineFields
          key={`${medicineSelectedValues.value}-${medicineSelectedValues.title}`}
          isLast={index === medicinesSelectedValues.length - 1}
          treatmentArea={treatmentArea}
          dosingTypes={dosingTypes}
          dosingAdminRoutes={dosingAdminRoutes}
          medicineAdminRoutes={medicineAdminRoutes}
          medicineSelectedValues={medicineSelectedValues}
          index={index}
          name={fieldName}
        />
      ))}
    </Box>
  );
};
