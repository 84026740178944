import { n8nClient } from "apis";
import {
  ConfirmOrderPackingPayload,
  GetCouriersResponse,
  GetTreatmentOrdersResponse,
} from "widgets/BulkOrderPacking/types";
import { formatTreatmentOrder } from "widgets/BulkOrderPacking/utils";

export const confirmOrderPacking = async (
  payload: ConfirmOrderPackingPayload
) => {
  const res = await n8nClient.post(`/treatment-order`, payload);
  return res.data;
};

export const confirmOrderManifest = async (payload: {
  records: { id: string; courierManifestNumber: string }[];
}) => {
  const res = await n8nClient.post(
    `/treatment-order/ready-for-pickup`,
    payload
  );
  return res.data;
};

export const getTreatmentOrders = async (treatmentOrderIds: string[]) => {
  try {
    const payload = {
      treatmentOrders: treatmentOrderIds,
    };
    const res = await n8nClient.post<GetTreatmentOrdersResponse>(
      `/treatmentOrders`,
      payload
    );
    const rawTreatmentOrders = res.data.data;
    const formattedOrders = rawTreatmentOrders.map(formatTreatmentOrder);
    return formattedOrders;
  } catch (error) {
    return [];
  }
};

export const getCouriers = async () => {
  try {
    const res = await n8nClient.get<GetCouriersResponse>(`/get-couriers`);
    const rawCourierRes = res.data.data;
    const formatted = rawCourierRes.map((c) => ({
      id: c.id,
      label: c.Name,
      value: c.Name,
      title: c.Name,
      manifestRequired: c.Manifest_Required,
    }));
    return formatted;
  } catch (error) {
    return [];
  }
};
