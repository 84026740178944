import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

interface Props {
  missingRequiredFields: string[];
}

export const BlockWidgetAlert = ({ missingRequiredFields }: Props) => {
  return (
    <Alert severity="error">
      <AlertTitle>
        This treatment cannot be finalized without entering the following
        details for this Client:
      </AlertTitle>
      <Box component="ul" margin={0}>
        {missingRequiredFields.map((label) => (
          <li key={label}>{label}</li>
        ))}
      </Box>
    </Alert>
  );
};
