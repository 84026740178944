import {
  UnmatchedHL7,
  PathwayRecordSearchResult,
  ClientRecord,
  OriginalUnmatchedHL7,
} from "@deep-consulting-solutions/bmh-constants";
import { formatDateForRequest } from "helpers";

import {
  ConvertedClientRecord,
  ConvertedPathwayRecord,
} from "./Unmatched.types";

export const convertPossibleMatches = (data: ClientRecord[]) => {
  const clientsMap: { [id: string]: ConvertedClientRecord } = {};
  const pathwaysMap: { [id: string]: ConvertedPathwayRecord } = {};

  data.forEach((d) => {
    const { pathways: ps, ...client } = d;
    clientsMap[client.id] = client;

    ps.forEach((p) => {
      const converted = {
        ...p,
        client,
      };
      pathwaysMap[converted.id] = converted;
    });
  });

  return { clientsMap, pathwaysMap };
};

export const rearrangeAndFilterPossibleMatches = ({
  possibleClientsMap,
  possiblePathwaysMap,
  selectedClientId,
}: {
  possibleClientsMap: { [id: string]: ConvertedClientRecord };
  possiblePathwaysMap: { [id: string]: ConvertedPathwayRecord };
  selectedClientId: string | null;
}) => {
  const clients = Object.values(possibleClientsMap);
  const pathways = Object.values(possiblePathwaysMap);

  return {
    possibleClients: clients,
    availablePathways: selectedClientId
      ? pathways.filter((p) => p.client.id === selectedClientId)
      : pathways,
  };
};

export const replaceData = (
  data: UnmatchedHL7,
  client: ConvertedClientRecord,
  pathway: ConvertedPathwayRecord
): Omit<UnmatchedHL7, "discardReason" | "originalData"> => {
  const unmatchedHL7 = data;
  delete unmatchedHL7.discardReason;
  delete unmatchedHL7.originalData;

  return {
    ...unmatchedHL7,
    firstName: client.firstName,
    lastName: client.lastName,
    gender: client.gender,
    dob: client.dob,
    patientId: client.patientId,
    sampleId: pathway.sampleId,
  };
};

export const getPathwayRecordLink = (pathway: ConvertedPathwayRecord) => {
  return `${process.env.REACT_APP_ZOHO_BASE_URL}/${process.env.REACT_APP_ZOHO_TAB_PATHWAYS}/${pathway.zohoId}`;
};

export const getClientRecordLink = (client: ConvertedClientRecord) => {
  return `${process.env.REACT_APP_ZOHO_BASE_URL}/${process.env.REACT_APP_ZOHO_TAB_CLIENTS}/${client.zohoId}`;
};

export const composeDataToSend = ({
  id,
  doesMatchingBloodTestResultExist,
  unmatched,
  status,
  sampleCollectedOn,
  zohoID,
  testName,
  ...result
}: UnmatchedHL7) => {
  return result;
};

export const displayPathwayRecord = (
  p: PathwayRecordSearchResult | ConvertedPathwayRecord
) => {
  return `${p.name} - ${p.client.firstName} ${p.client.lastName}`;
};

export const addPathwaySearchResultToRecords = ({
  result,
  clientsMap,
  pathwaysMap,
}: {
  result: PathwayRecordSearchResult;
  clientsMap: { [id: string]: ConvertedClientRecord };
  pathwaysMap: { [id: string]: ConvertedPathwayRecord };
}) => {
  const { client } = result;
  if (client) clientsMap[client.id] = client;
  pathwaysMap[result.id] = result;

  return {
    clientsMap,
    pathwaysMap,
    clients: Object.values(clientsMap),
    pathways: Object.values(pathwaysMap),
  };
};

const convertFieldValueToString = (value: null | string | Date) => {
  if (!value || typeof value === "string") {
    return value || "";
  }
  return Number.isNaN(value.getTime()) ? "" : formatDateForRequest(value);
};

export const isFieldValueChanged = (
  value: string | Date | null,
  originalValue: string
) => {
  return convertFieldValueToString(value) !== originalValue;
};

export const shouldAllowRestoreOriginalData = (
  data: UnmatchedHL7,
  originalData?: OriginalUnmatchedHL7
) => {
  if (!originalData) return false;
  return (
    data.sampleId !== originalData.sampleId ||
    data.sampleCollectedOn !== originalData.sampleCollectedOn ||
    data.patientId !== originalData.patientId ||
    data.firstName !== originalData.firstName ||
    data.lastName !== originalData.lastName ||
    data.dob !== originalData.dob ||
    data.gender !== originalData.gender
  );
};
