import { PathwayRecordSearchResult } from "@deep-consulting-solutions/bmh-constants";
import _debounce from "lodash/debounce";
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Popper,
  Card,
  CircularProgress,
  Button,
  DialogActions,
  Typography,
  makeStyles,
  Box,
  DialogProps,
} from "@material-ui/core";

import { unmatchedRequests } from "redux/unmatched";
import { SEARCH_DEBOUNCE_TIME } from "configs";

import { displayPathwayRecord } from "../Unmatched.helpers";

import SearchResult from "./SearchResult";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    display: "flex",
    alignItems: "center",
  },
  titleLoading: {
    marginLeft: s(1),
  },
  actions: {
    paddingRight: s(2),
    paddingBottom: s(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  searchResults: {
    maxHeight: "30vh",
    overflowY: "auto",
  },
  selectBtn: {
    marginLeft: s(1),
  },
  nodata: {
    color: p.grey[500],
  },
}));

interface SearchDialogProps extends DialogProps {
  onClose: () => any;
  onOK: (result: PathwayRecordSearchResult) => any;
}

const SearchDialog: React.FC<SearchDialogProps> = ({
  onClose,
  onOK,
  open,
  ...others
}) => {
  const wrapperRef = useRef<HTMLElement | null>(null);
  const [searchInputEl, setSearchInputEl] = useState<HTMLInputElement | null>(
    null
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<{
    [s: string]: PathwayRecordSearchResult[];
  }>({});
  const [loading, setLoading] = useState(0);
  const [searchInputWidth, setSearchInputWidth] = useState<string | undefined>(
    undefined
  );

  const [
    selectedResult,
    setSelectedResult,
  ] = useState<PathwayRecordSearchResult | null>(null);

  const [searchInputFocus, setSearchInputFocus] = useState(false);

  const searchRef = useRef(
    _debounce(async (s: string) => {
      setLoading((c) => c + 1);
      try {
        const d = await unmatchedRequests.fetchPathwaySearchResults(s);

        setSearchResults((current) => {
          return {
            ...current,
            [s]: d,
          };
        });
        setLoading((c) => c - 1);
      } catch {
        setLoading((c) => c - 1);
      }
    }, SEARCH_DEBOUNCE_TIME)
  );

  const reset = useCallback(() => {
    setSearchTerm("");
    setSelectedResult(null);
  }, []);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const s = e.target.value;
      setSearchTerm(s);
      setSelectedResult(null);
      if (searchResults[s]) return;
      if (s.length < 3) {
        setSearchResults((current) => {
          return {
            ...current,
            [s]: [],
          };
        });
        return;
      }
      searchRef.current(s);
    },
    [searchResults]
  );

  const onSearchInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { currentTarget } = e;

      setSearchInputFocus(true);

      setSearchInputEl(() => {
        setSearchInputWidth(`${currentTarget.offsetWidth}px`);

        return currentTarget;
      });
    },
    []
  );

  const onSearchInputBlur = useCallback(() => {
    setSearchInputFocus(false);
  }, []);

  const onResultClick = useCallback((r: PathwayRecordSearchResult) => {
    setSelectedResult(r);
  }, []);

  const onDialogClose = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  const onSelectClick = useCallback(() => {
    if (!selectedResult) return;
    onOK(selectedResult);
    reset();
  }, [onOK, selectedResult, reset]);

  const currentResult = searchResults[searchTerm];

  const inputValue = useMemo(() => {
    if (!selectedResult) return searchTerm;
    if (!searchInputFocus) return displayPathwayRecord(selectedResult);
    return selectedResult.name;
  }, [selectedResult, searchInputFocus, searchTerm]);

  useEffect(() => {
    if (open) {
      setLoading(0);
    }
  }, [open]);

  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      innerRef={wrapperRef}
      onClose={onDialogClose}
      open={open}
      {...others}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h5">Search Blood Test Order Record</Typography>
        {!!loading && (
          <CircularProgress size={25} className={classes.titleLoading} />
        )}
      </DialogTitle>
      <DialogContent>
        <TextField
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
          value={inputValue}
          placeholder="Search by Blood Test Order name..."
          onChange={onSearchChange}
        />
        {inputValue.length > 2 && currentResult && !currentResult.length && (
          <Box className={classes.nodata}>
            <Typography variant="caption">
              No Blood Test Order found!
            </Typography>
          </Box>
        )}

        <Popper
          container={wrapperRef.current}
          anchorEl={searchInputEl}
          open={!!(!selectedResult && currentResult && currentResult.length)}
          placement="bottom-start"
          keepMounted
        >
          <Card
            className={classes.searchResults}
            style={{ width: searchInputWidth }}
          >
            {currentResult && (
              <>
                {currentResult.map((r) => {
                  return (
                    <SearchResult
                      key={r.id}
                      result={r}
                      onResultClick={onResultClick}
                    />
                  );
                })}
              </>
            )}
          </Card>
        </Popper>
      </DialogContent>

      <DialogActions>
        <Box className={classes.actions}>
          <Button variant="text" color="primary" onClick={onDialogClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.selectBtn}
            disabled={!selectedResult}
            onClick={onSelectClick}
          >
            Select
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SearchDialog;
