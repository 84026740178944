import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import printJS from "print-js";
import moment from "moment";
import {
  Document,
  Text,
  Page,
  View,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import React, { useCallback, useEffect, useMemo } from "react";
import { PDFViewer } from "components/PDFViewer";
import { DispenseOrderDetails, TreatmentOrderItem } from "../types";

interface Props {
  details?: DispenseOrderDetails;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  list: {
    margin: 0,
  },
  picklist: {
    height: "400px",
    backgroundColor: p.grey[100],
    border: `1px solid ${p.grey[500]}`,
    borderRadius: "4px",
    textAlign: "center",
    padding: s(2),
  },
}));

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: 10,
    fontSize: 16,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    marginTop: 1,
    alignItems: "center",
    borderBottom: 1,
    borderColor: "#C4C4C4",
    borderStyle: "solid",
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  leftSection: {
    margin: 10,
    width: "60%",
    flexGrow: 1,
    marginRight: 40,
  },
  rightSection: {
    margin: 10,
    width: "40%",
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  header: {
    paddingBottom: 20,
    borderBottom: 1,
    borderColor: "#C4C4C4",
    borderStyle: "solid",
  },
  small: {
    fontSize: 12,
    paddingLeft: 20,
  },
  maxWidthText: {
    maxWidth: 400,
  },
});

const PicklistPdf: React.FC<{ details?: DispenseOrderDetails }> = ({
  details,
}) => {
  const modifiedItems = useMemo(() => {
    if (!details?.treatmentOrderItems) {
      return [];
    }

    return details.treatmentOrderItems.map((item, index) => ({
      ...item,
      index,
    }));
  }, [details]);

  const pageHeader = useMemo(() => {
    return (
      <View fixed style={styles.header}>
        <View style={styles.section}>
          <Text style={{ fontWeight: 700 }}>
            Patient Name: {details?.treatmentOrder?.Patient?.name}
          </Text>
        </View>
        <View style={styles.headerRow}>
          <View style={styles.leftSection}>
            <Text>
              Order Received Date:{" "}
              <strong>
                {details?.treatmentOrder?.Order_Date
                  ? moment(details.treatmentOrder.Order_Date).format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </strong>
            </Text>
          </View>
          <View style={styles.rightSection}>
            <Text>
              Order ID: <strong>{details?.treatmentOrder?.Name}</strong>
            </Text>
          </View>
        </View>
      </View>
    );
  }, [details]);

  const getItem = useCallback((item: TreatmentOrderItem) => {
    return (
      <View key={item.id} style={styles.row} wrap={false}>
        <View style={styles.leftSection}>
          <View style={styles.maxWidthText}>
            <Text style={{ marginBottom: 4 }}>
              {(item.index || 0) + 1}.{" "}
              {item?.productItem?.Name_For_Prescription} - {item?.Quantity}
            </Text>
            <Text style={styles.small}>{item.Usage_Instructions}</Text>
          </View>
        </View>
        <View style={styles.rightSection}>
          <Text>
            Quantity: <strong>{item.Quantity}</strong>
          </Text>
        </View>
      </View>
    );
  }, []);

  return (
    <Document>
      <Page size={{ width: "816px", height: "408px" }} style={styles.page}>
        {pageHeader}
        {modifiedItems.map((item) => getItem(item))}
      </Page>
    </Document>
  );
};

export const Picklist: React.FC<Props> = ({ details }) => {
  const classes = useStyles();
  const [instance, update] = usePDF({
    document: <PicklistPdf details={details} />,
  });

  const handlePrint = useCallback(() => {
    if (instance.url) {
      printJS({
        printable: instance.url,
        type: "pdf",
      });
    }
  }, [instance.url]);

  const pdfData = useMemo(() => {
    return instance.url ? instance.url : "";
  }, [instance.url]);

  const updatePdf = useCallback(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (details?.treatmentOrderItems) {
      updatePdf();
    }
  }, [details, updatePdf]);

  return (
    <Box>
      <Box mb={3}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography color="primary" variant="h5" component="h2">
              Picklist Preview
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handlePrint} variant="outlined" color="primary">
              Print Picklists
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.picklist}>
        <PDFViewer scale={1} data={pdfData} height={330} />
      </Box>
      <Box mt={2}>
        <Alert severity="info">
          <ul className={classes.list}>
            <li>
              <Typography variant="body2">
                Use the <strong>“Confirm Dispensing and Print Labels”</strong>{" "}
                button if all items are available.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Use the <strong>“Mark Order as Owed”</strong> button if some
                items are missing but will be available within 2 days.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Use the <strong>“Handle Missing Items”</strong> button if some
                items are missing and you need to specify a substitute or
                suggest substitute to processing team and want to make partial
                shipping.
              </Typography>
            </li>
          </ul>
        </Alert>
      </Box>
    </Box>
  );
};
