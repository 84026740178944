import * as Yup from "yup";
import { Formik, FormikConfig } from "formik";
import React, { useCallback, useMemo } from "react";
import { CheckoutPage, FormValues } from "../types";
import {
  AddCheckoutPageDialog,
  AddCheckoutPageDialogProps,
} from "./AddCheckoutPageDialog";

interface AddCheckoutPageFormProps extends AddCheckoutPageDialogProps {
  handleSubmit: (values: FormValues) => Promise<void>;
  checkoutPageToEdit: CheckoutPage | null;
}

export const EditCheckoutPageForm = ({
  handleSubmit,
  onClose,
  checkoutPageToEdit,
  testProfiles,
  ...props
}: AddCheckoutPageFormProps) => {
  const initialValues = useMemo(() => {
    if (checkoutPageToEdit) {
      return {
        name: checkoutPageToEdit.Name,
        labKey: {
          title: checkoutPageToEdit.BloodTestingLab.Key,
          value: checkoutPageToEdit.BloodTestingLab.Key,
        },
        testProfiles: checkoutPageToEdit[
          "MasterZohoCheckoutPageTestProfile List"
        ].map((masterZohoCheckoutPageTestProfile) => {
          const testProfile =
            testProfiles.find(
              (i) =>
                i.Id === masterZohoCheckoutPageTestProfile.MasterTestProfile.Id
            )?.BmhName || "";
          return { title: testProfile, value: testProfile };
        }),
        bloodTakingOption: {
          title: checkoutPageToEdit.BloodTakingOption,
          value: checkoutPageToEdit.BloodTakingOption,
        },
        bloodDrawerName: {
          title: checkoutPageToEdit.BloodDrawer.Name,
          value: checkoutPageToEdit.BloodDrawer.Name,
        },
        bloodDrawFeeCollected: !!checkoutPageToEdit.BloodDrawFeeCollected,
        gender: {
          title: checkoutPageToEdit.Gender === "M" ? "Male" : "Female",
          value: checkoutPageToEdit.Gender,
        },
      };
    }
    return {
      name: "",
      labKey: null,
      testProfiles: [],
      bloodTakingOption: null,
      bloodDrawerName: null,
      bloodDrawFeeCollected: false,
      gender: null,
    };
  }, [checkoutPageToEdit, testProfiles]);

  const validationSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().required().label("Name"),
      labKey: Yup.object()
        .required("Lab key is required")
        .typeError("Lab key is required")
        .test("Select lab key", "Lab key is required", (value) => {
          return !!(value as any)?.value;
        }),
      testProfiles: Yup.array().test(
        "Selected one",
        "Test profiles is required",
        (value) => {
          return !!(value as [])?.length;
        }
      ),
      bloodTakingOption: Yup.object()
        .required("Blood taking option is required")
        .typeError("Blood taking option is required")
        .test(
          "Select blood taking option",
          "Blood taking option is required",
          (value) => {
            return !!(value as any)?.value;
          }
        ),
      bloodDrawerName: Yup.object()
        .nullable()
        .optional()
        .label("Blood drawer name"),
      bloodDrawFeeCollected: Yup.boolean().label("Blood drawer fee collected"),
      gender: Yup.object()
        .required("Gender is required")
        .typeError("Gender is required")
        .test("Select gender", "Gender is required", (value) => {
          return !!(value as any)?.value;
        }),
    });
  }, []);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      try {
        await handleSubmit(values);
        resetForm();
        onClose();
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmit, onClose]
  );

  return (
    <Formik<FormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <AddCheckoutPageDialog
        {...props}
        onClose={onClose}
        testProfiles={testProfiles}
        title="Edit Checkout Page"
      />
    </Formik>
  );
};
