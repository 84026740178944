export type HistoryViewTableField =
  | "collected"
  | "result"
  | "range"
  | "unit"
  | "abnormal";

export const HISTORY_VIEW_TABLE_META: {
  [key in HistoryViewTableField]: {
    label: string;
    sortable?: boolean;
  };
} = {
  collected: {
    label: "Sample Collected Date",
    sortable: true,
  },
  result: {
    label: "Result",
  },
  range: {
    label: "Normal Range",
  },
  unit: {
    label: "Unit",
  },
  abnormal: {
    label: "Abnormal flags",
  },
};
