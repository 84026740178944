import { Dialog, DialogProps } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { zohoServices } from "services";
import { TreatmentWidgetLayoutContext } from "./context";
import { TreatmentWidgetDimension } from "./types";
import { CloseTreatmentWidgetDialog } from "./CloseTreatmentWidgetDialog";

interface Props extends Omit<DialogProps, "open"> {
  cancelDialog?: {
    title: string;
    text: string;
    proceedButtonText: string;
    cancelButtonText: string;
  };
  defaultWidth?: number;
}

const MAX_WIDTH = 1200;
const MIN_WIDTH = 700;
const MAX_HEIGHT = 1000;
export const TreatmentWidgetLayout = ({
  children,
  cancelDialog,
  defaultWidth = 0,
  ...props
}: Props) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [titleWidth, setTitleWidth] = useState(defaultWidth);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(defaultWidth);
  const [actionsHeight, setActionsHeight] = useState(0);
  const [actionsWidth, setActionsWidth] = useState(defaultWidth);
  const [showDialog, setShowDialog] = useState(false);

  const setActionsDimension = useCallback(
    (dimension: TreatmentWidgetDimension) => {
      setActionsHeight(dimension.height);
      setActionsWidth(defaultWidth || dimension.width);
    },
    [defaultWidth]
  );
  const setContentDimension = useCallback(
    (dimension: TreatmentWidgetDimension) => {
      setContentHeight(dimension.height);
      setContentWidth(defaultWidth || dimension.width);
    },
    [defaultWidth]
  );
  const setTitleDimension = useCallback(
    (dimension: TreatmentWidgetDimension) => {
      setTitleHeight(dimension.height);
      setTitleWidth(defaultWidth || dimension.width);
    },
    [defaultWidth]
  );

  const totalWidth = useMemo(() => {
    if (defaultWidth) {
      return defaultWidth;
    }
    const width =
      [titleWidth, contentWidth, actionsWidth].reduce((previous, current) => {
        return current > previous ? current : previous;
      }, 0) + 100;
    const minWidth = width < MIN_WIDTH ? MIN_WIDTH : width;
    return minWidth < MAX_WIDTH ? minWidth : MAX_WIDTH;
  }, [titleWidth, contentWidth, actionsWidth, defaultWidth]);

  const totalHeight = useMemo(() => {
    const height =
      [titleHeight, contentHeight, actionsHeight].reduce(
        (previous, current) => {
          return previous + Number(current);
        },
        0
      ) + 100;

    return height > MAX_HEIGHT ? MAX_HEIGHT : height;
  }, [titleHeight, contentHeight, actionsHeight]);

  const handleClose = useCallback(
    (refresh = false, showDialog_ = false) => {
      if (showDialog_ && !!cancelDialog) {
        setShowDialog(true);
      } else {
        zohoServices.closePopup(refresh);
      }
    },
    [cancelDialog]
  );

  const handleCloseShowDialog = useCallback((showDialog_ = false) => {
    setShowDialog(showDialog_);
  }, []);

  const resize = useCallback(() => {
    try {
      zohoServices
        .resizeWidget({
          height: totalHeight,
          width: totalWidth,
        })
        .then(() => {})
        .catch(() => {});
    } catch (error) {
      //
    }
  }, [totalHeight, totalWidth]);

  useEffect(() => {
    resize();
  }, [resize]);

  return (
    <>
      <TreatmentWidgetLayoutContext.Provider
        value={{
          setActionsDimension,
          setContentDimension,
          setTitleDimension,
          handleClose,
          showDialog,
          handleCloseShowDialog,
        }}
      >
        <Dialog {...props} open fullScreen hideBackdrop>
          {children}
          {cancelDialog ? (
            <CloseTreatmentWidgetDialog
              title={cancelDialog.title}
              text={cancelDialog.text}
              proceedButtonText={cancelDialog.proceedButtonText}
              cancelButtonText={cancelDialog.cancelButtonText}
            />
          ) : null}
        </Dialog>
      </TreatmentWidgetLayoutContext.Provider>
    </>
  );
};
