import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";

import { zohoServices } from "services";
import { useTubeLabelsPrint, useWidgetResize } from "hooks";
import Loader from "components/Loader";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { RecordsLimitExceeded } from "components/RecordsLimitExceeded";

const recordLimit = 200;

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
  },
  text: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    textAlign: "right",
  },
  lastButton: {
    marginLeft: s(1),
  },
}));

const PathwayListPrintTubeLabels = () => {
  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const ids = useSelector(zohoSelectors.getIds);

  const { printPDF } = useTubeLabelsPrint();

  const onProceedClick = useCallback(() => {
    printPDF({
      setLoading,
    });
  }, [printPDF]);

  const isLimitExceeded = useMemo(() => ids.length > recordLimit, [ids]);

  const closeWidget = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  useEffect(() => {
    resize();
  }, [resize, wrapperRef]);

  const classes = useStyle();

  return (
    <WidgetButtonWrapper>
      <Container innerRef={wrapperRef}>
        {isLimitExceeded && (
          <RecordsLimitExceeded
            onClose={closeWidget}
            numberOfRecordsSelected={ids.length}
            maxNumberOfRecordsAllowed={recordLimit}
          />
        )}
        {!isLimitExceeded && (
          <>
            <Loader open={loading} />

            <Typography variant="h5">
              Tube Labels Printing for Multiple Records
            </Typography>

            <Box className={classes.section}>
              <Typography variant="body1" className={classes.text}>
                For each selected order, a Tube Label will be generated for each
                tube, after which you can print the labels. Tubes quantity
                depends on the selected Test Profiles for that order.
              </Typography>
            </Box>

            <Box className={classes.buttons}>
              <Button variant="text" color="primary" onClick={closeWidget}>
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.lastButton}
                onClick={onProceedClick}
              >
                Proceed
              </Button>
            </Box>
          </>
        )}
      </Container>
    </WidgetButtonWrapper>
  );
};

export default PathwayListPrintTubeLabels;
