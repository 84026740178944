import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import {
  BloodTestResult,
  BloodTestResultItem,
  BloodTestResultItemHistory,
  Pathway,
  BloodDrawerTypeEnum,
  BluePrintStagesEnum,
} from "@deep-consulting-solutions/bmh-constants";

import { zohoSelectors } from "redux/zoho";
import {
  fetchBloodTestResultByPathway,
  fetchHistoricalObx,
} from "redux/testResults/requests";
import { pathwayRequests } from "redux/pathway";
import Loader from "components/Loader";

import { LoadingContext } from "./LoadingContext";
import HistoryView from "./HistoryView";
import TestResults from "./TestResults";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    margin: 0,
    padding: s(1),
    height: `calc(100vh - ${s(1)}px)`,
    width: `calc(100vw - ${s(1)}px)`,
    background: p.common.white,
  },
}));

export const TestResultInPathway = () => {
  const ids = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [pathwayRecord, setPathwayRecord] = useState<Pathway | null>(null);
  const [result, setResult] = useState<BloodTestResult | null>(null);
  const [contactZohoID, setContactZohoID] = useState<string | null>(null);
  const [historyOBXID, setHistoryOBXID] = useState<string | null>(null);
  const [historyData, setHistoryData] = useState<{
    [obxID: string]: BloodTestResultItemHistory[];
  }>({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const pathway = await pathwayRequests.fetchPathwayRecord(ids[0]);
      setPathwayRecord(pathway);

      const r = await fetchBloodTestResultByPathway(ids[0]);
      if (r) {
        const { contact, ...data } = r;
        setResult(data);
        setContactZohoID(contact.zohoID);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [ids]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToHistory = useCallback(
    async ({ id }: BloodTestResultItem) => {
      if (!contactZohoID) return;
      if (Object.prototype.hasOwnProperty.call(historyData, id)) {
        setHistoryOBXID(id);
        return;
      }

      setLoading(true);
      try {
        const data = await fetchHistoricalObx(contactZohoID, id);

        setHistoryData((current) => ({
          ...current,
          [id]: data,
        }));
        setHistoryOBXID(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [historyData, contactZohoID]
  );

  const goBackFromHistory = useCallback(() => {
    setHistoryOBXID(null);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const classes = useStyle();
  const hasManualResult =
    pathwayRecord?.bloodDrawerCategory ===
    BloodDrawerTypeEnum.LAB_MANUAL_RESULTS;

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <Container
        data-testid="test-result-in-pathway"
        className={classes.wrapper}
      >
        <Loader open={loading} />
        {!loading &&
          (() => {
            if (
              pathwayRecord &&
              (pathwayRecord.stage ===
                BluePrintStagesEnum.PENDING_MANUAL_RESULTS ||
                (hasManualResult && result))
            ) {
              return (
                <TestResults
                  isPathway
                  pathwayRecord={pathwayRecord}
                  bloodTestResult={result || null}
                />
              );
            }

            if (!hasManualResult && result) {
              if (!historyOBXID)
                return (
                  <TestResults
                    isPathway
                    pathwayRecord={pathwayRecord || undefined}
                    bloodTestResult={result || null}
                  />
                );
              return (
                <HistoryView
                  items={historyData[historyOBXID]}
                  goBack={goBackFromHistory}
                />
              );
            }

            return <div>No blood test result is available</div>;
          })()}
      </Container>
    </LoadingContext.Provider>
  );
};
