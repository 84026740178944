import { Box } from "@material-ui/core";
import React from "react";
import { TreatmentInstructionsField } from "./TreatmentInstructionsField";
import { TreatmentPeriodFields } from "./TreatmentPeriodField";

interface Props {
  name?: string;
}
export const TreatmentFields = ({ name }: Props) => {
  return (
    <Box maxWidth={500}>
      <Box mb={2}>
        <TreatmentInstructionsField name={name} />
      </Box>
      <TreatmentPeriodFields name={name} />
    </Box>
  );
};
