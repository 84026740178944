import React, { memo, useCallback } from "react";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutline as MinusIcon } from "@material-ui/icons";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  btn: {
    color: p.error.main,
    marginLeft: s(1),
  },
}));

interface MinusBtnProps {
  index: number;
  onClick?: (index: number) => any;
}

const MinusBtn: React.FC<MinusBtnProps> = ({ index, onClick }) => {
  const onIconClick = useCallback(() => {
    if (onClick) onClick(index);
  }, [onClick, index]);

  const classes = useStyle();

  return (
    <Tooltip title={onClick ? "Remove" : ""}>
      <IconButton
        className={classes.btn}
        onClick={onIconClick}
        disabled={!onClick}
        size="small"
      >
        <MinusIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(MinusBtn);
