import { Box, Button, makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import {
  FinalizeMatchingMedPackage,
  ZohoMedUsageUnit,
  ZohoProduct,
} from "types";
import {
  getFieldName,
  getValueFromObject,
  sortPackages,
  getBestBillingAndCheapestPackage,
} from "helpers";
import { useFormikContext } from "formik";
import { MatchingPackageForms } from "./MatchingPackageForms";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
}));

interface Props {
  matchingPackages: FinalizeMatchingMedPackage[];
  bacteriostaticProducts: ZohoProduct[];
  name?: string;
  packageFieldName: string;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isMatchingPackagesVisible: boolean;
}

export const MatchingPackagesForms = ({
  matchingPackages,
  bacteriostaticProducts,
  name = "",
  packageFieldName,
  medUsageUnits,
  isMatchingPackagesVisible,
}: Props) => {
  const classes = useStyles();
  const { values, initialValues } = useFormikContext<any>();

  const [showAllPackages, setShowAllPackages] = useState(false);

  const selectedPackage: string = useMemo(
    () => getValueFromObject("selectedPackage", values),
    [values]
  );

  const handleShowPackages = useCallback(() => {
    setShowAllPackages(true);
  }, []);

  const { bestBillingPackage, cheapestPackage } = useMemo(() => {
    return getBestBillingAndCheapestPackage(matchingPackages);
  }, [matchingPackages]);

  const { bestBilling, cheapest, sortedPackages } = useMemo(() => {
    return sortPackages(matchingPackages, cheapestPackage, bestBillingPackage);
  }, [matchingPackages, cheapestPackage, bestBillingPackage]);

  const slicedPackages = useMemo(() => {
    if (
      showAllPackages ||
      (selectedPackage &&
        (cheapest?.id || bestBilling?.id) &&
        selectedPackage !== cheapest?.id &&
        selectedPackage !== bestBilling?.id)
    ) {
      return sortedPackages;
    }

    if (cheapest && bestBilling) {
      if (cheapest.id === bestBilling.id) {
        return [bestBilling];
      }
      return [bestBilling, cheapest];
    }

    if (bestBilling) {
      return [bestBilling];
    }

    if (cheapest) {
      return [cheapest];
    }

    return sortedPackages;
  }, [showAllPackages, cheapest, bestBilling, sortedPackages, selectedPackage]);

  if (!initialValues.matchingPackages?.length) {
    return null;
  }

  return (
    <Box>
      {slicedPackages.map((matchingPackage, index) => (
        <Box
          mt={index === 0 ? 0 : 2}
          key={matchingPackage.id}
          className={classes.wrapper}
        >
          <MatchingPackageForms
            matchingPackage={matchingPackage}
            bacteriostaticProducts={bacteriostaticProducts}
            name={getFieldName(index, name)}
            packageFieldName={packageFieldName}
            isCheapest={matchingPackage.id === cheapestPackage}
            isBestBillingPackage={matchingPackage.id === bestBillingPackage}
            medUsageUnits={medUsageUnits}
            isMatchingPackagesVisible={isMatchingPackagesVisible}
          />
        </Box>
      ))}

      {matchingPackages.length > slicedPackages.length && !showAllPackages ? (
        <Box mt={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleShowPackages}
          >
            SHOW ALL PACKAGES
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
