import { n8nClient } from "apis";
import axios from "axios";
import { getDoctorName, sortAlphabetically } from "helpers";
import { ZohoBMHDoctor } from "types";
import {
  Allergy,
  Contact,
  FormattedProduct,
} from "widgets/CreateManualPrescription/types";

type GetDoctorProductsDetailsResponse = {
  doctors: ZohoBMHDoctor[];
  products: {
    Product_Name: string;
    id: string;
  }[];
};

export async function getDoctorProductsDetails() {
  try {
    const response = await n8nClient.get<GetDoctorProductsDetailsResponse>(
      "/prescriptions/manual-creation/doctor-and-products"
    );

    const { doctors, products } = response.data;
    const formattedDoctors = sortAlphabetically(
      doctors.map((doctor) => ({
        title: getDoctorName(doctor),
        label: getDoctorName(doctor),
        value: doctor.id,
        id: doctor.id,
      }))
    );
    const formattedProducts: FormattedProduct[] = products.map((p) => ({
      id: p.id,
      value: p.id,
      label: p.Product_Name,
      title: p.Product_Name,
    }));

    return { doctors: formattedDoctors, products: formattedProducts };
  } catch (error) {
    return null;
  }
}

export async function getClientData(searchText: string) {
  try {
    const response = await n8nClient.get<{ contacts: Contact[] }>(
      `/search-contact-by-name?name=${searchText}`
    );
    const formatted = response.data.contacts.map((c) => {
      const title = `${c.FirstName ? c.FirstName : ""} ${
        c.LastName ? ` ${c.LastName}` : ""
      } ${c.Email ? ` - ${c.Email}` : ""}`;
      const value = c.Id;
      return {
        ...c,
        title,
        value,
      };
    });
    return formatted;
  } catch (error) {
    return [];
  }
}

export async function createManualPrescription(payload: any) {
  try {
    const response = await n8nClient.post(
      "/prescriptions/manual-creation",
      payload
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getAllergiesList() {
  try {
    const response = await n8nClient.get<{ allergies: Allergy[] }>(
      "/patient/allergies"
    );
    return response.data.allergies;
  } catch (error) {
    return [];
  }
}

export const fetchSignedUrlForUpload = async (fileName: string) => {
  try {
    const payload = {
      resourceType: "documents",
      operation: "upload",
      fileNamePrefix: fileName,
      fileType: "pdf",
    };

    const res = await n8nClient.post<{
      data: { signedUploadUrl: string; s3Key: string };
    }>(`/crm/generate-signed-url`, payload);
    return res.data.data;
  } catch (error) {
    return null;
  }
};

export const uploadFileToS3 = async (file: File, url: string) => {
  try {
    const response = await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(`Failed to upload file: ${error.message}`);
  }
};
