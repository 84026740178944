import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";

export const ActionButtons = ({
  closeWidget,
  createdTreatmentUrl,
  hasAllRequiredFields,
}: {
  closeWidget: (arg?: boolean) => void;
  createdTreatmentUrl: string;
  hasAllRequiredFields: boolean;
}) => {
  const { isValid, submitForm, isSubmitting } = useFormikContext();

  if (createdTreatmentUrl) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gridColumnGap="8px"
        marginY={1}
      >
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "100px",
          }}
          variant="outlined"
          onClick={() => closeWidget(true)}
          color="primary"
        >
          Close
        </Button>
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "100px",
          }}
          variant="contained"
          color="primary"
          component="a"
          href={createdTreatmentUrl}
          target="_blank"
        >
          View Treatment
        </Button>
      </Box>
    );
  }

  return (
    <Box
      marginY={1}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "100px",
        }}
        color="primary"
        variant="outlined"
        onClick={() => closeWidget()}
      >
        {hasAllRequiredFields ? "Cancel" : "Close"}
      </Button>
      {hasAllRequiredFields && (
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "100px",
          }}
          color="primary"
          variant="contained"
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? "Creating..." : "Create"}
        </Button>
      )}
    </Box>
  );
};
