import React, { memo, useMemo, useState, useCallback } from "react";
import { makeStyles, Box, Collapse, IconButton } from "@material-ui/core";
import {
  InsertDriveFile as FileIcon,
  List as ListIcon,
  CalendarToday as CalendarIcon,
  Apartment as CompanyIcon,
  AccountCircle as UserIcon,
  ExpandMore,
} from "@material-ui/icons";
import {
  BloodTestResultInputMethodEnum,
  BloodTestResultWithPathway,
} from "@deep-consulting-solutions/bmh-constants";

import {
  getDateData,
  processSampleCollectedDate,
} from "../../TestResults.helpers";
import InfoItem from "../../InfoItem";
import { ReactComponent as BloodBag } from "./blood-bag.svg";

interface StyleProps {
  isManual: boolean;
  expand: boolean;
  isOnPathway?: boolean;
  hasRelatedPathway?: boolean;
}

const useStyle = makeStyles(({ spacing: s }) => {
  const leftSide = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return {
    wrapper: {
      marginTop: s(2),
    },
    row: {
      width: "100%",
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      paddingLeft: s(1),
      paddingRight: s(1),
      marginBottom: s(1),
    },
    left: {
      width: "70%",
      ...leftSide,
    },
    leftManualLower: {
      width: "100%",
      ...leftSide,
    },
    leftManualUpper: {
      flex: 1,
      ...leftSide,
    },
    right: ({ isManual }: StyleProps) => ({
      width: isManual ? undefined : "30%",
      paddingLeft: s(2),
    }),
    editBtn: {
      marginRight: s(1),
    },
    actions: {
      textAlign: "right",
    },
    openPDFBtn: {
      marginLeft: s(1),
    },
    upperItem: ({ isManual, isOnPathway, hasRelatedPathway }: StyleProps) => {
      if (isOnPathway || isManual) {
        return {
          width: `calc(${hasRelatedPathway ? "20%" : "25%"} - ${s(4 / 3)}px)`,
        };
      }
      return {
        width: `calc(33.33% - ${s(6 / 4)}px)`,
      };
    },
    lowerItem: {
      width: `calc(33.33% - ${s(4 / 3)}px)`,
    },
    companyItem: {
      width: "100%",
    },
    expandWrapper: {
      width: "100%",
      textAlign: "center",
    },
    expand: ({ expand }: StyleProps) => ({
      transition: "0.2s",
      transform: `rotate(${expand ? 180 : 0}deg)`,
    }),
  };
});

interface ResultDetailsInfoProps {
  result: BloodTestResultWithPathway;
  isOnPathway?: boolean;
}

const ResultDetailsInfo: React.FC<ResultDetailsInfoProps> = ({
  result,
  isOnPathway,
}) => {
  const { testProfileCodes } = useMemo(() => {
    const profiles = result.testProfiles || [];
    return {
      testProfileNames: profiles.map((p) => p.name),
      testProfileCodes: profiles
        .map((p) => p.code)
        .filter((c) => !!c) as string[],
    };
  }, [result]);

  const [expand, setExpand] = useState(false);

  const onExpandClick = useCallback(() => {
    setExpand((e) => !e);
  }, []);

  const { date, time, warning } = useMemo(
    () =>
      processSampleCollectedDate(result, {
        isDetailsView: true,
        notFallback: true,
      }),
    [result]
  );

  const isManual =
    result.inputMethod === BloodTestResultInputMethodEnum.manual ||
    result.inputMethod === BloodTestResultInputMethodEnum.pathwayManual;
  const classes = useStyle({
    isManual,
    expand,
    isOnPathway,
    hasRelatedPathway: !!result.relatedBloodTestOrder,
  });

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.row}>
        <InfoItem
          name={result.name}
          withTitle={result.name}
          label="Record Name"
          Icon={FileIcon}
          className={classes.upperItem}
        />
        {!isOnPathway && (
          <>
            {isManual ? (
              <InfoItem
                name={result.labName}
                label="Laboratory Name"
                Icon={CompanyIcon}
                className={classes.upperItem}
              />
            ) : (
              <InfoItem
                name={
                  testProfileCodes.length ? testProfileCodes.join(", ") : "N/A"
                }
                withTitle={testProfileCodes.length ? testProfileCodes : ""}
                label="Test Profiles"
                Icon={ListIcon}
                className={classes.upperItem}
              />
            )}
            {result.relatedBloodTestOrder && (
              <InfoItem
                name={(result.relatedBloodTestOrder as any).sampleID}
                label="Blood Test Order"
                IconElement={<BloodBag width="24px" />}
                className={classes.upperItem}
              />
            )}
            {isManual && (
              <>
                <InfoItem
                  {...getDateData(result.lastUpdateAt || result.createdAt)}
                  label={
                    result.lastUpdateAt ? "Results Edited" : "Results Added"
                  }
                  Icon={CalendarIcon}
                  className={classes.upperItem}
                />
                <InfoItem
                  name={
                    result.lastUpdateBy
                      ? result.lastUpdateBy.name
                      : result.createdBy.name
                  }
                  label={
                    result.lastUpdateAt
                      ? "User who edited results"
                      : "User who added results"
                  }
                  Icon={UserIcon}
                  className={classes.upperItem}
                />
              </>
            )}
            {!isManual && (
              <InfoItem
                name={result.labName}
                label="Laboratory Name"
                Icon={CompanyIcon}
                className={classes.upperItem}
              />
            )}
          </>
        )}
        {isOnPathway && (
          <>
            <InfoItem
              name={date}
              subName={time}
              errorMessage={warning}
              label="Sample Collected"
              Icon={CalendarIcon}
              className={classes.upperItem}
            />
            <InfoItem
              {...getDateData(result.sampleReceivedByLabOn)}
              label="Sample Received by Lab"
              Icon={CalendarIcon}
              className={classes.upperItem}
            />
            <InfoItem
              {...getDateData(result.resultReportedOn)}
              label="Results Reported"
              Icon={CalendarIcon}
              className={classes.upperItem}
            />
          </>
        )}
      </Box>
      {!isOnPathway && (
        <>
          <Collapse in={expand}>
            <Box className={classes.row}>
              <InfoItem
                name={date}
                subName={time}
                errorMessage={warning}
                label="Sample Collected"
                Icon={CalendarIcon}
                className={classes.lowerItem}
              />
              <InfoItem
                {...getDateData(result.sampleReceivedByLabOn)}
                label="Sample Received by Lab"
                Icon={CalendarIcon}
                className={classes.lowerItem}
              />
              <InfoItem
                {...getDateData(result.resultReportedOn)}
                label="Results Reported"
                Icon={CalendarIcon}
                className={classes.lowerItem}
              />
            </Box>
          </Collapse>
          <Box className={classes.expandWrapper}>
            <IconButton
              size="small"
              onClick={onExpandClick}
              title={expand ? "" : "more information"}
            >
              <ExpandMore className={classes.expand} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(ResultDetailsInfo);
