import React, { memo, useCallback } from "react";
import { makeStyles, Typography, Radio, Link } from "@material-ui/core";

import { ConvertedClientRecord } from "../../Unmatched.types";
import { ROW_HEIGHT, getBGHover } from "../../Unmatched.styles";
import { getClientRecordLink } from "../../Unmatched.helpers";

interface StyleProps {
  selected: boolean;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  selectRow: ({ selected }: StyleProps) => ({
    height: `${ROW_HEIGHT}px`,
    display: "flex",
    alignItems: "center",
    paddingLeft: s(1),
    paddingRight: s(1),
    transition: "0.2s",
    background: selected ? p.grey[200] : undefined,

    "&:hover": {
      background: getBGHover(p),
    },
  }),
  radio: {
    marginLeft: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface PossibleClientRowProps {
  client: ConvertedClientRecord;
  selected: boolean;
  onSelect: (client: ConvertedClientRecord) => any;
  disableUpdate: boolean;
}

const PossibleClientRow: React.FC<PossibleClientRowProps> = ({
  client,
  selected,
  onSelect,
  disableUpdate,
}) => {
  const onRowClick = useCallback(() => {
    onSelect(client);
  }, [onSelect, client]);

  const classes = useStyle({ selected });

  return (
    <div className={classes.selectRow} data-testid="possible-client">
      <Link href={getClientRecordLink(client)} target="_blank">
        <Typography>
          {client.firstName} {client.lastName}
        </Typography>
      </Link>
      <Radio
        onClick={onRowClick}
        className={classes.radio}
        color="primary"
        size="small"
        checked={selected}
        disabled={disableUpdate}
      />
    </div>
  );
};

export default memo(PossibleClientRow);
