import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";
import { InitialValues } from "./types";

export const ActionButtons = () => {
  const { submitForm, isSubmitting, values } = useFormikContext();

  const orderItems: InitialValues["orderItems"] = getIn(values, "orderItems");
  const noneSelected = orderItems.every((i) => !i.isSelected);

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting || noneSelected}
      >
        {isSubmitting ? "Creating..." : "Save"}
      </Button>
    </Box>
  );
};
