import React from "react";
import { Grid, Box, Divider, Typography, makeStyles } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import { Field, getIn, useFormikContext } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { CustomNumberField } from "components/CustomNumberField";
import { CustomSelectField } from "components/CustomSelectField";
import { BundleItem, InitialValues } from "../types";
import { filterNeedleOptions, needleOptions } from "../constants";
import {
  getAlcoholWipesQuantityString,
  getInjectionBundleQuantityString,
} from "../utils";

interface InjectionDetailsProps {
  fieldName: string;
  hasExtraSyringe?: boolean;
}

interface BundleDetailsProps {
  fieldName: string;
}

const useStyles = makeStyles(({ palette: p }) => ({
  checkbox: {
    color: p.primary.main,
  },
}));

export const InjectionDetails = ({
  fieldName,
  hasExtraSyringe,
}: InjectionDetailsProps) => {
  const classes = useStyles();

  const { values } = useFormikContext<InitialValues>();
  const currentBundleDetails: BundleItem = getIn(values, fieldName);
  const syringeVolume = hasExtraSyringe
    ? currentBundleDetails.extraSyringeVolume
    : currentBundleDetails.syringeVolume;
  const syringeType = hasExtraSyringe
    ? currentBundleDetails.extraSyringeType
    : currentBundleDetails.syringeType;
  const numberOfDoses = currentBundleDetails.numberOfDoses;
  const quantityString = numberOfDoses
    ? getInjectionBundleQuantityString(numberOfDoses)
    : "";
  const wipesQuantityString = numberOfDoses
    ? getAlcoholWipesQuantityString(numberOfDoses)
    : "";
  const showQuantity = typeof numberOfDoses === "number" && numberOfDoses > 1;

  return (
    <Grid container>
      <Grid xs={3} item>
        <Box mb={2}>
          <Grid container>
            <Grid xs={6} item>
              <TitleValue
                title={`${hasExtraSyringe ? "Extra " : ""}Syringe Volume`}
                value={syringeVolume || "N/A"}
              />
            </Grid>
            <Grid xs={6} item>
              <TitleValue title="Syringe Type" value={syringeType || "N/A"} />
            </Grid>
          </Grid>
        </Box>
        {showQuantity && <TitleValue title="Quantity" value={quantityString} />}
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid xs={3} item>
        <Box mb={2} maxWidth={500}>
          <CustomSelectField
            label="Needle Filter"
            name={`${fieldName}.${
              hasExtraSyringe ? "extraNeedleFilter" : "needleFilter"
            }`}
            options={filterNeedleOptions}
          />
        </Box>
        {showQuantity && <TitleValue title="Quantity" value={quantityString} />}
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid xs={3} item>
        <Box mb={2} maxWidth={500}>
          <CustomSelectField
            label="Needle"
            name={`${fieldName}.${hasExtraSyringe ? "extraNeedle" : "needle"}`}
            options={needleOptions}
          />
        </Box>
        {showQuantity && <TitleValue title="Quantity" value={quantityString} />}
      </Grid>
      {!hasExtraSyringe && (
        <>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Box mb={2}>
              <Field
                name={`${fieldName}.includeAlcoholWipes`}
                component={CheckboxWithLabel}
                type="checkbox"
                className={classes.checkbox}
                Label={{
                  label: (
                    <Typography variant="body2">
                      Include Alcohol Wipes
                    </Typography>
                  ),
                }}
                color="primary"
              />
            </Box>
            {showQuantity && currentBundleDetails.includeAlcoholWipes && (
              <TitleValue title="Quantity" value={wipesQuantityString} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const BundleDetails = ({ fieldName }: BundleDetailsProps) => {
  const { values } = useFormikContext<InitialValues>();
  const currentBundleDetails: BundleItem = getIn(values, fieldName);
  const { isHCG } = currentBundleDetails;

  return (
    <Box style={{ minHeight: "200px", width: "100%" }}>
      <Box mb={4} maxWidth="250px">
        <CustomNumberField
          label="Number of Doses"
          name={`${fieldName}.numberOfDoses`}
        />
      </Box>
      <Box mb={4}>
        <InjectionDetails fieldName={fieldName} />
      </Box>
      {isHCG && <InjectionDetails fieldName={fieldName} hasExtraSyringe />}
    </Box>
  );
};
