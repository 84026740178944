export const treatmentOrderBaseUrl = `${process.env.REACT_APP_ZOHO_BASE_URL}/${process.env.REACT_APP_ZOHO_TAB_TREATMENT_ORDERS}`;

export const outOfProgressStates = [
  "Delivered",
  "Returned",
  "Disposed",
  "Cancelled",
  "Prescription Sent",
];

export const radioOptions = [
  {
    value: "cancel",
    label: "Order should be cancelled",
  },
  {
    value: "complete",
    label: "Order should be completed",
  },
];
