import {
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  DialogActions,
  Button,
  makeStyles,
  DialogContent,
  fade,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Loader from "components/Loader";
import React, { useCallback, useState, MouseEventHandler } from "react";

interface Props extends DialogProps {
  handleReplaceExistingFiles: () => Promise<boolean>;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  replaceBtn: {
    background: p.error.main,
    color: "white",
    border: `1px solid ${fade(p.error.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${p.error.main}`,
      background: p.error.dark,
    },
    "&:disabled": {
      border: `1px solid ${p.error.light}`,
      background: p.error.light,
      color: "white",
    },
  },
  actionText: { color: p.error.main },
  title: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
}));

export const ReplaceExistingDialog = ({
  handleReplaceExistingFiles,
  onClose,
  ...props
}: Props) => {
  const [loading, setLoading] = useState(false);
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose]
  );

  const onReplace = useCallback(async () => {
    try {
      setLoading(true);
      await handleReplaceExistingFiles();
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [handleReplaceExistingFiles]);

  const classes = useStyle();
  return (
    <Dialog {...props} onClose={onClose}>
      <Loader open={loading} />
      <DialogTitle className={classes.title}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">Replace Existing Results</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>
          This will completely wipe the existing results associated with this
          blood test order and put the new file&apos;s results in their place.
          <br />
          Every current marker line item and their results will be deleted, even
          if there is no alternative result for a marker in the new file. The
          Blood test will only have the results contained in the new file
          associated with it.
          <br />
          <i>
            <u>
              Use this option only if the old file&apos;s results were erroneous
              and need to be discarded completely
            </u>
          </i>
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Grid container justify="space-between" spacing={2} alignItems="center">
          <Grid item>
            <Typography className={classes.actionText}>
              Warning! This action will be irreversible
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" spacing={2} alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onReplace}
                  className={classes.replaceBtn}
                >
                  REPLACE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
