/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Paper, Box, Typography, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import { zohoServices } from "services";

import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  getTreatmentAlt,
  getPrescriptions,
  getLocationData,
  getPermissions,
  getUpcomingConsultations,
  getVenesectionTreatmentSigningUrl,
} from "services/signing";
import { Treatment, Prescription } from "types";
import { domain } from "configs/app";
import { documentIsPossiblySignedMessages } from "configs";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(0, 2),
    background: p.common.white,
    margin: s(0),
  },
}));

export const SignVenesectionTreatment = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasPermissions, setHasPermissions] = useState(false);
  const [treatment, setTreatment] = useState<Treatment | null>(null);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [signingUrl, setSigningUrl] = useState("");
  const [consultationText, setConsultationText] = useState("");

  const ids = useSelector(zohoSelectors.getIds);
  const user = useSelector(zohoSelectors.getCurrentUser);
  const userId = user?.id as string;

  const isPendingDoctorSigning = useMemo(
    () => treatment?.State === "Pending Doctor Signing",
    [treatment?.State]
  );

  const isVenesectionTreatment = useMemo(
    () => treatment?.Type === "Venesection Treatment",
    [treatment?.Type]
  );

  const areAllDocsSigned = useMemo(
    () => prescriptions.every((p) => p.isSigned),
    [prescriptions]
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyle();

  const resizeWidget = useCallback(() => {
    zohoServices
      .resizeWidget({
        width: 1400,
        height: 700,
      })
      .then(() => {
        setTimeout(() => {
          if (!loading && wrapperRef?.current?.offsetHeight) {
            let height = 700;

            if (!isPendingDoctorSigning || !hasPermissions) {
              height = wrapperRef.current.offsetHeight;
            }

            zohoServices.resizeWidget({
              width: !isPendingDoctorSigning || !hasPermissions ? 1000 : 1400,
              height,
            });
          }
        }, 100);
      });
  }, [hasPermissions, isPendingDoctorSigning, loading]);

  const fetchTreatment = useCallback(async (treatmentId: string) => {
    const rawTreatment = await getTreatmentAlt(treatmentId);

    setTreatment(rawTreatment);
    return rawTreatment;
  }, []);

  const fetchPrescriptions = useCallback(async (treatmentId: string) => {
    const prescriptionsRes = await getPrescriptions(treatmentId);
    setPrescriptions(prescriptionsRes);
    return prescriptionsRes;
  }, []);

  const fetchUpcomingConsultation = useCallback(async () => {
    const treatmentId = ids[0];
    const consultationTextData = await getUpcomingConsultations(treatmentId);
    setConsultationText(consultationTextData);
    return consultationTextData;
  }, [ids]);

  const getData = useCallback(async () => {
    const treatmentId = ids[0];
    try {
      setLoading(true);
      setErrorMessage("");

      const [
        location,
        rawTreatment,
        hasPermissionsRes,
        rawPrescriptions,
      ] = await Promise.all([
        getLocationData(),
        fetchTreatment(treatmentId),
        getPermissions(treatmentId, userId),
        fetchPrescriptions(treatmentId),
      ]);

      if (rawTreatment?.State !== "Pending Doctor Signing") {
        return;
      }

      if (rawTreatment.Type !== "Venesection Treatment") {
        return;
      }

      setHasPermissions(hasPermissionsRes);

      if (!hasPermissionsRes) {
        return;
      }

      const prescriptionList = rawPrescriptions.map((p) => ({
        ...p,
        id: p.id,
        label: p.label,
        type: p.type,
        isSigned: !!p.Signing_Date,
      }));

      setPrescriptions(prescriptionList);

      // Check if all documents has been signed
      const allDocsSigned = prescriptionList.every((d) => {
        return d.isSigned;
      });

      if (allDocsSigned) {
        return;
      }

      const prescription = prescriptionList[0];

      if (location) {
        if (prescription.isSigned) {
          setErrorMessage("Document has been signed");
          return;
        }
        if (!prescription.isSigned) {
          const payload = {
            treatmentId,
            prescriptionId: prescription.id,
            locationInfo: location,
            userId,
          };
          const {
            signingUrlResponse,
            error: signingError,
            message: signingErrorMessage,
          } = await getVenesectionTreatmentSigningUrl(payload);

          if (signingError) {
            const errMessage = documentIsPossiblySignedMessages.includes(
              signingErrorMessage
            )
              ? "Document has been signed"
              : "Error happened fetching this document";
            setErrorMessage(errMessage);
            return;
          }

          if (signingUrlResponse?.data.status === "AwaitingSignatory") {
            setSigningUrl(signingUrlResponse.data.signingUrl);
            return;
          }
        }
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [fetchPrescriptions, fetchTreatment, ids, userId]);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    fetchUpcomingConsultation();
  }, [fetchUpcomingConsultation]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      {loading && <Loader open />}
      {!loading && !isVenesectionTreatment && (
        <Box paddingY={2} paddingX={1}>
          <Alert severity="warning">
            The Treatment must be
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: "bold", margin: "0px 4px" }}
            >
              Venesection Treatment
            </Typography>
            to use this widget.
          </Alert>
        </Box>
      )}
      {!loading && !isPendingDoctorSigning && (
        <Box paddingY={2} paddingX={1}>
          <Alert severity="warning">
            The Treatment must be
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: "bold", margin: "0px 4px" }}
            >
              Pending Doctor Signing
            </Typography>
            to use this widget.
          </Alert>
        </Box>
      )}
      {!loading &&
        isPendingDoctorSigning &&
        isVenesectionTreatment &&
        !hasPermissions && (
          <Box paddingY={2} paddingX={1}>
            <Alert severity="warning">
              <AlertTitle>
                You do not have the neccessary permissions to use this widget.
              </AlertTitle>
              Only the doctor assigned to this treatment can sign the
              prescription document.
            </Alert>
          </Box>
        )}
      {isPendingDoctorSigning && hasPermissions && isVenesectionTreatment && (
        <Paper
          variant="outlined"
          style={{
            display: "grid",
            width: "100%",
            height: "668px",
            margin: "16px auto",
            gridTemplateRows: "auto 1fr",
          }}
        >
          <div
            style={{
              background: "transparent",
            }}
          >
            {!loading && consultationText && (
              <Box padding={1}>
                <Alert severity="info" style={{ marginTop: "8px" }}>
                  {consultationText}
                </Alert>
              </Box>
            )}
          </div>
          <div style={{ background: "transparent" }}>
            {errorMessage && (
              <Box
                height="570px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography style={{ padding: "8px", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              </Box>
            )}
            {!loading && areAllDocsSigned ? (
              <Box
                height="570px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography style={{ padding: "8px", textAlign: "center" }}>
                  Document is signed
                </Typography>
              </Box>
            ) : (
              <>
                {signingUrl && (
                  <iframe
                    title="Document Signing"
                    src={`${signingUrl}?frameorigin=${domain}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      background: "#EFEFEF",
                    }}
                  />
                )}
              </>
            )}
          </div>
        </Paper>
      )}
    </div>
  );
};
