import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface TitleValueProps {
  title: string;
  value?: string | number | JSX.Element;
  singleLine?: boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "0.875rem",
    color: theme.palette.grey[600],
    whiteSpace: "nowrap",
  },
}));

export const TitleValue: React.FC<TitleValueProps> = ({
  title,
  value,
  singleLine = false,
}) => {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      <span className={classes.title}>{title}</span>
      {!singleLine && <br />}
      {value}
    </Typography>
  );
};
