import { Box, Grid } from "@material-ui/core";
import React from "react";
import { DBPharmacyProduct } from "types";
import { NumberInputField } from "components/Treatments";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import {
  ProductType,
  TreatmentOrderItem,
  useStyles,
} from "widgets/DispenseDrugs";
import { OrderItemDispensedUnits } from "components/Dispensing";
import { ReplacementField } from "./ReplacementField";

interface ReplaceProps {
  fieldName: string;
  replacements: DBPharmacyProduct[];
  item: TreatmentOrderItem;
  manufacturers: string[];
}

export const Replace: React.FC<ReplaceProps> = ({
  fieldName,
  replacements,
  item,
  manufacturers,
}) => {
  const classes = useStyles();
  return (
    <Box ml={4}>
      <Grid container>
        <Grid item xs={4}>
          <ReplacementField
            label="Replacement"
            fieldName={`${fieldName}.replacement`}
            replacements={replacements}
            item={item}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInputField
            label="Replacement Quantity"
            name={`${fieldName}.replacementQuantity`}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            className={classes.text}
            name={`${fieldName}.replacementUsageNotes`}
            label="Replacement Usage Notes"
            size="small"
            required
            multiline
            rows={2}
            fullWidth
          />
        </Grid>
      </Grid>
      {item.productItem?.Product_Type !== ProductType.INJECTION_BUNDLE && (
        <Box mt={2}>
          <OrderItemDispensedUnits
            manufacturers={manufacturers}
            quantity={item.Quantity}
            fieldName={fieldName}
            item={item}
          />
        </Box>
      )}
    </Box>
  );
};
