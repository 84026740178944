import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Contact } from "types";
import { zohoSelectors } from "redux/zoho";
import { fetchClientsFromZohoIDs } from "redux/contacts/requests";

export const useGetClient = ({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [client, setClient] = useState<Contact>();
  const ids = useSelector(zohoSelectors.getIds);
  const id = ids[0];

  const getClient = useCallback(async () => {
    setLoading(true);

    try {
      const data = await fetchClientsFromZohoIDs(id);
      setClient(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id, setLoading]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return {
    id,
    client,
  };
};
