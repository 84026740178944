import { Box, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useMemo } from "react";
import { getInfoComponentText } from "../helpers";
import { RetestErrorEnum } from "../types";

const useStyle = makeStyles(() => ({
  alert: {
    fontWeight: 500,
    "& .MuiAlert-icon": {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
}));

interface Props {
  variant: RetestErrorEnum;
}

export const InfoComponent = ({ variant }: Props) => {
  const classes = useStyle();

  const info = useMemo(() => getInfoComponentText(variant), [variant]);

  return (
    <Box mt={4}>
      <Alert
        severity="info"
        icon={<Info fontSize="inherit" />}
        className={classes.alert}
      >
        {info}
      </Alert>
    </Box>
  );
};
