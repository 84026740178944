import { Alert, AlertTitle, Color } from "@material-ui/lab";
import { AxiosError } from "axios";
import { DispenseOrderActions } from "components/Dispensing";
import Loader from "components/Loader";
import { Formik, FormikConfig } from "formik";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import {
  clearSavedDefaultValues,
  getChangeNote,
  getDispenseOrderValidationSchema,
  getDispensePayload,
  getInitialItemsValues,
  getMainAndSupplementaryItems,
  getMedicineLabelsData,
  getSavedDefaultValues,
  getSendToBMHPayload,
} from "helpers";
import { DBPharmacyProduct } from "types";
import { DefaultValuesStore } from "components/DefaultValuesStore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { dispenseDrugsServices, notifications, zohoServices } from "services";
import {
  DispenseOrderDetails,
  DispenseOrderPages,
  ParentOrderItem,
  TreatmentOrderItem,
} from "../types";
import { Picklist } from "./Picklist";
import { ConfirmDispensing } from "./ConfirmDispensing";
import { HandleMissingItems } from "./HandleMissingItems";
import { MedicineLabels } from "./MedicineLabels";
import MedicineLabelsAndNotes from "./MedicineLabelsAndChangeNotes";
import { CurrentView } from "./CurrentView";

const dummyValues = {
  barcodeInputMode: "scanner",
  shouldSendToBMH: false,
  mainItems: [],
  supplementaryItems: [],
  currentPage: DispenseOrderPages.PICKLIST,
};

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const DispenseOrder: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [currentFormView, setCurrentFormView] = useState<DispenseOrderPages>(
    DispenseOrderPages.PICKLIST
  );
  const [details, setDetails] = useState<DispenseOrderDetails>();
  const [mainItems, setMainItems] = useState<TreatmentOrderItem[]>([]);
  const [supplementaryItems, setSupplementaryItems] = useState<
    TreatmentOrderItem[]
  >([]);
  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [replacementProducts, setReplacementProducts] = useState<
    DBPharmacyProduct[]
  >([]);
  const [medicineLabels, setMedicineLabels] = useState("");
  const [deliveryChangeNoteBase64, setDeliveryChangeNoteBase64] = useState("");
  const [fetchedAllData, setIsFetchedAllData] = useState(false);
  const [id] = useSelector(zohoSelectors.getIds);

  const initialValues = useMemo(() => {
    if (!details || !fetchedAllData) {
      return dummyValues;
    }
    const savedDefaults = getSavedDefaultValues("dispense-order", id, [
      details.treatmentOrder.Modified_Time,
    ]);
    if (savedDefaults) {
      return savedDefaults;
    }
    return {
      barcodeInputMode: "scanner",
      shouldSendToBMH: false,
      mainItems: getInitialItemsValues(
        mainItems as ParentOrderItem[],
        replacementProducts
      ),
      supplementaryItems: getInitialItemsValues(
        supplementaryItems as ParentOrderItem[],
        replacementProducts
      ),
      currentPage: DispenseOrderPages.PICKLIST,
    };
  }, [
    mainItems,
    supplementaryItems,
    replacementProducts,
    details,
    id,
    fetchedAllData,
  ]);

  const validationSchema = useMemo(() => {
    return getDispenseOrderValidationSchema(currentFormView);
  }, [currentFormView]);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    return currentFormView;
  }, [fetchError, currentFormView]);

  const updateCurrentPage = useCallback(
    (page: DispenseOrderPages) => () => {
      setCurrentFormView(page);
    },
    []
  );

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [orderDetails, allManufacturers] = await Promise.all([
        dispenseDrugsServices.fetchTreatmentOrders(id),
        dispenseDrugsServices.fetchManufacturers(),
      ]);

      setDetails(orderDetails);
      setManufacturers(allManufacturers);

      const {
        mainItems: mItems,
        supplementaryItems: sItems,
      } = getMainAndSupplementaryItems(orderDetails);
      setMainItems(mItems);
      setSupplementaryItems(sItems);
      setIsFetchedAllData(true);
    } catch (error) {
      setFetchError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchReplacementProducts = useCallback(async () => {
    try {
      const products = await dispenseDrugsServices.fetchReplacementProducts(
        details?.treatmentOrder.Pharmacy_Name || ""
      );
      setReplacementProducts(products);
    } catch (error) {
      const err = error as AxiosError;
      notifications.notifyError(err.response?.data.message || err.message);
    }
  }, [details]);

  const submitDetails = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const addMissingQuantity =
          currentFormView ===
          DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES;
        const payload = getDispensePayload(
          values,
          mainItems,
          supplementaryItems,
          details!.treatmentOrder,
          addMissingQuantity
        );
        const res = await dispenseDrugsServices.printFinalCheck(payload);
        if (res.error) {
          throw new Error(res.message);
        }

        const printLabelsWindow = window.open("", "_blank");
        if (printLabelsWindow) {
          printLabelsWindow.document.write(
            `<iframe id="labels" width="100%" height="100%" src="${medicineLabels}"></iframe>`
          );
          printLabelsWindow.document.title = "Medicine Labels";
        }

        if (
          currentFormView ===
          DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES
        ) {
          const changeNoteWindow = window.open("", "_blank");
          if (changeNoteWindow) {
            changeNoteWindow.document.write(
              `<iframe id="notes" width="100%" height="100%" src="${deliveryChangeNoteBase64}"></iframe>`
            );
            changeNoteWindow.document.title = "Delivery Change Note";
          }
        }

        clearSavedDefaultValues("dispense-order", id);
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        const msg =
          err.response?.status === 500
            ? "Sorry, something went wrong. Contact support."
            : err.response?.data.message;
        notifications.notifyError(msg);
      } finally {
        setLoading(false);
      }
    },
    [
      details,
      mainItems,
      supplementaryItems,
      medicineLabels,
      deliveryChangeNoteBase64,
      currentFormView,
      id,
    ]
  );

  const confirmDispensingAndPrintLabels = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const labelsModel = getMedicineLabelsData(
          values,
          mainItems,
          supplementaryItems,
          replacementProducts,
          details!.treatmentOrder
        );

        const res = await dispenseDrugsServices.fetchMedicineLabels(
          labelsModel,
          details!.treatmentOrder.id
        );
        setMedicineLabels(res.url);
        const changeNote = getChangeNote(
          values,
          mainItems,
          supplementaryItems,
          replacementProducts,
          details!.treatmentOrder
        );
        setDeliveryChangeNoteBase64(changeNote);
        setCurrentFormView(DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [details, mainItems, supplementaryItems, replacementProducts]
  );

  const printLabels = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const labelsModel = getMedicineLabelsData(
          values,
          mainItems,
          supplementaryItems,
          replacementProducts,
          details!.treatmentOrder
        );

        const res = await dispenseDrugsServices.fetchMedicineLabels(
          labelsModel,
          details!.treatmentOrder.id
        );
        setMedicineLabels(res.url);
        setCurrentFormView(DispenseOrderPages.MEDICINE_LABELS);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [details, mainItems, supplementaryItems, replacementProducts]
  );

  const sendToBmh = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const payload = getSendToBMHPayload(
          values,
          mainItems,
          supplementaryItems,
          details!.treatmentOrder
        );

        await dispenseDrugsServices.sendToBmh(payload);
        clearSavedDefaultValues("dispense-order", id);
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [details, mainItems, supplementaryItems, id]
  );

  const markOrderAsOwed = useCallback(async () => {
    try {
      setLoading(true);
      await dispenseDrugsServices.markAsOwed(details!.treatmentOrder.id);
      zohoServices.closePopup(true);
    } catch (error) {
      const err = error as AxiosError;
      notifications.notifyError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [details]);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      await submitDetails(values);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  useEffect(() => {
    if (details?.treatmentOrder.Pharmacy_Name) {
      fetchReplacementProducts();
    }
  }, [details, fetchReplacementProducts]);

  const getChangesRequested = useCallback(
    (severity: Color) => {
      if (details?.treatmentOrder.Changes_Requested) {
        return (
          <Box mb={2}>
            <Alert severity={severity}>
              <AlertTitle>Pharmacist requested changes.</AlertTitle>
              <Typography>
                Requested Changes:
                <br />
                <strong>{details?.treatmentOrder.Requested_Changes}</strong>
              </Typography>
            </Alert>
          </Box>
        );
      }
      return null;
    },
    [details]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validationContext={initialValues}
      enableReinitialize
    >
      <>
        <DefaultValuesStore widgetName="dispense-order" identifier={id} />
        <CurrentView currentView={currentFormView} />
        <TreatmentWidgetLayout defaultWidth={1200}>
          <TreatmentWidgetTitle title="Dispense Order" />
          <TreatmentWidgetContent>
            <Loader open={loading} />
            {view === "fetch-error" ? (
              <Alert severity="error">
                <AlertTitle>An error occurred while fetching data.</AlertTitle>
                {fetchError}
              </Alert>
            ) : null}
            {view === DispenseOrderPages.PICKLIST ? (
              <>
                {getChangesRequested("info")}
                <Picklist details={details} />
              </>
            ) : null}
            {view === DispenseOrderPages.CONFIRM_DISPENSING ? (
              <>
                {getChangesRequested("warning")}
                <ConfirmDispensing
                  mainItems={mainItems}
                  supplementaryItems={supplementaryItems}
                  manufacturers={manufacturers}
                />
              </>
            ) : null}
            {view === DispenseOrderPages.HANDLE_MISSING_ITEMS ? (
              <>
                {getChangesRequested("warning")}
                <HandleMissingItems
                  mainItems={mainItems}
                  supplementaryItems={supplementaryItems}
                  manufacturers={manufacturers}
                  treatmentOrder={details?.treatmentOrder}
                  replacements={replacementProducts}
                />
              </>
            ) : null}
            {view === DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES ? (
              <MedicineLabelsAndNotes
                medicineLabels={medicineLabels}
                updateCurrentPage={updateCurrentPage}
                deliveryChangeNoteBase64={deliveryChangeNoteBase64}
              />
            ) : null}
            {view === DispenseOrderPages.MEDICINE_LABELS ? (
              <MedicineLabels
                medicineLabels={medicineLabels}
                updateCurrentPage={updateCurrentPage}
              />
            ) : null}
          </TreatmentWidgetContent>
          <TreatmentWidgetActions containerClass={classes.actions}>
            <DispenseOrderActions
              updateCurrentPage={updateCurrentPage}
              markOrderAsOwed={markOrderAsOwed}
              onSubmit={submitDetails}
              confirmDispensingAndPrintLabels={confirmDispensingAndPrintLabels}
              printLabels={printLabels}
              sendToBmh={sendToBmh}
              view={view}
            />
          </TreatmentWidgetActions>
        </TreatmentWidgetLayout>
      </>
    </Formik>
  );
};
