import { Button, Grid } from "@material-ui/core";
import React from "react";
import { PageEnum } from "./types";

interface Props {
  page: PageEnum;
  loading: boolean;
  onHandleManually: () => void;
  handleUpdateToPendingItemsCreation: () => void;
  onDoneClick: () => void;
}

export const Actions = ({
  page,
  loading,
  onHandleManually,
  handleUpdateToPendingItemsCreation,
  onDoneClick,
}: Props) => {
  return (
    <Grid
      container
      spacing={2}
      justify="flex-end"
      alignItems="center"
      direction="row"
      wrap="nowrap"
    >
      {page === PageEnum.Error && (
        <Grid item>
          <Button
            color="primary"
            onClick={handleUpdateToPendingItemsCreation}
            disabled={loading}
          >
            Okay
          </Button>
        </Grid>
      )}
      {page === PageEnum.Success && (
        <Grid item>
          <Button color="primary" disabled={loading} onClick={onDoneClick}>
            Okay
          </Button>
        </Grid>
      )}
      {page === PageEnum.CreateInvoiceError && (
        <Grid item>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={onHandleManually}
            color="primary"
          >
            Okay, I will create the invoice manually
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
