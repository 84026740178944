import { format, addMinutes } from "date-fns";

import {
  DATE_DISPLAY_FORMAT,
  DATE_REQUEST_FORMAT,
  TIME_DISPLAY_FORMAT,
  TIME_REQUEST_FORMAT,
} from "configs";

export const getLabelDate = (d: Date) => {
  return addMinutes(d, d.getTimezoneOffset());
};

export const formatDateToDisplay = (str: string) => {
  return format(getLabelDate(new Date(str)), DATE_DISPLAY_FORMAT);
};

export const formatDateTimeToDisplayInCompanyTZ = (str: string) => {
  try {
    const date = new Date(str);
    return {
      date: format(date, DATE_DISPLAY_FORMAT),
      time: format(date, TIME_DISPLAY_FORMAT),
    };
  } catch {
    return {
      date: "",
      time: "",
    };
  }
};

export const formatDateForRequest = (d: Date) => {
  return format(d, DATE_REQUEST_FORMAT);
};

export const formatTimeForRequest = (d: Date) => {
  return format(d, TIME_REQUEST_FORMAT);
};
