import { SendGridStatusEnum } from "types";

export const isFailedSendGridEmail = (status: SendGridStatusEnum) => {
  return (
    status === SendGridStatusEnum.bounce ||
    status === SendGridStatusEnum.deferred ||
    status === SendGridStatusEnum.dropped
  );
};

export const splitEmails = (str: string) =>
  str
    .replace(/\s/g, "")
    .split(",")
    .filter((e) => !!e);
