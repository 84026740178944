import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import { getFieldName } from "helpers";
import React from "react";
import { ZohoBMHDoctor } from "types";
import { TextField } from "formik-material-ui";

interface Props {
  doctor?: ZohoBMHDoctor | null;
  name?: string;
  requestedChanges: string;
}

export const RequestedChanges = ({ doctor, name, requestedChanges }: Props) => {
  if (!doctor?.id) {
    if (!requestedChanges) {
      return null;
    }
    return (
      <Box mb={2}>
        <Typography>Requested Changes:</Typography>
        <Typography>
          <strong>{requestedChanges}</strong>
        </Typography>
      </Box>
    );
  }
  return (
    <Box mb={2} maxWidth={500}>
      <Field
        component={TextField}
        name={getFieldName("reasonForChanges", name)}
        label="Reason for Changes"
        multiline
        rows={3}
        size="small"
        fullWidth
      />
    </Box>
  );
};
