import React, { memo, useCallback } from "react";
import { Checkbox, CheckboxProps } from "@material-ui/core";

interface Props extends Omit<CheckboxProps, "onClick"> {
  name: any;
  onFilterClick: (name: any) => any;
}

export const TableFilterCheckbox = ({
  name,
  onFilterClick,
  checked,
  ...checkboxProps
}: Props) => {
  const onCheckboxClick = useCallback(() => {
    onFilterClick(name);
  }, [name, onFilterClick]);

  return (
    <Checkbox
      onClick={onCheckboxClick}
      checked={checked}
      color={checked ? "primary" : "secondary"}
      {...checkboxProps}
    />
  );
};

export const PureTableFilterCheckbox = memo(TableFilterCheckbox);
