import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import { CustomTextareaField } from "components/CustomTextareaField";
import { CustomCheckboxField } from "components/CustomCheckboxField";
import { Alert } from "@material-ui/lab";
import { Pharmacy, TreatmentOrder } from "./types";

export const CancelTreatmentOrderForm = ({
  treatmentOrder,
  pharmacy,
}: {
  treatmentOrder: TreatmentOrder | null;
  pharmacy: Pharmacy | null;
}) => {
  const { values } = useFormikContext();
  const shouldCreditOrRefund = getIn(values, "shouldCreditOrRefund");

  const infoText = useMemo(() => {
    const isPaid = treatmentOrder?.PaymentStatus === "Paid";
    const isHormonist = pharmacy?.Name === "The Hormonist";
    const isPharmacyOrder = treatmentOrder?.Type === "Pharmacy";
    const isPrescriptionOrder = treatmentOrder?.Type === "Prescription";

    if (isPaid && isPharmacyOrder) {
      if (isHormonist && shouldCreditOrRefund) {
        return `
        A zoho desk ticket will be created to the finance department to refund or credit the related 
        invoice and a desk ticket will be created to inform The Hormonist about the cancellation.
        `;
      }
      if (isHormonist && !shouldCreditOrRefund) {
        return "A desk ticket will be created to inform The Hormonist about the cancellation.";
      }
      if (!isHormonist && shouldCreditOrRefund) {
        return `
        A zoho desk ticket will be created to the finance department to refund or credit the related invoice and 
        an email will be sent to inform ${pharmacy?.Name} about the cancellation.
        `;
      }
      if (!isHormonist && !shouldCreditOrRefund) {
        return `An email will be sent to inform ${pharmacy?.Name} about the cancellation.`;
      }
    }
    if (isPaid && isPrescriptionOrder) {
      return `
        A zoho desk ticket will be created to the finance department to refund or credit the related
        invoice. Inform client about the cancellation if needed.
        `;
    }
    return "Inform client about the cancellation if needed.";
  }, [
    shouldCreditOrRefund,
    pharmacy?.Name,
    treatmentOrder?.PaymentStatus,
    treatmentOrder?.Type,
  ]);

  return (
    <Box minHeight="300px">
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginBottom: "4px" }}
        >
          Cancellation Reason *
        </Typography>
        <Box>
          <CustomTextareaField name="reason" rows={4} rowsMax={9} />
        </Box>
      </Box>
      {treatmentOrder?.PaymentStatus === "Paid" && (
        <Box marginY={3}>
          <CustomCheckboxField
            name="shouldCreditOrRefund"
            label="Credit or Refund the order"
          />
        </Box>
      )}
      <Alert severity="info" style={{ margin: "16px 0px" }}>
        {infoText}
      </Alert>
    </Box>
  );
};
