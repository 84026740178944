import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ZohoModuleEnum } from "@deep-consulting-solutions/bmh-constants";

import { zohoSelectors } from "redux/zoho";
import { ContactModule } from "./ContactModule";
import { MeetingModule } from "./MeetingModule";

const SelectModule: FC = () => {
  const entity = useSelector(zohoSelectors.getEntity);

  if (entity === ZohoModuleEnum.CONTACTS) return <ContactModule />;
  if (entity === ZohoModuleEnum.MEETINGS) return <MeetingModule />;
  return (
    <>{`Sorry, this module is not configured to use in module ${entity}`}</>
  );
};

export default SelectModule;
