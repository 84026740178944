import { Button, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getUpdateTreatmentBillingCycleValidation,
  getValueFromObject,
} from "helpers";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { MigratedLegacyTreatment } from "types";

interface Props {
  view:
    | "fetch-error"
    | "treatment-in-progress"
    | "migrated"
    | "form"
    | "success";
  onSubmit: (values: any) => Promise<void>;
  newTreatment?: MigratedLegacyTreatment;
}

export const MigrateTreatmentActions: React.FC<Props> = ({
  view,
  onSubmit,
  newTreatment,
}) => {
  const { isValid: isFormValid, values, setFieldValue } = useFormikContext();
  const manualOrAuto = useMemo(
    () => getValueFromObject("manualOrAutoSelection", values),
    [values]
  );
  const { handleClose } = useTreatmentWidgetLayout();

  const { selfSupplyFailed, isMultipleFailed } = useMemo(() => {
    return getUpdateTreatmentBillingCycleValidation(values, "");
  }, [values]);

  const isValid = useMemo(() => {
    if (selfSupplyFailed || isMultipleFailed) {
      return false;
    }
    return isFormValid;
  }, [isFormValid, selfSupplyFailed, isMultipleFailed]);

  const onClickCloseSuccess = useCallback(() => {
    handleClose(true);
  }, [handleClose]);

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const onMigrateTreatment = useCallback(() => {
    if (!isValid) {
      setFieldValue(
        "submitCount",
        (((values as any).submitCount as number) || 0) + 1
      );
      return;
    }
    onSubmit(values);
  }, [values, onSubmit, isValid, setFieldValue]);

  return (
    <>
      {" "}
      {["fetch-error", "treatment-in-progress", "migrated"].includes(view) ? (
        <Button variant="outlined" onClick={onClickClose} color="primary">
          CLOSE
        </Button>
      ) : null}
      {view === "form" ? (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              onClick={onMigrateTreatment}
              color="primary"
              type="submit"
              // disabled={!isValid}
            >
              Migrate Treatment
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {view === "success" ? (
        <>
          {manualOrAuto === "manual" && (
            <Button
              onClick={onClickCloseSuccess}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          )}
          {manualOrAuto === "auto" && (
            <a
              onClick={onClickCloseSuccess}
              href={newTreatment?.recordCrmLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="contained" color="primary">
                Close and Open New Treatment
              </Button>
            </a>
          )}
        </>
      ) : null}
    </>
  );
};
