import clsx from "clsx";
import React, { useMemo, useState, useCallback, useEffect, memo } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  TableRowProps,
  styled,
  Box,
  Tooltip,
  makeStyles,
  Theme,
  createStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Cancel,
  CheckCircle,
  DeleteForever,
  Edit,
  MoreHoriz,
} from "@material-ui/icons";
import { Column, Data, RowActionsEnum, StatusEnum } from "types";
import { EditableCell } from "components/EditableCell";
import { isEqual } from "lodash";
import { StyledIconButton } from "components/StyledIconButton";
import { DialogConfirm } from "components/DialogConfirm";

const StyledBox = styled(Box)(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: 108,
  padding: `3px 0 !important`,
  paddingRight: `${theme.spacing(0.5)} !important`,
}));

const useStyles = makeStyles<Theme, { hasError: boolean }>(({ palette: p }) =>
  createStyles({
    row: {
      "& > .MuiTableCell-body": {
        verticalAlign: (props) => (props.hasError ? "top" : "middle"),
      },
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 3,
      backgroundColor: p.common.white,
    },
  })
);

export type TableEntity =
  | "Profile Name"
  | "OBX Code"
  | "Failure Code"
  | "Abnormal Flag";

const getStatusDialogText = (entity?: TableEntity) => {
  if (!entity) return "";

  if (entity === "Profile Name") {
    return "After you deactivate Profile Name it won’t be active in the system and won’t be shown in Active Profile Names Tab. You can reactivate it at any moment.";
  }
  if (entity === "OBX Code") {
    return "After you deactivate OBX Code it won’t be active in the system and won’t be shown in Active OBX Codes Tab. However, OBX code will be activated automatically in case such code is received in test result files. You can reactivate it at any moment.";
  }
  if (entity === "Failure Code") {
    return "After you deactivate Failure Code it won’t be active in the system and won’t be shown in Active Failure Codes Tab. However, Failure Code will be activated automatically in case such code is received in test result files. You can reactivate it at any moment.";
  }
  if (entity === "Abnormal Flag") {
    return "After you deactivate Abnormal Flag it won’t be active in the system and won’t be shown in Active Abnormal Flags Tab. However, Abnormal Flag will be activated automatically in case such flag is received in test result files. You can reactivate it at any moment.";
  }

  return "";
};

export interface RowHandlers<T extends Data> {
  onRowUpdate: (data: T) => Promise<boolean>;
  onRowChangeSatus?: (data: T) => Promise<boolean>;
  onRowDelete?: (id: string) => Promise<boolean>;
  onRowCreate?: (data: Omit<T, "id">) => Promise<boolean>;
  onAcknowledgeUpdate?: (row: T) => Promise<any>;
}

interface EditableRowProps<T extends Data> extends TableRowProps {
  actions: RowActionsEnum[];
  handlers: RowHandlers<T>;
  validate: (
    row: T,
    val: any,
    dataKey: Column<T>["key"],
    required: boolean
  ) => {
    error: boolean;
    helperText: string;
  };
  row: T;
  columns: Column<T>[];
  creating?: boolean;
  onCancel?: () => void;
  columnsWidths?: Partial<Record<Exclude<keyof T, "id">, number>>;
  entity?: TableEntity;
}

function EditableRow<T extends Data>({
  actions,
  handlers: {
    onRowUpdate,
    onRowCreate,
    onRowChangeSatus,
    onRowDelete,
    onAcknowledgeUpdate,
  },
  columns,
  row,
  validate,
  creating = false,
  onCancel,
  columnsWidths,
  entity,
  ...rest
}: EditableRowProps<T>) {
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(row);
  useEffect(() => {
    if (row && !creating) {
      setData(row);
    }
  }, [row, creating]);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const setHasError = useCallback(
    (dataKey: Column<T>["key"], hasError: boolean) => {
      setErrors((prev) => ({
        ...prev,
        [dataKey]: hasError,
      }));
    },
    []
  );
  const hasError = useMemo(
    () => !!Object.values(errors).filter((val) => !!val).length,
    [errors]
  );

  const onCellChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, dataKey: Column<T>["key"]) => {
      setData({
        ...data,
        [dataKey]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    },
    [data]
  );

  const disableSave = useMemo(() => hasError || isEqual(data, row), [
    data,
    hasError,
    row,
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleEditing = useCallback(() => {
    setEditing((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onCancelClick = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      setData(row);
      setEditing(false);
    }
  }, [onCancel, row]);

  const onSaveClick = useCallback(
    async (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLTableRowElement>
    ) => {
      event.preventDefault();
      const successful =
        creating && onRowCreate
          ? await onRowCreate(data)
          : await onRowUpdate(data);
      if (successful) {
        if (onCancel) {
          onCancel();
        } else {
          setEditing(false);
        }
      }
    },
    [creating, data, onCancel, onRowCreate, onRowUpdate]
  );

  const [deactivating, setDeactivating] = useState(false);
  const toggleDeactivating = useCallback(() => {
    setDeactivating((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onChangeStatus = useCallback(async () => {
    if (onRowChangeSatus) {
      await onRowChangeSatus(data);
    }
  }, [data, onRowChangeSatus]);

  const [deleting, setDeleting] = useState(false);
  const toggleDeleting = useCallback(() => {
    setDeleting((prev) => !prev);
    handleClose();
  }, [handleClose]);

  const onDelete = useCallback(async () => {
    if (onRowDelete) {
      const deleted = await onRowDelete(row.id);
      if (deleted) {
        toggleDeleting();
      }
    }
  }, [onRowDelete, row.id, toggleDeleting]);

  const onAcknowledgeUpdateClick = useCallback(() => {
    if (onAcknowledgeUpdate) {
      onAcknowledgeUpdate(data);
    }
  }, [data, onAcknowledgeUpdate]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTableRowElement>) => {
      if (e.keyCode === 13 && disableSave) {
        return;
      }
      const element = e.target as HTMLElement;
      if (
        e.keyCode === 13 &&
        element.tagName === "BUTTON" &&
        (element.getAttribute("aria-label") !== "save" ||
          element.getAttribute("aria-label") !== "okay")
      ) {
        return;
      }
      if (
        e.keyCode === 13 &&
        (e.target as HTMLInputElement).type !== "textarea"
      ) {
        onSaveClick(e);
      } else if (
        e.keyCode === 13 &&
        (e.target as HTMLInputElement).type === "textarea" &&
        e.shiftKey
      ) {
        onSaveClick(e);
      } else if (e.keyCode === 27) {
        onCancelClick();
      }
    },
    [disableSave, onCancelClick, onSaveClick]
  );

  const statusAction =
    row.status === StatusEnum.Active ? "Deactivate" : "Activate";

  const deactivateDialogText = useMemo(() => getStatusDialogText(entity), [
    entity,
  ]);

  const classes = useStyles({ hasError });
  return (
    <>
      <TableRow className={classes.row} onKeyDown={onKeyDown} {...rest}>
        {columns.map((col) => {
          if (col.editable) {
            return (
              <EditableCell<T>
                key={col.key as string}
                className={clsx({ [classes.stickyColumn]: col.stickyLeft })}
                row={data}
                dataKey={col.key}
                editing={editing || creating || (row.isNew && col.isBoolean)}
                data={data[col.key]}
                onCellChange={onCellChange}
                align={col.align}
                width={columnsWidths ? columnsWidths[col.key] : undefined}
                setHasError={setHasError}
                validate={validate}
                textFieldProps={{
                  placeholder: col.header,
                  autoFocus: col.autoFocus,
                }}
                shouldHighlight={row.updates ? row.updates[col.key] : row.isNew}
                isNew={row.isNew}
                required={col.required}
              />
            );
          }
          return (
            <TableCell
              className={clsx({ [classes.stickyColumn]: col.stickyLeft })}
              style={{
                width: columnsWidths ? columnsWidths[col.key] : undefined,
              }}
              key={col.key as string}
              align={col.align}
            >
              {data[col.key]}
            </TableCell>
          );
        })}
        <StyledTableCell>
          {editing || creating ? (
            <StyledBox display="flex" alignItems="center">
              {disableSave ? (
                <StyledIconButton
                  aria-label="save"
                  onClick={onSaveClick}
                  disabled={disableSave}
                  color="success"
                >
                  <CheckCircle />
                </StyledIconButton>
              ) : (
                <Tooltip title="Save">
                  <StyledIconButton
                    aria-label="save"
                    onClick={onSaveClick}
                    disabled={disableSave}
                    color="success"
                  >
                    <CheckCircle />
                  </StyledIconButton>
                </Tooltip>
              )}

              <Tooltip title="Cancel">
                <StyledIconButton
                  color="error"
                  aria-label="cancel"
                  onClick={onCancelClick}
                >
                  <Cancel />
                </StyledIconButton>
              </Tooltip>
            </StyledBox>
          ) : (
            <StyledBox
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {row.isUpdated || row.isNew ? (
                <>
                  {hasError ? (
                    <StyledIconButton
                      color="success"
                      aria-label={row.isNew ? "save" : "okay"}
                      onClick={onAcknowledgeUpdateClick}
                      disabled={hasError}
                    >
                      <CheckCircle />
                    </StyledIconButton>
                  ) : (
                    <Tooltip title={row.isNew ? "Save" : "Okay"}>
                      <StyledIconButton
                        color="success"
                        aria-label={row.isNew ? "save" : "okay"}
                        onClick={onAcknowledgeUpdateClick}
                      >
                        <CheckCircle />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {actions.length > 1 ? (
                    <>
                      <Tooltip title="Actions">
                        <IconButton
                          aria-label="actions"
                          aria-controls="actions-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="actions-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {actions.map((action) => {
                          if (action === RowActionsEnum.ChangeStatus) {
                            return (
                              <MenuItem
                                key={action}
                                onClick={
                                  statusAction === "Activate"
                                    ? onChangeStatus
                                    : toggleDeactivating
                                }
                              >
                                {statusAction}
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem key={action} onClick={toggleEditing}>
                              {action}
                            </MenuItem>
                          );
                        })}
                        {row.isDeletable && (
                          <MenuItem onClick={toggleDeleting}>Delete</MenuItem>
                        )}
                      </Menu>
                    </>
                  ) : (
                    <Tooltip title="Edit">
                      <IconButton aria-label="edit" onClick={toggleEditing}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </StyledBox>
          )}
        </StyledTableCell>
      </TableRow>
      {onRowDelete && (
        <DialogConfirm
          open={deleting}
          onClose={toggleDeleting}
          onConfirm={onDelete}
          type="error"
          title={`Delete ${entity}`}
          message={`After you delete ${
            row[columns[0].key]
          } ${entity}, it's permanently deleted and can't be undeleted.`}
          confirmBtnLabel="delete"
          confirmBtnIcon={<DeleteForever />}
        />
      )}
      {onRowChangeSatus && (
        <DialogConfirm
          open={deactivating}
          onClose={toggleDeactivating}
          onConfirm={onChangeStatus}
          type="warning"
          title={`${statusAction} ${entity}`}
          message={deactivateDialogText}
          confirmBtnLabel="deactivate"
        />
      )}
    </>
  );
}

export default memo(EditableRow) as typeof EditableRow;
