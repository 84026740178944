import {
  DialogTitle,
  DialogTitleProps,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { getParentElement } from "helpers";
import { Close } from "@material-ui/icons";
import { useTreatmentWidgetLayout } from "../useTreatmentWidgetLayout";

interface Props extends DialogTitleProps {
  title: string;
  isCustomButton?: boolean;
}

const useStyles = makeStyles(({ palette: p }) => ({
  wrapper: {
    backgroundColor: p.grey[50],
  },
}));

export const TreatmentWidgetTitle = ({
  title,
  isCustomButton = false,
  ...props
}: Props) => {
  const classes = useStyles();
  const { setTitleDimension, handleClose } = useTreatmentWidgetLayout();

  const elementID = useMemo(() => {
    return String(Math.random());
  }, []);

  const handleMutation = useCallback(
    (entries: MutationRecord[]) => {
      const entry = entries[0];

      const element = getParentElement(
        (entry.target as unknown) as HTMLElement,
        elementID
      );

      if (element) {
        setTitleDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
      }
    },
    [setTitleDimension, elementID]
  );

  const observer = useMemo(() => {
    return new MutationObserver(handleMutation);
  }, [handleMutation]);

  const wrapperRef = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        setTitleDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
        observer.observe(element, {
          childList: true,
          attributes: true,
          subtree: true,
        });
      }
    },
    [observer, setTitleDimension]
  );

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  if (isCustomButton) {
    return null;
  }

  return (
    <DialogTitle {...props} className={classes.wrapper}>
      <div id={elementID} ref={wrapperRef}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
          direction="row"
        >
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleClose(false, true);
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </DialogTitle>
  );
};
