import { Chip } from "@material-ui/core";
import { BasicTable } from "components/BasicTable";
import moment from "moment";
import React, { useMemo } from "react";
import { ZohoInvoice } from "types";

interface PatientPendingPaymentPharmInvoicesProps {
  invoices: ZohoInvoice[];
}

export const PatientPendingPaymentPharmInvoices: React.FC<PatientPendingPaymentPharmInvoicesProps> = ({
  invoices,
}) => {
  const currencySymbol = useMemo(() => invoices[0]?.currency_symbol || "£", [
    invoices,
  ]);
  const memoizedTableData = useMemo(
    () =>
      invoices.map((invoice) => ({
        ...invoice,
        name: invoice.invoice_number,
        due_date: moment(invoice.due_date).format("DD/MM/YYYY"),
        invoice: (
          <a
            href={invoice.invoice_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {invoice.invoice_number}
          </a>
        ),
        status: (
          <Chip
            label={invoice.status}
            color={
              invoice.status.toLowerCase() === "paid" ? "primary" : "default"
            }
          />
        ),
        recurringInvoice: invoice.recurrence_name ? (
          <a
            href={invoice.recurrence_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {invoice.recurrence_name}
          </a>
        ) : (
          "N/A"
        ),
      })),
    [invoices]
  );

  return (
    <>
      <BasicTable
        tableHeaders={[
          { title: "Invoice", id: "invoice" },
          { title: "Status", id: "status" },
          { title: "Due Date", id: "due_date" },
          { title: `Amount (${currencySymbol})`, id: "total" },
          { title: "Recurring Invoice", id: "recurringInvoice" },
        ]}
        tableData={memoizedTableData}
        emptyMessage={
          memoizedTableData.length === 0
            ? "No Pending Payment Invoices Found"
            : ""
        }
      />
    </>
  );
};
