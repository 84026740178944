import { Box, Button, makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import {
  FinalizeMatchingProductDetails,
  ZohoMedUsageUnit,
  ZohoProduct,
  ZohoTreatmentTreatmentArea,
} from "types";
import {
  getBestBillingAndCheapestProduct,
  getFieldName,
  getValueFromObject,
  sortProducts,
} from "helpers";
import { useFormikContext } from "formik";
import { MatchingProduct } from "./MatchingProduct";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  products: FinalizeMatchingProductDetails[];
  name?: string;
  productsUrl: string;
  treatmentTreatmentArea: ZohoTreatmentTreatmentArea;
  bacteriostaticProducts: ZohoProduct[];
  productFieldName: string;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean;
  isMatchingProductVisible: boolean;
  matchingProductsLength: number;
}

export const ProductsList = ({
  products,
  name = "",
  productsUrl,
  treatmentTreatmentArea,
  bacteriostaticProducts,
  productFieldName,
  medUsageUnits,
  isSelectedMatchingProductsDefaultBillingCyclesFailed,
  isMatchingProductVisible,
  matchingProductsLength,
}: Props) => {
  const classes = useStyles();

  const { values } = useFormikContext();

  const [showAllProducts, setShowAllProducts] = useState(false);

  const selectedProduct = useMemo(
    () => getValueFromObject(productFieldName, values),
    [productFieldName, values]
  );

  const handleShowProducts = useCallback(() => {
    setShowAllProducts(true);
  }, []);

  const { bestBillingProduct, cheapestProduct } = useMemo(() => {
    return getBestBillingAndCheapestProduct(products);
  }, [products]);

  const {
    bestBillingProductDetails,
    cheapestProductDetails,
    sortedProducts,
  } = useMemo(() => {
    return sortProducts(products, cheapestProduct, bestBillingProduct);
  }, [products, cheapestProduct, bestBillingProduct]);

  const slicedProducts = useMemo(() => {
    if (
      showAllProducts ||
      (selectedProduct &&
        (cheapestProductDetails?.id || bestBillingProductDetails?.id) &&
        selectedProduct !== cheapestProductDetails?.id &&
        selectedProduct !== bestBillingProductDetails?.id)
    ) {
      return sortedProducts;
    }

    if (cheapestProductDetails && bestBillingProductDetails) {
      if (cheapestProductDetails.id === bestBillingProductDetails.id) {
        return [bestBillingProductDetails];
      }
      return [bestBillingProductDetails, cheapestProductDetails];
    }

    if (bestBillingProductDetails) {
      return [bestBillingProductDetails];
    }

    if (cheapestProductDetails) {
      return [cheapestProductDetails];
    }

    return sortedProducts;
  }, [
    showAllProducts,
    sortedProducts,
    cheapestProductDetails,
    bestBillingProductDetails,
    selectedProduct,
  ]);

  return (
    <Box>
      {slicedProducts.map((product, index) => (
        <Box
          mt={index === 0 ? 0 : 2}
          key={product.id}
          className={classes.wrapper}
        >
          <MatchingProduct
            product={product}
            productsUrl={productsUrl}
            name={getFieldName(index, name)}
            isCheapest={cheapestProduct === product.id}
            isBestBillingProduct={bestBillingProduct === product.id}
            treatmentTreatmentArea={treatmentTreatmentArea}
            bacteriostaticProducts={bacteriostaticProducts}
            productFieldName={productFieldName}
            medUsageUnits={medUsageUnits}
            isSelectedMatchingProductsDefaultBillingCyclesFailed={
              isSelectedMatchingProductsDefaultBillingCyclesFailed
            }
            isMatchingProductVisible={isMatchingProductVisible}
            productsLength={products.length}
            matchingProductsLength={matchingProductsLength}
          />
        </Box>
      ))}
      {products.length > slicedProducts.length && !showAllProducts ? (
        <Box mt={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleShowProducts}
          >
            SHOW ALL PRODUCTS
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
