import {
  UnmatchedBloodTestResultStatusEnum,
  BloodTestResult,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useState, useCallback, useMemo } from "react";
import { createTableData } from "./helpers";
import { RowGroup } from "./RowGroup";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  actionBtn: {
    marginRight: s(1),
    "&:last-child": {
      marginRight: s(0),
    },
  },
  tableContainer: {
    flex: 1,
  },
  tableHeadCell: {
    background: "white",
  },
  firstHeadRow: {
    border: 0,
    padding: 0,
    paddingBottom: s(1),
  },
  existingResultTitle: {
    background: p.grey[100],
    borderWidth: 1,
    borderColor: p.grey[200],
    borderStyle: "solid",
  },
  newResultTitle: {
    borderWidth: 1,
    borderColor: p.grey[200],
    background: "white",
    borderStyle: "solid",
  },
  currentResultTitle: {
    background: p.grey[100],
    borderWidth: 1,
    borderColor: p.grey[200],
    borderStyle: "solid",
  },
  existingResultText: {
    fontWeight: 700,
  },
  test: {
    background: p.grey[50],
  },
  existing: {
    background: p.grey[100],
  },
  headerText: {
    color: "#7E9FCE",
    fontWeight: 500,
  },
  cell: {
    padding: s(0, 2),
  },
  noPadding: {
    padding: s(0),
  },
  collectionDate: {
    padding: s(1, 2),
    background: p.grey[200],
    margin: s(1, 0),
    borderRadius: s(2),
  },
  paddingBottom: {
    paddingBottom: s(1),
  },
}));

const CollectionDate = ({ date }: { date: string }) => {
  const { time, dateString } = useMemo(() => {
    try {
      return {
        time: format(new Date(date), "hh:mm"),
        dateString: format(new Date(date), "MMM dd yyyy"),
      };
    } catch {
      return {
        time: "",
        dateString: "",
      };
    }
  }, [date]);

  const classes = useStyle();

  return (
    <div className={classes.collectionDate}>
      <Grid
        justify="space-between"
        spacing={2}
        alignItems="center"
        wrap="nowrap"
        container
      >
        <Grid item>
          <CalendarToday />
        </Grid>
        <Grid item>
          <Typography>
            {time} <strong>{dateString}</strong>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

interface Props {
  newResult?: BloodTestResult | null;
  existingResult?: BloodTestResult | null;
  currentResult?: BloodTestResult | null;
  oldResult?: BloodTestResult | null;
  stage: UnmatchedBloodTestResultStatusEnum;
}

export const ComparisonView = ({
  newResult,
  oldResult,
  existingResult,
  currentResult,
  stage,
}: Props) => {
  const [showRange, setShowRange] = useState(false);
  const [showCollectedDate, setShowCollectedDate] = useState(false);

  const onRangeToggle = useCallback(() => {
    setShowRange((current) => !current);
  }, []);

  const onCollectedDateToggle = useCallback(() => {
    setShowCollectedDate((current) => !current);
  }, []);

  const classes = useStyle();

  const {
    newResults,
    newResultTooltipTitle,
    newCollectionDate,
  } = useMemo(() => {
    if (newResult) {
      return {
        newResults: newResult.results,
        newResultTooltipTitle: newResult.name,
        newCollectionDate: newResult.sampleCollectedOn,
      };
    }
    return { newResults: [], newResultTooltipTitle: "", newCollectionDate: "" };
  }, [newResult]);

  const {
    existingResults,
    existingResultTooltipTitle,
    existingCollectionDate,
  } = useMemo(() => {
    if (
      (stage === UnmatchedBloodTestResultStatusEnum.DISCARDED ||
        stage === UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT ||
        stage ===
          UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT) &&
      oldResult
    ) {
      return {
        existingResults: oldResult.results,
        existingResultTooltipTitle: oldResult.name,
        existingCollectionDate: oldResult.sampleCollectedOn,
      };
    }

    if (existingResult) {
      return {
        existingResults: existingResult.results,
        existingResultTooltipTitle: existingResult.name,
        existingCollectionDate: existingResult.sampleCollectedOn,
      };
    }
    return {
      existingResults: [],
      existingResultTooltipTitle: "",
      existingCollectionDate: "",
    };
  }, [stage, existingResult, oldResult]);

  const {
    currentResultTooltipTitle,
    currentCollectionDate,
    currentResults,
  } = useMemo(() => {
    if (
      stage ===
        UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT &&
      currentResult
    ) {
      return {
        currentResultTooltipTitle: currentResult.name,
        currentCollectionDate: currentResult.sampleCollectedOn,
        currentResults: currentResult.results,
      };
    }
    return {
      currentResultTooltipTitle: "",
      currentCollectionDate: "",
      currentResults: undefined,
    };
  }, [stage, currentResult]);

  const groups = useMemo(
    () => createTableData(newResults, existingResults, currentResults),
    [newResults, existingResults, currentResults]
  );

  const existingResultComponent = useMemo(() => {
    let title = "Existing Result";
    if (stage === UnmatchedBloodTestResultStatusEnum.DISCARDED) {
      title = "Old Result (Final Result)";
    }
    if (
      stage ===
        UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT ||
      stage === UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT
    ) {
      title = "Old Result";
    }
    return (
      <Tooltip title={existingResultTooltipTitle}>
        <Typography className={classes.existingResultText} align="center">
          {title}
        </Typography>
      </Tooltip>
    );
  }, [classes, existingResultTooltipTitle, stage]);

  const newResultComponent = useMemo(() => {
    let title = "New Result";
    if (stage === UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT) {
      title = "New Result (Final Result)";
    }
    return (
      <Tooltip title={newResultTooltipTitle}>
        <Typography className={classes.existingResultText} align="center">
          {title}
        </Typography>
      </Tooltip>
    );
  }, [classes, newResultTooltipTitle, stage]);

  const currentResultComponent = useMemo(
    () => (
      <Tooltip title={currentResultTooltipTitle}>
        <Typography className={classes.existingResultText} align="center">
          Final Result
        </Typography>
      </Tooltip>
    ),
    [classes, currentResultTooltipTitle]
  );

  const firstHeadRowColSpan = useMemo(() => {
    if (showCollectedDate) {
      return undefined;
    }
    return showRange ? 7 : 5;
  }, [showRange, showCollectedDate]);

  return (
    <Box className={classes.wrapper}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={firstHeadRowColSpan}
                className={clsx(classes.tableHeadCell, classes.firstHeadRow)}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.actionBtn}
                  onClick={onRangeToggle}
                >
                  {showRange ? "HIDE" : "SHOW"} NORMAL RANGES
                </Button>
              </TableCell>

              {showCollectedDate && (
                <>
                  <TableCell
                    colSpan={showRange ? 3 : 2}
                    className={clsx(classes.cell, classes.existingResultTitle)}
                  >
                    {existingResultComponent}
                  </TableCell>
                  <TableCell
                    colSpan={showRange ? 3 : 2}
                    className={clsx(classes.cell, classes.newResultTitle)}
                  >
                    {newResultComponent}
                  </TableCell>
                  {stage ===
                    UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT && (
                    <TableCell
                      colSpan={showRange ? 3 : 2}
                      className={clsx(classes.cell, classes.currentResultTitle)}
                    >
                      {currentResultComponent}
                    </TableCell>
                  )}
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(
                  classes.noPadding,
                  classes.tableHeadCell,
                  classes.paddingBottom
                )}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.actionBtn}
                  onClick={onCollectedDateToggle}
                >
                  {showCollectedDate ? "HIDE" : "SHOW"} SAMPLE COLLECTION DATE
                </Button>
              </TableCell>
              <TableCell
                colSpan={showRange ? 3 : 2}
                className={clsx(classes.cell, classes.existingResultTitle)}
              >
                {showCollectedDate ? (
                  <CollectionDate date={existingCollectionDate} />
                ) : (
                  existingResultComponent
                )}
              </TableCell>
              <TableCell
                colSpan={showRange ? 3 : 2}
                className={clsx(classes.cell, classes.newResultTitle)}
              >
                {showCollectedDate ? (
                  <CollectionDate date={newCollectionDate} />
                ) : (
                  newResultComponent
                )}
              </TableCell>

              {stage ===
                UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT && (
                <TableCell
                  colSpan={showRange ? 3 : 2}
                  className={clsx(classes.cell, classes.currentResultTitle)}
                >
                  {showCollectedDate ? (
                    <CollectionDate date={currentCollectionDate} />
                  ) : (
                    currentResultComponent
                  )}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={clsx(classes.noPadding, classes.test)}>
                <Typography className={classes.headerText}>Test</Typography>
              </TableCell>

              <TableCell className={clsx(classes.cell, classes.existing)}>
                <Typography className={classes.headerText}>Result</Typography>
              </TableCell>
              {showRange && (
                <TableCell className={clsx(classes.cell, classes.existing)}>
                  <Typography className={classes.headerText}>
                    N.Range
                  </Typography>
                </TableCell>
              )}
              <TableCell className={clsx(classes.cell, classes.existing)}>
                <Typography className={classes.headerText}>Unit</Typography>
              </TableCell>

              <TableCell className={clsx(classes.cell, classes.test)}>
                <Typography className={classes.headerText}>Result</Typography>
              </TableCell>
              {showRange && (
                <TableCell className={clsx(classes.cell, classes.test)}>
                  <Typography className={classes.headerText}>
                    N.Range
                  </Typography>
                </TableCell>
              )}
              <TableCell className={clsx(classes.cell, classes.test)}>
                <Typography className={classes.headerText}>Unit</Typography>
              </TableCell>

              {stage ===
                UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT && (
                <>
                  <TableCell className={clsx(classes.cell, classes.test)}>
                    <Typography className={classes.headerText}>
                      Result
                    </Typography>
                  </TableCell>
                  {showRange && (
                    <TableCell className={clsx(classes.cell, classes.test)}>
                      <Typography className={classes.headerText}>
                        N.Range
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell className={clsx(classes.cell, classes.test)}>
                    <Typography className={classes.headerText}>Unit</Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <RowGroup
                key={group.identifier}
                group={group}
                showRange={showRange}
                showCurrentResult={
                  stage ===
                  UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
