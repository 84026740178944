import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Button, CircularProgress } from "@material-ui/core";
import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  hasValidTreatmentState,
  notifyDoctorToSign,
} from "services/treatments/notify-doctor";
import { NotifyDoctorResponse } from "./types";
import { validStates } from "./constants";

export const NotifyDoctorToSignDoc = () => {
  const [treatmentId] = useSelector(zohoSelectors.getIds);
  const [loading, setLoading] = useState(true);
  const [canProceed, setCanProceed] = useState(false);
  const [apiResponse, setApiResponse] = useState<NotifyDoctorResponse | null>(
    null
  );

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const severity = useMemo(() => {
    return apiResponse?.status === "failed" ? "error" : "success";
  }, [apiResponse?.status]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const hasValidStateResponse = await hasValidTreatmentState(treatmentId);

      setCanProceed(hasValidStateResponse);
      if (!hasValidStateResponse) return;

      const notifyDoctorResponse = await notifyDoctorToSign(treatmentId);
      setApiResponse(notifyDoctorResponse);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TreatmentWidgetLayout defaultWidth={700}>
      <TreatmentWidgetTitle title="Notify Doctor To Sign" isCustomButton />
      <TreatmentWidgetContent>
        {loading && (
          <Box
            height="300px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && !canProceed && (
          <Alert severity="error">
            <AlertTitle>
              The Treatment must be in one of the following states to use this
              widget:
            </AlertTitle>
            <Box component="ul" margin={0}>
              {validStates.map((label) => (
                <li key={label}>{label}</li>
              ))}
            </Box>
          </Alert>
        )}
        {!loading && apiResponse?.message && (
          <Alert severity={severity}>{apiResponse.message}</Alert>
        )}
      </TreatmentWidgetContent>
      {!loading && (
        <TreatmentWidgetActions>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gridColumnGap="8px"
          >
            <Button
              style={{ textTransform: "capitalize" }}
              color="primary"
              variant="outlined"
              onClick={() => closeWidget()}
            >
              Close
            </Button>
          </Box>
        </TreatmentWidgetActions>
      )}
    </TreatmentWidgetLayout>
  );
};
