import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { Decision, TreatmentOrderItem } from "widgets/DispenseDrugs";

interface OrderItemProps {
  orderItem: TreatmentOrderItem;
}

export const useStyles = makeStyles(({ spacing: s }) => ({
  ul: {
    padding: s(0, 0, 0, 2),
    margin: 0,
  },
  orangChip: {
    backgroundColor: "#ED6C02",
    color: "#fff",
  },
  yellowChip: {
    backgroundColor: "#F2C94C",
  },
  gridItem: {
    padding: "0px !important",
  },
  textDescription: {
    display: "flex",
    gap: s(2),
    alignItems: "center",
    marginBottom: s(1),
  },
}));

export const OrderItem: React.FC<OrderItemProps> = ({ orderItem }) => {
  const classes = useStyles();

  const chipDetails: { label: string; className?: string } = useMemo(() => {
    if (orderItem.Decision === Decision.MARK_AS_OWED) {
      return {
        label: "ALL UNITS OWED",
        className: classes.yellowChip,
      };
    }
    if (orderItem.Decision === Decision.REPLACE) {
      return {
        label: "ALL UNITS REPLACED",
      };
    }
    if (
      orderItem.Quantity === orderItem.Missing_Quantity &&
      orderItem.Dispensed_Unit?.length === 0
    ) {
      return {
        label: "ALL UNITS MISSING",
        className: classes.orangChip,
      };
    }
    if (
      [
        Decision.PARTIAL_DISPENSE_MARK_REST_OWED,
        Decision.PARTIAL_DISPENSE_SEND_TO_BMH,
      ].includes(orderItem.Decision as Decision)
    ) {
      return {
        label: "PARTIALLY AVAILABLE",
        className: classes.yellowChip,
      };
    }
    return {
      label: "ALL UNITS AVAILABLE",
    };
  }, [orderItem, classes.orangChip, classes.yellowChip]);

  return (
    <Box mb={2}>
      <Box className={classes.textDescription}>
        <Typography variant="body1" color="primary" gutterBottom>
          {orderItem.Quantity}
          {orderItem.productItem.Usage_Units_Per_Sellable_Unit
            ? ` x ${orderItem.productItem.Usage_Units_Per_Sellable_Unit}`
            : ""}
          &nbsp;
          {orderItem.productItem.Meds_Usage_Unit_Name || ""}{" "}
          {orderItem.productItem.Meds_Usage_Unit_Name ? " of " : ""}
          {orderItem.productItem.Name_For_Prescription}
        </Typography>
        <Chip
          className={chipDetails.className ? chipDetails.className : ""}
          label={chipDetails.label}
        />
      </Box>
      <Box pl={2} display="flex" flexWrap="wrap">
        {orderItem.Dispensed_Unit?.map((unit, index) => (
          <Box key={unit.id} width="50%">
            <Box display="flex">
              <Box width="50%">
                <Typography variant="body2" color="textSecondary">
                  <strong>Unit {index + 1}:</strong>&nbsp;Batch Number:&nbsp;
                  <strong>{unit.Batch_Number}</strong>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Expiry Date: <strong>{unit.Expiry_Date}</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
