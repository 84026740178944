/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { zohoServices } from "services";

import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  getSigningUrl,
  getTreatmentAlt,
  getCttpRecord,
  getPrescriptions,
  getLocationData,
  getPermissions,
  getUpcomingConsultations,
} from "services/signing";
import {
  Treatment,
  CttpDocument,
  Prescription,
  UserLocation,
  SigningRequestPayload,
} from "types";
import { domain } from "configs/app";
import { documentIsPossiblySignedMessages } from "configs";
import Loader from "components/Loader";

type DocTab = {
  id: string;
  label: string;
  type: string;
  isSigned: boolean;
};

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(0, 2),
    background: p.common.white,
    margin: s(0),
  },
}));

const defaultErrorMessage =
  "Error happened fetching this document. Please retry in 2 minutes. If the error persists, please contact support.";

export const SignCttp = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchingSigningUrl, setFetchingSigningUrl] = useState(true);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [treatment, setTreatment] = useState<Treatment | null>(null);
  const [cttp, setCttp] = useState<CttpDocument | null>(null);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [tabList, setTabList] = useState<DocTab[]>([]);
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);
  const [signingUrl, setSigningUrl] = useState("");
  const [tabValue, setTabValue] = useState("");
  const [consultationText, setConsultationText] = useState("");

  const ids = useSelector(zohoSelectors.getIds);
  const user = useSelector(zohoSelectors.getCurrentUser);
  const userId = user?.id as string;

  const canSign = useMemo(() => {
    if (treatment?.State) {
      return [
        "Pending Doctor Signing",
        "Active",
        "Pending First Payment",
        "Pending Client Signing",
      ].includes(treatment.State);
    }
    return false;
  }, [treatment?.State]);

  const areAllDocsSigned = useMemo(() => tabList.every((t) => t.isSigned), [
    tabList,
  ]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyle();

  const resizeWidget = useCallback(() => {
    zohoServices
      .resizeWidget({
        width: 1400,
        height: 700,
      })
      .then(() => {
        setTimeout(() => {
          if (!loading && wrapperRef?.current?.offsetHeight) {
            let height = 700;

            if (tabList.length > 0 && areAllDocsSigned) {
              height = 700;
            }

            if (!canSign || !hasPermissions) {
              height = wrapperRef.current.offsetHeight;
            }

            zohoServices.resizeWidget({
              width: !canSign || !hasPermissions ? 1000 : 1400,
              height,
            });
          }
        }, 100);
      });
  }, [areAllDocsSigned, hasPermissions, canSign, loading, tabList.length]);

  const fetchSigningUrl = useCallback(
    async (selectedId: string) => {
      setSigningUrl("");
      setErrorMessage("");

      const selectedTab = tabList.find((t) => t.id === selectedId);
      if (selectedTab?.isSigned) {
        setErrorMessage("Document has been signed");
        return;
      }

      setFetchingSigningUrl(true);

      const selectedDoc = [cttp, ...prescriptions].find(
        (d) => d?.id === selectedId
      );

      const payload: SigningRequestPayload = {
        treatmentId: treatment?.ZohoCrmId as string,
        cttpOrPrescriptionId: selectedDoc?.id as string,
        type: selectedDoc?.type as string,
        locationInfo: userLocation as UserLocation,
        userId,
      };

      try {
        const {
          signingUrlResponse,
          error: signingError,
          message: signingErrorMessage,
        } = await getSigningUrl(payload);
        if (signingError) {
          if (signingErrorMessage === "Canceling previous request") {
            setFetchingSigningUrl(true);
            return;
          }
          const errMessage = documentIsPossiblySignedMessages.includes(
            signingErrorMessage || ""
          )
            ? "Document has been signed"
            : defaultErrorMessage;
          setErrorMessage(errMessage);
          return;
        }

        if (signingUrlResponse?.data.status === "AwaitingSignatory") {
          setSigningUrl(signingUrlResponse.data.signingUrl);
        }
      } catch (err: any) {
        const message = err.response?.data.message || err.message || "";
        const errMessage = documentIsPossiblySignedMessages.includes(message)
          ? "Document has been signed"
          : defaultErrorMessage;
        setErrorMessage(errMessage);
      } finally {
        setFetchingSigningUrl(false);
      }
    },
    [tabList, cttp, prescriptions, treatment?.ZohoCrmId, userLocation, userId]
  );

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    async (event: React.ChangeEvent<{}>, newValue: string) => {
      setTabValue(newValue);
      setErrorMessage("");
      await fetchSigningUrl(newValue);
    },
    [fetchSigningUrl]
  );

  const fetchTreatment = useCallback(async (treatmentId: string) => {
    const rawTreatment = await getTreatmentAlt(treatmentId);

    setTreatment(rawTreatment);
    return rawTreatment;
  }, []);

  const fetchCttp = useCallback(async (treatmentId: string) => {
    const cttpRes = await getCttpRecord(treatmentId);
    setCttp(cttpRes);
    return cttpRes;
  }, []);

  const fetchPrescriptions = useCallback(async (treatmentId: string) => {
    const prescriptionsRes = await getPrescriptions(treatmentId);
    setPrescriptions(prescriptionsRes);
    return prescriptionsRes;
  }, []);

  const fetchUserLocation = useCallback(async () => {
    const userLocationRes = await getLocationData();
    setUserLocation(userLocationRes);
    return userLocationRes;
  }, []);

  const fetchUpcomingConsultation = useCallback(async () => {
    const treatmentId = ids[0];
    const consultationTextData = await getUpcomingConsultations(treatmentId);
    setConsultationText(consultationTextData);
    return consultationTextData;
  }, [ids]);

  const getData = useCallback(async () => {
    const treatmentId = ids[0];
    try {
      setLoading(true);
      setFetchingSigningUrl(true);
      setErrorMessage("");

      const [location, rawTreatment, hasPermissionsRes] = await Promise.all([
        fetchUserLocation(),
        fetchTreatment(treatmentId),
        getPermissions(treatmentId, userId),
      ]);

      setHasPermissions(hasPermissionsRes);

      if (!rawTreatment || !hasPermissionsRes) {
        return;
      }

      const [rawCttp, rawPrescriptions] = await Promise.all([
        fetchCttp(rawTreatment.Id),
        fetchPrescriptions(treatmentId),
      ]);

      if (!rawCttp) {
        return;
      }

      const cttpTab = {
        id: rawCttp.id,
        label: "Consent to Treatment Plan",
        type: rawCttp.type,
        isSigned: ["Pending Client Signing", "Signed"].includes(rawCttp.State),
      };
      const prescriptionTabs = rawPrescriptions.map((p) => ({
        id: p.id,
        label: p.label,
        type: p.type,
        isSigned: !!p.Signing_Date,
      }));
      const signPrescriptions = prescriptionTabs.filter((p) => p.isSigned);
      const unsignedPrescriptions = prescriptionTabs.filter((p) => !p.isSigned);

      let tabs = [...unsignedPrescriptions, ...signPrescriptions];

      if (cttpTab.isSigned) {
        tabs = [...tabs, cttpTab];
      } else {
        tabs = [cttpTab, ...tabs];
      }

      setTabList(tabs);

      // Check if all documents has been signed
      const allDocsSigned = tabs.every((d) => {
        return d.isSigned;
      });

      if (allDocsSigned) {
        return;
      }

      const firstTab = tabs[0];

      if (location) {
        if (firstTab.isSigned) {
          setErrorMessage("Document has been signed");
          setTabValue(firstTab.id);
          return;
        }
        if (!firstTab.isSigned) {
          const payload = {
            treatmentId,
            cttpOrPrescriptionId: firstTab.id,
            type: firstTab.type,
            locationInfo: location,
            userId,
          };
          const {
            signingUrlResponse,
            error: signingError,
            message: signingErrorMessage,
          } = await getSigningUrl(payload);
          if (signingError) {
            const errMessage = documentIsPossiblySignedMessages.includes(
              signingErrorMessage || ""
            )
              ? "Document has been signed"
              : defaultErrorMessage;
            setErrorMessage(errMessage);
            setTabValue(firstTab.id);
            return;
          }

          if (signingUrlResponse?.data.status === "AwaitingSignatory") {
            setSigningUrl(signingUrlResponse.data.signingUrl);
            setTabValue(firstTab.id);
            return;
          }
        }
      }
    } catch (err) {
      setLoading(false);
      setFetchingSigningUrl(false);
    } finally {
      setLoading(false);
      setFetchingSigningUrl(false);
    }
  }, [
    fetchCttp,
    fetchPrescriptions,
    fetchTreatment,
    fetchUserLocation,
    ids,
    userId,
  ]);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    fetchUpcomingConsultation();
  }, [fetchUpcomingConsultation]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      {loading && <Loader open />}
      {!loading && !canSign && (
        <Box paddingY={2} paddingX={1}>
          <Alert severity="warning">
            The Treatment must have documents
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: "bold", margin: "0px 4px" }}
            >
              Pending Doctor Signing
            </Typography>
            to use this widget.
          </Alert>
        </Box>
      )}
      {!loading && canSign && !hasPermissions && (
        <Box paddingY={2} paddingX={1}>
          <Alert severity="warning">
            <AlertTitle>
              You do not have the neccessary permissions to use this widget.
            </AlertTitle>
            Only the doctor assigned to this treatment can sign the prescription
            and cttp documents.
          </Alert>
        </Box>
      )}
      {canSign && hasPermissions && (
        <Paper
          variant="outlined"
          style={{
            display: "grid",
            width: "100%",
            height: "668px",
            margin: "16px auto",
            gridTemplateRows: "auto 1fr",
          }}
        >
          <div
            style={{
              background: "transparent",
            }}
          >
            {!loading && (
              <Box padding={1}>
                <Alert severity="info">
                  If changes are needed in the prescription or Consent to
                  Treatment Plan you can request the changes from the processing
                  team or update the treatment yourself from the treatment
                  record.
                </Alert>
                {consultationText && (
                  <Alert severity="info" style={{ marginTop: "8px" }}>
                    {consultationText}
                  </Alert>
                )}
              </Box>
            )}
            {tabList.length > 0 && (
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="document tabs"
                style={{ background: "#E3E3E3" }}
              >
                {tabList.map((tab) => {
                  return (
                    <Tab
                      key={tab.id}
                      label={tab.label}
                      value={tab.id}
                      disabled={areAllDocsSigned}
                    />
                  );
                })}
              </Tabs>
            )}
          </div>
          <div style={{ background: "transparent" }}>
            {errorMessage && (
              <Box
                height="50vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{
                    padding: "8px",
                    textAlign: "center",
                    maxWidth: "490px",
                  }}
                >
                  {defaultErrorMessage}
                </Typography>
              </Box>
            )}
            {!errorMessage && (
              <>
                {!loading && areAllDocsSigned ? (
                  <Box
                    height="50vh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography style={{ padding: "8px", textAlign: "center" }}>
                      {tabList.length === 1
                        ? "Document is signed"
                        : "All Documents completely signed"}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {!loading && fetchingSigningUrl && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                    {signingUrl && (
                      <iframe
                        title="Document Signing"
                        src={`${signingUrl}?frameorigin=${domain}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "#EFEFEF",
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Paper>
      )}
    </div>
  );
};
