import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";

export const ActionButtons = ({
  closeWidget,
  createdTreatmentUrl,
  hasInProgressTreatment,
}: {
  closeWidget: () => void;
  createdTreatmentUrl: string;
  hasInProgressTreatment: boolean;
}) => {
  const { isValid, submitForm, isSubmitting } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      {!createdTreatmentUrl && !hasInProgressTreatment && (
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "120px",
          }}
          color="primary"
          variant="contained"
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </Button>
      )}
      {createdTreatmentUrl && (
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "120px",
          }}
          variant="contained"
          color="primary"
          component="a"
          href={createdTreatmentUrl}
          target="_blank"
        >
          View Treatment
        </Button>
      )}
    </Box>
  );
};
