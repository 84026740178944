import {
  TestProfilesObr,
  BloodDrawer,
  PatchPathwaySpecifyDetails,
  GenderEnum,
  StatusEnum,
  BloodDrawBilledStatus,
  Lab,
} from "@deep-consulting-solutions/bmh-constants";
import * as Yup from "yup";
import { format } from "date-fns";

import { getENText } from "helpers";
import { DATE_REQUEST_FORMAT } from "configs";

import { ZohoPathway, Contact } from "types";
import {
  TestProfilesObrWithMappedLabs,
  FormValues,
  ZohoPathwayTestProfile,
  AfterResultConsultationEnum,
} from "./types";

export const mapLabsInTestProfiles = (
  rawTestProfiles?: TestProfilesObr[] | null
): TestProfilesObrWithMappedLabs[] => {
  return (rawTestProfiles || []).map((testProfile) => {
    const mapped: TestProfilesObrWithMappedLabs["mappedLabs"] = {};
    (testProfile.labs || []).forEach(({ id }) => {
      mapped[id] = true;
    });
    return {
      ...testProfile,
      mappedLabs: mapped,
    };
  });
};

export const getTestProfilesFromPathwayTestProfiles = (
  pathwayTestProfiles: ZohoPathwayTestProfile[],
  testProfiles: TestProfilesObrWithMappedLabs[]
) => {
  const mapped: { [bmhObrCode: string]: TestProfilesObrWithMappedLabs } = {};
  testProfiles.forEach((tp) => {
    mapped[tp.bmhObrCode] = tp;
  });

  const results: TestProfilesObrWithMappedLabs[] = [];
  pathwayTestProfiles.forEach((ptp) => {
    if (ptp.Test_Profiles.name && mapped[ptp.Test_Profiles.name]) {
      results.push(mapped[ptp.Test_Profiles.name]);
    }
  });

  return results;
};

export const validateTestProfilesTestToLab = (
  testProfiles: FormValues["testProfiles"],
  lab: FormValues["lab"]
) => {
  if (!lab) return true;

  const invalids: string[] = [];
  const inactives: string[] = [];
  testProfiles.forEach((p) => {
    if (p.status === StatusEnum.Inactive) inactives.push(p.bmhObrCode);
    if (!p.mappedLabs[lab.id]) {
      invalids.push(p.bmhObrCode);
    }
  });

  if (!invalids.length && !inactives.length) return true;
  const messages = [
    invalids.length
      ? getENText("validation.testProfiles.someNotAssociated", {
          testProfiles: invalids.join(", "),
          toBe: invalids.length > 1 ? "are" : "is",
          lab: lab.name,
        })
      : "",
    inactives.length
      ? getENText("validation.testProfiles.someNotActive", {
          testProfiles: inactives.join(", "),
          toBe: inactives.length > 1 ? "are" : "is",
          lab: lab.name,
        })
      : "",
  ];
  return messages.join(" ");
};

export const validateTestProfilesTest = ({ testProfiles, lab }: FormValues) => {
  return Yup.array()
    .required(getENText("validation.testProfiles.required"))
    .min(1, getENText("validation.testProfiles.required"))
    .test({
      name: "are-associated",
      test() {
        const message = validateTestProfilesTestToLab(testProfiles, lab);
        if (message === true) {
          return true;
        }
        return this.createError({
          message,
          path: "testProfiles",
        });
      },
    });
};

export const isPathwayAddressAvailable = (pathway: ZohoPathway) => {
  return (
    pathway.Shipping_Apartment_Flat_Door_Other ||
    pathway.Shipping_City ||
    pathway.Shipping_Country ||
    pathway.Shipping_Post_Code ||
    pathway.Shipping_Province ||
    pathway.Shipping_Street
  );
};

export const isCountryChanged = (
  country?: string,
  pathwayCountry?: string | null
) => {
  if (pathwayCountry) {
    return country !== pathwayCountry;
  }
  return !!country;
};

export const isAddressChanged = (
  {
    city,
    country,
    other,
    region,
    street,
    zip,
  }: Pick<
    FormValues,
    "city" | "country" | "other" | "region" | "street" | "zip"
  >,
  pathway: ZohoPathway,
  client: Contact | null
) => {
  if (isPathwayAddressAvailable(pathway)) {
    return (
      city !== (pathway.Shipping_City || "") ||
      isCountryChanged(country, pathway.Shipping_Country) ||
      region !== (pathway.Shipping_Province || "") ||
      other !== (pathway.Shipping_Apartment_Flat_Door_Other || "") ||
      street !== (pathway.Shipping_Street || "") ||
      zip !== (pathway.Shipping_Post_Code || "")
    );
  }
  return (
    street !== (client?.shippingAddress?.street || "") ||
    city !== (client?.shippingAddress?.city || "") ||
    isCountryChanged(country, client?.shippingAddress.country) ||
    region !== (client?.shippingAddress.region || "") ||
    other !== (client?.shippingAddress.street2 || "") ||
    zip !== (client?.shippingAddress.zip || "")
  );
};

export const shouldShowShippingAddress = (bd?: BloodDrawer | null) => {
  if (!bd) return false;
  return !bd.providesOwnKit;
};

export const showBDFeeCollectionRequired = (
  bdPrepaidByBMH: boolean,
  feeCollected: boolean
) => {
  return bdPrepaidByBMH && !feeCollected;
};

export const composeSpecifyDetailsBody = (
  values: FormValues,
  client: Contact,
  pathway: ZohoPathway,
  pathwayTestProfileRecords: ZohoPathwayTestProfile[]
) => {
  const requireAddress = shouldShowShippingAddress(values.bloodDrawer);
  const updateClientToo =
    isAddressChanged(values, pathway, client) && values.updateAddressForClient;

  const pathwayTestProfilesToDelete: string[] = [];
  const testProfilesToLink: string[] = [];
  values.testProfiles.forEach((tp) => {
    if (
      !pathwayTestProfileRecords.find((pt) => pt.Test_Profiles.id === tp.zohoID)
    ) {
      testProfilesToLink.push(tp.zohoID);
    }
  });
  pathwayTestProfileRecords.forEach((r) => {
    if (!values.testProfiles.find((tp) => tp.zohoID === r.Test_Profiles.id)) {
      pathwayTestProfilesToDelete.push(r.id);
    }
  });

  const body: typeof PatchPathwaySpecifyDetails["Body"] = {
    pathway: {
      Diagnostic_Laboratory: { id: values.lab!.zohoID },
      Blood_Drawer: { id: values.bloodDrawer!.zohoID },
      Blood_Taking_Option: values.bloodTakingOption || null,
      Offer_MCM_Call:
        values.afterResultConsultation === AfterResultConsultationEnum.PCM,
      Doctor_Consultation_Needed:
        values.afterResultConsultation === AfterResultConsultationEnum.DOCTOR,
      Type_of_Order: (values.afterResultConsultation !==
      AfterResultConsultationEnum.NONE
        ? values.orderType
        : null) as any,
      BD_Category: values.bloodDrawer?.category,
      BD_Prepaid_by_BMH: values.bloodDrawer?.isPrepaid,
      BD_Provides_Own_Kits: values.bloodDrawer?.providesOwnKit,
      Send_Order_Form_to_Blood_Drawer:
        values.bloodDrawer?.sendOrderFormToBloodDrawer,
      Special_Notes: values.specialNotes,
      Billed_Status: values.billedStatus || undefined,
      Blood_Draw_Invoice_Number:
        values.billedStatus === BloodDrawBilledStatus.BILLED
          ? values.billInvoiceNumber
          : undefined,
      ...(requireAddress && {
        Shipping_Street: values.street,
        Shipping_City: values.city,
        Shipping_Country: values.country,
        Shipping_Province: values.region,
        Shipping_Post_Code: values.zip,
        Shipping_Apartment_Flat_Door_Other: values.other,
      }),
    },
    contact: {
      zohoId: client.zohoID,
      Date_of_Birth: format(values.clientDob!, DATE_REQUEST_FORMAT),
      Gender: values.clientGender === GenderEnum.female ? "Female" : "Male",
      ...(requireAddress &&
        updateClientToo && {
          Shipping_Street: values.street,
          Shipping_Street_2: values.other,
          Shipping_City: values.city,
          Shipping_Country: values.country,
          Shipping_State: values.region,
          Shipping_Zip: values.zip,
        }),
    },
    pathwayTestProfilesToDelete,
    testProfilesToLink,
  };

  return body;
};

export const getAfterResultConsultationOption = (
  offerMCMCall: boolean,
  doctorConsultationNeeded: boolean
) => {
  if (offerMCMCall) return AfterResultConsultationEnum.PCM;
  if (doctorConsultationNeeded) return AfterResultConsultationEnum.DOCTOR;
  return AfterResultConsultationEnum.NONE;
};

export const cannotDisplaySpecifyDetailsRelatedList = (
  pathway: ZohoPathway
) => {
  return pathway.Stage !== "Pending Contact with Client";
};

const UNITED_KINGDOM = "United Kingdom";

const unitedKingdomCountries = [
  "Wales, United Kingdom",
  "England, United Kingdom",
  "Northern Ireland, United Kingdom",
  "Scotland, United Kingdom",
  "Wales, UK",
  "England, UK",
  "Northern Ireland, UK",
  "Scotland, UK",
  "Wales",
  "England",
  "Scotland",
  "Northern Ireland",
  "UK",
  UNITED_KINGDOM,
];

export const getIsCountryInUnitedKingdom = (value: string | null | undefined) =>
  !!unitedKingdomCountries.find(
    (country) =>
      country.replace(/,/g, "").replace(/ /g, "").toLowerCase() ===
      value?.replace(/,/g, "").replace(/ /g, "").toLowerCase()
  );

export const transformUnitedKingdomCountries = (
  value: string | null | undefined
) => {
  if (getIsCountryInUnitedKingdom(value)) {
    return UNITED_KINGDOM;
  }
  return value || "";
};

export const getIsCountriesMatch = (country1: string, country2: string) => {
  if (!country1 || !country2) {
    return false;
  }
  const firstCountry = transformUnitedKingdomCountries(country1);
  const secondCountry = transformUnitedKingdomCountries(country2);
  return firstCountry.toLowerCase() === secondCountry.toLowerCase();
};

export const countryListIncludesCountry = (
  countries: string[],
  country: string
) => {
  return !!countries.find((c) => getIsCountriesMatch(c, country));
};

export const getIsCountryAllowed = (
  country?: string | null,
  allowedCountries?: string[],
  notAllowedCountries?: string[]
) => {
  if (!country) {
    return false;
  }
  if (allowedCountries?.length) {
    return countryListIncludesCountry(allowedCountries, country);
  }

  if (notAllowedCountries?.length) {
    return !countryListIncludesCountry(notAllowedCountries, country);
  }

  return false;
};

export const getIsTestProfileAndCountryInvalid = (
  testProfile: TestProfilesObrWithMappedLabs,
  labs: Lab[],
  country?: string | null
) => {
  if (!testProfile.labs) {
    return true;
  }
  const labsWithCountry = testProfile.labs.filter(({ id }) => {
    const lab = labs.find((l) => l.id === id);
    if (!lab) {
      return true;
    }
    return getIsCountryAllowed(
      country,
      lab.allowedCountries,
      lab.notAllowedCountries
    );
  });

  if (!labsWithCountry.length) {
    return true;
  }

  return false;
};

export const getIsTestProfilesAndCountryInvalid = (
  testProfiles: TestProfilesObrWithMappedLabs[] | null,
  labs: Lab[],
  country?: string | null
) => {
  if (!testProfiles) {
    return false;
  }
  return testProfiles.some((testProfile) => {
    return getIsTestProfileAndCountryInvalid(testProfile, labs, country);
  });
};
