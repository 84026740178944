import { BloodTakingOptionEnum } from "@deep-consulting-solutions/bmh-constants";
import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

const useStyle = makeStyles(() => ({
  link: {
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "lowercase",
    color: "#338cf0",
    padding: 0,
  },
}));

interface Props {
  invoiceLink?: string;
  newBloodOrderLink?: string;
  isFingerprick?: boolean;
  bloodTakingOption?: BloodTakingOptionEnum;
  isBloodTakingOptionChanged?: boolean;
  retestInvoiceLink?: string;
  isPayForBloodDrawerEmailSent?: boolean;
  isPayForRetestEmailSent?: boolean;
  isBloodDrawerProvidesOwnKitSuccessVariant?: boolean;
}

export const Success = ({
  invoiceLink,
  newBloodOrderLink,
  isFingerprick = false,
  bloodTakingOption,
  isBloodTakingOptionChanged = false,
  retestInvoiceLink,
  isPayForBloodDrawerEmailSent = false,
  isPayForRetestEmailSent = false,
  isBloodDrawerProvidesOwnKitSuccessVariant = false,
}: Props) => {
  const handleOpenURL = useCallback((url: string) => {
    window.open(url, "blank");
  }, []);

  const stage = useMemo(
    () =>
      isBloodDrawerProvidesOwnKitSuccessVariant
        ? "Pending Contact with Client"
        : "Awaiting Blood Kit Payment.",
    [isBloodDrawerProvidesOwnKitSuccessVariant]
  );

  const classes = useStyle();

  return (
    <>
      {!!invoiceLink && !isBloodDrawerProvidesOwnKitSuccessVariant && (
        <Box>
          <Typography>
            An invoice was created for the blood draw kit.
            <br />
            <a
              type="link"
              href={invoiceLink}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {invoiceLink}
            </a>
          </Typography>
        </Box>
      )}

      {!!retestInvoiceLink && !isBloodDrawerProvidesOwnKitSuccessVariant && (
        <Box>
          <Typography>
            An invoice was created for the retest.
            <br />
            <a
              type="link"
              href={retestInvoiceLink}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleOpenURL(retestInvoiceLink)}
            >
              {retestInvoiceLink}
            </a>
          </Typography>
        </Box>
      )}
      {!!newBloodOrderLink && (
        <Box mt={2}>
          <Typography>
            A reset blood test order was created in stage {stage}
            <br />
            <a
              type="link"
              href={newBloodOrderLink}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {newBloodOrderLink}
            </a>{" "}
            {isBloodDrawerProvidesOwnKitSuccessVariant
              ? "and it will be progressed automatically."
              : null}
          </Typography>
        </Box>
      )}
      {isPayForBloodDrawerEmailSent &&
        !isBloodDrawerProvidesOwnKitSuccessVariant && (
          <Box mt={2}>
            <Typography>
              An email was sent to the client to inform him that he needs to pay
              for the blood draw kit for{" "}
              {isFingerprick && isBloodTakingOptionChanged
                ? "Venepuncture"
                : bloodTakingOption}{" "}
              test with the invoice attached to it and the payment link.
            </Typography>
          </Box>
        )}

      {isPayForRetestEmailSent && !isBloodDrawerProvidesOwnKitSuccessVariant && (
        <Box mt={2}>
          <Typography>
            An email was sent to the client to inform him that he needs to pay
            for the retest with the invoice attached and the payment link.
          </Typography>
        </Box>
      )}
    </>
  );
};
