import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";

import { zohoServices } from "services";
import { useOrderFormPrint, useWidgetResize } from "hooks";
import Loader from "components/Loader";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import { RecordsLimitExceeded } from "components/RecordsLimitExceeded";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";

const recordLimit = 200;

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
  },
  text: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    textAlign: "right",
  },
  lastButton: {
    marginLeft: s(1),
  },
}));

const PathwayListGenerateForm = () => {
  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const ids = useSelector(zohoSelectors.getIds);

  const { printPDF } = useOrderFormPrint();

  const isLimitExceeded = useMemo(() => ids.length > recordLimit, [ids]);

  const onProceedClick = useCallback(() => {
    printPDF({
      setLoading,
    });
  }, [printPDF]);

  const closeWidget = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  useEffect(() => {
    resize();
  }, [resize]);

  const classes = useStyle();

  return (
    <WidgetButtonWrapper>
      <Container innerRef={wrapperRef}>
        {isLimitExceeded && (
          <RecordsLimitExceeded
            onClose={closeWidget}
            numberOfRecordsSelected={ids.length}
            maxNumberOfRecordsAllowed={recordLimit}
          />
        )}
        {!isLimitExceeded && (
          <>
            <Loader open={loading} />
            <Typography variant="h5">
              Blood Test Order Forms Printing for Multiple Records
            </Typography>
            <Box className={classes.section}>
              <Typography variant="body1" className={classes.text}>
                Blood Test Order forms will be generated for selected Blood Test
                Order records, after which you will be able to print it as a
                single document on different pages.
              </Typography>
            </Box>
            <Box className={classes.buttons}>
              <Button variant="text" color="primary" onClick={closeWidget}>
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.lastButton}
                onClick={onProceedClick}
              >
                Proceed
              </Button>
            </Box>
          </>
        )}
      </Container>
    </WidgetButtonWrapper>
  );
};

export default PathwayListGenerateForm;
