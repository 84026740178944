import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, FormikConfig } from "formik";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { notifications, zohoServices } from "services";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import Loader from "components/Loader";
import {
  getTreatmentOrderDetails,
  sendForDispensing,
  sendForSigning,
} from "services/treatment-orders/complete-details";
import { AxiosError } from "axios";
import { ActionButtons } from "./ActionButtons";
import { CompleteOrderForm } from "./CompleteOrderForm";
import { initValues, orderDetailsValidationSchema } from "./constants";
import { InitialValues, LegacyPrescription, LegacyTreatment } from "./types";
import {
  getFormattedOrderDetailsData,
  constructOrderItemPrescriptionItemPairs,
  getUpdatedInitialValues,
  getSubmitPayload,
} from "./utils";

export const CompleteOrderDetails = () => {
  const [fetchError, setFetchError] = useState("");
  const [loading, setLoading] = useState(true);
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);
  const [initialValues, setInitialValues] = useState<InitialValues>(initValues);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    return "form";
  }, [fetchError]);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setLoading(true);
        const payload = getSubmitPayload({
          formattedOrderItems: values.formattedOrderItems,
          legacyTreatment: values.legacyTreatment as LegacyTreatment,
          legacyPrescription: values.legacyPrescription as LegacyPrescription,
          treatmentOrderId,
        });

        const shouldSendForSigning = values.formattedOrderItems.some(
          (f) => f.remainingQty === 0
        );

        if (shouldSendForSigning) {
          await sendForSigning(payload);
        } else {
          await sendForDispensing(payload);
        }
        closeWidget(true);
      } catch (error) {
        const message =
          "Something went wrong and the action could not be completed.";
        notifications.notifyError(message);
        setFetchError(message);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentOrderId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      setFetchError("");
      const res = await getTreatmentOrderDetails(treatmentOrderId);
      if (res) {
        const {
          orderDetails,
          shippingAddress,
          legacyTreatments,
          signedLegacyPrescriptions,
          prescriptionItems,
          treatmentOrderItems,
        } = getFormattedOrderDetailsData(res);
        const {
          formattedOrderItems,
          everyItemHasPair,
          orderItemPrescriptionItemPairs,
        } = constructOrderItemPrescriptionItemPairs(
          treatmentOrderItems,
          prescriptionItems
        );
        const updatedInitialValues = getUpdatedInitialValues({
          orderDetails,
          shippingAddress,
          legacyTreatments,
          signedLegacyPrescriptions,
          prescriptionItems,
          formattedOrderItems,
          everyItemHasPair,
          orderItemPrescriptionItemPairs,
        });
        setInitialValues(updatedInitialValues);
      }
    } catch (error) {
      const err = error as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={orderDetailsValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle title="Complete Order Details" />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}
          {view === "form" ? <CompleteOrderForm /> : null}
        </TreatmentWidgetContent>
        <TreatmentWidgetActions>
          <ActionButtons closeWidget={closeWidget} />
        </TreatmentWidgetActions>
      </TreatmentWidgetLayout>
    </Formik>
  );
};
