/* eslint-disable @typescript-eslint/no-unsafe-call */
import { n8nClient } from "apis";
import {
  GetCouriersResponse,
  GetTreatmentOrderResponse,
} from "widgets/ConfirmOrderPacking/types";
import { formatTreatmentOrder } from "widgets/ConfirmOrderPacking/utils";

export const confirmOrderPacking = async (payload: any) => {
  const res = await n8nClient.post(`/treatment-order`, payload);
  return res.data;
};

export const getTreatmentOrder = async (treatmentOrderId: string) => {
  try {
    const payload = {
      treatmentOrders: [treatmentOrderId],
    };
    const res = await n8nClient.post<GetTreatmentOrderResponse>(
      `/treatmentOrders`,
      payload
    );
    const [rawTreatmentOrder] = res.data.data;
    const formatted = formatTreatmentOrder(rawTreatmentOrder);
    return formatted;
  } catch (error) {
    return null;
  }
};

export const getCouriers = async () => {
  try {
    const res = await n8nClient.get<GetCouriersResponse>(`/get-couriers`);
    const rawCourierRes = res.data.data;
    const formatted = rawCourierRes.map((c) => ({
      id: c.id,
      label: c.Name,
      value: c.Name,
      title: c.Name,
      manifestRequired: c.Manifest_Required,
    }));
    return formatted;
  } catch (error) {
    return [];
  }
};

export const getDeliveryChangeNotes = async (treatmentOrderId: string) => {
  try {
    const payload = {
      treatmentOrderId,
    };
    const res = await n8nClient.post<{ data: string }>(
      `/treatment-order/delivery-change-note`,
      payload
    );
    return res.data.data;
  } catch (error) {
    return "";
  }
};
