import React from "react";
import { Container, ContainerProps } from "@material-ui/core";

export interface WidgetButtonWrapperProps extends ContainerProps {
  isBlueprint?: boolean;
}

export const WidgetButtonWrapper: React.FC<WidgetButtonWrapperProps> = ({
  isBlueprint,
  children,
  ...props
}) => {
  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...(isBlueprint
          ? {
              minHeight: "100vh",
            }
          : { height: "100vh" }),
      }}
      {...props}
    >
      {children as any}
    </Container>
  );
};

export default WidgetButtonWrapper;
