import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import { useSelector } from "react-redux";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { resumeTreatmentValidationSchema } from "helpers";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ZohoTreatment } from "types";
import { fetchCreateTreatmentWidgetDetails } from "services/treatments";
import {
  getTreatmentClientId,
  resumeTreatment,
} from "services/treatments/resume-treatment";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { ResumeTreatmentForm } from "./ResumeTreatmentForm";
import { WarningAlert } from "./WarningAlert";
import { ActionButtons } from "./ActionButtons";
import { InitialValues, View } from "./types";

export const ResumeTreatment = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<View>("form-view");
  const [treatmentId] = useSelector(zohoSelectors.getIds);
  const [hasInProgressTreatment, setHasInProgressTreatment] = useState(false);
  const [
    inProgressTreatment,
    setInProgressTreatment,
  ] = useState<ZohoTreatment | null>(null);
  const [createdTreatmentUrl] = useState("");

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          reason: values.reason,
          TreatmentID: treatmentId,
        };
        await resumeTreatment(payload);
      } catch (error) {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const clientId = await getTreatmentClientId(treatmentId);
      if (clientId) {
        const treatmentRes = await fetchCreateTreatmentWidgetDetails(clientId);
        if (treatmentRes.hasInProgressTreatment) {
          setView("inprogress-view");
          setHasInProgressTreatment(true);
          setInProgressTreatment(treatmentRes.inProgressTreatments[0]);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={{
        reason: "",
      }}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={resumeTreatmentValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle title="Resume Treatment" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              height="400px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {view === "inprogress-view" && (
                <WarningAlert inProgressTreatment={inProgressTreatment} />
              )}
              {view === "form-view" && <ResumeTreatmentForm />}
              {view === "success-view" && (
                <Alert severity="success">
                  <AlertTitle>
                    New Treatment Plan Created Successfully.
                  </AlertTitle>
                  Medicine product packages are calculated and Processing team
                  has been notified to confirm selection for prescription
                  generation
                </Alert>
              )}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons
              closeWidget={closeWidget}
              hasInProgressTreatment={hasInProgressTreatment}
              createdTreatmentUrl={createdTreatmentUrl}
            />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
