import moment from "moment";
import { InitialValues } from "./types";

export const choices = [
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "NO",
    label: "NO",
  },
];

export const initialValues: InitialValues = {
  resumptionDate: moment().startOf("day").format("YYYY-MM-DD"),
  reason: "",
  choice: "NO",
};
