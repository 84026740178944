import { Alert, AlertTitle } from "@material-ui/lab";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import { MigratedLegacyTreatment } from "types";

interface Props {
  newTreatment?: MigratedLegacyTreatment;
}

export const MigrateTreatmentSuccess: React.FC<Props> = ({ newTreatment }) => {
  const { values } = useFormikContext();
  const manualOrAuto = useMemo(
    () => getValueFromObject("manualOrAutoSelection", values),
    [values]
  );

  if (newTreatment?.recordCrmLink && manualOrAuto === "manual") {
    return (
      <Alert severity="success">
        <AlertTitle>
          The new treatment is created{" "}
          <a
            href={newTreatment.recordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {newTreatment.Name}
          </a>
        </AlertTitle>
        The prescription(s) are being generated then will be sent to Dr{" "}
        {newTreatment?.Doctor?.name} for signing.
      </Alert>
    );
  }

  if (newTreatment?.recordCrmLink && manualOrAuto === "auto") {
    return (
      <>
        <Alert severity="success">
          <AlertTitle>
            The new treatment is created{" "}
            <a
              href={newTreatment.recordCrmLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {newTreatment?.Name}
            </a>
          </AlertTitle>
          The matching medicine products and packages are being calculated
          automatically.
        </Alert>
      </>
    );
  }
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Treatment migration unsuccessful.
    </Alert>
  );
};
