import { TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { getFieldName, getValueFromObject, sortAlphabetically } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption } from "types";

const genderOptions = [
  {
    title: "Male",
    value: "M",
  },
  {
    title: "Female",
    value: "F",
  },
];

export interface GenderFieldProps {
  name?: string;
}
export const GenderField = ({ name }: GenderFieldProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("gender", name), [name]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(() => {
    return sortAlphabetically(genderOptions);
  }, []);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Field
      name={fieldName}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      color="primary"
      getOptionSelected={(option: any, value: any) => {
        return option.value === value.value && option.title === value.title;
      }}
      required
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={fieldName}
          variant="outlined"
          label="Gender"
          helperText={fieldTouched && fieldError}
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
