export default {
  en: {
    "default.notAllowed.widget": "This function is not available",
    "api.noti.info.tokenExpired":
      "Your session has expired. Please login again.",

    "validation.email.required": "Email is required",
    "validation.email.invalid": "Email is invalid",
    "validation.email.exist": "Email already exists",
    "validation.firstName.required": "First name is required",
    "validation.lastName.required": "Last name is required",
    "validation.phone.required": "Phone number is required",
    "validation.phone.invalid": "Phone number is invalid",
    "validation.role.required": "Role is required",
    "validation.password.min":
      "Passwords must be at least 8 characters in length",
    "validation.password.required": "Password is required",
    "validation.repassword.required": "Please confirm your password",
    "validation.repassword.match": "Passwords must match",
    "validation.senderName.required": "Sender Name is required",
    "validation.domain.required": "Domain is required",
    "validation.domain.startWith": "Domain must start with @",
    "validation.domain.invalid": "Domain is invalid",
    "validation.fromEmail.required": "From Email is required",
    "validation.testProfiles.required": "Test Profiles are required",
    "validation.lab.required": "Diagonastic Laboratory is required",
    "validation.bloodDrawer.required": "Blood Drawer is required",
    "validation.bloodTakingOption.required": "Blood Taking Option is required",
    "validation.dob.required": "Date of birth is required",
    "validation.dob.inPast": "Date of birth can only be in the past",
    "validation.gender.required": "Gender is required",
    "validation.orderType.required": "Client Type is required",
    "validation.testProfiles.notAssociated":
      "Test Profiles must be associated with the selected Laboratory",
    "validation.testProfiles.someNotAssociated":
      "{testProfiles} {toBe} not associated with {lab}.",
    "validation.testProfiles.someNotActive": "{testProfiles} {toBe} inactive.",
    "validation.testProfiles.associationNotctive":
      "Test Profiles association with the selected Laboratory must be active",
    "validation.lab.notSameAsBloodDrawer":
      "Blood Drawer must be the same as Laboratory",
    "validation.lab.notExist": "Laboratory does not exist",
    "validation.bloodDrawer.notExist": "Blood Drawer does not exist",
    "validation.testProfiles.notExist":
      "One of the Test Profiles does not exist",
    "validation.street.required": "Street is required",
    "validation.city.required": "City is required",
    "validation.region.required": "Region is required",
    "validation.zip.required": "Postal code is required",
    "validation.country.required": "Country is required",

    "settings.field.required": "Required field",
    "settings.field.valueExist": "Value already exists",
    "settings.field.number": "Must be a number",
    "settings.noti.success.updated": "Updated successfully",
    "settings.noti.success.created": "Created successfully",
    "settings.noti.success.deleted": "Deleted successfully",
    "settings.noti.success.acknowledge": "Update acknowledged",
    "settings.noti.success.changeStatus": "{action} successfully", // action = "Activated" or "Deactivated"

    "emailConfig.noti.success.updated": "Updated successfully",

    "mcm.noti.success.updated":
      "PCM availability intervals updated successfully",
    "mcmSpecial.noti.success.updated":
      "PCM Special availability updated successfully",

    "unmatched.date.todayMax": "Date can not be later than today",

    "results.comparisonView.dismiss": "Comparison list discarded",
    "results.manualAdd.required": "This is required",
    "results.manualAdd.pdf.attached": "The PDF has been successfully attached",
    "results.manualAdd.pdf.notAttached": "The PDF could not be attached",
    "results.manualAdd.added": "Blood test result has been successfully added.",
    "results.manualAdd.added.notify":
      "Blood test result has been successfully added. Notify client to view results if needed.",
    "results.manualAdd.updated":
      "Blood test result has been successfully updated",
    "results.delete.success.single": "1 Blood test result has been deleted",
    "results.delete.success.plural":
      "{count} Blood test results have been deleted",
    "validation.specialNotes.max":
      "Special Notes cannot exceed {max} characters",
    "confirmKitPacking.success": "kit packing confirmed successfully.",

    "retestRequiredNotClientError.reason.required": "Reason is required",
    "reason.required": "Reason is required",

    "validation.shippingCountry.required": "Shipping country is required",
    "validation.shippingCountry.invalid": "Shipping country is invalid",
    "validation.shippingCity.required": "Shipping city is required",
    "validation.shippingStreet.required": "Shipping street is required",
    "validation.lab.invalid": "Lab is invalid",
    "validation.age.required": "Age is required",
    "validation.dob.provideValid": "Provide a valid date of birth",
    "validation.billInvoiceNumber.required":
      "Billing invoice number is required",
    "validation.billedStatus.required": "Billing status is required",
    "validation.afterResultsConsultation.required":
      "After consultation is required",
    "validation.patientSourcedLab.invalid":
      "Please select a valid diagnostic lab",
  },
};
