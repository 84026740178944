import React, { useMemo, useCallback, useState, useEffect } from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { useFormikContext } from "formik";

import { useScript } from "hooks";

import { getCountryCode } from "helpers";
import { getLongAddressObject, DEFAULT_COORDS } from "./helpers";
import { FormValues } from "../types";

(window as any).dummyPlacesLoaded = () => {};

const fieldName = "shippingCity";

interface Props {
  handleBlur?: () => void;
  shippingCityFieldError?: string;
}

export const ShippingCity = ({ handleBlur, shippingCityFieldError }: Props) => {
  const {
    errors: { shippingCity: fieldError },
    touched: { shippingCity: isFieldTouched },
    values: { shippingCountry: countryValue, shippingCity: fieldValue },
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormValues>();

  const googleStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=dummyPlacesLoaded`
  );

  const showFieldError: boolean = useMemo(
    () => !!isFieldTouched && (!!fieldError || !!shippingCityFieldError),
    [isFieldTouched, fieldError, shippingCityFieldError]
  );

  const countryCode = useMemo(() => getCountryCode(countryValue), [
    countryValue,
  ]);

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  const [loadingAddress, setLoadingAddress] = useState(false);

  const {
    ready,
    init,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      location,
      componentRestrictions: countryCode
        ? {
            country: countryCode,
          }
        : undefined,
      radius: 38600000,
    },
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically,
    cache: false,
  });

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
      }
      return undefined;
    },
    [setValue]
  );

  const onAutocompleteChange = useCallback(
    async (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === "select-option") {
        const result = await handleSelect(
          (value as google.maps.places.AutocompletePrediction).description
        );
        if (result) {
          const longAddress = getLongAddressObject(result);

          const street = `${longAddress.street_number || ""}${
            longAddress.route
              ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
              : ""
          }`;
          setValue(street, false);
          setFieldValue(
            fieldName,
            longAddress.locality ||
              longAddress.administrative_area_level_3 ||
              longAddress.administrative_area_level_4 ||
              longAddress.postal_town ||
              longAddress.neighborhood ||
              longAddress.sublocality ||
              ""
          );
          if (handleBlur) {
            handleBlur();
          }
        }
      } else if (reason === "clear") {
        setFieldValue(fieldName, "");
      } else if (reason === "create-option") {
        //
      } else if (reason === "remove-option") {
        //
      } else if (reason === "blur") {
        //
      }
    },
    [handleSelect, setValue, setFieldValue, handleBlur]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = ((value as unknown) as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setValue(value);
      setFieldValue(fieldName, value);
    },
    [setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(fieldName, true);
    if (handleBlur) {
      handleBlur();
    }
  }, [setFieldTouched, handleBlur]);

  useEffect(() => {
    if (googleStatus === "ready") {
      init();
    }
  }, [init, googleStatus]);

  useEffect(() => {
    if (ready) {
      setLocation(
        new google.maps.LatLng(DEFAULT_COORDS.lat, DEFAULT_COORDS.lng)
      );
    }
  }, [ready]);

  const disabled = loadingAddress || isSubmitting;
  const otherFieldDisabled = !countryValue || disabled;

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction, false, false, true>
      freeSolo
      noOptionsText="No Results Found"
      value={fieldValue}
      getOptionSelected={getOptionSelected}
      onChange={onAutocompleteChange}
      inputValue={fieldValue}
      onInputChange={onInputChange}
      loading={placesLoading}
      disabled={otherFieldDisabled}
      options={data}
      getOptionLabel={(
        option: google.maps.places.AutocompletePrediction | string
      ) => (typeof option === "string" ? option : option.description)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          {...params}
          name={fieldName}
          autoComplete="off"
          onBlur={onInputBlur}
          error={showFieldError}
          helperText={showFieldError && (fieldError || shippingCityFieldError)}
          label="Shipping City"
          required
          InputProps={params.InputProps}
        />
      )}
    />
  );
};
