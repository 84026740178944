import React from "react";
import {
  styled,
  TableCell as MUITableCell,
  TableCellProps,
} from "@material-ui/core";

export const tableCellStyle = {
  borderRight: `1px solid #e0e0e0`,
};

export const ActionTableCell = styled(MUITableCell)(({ theme }) => ({
  ...tableCellStyle,
  width: 108,
  minWidth: 108,
  padding: `3px 0 !important`,
  paddingRight: `${theme.spacing(0.5)} !important`,
}));

export const TableCell = ({ style, ...props }: TableCellProps) => {
  return (
    <MUITableCell
      align="center"
      style={{
        ...style,
        borderRight: `1px solid #e0e0e0`,
      }}
      {...props}
    />
  );
};
