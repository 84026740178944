import {
  Box,
  Chip,
  colors,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import React, { useMemo } from "react";
import { ZohoMedUsageUnit, ZohoSelectedProduct } from "types";

interface StyleProps {
  selfSupply: boolean;
}

const useStyle = makeStyles<Theme, StyleProps>(
  ({ palette: p, typography }) => ({
    selfSupply: {
      color: ({ selfSupply }) =>
        selfSupply ? colors.green[500] : colors.red[500],
    },
    chip: {
      background: p.success.dark,
      color: "white",
      fontSize: typography.caption.fontSize,
    },
  })
);

interface Props {
  selectedProduct: ZohoSelectedProduct;
  isInjection?: boolean;
  isBacteriostatic?: boolean;
  isInjectionBundle?: boolean;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
}

export const DeliveredProductDetails = ({
  selectedProduct,
  isInjection = false,
  isBacteriostatic = false,
  isInjectionBundle = false,
  medUsageUnits,
}: Props) => {
  const classes = useStyle({ selfSupply: selectedProduct.Self_Supply });

  const isChildProduct = useMemo(() => isBacteriostatic || isInjectionBundle, [
    isBacteriostatic,
    isInjectionBundle,
  ]);

  const billingCycle = useMemo(() => {
    if (isChildProduct) {
      return "";
    }
    if (selectedProduct.Only_Once) {
      return "Only Once";
    }
    if (selectedProduct.Billing_Cycle_Period_X === 1) {
      return `Every ${selectedProduct.Billing_Cycle_Unit.replace(
        "Months",
        "month"
      )
        .replace("Weeks", "week")
        .replace("Days", "day")}`;
    }
    return `Every ${
      selectedProduct.Billing_Cycle_Period_X
    } ${selectedProduct.Billing_Cycle_Unit?.toLowerCase()}`;
  }, [
    selectedProduct.Only_Once,
    selectedProduct.Billing_Cycle_Unit,
    selectedProduct.Billing_Cycle_Period_X,
    isChildProduct,
  ]);

  const discardRemaining = useMemo(() => {
    if (!selectedProduct.product?.Med_Usage_Unit?.id) {
      return false;
    }
    const productMedUsageUnit =
      medUsageUnits[selectedProduct.product?.Med_Usage_Unit?.id];
    return productMedUsageUnit.Discard_Remaining;
  }, [selectedProduct.product?.Med_Usage_Unit?.id, medUsageUnits]);
  return (
    <Box>
      <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
        <Grid item>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <TitleValue
                title="Product:"
                value={selectedProduct.Product?.name}
              />
            </Grid>
            <Grid item xs={2}>
              <TitleValue
                title="Quantity:"
                value={`${selectedProduct.Quantity} ${
                  selectedProduct.product?.Usage_Units_Per_Sellable_Unit &&
                  selectedProduct.product?.Med_Usage_Unit?.name
                    ? "X"
                    : ""
                } ${
                  selectedProduct.product?.Usage_Units_Per_Sellable_Unit || ""
                } ${selectedProduct.product?.Med_Usage_Unit?.name || ""}`}
              />
            </Grid>
            <Grid item xs={2}>
              <TitleValue
                title="Unit Price:"
                value={`£ ${Number(
                  selectedProduct.product?.Unit_Price || 0
                ).toFixed(2)}`}
              />
            </Grid>
            <Grid item xs={2}>
              <TitleValue
                title="Custom Unit Price:"
                value={`£ ${Number(
                  selectedProduct.Custom_Unit_Price || 0
                ).toFixed(2)}`}
              />
            </Grid>
            <Grid item xs={2}>
              <TitleValue
                title="Price:"
                value={`£ ${Number(
                  (selectedProduct.Custom_Unit_Price ||
                    selectedProduct.product?.Unit_Price ||
                    0) * (selectedProduct.Quantity || 0)
                ).toFixed(2)}`}
              />
            </Grid>
            {!isChildProduct ? (
              <Grid item xs={2}>
                <TitleValue title="Billing Cycle:" value={billingCycle} />
              </Grid>
            ) : null}
            <Grid item xs={2}>
              <TitleValue
                title="Self Supplied:"
                value={
                  <Typography component="span" className={classes.selfSupply}>
                    {selectedProduct.Self_Supply ? "Yes" : "No"}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TitleValue
                title="Pharmacy:"
                value={selectedProduct.product?.Pharmacy?.name || "N/A"}
              />
            </Grid>
            {!isChildProduct && isInjection ? (
              <Grid item xs={2}>
                <TitleValue
                  title="Dosing Volume:"
                  value={selectedProduct.product?.Dosage_Per_ml}
                />
              </Grid>
            ) : null}
            {isBacteriostatic ? (
              <Grid item xs={2}>
                <TitleValue
                  title="Unit Volume:"
                  value={selectedProduct.product?.Usage_Unit_Volume_ml || "N/A"}
                />
              </Grid>
            ) : null}
            {isInjectionBundle ? (
              <>
                <Grid item xs={2}>
                  <TitleValue
                    title="Enough For:"
                    value={selectedProduct.product?.Enough_For || "N/A"}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TitleValue
                    title="Syringe Volume:"
                    value={selectedProduct.product?.Syringe_Volume_ml || "N/A"}
                  />
                </Grid>
              </>
            ) : null}
            {discardRemaining ? (
              <Grid item xs={2}>
                <TitleValue title="" value={<span>(Discard Remaining)</span>} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Chip className={classes.chip} label="DELIVERED" />
        </Grid>
      </Grid>
    </Box>
  );
};
