import React, { useCallback, useEffect, useState } from "react";
import { Box, Collapse, createStyles, makeStyles } from "@material-ui/core";
import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import { useFormikContext } from "formik";
import { Alert } from "@material-ui/lab";
import { Info } from "@material-ui/icons";
import { ShippingCity } from "./ShippingCity";
import { FormValues, ZohoVariables } from "../types";
import { OfferingNurseInstructions } from "./OfferingNurseInstructions";
import { OfferingNurseBloodDrawerOptions } from "./OfferingNurseBloodDrawerOptions";
import { CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW } from "../constants";

interface Props {
  labName?: string;
  bloodDrawers: BloodDrawer[];
  initialShippingCity: string;
  variables: Record<ZohoVariables, string>;
  showOfferingNurseService: boolean;
  shippingCityFieldError?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      fontWeight: 500,
      "& .MuiAlert-icon": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
    },
  })
);

export const OfferingNurseService = ({
  labName,
  bloodDrawers,
  initialShippingCity,
  variables,
  showOfferingNurseService,
  shippingCityFieldError,
}: Props) => {
  const {
    values: { shippingCity },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const [
    isClientWantsSourcedBloodDrawerSelectDisabled,
    setIsClientWantsSourcedBloodDrawerSelectDisabled,
  ] = useState(false);

  const onClientWantsSourcedBloodDrawerSelect = useCallback(() => {
    setFieldValue("nurseBloodDrawer", CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW);
  }, [setFieldValue]);

  const handleBlur = useCallback(() => {
    if (!bloodDrawers.length && shippingCity) {
      onClientWantsSourcedBloodDrawerSelect();
    } else {
      setIsClientWantsSourcedBloodDrawerSelectDisabled(false);
    }
  }, [
    onClientWantsSourcedBloodDrawerSelect,
    bloodDrawers.length,
    shippingCity,
  ]);

  useEffect(() => {
    if (
      !bloodDrawers.length &&
      initialShippingCity &&
      showOfferingNurseService
    ) {
      onClientWantsSourcedBloodDrawerSelect();
      setIsClientWantsSourcedBloodDrawerSelectDisabled(true);
    } else {
      setFieldValue("nurseBloodDrawer", "");
      setIsClientWantsSourcedBloodDrawerSelectDisabled(false);
    }
  }, [
    onClientWantsSourcedBloodDrawerSelect,
    initialShippingCity,
    bloodDrawers.length,
    setFieldValue,
    showOfferingNurseService,
  ]);

  const classes = useStyles();

  return (
    <>
      <Box mt={2}>
        <ShippingCity
          handleBlur={handleBlur}
          shippingCityFieldError={shippingCityFieldError}
        />
      </Box>
      <Collapse in={!!shippingCity?.length}>
        <Collapse in={!bloodDrawers.length}>
          <Box mt={2}>
            <Alert
              severity="info"
              icon={<Info fontSize="inherit" />}
              className={classes.alert}
            >
              There are no prepaid partner blood drawers located in the same
              city as the client.
            </Alert>
          </Box>
        </Collapse>
        <Collapse in={!!bloodDrawers.length}>
          <Box mt={2}>
            <OfferingNurseInstructions
              labName={labName}
              bloodDrawers={bloodDrawers}
              variables={variables}
            />
          </Box>
        </Collapse>
        <Box mt={2}>
          <OfferingNurseBloodDrawerOptions
            bloodDrawers={bloodDrawers}
            isClientWantsSourcedBloodDrawerSelectDisabled={
              isClientWantsSourcedBloodDrawerSelectDisabled
            }
          />
        </Box>
      </Collapse>
    </>
  );
};
