import { crhClient, n8nClient } from "apis";
import {
  GetMinimalTreatmentResponse,
  GetTreatmentOrdersResponse,
} from "widgets/Treatments/CancelTreatment/types";

const BASE_URL = "/api/v1/db/data/v1/CustomBackend";

const getMinimalTreatmentQuery = (treatmentId: string) => {
  return `${BASE_URL}/Treatments?where=(ZohoCrmId,eq,${treatmentId})&fields=Id,ZohoCrmId,ActivationDate,CreatedAt`;
};

const getTreatmentOrdersQuery = (treatmentId: string) => {
  return `${BASE_URL}/TreatmentOrders?where=(TreatmentId,eq,${treatmentId})&fields=Id,Name,ZohoCrmId,TreatmentId,OrderDate,PaymentStatus,State`;
};

export async function getMinimalTreatment(treatmentId: string) {
  try {
    const response = await crhClient.post<GetMinimalTreatmentResponse>("/crm", {
      url: getMinimalTreatmentQuery(treatmentId),
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list[0];
  } catch (error) {
    return null;
  }
}

export async function getTreatmentOrders(treatmentDbId: string) {
  try {
    const response = await crhClient.post<GetTreatmentOrdersResponse>("/crm", {
      url: getTreatmentOrdersQuery(treatmentDbId),
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list;
  } catch (error) {
    return [];
  }
}

export const cancelTreatment = async (payload: any) => {
  const res = await n8nClient.post(`/treatments/cancel`, payload);
  return res.data;
};
