import { n8nClient, crhClient } from "apis";
import { AxiosError } from "axios";
import { validStates } from "widgets/NotifyDoctorToSignDoc/constants";
import {
  GetTreatmentsResponse,
  NotifyDoctorResponse,
} from "widgets/NotifyDoctorToSignDoc/types";

export async function notifyDoctorToSign(treatmentId: string) {
  try {
    const response = await n8nClient.get<NotifyDoctorResponse>(
      `/notify-doctor?treatmentId=${treatmentId}`
    );
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return {
      status: "failed" as const,
      message:
        (err.response?.data.message as string) ||
        err.message ||
        "The request failed",
    };
  }
}

export async function hasValidTreatmentState(treatmentId: string) {
  try {
    const response = await crhClient.post<GetTreatmentsResponse>("/crm", {
      url: `/api/v1/db/data/v1/CustomBackend/Treatments?where=(ZohoCrmId,eq,${treatmentId})&fields=State,ZohoCrmId`,
      app: "NOCODB",
      method: "GET",
    });
    const treatmentData = response.data.data.list[0];
    return validStates.includes(treatmentData.State);
  } catch (error) {
    return false;
  }
}
