import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { dispenseDrugsServices, notifications, zohoServices } from "services";
import { AxiosError } from "axios";

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const RequestClarificationFromDoctor: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  const [id] = useSelector(zohoSelectors.getIds);

  const onNotesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNotes(e.target.value);
    },
    []
  );

  const submitDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await dispenseDrugsServices.requestClarification({
        prescriptionZohoId: id,
        pharmacyNotes: notes,
      });
      if (res.error) {
        throw new Error(res.message);
      }
      zohoServices.closePopup(true);
    } catch (error) {
      const err = error as AxiosError;
      notifications.notifyError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, notes]);

  return (
    <TreatmentWidgetLayout defaultWidth={700}>
      <TreatmentWidgetTitle title="Request Clarification From Doctor" />
      <TreatmentWidgetContent>
        <Loader open={loading} />
        <Box>
          <Box mb={2}>
            <Typography variant="body2">
              The Doctor who signed the prescription will be automatically
              notified of your clarification request. Once they respond, you
              will be informed automatically. If they decide to change the
              prescription based on your comments, this prescription will become
              inactive & you will receive a new one for review
            </Typography>
          </Box>
          <TextField
            value={notes}
            onChange={onNotesChange}
            required
            label="Pharmacy Notes"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
          />
        </Box>
      </TreatmentWidgetContent>
      <TreatmentWidgetActions containerClass={classes.actions}>
        <Box textAlign="right">
          <Button
            onClick={() => zohoServices.closePopup(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            disabled={!notes}
            onClick={submitDetails}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Box>
      </TreatmentWidgetActions>
    </TreatmentWidgetLayout>
  );
};
