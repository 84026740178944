import { BloodTestResult } from "@deep-consulting-solutions/bmh-constants";

export type BloodTestResultField = keyof BloodTestResult;

export const TEST_RESULTS_TABLE_META: {
  [key in BloodTestResultField]?: {
    label: string;
    hasSort?: boolean;
  };
} = {
  name: {
    label: "Test Results Name",
    hasSort: true,
  },
  labName: {
    label: "Laboratory",
  },
  bloodTakingOption: {
    label: "Blood Taking Option",
  },
  sampleCollectedOn: {
    label: "Sample Collected  Date",
    hasSort: true,
  },
};

export interface MapObxCodeDetailsLab {
  key: string;
  code: string;
}
export interface ShortMapObxCodeDetails {
  labs: MapObxCodeDetailsLab[];
  unit: string;
  observationID: string;
  normalRange: string;
}

export interface MapObxCodeDetails extends ShortMapObxCodeDetails {
  id: string;
}
export interface MapObxCode {
  id: string;
  bmhName: string;
  details: MapObxCodeDetails[];
}
