import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  Box,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { OrderItemsTable } from "./OrderItemsTable";
import { OrderItemsColumn, OrderItemsDialogProps } from "./types";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  closeButton: {
    position: "absolute",
    right: s(1),
    top: 3,
    color: p.grey[500],
  },
}));

const columns: OrderItemsColumn[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "quantity", label: "Quantity", minWidth: 170 },
  {
    id: "batchAndExpiryDate",
    label: "Batch Numbers and Expiry Dates",
    minWidth: 200,
    render: (value) =>
      Array.isArray(value) ? (
        <Box component="ul" padding="0" margin="0">
          {value.map((s) => (
            <Typography
              key={s}
              component="li"
              variant="body2"
              style={{ marginTop: "4px" }}
            >
              {s}
            </Typography>
          ))}
        </Box>
      ) : (
        value
      ),
  },
];

export const OrderItemsDialog: React.FC<OrderItemsDialogProps> = ({
  closeDialog,
  isOpen,
  loading,
  orderItems,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="order-items-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5">Order Items</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            minHeight="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <OrderItemsTable rows={orderItems} columns={columns} />
        )}
      </DialogContent>
    </Dialog>
  );
};
