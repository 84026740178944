import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { BloodTestResultWithConfirmKitInfo } from "redux/testResults";
import { ConfirmKitPackingTableHeadCell } from "./ConfirmKitPackingTableHeadCell";
import { ConfirmKitPackingTableRow } from "./ConfirmKitPackingTableRow";
import {
  BloodtestResultsTableKeys,
  BLOOD_TEST_RESULTS_TABLE_META,
} from "./helpers";

const useStyle = makeStyles(({ spacing: s }) => ({
  wrapper: {
    marginBottom: s(4),
  },
}));

interface Props {
  bloodTestOrders: BloodTestResultWithConfirmKitInfo[];
  isAllPendingKitShipping: boolean;
}

export const ConfirmKitPackingTable = ({
  bloodTestOrders,
  isAllPendingKitShipping,
}: Props) => {
  const classes = useStyle();

  const showFirstColumn = useMemo(() => !isAllPendingKitShipping, [
    isAllPendingKitShipping,
  ]);

  const headers = useMemo(
    () =>
      Object.keys(BLOOD_TEST_RESULTS_TABLE_META).filter((header) =>
        showFirstColumn ? true : header !== "stage"
      ),
    [showFirstColumn]
  );
  return (
    <div>
      <TableContainer className={classes.wrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((key) => {
                return (
                  <ConfirmKitPackingTableHeadCell
                    key={key}
                    field={key as BloodtestResultsTableKeys}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {bloodTestOrders.map((bloodTestOrder) => {
              return (
                <ConfirmKitPackingTableRow
                  key={bloodTestOrder.id}
                  bloodTestOrder={bloodTestOrder}
                  showFirstColumn={showFirstColumn}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
