import { useContext } from "react";
import { TreatmentWidgetLayoutContext } from "./context";

export const useTreatmentWidgetLayout = () => {
  const {
    setActionsDimension,
    setTitleDimension,
    setContentDimension,
    handleClose,
    showDialog,
    handleCloseShowDialog,
  } = useContext(TreatmentWidgetLayoutContext);

  return {
    setActionsDimension,
    setTitleDimension,
    setContentDimension,
    handleClose,
    showDialog,
    handleCloseShowDialog,
  };
};
