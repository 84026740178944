import { BluePrintStagesEnum } from "@deep-consulting-solutions/bmh-constants";
import { makeStyles } from "@material-ui/core";
import ErrorMessage from "components/ErrorMessage";
import Loader from "components/Loader";
import { RecordsLimitExceeded } from "components/RecordsLimitExceeded";
import { getENText } from "helpers";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  BloodTestResultWithConfirmKitInfo,
  testResultsRequests,
} from "redux/testResults";
import { zohoSelectors } from "redux/zoho";
import { zohoServices } from "services";
import notification from "services/notifications";
import { ConfirmKitPackingActions } from "./ConfirmKitPackingActions";
import { ConfirmKitPackingStatus } from "./ConfirmKitPackingStatus";
import { ConfirmKitPackingSuccess } from "./ConfirmKitPackingSuccess";
import { ConfirmKitPackingTable } from "./ConfirmKitPackingTable";

const recordLimit = 200;

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(4),
    background: p.common.white,
    margin: s(0),
  },
}));

export const ConfirmKitPacking = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [bloodTestOrders, setBloodTestOrders] = useState<
    BloodTestResultWithConfirmKitInfo[]
  >([]);
  const [message, setMessage] = useState("");
  const [isSuccessful] = useState(false);
  const [shouldReloadOnClose, setShouldReloadOnClose] = useState(false);
  const [bulkJobId] = useState<string | number>("");

  const ids = useSelector(zohoSelectors.getIds);

  const isAllPendingKitShipping = useMemo(
    () =>
      bloodTestOrders.every(
        (bloodTestOrder) =>
          bloodTestOrder.stage === BluePrintStagesEnum.PENDING_KIT_SHIPPING
      ),
    [bloodTestOrders]
  );
  const isLimitExceeded = useMemo(() => ids.length > recordLimit, [ids]);

  const fetchBloodTestOrders = useCallback(async () => {
    try {
      if (!ids.length || isLimitExceeded) return;
      setInitLoading(true);
      const res = await testResultsRequests.fetchBloodTestResultsByIDs(ids);
      setBloodTestOrders(res);
    } catch (e) {
      const error = e as Error;
      setMessage(error.message);
    } finally {
      setInitLoading(false);
    }
  }, [ids, isLimitExceeded]);

  const onCloseWidget = useCallback(
    (reload = shouldReloadOnClose) => {
      zohoServices.closePopup(reload === true);
    },
    [shouldReloadOnClose]
  );

  const handleSuccess = useCallback(() => {
    onCloseWidget(true);
    setShouldReloadOnClose(true);
    setLoading(false);
    notification.notifySuccess(getENText("confirmKitPacking.success"));
    setBloodTestOrders((current) =>
      current.map((c) => ({
        ...c,
        stage: BluePrintStagesEnum.PENDING_KIT_SHIPPING,
      }))
    );
  }, [onCloseWidget]);

  const handleError = useCallback(
    async (errors?: { id: string }[]) => {
      if (errors?.length) {
        const updatedBloodTests = await testResultsRequests.fetchBloodTestResultsByIDs(
          ids
        );
        setBloodTestOrders(updatedBloodTests);
      }
      setShouldReloadOnClose((errors?.length || 0) !== ids.length);
      setLoading(false);
      notification.notifyError(
        `An error occurred while confirming kit${
          errors ? " packing for " : ""
        }${errors?.map((error) => error.id).join(", ")}.`
      );
    },
    [ids]
  );

  const onConfirm = useCallback(async () => {
    if (!ids.length) return;

    setLoading(true);

    try {
      const res = await testResultsRequests.patchBloodTestResultShippingKitStatusesToPendingKitShippingByIDs(
        ids
      );
      if (res.status === "completed" && !res.errors) {
        handleSuccess();
      } else if (res.status === "completed" && res.errors) {
        await handleError(res.errors);
      }
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [handleSuccess, ids, handleError]);

  const getIsBulkJobComplete = useCallback(
    async (failCount = 0) => {
      if (!bulkJobId) return;

      setLoading(true);

      try {
        const res = await testResultsRequests.getBulkConfirmKitPackingJobStatus(
          bulkJobId
        );
        if (res.status === "completed" && !res.errors) {
          handleSuccess();
        } else if (res.status === "completed" && res.errors) {
          await handleError(res.errors);
        } else {
          setTimeout(() => {
            getIsBulkJobComplete(0);
          }, 3000);
        }
      } catch {
        if (failCount > 2) {
          await handleError();
        } else {
          getIsBulkJobComplete(Number(failCount) + 2);
        }
      }
    },
    [bulkJobId, handleError, handleSuccess]
  );

  const resizeWidget = useCallback(() => {
    zohoServices
      .resizeWidget({
        width: 1000,
        height: 700,
      })
      .then(() => {
        setTimeout(() => {
          if (
            (bloodTestOrders.length && wrapperRef?.current?.offsetHeight) ||
            (isLimitExceeded && wrapperRef?.current?.offsetHeight)
          ) {
            const height = wrapperRef.current.offsetHeight;

            zohoServices.resizeWidget({
              width: 1000,
              height: height > 700 ? 700 : height,
            });
          }
        }, 100);
      });
  }, [wrapperRef, bloodTestOrders.length, isLimitExceeded]);

  useEffect(() => {
    fetchBloodTestOrders();
  }, [fetchBloodTestOrders]);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  useEffect(() => {
    getIsBulkJobComplete();
  }, [getIsBulkJobComplete]);

  const classes = useStyle();

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      {initLoading && <Loader open />}
      {!initLoading && (
        <>
          <Loader open={loading} isFixed />

          {message ? (
            <ErrorMessage message={message} />
          ) : (
            <>
              {isLimitExceeded ? (
                <RecordsLimitExceeded
                  onClose={onCloseWidget}
                  numberOfRecordsSelected={ids.length}
                  maxNumberOfRecordsAllowed={recordLimit}
                />
              ) : (
                <>
                  {isSuccessful ? (
                    <ConfirmKitPackingSuccess onClose={onCloseWidget} />
                  ) : (
                    <>
                      <ConfirmKitPackingStatus
                        isAllPendingKitShipping={isAllPendingKitShipping}
                      />

                      <ConfirmKitPackingTable
                        bloodTestOrders={bloodTestOrders}
                        isAllPendingKitShipping={isAllPendingKitShipping}
                      />

                      <ConfirmKitPackingActions
                        isAllPendingKitShipping={isAllPendingKitShipping}
                        onConfirm={onConfirm}
                        onCancel={onCloseWidget}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
