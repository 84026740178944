import {
  Button,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { FormikErrors, FormikHelpers, useFormikContext } from "formik";
import {
  getFieldName,
  getManualMedicines,
  getSpecifyDosingInstructionsMedicines,
  getUpdateTreatmentBillingCycleValidation,
  getUpdateTreatmentChanges,
  getValueFromObject,
} from "helpers";
import { TreatmentWidgetActions, useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { TreatmentFormValues, ZohoBMHDoctor } from "types";

const useStyles = makeStyles(({ palette: p }) => ({
  danger: {
    color: p.error.main,
    borderColor: p.error.main,
    cursor: "pointer",
  },
  action: {
    width: "100%",
  },
}));

interface Props {
  view: "success" | "initial-block" | "fetch-error" | "form" | "form-dialog";
  onSubmit: (
    values: any,
    formErrors: FormikErrors<any>,
    setFieldTouched: FormikHelpers<any>["setFieldTouched"],
    finalize?: boolean
  ) => Promise<void>;
  doctor: ZohoBMHDoctor | null;
  name?: string;
  createdTreatmentUrl?: string;
  submitButtonText: string;
  activeTab: number;
  setFormDialog: (form: "products" | "treatment-plan") => void;
  otherMedicineTypeId: string;
  isSuccessWithNoNewTreatmentPlan: boolean;
}

export const UpdateTreatmentActions = ({
  view,
  onSubmit: handleSubmitClick,
  doctor,
  name = "",
  createdTreatmentUrl,
  submitButtonText,
  activeTab,
  setFormDialog,
  otherMedicineTypeId,
  isSuccessWithNoNewTreatmentPlan,
}: Props) => {
  const classes = useStyles();
  const {
    values,
    initialValues,
    errors,
    setFieldTouched,
    setSubmitting,
  } = useFormikContext();
  const { handleClose } = useTreatmentWidgetLayout();

  const isDoctor = useMemo(() => {
    return !!doctor?.id;
  }, [doctor?.id]);

  const fieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const {
    treatmentHasAnyChange,
    requirePackageAndPharmacySelection,
  } = useMemo(
    () => getUpdateTreatmentChanges(values, initialValues, name, activeTab),
    [values, initialValues, name, activeTab]
  );

  const { selfSupplyFailed, isMultipleFailed } = useMemo(() => {
    if (!treatmentHasAnyChange) {
      return {
        selfSupplyFailed: false,
        medicinesWithMoreTreatmentPeriod: [],
        isMultipleFailed: false,
        supplementaryProductsWithMoreTreatmentPeriod: [],
      };
    }
    return getUpdateTreatmentBillingCycleValidation(values, name);
  }, [values, name, treatmentHasAnyChange]);

  const isValid = useMemo(() => {
    if (!treatmentHasAnyChange) {
      return false;
    }
    if (
      activeTab === 1 &&
      (selfSupplyFailed || isMultipleFailed) &&
      !requirePackageAndPharmacySelection
    ) {
      return false;
    }
    return true;
  }, [
    activeTab,
    selfSupplyFailed,
    isMultipleFailed,
    treatmentHasAnyChange,
    requirePackageAndPharmacySelection,
  ]);

  const manualMedicines = useMemo(() => {
    return getManualMedicines(treatmentAreasValues, otherMedicineTypeId);
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasManualMedicine = useMemo(() => {
    return !!manualMedicines.length;
  }, [manualMedicines.length]);

  const specifyDosingInstructionsMedicines = useMemo(() => {
    return getSpecifyDosingInstructionsMedicines(
      treatmentAreasValues,
      otherMedicineTypeId
    );
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasSpecifyDosingInstruction = useMemo(() => {
    return !!specifyDosingInstructionsMedicines.length;
  }, [specifyDosingInstructionsMedicines.length]);

  const showFinalizationButton = useMemo(() => {
    return (
      !isDoctor &&
      !hasManualMedicine &&
      !hasSpecifyDosingInstruction &&
      activeTab === 0
    );
  }, [isDoctor, hasManualMedicine, hasSpecifyDosingInstruction, activeTab]);

  const onClickCloseForInProgressTreatment = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onClickCancelTreatmentPlan = useCallback(() => {
    handleClose(false, treatmentHasAnyChange);
  }, [handleClose, treatmentHasAnyChange]);

  const onSubmit = useCallback(
    async (finalize?: boolean) => {
      setSubmitting(true);
      await handleSubmitClick(values, errors, setFieldTouched, finalize);
      setSubmitting(false);
    },
    [handleSubmitClick, errors, setFieldTouched, values, setSubmitting]
  );

  const onGoToFinalize = useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  const onCreateTreatmentPlan = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleCloseTreatmentPlanOnSuccess = useCallback(() => {
    if (createdTreatmentUrl) {
      window.open(createdTreatmentUrl, "_blank");
    }
    handleClose(true);
  }, [handleClose, createdTreatmentUrl]);

  const handleCloseOnSuccessWithNoNewTreatmentPlan = useCallback(() => {
    handleClose(true);
  }, [handleClose]);

  const handleUpdateTreatmentPlan = useCallback(() => {
    setFormDialog("treatment-plan");
  }, [setFormDialog]);

  const handleUpdateProducts = useCallback(() => {
    setFormDialog("products");
  }, [setFormDialog]);

  return (
    <TreatmentWidgetActions
      containerClass={
        view === "form" || view === "success" ? classes.action : ""
      }
    >
      {view === "initial-block" ? (
        <Button
          variant="outlined"
          onClick={onClickCloseForInProgressTreatment}
          color="primary"
        >
          CLOSE
        </Button>
      ) : null}
      {view === "success" ? (
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item>
            {isSuccessWithNoNewTreatmentPlan ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseOnSuccessWithNoNewTreatmentPlan}
              >
                CLOSE
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseTreatmentPlanOnSuccess}
              >
                CLOSE THIS AND OPEN THE NEW TREATMENT
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}
      {view === "form-dialog" ? (
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateTreatmentPlan}
            >
              UPDATE TREATMENT PLAN
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateProducts}
            >
              UPDATE PRODUCTS
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {view === "form" ? (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickCancelTreatmentPlan}
              className={classes.danger}
            >
              CANCEL CHANGES
            </Button>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              {showFinalizationButton ? (
                <Grid item>
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        This will skip the automatic calculation of matching
                        products and packages for this treatment
                      </Typography>
                    }
                  >
                    <span>
                      <Button
                        variant="outlined"
                        onClick={onGoToFinalize}
                        color="primary"
                        disabled={!isValid}
                      >
                        GO TO FINALIZATION
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              ) : null}

              <Grid item>
                <Button
                  variant="contained"
                  onClick={onCreateTreatmentPlan}
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  {submitButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </TreatmentWidgetActions>
  );
};
