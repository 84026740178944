import { FastField, Field } from "formik";
import React, { memo } from "react";
import { makeStyles, Typography, Box, BoxProps } from "@material-ui/core";

interface StyleProps {
  title?: string;
}

const useStyle = makeStyles(() => ({
  wrapper: () => ({
    display: "flex",
    flexDirection: "column",
  }),
  label: ({ title }: StyleProps) => ({
    opacity: title ? 1 : 0,
  }),
}));

interface FieldWithLabelProps extends BoxProps {
  title?: string;
  name: string;
  component: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isDate?: boolean;
  fieldProps?: Record<string, any>;
  useNormalField?: boolean;
}

const FieldWithTitle: React.FC<FieldWithLabelProps> = ({
  title,
  name,
  component,
  label,
  required,
  placeholder,
  className,
  disabled,
  isDate,
  fieldProps,
  useNormalField,
  ...props
}) => {
  const classes = useStyle({ title });
  const fullClass = `${classes.wrapper} ${className || ""}`;
  const F = useNormalField ? Field : FastField;
  return (
    <Box className={fullClass} {...props}>
      <Typography variant="caption" className={classes.label}>
        {title || "no title"}
      </Typography>

      {isDate && (
        <F
          name={name}
          component={component}
          label={label}
          required={required}
          placeholder={placeholder}
          disableFuture
          disablePast={false}
          {...fieldProps}
        />
      )}

      {!isDate && (
        <F
          name={name}
          component={component}
          label={label}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          {...fieldProps}
        />
      )}
    </Box>
  );
};

export default memo(FieldWithTitle);
