import * as Yup from "yup";

export const yesNoOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const specialPurposeOfConsultationValues = [
  "Discuss Blood Test Results",
  "6 weeks check in",
];

export const checkupType = {
  threeWeeks: "3 weeks check-up",
  sixWeeks: "6 weeks check-up",
  sixMonths: "6 months check-up",
  twelveMonths: "12 months check-up",
  emptyValue: "Not Available",
};

export const stepOneValidation = Yup.object().shape({
  checkupActionType: Yup.mixed<
    "update-details" | "update-checkup-date" | "cancel-checkup"
  >()
    .oneOf(["update-details", "update-checkup-date", "cancel-checkup"])
    .required("This field is required"),
});

export const stepTwoValidation = Yup.object().shape({
  checkupActionType: Yup.mixed<
    "update-details" | "update-checkup-date" | "cancel-checkup"
  >().oneOf(["update-details", "update-checkup-date", "cancel-checkup"]),
  purposeOfLastConsultation: Yup.string(),
  isRecentBloodTestAcceptable: Yup.mixed<"Yes" | "No">()
    .nullable()
    .when("checkupActionType", {
      is: "update-details",
      then: Yup.string()
        .required("This field is required")
        .when("purposeOfLastConsultation", {
          is: (value) => specialPurposeOfConsultationValues.includes(value),
          then: Yup.string().required("This field is required"),
        }),
    }),
  shouldSendDoctorInvite: Yup.mixed<"Yes" | "No">()
    .nullable()
    .when("isRecentBloodTestAcceptable", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
  clientNotes: Yup.string().when("checkupActionType", {
    is: "update-details",
    then: Yup.string().required("This field is required"),
  }),
  nextCheckupType: Yup.string().optional(),
  nextCheckupDate: Yup.string().when("checkupActionType", {
    is: "update-checkup-date",
    then: Yup.string().required("Next checkup date is required"),
  }),
});

export const stepThreeValidation = Yup.object().shape({
  shouldSendDoctorInvite: Yup.mixed<"Yes" | "No">(),
  doctor: Yup.string().when("shouldSendDoctorInvite", {
    is: "Yes",
    then: Yup.string().required("Doctor is required"),
  }),
});

export const defaultValidationSchema = Yup.object().shape({
  checkupActionType: Yup.string().optional(),
});
