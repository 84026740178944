import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, makeStyles, Button, Link } from "@material-ui/core";
import { Field, Form, Formik, FormikConfig } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Error as ErrorIcon } from "@material-ui/icons";
import { GetCancelRetestArrangementData } from "@deep-consulting-solutions/bmh-constants";

import Loader from "components/Loader";
import { zohoSelectors } from "redux/zoho";
import { pathwayRequests } from "redux/pathway";
import { zohoServices } from "services";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { isStageValid, CancelStageEnum } from "./helpers";

interface FormValues {
  reason: string;
}

const validationSchema = Yup.object<FormValues>({
  reason: Yup.string().required("Reason is required"),
});

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    padding: s(2),
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
  },
  reasonWrapper: {
    marginTop: s(3),
  },
  banner: {
    marginTop: s(2),
    background: "#fef4e7",
    padding: s(1),
    display: "flex",
    alignItems: "center",
  },
  bannerIcon: {
    marginRight: s(1),
    color: "#f19d38",
  },
  bannerText: {
    color: "#603e11",
    fontSize: 15,
  },
  btnWrapper: {
    textAlign: "right",
    marginTop: s(2),
  },
  btn: {
    background: "#d44425",
    marginLeft: s(1),
    "&:hover": {
      background: "#cc8e81",
    },
  },
  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "200px",
  },
  errorText: {
    color: p.error.main,
    fontSize: 30,
    marginBottom: s(1),
  },
}));

export const PathwayCancelRetestArragementBlueprint = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    typeof GetCancelRetestArrangementData["CancelRetestArrangementData"] | null
  >(null);

  const bannerText = useMemo(() => {
    if (!data) return null;
    if (
      data.stage === CancelStageEnum.PENDING_RETEST_CHARGES_APPROVAL ||
      data.stage === CancelStageEnum.PENDING_ITEMS_CREATION
    ) {
      return null;
    }

    if (data.stage === CancelStageEnum.PENDING_RETEST_KIT_INVOICE_CREATION) {
      return (
        <>
          The Blood Test Order is pending retest kit invoice creation. If the
          invoice has been created make sure you void it.
        </>
      );
    }

    if (data.stage === CancelStageEnum.PENDING_RETEST_INVOICE_CREATION) {
      return (
        <>
          The Blood Test Order is pending retest invoice creation. If the
          invoice has been created make sure you void it.
        </>
      );
    }

    if (data.doesTheClientNeedToPayForTheRetest) {
      if (data.isRetestInvoicePaid) {
        return (
          <>
            Retest invoice &quot;
            <Link href={data.retestInvoiceLink || ""} target="_blank">
              {data.retestInvoiceNumber || "-"}
            </Link>
            &quot; has been paid by the client. The retest blood test order will
            be cancelled on cancelling confirmation.
          </>
        );
      }
      return (
        <>
          Retest invoice &quot;
          <Link href={data.retestInvoiceLink || ""} target="_blank">
            {data.retestInvoiceNumber || "-"}
          </Link>
          &quot; will be voided.
        </>
      );
    }

    if (data.isRetestKitInvoicePaid) {
      return (
        <>
          Retest kit invoice &quot;
          <Link href={data.retestKitInvoiceLink || ""} target="_blank">
            {data.retestKitInvoiceNumber || "-"}
          </Link>
          &quot; has been paid by the client. The retest blood test order will
          be cancelled on cancelling confirmation.
        </>
      );
    }

    return (
      <>
        Retest kit invoice &quot;
        <Link href={data.retestKitInvoiceLink || ""} target="_blank">
          {data.retestKitInvoiceNumber || "-"}
        </Link>
        &quot; will be voided. The retest blood test order will be cancelled on
        cancelling confirmation.
      </>
    );
  }, [data]);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(false);
      setLoading(true);
      try {
        await pathwayRequests.cancelRetestArrangementReq(
          pathwayZohoId,
          values.reason
        );

        setLoading(false);
        setTimeout(() => {
          zohoServices.closePopup(true);
        }, 0);
      } catch {
        setLoading(false);
      }
    },
    [pathwayZohoId]
  );

  const handleCloseClick = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  useEffect(() => {
    (async () => {
      setData(null);
      setLoading(true);
      try {
        const res = await pathwayRequests.fetchCancelRetestArrangementData(
          pathwayZohoId
        );
        setData(res);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [pathwayZohoId]);

  const classes = useStyles();

  if (loading && !data) return <Loader open />;

  if (!loading && (!data || !isStageValid(data?.stage))) {
    return (
      <div className={classes.errorWrapper}>
        <Typography className={classes.errorText}>
          Something went wrong
        </Typography>
        <Button color="primary" variant="outlined" onClick={handleCloseClick}>
          Close
        </Button>
      </div>
    );
  }

  return (
    <Formik<FormValues>
      initialValues={{
        reason: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <div className={classes.wrapper}>
            <Loader open={loading} />
            <Form>
              <WidgetLayout>
                <WidgetTitle>
                  <Typography className={classes.title}>
                    Cancel Retest Arrangements
                  </Typography>
                </WidgetTitle>
                <WidgetContent>
                  <div className={classes.reasonWrapper}>
                    <Field
                      name="reason"
                      component={TextField}
                      multiline
                      rows={3}
                      required
                      label="Retest Arragements Cancellation Reason"
                    />
                  </div>
                  {bannerText && (
                    <div className={classes.banner}>
                      <ErrorIcon className={classes.bannerIcon} />
                      <Typography className={classes.bannerText}>
                        {bannerText}
                      </Typography>
                    </div>
                  )}
                </WidgetContent>
                <WidgetActions>
                  <div className={classes.btnWrapper}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseClick}
                    >
                      Close
                    </Button>
                    <Button
                      className={classes.btn}
                      color="primary"
                      type="submit"
                    >
                      Cancel
                    </Button>
                  </div>
                </WidgetActions>
              </WidgetLayout>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
