import { Button, Grid, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";

interface Props {
  isAllPendingKitShipping: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

const useStyle = makeStyles(() => ({
  alert: {
    fontWeight: 500,
    "& .MuiAlert-icon": {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
}));

export const ConfirmKitPackingActions = ({
  isAllPendingKitShipping,
  onCancel,
  onConfirm,
}: Props) => {
  const classes = useStyle();

  return (
    <Grid
      container
      spacing={2}
      justify={isAllPendingKitShipping ? "space-between" : "flex-end"}
      alignItems="center"
      direction="row"
      wrap="nowrap"
    >
      {isAllPendingKitShipping ? (
        <Grid item>
          <Alert
            severity="info"
            icon={<Info fontSize="inherit" />}
            className={classes.alert}
          >
            On confirmation, the stage of the selected blood test orders will be
            updated but this will take few seconds after the dialog is closed.
          </Alert>
        </Grid>
      ) : null}
      <Grid item>
        <Grid
          container
          spacing={2}
          justify="flex-end"
          direction="row"
          wrap="nowrap"
        >
          <Grid item>
            <Button
              variant={isAllPendingKitShipping ? "outlined" : undefined}
              color="primary"
              onClick={onCancel}
            >
              {isAllPendingKitShipping ? "CANCEL" : "OKAY"}
            </Button>
          </Grid>
          {isAllPendingKitShipping && (
            <Grid item>
              <Button onClick={onConfirm} color="primary">
                CONFIRM
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
