import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import { useSelector } from "react-redux";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { cancelTreatmentValidationSchema } from "helpers";
import {
  cancelTreatment,
  getMinimalTreatment,
  getTreatmentOrders,
} from "services/treatments/cancel-treatment";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import Loader from "components/Loader";
import { InitialValues, MinimalTreatment, TreatmentOrder } from "./types";
import { outOfProgressStates, treatmentOrderBaseUrl } from "./constants";
import { CancelTreatmentForm } from "./CancelTreatmentForm";
import { ActionButtons } from "./ActionButtons";

export const CancelTreatment = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [treatmentId] = useSelector(zohoSelectors.getIds);
  const [
    currentTreatment,
    setCurrentTreatment,
  ] = useState<MinimalTreatment | null>(null);
  const [treatmentOrders, setTreatmentOrders] = useState<TreatmentOrder[]>([]);

  const hasBeenActivated = useMemo(
    () => Boolean(currentTreatment?.ActivationDate),
    [currentTreatment?.ActivationDate]
  );

  const paidInProgressTreatmentOrders = useMemo(
    () =>
      treatmentOrders.filter((i) => {
        return (
          i.PaymentStatus === "Paid" && !outOfProgressStates.includes(i.State)
        );
      }),
    [treatmentOrders]
  );

  const sortedPaidInProgressTreatmentOrders = [
    ...paidInProgressTreatmentOrders,
  ].sort((a, b) => {
    const dateA = new Date(a.OrderDate);
    const dateB = new Date(b.OrderDate);
    return dateB.getTime() - dateA.getTime();
  });

  const paidOutOfProgressTreatmentOrders = useMemo(
    () =>
      treatmentOrders.filter((i) => {
        return (
          i.PaymentStatus === "Paid" && outOfProgressStates.includes(i.State)
        );
      }),
    [treatmentOrders]
  );

  const sortedPaidOutOfProgressTreatmentOrders = [
    ...paidOutOfProgressTreatmentOrders,
  ].sort((a, b) => {
    const dateA = new Date(a.OrderDate);
    const dateB = new Date(b.OrderDate);
    return dateB.getTime() - dateA.getTime();
  });

  const infoText = useMemo(() => {
    const createdDate = moment(currentTreatment?.CreatedAt).format(
      "YYYY-MM-DD"
    );
    if (!hasBeenActivated) {
      return `
      This treatment plan has been created for the client on ${createdDate} and the client hasn't received any of their medication yet. 
      Are you sure you want to cancel the treatment and mark the client as Drop Out?`;
    }

    if (sortedPaidInProgressTreatmentOrders.length > 0) {
      return `
      This client has been active and paid for treatment medication last on ${sortedPaidInProgressTreatmentOrders[0].OrderDate}.`;
    }

    if (sortedPaidOutOfProgressTreatmentOrders.length > 0) {
      return `
      This client has been active and paid for treatment medication last on ${sortedPaidOutOfProgressTreatmentOrders[0].OrderDate}. 
      Are you sure you want to cancel the treatment and mark the client as Drop Out?`;
    }

    return `
      This treatment plan has been created for the client on ${createdDate}. 
      Are you sure you want to cancel the treatment and mark the client as Drop Out?`;
  }, [
    hasBeenActivated,
    currentTreatment?.CreatedAt,
    sortedPaidInProgressTreatmentOrders,
    sortedPaidOutOfProgressTreatmentOrders,
  ]);

  const initialValues = {
    reason: "",
    treatmentOrders: sortedPaidInProgressTreatmentOrders,
  };

  const resizeWidget = useCallback(() => {
    zohoServices.resizeWidget({
      width: 900,
      height: 500,
    });
  }, []);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          cancellationReason: values.reason,
          treatmentCrmId: treatmentId,
          treatmentOrders: values.treatmentOrders.map((t) => ({
            treatmentOrderCrmId: t.Id,
            cancelOrComplete: t.selectedAction,
            creditOrRefundOrder: t.shouldCreditOrRefund,
          })),
        };
        await cancelTreatment(payload);
      } catch (error) {
        closeWidget(true);
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        closeWidget(true);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const treatment = await getMinimalTreatment(treatmentId);
      const shouldFetchOrder = treatment && !!treatment.ActivationDate;
      setCurrentTreatment(treatment);

      if (shouldFetchOrder) {
        const treatmentOrdersRes = await getTreatmentOrders(
          treatment?.Id as string
        );
        setTreatmentOrders(
          treatmentOrdersRes.map((t) => ({
            ...t,
            selectedAction: "cancel",
            shouldCreditOrRefund: false,
            url: `${treatmentOrderBaseUrl}/${t.ZohoCrmId}`,
          }))
        );
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={cancelTreatmentValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle title="Cancel Treatment" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              height="400px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <CancelTreatmentForm infoText={infoText} />
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
