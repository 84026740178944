import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Lab,
  BloodDrawer,
  BloodDrawerTypeEnum,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  TextField as MuiTextField,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  Collapse,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import Loader from "components/Loader";
import { Field, FormikConfig, FormikProvider, useFormik } from "formik";
import { Contact, ZohoPathway } from "types";

import { Alert, AutocompleteRenderInputParams } from "@material-ui/lab";
import { Autocomplete } from "formik-material-ui-lab";
import { CheckboxWithLabel } from "formik-material-ui";
import { FormikCountrySelectInput } from "@deep-consulting-solutions/dcs-web-ui";
import { notifications, zohoServices } from "services";
import { pathwayRequests } from "redux/pathway";
import {
  FormValues,
  TestProfilesObrWithMappedLabs,
  ErrorMessageEnum,
  ZohoPathwayTestProfile,
  ZohoVariables,
  PatientSourcedVariant,
} from "./types";
import {
  defaultZohoCRMVariables,
  getFilteredBloodDrawersBasedOnPreselectedLabs,
  getFilteredLabsByCountry,
  getFilteredLabsByCountryAndTestProfile,
  getInitialValues,
  getTestProfilesFromPathwayTestProfiles,
  getValidationSchema,
  composeSpecifyDetailsBody,
  getSelectedBloodDrawerLabs,
  handleExtraValidation,
  getShouldShowFirstLabField,
} from "./helpers";
import {
  getIsCountriesMatch,
  getIsTestProfileAndCountryInvalid,
  mapLabsInTestProfiles,
} from "../helpers";
import { OfferingLab, OfferingMobile } from "./BloodDrawerSection";
import {
  CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW,
  DO_NOT_USE_MOBILE_DRAWER_VALUE,
} from "./constants";
import { OfferingNurseService } from "./BloodDrawerSection/OfferingNurseService";
import { OfferingPatientSourced } from "./OfferingPatientSourced";
import { GuidedSpecifyingOfDetailsSuccess } from "./GuidedSpecifyingOfDetailsSuccess";

const useStyles = makeStyles(({ spacing: s }) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    padding: s(0, 4),
  },
  title: {
    padding: s(2, 4),
  },
  actions: {
    padding: s(2, 4),
  },
  alert: {
    fontWeight: 500,
    "& .MuiAlert-icon": {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
}));

interface Props {
  bloodDrawers: BloodDrawer[];
  labs: Lab[];
  testProfiles: TestProfilesObrWithMappedLabs[];
  client: Contact;
  pathwayTestProfileRecords: ZohoPathwayTestProfile[];
  isBlueprint?: boolean;
}

export const GuidedSpecifyingOfDetails = ({
  bloodDrawers,
  labs,
  testProfiles,
  client,
  pathwayTestProfileRecords,
  isBlueprint = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [zohoCRMVariables, setZohoCRMVariables] = useState<
    Record<ZohoVariables, string>
  >(defaultZohoCRMVariables);

  const fetchVariables = useCallback(async () => {
    try {
      setLoading(true);
      const res = await zohoServices.getVariables(Object.values(ZohoVariables));
      setZohoCRMVariables((current) =>
        Object.values(ZohoVariables).reduce((previous, present) => {
          return { ...previous, [present]: res[present].value };
        }, current)
      );
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVariables();
  }, [fetchVariables]);

  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];
  const pathways = useSelector(zohoSelectors.getRecords);
  const pathway = (pathways[pathwayZohoId] as unknown) as ZohoPathway;
  const [selectedBloodDrawer, setSelectedBloodDrawer] = useState<
    BloodDrawer | undefined
  >();
  const [extraValidationFieldErrors, setExtraValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [disableCity, setDisableCity] = useState(false);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      try {
        setLoading(true);

        const body = composeSpecifyDetailsBody(
          values,
          client,
          pathway,
          pathwayTestProfileRecords,
          disableCity,
          selectedBloodDrawer
        );
        await pathwayRequests.specifyPathwayDetails(pathwayZohoId, body);

        setShowSuccess(true);
      } catch {
        //
      }
      return setLoading(false);
    },
    [
      client,
      pathway,
      pathwayTestProfileRecords,
      pathwayZohoId,
      selectedBloodDrawer,
      disableCity,
    ]
  );

  const initialValues = useMemo(() => getInitialValues(labs, pathway, client), [
    labs,
    pathway,
    client,
  ]);

  const initialValueLab = useMemo(() => {
    return labs.find((l) => l.id === initialValues.lab?.id);
  }, [labs, initialValues.lab?.id]);

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(labs, pathway, initialValueLab),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
    initialTouched: {
      shippingCountry: true,
    },
  });

  const {
    values,
    setFieldValue,
    errors: { lab: labError, testProfiles: testProfileError },
    touched: {
      testProfiles: isTestProfilesTouched,
      shippingCity: shippingCityTouched,
      city: cityTouched,
      street: streetTouched,
      region: regionTouched,
      zip: zipTouched,
      other: otherTouched,
    },
    errors,
    handleSubmit,
  } = useMemo(() => formik, [formik]);

  const handleFormSubmit = useCallback(() => {
    const errorsArray = Object.values(errors).concat(
      Object.values(extraValidationFieldErrors).filter(
        (extraValidationFieldError) => !!extraValidationFieldError
      ) as string[]
    );

    const errorMessage = errorsArray.find(
      (errorArray) => !!errorArray && typeof errorArray === "string"
    ) as string | undefined;

    if (errorMessage) {
      return notifications.notifyError(errorMessage);
    }
    return handleSubmit();
  }, [errors, handleSubmit, extraValidationFieldErrors]);

  const showLabError: boolean = useMemo(() => {
    return !!labError;
  }, [labError]);

  const showTestProfilesError: boolean = useMemo(() => {
    if (!isTestProfilesTouched) {
      return false;
    }
    return !!testProfileError;
  }, [isTestProfilesTouched, testProfileError]);

  const sortedTestProfiles = useMemo(() => {
    if (!testProfiles) {
      return [];
    }
    const cloned = [...testProfiles];
    cloned.sort((a, b) => {
      const isADisabled =
        !a.labs?.length ||
        (!!values.shippingCountry &&
          getIsTestProfileAndCountryInvalid(a, labs, values.shippingCountry));
      const isBDisabled =
        !b.labs?.length ||
        (!!values.shippingCountry &&
          getIsTestProfileAndCountryInvalid(b, labs, values.shippingCountry));

      if (!isADisabled && isBDisabled) return -1;
      if (isADisabled && !isBDisabled) return 1;
      return 0;
    });

    return cloned;
  }, [testProfiles, values.shippingCountry, labs]);

  const { clientMobile, clientOtherPhone } = useMemo(() => {
    if (!client) {
      return {
        clientMobile: "",
        clientOtherPhone: "",
      };
    }
    return {
      clientMobile:
        (client as { mobilePhone?: string }).mobilePhone || client.phone || "",
      clientOtherPhone: client?.phone2 || "",
    };
  }, [client]);

  const filteredLabsByCountry = useMemo((): Lab[] => {
    return getFilteredLabsByCountry(labs, values.shippingCountry);
  }, [labs, values.shippingCountry]);

  const filteredLabsByCountryAndTestProfile = useMemo((): Lab[] => {
    return getFilteredLabsByCountryAndTestProfile(
      filteredLabsByCountry,
      values.testProfiles
    );
  }, [filteredLabsByCountry, values.testProfiles]);

  const errorMessage = useMemo(() => {
    if (values.shippingCountry && filteredLabsByCountry.length === 0) {
      return ErrorMessageEnum.NoLab;
    }
    if (
      values.shippingCountry &&
      values.testProfiles.length &&
      filteredLabsByCountryAndTestProfile.length === 0
    ) {
      return ErrorMessageEnum.NotAvailable;
    }

    return "";
  }, [
    values.shippingCountry,
    filteredLabsByCountryAndTestProfile,
    filteredLabsByCountry,
    values.testProfiles.length,
  ]);

  const setTestProfiles = useCallback(() => {
    if (pathwayTestProfileRecords.length) {
      const mappedLabsInTestProfiles = mapLabsInTestProfiles(
        getTestProfilesFromPathwayTestProfiles(
          pathwayTestProfileRecords,
          testProfiles
        )
      );
      setFieldValue("testProfiles", mappedLabsInTestProfiles);
    }
  }, [pathwayTestProfileRecords, testProfiles, setFieldValue]);

  const shouldShowFirstLabField = useMemo(() => {
    return getShouldShowFirstLabField(
      pathway,
      values.testProfiles,
      values.shippingCountry,
      initialValueLab
    );
  }, [pathway, initialValueLab, values.shippingCountry, values.testProfiles]);

  const labName = useMemo(
    () => (shouldShowFirstLabField ? values.lab?.name || "" : ""),
    [values.lab?.name, shouldShowFirstLabField]
  );

  const preselectedLab = useMemo(
    () => (shouldShowFirstLabField ? values.lab : null),
    [values.lab, shouldShowFirstLabField]
  );

  const filteredBloodDrawersAndCountry: BloodDrawer[] = useMemo(() => {
    return bloodDrawers.filter((bloodDrawer) =>
      bloodDrawer.addresses.some((address) =>
        getIsCountriesMatch(address.country, values.shippingCountry)
      )
    );
  }, [bloodDrawers, values.shippingCountry]);

  const filteredBloodDrawersAndCountryAndCity: BloodDrawer[] = useMemo(() => {
    return bloodDrawers.filter((bloodDrawer) =>
      bloodDrawer.addresses.some(
        (address) =>
          getIsCountriesMatch(address.country, values.shippingCountry) &&
          values.shippingCity === address.city
      )
    );
  }, [bloodDrawers, values.shippingCountry, values.shippingCity]);

  const filteredMobileBloodDrawers = useMemo(() => {
    const filteredMobileBloodDrawersAndCountry = filteredBloodDrawersAndCountry.filter(
      (bloodDrawer) => bloodDrawer.mobile
    );

    return getFilteredBloodDrawersBasedOnPreselectedLabs(
      filteredMobileBloodDrawersAndCountry,
      filteredLabsByCountryAndTestProfile,
      preselectedLab
    );
  }, [
    filteredBloodDrawersAndCountry,
    preselectedLab,
    filteredLabsByCountryAndTestProfile,
  ]);

  const filteredLabBloodDrawers = useMemo(() => {
    const filteredLabBloodDrawersAndCountry = filteredBloodDrawersAndCountry.filter(
      (bloodDrawer) =>
        [
          BloodDrawerTypeEnum.LABORATORY,
          BloodDrawerTypeEnum.LAB_MANUAL_RESULTS,
        ].includes(bloodDrawer.category)
    );
    return getFilteredBloodDrawersBasedOnPreselectedLabs(
      filteredLabBloodDrawersAndCountry,
      filteredLabsByCountryAndTestProfile,
      preselectedLab
    );
  }, [
    filteredBloodDrawersAndCountry,
    preselectedLab,
    filteredLabsByCountryAndTestProfile,
  ]);

  const filteredNurseBloodDrawers = useMemo(() => {
    const filteredNurseBloodDrawersAndCountryCity = filteredBloodDrawersAndCountryAndCity.filter(
      (bloodDrawer) =>
        [
          BloodDrawerTypeEnum.PARTNER_BLOOD_DRAWER,
          BloodDrawerTypeEnum.PARTNER_NURSE_SERVICE,
        ].includes(bloodDrawer.category) && bloodDrawer.isPrepaid
    );
    return getFilteredBloodDrawersBasedOnPreselectedLabs(
      filteredNurseBloodDrawersAndCountryCity,
      filteredLabsByCountryAndTestProfile,
      preselectedLab
    );
  }, [
    filteredBloodDrawersAndCountryAndCity,
    preselectedLab,
    filteredLabsByCountryAndTestProfile,
  ]);

  const showOfferingMobile = useMemo(() => {
    if (errors.lab) {
      return false;
    }
    return !!filteredMobileBloodDrawers.length;
  }, [filteredMobileBloodDrawers.length, errors.lab]);

  const showOfferingLab = useMemo(() => {
    if (errors.lab) {
      return false;
    }
    if (!filteredLabBloodDrawers.length) {
      return false;
    }

    if (
      filteredMobileBloodDrawers.length &&
      (!values.bloodDrawer ||
        values.bloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE)
    ) {
      return false;
    }

    return true;
  }, [
    values.bloodDrawer,
    filteredMobileBloodDrawers.length,
    filteredLabBloodDrawers.length,
    errors.lab,
  ]);

  const showOfferingNurseService = useMemo(() => {
    if (errors.lab) {
      return false;
    }
    if (!showOfferingMobile && !showOfferingLab) {
      return true;
    }

    if (
      showOfferingMobile &&
      values.bloodDrawer &&
      values.bloodDrawer === DO_NOT_USE_MOBILE_DRAWER_VALUE &&
      !showOfferingLab
    ) {
      return true;
    }

    if (
      showOfferingLab &&
      values.labBloodDrawer === DO_NOT_USE_MOBILE_DRAWER_VALUE
    ) {
      return true;
    }

    return false;
  }, [
    showOfferingMobile,
    showOfferingLab,
    values.labBloodDrawer,
    errors.lab,
    values.bloodDrawer,
  ]);

  const showPatientSourced: false | PatientSourcedVariant = useMemo(() => {
    if (errors.lab) {
      return false;
    }
    if (
      (showOfferingNurseService &&
        values.nurseBloodDrawer === CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW &&
        values.shippingCity) ||
      (showOfferingLab &&
        values.labBloodDrawer === CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW)
    ) {
      return "patient-sourced";
    }

    if (
      (showOfferingNurseService &&
        values.nurseBloodDrawer &&
        values.nurseBloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE &&
        values.nurseBloodDrawer !== CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW &&
        values.shippingCity) ||
      (showOfferingLab &&
        values.labBloodDrawer &&
        values.labBloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE &&
        values.labBloodDrawer !== CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW) ||
      (showOfferingMobile &&
        values.bloodDrawer &&
        values.bloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE)
    ) {
      return "rest-fields";
    }

    return false;
  }, [
    showOfferingNurseService,
    values.nurseBloodDrawer,
    showOfferingLab,
    values.labBloodDrawer,
    values.shippingCity,
    values.bloodDrawer,
    showOfferingMobile,
    errors.lab,
  ]);

  const patientSourcedBloodDrawer = useMemo(
    () =>
      bloodDrawers.find(
        (bloodDrawer) =>
          bloodDrawer.name === "Patient Sourced" ||
          bloodDrawer.category ===
            BloodDrawerTypeEnum.PATIENT_SOURCED_BLOOD_DRAWER
      ),
    [bloodDrawers]
  );

  const getSelectedBloodDrawer = useCallback(() => {
    let bloodDrawerID = "";

    if (
      showOfferingNurseService &&
      values.nurseBloodDrawer &&
      values.nurseBloodDrawer !== CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW
    ) {
      bloodDrawerID = values.nurseBloodDrawer;
    } else if (
      showOfferingLab &&
      values.labBloodDrawer &&
      values.labBloodDrawer !== CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW &&
      values.labBloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE
    ) {
      bloodDrawerID = values.labBloodDrawer;
    } else if (
      showOfferingMobile &&
      values.bloodDrawer &&
      values.bloodDrawer !== DO_NOT_USE_MOBILE_DRAWER_VALUE
    ) {
      bloodDrawerID = values.bloodDrawer;
    }

    const choice = bloodDrawers.find(
      (bloodDrawer) => bloodDrawer.id === bloodDrawerID
    );
    if (choice) {
      return choice;
    }

    return patientSourcedBloodDrawer;
  }, [
    showOfferingLab,
    values.bloodDrawer,
    values.labBloodDrawer,
    values.nurseBloodDrawer,
    showOfferingMobile,
    showOfferingNurseService,
    bloodDrawers,
    patientSourcedBloodDrawer,
  ]);

  useEffect(() => {
    setSelectedBloodDrawer(getSelectedBloodDrawer());
  }, [getSelectedBloodDrawer]);

  useEffect(() => setDisableCity(showOfferingNurseService), [
    showOfferingNurseService,
  ]);

  const handleCloseWidget = useCallback((reload?: any) => {
    zohoServices.closePopup(reload === true);
  }, []);

  useEffect(() => {
    setTestProfiles();
  }, [setTestProfiles]);

  const resizeWidget = useCallback(() => {
    zohoServices.resizeWidget({
      width: 1000,
      height: 850,
    });
  }, []);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  const filteredLabsByBloodDrawer = useMemo(() => {
    if (selectedBloodDrawer) {
      return getSelectedBloodDrawerLabs(
        filteredLabsByCountryAndTestProfile,
        selectedBloodDrawer
      );
    }

    return [];
  }, [filteredLabsByCountryAndTestProfile, selectedBloodDrawer]);

  const getExtraValidationErrors = useCallback(() => {
    const extraValidationErrors = handleExtraValidation(
      {
        billedStatus: values.billedStatus,
        billInvoiceNumber: values.billInvoiceNumber,
        patientSourcedLab: values.patientSourcedLab,
        shippingCity: values.shippingCity,
        street: values.street,
        zip: values.zip,
        region: values.region,
        city: values.city,
      },
      labs,
      showOfferingNurseService,
      pathway.Blood_Draw_Fee_Collected,
      selectedBloodDrawer,
      shouldShowFirstLabField,
      disableCity
    );

    setExtraValidationErrors(extraValidationErrors);
  }, [
    selectedBloodDrawer,
    values.billedStatus,
    values.billInvoiceNumber,
    values.patientSourcedLab,
    labs,
    pathway.Blood_Draw_Fee_Collected,
    values.shippingCity,
    values.street,
    values.zip,
    values.region,
    values.city,
    showOfferingNurseService,
    shouldShowFirstLabField,
    disableCity,
  ]);

  useEffect(() => {
    getExtraValidationErrors();
  }, [getExtraValidationErrors]);

  /**
   * Prefilling value for address fields when not touched
   */

  const handleShippingCountryAddressValuesSync = useCallback(() => {
    const cityFieldTouched = showOfferingNurseService
      ? shippingCityTouched
      : cityTouched;
    if (
      values.shippingCountry !== initialValues.shippingCountry &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !cityFieldTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", "");
      setFieldValue("other", "");
      setFieldValue("region", "");
      if (showOfferingNurseService) {
        setFieldValue("shippingCity", "");
      } else {
        setFieldValue("city", "");
      }
      setFieldValue("street", "");
    } else if (
      values.shippingCountry === initialValues.shippingCountry &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !cityFieldTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", initialValues.zip);
      setFieldValue("other", initialValues.other);
      setFieldValue("region", initialValues.region);
      if (showOfferingNurseService) {
        setFieldValue("shippingCity", initialValues.shippingCity);
      } else {
        setFieldValue("city", initialValues.city);
      }
      setFieldValue("street", initialValues.street);
    }
  }, [
    values.shippingCountry,
    initialValues.shippingCountry,
    zipTouched,
    cityTouched,
    otherTouched,
    regionTouched,
    shippingCityTouched,
    setFieldValue,
    streetTouched,
    initialValues.region,
    initialValues.street,
    initialValues.other,
    initialValues.zip,
    initialValues.shippingCity,
    initialValues.city,
    showOfferingNurseService,
  ]);

  const handleShippingCityAddressValuesSync = useCallback(() => {
    if (!showOfferingNurseService) {
      return;
    }
    if (
      values.shippingCountry === initialValues.shippingCountry &&
      shippingCityTouched &&
      values.shippingCity !== initialValues.shippingCity &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", "");
      setFieldValue("other", "");
      setFieldValue("region", "");
      setFieldValue("street", "");
    } else if (
      values.shippingCountry === initialValues.shippingCountry &&
      shippingCityTouched &&
      values.shippingCity === initialValues.shippingCity &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", initialValues.zip);
      setFieldValue("other", initialValues.other);
      setFieldValue("region", initialValues.region);
      setFieldValue("street", initialValues.street);
    }
  }, [
    values.shippingCity,
    values.shippingCountry,
    initialValues.shippingCity,
    initialValues.shippingCountry,
    zipTouched,
    otherTouched,
    regionTouched,
    shippingCityTouched,
    setFieldValue,
    streetTouched,
    initialValues.region,
    initialValues.street,
    initialValues.other,
    initialValues.zip,
    showOfferingNurseService,
  ]);

  const handleCityAddressValuesSync = useCallback(() => {
    if (showOfferingNurseService) {
      return;
    }
    if (
      values.shippingCountry === initialValues.shippingCountry &&
      cityTouched &&
      values.city !== initialValues.city &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", "");
      setFieldValue("other", "");
      setFieldValue("region", "");
      setFieldValue("street", "");
    } else if (
      values.shippingCountry === initialValues.shippingCountry &&
      cityTouched &&
      values.city === initialValues.city &&
      !zipTouched &&
      !otherTouched &&
      !regionTouched &&
      !streetTouched
    ) {
      setFieldValue("zip", initialValues.zip);
      setFieldValue("other", initialValues.other);
      setFieldValue("region", initialValues.region);
      setFieldValue("street", initialValues.street);
    }
  }, [
    values.city,
    values.shippingCountry,
    initialValues.city,
    initialValues.shippingCountry,
    zipTouched,
    otherTouched,
    regionTouched,
    cityTouched,
    setFieldValue,
    streetTouched,
    initialValues.region,
    initialValues.street,
    initialValues.other,
    initialValues.zip,
    showOfferingNurseService,
  ]);

  useEffect(() => {
    handleCityAddressValuesSync();
  }, [handleCityAddressValuesSync]);

  useEffect(() => {
    handleShippingCountryAddressValuesSync();
  }, [handleShippingCountryAddressValuesSync]);

  useEffect(() => {
    handleShippingCityAddressValuesSync();
  }, [handleShippingCityAddressValuesSync]);

  const classes = useStyles();

  return (
    <Dialog fullScreen open>
      <Loader open={loading} isFixed />
      {testProfiles && labs && bloodDrawers && client && (
        <FormikProvider value={formik}>
          {isBlueprint && (
            <DialogTitle className={classes.title}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h4">Specify Details</Typography>
                </Grid>
                {!showSuccess ? (
                  <Grid item>
                    <IconButton onClick={handleCloseWidget}>
                      <Close />
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            </DialogTitle>
          )}
          <DialogContent className={classes.content}>
            <Collapse in={showSuccess}>
              <GuidedSpecifyingOfDetailsSuccess isBlueprint={isBlueprint} />
            </Collapse>
            <Collapse in={!showSuccess}>
              <Alert
                severity="info"
                icon={<Info fontSize="inherit" />}
                className={classes.alert}
              >
                Call the client to discuss details about the blood test order
                and agree on the blood drawer and blood taking option.
              </Alert>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Client Mobile: {clientMobile}</Typography>
                  </Grid>
                  {clientOtherPhone ? (
                    <Grid item xs={6}>
                      <Typography>
                        Client Other Phone: {clientOtherPhone}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>

              <Box mt={2}>
                <Field
                  name="isOnPhone"
                  component={CheckboxWithLabel}
                  Label={{
                    label: (
                      <Typography variant="body2">
                        I&apos;m on phone with the client
                      </Typography>
                    ),
                  }}
                  type="checkbox"
                  id="isOnPhone"
                  color="primary"
                />
              </Box>

              <Collapse in={!!values.isOnPhone}>
                <Box mt={2}>
                  <Field
                    multiple
                    name="testProfiles"
                    component={Autocomplete}
                    options={sortedTestProfiles}
                    getOptionLabel={(option: TestProfilesObrWithMappedLabs) => {
                      return `${option.bmhObrCode} (${option.bmhMarketName})`;
                    }}
                    getOptionDisabled={(
                      option: TestProfilesObrWithMappedLabs
                    ) => {
                      return (
                        !option.labs?.length ||
                        (!!values.shippingCountry &&
                          getIsTestProfileAndCountryInvalid(
                            option,
                            labs,
                            values.shippingCountry
                          ))
                      );
                    }}
                    getOptionSelected={(
                      option: TestProfilesObrWithMappedLabs,
                      value: TestProfilesObrWithMappedLabs
                    ) => {
                      return option.id === value.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        name="testProfiles"
                        label="Test Profiles"
                        error={showTestProfilesError}
                        helperText={showTestProfilesError && testProfileError}
                        required
                      />
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <Field
                    component={FormikCountrySelectInput}
                    name="shippingCountry"
                    label="Shipping Country"
                    required
                    useCountryCode={false}
                  />
                </Box>

                <Collapse
                  in={
                    !!values.testProfiles.length &&
                    !!values.shippingCountry &&
                    !errorMessage &&
                    !errors.shippingCountry &&
                    !!filteredLabsByCountryAndTestProfile.length
                  }
                >
                  <Collapse in={shouldShowFirstLabField}>
                    <Box mt={2}>
                      <Field
                        name="lab"
                        component={Autocomplete}
                        options={filteredLabsByCountryAndTestProfile}
                        getOptionLabel={(option: Lab) => option.name}
                        getOptionSelected={(option: Lab, value: Lab) =>
                          option.id === value.id
                        }
                        style={{ width: "100%" }}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <MuiTextField
                            {...params}
                            name="lab"
                            error={showLabError}
                            helperText={showLabError && labError}
                            label="Diagnostic Laboratory"
                          />
                        )}
                      />
                    </Box>
                  </Collapse>
                  <Collapse in={showOfferingMobile}>
                    <OfferingMobile
                      labName={labName}
                      variables={zohoCRMVariables}
                      bloodDrawers={filteredMobileBloodDrawers}
                    />
                  </Collapse>

                  <Collapse in={showOfferingLab}>
                    <OfferingLab
                      labName={labName}
                      bloodDrawers={filteredLabBloodDrawers}
                      variables={zohoCRMVariables}
                    />
                  </Collapse>

                  <Collapse in={showOfferingNurseService}>
                    <OfferingNurseService
                      labName={labName}
                      bloodDrawers={filteredNurseBloodDrawers}
                      initialShippingCity={initialValues.shippingCity}
                      variables={zohoCRMVariables}
                      showOfferingNurseService={showOfferingNurseService}
                      shippingCityFieldError={
                        extraValidationFieldErrors.shippingCity
                      }
                    />
                  </Collapse>
                  <Collapse in={!!showPatientSourced}>
                    <OfferingPatientSourced
                      client={client}
                      variables={zohoCRMVariables}
                      selectedBloodDrawer={selectedBloodDrawer}
                      disableCity={disableCity}
                      showPatientSourced={showPatientSourced}
                      pathway={pathway}
                      filteredLabsByBloodDrawer={filteredLabsByBloodDrawer}
                      extraValidationFieldErrors={extraValidationFieldErrors}
                      shouldShowFirstLabField={shouldShowFirstLabField}
                    />
                  </Collapse>
                </Collapse>
                <Collapse in={!!errorMessage}>
                  <Box mt={2}>
                    <Alert
                      severity="error"
                      icon={<Info fontSize="inherit" />}
                      className={classes.alert}
                    >
                      {errorMessage}
                    </Alert>
                  </Box>
                </Collapse>
              </Collapse>
            </Collapse>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Grid container justify="flex-end" spacing={2}>
              {isBlueprint && !showSuccess && (
                <Grid item>
                  <Button variant="text" onClick={handleCloseWidget}>
                    Cancel
                  </Button>
                </Grid>
              )}

              {showPatientSourced && !showSuccess && (
                <Grid item>
                  <Button
                    type="submit"
                    onClick={handleFormSubmit}
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              )}

              {isBlueprint && showSuccess && (
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={() => handleCloseWidget(true)}
                  >
                    OK
                  </Button>
                </DialogActions>
              )}
            </Grid>
          </DialogActions>
        </FormikProvider>
      )}
    </Dialog>
  );
};
