/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormikContext } from "formik";
import {
  activateScanner,
  deactivateScanner,
  getDataForScan,
  getValueFromObject,
} from "helpers";
import React, { useCallback, useEffect } from "react";
import { BarcodeData, TreatmentOrderItem } from "widgets/DispenseDrugs";
import { notifications } from "services";

interface ScannerProps {
  mainItems: TreatmentOrderItem[];
  supplementaryItems: TreatmentOrderItem[];
}

export const Scanner: React.FC<ScannerProps> = ({
  mainItems,
  supplementaryItems,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const onScanSuccess = useCallback(
    async (data: BarcodeData) => {
      try {
        const mainItemsValue = getValueFromObject("mainItems", values) || [];
        const supplementaryItemsValue =
          getValueFromObject("supplementaryItems", values) || [];

        await getDataForScan(
          mainItems,
          mainItemsValue,
          supplementaryItems,
          supplementaryItemsValue,
          setFieldValue,
          data
        );
      } catch (error) {
        notifications.notifyError("Error processing scanned barcode");
      }
    },
    [mainItems, supplementaryItems, values, setFieldValue]
  );

  const onScanError = useCallback(() => {}, []);

  useEffect(() => {
    activateScanner(onScanSuccess, onScanError);

    return () => {
      deactivateScanner();
    };
  }, [onScanSuccess, onScanError]);

  return (
    <Box>
      <Box mb={2}>
        <Alert severity="info">
          Scanning an item will fill the batch number and expiry date
          automatically. Batch numbers and expiry dates can also be entered
          manually.
        </Alert>
      </Box>
    </Box>
  );
};
