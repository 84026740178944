import {
  UnmatchedHL7,
  UnmatchedBloodTestResultStatusEnum,
  BluePrintStagesEnum,
  OriginalUnmatchedHL7,
  Pathway,
  BloodTestResult,
} from "@deep-consulting-solutions/bmh-constants";
import React, { useMemo, memo, useState, useCallback } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  fade,
  Chip,
  Grid,
  Tooltip,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { ArrowBack, Info } from "@material-ui/icons";
import FileFieldRow from "./FileFieldRow";
import { FILE_FIELDS_META, UnmatchedHL7Field } from "../Unmatched.types";
import { getBorder } from "../Unmatched.styles";
import { Comparison } from "./Comparison/Comparison";
import { shouldAllowRestoreOriginalData } from "../Unmatched.helpers";

interface StyleProps {
  status: UnmatchedBloodTestResultStatusEnum;
  isComparing: boolean;
}

const useStyle = makeStyles(({ spacing: s, palette: p, breakpoints: b }) => ({
  wrapper: {
    width: "70%",
    borderRight: getBorder(p),
    borderLeft: getBorder(p),
    borderBottom: getBorder(p),
    [b.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    width: "100%",
    borderTop: getBorder(p),
    padding: s(1),
  },
  status: ({ status }: StyleProps) => {
    let bg = p.error.main;
    if (
      status === UnmatchedBloodTestResultStatusEnum.NEW ||
      status === UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION
    ) {
      bg = p.warning.main;
    } else if (
      [
        UnmatchedBloodTestResultStatusEnum.RESOLVED,
        UnmatchedBloodTestResultStatusEnum.MATCHED,
        UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT,
        UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT,
      ].includes(status)
    ) {
      bg = p.success.main;
    } else {
      bg = p.error.main;
    }
    return {
      color: p.common.white,
      fontWeight: 600,
      background: bg,
    };
  },
  noteWrapper: {
    padding: s(1),
  },
  discardBtn: {
    color: ({ isComparing }: StyleProps) =>
      isComparing ? p.primary.main : p.error.main,
    border: ({ isComparing }: StyleProps) =>
      `1px solid ${fade(isComparing ? p.primary.main : p.error.main, 0.5)}`,
    "&:hover": {
      border: ({ isComparing }: StyleProps) =>
        `1px solid ${isComparing ? p.primary.main : p.error.main}`,
    },
  },
  markNewBtn: {
    color: p.primary.main,
    border: `1px solid ${fade(p.primary.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${p.primary.main}`,
    },
  },
  mergeBtn: {
    background: p.warning.main,
    color: "white",
    border: `1px solid ${fade(p.warning.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${p.warning.main}`,
      background: p.warning.dark,
    },
    "&:disabled": {
      border: `1px solid ${p.warning.light}`,
      background: p.warning.light,
      color: "white",
    },
  },
  replaceBtn: {
    background: p.error.main,
    color: "white",
    border: `1px solid ${fade(p.error.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${p.error.main}`,
      background: p.error.dark,
    },
    "&:disabled": {
      border: `1px solid ${p.error.light}`,
      background: p.error.light,
      color: "white",
    },
  },
  testProfileMismatchedMsg: {
    width: "100%",
    textAlign: "center",
    color: p.error.main,
  },
  backIcon: {
    marginRight: s(1),
  },
}));

interface FileDataTableProps {
  data: UnmatchedHL7;
  originalData?: OriginalUnmatchedHL7;
  onManualValueUpdate: (args: {
    field: UnmatchedHL7Field;
    value: string;
  }) => Promise<boolean>;
  onRestartMatchClick: () => any;
  onDiscardClick: () => any;
  onMarkNewClick: () => any;
  onRestoreClick: () => any;
  onReplaceOldFileClick?: () => any;
  onFileOverwriteOptionClick: () => any;
  onCompareResults: () => void;
  isComparing: boolean;
  onStopComparing: () => void;
  onReplaceClick: () => void;
  onMergeClick: () => void;
  matchingBloodTestOrder?: Pathway | null;
  newResult: BloodTestResult | null;
  existingResult: BloodTestResult | null;
}

const MergeOrReplaceButtonComponent = ({
  children,
  title,
  enabledMergeOrReplace,
}: {
  title: string;
  children: JSX.Element;
  enabledMergeOrReplace: boolean;
}) =>
  enabledMergeOrReplace ? (
    children
  ) : (
    <Tooltip title={title}>
      <div>{children}</div>
    </Tooltip>
  );

const FileDataTable: React.FC<FileDataTableProps> = ({
  data,
  originalData,
  onManualValueUpdate,
  onRestartMatchClick,
  onDiscardClick,
  onMarkNewClick,
  onRestoreClick,
  onCompareResults,
  isComparing,
  onStopComparing,
  onReplaceClick,
  onMergeClick,
  matchingBloodTestOrder,
  newResult,
  existingResult,
}) => {
  const [
    activeEditForm,
    setActiveEditForm,
  ] = useState<UnmatchedHL7Field | null>(null);

  const onActiveEditOpen = useCallback((field: UnmatchedHL7Field) => {
    setActiveEditForm(field);
  }, []);

  const onActiveEditCancel = useCallback(() => {
    setActiveEditForm(null);
  }, []);

  const onActiveEditOK = useCallback(
    async (args: { field: UnmatchedHL7Field; value: string }) => {
      await onManualValueUpdate(args);
      setActiveEditForm(null);
    },
    [onManualValueUpdate]
  );

  const unmatched = useMemo(() => {
    return (data.unmatched || []).reduce(
      (map: { [fieldName: string]: true }, f) => {
        map[f] = true;
        return map;
      },
      {}
    );
  }, [data]);

  const disableUpdate = useMemo(
    () => ![UnmatchedBloodTestResultStatusEnum.NEW].includes(data.status),
    [data.status]
  );

  const enabledMergeOrReplace = useMemo(() => {
    if (data.unmatched?.length) {
      return false;
    }
    return matchingBloodTestOrder
      ? [
          BluePrintStagesEnum.RESULTS_FAILED,
          BluePrintStagesEnum.RESULTS_RECEIVED_OK,
          BluePrintStagesEnum.ORDER_CANCELLED,
        ].includes(matchingBloodTestOrder.stage)
      : false;
  }, [matchingBloodTestOrder, data.unmatched?.length]);

  const getMergeOrReplaceTooltipTitle = useCallback(
    (action: "Merge" | "Replace") => {
      if (data.unmatched?.length) {
        return `${action} is not available when record contains unmatched fields.`;
      }

      if (
        !matchingBloodTestOrder ||
        ![
          BluePrintStagesEnum.RESULTS_FAILED,
          BluePrintStagesEnum.RESULTS_RECEIVED_OK,
          BluePrintStagesEnum.ORDER_CANCELLED,
        ].includes(matchingBloodTestOrder.stage)
      ) {
        return `${action} is only available for blood test order in Result Failed, Result Received OK or Order Cancelled stage.`;
      }
      return "";
    },
    [matchingBloodTestOrder, data.unmatched?.length]
  );

  const allowRestore = useMemo(
    () => shouldAllowRestoreOriginalData(data, originalData),
    [data, originalData]
  );

  const classes = useStyle({ status: data.status, isComparing });

  return (
    <div className={classes.wrapper}>
      <div>
        <Grid
          container
          spacing={2}
          justify="space-between"
          className={classes.header}
          alignItems={
            data.status ===
              UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
            !isComparing
              ? "flex-start"
              : "center"
          }
          wrap="nowrap"
        >
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {data.status ===
                UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                isComparing && (
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={onStopComparing}
                      variant="outlined"
                    >
                      <ArrowBack className={classes.backIcon} />
                      BACK
                    </Button>
                  </Grid>
                )}
              <Grid item>
                <Chip
                  label={data.status.toUpperCase()}
                  className={classes.status}
                />
              </Grid>
            </Grid>
          </Grid>
          {data.status ===
            UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
            !isComparing && (
              <Grid item>
                <Alert severity="error" icon={<Info fontSize="inherit" />}>
                  Matching Blood Test Order has a blood test result. Compare the
                  results and decide whether to discard the file or merge it to
                  the existing result or replace the existing result.
                </Alert>
              </Grid>
            )}
          <Grid
            item
            style={{
              flexShrink: 0,
            }}
          >
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              {(data.status === UnmatchedBloodTestResultStatusEnum.NEW ||
                (data.status ===
                  UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                  isComparing)) && (
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.discardBtn}
                    onClick={onDiscardClick}
                    variant="outlined"
                  >
                    DISCARD FILE
                  </Button>
                </Grid>
              )}

              {data.status ===
                UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                isComparing && (
                  <>
                    <Grid item>
                      <MergeOrReplaceButtonComponent
                        title={getMergeOrReplaceTooltipTitle("Merge")}
                        enabledMergeOrReplace={enabledMergeOrReplace}
                      >
                        <Button
                          className={classes.mergeBtn}
                          onClick={onMergeClick}
                          disabled={!enabledMergeOrReplace}
                        >
                          MERGE
                        </Button>
                      </MergeOrReplaceButtonComponent>
                    </Grid>
                    <Grid item>
                      <MergeOrReplaceButtonComponent
                        title={getMergeOrReplaceTooltipTitle("Replace")}
                        enabledMergeOrReplace={enabledMergeOrReplace}
                      >
                        <Button
                          className={classes.replaceBtn}
                          onClick={onReplaceClick}
                          disabled={!enabledMergeOrReplace}
                        >
                          REPLACE
                        </Button>
                      </MergeOrReplaceButtonComponent>
                    </Grid>
                  </>
                )}
              {(data.status === UnmatchedBloodTestResultStatusEnum.UNRESOLVED ||
                data.status ===
                  UnmatchedBloodTestResultStatusEnum.DISCARDED) && (
                <Grid item data-testid="discarded-actions">
                  <Grid
                    container
                    justify="space-between"
                    spacing={2}
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {data.status ===
                      UnmatchedBloodTestResultStatusEnum.DISCARDED && (
                      <Grid item>
                        <Typography>
                          <b>Reason for Discarding: </b>
                          {data.discardReason}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        color="primary"
                        className={classes.markNewBtn}
                        onClick={onMarkNewClick}
                        variant="outlined"
                      >
                        MARK AS NEW
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {(data.status === UnmatchedBloodTestResultStatusEnum.NEW ||
                (data.status ===
                  UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                  !isComparing)) && (
                <Grid item>
                  <Grid
                    container
                    direction={
                      data.status ===
                        UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                      !isComparing
                        ? "column"
                        : "row"
                    }
                    spacing={2}
                    alignItems={
                      data.status ===
                        UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
                      !isComparing
                        ? "flex-end"
                        : "center"
                    }
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={onRestoreClick}
                        variant="outlined"
                        disabled={!allowRestore}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        RESTORE ORIGINAL DATA
                      </Button>
                    </Grid>
                    {data.status === UnmatchedBloodTestResultStatusEnum.NEW ? (
                      <>
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={onRestartMatchClick}
                            disabled={
                              data.unmatched
                                ? !!data.unmatched.length
                                : undefined
                            }
                          >
                            RESTART MATCHING
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        item
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        <Button color="primary" onClick={onCompareResults}>
                          COMPARE RESULTS
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>

      {data.status ===
        UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION &&
      isComparing ? (
        <Comparison
          newResult={newResult}
          existingResult={existingResult}
          stage={data.status}
        />
      ) : (
        <div data-testid="file-fields">
          {Object.keys(FILE_FIELDS_META).map((k) => {
            const field = k as UnmatchedHL7Field;
            const meta = FILE_FIELDS_META[field];

            if (!meta) return null;

            const { name, disabled } = meta;

            return (
              <FileFieldRow
                key={k}
                isUnmatched={unmatched[field]}
                disabled={disabled}
                disableUpdate={disableUpdate}
                field={field}
                name={name}
                value={data[field]}
                originalValue={data[field]}
                isEditOpen={activeEditForm === field}
                onActiveEditOpen={onActiveEditOpen}
                onActiveEditCancel={onActiveEditCancel}
                onActiveEditOK={onActiveEditOK}
              />
            );
          })}
        </div>
      )}

      <Box className={classes.noteWrapper}>
        <Typography variant="caption">
          Unmatched File Data displays information from the HL7 file, which
          should match the data in the Blood Test Order record and the Client
          CRM records, respectively. Information that doesn&apos;t match is
          marked in red. Click &quot;Restart Matching&quot;, if you corrected
          the file. You can correct each line manually or by clicking “Replace
          the Data” button. The system will try to find a match with the records
          again. If the problem is not solved, contact the lab that sent the
          file.
        </Typography>
      </Box>
    </div>
  );
};

export default memo(FileDataTable);
