import React, { ReactNode } from "react";
import { Box, BoxProps, Container } from "@material-ui/core";

interface SettingsContainerProps extends BoxProps {
  children: ReactNode;
}

export const SettingsContainer = ({
  children,
  ...boxProps
}: SettingsContainerProps) => {
  return (
    <Container maxWidth="xl">
      <Box
        flexGrow={1}
        width="100%"
        bgcolor="background.paper"
        minHeight="100vh"
        py={3}
        {...boxProps}
      >
        {children}
      </Box>
    </Container>
  );
};
