import React, { useCallback, useMemo } from "react";
import { TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { Field, getIn, useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { AutoCompleteOption } from "types";

interface Props {
  name: string;
  label?: string;
  options: {
    value: string;
    label: string;
  }[];
  renderOption?: (option: any) => React.ReactNode;
}

export const CustomAutocompleteField = ({
  name,
  label = "",
  options,
  renderOption,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  const onChange = useCallback(
    (_: any, value: any, reason: AutocompleteChangeReason) => {
      if (reason === "select-option" && value) {
        setFieldValue(name, value);
      }
      if (reason === "clear") {
        setFieldValue(name, "");
      }
    },
    [name, setFieldValue]
  );

  return (
    <Field
      required
      name={name}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.value === value.value && option.title === value.title;
      }}
      renderOption={renderOption}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={name}
          variant="outlined"
          label={label}
          helperText={fieldTouched && fieldError}
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
