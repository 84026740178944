import React, { memo } from "react";
import { TableCell, Box, Typography, makeStyles } from "@material-ui/core";

import { Columns, TABLE_META } from "../interface";

const TABLE_ROW_COLOR = "#f7fafc";

const useStyle = makeStyles(({ palette: p }) => ({
  cellHeader: {
    background: TABLE_ROW_COLOR,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    color: p.text.primary,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  icon: {
    color: p.text.primary,
    fontWeight: 600,
    transition: "0.2s",
  },
}));

interface HeaderCellProps {
  column: Columns;
}

export const HeadCell: React.FC<HeaderCellProps> = ({ column }) => {
  const { label } = TABLE_META[column];

  const classes = useStyle();

  return (
    <TableCell className={classes.cellHeader}>
      <Box className={classes.wrapper}>
        <Typography className={classes.text}>{label}</Typography>
      </Box>
    </TableCell>
  );
};

export const PureHeadCell = memo(HeadCell);
