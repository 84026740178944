import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { isStringEqual } from "helpers";
import React, { useMemo } from "react";
import { ZohoBMHDoctor, ZohoTreatment } from "types";

interface Props {
  previousState: string;
  doctor: ZohoBMHDoctor | null;
  treatment?: ZohoTreatment | null;
  requirePackageAndPharmacySelection: boolean;
  requirePrescriptionAndCttpChange: boolean;
  requirePrescriptionChange: boolean;
  treatmentHasAnyChange: boolean;
}

const doctorMessages = {
  requirePackageAndPharmacySelection: {
    body:
      "The changes require creating a new treatment and recalculating the pharmacy and products to be used.",
  },
  requirePrescriptionAndCttpChange: {
    body:
      "The changes require creating a new treatment and regenerating the prescription(s) and CTTP.",
  },
  requirePrescriptionChange: {
    body:
      "The changes require creating a new treatment and regenerating the prescription(s).",
  },
};

const processingTeamMessages = {
  requirePackageAndPharmacySelection: {
    pendingUpdate: {
      body:
        "A new treatment will be created in state Details Specified and package and pharmacy selection and calculation will be started for it. This treatment state will be set to updated.",
    },
    notPendingUpdate: {
      body:
        "A new treatment will be created in state Pending Doctor Approval and package and pharmacy selection and calculation will be started for it on doctor approval. The treatment state will be set to updated on doctor approval of the new treatment.",
    },
  },
  requirePrescriptionAndCttpChange: {
    body:
      "A new treatment will be created in state Finalized and prescription(s) and CTTP will be generated and sent to the doctor for signing. This treatment state will be set to updated.",
  },
  requirePrescriptionChange: {
    body:
      "A new treatment will be created in state Finalized and prescription(s) will be generated and sent to the doctor for signing. This treatment state will be set to updated.",
  },
};

export const TreatmentUpdateInfo = ({
  previousState,
  doctor,
  treatment,
  requirePackageAndPharmacySelection,
  requirePrescriptionAndCttpChange,
  requirePrescriptionChange,
  treatmentHasAnyChange,
}: Props) => {
  const isDoctor = useMemo(() => !!doctor?.id, [doctor?.id]);

  const showRequirePackageAndPharmacySelection = useMemo(() => {
    if (!treatment?.State) {
      return false;
    }
    return (
      requirePackageAndPharmacySelection ||
      ["Details Specified", "Pending Review and Finalization"].includes(
        treatment.State
      ) ||
      (treatment.State === "Pending Update" &&
        ["Details Specified", "Pending Review and Finalization"].includes(
          previousState
        ))
    );
  }, [treatment?.State, requirePackageAndPharmacySelection, previousState]);

  const { body, title }: { body: string; title?: string } = useMemo(() => {
    if (showRequirePackageAndPharmacySelection) {
      if (isDoctor) {
        return doctorMessages.requirePackageAndPharmacySelection;
      }
      if (isStringEqual(treatment?.State, "Pending Update")) {
        return processingTeamMessages.requirePackageAndPharmacySelection
          .pendingUpdate;
      }
      return processingTeamMessages.requirePackageAndPharmacySelection
        .notPendingUpdate;
    }

    if (requirePrescriptionAndCttpChange) {
      if (isDoctor) {
        return doctorMessages.requirePrescriptionAndCttpChange;
      }
      return processingTeamMessages.requirePrescriptionAndCttpChange;
    }

    if (requirePrescriptionChange) {
      if (isDoctor) {
        return doctorMessages.requirePrescriptionChange;
      }
      return processingTeamMessages.requirePrescriptionChange;
    }

    return { body: "", title: "" };
  }, [
    showRequirePackageAndPharmacySelection,
    treatment?.State,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
    isDoctor,
  ]);

  if (!treatmentHasAnyChange) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-start"
      mb={2}
    >
      <Box width="80%" maxWidth={700}>
        {body ? (
          <Alert severity={!isDoctor ? "info" : "warning"}>
            {title ? <AlertTitle>{title}</AlertTitle> : null}
            {body}
          </Alert>
        ) : null}
      </Box>
    </Box>
  );
};
