import { truncate, cloneDeep } from "lodash";
import {
  BloodTestResultItem,
  BloodTestResult,
  BloodTestResultItemHistory,
  AbnormalFlag,
  BloodTestResultInputMethodEnum,
  Lab,
  TestObxCodes,
  StatusEnum,
  LabObxCode,
  PathwayRes,
} from "@deep-consulting-solutions/bmh-constants";
import { isAfter } from "date-fns";

import { formatDateTimeToDisplayInCompanyTZ } from "helpers";
import { MapObxCode, ShortMapObxCodeDetails } from "./TestResults.types";

export const shouldDisplayAbnormalFlag = (abnormal?: AbnormalFlag | null) => {
  return abnormal ? abnormal.highlight : false;
};

export const groupBloodTestItems = (items: BloodTestResultItem[]) => {
  const groups: {
    [identifier: string]: { [name: string]: BloodTestResultItem };
  } = {};
  let hasWarning = false;

  items.forEach((item) => {
    const { name, identifier } = item;
    if (!groups[identifier]) {
      groups[identifier] = {};
    }

    groups[identifier][name] = item;

    if (shouldDisplayAbnormalFlag(item.abnormalFlag) || item.isFailed) {
      hasWarning = true;
    }
  });

  const arrayGroups: { [identifier: string]: BloodTestResultItem[] } = {};

  Object.keys(groups).forEach((identifier) => {
    arrayGroups[identifier] = Object.values(groups[identifier]).sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
  });

  return {
    hasWarning,
    groups,
    arrayGroups,
    array: Object.keys(arrayGroups)
      .sort()
      .map((id) => ({
        id,
        items: arrayGroups[id],
      })),
  };
};

export const truncateName = (name: string, long?: boolean) => {
  const truncated = truncate(name, {
    length: long ? 40 : 20,
  });
  return {
    truncated,
    isTruncated: name !== truncated,
  };
};

export interface MappedBloodTestResult extends Omit<BloodTestResult, "result"> {
  result: { [name: string]: BloodTestResultItem };
  testProfileNames: string[];
  testProfileCodes: string[];
}

export const genComparisonData = (results: BloodTestResult[]) => {
  const groups: {
    [identifier: string]: { [name: string]: true };
  } = {};

  const mappedResults = results.map(
    (r): MappedBloodTestResult => {
      const map: MappedBloodTestResult["result"] = {};
      const testProfiles = r.testProfiles || [];
      r.results.forEach((item) => {
        map[item.name] = item;
        if (!groups[item.identifier]) groups[item.identifier] = {};
        groups[item.identifier][item.name] = true;
      });
      return {
        ...r,
        result: map,
        testProfileNames: testProfiles.map((p) => p.name),
        testProfileCodes: testProfiles
          .map((p) => p.code)
          .filter((c) => !!c) as string[],
      };
    }
  );

  const sortedGroups: {
    identifier: string;
    names: string[];
  }[] = [];

  Object.keys(groups)
    .sort()
    .forEach((identifier) => {
      sortedGroups.push({
        identifier,
        names: Object.keys(groups[identifier]).sort(),
      });
    });

  return {
    groups: sortedGroups,
    mappedResults,
  };
};

export const getDateData = (d?: string) => {
  if (!d) {
    return {
      name: "N/A",
      subName: "",
    };
  }

  const { date, time } = formatDateTimeToDisplayInCompanyTZ(d);
  return {
    name: date,
    subName: time,
  };
};

export const genHistoricalData = (
  results: BloodTestResult[],
  obxName: string
) => {
  const data: BloodTestResultItemHistory[] = [];
  let hasWarning = false;

  results.forEach((r) => {
    r.results.forEach((item) => {
      if (item.name.toLowerCase() === obxName.toLowerCase()) {
        data.push({
          ...item,
          sampleCollectedOn: r.sampleCollectedOn,
          sampleReceivedByLabOn: r.sampleReceivedByLabOn,
          id: r.id,
        });

        if (shouldDisplayAbnormalFlag(item.abnormalFlag)) hasWarning = true;
      }
    });
  });

  return { data, hasWarning };
};

export interface MappedHistoryData {
  [obxName: string]: BloodTestResultItemHistory[];
}

export interface HistorialObxCounts {
  [obxName: string]: number;
}

export const countObxForBloodTestResults = (results: BloodTestResult[]) => {
  const counts: HistorialObxCounts = {};
  results.forEach((r) => {
    r.results.forEach((item) => {
      const name = item.name.toLowerCase();
      counts[name] = (counts[name] || 0) + 1;
    });
  });

  return counts;
};

export const addObxCountToBloodTestResult = (
  result: BloodTestResult,
  obxCounts: HistorialObxCounts
) => {
  const cloned = cloneDeep(result);
  cloned.results.forEach((r) => {
    r.historicalCount = obxCounts[r.name.toLowerCase()] || 1;
  });
  return cloned;
};

export const addObxCountToNewBloodTestResult = (
  result: BloodTestResult,
  obxCounts: HistorialObxCounts
) => {
  const cloned = cloneDeep(result);
  const counts = cloneDeep(obxCounts);
  cloned.results.forEach((item) => {
    const name = item.name.toLowerCase();
    counts[name] = (counts[name] || 0) + 1;
  });
  cloned.results.forEach((item) => {
    item.historicalCount = counts[item.name.toLowerCase()];
  });
  return cloned;
};

export const genHistoricalDataFromItems = (
  items: BloodTestResultItemHistory[]
) => {
  return {
    data: items,
    hasWarning: !!items.find((i) => shouldDisplayAbnormalFlag(i.abnormalFlag)),
  };
};

export const truncateFileName = (name: string, length = 40) => {
  const splits = name.split(".");
  const firstPart = splits.slice(0, -1).join(".");
  const last3 = firstPart.slice(firstPart.length - 3);
  const first = firstPart.slice(0, firstPart.length - 3);
  const truncatedFirst = truncate(first, {
    length,
  });
  const truncated = `${truncatedFirst}${last3}.${splits[splits.length - 1]}`;

  return {
    truncated,
    isTruncated: truncated !== name,
  };
};

interface CollectedDateSortResult {
  sampleCollectedOn?: string;
  sampleReceivedByLabOn?: string;
}

export const getCollectedDate = ({
  sampleCollectedOn,
  sampleReceivedByLabOn,
}: CollectedDateSortResult) => {
  if (!sampleCollectedOn && !sampleReceivedByLabOn) return null;

  let date = sampleCollectedOn ? new Date(sampleCollectedOn) : null;
  if (sampleReceivedByLabOn) {
    const receivedDate = new Date(sampleReceivedByLabOn);
    if (!date || isAfter(date, receivedDate)) date = receivedDate;
  }
  return date;
};

export const sortByCollectedDate = (
  a: CollectedDateSortResult,
  b: CollectedDateSortResult,
  isDesc?: boolean
) => {
  const dateA = getCollectedDate(a);
  const dateB = getCollectedDate(b);
  if (!dateA) return isDesc ? 1 : -1;
  if (!dateB) return isDesc ? -1 : 1;
  if (isDesc) {
    return isAfter(dateA, dateB) ? -1 : 1;
  }
  return isAfter(dateA, dateB) ? 1 : -1;
};

export const processSampleCollectedDate = (
  {
    sampleCollectedOn,
    sampleReceivedByLabOn,
  }: {
    sampleCollectedOn?: string | null;
    sampleReceivedByLabOn?: string | null;
  },
  {
    notFallback,
    isDetailsView,
  }: {
    notFallback?: boolean;
    isDetailsView?: boolean;
  } = {}
): {
  date: string;
  time: string;
  warning: string;
} => {
  const MISSING_MESSAGE = "Sample Collection Date was not provided";
  const ERROR_MESSAGE = isDetailsView
    ? "The recorded sample collected date is after sample received date, a data entry error likely occurred and the sample collected date is likely invalid."
    : "Recorded sample collected date is likely erroneous and thus the sample received date is shown.";

  const emptyResult = {
    date: "",
    time: "",
    warning: "",
  };

  if (!sampleCollectedOn && !sampleReceivedByLabOn) {
    return emptyResult;
  }

  if (!sampleReceivedByLabOn) {
    return {
      ...formatDateTimeToDisplayInCompanyTZ(sampleCollectedOn!),
      warning: "",
    };
  }

  if (!sampleCollectedOn) {
    if (notFallback) {
      return {
        ...emptyResult,
        warning: MISSING_MESSAGE,
      };
    }

    return {
      ...formatDateTimeToDisplayInCompanyTZ(sampleReceivedByLabOn),
      warning: MISSING_MESSAGE,
    };
  }

  if (isAfter(new Date(sampleCollectedOn), new Date(sampleReceivedByLabOn))) {
    return {
      ...formatDateTimeToDisplayInCompanyTZ(
        notFallback ? sampleCollectedOn : sampleReceivedByLabOn
      ),
      warning: ERROR_MESSAGE,
    };
  }

  return {
    ...formatDateTimeToDisplayInCompanyTZ(sampleCollectedOn),
    warning: "",
  };
};

export const shouldShowPDFButton = (result: BloodTestResult) => {
  return (
    result.inputMethod !== BloodTestResultInputMethodEnum.manual ||
    !!result.s3Key
  );
};

export const mapRelatedPathwaysByLab = (pathways?: PathwayRes[]) => {
  if (!pathways) return undefined;
  const map: { [labId: string]: PathwayRes[] } = {};
  pathways.forEach((p) => {
    const lab = (p as any).diagonisticLab as Lab;
    if (lab) {
      if (!map[lab.id]) map[lab.id] = [];
      map[lab.id].push(p);
    }
  });
  return map;
};

export interface LabOrder {
  id: string;
  name: string;
  key: string;
  rank: number;
}

export const processLabsOrder = (
  input: {
    lab: {
      id: string;
      zohoID: string;
      name: string;
      key: string;
    };
    rank: number;
  }[]
) => {
  const output: LabOrder[] = input.map(({ lab: { id, name, key }, rank }) => {
    return {
      id,
      name,
      key,
      rank,
    };
  });
  return output;
};

export const sortObxCodeLabByLabsOrder = (
  labs: LabObxCode[],
  mappedLabsOrder: { [labKey: string]: LabOrder },
  mappedLabs: { [labKey: string]: Lab }
) => {
  const completes: LabObxCode[] = [];
  const incompletes: LabObxCode[] = [];
  labs.forEach((l) => {
    if (
      l.normalRange !== null &&
      l.observationAltIdentifier !== null &&
      l.unit !== null
    ) {
      completes.push(l);
    } else {
      incompletes.push(l);
    }
  });

  const rearranged = [...completes, ...incompletes];

  rearranged.sort((a, b) => {
    const rankA = mappedLabsOrder[a.key].rank;
    const rankB = mappedLabsOrder[b.key].rank;
    return rankA < rankB ? -1 : 1;
  });

  const obj: { [key: string]: ShortMapObxCodeDetails } = {};

  rearranged.forEach((l) => {
    const key = `${l.observationAltIdentifier}|${l.unit}|${l.normalRange}`;
    if (!obj[key]) {
      obj[key] = {
        labs: [],
        unit: l.unit,
        observationID: l.observationAltIdentifier,
        normalRange: l.normalRange,
      };
    }
    const lab = mappedLabs[l.key];
    obj[key].labs.push({
      key: lab.key,
      code: lab.name,
    });
  });
  return Object.values(obj).map((d, index) => ({
    ...d,
    id: `${index}`,
  }));
};

export const mapFullObxCodes = (
  codes: TestObxCodes[],
  labsOrder: LabOrder[],
  labs: Lab[],
  currentLab?: Lab | null
) => {
  const rearrangedLabsOrder = [...labsOrder];
  if (currentLab) {
    rearrangedLabsOrder.sort((a, b) => {
      if (a.key === currentLab.key) return -1;
      if (b.key === currentLab.key) return 1;
      return a.rank < b.rank ? -1 : 1;
    });
  }
  const mappedLabsOrder: { [labKey: string]: LabOrder } = {};
  labsOrder.forEach((or, index) => {
    mappedLabsOrder[or.key] = {
      ...or,
      rank: index,
    };
  });

  const mappedLabs: { [labKey: string]: Lab } = {};
  labs.forEach((lab) => {
    mappedLabs[lab.key] = lab;
  });

  const mapped: MapObxCode[] = [];
  codes.forEach((code) => {
    if (code.status !== StatusEnum.Active) return;
    mapped.push({
      id: code.id,
      bmhName: code.bmhCodeName,
      details: sortObxCodeLabByLabsOrder(
        code.labs,
        mappedLabsOrder,
        mappedLabs
      ),
    });
  });
  return mapped;
};
