import React, { memo, useCallback, useState } from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Button,
  Box,
} from "@material-ui/core";
import { BloodTestResult } from "@deep-consulting-solutions/bmh-constants";

import { useGetZohoClients } from "hooks";
import { testResultsRequests } from "redux/testResults";
import { notifications } from "services";
import { getENText } from "helpers";
import Loader from "components/Loader";

const useStyle = makeStyles(({ typography: typo, palette: p, spacing: s }) => {
  const text = {
    color: p.grey[600],
    ...typo.body1,
  };
  return {
    textUpper: {
      ...text,
      marginBottom: s(1),
    },
    textLower: {
      ...text,
      marginTop: s(1),
    },
    highlight: {
      color: p.text.primary,
    },
    textItem: {
      ...typo.body1,
    },
    actionsWrapper: {
      marginRight: s(1),
      marginBottom: s(1),
    },
    cancel: {
      marginRight: s(1),
    },
  };
});

interface DialogDeleteProps extends Omit<DialogProps, "open" | "results"> {
  closeDialog: (success?: boolean) => any;
  results: BloodTestResult[] | null;
}

const DialogDelete: React.FC<DialogDeleteProps> = ({
  closeDialog,
  results,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const { firstClient } = useGetZohoClients({ setLoading });

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const onDeleteClick = useCallback(async () => {
    if (!results) return;
    setSubmitting(true);
    try {
      await testResultsRequests.deleteResults(results.map((r) => r.id));
      notifications.notifySuccess(
        getENText(
          results.length > 1
            ? "results.delete.success.plural"
            : "results.delete.success.single",
          {
            count: `${results.length}`,
          }
        )
      );
      closeDialog(true);
      setSubmitting(false);
    } catch {
      setSubmitting(false);
    }
  }, [closeDialog, results]);

  const classes = useStyle();
  const rs = results || [];
  const isPlural = rs.length > 1;

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={!!results}
      onClose={onClose}
      {...props}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" color="primary">
          Delete Blood Test Results
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Loader open={loading} />
        <Typography className={classes.textUpper}>
          You are going to delete{" "}
          <b className={classes.highlight}>{rs.length}</b> test result
          {isPlural ? "s" : ""} of{" "}
          <b className={classes.highlight}>
            {firstClient ? firstClient.Full_Name : ""}
          </b>
          :
        </Typography>
        {rs.map((r) =>
          r.testProfiles ? (
            <Typography key={r.id} className={classes.textItem}>
              - {r.testProfiles.map((p) => p.name).join(", ")}
            </Typography>
          ) : null
        )}
        <Typography className={classes.textLower}>
          Are you sure you want to delete {isPlural ? "them" : "it"}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box className={classes.actionsWrapper}>
          <Button
            className={classes.cancel}
            color="primary"
            variant="text"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button color="primary" onClick={onDeleteClick} disabled={submitting}>
            DELETE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DialogDelete);
