import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { Add } from "@material-ui/icons";
import { checkoutPageRequests } from "./requests";
import {
  AddCheckoutPageForm,
  EditCheckoutPageForm,
} from "./AddCheckoutPageDialog";
import { CheckoutPage as CheckoutPageType, FormValues } from "./types";
import { LabKeyFieldProps } from "./AddCheckoutPageDialog/LabKeyField";
import { TestProfilesFieldProps } from "./AddCheckoutPageDialog/TestProfilesField";
import { BloodDrawerNameFieldProps } from "./AddCheckoutPageDialog/BloodDrawerNameField";
import { Table } from "./Table";
import { DeleteCheckoutPageDialog } from "./DeleteCheckoutPageDialog";

const useStyles = makeStyles(({ spacing: s }) => {
  return {
    containerWrapper: {
      background: "white",
    },
    container: {},
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      background: "white",
      flex: 1,
    },
    paper: {
      flex: 1,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      padding: s(2),
    },
    controller: {
      paddingTop: s(2),
    },
  };
});

export const CheckoutPage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [
    checkoutPageToEdit,
    setCheckoutPageToEdit,
  ] = useState<CheckoutPageType | null>(null);
  const [
    checkoutPageToDelete,
    setCheckoutPageToDelete,
  ] = useState<CheckoutPageType | null>(null);
  const [openAddCheckoutForm, setOpenAddCheckoutForm] = useState(false);
  const [testProfiles, setTestProfiles] = useState<
    TestProfilesFieldProps["testProfiles"]
  >([]);
  const [bloodTestingLabs, setBloodTestingLabs] = useState<
    LabKeyFieldProps["labKeys"]
  >([]);

  const [bloodDrawers, setBloodDrawers] = useState<
    BloodDrawerNameFieldProps["bloodDrawers"]
  >([]);

  const [checkOutPages, setCheckOutPages] = useState<CheckoutPageType[]>([]);

  const fetchCheckoutPages = useCallback(async () => {
    const checkoutPagesRes = await checkoutPageRequests.fetchCheckoutPages();
    setCheckOutPages(checkoutPagesRes);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [
        testProfilesRes,
        bloodTestLabsRes,
        bloodDrawersRes,
      ] = await Promise.all([
        checkoutPageRequests.fetchTestProfiles(),
        checkoutPageRequests.fetchBloodTestingLab(),
        checkoutPageRequests.fetchBloodDrawer(),
        fetchCheckoutPages(),
      ]);

      setTestProfiles(testProfilesRes);
      setBloodTestingLabs(bloodTestLabsRes);
      setBloodDrawers(bloodDrawersRes);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [fetchCheckoutPages]);

  const onOpenAddCheckoutForm = useCallback(() => {
    setOpenAddCheckoutForm(true);
  }, []);

  const onCloseAddCheckoutForm = useCallback(() => {
    setOpenAddCheckoutForm(false);
  }, []);

  const onGetPayload = useCallback((values: FormValues) => {
    return {
      name: values.name!,
      labKey: values.labKey!.value,
      testProfiles: values.testProfiles.map((testProfile) => testProfile.value),
      bloodTakingOption: values.bloodTakingOption!.value,
      bloodDrawerName: values.bloodDrawerName?.value || "",
      gender: values.gender!.value,
      bloodDrawFeeCollected: values.bloodDrawFeeCollected,
    };
  }, []);

  const onSubmitAddCheckoutPageForm = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true);
        await checkoutPageRequests.postCheckoutPage(onGetPayload(values));
        await fetchCheckoutPages();
      } catch (error) {
        throw new Error((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [fetchCheckoutPages, onGetPayload]
  );

  const handleChangeCheckoutPage = useCallback(
    (checkoutPage: CheckoutPageType, action: "edit" | "delete") => {
      if (action === "edit") {
        setCheckoutPageToEdit(checkoutPage);
      } else {
        setCheckoutPageToDelete(checkoutPage);
      }
    },
    []
  );

  const onCloseEditCheckoutForm = useCallback(() => {
    setCheckoutPageToEdit(null);
  }, []);

  const onSubmitEditCheckoutPageForm = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true);
        await checkoutPageRequests.putCheckoutPage(
          checkoutPageToEdit!.Id,
          onGetPayload(values)
        );
        await fetchCheckoutPages();
      } catch (error) {
        throw new Error((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [fetchCheckoutPages, onGetPayload, checkoutPageToEdit]
  );

  const onDeleteCheckoutPage = useCallback(async () => {
    try {
      setLoading(true);
      await checkoutPageRequests.deleteCheckoutPage(checkoutPageToDelete!.Id);
      await fetchCheckoutPages();
      setCheckoutPageToDelete(null);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [fetchCheckoutPages, checkoutPageToDelete]);

  const onCloseDeleteCheckoutDialog = useCallback(() => {
    setCheckoutPageToDelete(null);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.wrapper}>
        <Paper className={classes.paper} elevation={2}>
          <Box mb={2}>
            <Typography variant="subtitle1">
              Checkout Pages Management
            </Typography>
          </Box>
          <Box mb={2}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button color="primary" onClick={onOpenAddCheckoutForm}>
                  ADD CHECKOUT PAGE <Add fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Loader open={loading} />
          <AddCheckoutPageForm
            handleSubmit={onSubmitAddCheckoutPageForm}
            onClose={onCloseAddCheckoutForm}
            open={openAddCheckoutForm}
            labKeys={bloodTestingLabs}
            testProfiles={testProfiles}
            bloodDrawers={bloodDrawers}
          />
          <EditCheckoutPageForm
            handleSubmit={onSubmitEditCheckoutPageForm}
            onClose={onCloseEditCheckoutForm}
            open={!!checkoutPageToEdit}
            labKeys={bloodTestingLabs}
            testProfiles={testProfiles}
            bloodDrawers={bloodDrawers}
            checkoutPageToEdit={checkoutPageToEdit}
          />
          <DeleteCheckoutPageDialog
            handleDelete={onDeleteCheckoutPage}
            onClose={onCloseDeleteCheckoutDialog}
            open={!!checkoutPageToDelete}
          />
          <Table
            loading={loading}
            testProfiles={testProfiles}
            checkOutPages={checkOutPages}
            handleChangeCheckoutPage={handleChangeCheckoutPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};
