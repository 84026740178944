import { Box, FormControlLabel, Radio } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import { getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import { DBPharmacyProduct } from "types";
import {
  MedicationCategory,
  MissingState,
  ProductType,
  TreatmentOrder,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";
import { Replace } from "./Replace";
import { DispensePartially } from "./DispensePartially";
import { SuggestedReplacement } from "./SuggestedReplacement";

interface HandleMissingItemsFieldsProps {
  item: TreatmentOrderItem;
  fieldName: string;
  treatmentOrder?: TreatmentOrder;
  replacements: DBPharmacyProduct[];
  manufacturers: string[];
}

export const HandleMissingItemsFields: React.FC<HandleMissingItemsFieldsProps> = ({
  item,
  fieldName,
  treatmentOrder,
  replacements,
  manufacturers,
}) => {
  const { values } = useFormikContext();

  const missingQuantity = useMemo(
    () => getValueFromObject(`${fieldName}.missingQuantity`, values),
    [fieldName, values]
  );
  const missingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [fieldName, values]
  );
  const shouldSendToBMH = useMemo(
    () => getValueFromObject("shouldSendToBMH", values),
    [values]
  );

  const isPrescriptionMedication = useMemo(
    () =>
      item.productItem?.Medication_Category === MedicationCategory.PRESCRIPTION,
    [item]
  );
  const isOverTheCounter = useMemo(
    () =>
      item.productItem?.Medication_Category ===
        MedicationCategory.OVER_THE_COUNTER ||
      item.productItem.Product_Type === ProductType.SUPPLEMENTARY,
    [item]
  );

  if (!missingQuantity) {
    return null;
  }

  return (
    <>
      <Box my={2}>
        {missingQuantity < item.Quantity && isPrescriptionMedication && (
          <Alert severity="warning">
            <AlertTitle>The item should be dispensed partially.</AlertTitle>
            {treatmentOrder?.Manual
              ? "The missing quantity will be marked as owed. You can also mark the whole order as owed If you expect it to be available in time for the patient to continue treatment uninterrupted."
              : "For the missing quantity mark it as owed if you expect it to be available in time for the patient to continue treatment uninterrupted. Otherwise, send it to BMH to resolve unavailability."}
          </Alert>
        )}
        {((missingQuantity === item.Quantity && isPrescriptionMedication) ||
          shouldSendToBMH) && (
          <>
            <SuggestedReplacement
              fieldName={fieldName}
              item={item}
              replacements={replacements}
              noLeftMargin
            />
          </>
        )}
        {missingQuantity === item.Quantity &&
          isPrescriptionMedication &&
          treatmentOrder?.Manual && (
            <Alert severity="error">
              The order can&#8217;t be dispensed. Contact the requester to send
              a different signed prescription with an available replacement or
              mark the order as owed if the item will be available in the near
              future.
            </Alert>
          )}
      </Box>

      {!shouldSendToBMH && (
        <Field component={RadioGroup} name={`${fieldName}.missingState`}>
          {isOverTheCounter && missingQuantity <= item.Quantity && (
            <Box mb={2}>
              <FormControlLabel
                value={MissingState.REPLACE}
                control={<Radio color="primary" />}
                label="Replace"
              />
              {missingState === MissingState.REPLACE && (
                <Replace
                  fieldName={fieldName}
                  item={item}
                  replacements={replacements}
                  manufacturers={manufacturers}
                />
              )}
            </Box>
          )}
          {((isOverTheCounter && missingQuantity === item.Quantity) ||
            (isPrescriptionMedication && missingQuantity < item.Quantity)) && (
            <Box mb={2}>
              <FormControlLabel
                value={MissingState.MARK_AS_OWED}
                control={<Radio color="primary" />}
                label="Mark as Owed"
              />
            </Box>
          )}
          {isOverTheCounter && missingQuantity < item.Quantity && (
            <Box mb={2}>
              <FormControlLabel
                value={MissingState.DISPENSE_PARTIALLY}
                control={<Radio color="primary" />}
                label="Dispense Partially"
              />
              {missingState === MissingState.DISPENSE_PARTIALLY && (
                <DispensePartially
                  fieldName={fieldName}
                  item={item}
                  manufacturers={manufacturers}
                />
              )}
            </Box>
          )}
          {isPrescriptionMedication && missingQuantity < item.Quantity && (
            <Box mb={2}>
              <FormControlLabel
                value={MissingState.SEND_TO_BMH}
                control={<Radio color="primary" />}
                label="Send to BMH"
              />
              {missingState === MissingState.SEND_TO_BMH && (
                <SuggestedReplacement
                  fieldName={fieldName}
                  item={item}
                  replacements={replacements}
                />
              )}
            </Box>
          )}
        </Field>
      )}
    </>
  );
};
