import { Box, Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  AutoCompleteOption,
  ZohoMedUsageUnit,
  ZohoPharmacy,
  ZohoProduct,
  ZohoSelectedProduct,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
} from "types";
import { getValueFromObject } from "helpers";
import { useFormikContext } from "formik";
import { PharmaciesField } from "../PharmaciesField";
import { BillingCycleUnitField } from "../BillingCycleUnitField";
import { TreatmentMedicinesAndProducts } from "../TreatmentMedicinesAndProducts";
import { SupplementaryProducts } from "../SupplementaryProducts";

interface Props {
  pharmacies: ZohoPharmacy[];
  suggestedMedicines: ZohoTreatmentMedicine[];
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  bacteriostaticProducts: ZohoProduct[];
  supplementaryProducts: ZohoProduct[];
  medUsageUnits: ZohoMedUsageUnit[];
  selectedSupplementaryProducts: ZohoSelectedProduct[];
}

export const UpdateTreatmentMedicineAndProductsTab = ({
  pharmacies,
  treatmentTreatmentAreasById,
  suggestedMedicines,
  bacteriostaticProducts,
  supplementaryProducts,
  medUsageUnits,
  selectedSupplementaryProducts,
}: Props) => {
  const { values, initialValues } = useFormikContext();

  const selectedPharmacies = useMemo(
    () =>
      (getValueFromObject("pharmacies", values) as AutoCompleteOption[]) || [],
    [values]
  );

  const initialSelectedPharmacies = useMemo(() => {
    return (
      (getValueFromObject(
        "pharmacies",
        initialValues
      ) as AutoCompleteOption[]) || []
    );
  }, [initialValues]);

  const medUsageUnitsById = useMemo(() => {
    return medUsageUnits.reduce<Record<string, ZohoMedUsageUnit>>(
      (total, medUsageUnit) => {
        return { ...total, [medUsageUnit.id]: medUsageUnit };
      },
      {}
    );
  }, [medUsageUnits]);

  return (
    <Box mb={4}>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item>
            <PharmaciesField zohoPharmacies={pharmacies} maxWidth={500} />
          </Grid>
          <Grid item>
            <BillingCycleUnitField />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Typography color="primary" variant="subtitle2">
          Medicines
        </Typography>
      </Box>
      <TreatmentMedicinesAndProducts
        suggestedMedicines={suggestedMedicines}
        treatmentTreatmentAreasById={treatmentTreatmentAreasById}
        bacteriostaticProducts={bacteriostaticProducts}
        selectedPharmacies={selectedPharmacies}
        initialSelectedPharmacies={initialSelectedPharmacies}
        medUsageUnits={medUsageUnitsById}
      />

      <SupplementaryProducts
        supplementaryProducts={supplementaryProducts}
        selectedPharmacies={selectedPharmacies}
        initialSelectedPharmacies={initialSelectedPharmacies}
        medUsageUnits={medUsageUnitsById}
        selectedSupplementaryProducts={selectedSupplementaryProducts}
      />
    </Box>
  );
};
