import { Dialog, DialogProps } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { zohoServices } from "services";
import { WidgetLayoutContext } from "./context";
import { WidgetDimension } from "./types";

const MAX_WIDTH = 1000;
const MIN_WIDTH = 700;
const MAX_HEIGHT = 830;
export const WidgetLayout = ({
  children,
  ...props
}: Omit<DialogProps, "open">) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [titleWidth, setTitleWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [actionsHeight, setActionsHeight] = useState(0);
  const [actionsWidth, setActionsWidth] = useState(0);

  const setActionsDimension = useCallback((dimension: WidgetDimension) => {
    setActionsHeight(dimension.height);
    setActionsWidth(dimension.width);
  }, []);
  const setContentDimension = useCallback((dimension: WidgetDimension) => {
    setContentHeight(dimension.height);
    setContentWidth(dimension.width);
  }, []);
  const setTitleDimension = useCallback((dimension: WidgetDimension) => {
    setTitleHeight(dimension.height);
    setTitleWidth(dimension.width);
  }, []);

  const totalWidth = useMemo(() => {
    const width =
      [titleWidth, contentWidth, actionsWidth].reduce((previous, current) => {
        return current > previous ? current : previous;
      }, 0) + 100;
    const minWidth = width < MIN_WIDTH ? MIN_WIDTH : width;
    return minWidth < MAX_WIDTH ? minWidth : MAX_WIDTH;
  }, [titleWidth, contentWidth, actionsWidth]);

  const totalHeight = useMemo(() => {
    const height =
      [titleHeight, contentHeight, actionsHeight].reduce(
        (previous, current) => {
          return previous + Number(current);
        },
        0
      ) + 100;

    return height > MAX_HEIGHT ? MAX_HEIGHT : height;
  }, [titleHeight, contentHeight, actionsHeight]);

  const resize = useCallback(() => {
    zohoServices.resizeWidget({
      height: totalHeight,
      width: totalWidth,
    });
  }, [totalHeight, totalWidth]);

  useEffect(() => {
    resize();
  }, [resize]);

  return (
    <WidgetLayoutContext.Provider
      value={{
        setActionsDimension,
        setContentDimension,
        setTitleDimension,
      }}
    >
      <Dialog {...props} open fullScreen hideBackdrop>
        {children}
      </Dialog>
    </WidgetLayoutContext.Provider>
  );
};
