import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { ZohoClientRecord } from "types";
import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";

export const useGetZohoClients = ({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [clients, setClients] = useState<ZohoClientRecord[]>([]);
  const ids = useSelector(zohoSelectors.getIds);

  const getEmails = useCallback(async () => {
    setLoading(true);

    try {
      const data = await zohoServices.getClientRecords({ ids });
      setClients(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [ids, setLoading]);

  const map = useMemo(() => {
    const m: { [id: string]: ZohoClientRecord } = {};
    clients.forEach((c) => {
      m[c.id] = c;
    }, []);
    return m;
  }, [clients]);

  useEffect(() => {
    getEmails();
  }, [getEmails]);

  return {
    clients,
    firstClient: (clients[0] || null) as ZohoClientRecord | null,
    map,
  };
};
