import { n8nClient } from "apis";
import { ManualOrderRequestPayload, ZohoPrescription } from "types";

const messageToCheck =
  "There is a related order with state pending dispensing or pending final checks";

export async function getPrescription(prescriptionId: string) {
  try {
    const response = await n8nClient.get<ZohoPrescription>(
      `/prescription/${prescriptionId}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getPrescriptionRelatedOrder(prescriptionId: string) {
  try {
    const response = await n8nClient.get<{
      message: string;
      data: {
        name: string;
        cmrLink: string;
      };
    }>(`prescriptions/related-order/${prescriptionId}`);

    const { data, message } = response.data;
    if (message === messageToCheck) {
      return {
        hasPendingDispensingOrder: true,
        orderName: data.name,
        orderUrl: data.cmrLink,
      };
    }
    return {
      hasPendingDispensingOrder: false,
      orderName: "",
      orderUrl: "",
    };
  } catch (error) {
    return null;
  }
}

export async function createManualOrder(payload: ManualOrderRequestPayload) {
  try {
    const response = await n8nClient.post(
      "/prescriptions/order-manual-creation",
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}
