import { AutoCompleteOption } from "types";

export interface FormValues {
  name: string | null;
  labKey: AutoCompleteOption | null;
  testProfiles: AutoCompleteOption[];
  bloodTakingOption: AutoCompleteOption | null;
  bloodDrawerName: AutoCompleteOption | null;
  bloodDrawFeeCollected: boolean;
  gender: AutoCompleteOption | null;
}

export interface CheckoutPage {
  Id: string;
  Gender: string;
  BloodTestingLab: {
    Name: string;
    Key: string;
  };
  BloodTakingOption: string;
  BloodDrawer: {
    Name: string;
  };
  BloodDrawFeeCollected: boolean;
  Name: string;
  "MasterZohoCheckoutPageTestProfile List": {
    MasterTestProfile: {
      Id: string;
    };
  }[];
}

export type Columns =
  | "Name"
  | "LabKey"
  | "TestProfiles"
  | "BloodTakingOption"
  | "BloodDrawerName"
  | "BloodDrawerFeeCollected"
  | "Gender";

export const TABLE_META: {
  [key in Columns]: {
    label: string;
  };
} = {
  Name: {
    label: "Name",
  },
  LabKey: {
    label: "Lab key",
  },
  TestProfiles: {
    label: "Test profiles",
  },
  BloodTakingOption: {
    label: "Blood taking option",
  },
  BloodDrawerName: {
    label: "Blood drawer name",
  },
  BloodDrawerFeeCollected: {
    label: "Blood drawer fee collected",
  },
  Gender: {
    label: "Gender",
  },
};
