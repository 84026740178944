import { useCallback } from "react";
import { useSelector } from "react-redux";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";

export const useUpdateBluePrint = () => {
  const zohoIDs = useSelector(zohoSelectors.getIds);
  const zohoEntity = useSelector(zohoSelectors.getEntity);

  const updateBluePrint = useCallback(
    async (transitionName: string, nextFieldValue?: string) => {
      const isUpdated = await zohoServices.updateBlueprint({
        Entity: zohoEntity,
        RecordID: zohoIDs[0],
        transitionName,
        nextFieldValue,
      });
      return isUpdated;
    },
    [zohoEntity, zohoIDs]
  );

  return {
    zohoIDs,
    zohoEntity,
    updateBluePrint,
  };
};
