import { Column } from "./types";

export const MAX_LIMIT = 200;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const PENDING_MANIFEST_STATE = "Pending Manifesting";

export const defaultColumns: Column[] = [
  { id: "state", label: "Stage", minWidth: 170 },
  {
    id: "orderNumber",
    label: "Order No",
    minWidth: 130,
  },
  {
    id: "courier",
    label: "Courier",
    minWidth: 100,
  },
  {
    id: "courierOrderNumber",
    label: "Courier Order No",
    minWidth: 140,
  },
  {
    id: "patientName",
    label: "Patient Name",
    minWidth: 120,
  },
  {
    id: "patientShippingAddress",
    label: "Patient Shipping Address",
    minWidth: 180,
  },
  {
    id: "patientMobile",
    label: "Patient Mobile",
    minWidth: 130,
  },
];

export const defaultColumnsAlt = [
  ...defaultColumns,
  {
    id: "courierManifestNumber" as const,
    label: "Courier Manifest Number",
    minWidth: 150,
  },
];
