export const steps = ["Prescription Details", "First Order Details"];

export const newPrescriptionItem = {
  product: null,
  prescriptionTotalQuantity: undefined,
  usageNotes: "",
};

export const externalDoctorOption = {
  value: "external",
  label: "External",
  title: "External",
};

export const emptyProduct = null;
