import {
  BloodDrawBilledStatus,
  BloodDrawer,
  BloodTakingOptionEnum,
  GenderEnum,
  Lab,
} from "@deep-consulting-solutions/bmh-constants";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { Autocomplete } from "formik-material-ui-lab";
import {
  Box,
  Grid,
  MenuItem,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  Typography,
  makeStyles,
  Collapse,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  Alert,
  AlertTitle,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { ShippingAddressFormByCountry } from "components/ShippingAddressForm";
import { endOfYesterday } from "date-fns";
import { Field, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import { getENText } from "helpers";
import React, { useEffect, useMemo } from "react";
import { Contact, ZohoPathway } from "types";
import { specialNotesMax } from "widgets/PathwaySpecifyDetailsBlueprint/FormFields";
import {
  AfterResultConsultationEnum,
  OrderTypeEnum,
  ORDER_TYPE_DESCRIPTION,
} from "widgets/PathwaySpecifyDetailsBlueprint/types";
import {
  FormValues,
  PatientSourcedBloodDrawerEnum,
  PatientSourcedVariant,
  ZohoVariables,
} from "../types";
import { Instructions } from "./Instructions";
import {
  getSelectedBloodDrawerDefaultLab,
  getShouldShowBillingForm,
} from "../helpers";

interface Props {
  client: Contact;
  variables: Record<ZohoVariables, string>;
  selectedBloodDrawer?: BloodDrawer;
  disableCity: boolean;
  showPatientSourced: false | PatientSourcedVariant;
  pathway: ZohoPathway;
  filteredLabsByBloodDrawer: Lab[];
  extraValidationFieldErrors: Record<string, string | undefined>;
  shouldShowFirstLabField: boolean;
}

const useStyles = makeStyles(() => ({
  alert: {
    fontWeight: 500,
    "& .MuiAlert-icon": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
  feeCollection: {
    width: "100%",
  },
}));

export const OfferingPatientSourced = ({
  variables,
  client,
  disableCity,
  selectedBloodDrawer,
  showPatientSourced,
  pathway,
  filteredLabsByBloodDrawer,
  extraValidationFieldErrors,
  shouldShowFirstLabField,
}: Props) => {
  const formProps = useFormikContext<FormValues>();

  const {
    values,
    errors: {
      patientSourcedLab: patientSourcedLabError,
      billInvoiceNumber: billInvoiceNumberFormikError,
    },
    errors,
    setFieldValue,
    touched: { billInvoiceNumber: billInvoiceNumberTouched },
  } = useMemo(() => formProps, [formProps]);

  const billInvoiceNumberError: string | undefined = useMemo(
    () =>
      billInvoiceNumberFormikError ||
      extraValidationFieldErrors.billInvoiceNumber,
    [billInvoiceNumberFormikError, extraValidationFieldErrors]
  );
  const showBillInvoiceNumberError = useMemo(
    () => !!billInvoiceNumberTouched && !!billInvoiceNumberError,
    [billInvoiceNumberTouched, billInvoiceNumberError]
  );

  const { clientShippingCountry, clientName } = useMemo(
    () => ({
      clientShippingCountry: values.shippingCountry || "",
      clientName:
        `${client.firstName ? `${client.firstName} ` : ""}${client.lastName}` ||
        "Client",
    }),
    [values.shippingCountry, client.firstName, client.lastName]
  );

  const showPatientSourcedLabError: boolean = useMemo(() => {
    return (
      !!patientSourcedLabError || !!extraValidationFieldErrors.patientSourcedLab
    );
  }, [patientSourcedLabError, extraValidationFieldErrors.patientSourcedLab]);

  const classes = useStyles();

  useEffect(() => {
    if (!shouldShowFirstLabField && showPatientSourced) {
      setFieldValue(
        "patientSourcedLab",
        getSelectedBloodDrawerDefaultLab(filteredLabsByBloodDrawer)
      );
    } else {
      setFieldValue("patientSourcedLab", null);
    }
  }, [
    filteredLabsByBloodDrawer,
    setFieldValue,
    shouldShowFirstLabField,
    showPatientSourced,
    selectedBloodDrawer?.lab?.id,
  ]);
  return (
    <>
      {showPatientSourced === "patient-sourced" ? (
        <Box mt={2}>
          <Instructions
            clientShippingCountry={clientShippingCountry}
            variables={variables}
          />
        </Box>
      ) : null}

      {showPatientSourced === "rest-fields" && !shouldShowFirstLabField ? (
        <Box mt={2}>
          <Alert
            severity="info"
            icon={<Info fontSize="inherit" />}
            className={classes.alert}
          >
            <Typography>{variables[ZohoVariables.Lab_Selection]}</Typography>
          </Alert>
        </Box>
      ) : null}

      <Box mt={2}>
        <Grid container spacing={2}>
          {showPatientSourced === "patient-sourced" ? (
            <Grid item xs={6}>
              <Field
                component={TextField}
                SelectProps={{
                  displayEmpty: true,
                }}
                name="patientSourcedBloodDrawer"
                required
                label="Blood Drawer"
                disabled
                value={PatientSourcedBloodDrawerEnum.PATIENT_SOURCED}
              />
            </Grid>
          ) : null}
          {!shouldShowFirstLabField ? (
            <Grid item xs={6}>
              <Field
                name="patientSourcedLab"
                component={Autocomplete}
                options={filteredLabsByBloodDrawer}
                getOptionLabel={(option: Lab) => option.name}
                getOptionSelected={(option: Lab, value: Lab) =>
                  option.id === value.id
                }
                style={{ width: "100%" }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <MuiTextField
                    {...params}
                    name="patientSourcedLab"
                    error={showPatientSourcedLabError}
                    helperText={
                      showPatientSourcedLabError &&
                      (patientSourcedLabError ||
                        extraValidationFieldErrors.patientSourcedLab)
                    }
                    label="Diagnostic Laboratory"
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <Field
              component={TextField}
              select
              SelectProps={{
                displayEmpty: true,
              }}
              name="bloodTakingOption"
              label="Blood Taking Option"
              required
            >
              {Object.values(BloodTakingOptionEnum).map((bloodTakingOption) => (
                <MenuItem key={bloodTakingOption} value={bloodTakingOption}>
                  {bloodTakingOption}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>

        <Collapse
          in={
            values.bloodTakingOption === BloodTakingOptionEnum.venepuncture &&
            !selectedBloodDrawer?.providesOwnKit
          }
        >
          <Box mt={2}>
            <Alert
              severity="info"
              icon={<Info fontSize="inherit" />}
              className={classes.alert}
            >
              <Typography>
                {variables[ZohoVariables.BTO_Venepuncture]}
              </Typography>
            </Alert>
          </Box>
        </Collapse>
        <Box mt={2}>
          <FormControl>
            <FormLabel>After Results Consultation*</FormLabel>
            <Field component={RadioGroup} row name="afterResultsConsultation">
              <FormControlLabel
                value={AfterResultConsultationEnum.PCM}
                control={<Radio color="primary" />}
                label={AfterResultConsultationEnum.PCM}
              />
              <FormControlLabel
                value={AfterResultConsultationEnum.DOCTOR}
                control={<Radio color="primary" />}
                label={AfterResultConsultationEnum.DOCTOR}
              />
              <FormControlLabel
                value={AfterResultConsultationEnum.NONE}
                control={<Radio color="primary" />}
                label={AfterResultConsultationEnum.NONE}
              />
            </Field>
          </FormControl>
        </Box>

        {values.afterResultsConsultation !==
          AfterResultConsultationEnum.NONE && (
          <>
            <Box mt={2}>
              <Field
                component={TextField}
                select
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: any) => value,
                }}
                name="orderType"
                label="Client Type"
                required
              >
                {Object.values(OrderTypeEnum).map((type) => (
                  <MenuItem key={type} value={type}>
                    <div style={{}}>
                      <Typography>{type}</Typography>
                      <Typography variant="caption">
                        {ORDER_TYPE_DESCRIPTION[type]}
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Grid item xs={6} />
          </>
        )}

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                component={FormikDatePicker}
                disablePast={false}
                disableToolbar={false}
                disableFuture
                maxDate={endOfYesterday()}
                maxDateMessage={getENText("validation.dob.inPast")}
                label={`${clientName} - Date of Birth`}
                name="dob"
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                component={TextField}
                select
                SelectProps={{
                  displayEmpty: true,
                }}
                name="gender"
                label={`${clientName} - Gender`}
                required
              >
                {Object.values(GenderEnum).map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="specialNotes"
                label="Special Notes (will appear on Order Form)"
                multiline
                max={specialNotesMax}
                helperText={getENText("validation.specialNotes.max", {
                  max: specialNotesMax.toString(),
                })}
              />
            </Grid>
          </Grid>
        </Box>
        {!selectedBloodDrawer?.providesOwnKit ? (
          <>
            <Box mt={2}>
              <Typography variant="h5">Shipping Address</Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <ShippingAddressFormByCountry
                  formikProps={formProps}
                  countryName="shippingCountry"
                  cityName={disableCity ? "shippingCity" : "city"}
                  disabledFields={{
                    shippingCountry: true,
                    shippingCity: disableCity,
                  }}
                  customFieldErrors={{
                    shippingCity:
                      errors.shippingCity ||
                      extraValidationFieldErrors.shippingCity,
                    city: errors.city || extraValidationFieldErrors.city,
                    shippingCountry:
                      errors.shippingCountry ||
                      extraValidationFieldErrors.shippingCountry,
                    zip: errors.zip || extraValidationFieldErrors.zip,
                    region: errors.region || extraValidationFieldErrors.region,
                    other: errors.other || extraValidationFieldErrors.other,
                  }}
                />
              </Grid>
            </Box>
          </>
        ) : null}

        {getShouldShowBillingForm(
          pathway.Blood_Draw_Fee_Collected,
          selectedBloodDrawer
        ) ? (
          <Box mt={2}>
            <Alert
              severity="warning"
              icon={<Info fontSize="inherit" />}
              className={classes.alert}
            >
              <AlertTitle>
                <Typography>Blood Draw Fee Must Be Collected!</Typography>
              </AlertTitle>
              <Typography>
                Our records indicate that the Blood Drawer you selected requires
                a fee to be paid to them by BMH. BMH thus must bill the client
                for it in return. You therefore need to bill the client
                according to the terms of this Blood Drawer
              </Typography>
            </Alert>

            <Box mt={2}>
              <FormControl className={classes.feeCollection}>
                <Field component={RadioGroup} row name="billedStatus">
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={0}
                  >
                    <Grid item>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid
                          item
                          xs={
                            values.billedStatus === BloodDrawBilledStatus.BILLED
                              ? 6
                              : undefined
                          }
                        >
                          <FormControlLabel
                            value={BloodDrawBilledStatus.BILLED}
                            control={<Radio color="primary" />}
                            label="I have billed client for the blood draw fee"
                          />
                        </Grid>
                        {values.billedStatus ===
                          BloodDrawBilledStatus.BILLED && (
                          <Grid item xs={6}>
                            <Field
                              component={TextField}
                              name="billInvoiceNumber"
                              label="Blood Draw Invoice Number"
                              required
                              error={showBillInvoiceNumberError}
                              helperText={billInvoiceNumberError}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={BloodDrawBilledStatus.WAIVED}
                        control={<Radio color="primary" />}
                        label="I obtained management approval to waive the fee"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={BloodDrawBilledStatus.NOT_BILLED}
                        control={<Radio color="primary" />}
                        label="I didn't bill the client yet"
                      />
                      <Typography variant="body2">
                        The client will be invoiced automatically
                      </Typography>
                    </Grid>
                  </Grid>
                </Field>
              </FormControl>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
