import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Lab,
  BloodDrawer,
  PathwayValidationErrorCodes,
  BloodDrawBilledStatus,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Done as SuccessIcon, Info } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { pathwayRequests } from "redux/pathway";
import Loader from "components/Loader";
import { getShippingAddressFormValidationSchema } from "components/ShippingAddressForm";
import * as Yup from "yup";
import { Form, Formik, FormikConfig, FormikErrors, FormikProps } from "formik";
import { Contact, ZohoPathway } from "types";
import { notifications, zohoServices } from "services";
import { getENText } from "helpers";
import {
  BP_SPECIFY_DETAILS_TRANSITION,
  BP_SPECIFY_DETAILS_CLOSE_DELAY,
} from "configs";
import { useUpdateBluePrint } from "hooks/useUpdateBluePrint";
import { FormikScrollToViewError } from "components/FormikScrollToViewError";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { FormFields, specialNotesMax } from "./FormFields";
import {
  FormValues,
  ZohoPathwayTestProfile,
  TestProfilesObrWithMappedLabs,
  AfterResultConsultationEnum,
} from "./types";
import {
  validateTestProfilesTest,
  isAddressChanged,
  isPathwayAddressAvailable,
  shouldShowShippingAddress,
  composeSpecifyDetailsBody,
  getAfterResultConsultationOption,
  cannotDisplaySpecifyDetailsRelatedList,
  mapLabsInTestProfiles,
  getTestProfilesFromPathwayTestProfiles,
  countryListIncludesCountry,
} from "./helpers";

const useStyles = makeStyles(({ spacing: s, palette: p }) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flex: 1,
      padding: s(3),
    },
    contentSuccess: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    successIcon: {
      fontSize: "100px",
      color: p.success.main,
    },
    rlError: {
      width: "auto",
      padding: s(2),
    },
    rlErrorBanner: {
      background: "#e8f5fc",
      display: "flex",
      alignItems: "center",
      padding: s(1),
      borderRadius: s(1),
    },
    rlErrorText: {
      marginLeft: s(1),
      fontSize: 15,
    },
    rlErrorIcon: {
      color: p.info.main,
    },
  })
);

interface Props {
  isBlueprint?: boolean;
  bloodDrawers: BloodDrawer[];
  labs: Lab[];
  testProfiles: TestProfilesObrWithMappedLabs[];
  client: Contact;
  pathwayTestProfileRecords: ZohoPathwayTestProfile[];
}

export const NonGuidedSpecifyingOfDetails: React.FC<Props> = ({
  isBlueprint,
  bloodDrawers,
  labs,
  testProfiles,
  client,
  pathwayTestProfileRecords,
}) => {
  const classes = useStyles();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];
  const pathways = useSelector(zohoSelectors.getRecords);
  const pathway = (pathways[pathwayZohoId] as unknown) as ZohoPathway;
  const { updateBluePrint } = useUpdateBluePrint();

  const [toProceed, setToProceed] = useState(false);

  const validationSchema = useMemo(
    () =>
      Yup.lazy((values: FormValues) => {
        const requireAddress = shouldShowShippingAddress(values.bloodDrawer);

        const validationObject: { [key: string]: any } = {
          testProfiles: validateTestProfilesTest(values),
          lab: Yup.object()
            .required(getENText("validation.lab.required"))
            .test("lab test", "", function test() {
              if (values.country && values.lab) {
                if (
                  (values.lab.allowedCountries?.length &&
                    !countryListIncludesCountry(
                      values.lab.allowedCountries,
                      values.country
                    )) ||
                  (values.lab.notAllowedCountries?.length &&
                    countryListIncludesCountry(
                      values.lab.notAllowedCountries,
                      values.country
                    ))
                ) {
                  // eslint-disable-next-line react/no-this-in-sfc
                  return this.createError({
                    message: `The client shipping country "${values.country}" is not allowed for this laboratory`,
                  });
                }
              }

              return true;
            })
            .nullable(),
          bloodDrawer: Yup.object()
            .required(getENText("validation.bloodDrawer.required"))
            .nullable(),
          bloodTakingOption: Yup.string().required(
            getENText("validation.bloodTakingOption.required")
          ),
          orderType:
            values.afterResultConsultation !== AfterResultConsultationEnum.NONE
              ? Yup.string().required(
                  getENText("validation.orderType.required")
                )
              : undefined,
          clientDob: Yup.date()
            .required(getENText("validation.dob.required"))
            .nullable(),
          clientGender: Yup.string().required(
            getENText("validation.gender.required")
          ),
          specialNotes: Yup.string().max(
            specialNotesMax,
            getENText("validation.specialNotes.max", {
              max: specialNotesMax.toString(),
            })
          ),
          billInvoiceNumber:
            values.billedStatus === BloodDrawBilledStatus.BILLED
              ? Yup.string().required("This is required")
              : undefined,
          ...(requireAddress && getShippingAddressFormValidationSchema()),
        };

        return (Yup.object(validationObject) as unknown) as Yup.Schema<
          FormValues,
          // eslint-disable-next-line @typescript-eslint/ban-types
          object
        >;
      }),
    []
  );

  const toBePopulatedAddressData = useMemo(() => {
    if (isPathwayAddressAvailable(pathway)) {
      return {
        street: pathway.Shipping_Street || "",
        city: pathway.Shipping_City || "",
        country: pathway.Shipping_Country || "",
        region: pathway.Shipping_Province || "",
        other: pathway.Shipping_Apartment_Flat_Door_Other || "",
        zip: pathway.Shipping_Post_Code || "",
      };
    }

    return {
      street: client?.shippingAddress?.street || "",
      city: client?.shippingAddress?.city || "",
      country: client?.shippingAddress.country || "",
      region: client?.shippingAddress.region || "",
      other: client?.shippingAddress.street2 || "",
      zip: client?.shippingAddress.zip || "",
    };
  }, [pathway, client?.shippingAddress]);

  const initialValues: FormValues = useMemo(
    () => ({
      testProfiles: mapLabsInTestProfiles(
        getTestProfilesFromPathwayTestProfiles(
          pathwayTestProfileRecords,
          testProfiles
        )
      ),
      lab:
        (labs &&
          labs.find((l) => l.zohoID === pathway.Diagnostic_Laboratory?.id)) ||
        null,
      bloodDrawer:
        (bloodDrawers &&
          bloodDrawers.find((b) => b.zohoID === pathway.Blood_Drawer?.id)) ||
        null,
      bloodTakingOption: pathway.Blood_Taking_Option || "",
      offerMcmCall: pathway.Offer_MCM_Call,
      doctorConsultationNeeded: pathway.Doctor_Consultation_Needed,
      orderType: pathway.Type_of_Order || "",
      clientDob:
        client && client.dateOfBirth
          ? new Date(client.dateOfBirth.replace(/-/g, "/"))
          : null,
      clientGender: (client && client.gender) || "",
      specialNotes: pathway.Special_Notes || "",
      updateAddressForClient: true,
      feeCollected: !!pathway.Blood_Draw_Fee_Collected,
      afterResultConsultation: getAfterResultConsultationOption(
        pathway.Offer_MCM_Call,
        pathway.Doctor_Consultation_Needed
      ),
      billedStatus: pathway.Billed_Status || BloodDrawBilledStatus.NOT_BILLED,
      billInvoiceNumber: pathway.Blood_Draw_Invoice_Number || "",
      ...toBePopulatedAddressData,
    }),
    [
      bloodDrawers,
      client,
      labs,
      pathwayTestProfileRecords,
      testProfiles,
      pathway.Blood_Drawer?.id,
      pathway.Blood_Taking_Option,
      pathway.Diagnostic_Laboratory?.id,
      pathway.Doctor_Consultation_Needed,
      pathway.Type_of_Order,
      pathway.Special_Notes,
      pathway.Blood_Draw_Fee_Collected,
      toBePopulatedAddressData,
      pathway.Offer_MCM_Call,
      pathway.Billed_Status,
      pathway.Blood_Draw_Invoice_Number,
    ]
  );

  const validateValues = useCallback(
    async (
      values: FormValues,
      setErrors: (errors: FormikErrors<FormValues>) => void
    ) => {
      try {
        setLoading(true);
        await pathwayRequests.validatePathwayDetails({
          zohoId: pathwayZohoId,
          ids: {
            testProfileIDs: values.testProfiles.map((t) => t.id),
            labID: values.lab!.id,
            bloodDrawerID: values.bloodDrawer!.id,
          },
        });
        setLoading(false);
        return true;
      } catch (error: any) {
        const message =
          error?.response?.data?.message || error?.message || "Unknown error";
        setLoading(false);
        switch (message) {
          case PathwayValidationErrorCodes.CODE_TEST_PROFILE_NOT_ASSOCIATED:
            return setErrors({
              ...(formRef.current?.errors || {}),
              testProfiles: getENText("validation.testProfiles.notAssociated"),
            });
          case PathwayValidationErrorCodes.CODE_ASSOCIATION_NOT_ACTIVE:
            return setErrors({
              ...(formRef.current?.errors || {}),
              testProfiles: getENText(
                "validation.testProfiles.associationNotctive"
              ),
            });
          case PathwayValidationErrorCodes.CODE_BLOOD_DRAWER_NOT_CORRESPONDING:
            return setErrors({
              ...(formRef.current?.errors || {}),
              bloodDrawer: getENText("validation.lab.notSameAsBloodDrawer"),
            });
          case PathwayValidationErrorCodes.CODE_LAB_NOT_EXISTS:
            return setErrors({
              ...(formRef.current?.errors || {}),
              lab: getENText("validation.lab.notExist"),
            });
          case PathwayValidationErrorCodes.CODE_BLOOD_DRAWER_NOT_EXISTS:
            return setErrors({
              ...(formRef.current?.errors || {}),
              bloodDrawer: getENText("validation.bloodDrawer.notExist"),
            });
          case PathwayValidationErrorCodes.CODE_TEST_PROFILE_NOT_EXISTS:
            return setErrors({
              ...(formRef.current?.errors || {}),
              testProfiles: getENText("validation.testProfiles.notExist"),
            });
          default:
            return notifications.notifyError(message);
        }
      }
    },
    [pathwayZohoId]
  );

  const validateInitialValues = useCallback(
    async (setErrors: (errors: FormikErrors<FormValues>) => void) => {
      const { lab, bloodDrawer, testProfiles: tPs } = initialValues;
      if (lab?.id && (bloodDrawer?.id || tPs?.length)) {
        await validateValues(initialValues, setErrors);
      }
    },
    [initialValues, validateValues]
  );

  const updateToNextBPState = useCallback(async () => {
    setLoading(true);
    let isUpdated = false;
    let times = 0;
    while (!isUpdated && times < 3) {
      // eslint-disable-next-line no-await-in-loop
      isUpdated = await updateBluePrint(BP_SPECIFY_DETAILS_TRANSITION);
      times += 1;
      // eslint-disable-next-line no-await-in-loop
      await new Promise((res) => setTimeout(res, 100));
    }
    setLoading(false);
  }, [updateBluePrint]);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      const validationRes = await validateValues(values, helpers.setErrors);

      if (validationRes !== true) {
        return validationRes;
      }

      try {
        setLoading(true);
        const body = composeSpecifyDetailsBody(
          values,
          client,
          pathway,
          pathwayTestProfileRecords
        );
        await pathwayRequests.specifyPathwayDetails(pathwayZohoId, body);

        await new Promise((resolve) =>
          setTimeout(resolve, BP_SPECIFY_DETAILS_CLOSE_DELAY || 0)
        );
        if (!isBlueprint && toProceed) {
          await updateToNextBPState();
        }
        setShowSuccess(true);
      } catch {
        //
      }

      return setLoading(false);
    },
    [
      client,
      pathwayTestProfileRecords,
      pathwayZohoId,
      pathway,
      validateValues,
      isBlueprint,
      toProceed,
      updateToNextBPState,
    ]
  );

  const onCancelClick = useCallback(async () => {
    if (!isBlueprint) {
      formRef.current?.resetForm();
      return;
    }
    await zohoServices.closePopup();
  }, [isBlueprint]);

  const closeWidgetOnSuccess = useCallback(async () => {
    if (toProceed) {
      await updateToNextBPState();
    }
    zohoServices.closePopup(true);
  }, [toProceed, updateToNextBPState]);

  const handleSaveClick = useCallback(() => {
    setToProceed(false);
    formRef.current?.submitForm();
  }, []);

  const handleSaveAndProceedClick = useCallback(() => {
    setToProceed(true);
    formRef.current?.submitForm();
  }, []);

  if (
    !isBlueprint &&
    !loading &&
    pathway &&
    cannotDisplaySpecifyDetailsRelatedList(pathway)
  ) {
    return (
      <WidgetLayout fullScreen>
        <WidgetContent>
          <div className={classes.rlError}>
            <div className={classes.rlErrorBanner}>
              <Info className={classes.rlErrorIcon} />
              <Typography className={classes.rlErrorText}>
                This widget is available only if the stage of the blood test
                order is Pending Contact with Client.
              </Typography>
            </div>
          </div>
        </WidgetContent>
      </WidgetLayout>
    );
  }

  return (
    <WidgetLayout>
      <Loader open={loading} isFixed />

      {testProfiles && labs && bloodDrawers && client && (
        <Formik<FormValues>
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => {
            const { values } = props;
            return (
              <Form className={classes.form} noValidate>
                <FormikScrollToViewError />
                {!showSuccess && (
                  <>
                    {isBlueprint && (
                      <WidgetTitle disableTypography>
                        <Typography component="h1" variant="h4">
                          Specify Details
                        </Typography>
                      </WidgetTitle>
                    )}
                    <WidgetContent className={classes.content}>
                      <FormFields
                        formProps={props}
                        testProfiles={testProfiles}
                        labs={labs}
                        bloodDrawers={bloodDrawers}
                        client={client}
                        isAddressChanged={isAddressChanged(
                          values,
                          pathway,
                          client
                        )}
                        pathway={pathway}
                        validateInitialValues={validateInitialValues}
                      />
                    </WidgetContent>
                    <WidgetActions>
                      <Button
                        variant="outlined"
                        onClick={onCancelClick}
                        disabled={loading}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={loading}
                        onClick={handleSaveClick}
                      >
                        Save
                      </Button>
                      <Button
                        color="primary"
                        disabled={loading}
                        onClick={handleSaveAndProceedClick}
                      >
                        Save and Proceed
                      </Button>
                    </WidgetActions>
                  </>
                )}

                {showSuccess && (
                  <>
                    <WidgetContent className={classes.contentSuccess}>
                      <SuccessIcon className={classes.successIcon} />
                      <Typography variant="h5" align="center">
                        Blood Test Order Details are successfully updated.
                        {!isBlueprint && (
                          <>
                            <br />
                            Please reload the page to see the latest updates.
                          </>
                        )}
                      </Typography>
                    </WidgetContent>
                    {isBlueprint && (
                      <WidgetActions>
                        <Button color="primary" onClick={closeWidgetOnSuccess}>
                          OK
                        </Button>
                      </WidgetActions>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </WidgetLayout>
  );
};
