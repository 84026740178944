import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MainItem, Scanner, SupplementaryItem } from "components/Dispensing";
import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { getAllItems, getShouldSendToBMH, getValueFromObject } from "helpers";
import { TitleValue } from "components/TitleValue";
import { Alert, AlertTitle } from "@material-ui/lab";
import { NumberInputField } from "components/Treatments";
import { DBPharmacyProduct } from "types";
import { ParentOrderItem, TreatmentOrder, TreatmentOrderItem } from "../types";
import { useStyles } from "./ConfirmDispensing";

interface HandleMissingItemsProps {
  mainItems: TreatmentOrderItem[];
  supplementaryItems: TreatmentOrderItem[];
  manufacturers: string[];
  treatmentOrder?: TreatmentOrder;
  replacements: DBPharmacyProduct[];
}

export const HandleMissingItems: React.FC<HandleMissingItemsProps> = ({
  mainItems,
  supplementaryItems,
  manufacturers,
  treatmentOrder,
  replacements,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const mainItemsValue = useMemo(
    () => getValueFromObject("mainItems", values),
    [values]
  );
  const supplementaryItemsValue = useMemo(
    () => getValueFromObject("supplementaryItems", values),
    [values]
  );

  const isNoItemHavingMissingQuantity = useMemo(
    () =>
      !getAllItems(mainItemsValue, supplementaryItemsValue).some((i: any) => {
        return i?.missingQuantity;
      }),
    [mainItemsValue, supplementaryItemsValue]
  );

  const doesAllPrescriptionMedsHaveMissingQtyEqualToQty = useMemo(
    () =>
      getShouldSendToBMH(
        mainItemsValue,
        supplementaryItemsValue,
        mainItems,
        supplementaryItems
      ),
    [mainItemsValue, supplementaryItemsValue, mainItems, supplementaryItems]
  );

  useEffect(() => {
    setFieldValue(
      "shouldSendToBMH",
      doesAllPrescriptionMedsHaveMissingQtyEqualToQty
    );
  }, [doesAllPrescriptionMedsHaveMissingQtyEqualToQty, setFieldValue]);

  return (
    <Box>
      {isNoItemHavingMissingQuantity && (
        <Box mb={2}>
          <Alert severity="error">
            <AlertTitle>Please specify missing items.</AlertTitle>
            At least one of the items needs to have missing quantities
            specified.
          </Alert>
        </Box>
      )}
      <Box mb={2}>
        <Scanner
          mainItems={mainItems}
          supplementaryItems={supplementaryItems}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="h5" color="primary">
          Order Items
        </Typography>
        {mainItems.map((orderItem, index) => (
          <Accordion
            defaultExpanded
            className={clsx(classes.wrapper, classes.mb2)}
            key={orderItem.id}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                justify="space-between"
                alignItems="center"
                container
              >
                <Grid item>
                  <Typography
                    className={classes.heading}
                    variant="h5"
                    color="primary"
                  >
                    {orderItem.productItem?.Medicine_Type?.name}
                  </Typography>
                  <Typography>
                    {orderItem.medicineItem?.treatmentArea?.Treatment_Area
                      ?.name ||
                      orderItem.medicineItem?.treatmentArea
                        ?.Other_Treatment_Area}
                  </Typography>
                </Grid>
                <Grid xs={3} item>
                  <Box ml="auto" maxWidth={180}>
                    <NumberInputField
                      label="Missing Quantity"
                      name={`mainItems.${index}.missingQuantity`}
                      required={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <MainItem
                  manufacturers={manufacturers}
                  item={orderItem as ParentOrderItem}
                  fieldName={`mainItems.${index}`}
                  treatmentOrder={treatmentOrder}
                  handleMissingItems
                  replacements={replacements}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        {!!supplementaryItems.length && (
          <Typography variant="h5" color="primary">
            Supplementary Products
          </Typography>
        )}
        {supplementaryItems.map((orderItem, index) => (
          <Accordion
            defaultExpanded
            className={clsx(classes.wrapper, classes.mb2)}
            key={orderItem.id}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                container
                spacing={3}
              >
                <Grid item xs={3}>
                  <TitleValue
                    title="Name"
                    value={orderItem.productItem?.Name_For_Prescription}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TitleValue title="Quantity" value={orderItem.Quantity} />
                </Grid>
                <Grid item xs={4}>
                  <TitleValue
                    title="Description"
                    value={orderItem.productItem?.Description}
                  />
                </Grid>
                <Grid xs={3} item>
                  <Box ml="auto" maxWidth={180}>
                    <NumberInputField
                      label="Missing Quantity"
                      name={`supplementaryItems.${index}.missingQuantity`}
                      required={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <SupplementaryItem
                  item={orderItem as ParentOrderItem}
                  fieldName={`supplementaryItems.${index}`}
                  manufacturers={manufacturers}
                  treatmentOrder={treatmentOrder}
                  handleMissingItems
                  replacements={replacements}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};
