/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
import { stringify } from "querystring";
import {
  GetLabsRes,
  GetTestProfilesObrRes,
  labsEndpoints,
  StatusEnum,
  testProfilesObrEndpoints,
  ROUTE_PW_VALIDATE,
  POSTPathwayValidateRes,
  POSTPathwayValidateBody,
  GetBloodDrawersRes,
  GETPathwaySendInstructionsScenarioRes,
  ROUTE_PATHWAY_SEND_INSTRUCTIONS_SCENARIO,
  GETPathwayRes,
  ROUTE_PW,
  PatchPathwaySpecifyDetails,
  PostConfirmInvoiceCreation,
  PostConfirmKitInvoiceCreation,
  GetCancelRetestArrangementData,
  PatchCancelPathwayRetestArrangements,
  PostGenerateBloodDrawInvoice,
  PostConfirmBloodDrawInvoiceCreation,
} from "@deep-consulting-solutions/bmh-constants";

import { apiClient, silentClient } from "apis";
import { SendGridStatusEnum, SendGridEmailStatus, Client } from "types";
import { isFailedSendGridEmail } from "helpers";
import { SEND_GRID_STATUS_CHECK_INTERVAL } from "configs";

export const fetchClientsFromZohoIDs = async (
  ids: string[]
): Promise<Client[]> => {
  const params = { ids: ids.join(",") };
  const res = await apiClient.get(`/pathway/client?${stringify(params)}`);
  return res.data.data;
};

export const fetchOrderFormPdf = async (ids: string[]) => {
  const params = { ids: ids.join(",") };
  const res = await apiClient.get(
    `/pathway/order-form/pdf?${stringify(params)}`
  );
  return res.data.data.url;
};

export const checkEmailStatuses = async ({
  emails,
  messageID,
}: {
  emails: string[];
  messageID: string;
}) => {
  const pending: { [email: string]: true } = {};
  emails.forEach((e) => {
    pending[e.toLowerCase()] = true;
  });

  const failures: { [email: string]: SendGridEmailStatus } = {};

  while (Object.keys(pending).length) {
    const res = await apiClient.get<{
      data: SendGridEmailStatus[];
    }>(`/email-events/${messageID}`);

    res.data.data.forEach((d) => {
      const isFailed = isFailedSendGridEmail(d.status);
      const isOK = d.status === SendGridStatusEnum.delivered;
      if (isFailed || isOK) {
        delete pending[d.email.toLowerCase()];
      }
      if (isFailed) {
        failures[d.email.toLowerCase()] = d;
      }
    });

    if (Object.keys(pending).length) {
      await new Promise((r) => setTimeout(r, SEND_GRID_STATUS_CHECK_INTERVAL));
    }
  }

  return Object.values(failures);
};

export const sendEmail = async ({
  zohoId,
  emails,
  text,
  isBloodDrawer,
}: {
  zohoId: string;
  emails: string[];
  text: string;
  isBloodDrawer: boolean;
}) => {
  const res = await apiClient.post<{ data: { messageID: string } }>(
    `/pathway/order-form/email`,
    {
      zohoID: zohoId,
      emails,
      additionalText: text,
    },
    {
      params: {
        type: isBloodDrawer ? "blood_drawer" : "client",
      },
    }
  );

  return checkEmailStatuses({
    emails,
    messageID: res.data.data.messageID,
  });
};

export const fetchTubeLabelsPdf = async (ids: string[]) => {
  const params = { ids: ids.join(",") };
  const res = await apiClient.get(
    `/pathway/tube-label/pdf?${stringify(params)}`
  );
  return res.data.data.url;
};

export const sendInstructionEmails = async ({
  emails,
  zohoId,
  text,
  attachBloodTestOrderForm,
}: {
  emails: string[];
  zohoId: string;
  text: string;
  attachBloodTestOrderForm?: boolean;
}) => {
  const res = await apiClient.post<{ data: { messageID: string } }>(
    `/pathway/instruction/email`,
    {
      zohoID: zohoId,
      emails,
      additionalText: text,
      attachBloodTestOrderForm,
    }
  );

  return checkEmailStatuses({
    emails,
    messageID: res.data.data.messageID,
  });
};

export const sendConfirmationEmails = async ({
  emails,
  zohoId,
  text,
  attachBloodTestOrderForm,
}: {
  emails: string[];
  zohoId: string;
  text: string;
  attachBloodTestOrderForm?: boolean;
}) => {
  const res = await apiClient.post<{ data: { messageID: string } }>(
    `/pathway/confirmation/email`,
    {
      zohoID: zohoId,
      emails,
      additionalText: text,
      attachBloodTestOrderForm,
    }
  );

  return checkEmailStatuses({
    emails,
    messageID: res.data.data.messageID,
  });
};

export const fetchPathwayRecord = async (zohoId: string) => {
  const res = await apiClient.get<GETPathwayRes>(
    ROUTE_PW.replace(":zohoID", zohoId)
  );
  return res.data.data;
};

export const fetchPathwaySendInstructionsScenario = async (zohoId: string) => {
  const res = await apiClient.get<GETPathwaySendInstructionsScenarioRes>(
    ROUTE_PATHWAY_SEND_INSTRUCTIONS_SCENARIO.replace(":zohoID", zohoId)
  );
  return res.data.data;
};

export const getLabs = async () => {
  const res = await apiClient.get<GetLabsRes>(labsEndpoints.labs);
  return res.data.data;
};

export const getTestProfiles = async () => {
  const res = await apiClient.get<GetTestProfilesObrRes>(
    testProfilesObrEndpoints.getOrPost
  );
  return res.data.data;
};

export const getBloodDrawers = async () => {
  const res = await apiClient.get<GetBloodDrawersRes>("/blood-drawers");
  return res.data.data;
};

export const validatePathwayDetails = async ({
  zohoId,
  ids,
}: {
  zohoId: string;
  ids: POSTPathwayValidateBody;
}) => {
  const res = await silentClient.post<POSTPathwayValidateRes>(
    ROUTE_PW_VALIDATE.replace(":zohoID", zohoId),
    ids
  );

  return res.data.data.isValid as true;
};

export const specifyPathwayDetails = async (
  zohoId: string,
  body: typeof PatchPathwaySpecifyDetails["Body"]
) => {
  await apiClient.patch(
    PatchPathwaySpecifyDetails.ROUTE.replace(":zohoId", zohoId),
    body
  );
};

export const confirmInvoiceCreation = async (
  zohoID: string,
  invoiceNumber: string
) => {
  const res = await apiClient.post<typeof PostConfirmInvoiceCreation.Res>(
    PostConfirmInvoiceCreation.ROUTE.replace(":zohoID", zohoID),
    { invoiceNumber }
  );

  return res.data.data;
};

export const createConfirmKitPackingInvoice = async (
  zohoID: string,
  body: typeof PostConfirmKitInvoiceCreation.Body
) => {
  const res = await apiClient.post<typeof PostConfirmKitInvoiceCreation.Res>(
    PostConfirmKitInvoiceCreation.ROUTE.replace(":zohoID", zohoID),
    body
  );

  return res.data.data;
};

export const fetchCancelRetestArrangementData = async (zohoID: string) => {
  const res = await apiClient.get<typeof GetCancelRetestArrangementData["Res"]>(
    GetCancelRetestArrangementData.ROUTE.replace(":zohoID", zohoID)
  );
  return res.data.data;
};

export const cancelRetestArrangementReq = async (
  zohoID: string,
  reason: string
) => {
  const body: typeof PatchCancelPathwayRetestArrangements["Body"] = {
    cancellationReason: reason,
  };
  await apiClient.patch<typeof PatchCancelPathwayRetestArrangements["Res"]>(
    PatchCancelPathwayRetestArrangements.ROUTE.replace(":zohoID", zohoID),
    body
  );
};

export const postGenerateBloodDrawerInvoice = async (zohoID: string) => {
  const res = await silentClient.post<
    typeof PostGenerateBloodDrawInvoice["Res"]
  >(PostGenerateBloodDrawInvoice.ROUTE.replace(":zohoID", zohoID));
  return res.data.data;
};

export const postConfirmBloodDrawInvoiceCreation = async (
  zohoID: string,
  invoiceNumber: string
) => {
  const res = await apiClient.post<
    typeof PostConfirmBloodDrawInvoiceCreation.Res
  >(PostConfirmBloodDrawInvoiceCreation.ROUTE.replace(":zohoID", zohoID), {
    invoiceNumber,
  });

  return res.data.data;
};
