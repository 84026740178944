export const validStates = ["Not Received by Client", "Returned", "Disposed"];

export const radioOptions = [
  {
    value: "full-payment",
    label:
      "Full Payment (Client will be invoiced the same amount as for the original order)",
  },
  {
    value: "custom-payment",
    label:
      "Custom Price (Specify the amount client needs to pay to have the original order replaced)",
  },
  {
    value: "no-payment",
    label: "No Payment (Client will receive the replacement order for free)",
  },
];

export const helpText =
  "Does the client need to pay for the replacement order?";
