import { GenderEnum } from "@deep-consulting-solutions/bmh-constants";
import { CreateTreatment } from "components/Treatments";
import { Formik, FormikConfig, FormikErrors, FormikHelpers } from "formik";
import {
  clearSavedDefaultValues,
  getCreateTreatmentPayload,
  getDiagnosisDefaultValue,
  getDoctorName,
  getMissingRequiredFields,
  getSavedDefaultValues,
  getTreatmentValidationSchema,
  isStringEqual,
  validateTreatmentForm,
} from "helpers";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { notifications, treatmentServices, zohoServices } from "services";
import {
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoDiagnosisOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoTreatment,
  ZohoTreatmentArea,
} from "types";
import { AxiosError } from "axios";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { Box } from "@material-ui/core";
import { BlockWidgetAlert } from "./BlockWidgetAlert";

const dummyData = {
  doctor: null,
  diagnosis: [],
  treatmentTreatmentAreas: [],
  instructions: "",
  treatmentPeriod: null,
};

export const CreateTreatmentPlan = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [hasInProgressTreatment, setHasInProgressTreatment] = useState(false);
  const [
    inProgressTreatment,
    setInProgressTreatment,
  ] = useState<ZohoTreatment | null>(null);
  const [diagnosisOptions, setDiagnosisOptions] = useState<
    ZohoDiagnosisOption[]
  >([]);
  const [fetchError, setFetchError] = useState("");
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [id] = useSelector(zohoSelectors.getIds);
  const [doctors, setDoctors] = useState<ZohoBMHDoctor[]>([]);
  const [
    createdTreatment,
    setCreatedTreatment,
  ] = useState<ZohoTreatment | null>(null);

  const [treatmentAreas, setTreatmentAreas] = useState<ZohoTreatmentArea[]>([]);
  const [dosingAdminRoutes, setDosingAdminRoutes] = useState<
    ZohoDosingAdminRoute[]
  >([]);
  const [medicineAdminRoutes, setMedicineAdminRoute] = useState<
    ZohoMedicineAdminRoute[]
  >([]);
  const [dosingTypes, setDosingTypes] = useState<ZohoDosingType[]>([]);

  const [doctor, setDoctor] = useState<ZohoBMHDoctor | null>(null);
  const [client, setClient] = useState<ZohoClientRecord | null>(null);
  const [fetchedAllData, setIsFetchedAllData] = useState(false);
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);

  const initialValues = useMemo(() => {
    if (!fetchedAllData) {
      return dummyData;
    }
    const savedDefaultValue = getSavedDefaultValues("create-treatment", id);
    if (savedDefaultValue) {
      setImmediate(() => {
        setSavedDefaultValues({
          ...savedDefaultValue,
          doctor: doctor?.id
            ? { title: getDoctorName(doctor), value: doctor.id }
            : savedDefaultValue.doctor,
        });
      });
    }

    return {
      ...dummyData,
      doctor: doctor?.id
        ? { title: getDoctorName(doctor), value: doctor.id }
        : null,
      diagnosis: getDiagnosisDefaultValue(
        [],
        diagnosisOptions,
        client?.Gender || ("" as GenderEnum)
      ),
    };
  }, [client?.Gender, id, fetchedAllData, doctor, diagnosisOptions]);

  const validationSchema = useMemo(() => {
    return getTreatmentValidationSchema();
  }, []);

  const missingRequiredFields = useMemo(() => {
    return getMissingRequiredFields(client);
  }, [client]);

  const hasMissingRequiredFields = useMemo(
    () => !!missingRequiredFields.length,
    [missingRequiredFields.length]
  );

  const view = useMemo(() => {
    if (createdTreatment) {
      return "success";
    }
    if (fetchError) {
      return "fetch-error";
    }

    if (
      (hasInProgressTreatment && inProgressTreatment) ||
      hasMissingRequiredFields
    ) {
      return "initial-block";
    }
    return "form";
  }, [
    fetchError,
    hasInProgressTreatment,
    inProgressTreatment,
    createdTreatment,
    hasMissingRequiredFields,
  ]);

  const otherMedicineTypeId = useMemo(() => {
    const otherTreatmentArea = treatmentAreas.find((t) =>
      isStringEqual(t.Name, "Other")
    );
    if (!otherTreatmentArea) {
      return "";
    }

    return (
      otherTreatmentArea.medicineType.find((m) =>
        isStringEqual(m.Name, "Other")
      )?.id || ""
    );
  }, [treatmentAreas]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [treatmentRes, currentUserRes] = await Promise.all([
        treatmentServices.fetchCreateTreatmentWidgetDetails(id),
        dispatch(zohoActions.fetchCurrentUser()),
      ]);
      if (treatmentRes.hasInProgressTreatment) {
        setHasInProgressTreatment(true);
        setInProgressTreatment(treatmentRes.inProgressTreatments[0]);
      } else {
        setClient(treatmentRes.client);
        const [
          { doctors: doctorsRes },
          { treatmentAreas: treatmentAreasRes },
          { dosingAdminRoutes: dosingAdminRoutesRes },
          { dosingTypes: dosingTypesRes },
          { medicineAdminRoutes: medicineAdminRoutesRes },
          doctorRes,
          { fields },
        ] = await Promise.all([
          treatmentServices.fetchDoctors(),
          treatmentServices.fetchTreatmentAreas(),
          treatmentServices.fetchDosingAdminRoutes(),
          treatmentServices.fetchDosingTypes(),
          treatmentServices.fetchMedicineAdminRoutes(),
          treatmentServices.fetchUserDoctorProfile(
            (currentUserRes as any)?.payload?.id
          ),
          zohoServices.getFields("Treatments"),
        ]);
        const diagnosisField = fields.find(
          (f: any) => f.api_name === "Diagnosis"
        );

        setDiagnosisOptions(diagnosisField.pick_list_values);
        setDoctors(doctorsRes);
        setTreatmentAreas(treatmentAreasRes);
        setDosingAdminRoutes(dosingAdminRoutesRes);
        setDosingTypes(dosingTypesRes);
        setMedicineAdminRoute(medicineAdminRoutesRes);
        const userDoctor = (doctorsRes as ZohoBMHDoctor[]).find(
          (d) => d.id === doctorRes.doctorId
        );
        if (
          userDoctor &&
          isStringEqual(doctorRes.user.role.name, "BMH Doctors")
        ) {
          setDoctor(userDoctor);
        }
      }
      setIsFetchedAllData(true);
    } catch (error) {
      const err = error as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  const submitDetails = useCallback(
    async (
      values: any,
      formErrors: FormikErrors<any>,
      setFieldTouched: FormikHelpers<any>["setFieldTouched"],
      finalize?: boolean,
      draft?: boolean
    ) => {
      try {
        setLoading(true);
        if (!draft) {
          const isOk = validateTreatmentForm(formErrors, setFieldTouched);
          if (!isOk) {
            return;
          }
        }
        const payload = getCreateTreatmentPayload(
          values,
          client!,
          user!,
          doctor,
          treatmentAreas,
          medicineAdminRoutes,
          otherMedicineTypeId,
          finalize,
          draft
        );
        const res = await treatmentServices.createTreatment(payload);
        setCreatedTreatment(res);
        clearSavedDefaultValues("create-treatment", id);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [
      client,
      user,
      doctor,
      treatmentAreas,
      medicineAdminRoutes,
      id,
      otherMedicineTypeId,
    ]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting, validateForm, setFieldTouched }) => {
      const errors = await validateForm();
      await submitDetails(values, errors, setFieldTouched);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <Box>
        <DefaultValuesStore widgetName="create-treatment" identifier={id} />
        {savedDefaultValues && fetchedAllData ? (
          <SetInitialValues savedDefaultValues={savedDefaultValues} />
        ) : null}
        <CreateTreatment
          isCustomButton
          loading={loading}
          submitDetails={submitDetails}
          blockWidgetAlert={
            <BlockWidgetAlert
              inProgressTreatment={inProgressTreatment}
              missingRequiredFields={missingRequiredFields}
            />
          }
          cancelDialog={{
            title: "Cancel Treatment Plan",
            text:
              "All the data you entered will be discarded and no Treatment Plan will be created at this time. Do you want to continue Treatment Plan creation, or do you want to cancel and discard all the data you entered?",
            proceedButtonText: "CANCEL AND DISCARD ALL DATA",
            cancelButtonText: "CONTINUE TREATMENT PLAN CREATION",
          }}
          treatmentAreas={treatmentAreas}
          dosingTypes={dosingTypes}
          dosingAdminRoutes={dosingAdminRoutes}
          medicineAdminRoutes={medicineAdminRoutes}
          doctor={doctor}
          doctors={doctors}
          createdTreatment={createdTreatment}
          fetchError={fetchError}
          client={client}
          view={view}
          title="Create Treatment Plan"
          submitButtonText="CREATE TREATMENT PLAN"
          otherMedicineTypeId={otherMedicineTypeId}
          diagnosisOptions={diagnosisOptions}
        />
      </Box>
    </Formik>
  );
};
