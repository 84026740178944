export enum CancelStageEnum {
  PENDING_RETEST_CHARGES_APPROVAL = "Pending Retest Charges Approval",
  PENDING_ITEMS_CREATION = "Pending Items Creation",
  PENDING_RETEST_KIT_INVOICE_CREATION = "Pending Retest Kit Invoice Creation",
  PENDING_RETEST_INVOICE_CREATION = "Pending Retest Invoice Creation",
  RETEST_ARRANGEMENT_MADE = "Retest Arrangements Made",
}

export const isStageValid = (state: string) => {
  const values = Object.values(CancelStageEnum) as string[];
  return values.includes(state);
};
