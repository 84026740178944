import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles(() => ({
  link: {
    textAlign: "left",
    textDecoration: "underline",
    color: "#338cf0",
    padding: 0,
  },
}));

interface Props {
  invoiceLink?: string;
}

export const Success = ({ invoiceLink }: Props) => {
  const classes = useStyle();

  return (
    <Box>
      <Typography>
        An invoice was created for the blood draw kit.
        <br />
        {!!invoiceLink && (
          <a
            type="link"
            href={invoiceLink}
            className={classes.link}
            target="_blank"
            rel="noreferrer"
          >
            Link to the Invoice.
          </a>
        )}
      </Typography>
    </Box>
  );
};
