/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback } from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon, Cached as UpdatedIcon } from "@material-ui/icons";

import { formatDateToDisplay } from "helpers";

import { UnmatchedHL7Field } from "../Unmatched.types";
import { ROW_HEIGHT, getBorder, getBGHover } from "../Unmatched.styles";
import ManualEditForm from "./ManualEditForm";

const formatDate = (d: any) => {
  return d ? formatDateToDisplay(d) : "";
};

interface StyleProps {
  isUnmatched?: boolean;
  disabled?: boolean;
  isUpdated: boolean;
  isEditOpen: boolean;
}

const useStyle = makeStyles(
  ({ palette: p, spacing: s, breakpoints: b, typography: typo }) => ({
    wrapper: ({ isUnmatched, isEditOpen }: StyleProps) => ({
      display: "flex",
      alignItems: "center",
      height: isEditOpen ? "90px" : `${ROW_HEIGHT}px`,
      borderLeft: isUnmatched ? `${s(1)}px solid ${p.error.main}` : undefined,
      paddingLeft: s(isUnmatched ? 1 : 2),
      borderTop: getBorder(p),
      "&:last-child": {
        borderBottom: getBorder(p),
      },
      "&:hover": {
        background: getBGHover(p),
        cursor: "pointer",
      },
      transition: "0.2s",
    }),
    name: ({ disabled }: StyleProps) => ({
      width: "300px",
      color: disabled ? p.grey[400] : undefined,
      display: "flex",
      alignItems: "center",

      [b.only("md")]: {
        width: "200px",
      },

      [b.down("sm")]: {
        width: "150px",
      },
    }),
    value: {},
    editButton: {
      marginLeft: "auto",
      marginRight: s(1),
    },
    updatedIcon: {
      ...typo.body2,
      color: p.primary.light,
      marginLeft: s(0.5),
    },
  })
);

interface UnmatchedFieldRowProps {
  isUnmatched?: boolean;
  name: string;
  disabled?: boolean;
  disableUpdate: boolean;
  field: UnmatchedHL7Field;
  value: any;
  originalValue: any;
  isEditOpen: boolean;
  onActiveEditOpen: (field: UnmatchedHL7Field) => any;
  onActiveEditCancel: () => any;
  onActiveEditOK: (args: { field: UnmatchedHL7Field; value: string }) => any;
}

const FileFieldRow: React.FC<UnmatchedFieldRowProps> = ({
  name,
  disabled,
  disableUpdate,
  isUnmatched,
  value,
  originalValue,
  field,
  isEditOpen,
  onActiveEditCancel,
  onActiveEditOK,
  onActiveEditOpen,
}) => {
  const openEdit = useCallback(() => {
    onActiveEditOpen(field);
  }, [onActiveEditOpen, field]);

  const changeValueManually = useCallback(
    (nextValue: string) => {
      onActiveEditOK({
        field,
        value: nextValue,
      });
    },
    [onActiveEditOK, field]
  );

  const isUpdated = value !== originalValue;

  let display = value;
  let originalDisplay = originalValue;
  const titles: string[] = [];

  if (field === "sampleCollectedOn" || field === "dob") {
    display = formatDate(display);
    originalDisplay = formatDate(originalDisplay);
  }
  if (isUpdated && !isEditOpen) {
    titles.push(`Original value is "${originalDisplay}"`);
  }

  const classes = useStyle({
    disabled,
    isUnmatched,
    isUpdated,
    isEditOpen,
  });

  return (
    <Tooltip
      title={
        titles.length
          ? titles.map((title, index) => <div key={index}>{title}</div>)
          : ""
      }
    >
      <Box className={classes.wrapper}>
        <Box className={classes.name}>
          <Typography variant="h6">{name}</Typography>

          {isUpdated && <UpdatedIcon className={classes.updatedIcon} />}
        </Box>

        {!isEditOpen && (
          <>
            <Typography variant="body1" className={classes.value}>
              {display}
            </Typography>

            {!disableUpdate && !disabled && (
              <IconButton
                className={classes.editButton}
                onClick={openEdit}
                size="small"
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}

        {isEditOpen && (
          <ManualEditForm
            field={field}
            value={value}
            onCancel={onActiveEditCancel}
            onOK={changeValueManually}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default memo(FileFieldRow);
