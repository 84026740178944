import { Box, Grid, Typography, colors, makeStyles } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import {
  QuantityAndPricingFields,
  SelfSupplyField,
} from "components/Treatments";
import { ProductFields } from "components/Treatments/TreatmentMedicinesAndProducts";
import {
  getFieldName,
  getShowBacteriostatic,
  getShowInjectionBundle,
  mapZohoCrmProductsToDbProducts,
} from "helpers";
import React, { useMemo } from "react";
import { ZohoMatchingPackage, ZohoMedUsageUnit, ZohoProduct } from "types";

const useStyles = makeStyles(({ spacing, palette: p }) => ({
  cheapest: {
    color: colors.green["500"],
    fontWeight: "bolder",
  },
  productWrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  product: ZohoMatchingPackage;
  name?: string;
  productUrl: string;
  productName: string;
  bacteriostaticProducts: ZohoProduct[];
  unitPrice: number;
  billingCycle: string;
  injectionBundles: ZohoProduct[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
}

export const MatchingProduct = ({
  product,
  name = "",
  productUrl,
  bacteriostaticProducts,
  productName,
  unitPrice,
  injectionBundles,
  billingCycle: b,
  medUsageUnits,
}: Props) => {
  const classes = useStyles();

  const billingCycle = useMemo(() => {
    if (b.includes(" 1 ")) {
      if (b.includes("Month")) {
        return "Month";
      }
      if (b.includes("Week")) {
        return "Week";
      }
      return "Day";
    }

    return b.replace("Every", "");
  }, [b]);

  const injectionBundlesProducts = useMemo(() => {
    return mapZohoCrmProductsToDbProducts(
      (injectionBundles || []).filter(
        (p) => p.Pharmacy.id === product.product.Pharmacy.id
      ),
      medUsageUnits
    );
  }, [injectionBundles, product.product.Pharmacy.id, medUsageUnits]);

  const bacteriostaticProductsOptions = useMemo(() => {
    return mapZohoCrmProductsToDbProducts(
      (bacteriostaticProducts || []).filter(
        (p) => p.Pharmacy.id === product.product.Pharmacy.id
      ),
      medUsageUnits
    );
  }, [bacteriostaticProducts, product.product.Pharmacy.id, medUsageUnits]);

  const showInjectionBundle = useMemo(() => {
    return getShowInjectionBundle(
      product.treatmentMedicine.Dosing_Type?.name,
      product.packageMedicine?.Treatment_Area?.name
    );
  }, [
    product.treatmentMedicine.Dosing_Type?.name,
    product.packageMedicine?.Treatment_Area?.name,
  ]);

  const showBact = useMemo(() => {
    return getShowBacteriostatic(
      product.treatmentMedicine.Dosing_Type?.name,
      product.packageMedicine?.Treatment_Area?.name
    );
  }, [
    product.packageMedicine?.Treatment_Area?.name,
    product.treatmentMedicine.Dosing_Type?.name,
  ]);

  const selectedPharmacies = useMemo(() => {
    const pharmacy = product.product.Pharmacy;
    return [
      {
        title: pharmacy.name,
        value: pharmacy.id,
      },
    ];
  }, [product.product.Pharmacy]);

  const productQuantity = useMemo(() => {
    return `${product.Quantity} X ${
      product.product.Usage_Units_Per_Sellable_Unit || ""
    } ${product.product.Med_Usage_Unit?.name}`;
  }, [
    product.Quantity,
    product.product.Usage_Units_Per_Sellable_Unit,
    product.product.Med_Usage_Unit?.name,
  ]);

  return (
    <Box className={classes.productWrapper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <TitleValue
            title="Product:"
            value={
              <span>
                <a href={productUrl} target="_blank" rel="noreferrer">
                  {productName}
                </a>
              </span>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TitleValue title="Quantity:" value={productQuantity} />
        </Grid>
        <QuantityAndPricingFields
          name={name}
          unitPrice={unitPrice}
          quantity={product.Quantity}
        />

        <Grid item xs={6}>
          <TitleValue title="Billing Cycle:" value={`Every ${billingCycle}`} />
        </Grid>
        <Grid item xs={3}>
          <TitleValue title="Pharmacy:" value={product.product.Pharmacy.name} />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SelfSupplyField name={name} />
        </Grid>
      </Grid>
      {showInjectionBundle ? (
        <Box mt={4} className={classes.productWrapper}>
          <Box mb={2}>
            <Typography variant="subtitle2">Injection Bundle</Typography>
          </Box>
          <ProductFields
            name={getFieldName("injectionBundle", name)}
            products={injectionBundlesProducts || []}
            dosingType={product.treatmentMedicine.Dosing_Type.name}
            isInjectionBundle
            initialSelectedPharmacies={selectedPharmacies}
            selectedPharmacies={selectedPharmacies}
          />
        </Box>
      ) : null}
      {showBact ? (
        <Box mt={2} className={classes.productWrapper}>
          <Box mb={2}>
            <Typography variant="subtitle2">Bacteriostatic Water</Typography>
          </Box>
          <ProductFields
            name={getFieldName("bacteriostatic", name)}
            products={bacteriostaticProductsOptions}
            dosingType={product.treatmentMedicine.Dosing_Type.name}
            isBacteriostatic
            initialSelectedPharmacies={selectedPharmacies}
            selectedPharmacies={selectedPharmacies}
          />
        </Box>
      ) : null}
    </Box>
  );
};
