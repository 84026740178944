import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { zohoServices, notifications } from "services";
import { Field, Formik, FormikConfig } from "formik";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  disposalMedication,
  getTreatmentOrderItems,
} from "services/treatments/medication-disposal";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { InitialValues } from "./types";
import { MedicationDisposalTable } from "./MedicationDisposalTable";
import { ActionButtons } from "./ActionButtons";

export function MedicationDisposal() {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [rowCount, setRowCount] = useState(0);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          dateOfDisposal: moment(values.disposalDate).format("YYYY-MM-DD"),
          treatmentOrderId,
          treatmentOrdersItems: values.treatmentOrderItems.map(
            ({ id, methodOfDisposing }) => ({
              id,
              methodOfDisposing,
            })
          ),
        };
        await disposalMedication(payload);
        closeWidget(true);
      } catch (error) {
        notifications.notifyError(
          "Something went wrong and the action could not be completed."
        );
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentOrderId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const treatmentOrderItems = await getTreatmentOrderItems(
        treatmentOrderId
      );
      setInitialValues({
        disposalDate: moment().startOf("day").format("YYYY-MM-DD"),
        treatmentOrderItems,
      });
      setRowCount(treatmentOrderItems.length);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle title="Confirm Medication Disposal" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!loading && initialValues && (
                <>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "4px" }}
                  >
                    Disposal Date *
                  </Typography>
                  <Box width="400px">
                    <Field
                      component={FormikDatePicker}
                      name="disposalDate"
                      required
                      disablePast={false}
                      placeholder="DD/MM/YYYY"
                      size="small"
                      fullWidth
                    />
                  </Box>
                  <MedicationDisposalTable rowCount={rowCount} />
                </>
              )}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
}
