import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import {
  createStyles,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useMemo, useCallback } from "react";
import {
  CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW,
  DO_NOT_USE_MOBILE_DRAWER_VALUE,
} from "../../constants";
import { getSortedLabBloodDrawers } from "../../helpers";
import { FormValues } from "../../types";
import { OfferingLabRowGroup } from "./OfferingLabRowGroup";

const useStyles = makeStyles(() =>
  createStyles({
    radio: {},
  })
);

interface Props {
  bloodDrawers: BloodDrawer[];
}

export const OfferingLabBloodDrawerOptions = ({ bloodDrawers }: Props) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const isNoUseMobileDrawersSelected = useMemo(() => {
    return values.labBloodDrawer === DO_NOT_USE_MOBILE_DRAWER_VALUE;
  }, [values.labBloodDrawer]);

  const onNoUseMobileDrawersSelect = useCallback(() => {
    setFieldValue("labBloodDrawer", DO_NOT_USE_MOBILE_DRAWER_VALUE);
  }, [setFieldValue]);

  const isClientWantsSourcedBloodDrawer = useMemo(() => {
    return values.labBloodDrawer === CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW;
  }, [values.labBloodDrawer]);

  const onClientWantsSourcedBloodDrawerSelect = useCallback(() => {
    setFieldValue("labBloodDrawer", CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW);
  }, [setFieldValue]);

  const sortedBloodDrawers = useMemo(
    () => getSortedLabBloodDrawers(bloodDrawers),
    [bloodDrawers]
  );

  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>

            <TableCell>
              <Typography>Blood Drawer</Typography>
            </TableCell>
            <TableCell>
              <Typography>Price</Typography>
            </TableCell>
            <TableCell>
              <Typography>Prepaid by BMH</Typography>
            </TableCell>
            <TableCell>
              <Typography>Provides Own Kits</Typography>
            </TableCell>
            <TableCell>
              <Typography>Takes Sample to the Lab</Typography>
            </TableCell>
            <TableCell>
              <Typography>Special Instructions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedBloodDrawers.map((bloodDrawer) => (
            <OfferingLabRowGroup
              key={bloodDrawer.id}
              bloodDrawer={bloodDrawer}
            />
          ))}
          <TableRow>
            <TableCell>
              <Radio
                className={classes.radio}
                color="primary"
                size="small"
                checked={isNoUseMobileDrawersSelected}
                onClick={onNoUseMobileDrawersSelect}
              />
            </TableCell>
            <TableCell colSpan={6}>
              <Typography>
                Client doesn&apos;t want to use any of the laboratory blood
                drawers
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Radio
                className={classes.radio}
                color="primary"
                size="small"
                checked={isClientWantsSourcedBloodDrawer}
                onClick={onClientWantsSourcedBloodDrawerSelect}
              />
            </TableCell>
            <TableCell colSpan={6}>
              <Typography>Client wants patient sourced blood draw</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
