import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { getFieldName } from "helpers";

export const useStyles = makeStyles(({ palette: p }) => {
  return {
    checkbox: {
      color: p.primary.main,
    },
  };
});

interface Props {
  name?: string;
}

export const ChangeFirstInvoiceDateAfterSigningField = ({
  name = "",
}: Props) => {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <Field
        name={getFieldName("changeFirstInvoiceDateAfterSigning", name)}
        component={CheckboxWithLabel}
        type="checkbox"
        className={classes.checkbox}
        Label={{
          label: (
            <Typography>
              First Invoice should be Charged After Documents signing:
            </Typography>
          ),
        }}
        color="primary"
      />
    </Box>
  );
};
