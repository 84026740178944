import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import {
  AutoCompleteOption,
  SupplementaryProductValues,
  ZohoMedUsageUnit,
  ZohoProduct,
  ZohoSelectedProduct,
} from "types";
import {
  getFieldName,
  getValueFromObject,
  mapZohoCrmProductsToDbProducts,
} from "helpers";
import { useFormikContext } from "formik";

import { Alert } from "@material-ui/lab";
import { ProductFields } from "../TreatmentMedicinesAndProducts";
import { DeleteDialog } from "./DeleteDialog";
import { DeliveredProductDetails } from "../DeliveredProductDetails";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  fieldsSection: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  supplementaryProducts: ZohoProduct[];
  selectedPharmacies: AutoCompleteOption[];
  name?: string;
  initialSelectedPharmacies: AutoCompleteOption[];
  showAlertInfo?: boolean;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  allowChangeBillingCycleUnit?: boolean;
  selectedSupplementaryProducts?: ZohoSelectedProduct[];
}

export const SupplementaryProducts = ({
  supplementaryProducts,
  selectedPharmacies,
  name = "",
  initialSelectedPharmacies,
  showAlertInfo = false,
  medUsageUnits,
  allowChangeBillingCycleUnit,
  selectedSupplementaryProducts = [],
}: Props) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const [showDeleteDialog, setShowDeleteDialog] = useState<number | null>(null);

  const filteredProducts = useMemo(() => {
    return mapZohoCrmProductsToDbProducts(
      supplementaryProducts?.filter((p) => {
        return selectedPharmacies.some((s) => s.value === p.Pharmacy.id);
      }),
      medUsageUnits
    );
  }, [selectedPharmacies, supplementaryProducts, medUsageUnits]);

  const fieldName = useMemo(() => getFieldName("supplementaryProducts", name), [
    name,
  ]);

  const supplementaryProductsValue: SupplementaryProductValues[] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const addSupplementaryProduct = useCallback(() => {
    const newItem: SupplementaryProductValues = {
      product: null,
      customUnitPrice: null,
      onlyOnce: false,
      selfSupply: false,
      period: null,
      billingCycleUnit: {
        value: "Months",
        title: "Every X Months",
      },
      quantity: null,
      keyId: Math.random().toString(),
    };

    const newValue = supplementaryProductsValue.concat(newItem);
    setFieldValue(fieldName, newValue);
  }, [supplementaryProductsValue, fieldName, setFieldValue]);

  const openDeleteDialog = useCallback(
    (productIndex: number) => () => {
      setShowDeleteDialog(productIndex);
    },
    []
  );

  const closeDeleteDialog = useCallback(() => {
    setShowDeleteDialog(null);
  }, []);

  const deleteSupplementaryProduct = useCallback(() => {
    const newValue = supplementaryProductsValue.filter(
      (_, i) => i !== showDeleteDialog
    );
    setFieldValue(fieldName, newValue);
    closeDeleteDialog();
  }, [
    supplementaryProductsValue,
    fieldName,
    setFieldValue,
    showDeleteDialog,
    closeDeleteDialog,
  ]);

  const getSelectedSupplementaryProducts = useCallback(
    (id: string) => {
      const selectedSupplementaryProductsById = selectedSupplementaryProducts.reduce<
        Record<string, ZohoSelectedProduct>
      >((total, current) => {
        return { ...total, [current.id]: current };
      }, {});

      return selectedSupplementaryProductsById[id];
    },
    [selectedSupplementaryProducts]
  );

  return (
    <Box mb={2} className={classes.wrapper}>
      <DeleteDialog
        open={showDeleteDialog !== null}
        onDelete={deleteSupplementaryProduct}
        onClose={closeDeleteDialog}
      />
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography color="primary">Supplementary Products</Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={addSupplementaryProduct}
            variant="outlined"
            color="primary"
          >
            Add Supplementary Products
          </Button>
        </Grid>
      </Grid>

      {showAlertInfo ? (
        <Box mt={2} maxWidth={700}>
          <Alert severity="info">
            Injection bundles and when applicable, Bacteriostatic water are
            parts of the packages. You can add more items here, such as a sharps
            bin
          </Alert>
        </Box>
      ) : null}
      <Box>
        {supplementaryProductsValue.map((supplementaryProductValue, index) => (
          <Box
            className={classes.fieldsSection}
            mt={2}
            key={
              supplementaryProductValue?.id || supplementaryProductValue?.keyId
            }
          >
            {supplementaryProductValue?.id &&
            getSelectedSupplementaryProducts(supplementaryProductValue.id)
              ?.delivered ? (
              <DeliveredProductDetails
                selectedProduct={getSelectedSupplementaryProducts(
                  supplementaryProductValue.id
                )}
                medUsageUnits={medUsageUnits}
              />
            ) : (
              <ProductFields
                name={`${fieldName}.${index}`}
                products={filteredProducts}
                isSupplementary
                deleteProduct={openDeleteDialog(index)}
                initialSelectedPharmacies={initialSelectedPharmacies}
                selectedPharmacies={selectedPharmacies}
                allowChangeBillingCycleUnit={allowChangeBillingCycleUnit}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
