import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { CustomSelectField } from "components/CustomSelectField";
import { CustomInputField } from "components/CustomInputField";
import { InitialValues } from "./types";
import { choices } from "./constants";

export const PauseTreatmentForm = () => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <Box height="300px">
      <Box>
        <Typography>
          By pausing the client&apos;s treatment, you agree to put the
          client&apos;s treatment and orders on hold till when next it is
          resumed. Any overdue invoice will be void.
        </Typography>
      </Box>
      <Box marginTop={2} width="90%" margin="0px auto">
        <Box display="flex" alignItems="center" gridColumnGap="32px">
          <Typography style={{ width: "310px" }}>
            Reason for Pausing Treatment *
          </Typography>
          <Box width="400px">
            <CustomInputField name="reason" />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap="32px"
          marginTop={2}
        >
          <Typography style={{ width: "310px" }}>
            Contact Client to Continue Treatment Later
          </Typography>
          <Box width="400px">
            <CustomSelectField name="choice" options={choices} />
          </Box>
        </Box>
      </Box>
      {values.choice === "YES" && (
        <>
          <Box marginY={4}>
            <Typography>
              Please enter the date the client should be contacted to continue
              treatment.
            </Typography>
            <Typography>
              On the date entered here,{" "}
              <u>the client will be automatically sent a link to book a PCM</u>{" "}
              consultation.
            </Typography>
          </Box>
          <Box marginTop={2} width="90%" margin="0px auto">
            <Box
              display="flex"
              alignItems="center"
              gridColumnGap="32px"
              marginTop={1}
            >
              <Typography style={{ width: "310px" }}>
                Notify client to continue treatment on?
              </Typography>
              <Box width="400px">
                <Field
                  component={FormikDatePicker}
                  name="resumptionDate"
                  required
                  disablePast
                  placeholder="DD/MM/YYYY"
                  size="small"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
