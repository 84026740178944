import { Box, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useMemo } from "react";
import {
  DispenseOrderDetails,
  MedicationCategory,
  ProductType,
} from "widgets/DispenseDrugs";
import Markdown from "markdown-to-jsx";
import { OrderItem } from "./OrderItem";
import { MissingOrderItem } from "./MissingOrderItem";

interface Props {
  orderDetails?: DispenseOrderDetails;
}

export const useStyles = makeStyles(({ spacing: s }) => ({
  ul: {
    padding: s(0, 0, 0, 2),
    margin: 0,
  },
  text: {
    fontSize: "1.125rem",
  },
  text500: {
    fontWeight: 500,
  },
  itemTitle: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    padding: "6px 16px",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "0.75rem",
    textTransform: "uppercase",
    width: "fit-content",
    marginBottom: s(1),
  },
  basket: {
    padding: s(2),
    backgroundColor: "#f9fff6",
    border: `1px solid #7BC67E`,
    borderRadius: "4px",
    marginTop: s(2),
  },
}));

export const PerformFinalChecksItems: React.FC<Props> = ({ orderDetails }) => {
  const classes = useStyles();

  const prescriptionItems = useMemo(
    () =>
      orderDetails?.treatmentOrderItems.filter(
        (item) =>
          item.productItem.Medication_Category ===
            MedicationCategory.PRESCRIPTION &&
          item.productItem.Product_Type !== ProductType.SUPPLEMENTARY
      ),
    [orderDetails?.treatmentOrderItems]
  );

  const counterItems = useMemo(
    () =>
      orderDetails?.treatmentOrderItems.filter(
        (item) =>
          item.productItem.Medication_Category ===
            MedicationCategory.OVER_THE_COUNTER &&
          item.productItem.Product_Type !== ProductType.SUPPLEMENTARY
      ),
    [orderDetails?.treatmentOrderItems]
  );

  const supplementaryItems = useMemo(
    () =>
      orderDetails?.treatmentOrderItems.filter(
        (item) => item.productItem.Product_Type === ProductType.SUPPLEMENTARY
      ),
    [orderDetails?.treatmentOrderItems]
  );

  const missingItems = useMemo(() => {
    return (
      orderDetails || { treatmentOrderItems: [] }
    )?.treatmentOrderItems.filter((k) => k.Missing_Quantity > 0);
  }, [orderDetails]);

  return (
    <Box>
      <Typography variant="h4" component="h2" color="primary" gutterBottom>
        Order Items
      </Typography>
      <Box mb={2}>
        <Alert severity="info">
          <AlertTitle>
            Check if all collected items are the same as the items requested in
            the order.
          </AlertTitle>
          <ul className={classes.ul}>
            <li>
              <Typography variant="body2">
                In case there was a replacement, validate that the replacement
                medicine is correct.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Check if the quantity of the items are the same as the quantity
                specified here in the order.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                If the items in the basket are not correct use the{" "}
                <strong>&quot;Request Changes&quot;</strong> button to inform
                Dispenser of the changes to be made.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                If the items are correct, use the{" "}
                <strong>&quot;Approve Dispensed Order&quot;</strong> button to
                finalize dispensing and move on to packing and delivery.
              </Typography>
            </li>
          </ul>
        </Alert>
      </Box>
      <Box mb={2}>
        <Typography className={classes.text} variant="body1" color="primary">
          Prescription ID:{" "}
          <a
            href={orderDetails?.prescription.crmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {orderDetails?.prescription?.Name}
          </a>
        </Typography>
      </Box>
      <Box px={1} py={2} bgcolor="#FAFAFA" mb={2}>
        <Typography className={classes.text} variant="body1" color="primary">
          Order ID: <strong>{orderDetails?.treatmentOrder.Name}</strong>
        </Typography>
        <Box className={classes.basket}>
          <Typography
            className={classes.text500}
            variant="body1"
            color="primary"
          >
            Items in Basket
          </Typography>

          {!!prescriptionItems?.length && (
            <Box mt={2}>
              <Typography className={classes.itemTitle} variant="body2">
                Prescription Medication
              </Typography>
              {prescriptionItems?.map((pItem) => (
                <OrderItem key={pItem.id} orderItem={pItem} />
              ))}
            </Box>
          )}

          {!!counterItems?.length && (
            <Box mt={4}>
              <Typography className={classes.itemTitle} variant="body2">
                Over-the-Counter Medication
              </Typography>
              {counterItems?.map((pItem) => (
                <OrderItem key={pItem.id} orderItem={pItem} />
              ))}
            </Box>
          )}

          {!!supplementaryItems?.length && (
            <Box mt={4}>
              <Typography className={classes.itemTitle} variant="body2">
                Supplementary Products
              </Typography>
              {supplementaryItems?.map((pItem) => (
                <OrderItem key={pItem.id} orderItem={pItem} />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      {!!missingItems.length && (
        <Box>
          <Typography
            className={classes.text500}
            variant="body1"
            color="primary"
          >
            Items Ordered
          </Typography>
          <Box my={2}>
            <Alert severity="info">
              <ul className={classes.ul}>
                <li>
                  <Typography variant="body2">
                    The items marked as &quot;Send to BMH&quot; will be
                    separated into a new order and sent to BMH to resolve the
                    unavailability.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    A new order will be created for the owed items to be
                    dispensed as soon as possible.
                  </Typography>
                </li>
              </ul>
            </Alert>
          </Box>
          {missingItems.map((mItem) => (
            <MissingOrderItem
              key={mItem.id}
              orderItem={mItem}
              prescriptionItem={orderDetails?.prescriptionItems.find(
                (pItem) => pItem.id === mItem.Prescription_Item.id
              )}
            />
          ))}
          {!!missingItems.length &&
            orderDetails?.treatmentOrder?.deliveryChangeNotes && (
              <Box maxWidth={690} mt={2}>
                <Typography
                  className={classes.text}
                  variant="body1"
                  color="primary"
                >
                  Delivery Change Note:
                </Typography>
                <Typography variant="body2">
                  <Markdown>
                    {orderDetails?.treatmentOrder.deliveryChangeNotes}
                  </Markdown>
                </Typography>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};
