import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Client, Clients } from "types";
import { zohoSelectors } from "redux/zoho";
import { pathwayRequests } from "redux/pathway";

export const useGetPathwayClients = ({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [clientArray, setClientArray] = useState<Client[]>([]);
  const ids = useSelector(zohoSelectors.getIds);

  const clients = useMemo(() => {
    return clientArray.reduce(
      (map: Clients, { id, ...c }, index) => ({
        ...map,
        [index]: {
          id: index,
          ...c,
        },
      }),
      {}
    );
  }, [clientArray]);

  const getEmails = useCallback(async () => {
    setLoading(true);

    try {
      const data = await pathwayRequests.fetchClientsFromZohoIDs(ids);
      setClientArray(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [ids, setLoading]);

  useEffect(() => {
    getEmails();
  }, [getEmails]);

  return {
    ids,
    clientArray,
    clients,
  };
};
