import React, { useMemo } from "react";
import { TextField } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";
import MenuItem from "@material-ui/core/MenuItem";

export const CustomSelectField = ({
  name,
  options,
  label,
}: {
  name: string;
  label?: string;
  options: {
    value: string;
    label: string;
  }[];
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.value);
  };

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  return (
    <TextField
      select
      value={getIn(values, name)}
      onChange={handleChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      variant="outlined"
      required
      helperText={fieldTouched && fieldError}
      error={fieldTouched && !!fieldError}
      size="small"
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
