import React, { useState } from "react";

import { useGetMeetingClient } from "hooks";
import AssignMcm from "../AssignMcm";

const MeetingModule = () => {
  const [loading, setLoading] = useState(false);

  const { client } = useGetMeetingClient({ setLoading });

  return <AssignMcm clientLoading={loading} client={client} />;
};

export default MeetingModule;
