import React, { useCallback, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Tooltip,
  Popover,
  IconButton,
  makeStyles,
  Typography,
  ButtonProps,
  IconButtonProps,
} from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";

import { useResponsive } from "hooks";

const useStyle = makeStyles(({ spacing: s, breakpoints: b }) => ({
  wrapper: {
    padding: s(2),
    [b.down("sm")]: {
      maxWidth: 400,
    },
  },
  contentWrapper: {
    paddingBottom: s(2),
    display: "flex",
    flexDirection: "column",
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    marginLeft: s(1),
    fontWeight: 500,
  },
}));

interface Props extends BoxProps {
  resetFilters: () => any;
  applyFilters: () => any;
  disabled: boolean;
  isClearFiltersDisabled?: boolean;
  label?: string;
  btnProps?: ButtonProps;
  iconBtnProps?: IconButtonProps;
}

export const TableFiltersWrapper: React.FC<Props> = ({
  resetFilters,
  applyFilters,
  disabled,
  children,
  isClearFiltersDisabled,
  label,
  btnProps = {},
  iconBtnProps = {},
  ...boxProps
}) => {
  const { isSMDown } = useResponsive();
  const [filterIcon, setFilterIcon] = useState<HTMLButtonElement | null>(null);

  const onFilterIconClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!filterIcon && e.currentTarget) resetFilters();

      setFilterIcon(e.currentTarget);
    },
    [filterIcon, resetFilters]
  );

  const onFilterClose = useCallback(() => {
    setFilterIcon(null);
    resetFilters();
  }, [resetFilters]);

  const onApplyFiltersClick = useCallback(() => {
    applyFilters();
    setFilterIcon(null);
  }, [applyFilters]);

  const classes = useStyle();

  return (
    <Box {...boxProps}>
      <Tooltip title="Filters">
        <>
          {!label && (
            <IconButton
              aria-haspopup="true"
              aria-label="filters"
              onClick={onFilterIconClick}
              color={filterIcon ? "primary" : "default"}
              size={isSMDown ? "small" : "medium"}
              {...iconBtnProps}
            >
              <FilterListIcon />
            </IconButton>
          )}
          {!!label && (
            <Button
              aria-haspopup="true"
              aria-label="filters"
              onClick={onFilterIconClick}
              color={filterIcon ? "primary" : "default"}
              size={isSMDown ? "small" : "medium"}
              {...btnProps}
            >
              <FilterListIcon />
              <Typography component="span" className={classes.label}>
                {label}
              </Typography>
            </Button>
          )}
        </>
      </Tooltip>

      <Popover
        open={!!filterIcon}
        onClose={onFilterClose}
        anchorEl={filterIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isSMDown ? "center" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isSMDown ? "right" : "left",
        }}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.contentWrapper}>{children}</Box>

          <Box className={classes.actionWrapper}>
            <Box className={classes.buttonWrapper}>
              <Button variant="text" onClick={onFilterClose}>
                Cancel
              </Button>

              <Box ml={1}>
                <Button
                  color="primary"
                  onClick={onApplyFiltersClick}
                  disabled={disabled}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
