import { createContext } from "react";
import { TreatmentWidgetLayoutInterface } from "./types";

export const TreatmentWidgetLayoutContext = createContext<TreatmentWidgetLayoutInterface>(
  {
    setActionsDimension: () => {},
    setContentDimension: () => {},
    setTitleDimension: () => {},
    handleClose: () => {},
    handleCloseShowDialog: () => {},
    showDialog: false,
  }
);
