import { n8nClient } from "apis";
import { GetTreatmentOrderDetailsResponse } from "widgets/CompleteOrderDetails/types";

export async function getTreatmentOrderDetails(treatmentOrderId: string) {
  try {
    const response = await n8nClient.get<GetTreatmentOrderDetailsResponse>(
      `/treatment-order/${treatmentOrderId}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function sendForSigning(payload: any) {
  try {
    const response = await n8nClient.post(
      `/treatment-order/send-for-signing`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function sendForDispensing(payload: any) {
  try {
    const response = await n8nClient.post(
      `/treatment-order/send-for-dispensing`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}
