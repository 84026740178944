import { Box, makeStyles, Typography } from "@material-ui/core";
import { BasicTable, RowData } from "components/BasicTable";
import { TwoColumnData } from "components/TwoColumnData";
import React, { useMemo } from "react";

export interface Details {
  title: string;
  value: string | React.ReactNode;
}

export interface PatientDetails {
  patientName: string;
  patientRecordCrmLink: string;
  patientRecordBooksLink: string;
  doctorName: string;
  repeats: string;
}

export interface RxItem {
  item: string;
  quantity: string;
  dosing: string;
  name: string;
}

export interface TreatmentDetailsProps {
  patientDetails: PatientDetails;
  injectionSupplies: string[];
  rxItems: (RxItem | null)[];
}

const useStyle = makeStyles(({ spacing: s }) => ({
  subHeading: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: s(1),
  },
  injectionSupply: {
    listStyleType: "none",
  },
  injectionSupplyContainer: {
    margin: 0,
    padding: 0,
  },
  mb3: {
    marginBottom: s(3),
  },
}));

export const TreatmentDetails: React.FC<TreatmentDetailsProps> = ({
  patientDetails: {
    doctorName,
    patientName,
    patientRecordBooksLink,
    patientRecordCrmLink,
    repeats,
  },
  rxItems,
  injectionSupplies,
}) => {
  const classes = useStyle();
  const details: Details[] = useMemo(
    () => [
      {
        title: "Patient",
        value: (
          <a
            href={patientRecordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {patientName}
          </a>
        ),
      },
      {
        title: "Patient Zoho Books Record",
        value: (
          <a
            href={patientRecordBooksLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {patientName}
          </a>
        ),
      },
      {
        title: "Doctor",
        value: doctorName,
      },
      {
        title: "Repeats",
        value: repeats,
      },
    ],
    [
      doctorName,
      patientName,
      patientRecordBooksLink,
      patientRecordCrmLink,
      repeats,
    ]
  );

  const memoizedRxItems = useMemo(
    () =>
      rxItems.reduce((prev: RowData[], curr: RxItem | null) => {
        if (curr && curr.item) {
          return prev.concat({
            item: curr.item,
            name: curr.item,
            quantity: curr.quantity,
            dosing: curr.dosing,
          });
        }
        return prev;
      }, []),
    [rxItems]
  );

  return (
    <>
      <Box className={classes.mb3}>
        <TwoColumnData details={details} />
      </Box>
      <Box className={classes.mb3}>
        <Typography variant="h2" className={classes.subHeading}>
          Rx Items
        </Typography>
        <BasicTable
          tableHeaders={[
            { title: "Item", id: "item" },
            { title: "Quantity", id: "quantity" },
            { title: "Dosing", id: "dosing" },
          ]}
          tableData={memoizedRxItems}
          emptyMessage={memoizedRxItems.length === 0 ? "No data found" : ""}
        />
      </Box>
      <Box>
        <Typography variant="h2" className={classes.subHeading}>
          Injection Supplies
        </Typography>
        <ul className={classes.injectionSupplyContainer}>
          {injectionSupplies?.map((injectionSupply) => (
            <Typography
              variant="body2"
              component="li"
              className={classes.injectionSupply}
              key={injectionSupply}
            >
              {injectionSupply}
            </Typography>
          ))}
        </ul>
      </Box>
    </>
  );
};
