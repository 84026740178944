import { BloodDrawerAddress } from "@deep-consulting-solutions/bmh-constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

interface Props {
  addresses: BloodDrawerAddress[];
}

export const NurseInfoTable = ({ addresses }: Props) => {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Branch Name</Typography>
            </TableCell>
            <TableCell>
              <Typography>Street</Typography>
            </TableCell>
            <TableCell>
              <Typography>City</Typography>
            </TableCell>
            <TableCell>
              <Typography>Region</Typography>
            </TableCell>
            <TableCell>
              <Typography>Country</Typography>
            </TableCell>
            <TableCell>
              <Typography>Post Code</Typography>
            </TableCell>
            <TableCell>
              <Typography>Local Phone</Typography>
            </TableCell>
            <TableCell>
              <Typography>Working Hours</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addresses.map((address) => (
            <TableRow key={address.branchName}>
              <TableCell>
                <Typography>{address.branchName}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.street}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.city}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.region}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.country}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.zip}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.localPhone}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{address.workingHours}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
