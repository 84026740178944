// import { ConsultationPurposeEnum } from "@deep-consulting-solutions/bmh-constants";
import { useFormikContext } from "formik";
import { InitialValues } from "../types";
import { checkupType } from "../constants";

export const useCancelCheckup = () => {
  const { values } = useFormikContext<InitialValues>();

  const {
    nextCheckupType,
    isNotificationDateForNextConsultPast,
    hasUpcomingDoctorConsultation,
    isLatestBloodTestInvoicePaid,
    associatedBloodTestOrder,
    latestBloodTestOrderInvoice,
  } = values;

  const isNextCheckupTypeEmpty = nextCheckupType === checkupType.emptyValue;

  // actionTypeIsCancelCheckup
  if (isNextCheckupTypeEmpty) {
    return {
      textOne: `Please confirm if you would like to cancel client's 3 weeks check-up`,
      textTwo: `This will allow the client to proceed with current treatment till the next checkup date.`,
      textThree: "",
      cancellationState: "EmptyNextCheckupType",
    };
  }
  if (!isNotificationDateForNextConsultPast) {
    return {
      textOne: `Please confirm if you would like to cancel client's ${nextCheckupType}`,
      textTwo: `This will allow the client to proceed with current treatment till the next checkup date.`,
      textThree: "",
      cancellationState: "NotificationDateForNextConsultPast",
    };
  }
  if (hasUpcomingDoctorConsultation) {
    return {
      textOne: `Client has a consultation with the doctor already booked and paid for.`,
      textTwo: `Checkup cannot be cancelled.`,
      textThree: "",
      cancellationState: "HasUpcomingDoctorConsultation",
    };
  }
  if (latestBloodTestOrderInvoice && isLatestBloodTestInvoicePaid) {
    return {
      textOne: `Client has a blood test order already in progress for this checkup: ${associatedBloodTestOrder?.Name}`,
      textTwo: `Please confirm if you would like to cancel client's ${nextCheckupType}`,
      textThree: `This will not cancel the blood test, but will allow the client to proceed with 
        current treatment till the next checkup date without a PCM or Doctor's Consultation`,
      cancellationState: "LatestBloodTestInvoicePaid",
    };
  }
  if (latestBloodTestOrderInvoice && !isLatestBloodTestInvoicePaid) {
    return {
      textOne: `Client has a Blood Test Invoice related to this checkup that is ${latestBloodTestOrderInvoice?.status}.`,
      textTwo: `Please confirm if you would like to cancel client's ${nextCheckupType} and void the current invoice.`,
      textThree: `Cancelling this checkup will allow the client proceed with current treatment till the next regular checkup date.`,
      cancellationState: "LatestBloodTestInvoiceNotPaid",
    };
  }

  return {
    textOne: `Please confirm if you would like to cancel client's 3 weeks check-up`,
    textTwo: `This will allow the client to proceed with current treatment till the next checkup date.`,
    textThree: "",
    cancellationState: "Unknown",
  };
};
