import React, { memo, useCallback } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { ExpandLess as UpArrow } from "@material-ui/icons";

import {
  TEST_RESULTS_TABLE_META,
  BloodTestResultField,
} from "../TestResults.types";
import { TABLE_HEADER_COLOR, TableCell } from "../TestResults.styles";

const getRotate = (sortDesc: boolean | null) => {
  if (sortDesc === null || sortDesc) {
    return "180deg";
  }
  return "0deg";
};

interface StyleProps {
  sortDesc: boolean | null;
  hasSort?: boolean;
}

const useStyle = makeStyles(({ spacing: s }) => ({
  tableHeaderCell: ({ hasSort }: StyleProps) => ({
    color: TABLE_HEADER_COLOR,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      cursor: hasSort ? "pointer" : "default",
    },
  }),
  sortIcon: ({ sortDesc }: StyleProps) => ({
    opacity: sortDesc === null ? 0 : 1,
    transition: "0.2s",
    transform: `rotate(${getRotate(sortDesc)})`,
    marginLeft: s(1),
  }),
}));

interface ListViewHeaderCellProps {
  field: BloodTestResultField;
  sortDesc: boolean | null;
  onClick: (f: BloodTestResultField) => any;
}

const ListViewHeaderCell: React.FC<ListViewHeaderCellProps> = ({
  field,
  sortDesc,
  onClick,
}) => {
  const meta = TEST_RESULTS_TABLE_META[field];
  const label = meta?.label ?? "";
  const hasSort = meta?.hasSort ?? false;

  const onCellClick = useCallback(() => {
    onClick(field);
  }, [onClick, field]);

  const classes = useStyle({ sortDesc, hasSort });

  return (
    <TableCell>
      <Box
        className={classes.tableHeaderCell}
        onClick={hasSort ? onCellClick : undefined}
      >
        {label} {hasSort && <UpArrow className={classes.sortIcon} />}
      </Box>
    </TableCell>
  );
};

export default memo(ListViewHeaderCell);
