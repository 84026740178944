import { Column } from "./types";

export const MAX_LIMIT = 200;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const PENDING_PACKING_STATE = "Pending Packing";

export const defaultColumns: Column[] = [
  { id: "state", label: "State", minWidth: 170 },
  {
    id: "orderNumber",
    label: "Order NUmber",
    minWidth: 100,
  },
  {
    id: "patientName",
    label: "Patient Name",
    minWidth: 120,
  },
  {
    id: "patientID",
    label: "Patient Id",
    minWidth: 100,
  },
  {
    id: "patientMobile",
    label: "Patient Mobile",
    minWidth: 120,
  },
  {
    id: "patientEmail",
    label: "Patient Email",
    minWidth: 220,
  },
];

export const defaultColumnsAlt = [
  ...defaultColumns,
  {
    id: "courierOrderNumber" as const,
    label: "Courier Order Number",
    minWidth: 150,
  },
];
