import { n8nClient } from "apis";

type PaymentStatus = "Paid" | "Pending Payment" | "On-Hold" | null;

export async function getWaiveStatus(treatmentOrderId: string) {
  try {
    const response = await n8nClient.get<{
      waiveShippingCharges: boolean;
      paymentStatus: PaymentStatus;
    }>(`/treatment-order/waive-shipping-charge-state/${treatmentOrderId}`);

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function submitWaiveShippingCharges(treatmentOrderId: string) {
  try {
    const response = await n8nClient.post(
      `/treatment-order/waive-shipping-charge/${treatmentOrderId}`
    );

    return response.data;
  } catch (error) {
    return null;
  }
}
