import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/types";
import { settingsActions, settingsSelectors } from "redux/settings";
import { Column, RowActionsEnum } from "types";
import { notifications } from "services";
import { getENText } from "helpers";
import { EditableTable } from "components/EditableTable";
import { green, grey, purple, yellow } from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core";
import { TestTubesQty } from "@deep-consulting-solutions/bmh-constants";
import { SettingsContainer } from "components/SettingsContainer";

export const INITIAL_COLUMNS: Column<TestTubesQty>[] = [
  {
    key: "bmhObrCode",
    header: "BMH Test Profile OBR Code",
    info:
      "Test profiles quantity of tubes. Blood Test Order record quantity of tubes is the sum of all selected test profiles quantity of tubes in that record.",
    editable: false,
    align: "left",
    required: true,
    stickyLeft: true,
  },
  {
    key: "yellow",
    header: "YELLOW TUBES QTY",
    info: `Set quantity of yellow tubes per test profile.`,
    editable: true,
    align: "center",
    autoFocus: true,
    required: true,
  },
  {
    key: "purple",
    header: "PURPLE TUBES QTY",
    info: `Set quantity of purple tubes per test profile.`,
    editable: true,
    align: "center",
    required: true,
  },
  {
    key: "lightBlue",
    header: "LIGHT BLUE TUBES QTY",
    info: `Set quantity of light blue tubes per test profile.`,
    editable: true,
    align: "center",
    required: true,
  },
  {
    key: "grey",
    header: "GREY TUBES QTY",
    info: `Set quantity of grey tubes per test profile.`,
    editable: true,
    align: "center",
    required: true,
  },
  {
    key: "green",
    header: "GREEN TUBES QTY",
    info: `Set quantity of green tubes per test profile.`,
    editable: true,
    align: "center",
    required: true,
  },
  {
    key: "saliva",
    header: "SALIVA TUBES QTY",
    info: `Set quantity of saliva tubes per test profile.`,
    editable: true,
    align: "center",
    required: true,
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      "& > * .MuiTableCell-head": {
        "&:nth-of-type(2)": {
          color: yellow["900"],
        },
        "&:nth-of-type(3)": {
          color: purple[500],
        },
        "&:nth-of-type(4)": {
          color: "#4ba4f1",
        },
        "&:nth-of-type(5)": {
          color: grey["600"],
        },
        "&:nth-of-type(6)": {
          color: green["800"],
        },
        "&:nth-of-type(7)": {
          color: "#000000",
        },
      },
    },
  })
);

const TestTubesQtyTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const rows = useSelector(
    settingsSelectors.testTubeLabelsQtySelectors.selectAll
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(settingsActions.getTestTubesQty());
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTestTubeLabelsQty = async (data: TestTubesQty) => {
    setLoading(true);
    const res = await dispatch(settingsActions.updateTestTubesQty(data));
    setLoading(false);
    const successful = settingsActions.updateTestTubesQty.fulfilled.match(res);
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.updated"));
    }
    return successful;
  };

  const validateCell = useCallback(
    (
      row: TestTubesQty,
      val: string,
      dataKey: keyof TestTubesQty,
      required: boolean
    ) => {
      let error = false;
      let helperText = "";
      if (((val as unknown) as number) !== 0 && !val && required) {
        return {
          error: true,
          helperText: getENText("settings.field.required"),
        };
      }

      if (
        dataKey === "bmhObrCode" &&
        val &&
        rows
          .map((r) => r.id !== row.id && (r[dataKey] || "").toLowerCase())
          .includes(val.toLowerCase())
      ) {
        error = true;
        helperText = getENText("settings.field.valueExist");
      }

      return {
        error,
        helperText,
      };
    },
    [rows]
  );

  return (
    <SettingsContainer>
      <EditableTable<TestTubesQty>
        className={classes.header}
        actions={[RowActionsEnum.Edit]}
        handlers={{
          onRowUpdate: updateTestTubeLabelsQty,
        }}
        title="Set Up Test Tubes Quantity"
        description="Edit Test Tubes Quantity per Test Profile."
        loading={loading}
        columns={INITIAL_COLUMNS}
        rows={rows}
        validateCell={validateCell}
      />
    </SettingsContainer>
  );
};

export default TestTubesQtyTab;
