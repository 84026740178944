import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { Close } from "@material-ui/icons";
import { useTreatmentWidgetLayout } from "../useTreatmentWidgetLayout";

interface Props extends DialogTitleProps {
  title: string;
  text: string;
  proceedButtonText: string;
  cancelButtonText: string;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  title: {
    backgroundColor: p.grey[50],
  },
  proceed: {
    color: "white",
    background: p.error.main,
  },
  content: {
    padding: s(4),
  },
  actions: {
    borderTop: `1px solid ${p.grey[200]}`,
  },
}));

export const CloseTreatmentWidgetDialog = ({
  title,
  text,
  proceedButtonText,
  cancelButtonText,
  ...props
}: Props) => {
  const {
    showDialog,
    handleCloseShowDialog,
    handleClose: handleCloseWidget,
  } = useTreatmentWidgetLayout();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    handleCloseShowDialog(false);
  }, [handleCloseShowDialog]);

  const handleCancel = useCallback(() => {
    handleCloseWidget();
  }, [handleCloseWidget]);

  return (
    <Dialog open={showDialog}>
      <DialogTitle {...props} className={classes.title}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
          direction="row"
        >
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography align="center">{text}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" onClick={handleClose} color="primary">
          {cancelButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={handleCancel}
          className={classes.proceed}
        >
          {proceedButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
