import React, { useMemo } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";

import { SendGridEmailStatus, SendGridStatusEnum } from "types";

import { SendStatusEnum } from "./SendEmailWrapper.types";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    marginTop: s(2),
    textAlign: "right",
  },
  caption: {
    color: p.grey[500],
  },
}));

export interface SendEmailWrapperProps {
  status: SendStatusEnum | null;
  sendingMessage?: string;
  onClose: () => any;
  onSuccess?: () => any;
  failures: SendGridEmailStatus[];
  failureTitle?: string;
  failureHelper?: string;
  isBloodDrawer?: boolean;
}

const SendEmailWrapper: React.FC<SendEmailWrapperProps> = ({
  status,
  failures,
  onClose,
  onSuccess,
  sendingMessage,
  failureTitle,
  failureHelper,
  children,
  isBloodDrawer,
}) => {
  const classes = useStyle();

  const { deferredEmails = [], otherFailureEmails = [] } = useMemo(() => {
    const deferred = failures.filter(
      (failure) => failure.status === SendGridStatusEnum.deferred
    );
    const others = failures.filter(
      (failure) => failure.status !== SendGridStatusEnum.deferred
    );
    return { deferredEmails: deferred, otherFailureEmails: others };
  }, [failures]);

  return (
    <>
      {status === null && children}

      {status === SendStatusEnum.sending && (
        <Box>
          <Typography variant="h5">Sending email...</Typography>
          <Box className={classes.section}>
            <Typography variant="body1" className={classes.caption}>
              {sendingMessage ||
                "Few seconds. We just need to confirm that your email was delivered."}
            </Typography>
          </Box>
        </Box>
      )}

      {status === SendStatusEnum.error &&
        (() => {
          if (otherFailureEmails.length > 0) {
            return (
              <Box>
                <Typography variant="h5">
                  {failureTitle ||
                    "Unfortunately email was not delivered to recipient(s)."}
                </Typography>
                {otherFailureEmails.map(({ email, status: s }, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} className={classes.section}>
                      <Typography variant="body1" className={classes.caption}>
                        Not delivered to: {email}
                      </Typography>
                      <Typography variant="body1" className={classes.caption}>
                        Reason: {s}
                      </Typography>
                    </Box>
                  );
                })}

                <Box className={classes.section}>
                  <Typography variant="body1" className={classes.caption}>
                    {failureHelper ||
                      "Try again or contact DCS to resolve the problem"}
                  </Typography>
                </Box>

                <Box className={classes.buttons}>
                  <Button variant="outlined" color="primary" onClick={onClose}>
                    OK
                  </Button>
                </Box>
              </Box>
            );
          }
          if (deferredEmails.length > 0) {
            return (
              <Box>
                <Typography variant="h5">
                  Email deferred, will reach the inbox in ~72 hours
                </Typography>

                <Box className={classes.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onSuccess || onClose}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            );
          }
          return (
            <Box>
              <Typography variant="h5">
                {failureTitle ||
                  "Unfortunately email was not delivered to recipient(s)."}
              </Typography>

              <Box className={classes.section}>
                <Typography variant="body1" className={classes.caption}>
                  {failureHelper ||
                    "Try again or contact DCS to resolve the problem"}
                </Typography>
              </Box>

              <Box className={classes.buttons}>
                <Button variant="outlined" color="primary" onClick={onClose}>
                  OK
                </Button>
              </Box>
            </Box>
          );
        })()}

      {status === SendStatusEnum.ok && (
        <Box>
          <Typography variant="h5">
            Email was successfully delivered to{" "}
            {isBloodDrawer ? "Blood Drawer" : "Client"}!
          </Typography>

          <Box className={classes.buttons}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSuccess || onClose}
            >
              OK
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SendEmailWrapper;
