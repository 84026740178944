import { Grid, Box, Divider, Typography } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import React from "react";
import { TreatmentOrderItem, useStyles } from "widgets/DispenseDrugs";
import { NeedleField } from "./NeedleField";

interface InjectionDetailsProps {
  fieldName: string;
  childItem: TreatmentOrderItem;
  quantityString: string;
  wipesQuantityString: string;
  hasExtraSyringe?: boolean;
}

export const InjectionDetails: React.FC<InjectionDetailsProps> = ({
  fieldName,
  childItem,
  quantityString,
  wipesQuantityString,
  hasExtraSyringe,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid wrap="nowrap" container>
        <Grid xs={3} item>
          <Box mb={2}>
            <Grid container>
              <Grid xs={7} item>
                <TitleValue
                  title={`${hasExtraSyringe ? "Extra " : ""}Syringe Volume`}
                  value={childItem.productItem?.Syringe_Volume_ml || ""}
                />
              </Grid>
              <Grid xs={5} item>
                <TitleValue
                  title="Syringe Type"
                  value={childItem.productItem?.Injection_Type || ""}
                />
              </Grid>
            </Grid>
          </Box>
          <TitleValue title="Quantity" value={quantityString} />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={3} item>
          <NeedleField
            label="Needle Filter"
            fieldName={`${fieldName}.${
              hasExtraSyringe ? "extraNeedleFilter" : "needleFilter"
            }`}
          />
          <TitleValue title="Quantity" value={quantityString} />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={3} item>
          <NeedleField
            label="Needle"
            fieldName={`${fieldName}.${
              hasExtraSyringe ? "extraNeedle" : "needle"
            }`}
          />
          <TitleValue title="Quantity" value={quantityString} />
        </Grid>
        {!hasExtraSyringe && (
          <>
            <Divider orientation="vertical" flexItem />
            <Grid item>
              <Field
                name={`${fieldName}.includeAlcoholWipes`}
                component={CheckboxWithLabel}
                type="checkbox"
                className={classes.checkbox}
                Label={{
                  label: (
                    <Typography variant="body2">
                      Include Alcohol Wipes
                    </Typography>
                  ),
                }}
                color="primary"
              />
              <TitleValue title="Quantity" value={wipesQuantityString} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
