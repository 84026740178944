import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

type ActionButtonsProps = {
  activeStep: number;
  goBack: () => void;
};

export const ActionButtons = ({ activeStep, goBack }: ActionButtonsProps) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      {activeStep === 1 && (
        <Button
          style={{
            textTransform: "capitalize",
            minWidth: "110px",
          }}
          color="primary"
          variant="outlined"
          onClick={goBack}
          disabled={isSubmitting}
        >
          Back
        </Button>
      )}
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "110px",
        }}
        color="primary"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting}
        endIcon={activeStep === 0 && <ArrowForwardIcon />}
      >
        {activeStep === 0 ? "Next" : "Save"}
      </Button>
    </Box>
  );
};
