import { Box } from "@material-ui/core";
import { getFieldName, sortAlphabetically, timeOfDayOptions } from "helpers";
import React, { useMemo } from "react";
import { AutocompleteMultipleFreeSoloField } from "components/AutocompleteMultipleFreeSoloField";

interface Props {
  name?: string;
}
export const TimeOfDayField = ({ name }: Props) => {
  const fieldName = useMemo(() => getFieldName("timeOfDay", name), [name]);

  const options = useMemo(() => {
    return sortAlphabetically(timeOfDayOptions);
  }, []);

  return (
    <Box mb={2} maxWidth={500}>
      <AutocompleteMultipleFreeSoloField
        name={fieldName}
        label="Time of Day"
        options={options}
        required={false}
      />
    </Box>
  );
};
