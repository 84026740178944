import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { Alert } from "@material-ui/lab";
import { CustomAutocompleteField } from "components/CustomAutocompleteField";
import LaunchIcon from "@material-ui/icons/Launch";
import { InitialValues, LegacyTreatment } from "./types";
import { OrderItemsTable } from "./OrderItemsTable";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[600],
    marginRight: "4px",
  },
  gridItem: {
    padding: "8px 12px !important",
  },
  gridItemAlt: {
    padding: "0px 12px !important",
  },
  gridItemAltOne: {
    padding: "0px 0px 0px 12px  !important",
  },
}));

interface TitleValueProps {
  title: string;
  value?: string | number | JSX.Element;
}

export const TitleValue = ({ title, value }: TitleValueProps) => {
  const classes = useStyles();
  return (
    <Typography>
      <span className={classes.title}>{title}</span>
      {value}
    </Typography>
  );
};

const Dash = () => {
  return (
    <Typography variant="body2" component="span">
      &mdash;
    </Typography>
  );
};

export const CompleteOrderForm = () => {
  const { values } = useFormikContext<InitialValues>();
  const classes = useStyles();

  const showRunOutWarning = values.formattedOrderItems.some(
    (f) => f.remainingQty === 0
  );

  return (
    <Box>
      <Alert severity="info">
        Client has paid a medicine order invoice. Please check the treatment and
        the invoice to link the correct prescription to this order. Please
        update the items prescribed to this patient. This will be saved for
        subsequent orders on the same prescription.
      </Alert>
      <Box marginY={3}>
        <Typography
          style={{
            fontWeight: "bold",
            marginBottom: "16px",
            fontSize: "18px",
          }}
        >
          Order Details
        </Typography>
        <Grid container spacing={3} style={{ width: "80%" }}>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Invoice:"
              value={
                values.orderDetails.invoice?.webUrl ? (
                  <span>
                    <a
                      href={values.orderDetails.invoice.webUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {values.orderDetails.invoice.invoiceNumber}
                    </a>
                  </span>
                ) : (
                  <Dash />
                )
              }
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Recurring Invoice:"
              value={
                values.orderDetails.recurringInvoice?.webUrl ? (
                  <span>
                    <a
                      href={values.orderDetails.recurringInvoice.webUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {values.orderDetails.recurringInvoice.name}
                    </a>
                  </span>
                ) : (
                  <Dash />
                )
              }
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Patient:"
              value={
                values.orderDetails.patient?.webUrl ? (
                  <span>
                    <a
                      href={values.orderDetails.patient.webUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {values.orderDetails.patient.name}
                    </a>
                  </span>
                ) : (
                  <Dash />
                )
              }
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Doctor:"
              value={
                values.orderDetails.doctor?.webUrl ? (
                  <span>
                    <a
                      href={values.orderDetails.doctor.webUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {values.orderDetails.doctor.name}
                    </a>
                  </span>
                ) : (
                  <Dash />
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography
          style={{ fontWeight: "bold", marginBottom: "16px", fontSize: "18px" }}
        >
          Shipping Address
        </Typography>
        <Grid container spacing={3} style={{ width: "80%" }}>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Street:"
              value={values.shippingAddress.street || <Dash />}
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="City:"
              value={values.shippingAddress.city || <Dash />}
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Province / Region / State:"
              value={values.shippingAddress.provinceRegionState || <Dash />}
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Postal Code / Zip:"
              value={values.shippingAddress.postalCode || <Dash />}
            />
          </Grid>
          <Grid item sm={6} className={classes.gridItem}>
            <TitleValue
              title="Country:"
              value={values.shippingAddress.country || <Dash />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography
          style={{ fontWeight: "bold", marginBottom: "16px", fontSize: "18px" }}
        >
          Treatment Details
        </Typography>
        <Grid container>
          <Grid item sm={12} className={classes.gridItemAlt}>
            <Typography
              variant="body2"
              style={{
                fontWeight: "bold",
                color: "#6B7280",
                marginBottom: "4px",
              }}
            >
              Legacy Treatment *
            </Typography>
          </Grid>
          <Grid item sm={6} className={classes.gridItemAltOne}>
            <CustomAutocompleteField
              name="legacyTreatment"
              options={values.legacyTreatments}
              renderOption={(opt: LegacyTreatment) => (
                <Box display="flex" alignItems="center">
                  <Typography style={{ marginRight: "8px" }}>
                    {opt.title}
                  </Typography>
                  <a
                    href={opt.WebUrl}
                    style={{ display: "flex", alignItems: "center" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LaunchIcon fontSize="small" />
                  </a>
                </Box>
              )}
            />
          </Grid>
          {values.legacyTreatment && (
            <Grid item sm={6} className={classes.gridItem}>
              <a
                href={values.legacyTreatment?.WebUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LaunchIcon fontSize="default" />
              </a>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography
          style={{ fontWeight: "bold", marginBottom: "16px", fontSize: "18px" }}
        >
          Prescription Details
        </Typography>
        <Grid item sm={6}>
          <Alert severity="info" style={{ margin: "8px auto" }}>
            Select the prescription related to this order from the list.
          </Alert>
        </Grid>
        <Grid container>
          <Grid item sm={12} className={classes.gridItemAlt}>
            <Typography
              variant="body2"
              style={{
                fontWeight: "bold",
                color: "#6B7280",
                margin: "12px auto 4px",
              }}
            >
              Legacy Prescription *
            </Typography>
          </Grid>
          <Grid item sm={6} className={classes.gridItemAltOne}>
            <CustomAutocompleteField
              name="legacyPrescription"
              options={values.legacyPrescriptions}
              renderOption={(opt: LegacyTreatment) => (
                <Box display="flex" alignItems="center">
                  <Typography style={{ marginRight: "8px" }}>
                    {opt.title}
                  </Typography>
                  <a
                    href={opt.WebUrl}
                    style={{ display: "flex", alignItems: "center" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LaunchIcon fontSize="small" />
                  </a>
                </Box>
              )}
            />
          </Grid>
          {values.legacyPrescription && (
            <Grid item sm={6} className={classes.gridItem}>
              <a
                href={values.legacyPrescription?.WebUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LaunchIcon fontSize="default" />
              </a>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box marginY={3}>
        <Typography
          style={{ fontWeight: "bold", marginBottom: "16px", fontSize: "18px" }}
        >
          Order Details
        </Typography>
        <Alert severity="info">
          <Box component="ul" margin={0}>
            <li>
              Prescription Total Quantity: The total quantity including all
              repeats.
            </li>
            <li>
              Prescription Total Dispensed Quantity: The quantity you have
              already dispensed from Titan, before receiving this order today.
            </li>
            <li>
              Usage Notes: Add usage instructions from the doctor. This will
              appear on the label.
            </li>
          </Box>
        </Alert>
        <Box>
          <OrderItemsTable />
        </Box>
        {showRunOutWarning && (
          <Alert severity="warning">
            This order can&apos;t be dispensed until a new prescription is
            created and signed for it because the current prescription run out
            for one or more items. On clicking <strong>Send for Signing</strong>{" "}
            a new prescription will be created with no repetitions and the
            doctor will be informed to sign it then the order will be
            progressed.
          </Alert>
        )}
      </Box>
    </Box>
  );
};
