import { Box, Grid, Typography } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import {
  getDurationString,
  getQuantityString,
  getValueFromObject,
} from "helpers";
import React, { useMemo } from "react";
import {
  DispenseOrderPages,
  MissingState,
  ParentOrderItem,
  TreatmentOrder,
  TreatmentOrderItem,
  useStyles,
} from "widgets/DispenseDrugs";
import { DBPharmacyProduct } from "types";
import { ChildInjectionBundleItem } from "./ChildInjectionBundleItem";
import { ChildBacteriostaticItem } from "./ChildBacteriostaticItem";
import { HandleMissingItemsFields } from "./HandleMissingItemsFields";
import { OrderItemDispensedUnits } from "../OrderItemDispensedUnits";

interface MainItemProps {
  item: ParentOrderItem;
  fieldName: string;
  handleMissingItems?: boolean;
  treatmentOrder?: TreatmentOrder;
  replacements?: DBPharmacyProduct[];
  manufacturers: string[];
}

export const MainItem: React.FC<MainItemProps> = ({
  item,
  fieldName,
  treatmentOrder,
  handleMissingItems,
  replacements,
  manufacturers,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const quantityString = useMemo(() => getQuantityString(item), [item]);
  const durationString = useMemo(() => getDurationString(item), [item]);

  const checkMissingQuantity = useMemo(() => {
    return [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes((values as any).currentPage);
  }, [values]);

  const missingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [fieldName, values]
  );

  const renderChildItem = (child: TreatmentOrderItem, index: number) => (
    <Box key={child.id} pl={2} my={2}>
      <Box p={1} borderRadius="2px" bgcolor="#F4F4F4">
        <Typography color="primary">
          {child.productItem?.Product_Type === "Injection Bundle"
            ? "Injection Bundle"
            : "Bacteriostatic NACL Water"}
        </Typography>
        <Box mt={1} p={1} borderRadius="2px" bgcolor="#FFF">
          {child.productItem?.Product_Type === "Injection Bundle" ? (
            <ChildInjectionBundleItem
              parentItem={item}
              childItem={child}
              manufacturers={manufacturers}
              name={fieldName}
              index={index}
              handleMissingItems={handleMissingItems}
              treatmentOrder={treatmentOrder}
              replacements={replacements}
            />
          ) : (
            <ChildBacteriostaticItem
              parentItem={item}
              childItem={child}
              manufacturers={manufacturers}
              name={fieldName}
              index={index}
              handleMissingItems={handleMissingItems}
              treatmentOrder={treatmentOrder}
              replacements={replacements}
            />
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box mb={2}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <TitleValue
              title="Name"
              value={item.productItem?.Name_For_Prescription}
            />
          </Grid>
          <Grid item xs={2}>
            <TitleValue title="Quantity" value={quantityString} />
          </Grid>
          <Grid item xs={1}>
            <TitleValue title="Duration" value={durationString} />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              className={classes.text}
              name={`${fieldName}.usageInstructions`}
              label="Usage Instructions"
              size="small"
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              className={classes.text}
              name={`${fieldName}.usageNotes`}
              label="Usage Notes"
              size="small"
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      {![MissingState.DISPENSE_PARTIALLY, MissingState.REPLACE].includes(
        missingState
      ) &&
        checkMissingQuantity && (
          <OrderItemDispensedUnits
            quantity={item.Quantity}
            fieldName={fieldName}
            item={item}
            manufacturers={manufacturers}
          />
        )}

      {!checkMissingQuantity && (
        <OrderItemDispensedUnits
          quantity={item.Quantity}
          fieldName={fieldName}
          item={item}
          manufacturers={manufacturers}
        />
      )}

      {handleMissingItems && (
        <HandleMissingItemsFields
          manufacturers={manufacturers}
          item={item}
          fieldName={fieldName}
          treatmentOrder={treatmentOrder}
          replacements={replacements!}
        />
      )}

      {item.children.map((child, index) => renderChildItem(child, index))}
    </>
  );
};
