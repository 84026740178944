import { crhApiClient } from "apis";

export const getTechnicalAdminUsers = async () => {
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/noco/${process.env.REACT_APP_TECHNICAL_ADMIN_USERS_KEY}/TechnicalAdminUsers/views/TechnicalAdminUsers`,
    app: "NOCODB",
    method: "GET",
  });

  return res.data.data.list[0].userlDs as string[];
};
