import { n8nClient } from "apis";

export const createReplacementOrder = async (
  treatmentOrderId: string,
  payload: any
) => {
  const res = await n8nClient.post(
    `/treatment-order/${treatmentOrderId}/create-replacement`,
    payload
  );
  return res.data;
};
