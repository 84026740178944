import moment from "moment";
import { apiClient } from "apis";
import { PartialTreatmentOrderValues, TreatmentResponse } from "./types";
import { allowedOrderStates } from "./constants";
import { formatDate } from "./utils";

interface UpdateOrdersPayload {
  treatmentId: string;
  treatmentOrders: PartialTreatmentOrderValues[];
}

export async function updateOrders(payload: UpdateOrdersPayload) {
  try {
    const response = await apiClient.post(
      `/treatment-orders/update-order-details`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getTreatmentData(zohoTreatmentId: string) {
  try {
    const response = await apiClient.get<{ data: TreatmentResponse }>(
      `/treatment-orders/${zohoTreatmentId}`
    );

    const treatment = response.data.data;

    const billingData = treatment.Billing_Objects.sort((a, b) =>
      moment(a.billingDate).diff(moment(b.billingDate))
    ).map((b) => ({
      ...b,
      formattedBillingDate: formatDate(b.billingDate),
      treatmentOrders: b.treatmentOrders
        .filter((o) => allowedOrderStates.includes(o.State))
        .map((o) => ({
          ...o,
          Late_Dispensing_Date: o.Late_Dispensing_Date || "",
          Urgent: o.Urgent || false,
          lateDispensing: !!o.Late_Dispensing_Date,
        })),
    }));

    treatment.billingData = billingData.filter(
      (b) => b.treatmentOrders.length > 0
    );

    return treatment;
  } catch (error) {
    return null;
  }
}
