import { useSelector } from "react-redux";

import { zohoSelectors } from "redux/zoho";
import { ZohoPathway } from "types";

export const usePathwayRecord = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const records = useSelector(zohoSelectors.getRecords);
  const pathwayZohoID = ids[0];
  const pathway = records[pathwayZohoID] as ZohoPathway;
  return {
    pathway,
    pathwayZohoID,
  };
};
