import { n8nClient } from "apis";
import { ZohoBMHDoctor, ZohoClientRecord, ZohoCurrentUser } from "types";

export interface CreateVenesectionTreatmentPayload {
  doctor: string;
  instructions: string;
  client: string;
  userId: string;
}

export async function getDoctors() {
  try {
    const response = await n8nClient.get<{
      doctors: ZohoBMHDoctor[];
    }>("/create-treatment-plan-widget/doctors");

    return response.data.doctors;
  } catch (error) {
    return [];
  }
}

export async function getClient(clientId: string) {
  try {
    const response = await n8nClient.get<{
      client: ZohoClientRecord;
    }>(`/create-treatment-plan-widget/?zohoID=${clientId}`);

    return response.data.client;
  } catch (error) {
    return null;
  }
}

export async function getUserDoctorProfile(currentUserId: string) {
  try {
    const response = await n8nClient.get<{
      user: ZohoCurrentUser;
      doctorId: string;
    }>(`/create-treatment-plan-widget/user?userID=${currentUserId}`);

    return response.data;
  } catch (error) {
    return {
      user: null,
      doctorId: "",
    };
  }
}
export async function createVenesectionTreatment(
  payload: CreateVenesectionTreatmentPayload
) {
  try {
    const response = await n8nClient.post<{
      data: { status: string; url: string };
    }>(`/venesection-treatment`, payload);

    return response.data.data.url;
  } catch (error) {
    return "";
  }
}
