import React from "react";
import clsx from "clsx";
import { BoxProps, Box, makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  wrapper: {
    flex: 1,
    position: "relative",
    width: "100%",
  },
  inner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
  },
}));

interface Props extends BoxProps {
  outerDivProps?: BoxProps;
}

export const OverflowWrapper: React.FC<Props> = ({
  outerDivProps,
  ...innerDivProps
}) => {
  const { className: outerClassName, ...outerProps } = outerDivProps || {};
  const { className: innerClassName, ...innerProps } = innerDivProps || {};
  const classes = useStyle();
  return (
    <Box className={clsx(classes.wrapper, outerClassName)} {...outerProps}>
      <Box className={clsx(classes.inner, innerClassName)} {...innerProps} />
    </Box>
  );
};
