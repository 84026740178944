import { TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { getFieldName, getValueFromObject, sortAlphabetically } from "helpers";
import React, { useCallback, useEffect, useMemo } from "react";
import { AutoCompleteOption } from "types";
import { OtherFieldsProductDetails } from "./OtherFields";

export interface ProductSelectProps {
  name: string;
  products: OtherFieldsProductDetails[];
}

export const ProductSelect = ({ products, name }: ProductSelectProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("product", name), [name]);

  const fieldTouched = useMemo(
    () => getValueFromObject(fieldName, touched) === true,
    [fieldName, touched]
  );

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const fieldValue = useMemo(() => getValueFromObject(fieldName, values), [
    fieldName,
    values,
  ]);

  const options = useMemo(() => {
    return sortAlphabetically(
      products.map((product) => ({
        title: product.ProductName || "",
        value: product.ZohoCrmId || "",
        pharmacyZohoId: product.pharmacyZohoId || "",
      }))
    );
  }, [products]);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  useEffect(() => {
    setImmediate(() => {
      if (options.length === 0) {
        if (!fieldValue?.value) {
          setFieldValue(fieldName, null);
        }
      } else {
        if (!fieldValue?.value) {
          return;
        }
        const valueIsInOptions = options.some(
          (o) => o.title === fieldValue?.title
        );
        if (!valueIsInOptions) {
          setFieldValue(fieldName, null);
        }
      }
    });
  }, [options, fieldName, fieldValue, setFieldValue]);

  return (
    <Field
      required
      name={fieldName}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.value === value.value;
      }}
      disabled={!options.length}
      color="primary"
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={fieldName}
          variant="outlined"
          label="Product"
          helperText={options.length ? fieldTouched && fieldError : undefined}
          error={options.length ? fieldTouched && !!fieldError : undefined}
          size="small"
        />
      )}
    />
  );
};
