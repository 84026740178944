import { GenderEnum } from "@deep-consulting-solutions/bmh-constants";
import React, { useMemo, useCallback } from "react";
import { Formik, Form, Field, FormikConfig } from "formik";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { makeStyles, Box, IconButton, MenuItem } from "@material-ui/core";
import { Cancel as CancelIcon, CheckCircle } from "@material-ui/icons";
import { Select, TextField } from "formik-material-ui";

import { getLabelDate, formatDateForRequest, getENText } from "helpers";

import { UnmatchedHL7Field, FILE_FIELDS_META } from "../Unmatched.types";
import { isFieldValueChanged } from "../Unmatched.helpers";

const useStyle = makeStyles(({ spacing: s, breakpoints: b, palette: p }) => ({
  wrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  actions: {
    marginLeft: "auto",
    display: "flex",
  },
  cancel: {
    marginLeft: s(1),
  },
  ok: {
    marginLeft: s(1),
    marginRight: s(1),
    color: p.success.main,
  },
  field: {
    width: "250px",
    [b.only("md")]: {
      width: "230px",
    },
  },
}));

interface FormValues {
  value: string | Date;
}

interface ManualEditFormProps {
  field: UnmatchedHL7Field;
  value: string;
  onCancel: () => any;
  onOK: (value: string) => any;
}

const ManualEditForm: React.FC<ManualEditFormProps> = ({
  field,
  value,
  onCancel,
  onOK,
}) => {
  const { type, updatedValue } = useMemo(() => {
    if (field === "sampleCollectedOn" || field === "dob") {
      return {
        type: "date",
        updatedValue: getLabelDate(new Date(value)),
      };
    }

    if (field === "gender") {
      return {
        type: "gender",
      };
    }

    return {
      type: "text",
    };
  }, [field, value]);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values) => {
      const { value: v } = values;
      if (typeof v === "string") {
        return onOK(v);
      }

      return onOK(formatDateForRequest(v));
    },
    [onOK]
  );

  const classes = useStyle();
  return (
    <Formik
      initialValues={{
        value: updatedValue || value,
      }}
      onSubmit={onFormSubmit}
    >
      {({ isValid, values }) => {
        const isChanged = isFieldValueChanged(values.value, value);
        return (
          <Form className={classes.wrapper}>
            <Box className={classes.field}>
              {type === "date" && (
                <Field
                  name="value"
                  component={FormikDatePicker}
                  maxDate={new Date()}
                  maxDateMessage={getENText("unmatched.date.todayMax")}
                  disablePast={false}
                  disableFuture
                  disableToolbar={false}
                  disabled={false}
                  autoFocus
                  invalidDateMessage=""
                />
              )}

              {type === "gender" && (
                <Field name="value" component={Select}>
                  {Object.values(GenderEnum).map((g) => {
                    return (
                      <MenuItem key={g} value={g}>
                        {g}
                      </MenuItem>
                    );
                  })}
                </Field>
              )}

              {type === "text" && (
                <Field
                  name="value"
                  component={TextField}
                  placeholder={`${FILE_FIELDS_META[field]?.name ?? ""}...`}
                  autoFocus
                />
              )}
            </Box>

            <Box className={classes.actions}>
              <IconButton className={classes.cancel} onClick={onCancel}>
                <CancelIcon />
              </IconButton>

              <IconButton
                className={classes.ok}
                disabled={!isValid || !values.value || !isChanged}
                type="submit"
                data-testid="manual-edit-submit"
              >
                <CheckCircle />
              </IconButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ManualEditForm;
