import { apiClient, n8nApiClient } from "apis";

export const fetchS3SignedUrlForDownload = async (s3Key: string) => {
  const res = await n8nApiClient.post("/crm/generate-signed-url", {
    resourceType: "documents",
    operation: "download",
    s3Key,
  });
  return res.data.data;
};

const makeQueryString = (obj: Record<string, boolean>) => {
  const res: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (value === true) {
      res.push(key);
    }
  });

  return res.join(",");
};

interface DocumentParams {
  id: string;
  page: number;
  search: string;
  statusFilters: any;
  typeFilters: any;
  ownershipFilters: any;
  size: number;
  subscription?: string;
  entity: string;
}

const entities: Record<string, any> = {
  CTTPs: "cttp",
  Treatments: "treatment",
  Prescriptions: "prescription",
  Contacts: "client",
};

export const fetchDocuments = async ({
  id,
  size,
  page,
  statusFilters,
  typeFilters,
  search,
  entity,
  ownershipFilters,
}: DocumentParams) => {
  const statusFiltersString = makeQueryString(statusFilters);
  const ownershipFiltersString = makeQueryString(ownershipFilters);
  const typeFiltersString = makeQueryString(typeFilters);

  const url = `/documents/document-widget?page=${page || 1}&perPage=${
    size || 10
  }&recordType=${entities[entity]}&recordId=${id}${
    statusFiltersString ? `&documentStatus=${statusFiltersString}` : ""
  }${
    ownershipFiltersString ? `&documentOwnership=${ownershipFiltersString}` : ""
  }${typeFiltersString ? `&documentType=${typeFiltersString}` : ""}${
    search ? `&search=${search}` : ""
  }`;

  const res = await apiClient.get(url);

  return res.data.data;
};

export const fetchDocumentsLegacy = async ({
  id,
  size,
  page,
  statusFilters,
  typeFilters,
  search,
  entity,
  ownershipFilters,
}: DocumentParams) => {
  const statusFiltersString = makeQueryString(statusFilters);
  const ownershipFiltersString = makeQueryString(ownershipFilters);
  const typeFiltersString = makeQueryString(typeFilters);

  const url = `/document-widget?page=${page || 1}&perPage=${
    size || 10
  }&recordType=${entities[entity]}&recordId=${id}${
    statusFiltersString ? `&documentStatus=${statusFiltersString}` : ""
  }${
    ownershipFiltersString ? `&documentOwnership=${ownershipFiltersString}` : ""
  }${typeFiltersString ? `&documentType=${typeFiltersString}` : ""}${
    search ? `&search=${search}` : ""
  }`;

  const res = await n8nApiClient.get(url);

  return res.data;
};
