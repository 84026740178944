import { Alert, AlertTitle } from "@material-ui/lab";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { notifications, treatmentServices, zohoServices } from "services";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { AxiosError } from "axios";
import Loader from "components/Loader";
import { ZohoBMHDoctor, ZohoTreatment } from "types";
import { Field, Formik, FormikConfig } from "formik";
import { clearSavedDefaultValues, getSavedDefaultValues } from "helpers";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { TextField } from "formik-material-ui";
import { RequestChangesActions } from "./RequestChangesActions";

export const RequestChanges = () => {
  const dispatch = useDispatch();
  const [id] = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [isFetchedAllData, setIsFetchedAllData] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [error, setError] = useState("");
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);
  const [user, setUser] = useState<{ id: string } | null>(null);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }

    if (error) {
      return "error";
    }

    return "form";
  }, [fetchError, error]);

  const initialValues = useMemo(() => {
    if (!isFetchedAllData) {
      return {
        requestedChanges: "",
      };
    }
    const savedDefaultValue = getSavedDefaultValues(
      "request-changes-treatment",
      id,
      []
    );
    setImmediate(() => {
      setSavedDefaultValues(savedDefaultValue);
    });
    return {
      requestedChanges: "",
    };
  }, [id, isFetchedAllData]);

  const validationSchema = useMemo(() => {
    if (!isFetchedAllData) {
      return null;
    }
    return Yup.object({
      requestedChanges: Yup.string()
        .required("Reason for changes is required")
        .typeError("Reason for changes is required"),
    });
  }, [isFetchedAllData]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [treatmentRes, currentUserRes] = await Promise.all([
        zohoServices.getRecord({ Entity: "Treatments", RecordID: id }),
        dispatch(zohoActions.fetchCurrentUser()),
      ]);
      const treatment = treatmentRes as ZohoTreatment;
      const currentUserId = (currentUserRes as any)?.payload.id as string;

      if (treatment.Owner.id !== currentUserId) {
        const doctorRes = await zohoServices.getRecord({
          Entity: "BMH_Doctors",
          RecordID: treatmentRes.Doctor.id,
        });
        const doctor = doctorRes as ZohoBMHDoctor;
        if (doctor.User.id !== currentUserId) {
          setError("You are not allowed to make changes to this treatment");
          return;
        }
      }
      setUser((currentUserRes as any)?.payload);

      setIsFetchedAllData(true);
    } catch (e) {
      const err = e as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  const submitDetails = useCallback(
    async (values: any) => {
      if (!id) {
        return;
      }

      try {
        setLoading(true);
        const payload = {
          treatmentId: id,
          treatment: [
            {
              requestedChanges: values.requestedChanges,
              userId: user?.id,
            },
          ],
        };
        await treatmentServices.requestChangesForTreatment(payload);
        clearSavedDefaultValues("request-changes-treatment", id);
        zohoServices.closePopup(true);
      } catch (e) {
        const err = e as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [user, id]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      await submitDetails(values);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <TreatmentWidgetLayout defaultWidth={500}>
        <TreatmentWidgetTitle title="Request Changes" />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          <DefaultValuesStore
            widgetName="request-changes-treatment"
            identifier={id}
          />
          {view === "error" ? <Alert severity="warning">{error}</Alert> : null}
          {savedDefaultValues && isFetchedAllData ? (
            <SetInitialValues savedDefaultValues={savedDefaultValues} />
          ) : null}
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}
          {view === "form" ? (
            <Field
              component={TextField}
              name="requestedChanges"
              label="Reason for changes"
              size="small"
              multiline
              rows={3}
              fullWidth
            />
          ) : null}
        </TreatmentWidgetContent>
        <TreatmentWidgetActions>
          <RequestChangesActions view={view} onSubmit={submitDetails} />
        </TreatmentWidgetActions>
      </TreatmentWidgetLayout>
    </Formik>
  );
};
