import { Box, TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import {
  getDoctorName,
  getFieldName,
  getValueFromObject,
  sortAlphabetically,
} from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption, ZohoBMHDoctor } from "types";

interface Props {
  doctors: ZohoBMHDoctor[];
  name?: string;
  showLabel?: boolean;
}

export const DoctorAutoCompleteField = ({
  doctors,
  name,
  showLabel = true,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("doctor", name), [name]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(
    () =>
      sortAlphabetically(
        doctors.map((doctor) => ({
          title: getDoctorName(doctor),
          value: doctor.id,
        }))
      ),
    [doctors]
  );

  const onChange = useCallback(
    (_: any, value: ZohoBMHDoctor, reason: AutocompleteChangeReason) => {
      if (reason === "select-option" && value) {
        setFieldValue(fieldName, value);
      }
      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Box mb={2} maxWidth={500}>
      <Field
        required
        name={fieldName}
        component={Autocomplete}
        options={options}
        getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
        onChange={onChange}
        onBlur={() => {
          setFieldTouched(fieldName, true);
        }}
        getOptionSelected={(option: any, value: any) => {
          return option.value === value.value && option.title === value.title;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            required
            name={fieldName}
            variant="outlined"
            label={showLabel ? "Doctor" : ""}
            helperText={fieldTouched && fieldError}
            error={fieldTouched && !!fieldError}
            size="small"
          />
        )}
      />
    </Box>
  );
};
