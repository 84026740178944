import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import { useSelector } from "react-redux";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { cancelTreatmentOrderValidationSchema } from "helpers";
import { Alert } from "@material-ui/lab";
import {
  cancelTreatmentOrder,
  getPharmacy,
  getTreatmentOrder,
} from "services/treatment-orders";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { InitialValues, Pharmacy, TreatmentOrder } from "./types";
import { outOfProgressStates } from "./constants";
import { CancelTreatmentOrderForm } from "./CancelTreatmentOrderForm";
import { ActionButtons } from "./ActionButtons";

export const CancelTreatmentOrder = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalidState, setInvalidState] = useState(false);
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);
  const [treatmentOrder, setTreatmentOrder] = useState<TreatmentOrder | null>(
    null
  );
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);

  const initialValues = {
    reason: "",
    shouldCreditOrRefund: false,
  };

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          treatmentOrderId,
          cancellationReason: values.reason,
          creditOrRefundOrder: values.shouldCreditOrRefund,
        };
        await cancelTreatmentOrder(payload);
      } catch (error) {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentOrderId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const treatmentOrderRes = await getTreatmentOrder(treatmentOrderId);
      setTreatmentOrder(treatmentOrderRes);

      if (outOfProgressStates.includes(treatmentOrderRes?.State || "")) {
        setInvalidState(true);
        return;
      }

      if (treatmentOrderRes) {
        const pharmacyRes = await getPharmacy(treatmentOrderRes.PharmacyId);
        setPharmacy(pharmacyRes);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={cancelTreatmentOrderValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle title="Cancel Treatment Order" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!loading && invalidState && (
                <>
                  <Alert severity="warning">
                    Treatment order in {`"${treatmentOrder?.State}"`} state
                    cannot be cancelled.
                  </Alert>
                </>
              )}
              {!loading && !invalidState && (
                <>
                  <CancelTreatmentOrderForm
                    treatmentOrder={treatmentOrder}
                    pharmacy={pharmacy}
                  />
                </>
              )}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons
              closeWidget={closeWidget}
              invalidState={invalidState}
            />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
