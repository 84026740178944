import React, { memo, useCallback } from "react";
import { makeStyles, TableRow, Button } from "@material-ui/core";
import { BloodTestResultItem } from "@deep-consulting-solutions/bmh-constants";

import { TableCell } from "../../TestResults.styles";
import WarningCell from "../../WarningCell";
import { TABLE_COLUMN_COUNT } from "./OBXTable.types";

interface StyleProps {
  showHistory: boolean;
}

const useStyle = makeStyles(({ typography: typo, palette: p }) => ({
  note: {
    ...typo.body2,
    color: p.grey[500],
  },
  historyBtn: ({ showHistory }: StyleProps) => ({
    opacity: showHistory ? "100%" : "0%",
  }),
}));

interface OBXTableItemProps {
  item: BloodTestResultItem;
  hasWarning: boolean;
  goToHistory: (obx: BloodTestResultItem) => any;
}

const OBXTableItem: React.FC<OBXTableItemProps> = ({
  item,
  hasWarning,
  goToHistory,
}) => {
  const showHistory = !!item.historicalCount && item.historicalCount > 1;
  const classes = useStyle({
    showHistory,
  });

  const onHistoryClick = useCallback(() => {
    goToHistory(item);
  }, [goToHistory, item]);

  return (
    <>
      <TableRow>
        {hasWarning && (
          <WarningCell abnormal={item.abnormalFlag} isFailed={item.isFailed} />
        )}
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.value}</TableCell>
        <TableCell>{item.normalRange}</TableCell>
        <TableCell>{item.unit}</TableCell>
        <TableCell>
          {item.abnormalFlag?.meaning || item.abnormalFlag?.flag || ""}
        </TableCell>
        <TableCell>
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={onHistoryClick}
            disabled={!showHistory}
            className={classes.historyBtn}
          >
            HISTORY
          </Button>
        </TableCell>
      </TableRow>
      {!!item.notes && (
        <TableRow>
          {hasWarning && <TableCell />}
          <TableCell colSpan={TABLE_COLUMN_COUNT} className={classes.note}>
            {item.notes
              .split("\n")
              .filter((text) => !!text)
              .map((note, index, arr) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <span>{note}</span>
                    {index < arr.length - 1 && <br />}
                  </React.Fragment>
                );
              })}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(OBXTableItem);
