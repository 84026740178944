import React from "react";
import { getIn, useFormikContext } from "formik";
import { Box, Tooltip, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Alert } from "@material-ui/lab";
import { CustomCheckboxField } from "components/CustomCheckboxField";
import { CustomDatePicker } from "components/CustomDatePicker";
import { TreatmentOrderItem } from "./OrderItem";
import { InitialValues } from "../types";
import { OrderStatus } from "./OrderStatus";

export const TreatmentOrderTable = () => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <Box p={2}>
      <Typography
        component="h3"
        color="primary"
        style={{
          fontWeight: "bold",
          fontSize: "large",
          marginBottom: "8px",
        }}
      >
        Treatment Orders
      </Typography>
      <>
        {values.billingData.map((b, billingDataIndex) => (
          <Accordion
            key={b.billingDate}
            defaultExpanded
            style={{ padding: "8px 0" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  component="h3"
                  style={{
                    color: "#6B7280",
                    fontWeight: "bold",
                  }}
                >
                  {`Billing Date: ${b.formattedBillingDate}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              {b.treatmentOrders &&
                b.treatmentOrders.map((i, treatmentOrderIndex) => {
                  const isPharmacyOrder = i.Type === "Pharmacy";
                  const isUnavailableOrSentToPharmacy = [
                    "Pending Resolving Medication Unavailability",
                    "Sent to Pharmacy",
                  ].includes(i.State);
                  const pharmacyName = i.Pharmacy.Name || "";
                  const isDetailsSpecified = i.State === "Details Specified";
                  const orderTitle = isPharmacyOrder
                    ? `${i.Name} - ${pharmacyName}`
                    : "Prescription Order";
                  const basePath = `billingData.${billingDataIndex}.treatmentOrders.${treatmentOrderIndex}`;
                  const tooltipTitle = (
                    <Box>
                      <Typography style={{ fontSize: "12px" }}>
                        Some medicines are not available at the pharmacy and
                        they cannot be shipped to the client. You can decide to:
                      </Typography>
                      <Box style={{ padding: "8px" }}>
                        <Typography
                          style={{ fontSize: "12px", marginBottom: "4px" }}
                        >
                          1. Delay the whole order until items become available.
                          Call the client and confirm with them if they have
                          enough medicine left to wait for this delivery before
                          choosing this option.
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          2. Cancel the order and pause the treatment and create
                          a new treatment with different medicines.
                        </Typography>
                      </Box>
                    </Box>
                  );
                  return (
                    <Box
                      pb={1}
                      border={1}
                      borderColor="rgb(229, 231, 235)"
                      overflow="auto"
                      width="100%"
                      key={i.id}
                    >
                      <Accordion elevation={0} square>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="nowrap"
                            style={{ gap: 1 }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gridColumnGap="16px"
                            >
                              <Typography
                                variant="body1"
                                component="p"
                                style={{
                                  fontWeight: "bold",
                                  color: "#4C6890",
                                }}
                              >
                                {orderTitle}
                              </Typography>
                              {isPharmacyOrder &&
                                isUnavailableOrSentToPharmacy && (
                                  <Tooltip title={tooltipTitle} arrow>
                                    <InfoOutlinedIcon color="primary" />
                                  </Tooltip>
                                )}
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              style={{ columnGap: "16px", rowGap: "8px" }}
                            >
                              <OrderStatus data={i} />
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexDirection: "column" }}>
                          <Box display="flex" gridColumnGap="32px" pb={2}>
                            <CustomDatePicker
                              label="Order Date"
                              name={`${basePath}.Order_Date`}
                              disablePast
                              disabled={isPharmacyOrder && !isDetailsSpecified}
                            />
                            {isPharmacyOrder && (
                              <CustomCheckboxField
                                name={`${basePath}.Urgent`}
                                label="Urgent"
                              />
                            )}
                            {isPharmacyOrder &&
                              isUnavailableOrSentToPharmacy && (
                                <CustomCheckboxField
                                  name={`${basePath}.lateDispensing`}
                                  label="Late Dispensing"
                                />
                              )}
                          </Box>
                          {isPharmacyOrder &&
                            isUnavailableOrSentToPharmacy &&
                            getIn(values, `${basePath}.lateDispensing`) && (
                              <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="16px"
                                pb={2}
                              >
                                <CustomDatePicker
                                  label="Late Dispensing Date"
                                  name={`${basePath}.Late_Dispensing_Date`}
                                  disablePast
                                />
                                <Alert severity="warning">
                                  Call the client and make sure the client has
                                  enough medicine to wait till the delivery
                                  date.
                                </Alert>
                              </Box>
                            )}
                          {i.treatmentOrderItems.map((t) => (
                            <TreatmentOrderItem key={t.id} data={t} />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </Box>
  );
};
