import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AutoCompleteOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoMedicineType,
  ZohoTreatmentArea,
} from "types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { MedicineForm } from "../MedicineForm";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    background: p.grey[100],
    borderRadius: spacing(0.5),
  },
  marginBottom: {
    marginBottom: spacing(2),
  },
}));

interface Props {
  name: string;
  treatmentArea: ZohoTreatmentArea;
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
  isLast: boolean;
  medicineSelectedValues: AutoCompleteOption;
  index: number;
}

export const MedicineFields = ({
  name,
  treatmentArea,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
  medicineSelectedValues,
  isLast,
  index,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();

  const medicineTypes = useMemo(() => treatmentArea.medicineType, [
    treatmentArea.medicineType,
  ]);

  const medicineTypesById = useMemo(() => {
    return medicineTypes.reduce<Record<string, ZohoMedicineType>>(
      (total, current) => {
        return { ...total, [current.id]: current };
      },
      {}
    );
  }, [medicineTypes]);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      if (divRef.current && divRef.current.contains(event.target as Node)) {
        setExpanded(true);
      }
    };
    document.addEventListener("focus", handleFocusIn);
    return () => {
      document.addEventListener("focus", handleFocusIn);
    };
  }, []);

  return (
    <Accordion
      className={clsx(
        classes.wrapper,
        isLast ? undefined : classes.marginBottom
      )}
      key={`${medicineSelectedValues.value}-${medicineSelectedValues.title}`}
      expanded={expanded}
      onChange={(_, e) => {
        setExpanded(e);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{medicineSelectedValues.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div ref={divRef} style={{ width: "100%" }}>
          <MedicineForm
            name={`${name}.${index}`}
            medicineType={medicineTypesById[medicineSelectedValues.value]}
            dosingTypes={dosingTypes}
            dosingAdminRoutes={dosingAdminRoutes}
            medicineAdminRoutes={medicineAdminRoutes}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
