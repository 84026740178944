import React, { FC, useState, useCallback, useEffect } from "react";

import {
  Container,
  makeStyles,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { useWidgetResize } from "hooks";
import { notifications, zohoServices } from "services";
import Loader from "components/Loader";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import { zohoSelectors } from "redux/zoho";
import { testProfileRequests } from "redux/testProfile";

enum TestProfileStatusEnum {
  CONFIRM,
  SUCCESS,
  FAILURE,
}

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    position: "relative",
  },
  section: {
    margin: s(2),
  },
  actions: {
    marginTop: s(2),
    marginBottom: s(1),
    textAlign: "right",
    "& > * + *": {
      marginLeft: s(1),
    },
  },
}));

export const TestProfileDelete: FC = () => {
  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const ids = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    responseCode: number;
    message: string;
  } | null>(null);
  const [testProfileStatus, setTestProfileStatus] = useState(
    TestProfileStatusEnum.CONFIRM
  );

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleDeleteTestProfile = useCallback(async () => {
    try {
      setLoading(true);
      await testProfileRequests.deleteTestProfile(ids[0]);
      setTestProfileStatus(TestProfileStatusEnum.SUCCESS);
    } catch (err) {
      if (err.request.status !== 409) {
        notifications.notifyError(err.message);
      }
      setError({
        responseCode: err.request.status,
        message: err.message,
      });
      setTestProfileStatus(TestProfileStatusEnum.FAILURE);
    } finally {
      setLoading(false);
    }
  }, [ids]);

  useEffect(() => {
    resize();
  }, [resize]);

  const classes = useStyles();

  return (
    <WidgetButtonWrapper className={classes.root}>
      <Loader open={loading} />
      <Container innerRef={wrapperRef}>
        {testProfileStatus === TestProfileStatusEnum.CONFIRM ? (
          <>
            <Box className={classes.section}>
              <Typography variant="body1" color="textSecondary">
                Are you sure, delete test profile?
              </Typography>
            </Box>
            <Box className={classes.actions}>
              <Button
                variant="text"
                color="primary"
                onClick={() => closeWidget()}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteTestProfile}
              >
                Confirm
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.section}>
              <Typography variant="body1" color="textSecondary">
                {testProfileStatus === TestProfileStatusEnum.SUCCESS
                  ? "Test Profile deleted successfully"
                  : (error?.responseCode === 409 &&
                      "Test profile cannot be deleted as it is connected to pathway(s)") ||
                    "Something went wrong"}
              </Typography>
            </Box>
            <Box className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  closeWidget(
                    testProfileStatus === TestProfileStatusEnum.SUCCESS
                  )
                }
              >
                Close
              </Button>
            </Box>
          </>
        )}
      </Container>
    </WidgetButtonWrapper>
  );
};
