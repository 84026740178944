import React, { memo } from "react";
import { makeStyles, TableRow } from "@material-ui/core";
import { BloodTestResultItem } from "@deep-consulting-solutions/bmh-constants";

import { TableCell } from "../../TestResults.styles";

import { TABLE_COLUMN_COUNT } from "./OBXTable.types";
import OBXTableItem from "./OBXTableItem";

const useStyle = makeStyles(({ typography: typo, palette: p }) => ({
  identifier: {
    ...typo.h6,
    background: p.grey[50],
  },
}));

interface OBXTableGroupProps {
  identifier: string;
  items: BloodTestResultItem[];
  hasWarning: boolean;
  goToHistory: (obx: BloodTestResultItem) => any;
}

const OBXTableGroup: React.FC<OBXTableGroupProps> = ({
  identifier,
  items,
  hasWarning,
  goToHistory,
}) => {
  const classes = useStyle();
  return (
    <>
      <TableRow>
        {!!identifier && (
          <>
            {hasWarning && <TableCell className={classes.identifier} />}
            <TableCell
              colSpan={TABLE_COLUMN_COUNT}
              className={classes.identifier}
            >
              {identifier}
            </TableCell>
          </>
        )}
      </TableRow>
      {items.map((item) => {
        return (
          <OBXTableItem
            key={item.id}
            item={item}
            hasWarning={hasWarning}
            goToHistory={goToHistory}
          />
        );
      })}
    </>
  );
};

export default memo(OBXTableGroup);
