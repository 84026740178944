import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  MenuItem,
  styled,
  TableCell,
  TableCellProps,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Column, Data } from "types";
import { StyledChip } from "components/StyledChip";
import { isBoolean, isNumber } from "lodash";
import moment from "moment";

const getCellPadding = (data: any, editing: boolean) => {
  if (isBoolean(data)) return "3px";
  if (editing) return 0;
  return undefined;
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& > .MuiFormHelperText-root": {
    margin: theme.spacing(0.5),
  },
}));

interface EditableCellProps<T extends Data> extends TableCellProps {
  row: T;
  editing: boolean;
  data: any;
  dataKey: Column<T>["key"];
  width?: number;
  onCellChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    dataKey: Column<T>["key"]
  ) => void;
  setHasError: (dataKey: Column<T>["key"], hasError: boolean) => void;
  validate: (
    row: T,
    val: any,
    dataKey: Column<T>["key"],
    required: boolean
  ) => {
    error: boolean;
    helperText: string;
  };
  textFieldProps: {
    placeholder: string;
  } & Omit<TextFieldProps, "placeholder">;
  shouldHighlight?: boolean;
  isNew?: boolean;
  type?: string;
  required: boolean;
  select?: boolean;
  options?: string[];
}

function EditableCell<T extends Data>({
  row,
  editing,
  data,
  dataKey,
  width,
  onCellChange,
  setHasError,
  textFieldProps: {
    autoFocus,
    error: textFieldError,
    placeholder,
    ...textFieldProps
  },
  validate,
  shouldHighlight,
  isNew,
  required,
  type,
  select = false,
  options,
  ...rest
}: EditableCellProps<T>) {
  const [initialData, setInitialData] = useState(data);
  useEffect(() => {
    if (shouldHighlight === false) {
      setInitialData(data);
    }
  }, [data, shouldHighlight]);

  const [touched, setTouched] = useState(false);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isNumber(initialData)) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
      onCellChange(e, dataKey);
      setTouched(true);
    },
    [dataKey, initialData, onCellChange]
  );
  const formattedData = useMemo(
    () => (type === "date" ? moment(data).format("DD/MM/YYYY") : data),
    [data, type]
  );

  const { error, helperText } = useMemo(
    () => validate(row, data, dataKey, required),
    [row, data, dataKey, validate, required]
  );

  useEffect(() => {
    setHasError(dataKey, error || !!textFieldError);
  }, [dataKey, error, setHasError, textFieldError]);

  const onBlur = useCallback(() => {
    setTouched(true);
  }, []);

  const showTextField = useMemo(
    () => !isBoolean(initialData) && (editing || (!!isNew && !initialData)),
    [initialData, editing, isNew]
  );

  useEffect(() => {
    if (!showTextField) {
      setTouched(false);
    }
  }, [showTextField]);

  return (
    <TableCell
      style={{
        paddingTop: getCellPadding(data, showTextField),
        paddingBottom: getCellPadding(data, showTextField),
        width,
      }}
      {...rest}
    >
      {isBoolean(data) && (
        <StyledCheckbox
          checked={data}
          disabled={!editing}
          onChange={onChange}
          inputProps={{ "aria-label": placeholder }}
        />
      )}

      {showTextField && (
        <StyledTextField
          autoFocus={autoFocus || (!editing && !!isNew && !initialData)}
          onBlur={onBlur}
          type={type || "text"}
          value={data || (isNumber(data) ? data : "")}
          onChange={onChange}
          margin="dense"
          color="secondary"
          select={select}
          error={touched && error}
          helperText={touched && helperText}
          placeholder={placeholder}
          {...textFieldProps}
          InputProps={{
            style: {
              fontSize: "inherit",
            },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            style: {
              padding: "10.5px 8px",
            },
          }}
        >
          {options
            ? options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))
            : null}
        </StyledTextField>
      )}
      {!editing &&
        shouldHighlight &&
        !isBoolean(initialData) &&
        initialData && <StyledChip color="success">{formattedData}</StyledChip>}
      {!editing && !shouldHighlight && !isBoolean(initialData) && formattedData}
    </TableCell>
  );
}

export default memo(EditableCell) as typeof EditableCell;
