import React from "react";
import { Container, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
}));

interface Props {
  message: string;
}

export const InfoMessage: React.FC<Props> = ({ message }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h5" color="primary">
        {message}
      </Typography>
    </Container>
  );
};
