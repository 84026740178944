import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  Grid,
  TextField as MuiTextField,
  InputAdornment,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { getIn, Field } from "formik";
import { TextField } from "formik-material-ui";
import { FormikCountrySelectInput } from "@deep-consulting-solutions/dcs-web-ui";
import { Search } from "@material-ui/icons";

import { useScript } from "hooks";

import { getCountryCode } from "helpers";
import {
  getLongAddressObject,
  getShortAddressObject,
  statusMessage,
  DEFAULT_COORDS,
  ShippingAddressFormValues,
} from "./helpers";

(window as any).dummyPlacesLoaded = () => {};

export const ShippingAddressFormByCountry = <
  FormValues extends Record<string, any>
>({
  formikProps: {
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
  },
  streetName = "street",
  cityName = "city",
  regionName = "region",
  otherName = "other",
  zipName = "zip",
  countryName = "country",
  coordinations = DEFAULT_COORDS,
  disabledFields = {},
  customFieldErrors = {},
}: ShippingAddressFormValues<FormValues>) => {
  const googleStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=dummyPlacesLoaded`
  );
  const fieldError: string | undefined = useMemo(
    () => getIn(errors, streetName) || customFieldErrors[streetName],
    [errors, streetName, customFieldErrors]
  );
  const showFieldError: boolean = useMemo(
    () => getIn(touched, streetName) && !!fieldError,
    [touched, streetName, fieldError]
  );

  const countryFieldError: string | undefined = useMemo(
    () => getIn(errors, countryName) || customFieldErrors[countryName],
    [errors, countryName, customFieldErrors]
  );
  const showCountryFieldError: boolean = useMemo(
    () => getIn(touched, countryName) && !!countryFieldError,
    [touched, countryName, countryFieldError]
  );

  const cityFieldError: string | undefined = useMemo(
    () => getIn(errors, cityName) || customFieldErrors[cityName],
    [errors, cityName, customFieldErrors]
  );
  const showCityFieldError: boolean = useMemo(
    () => getIn(touched, cityName) && !!cityFieldError,
    [touched, cityName, cityFieldError]
  );

  const zipFieldError: string | undefined = useMemo(
    () => getIn(errors, zipName) || customFieldErrors[zipName],
    [errors, zipName, customFieldErrors]
  );
  const showZipFieldError: boolean = useMemo(
    () => getIn(touched, zipName) && !!zipFieldError,
    [touched, zipName, zipFieldError]
  );

  const regionFieldError: string | undefined = useMemo(
    () => getIn(errors, regionName) || customFieldErrors[regionName],
    [errors, regionName, customFieldErrors]
  );

  const showRegionFieldError: boolean = useMemo(
    () => getIn(touched, regionName) && !!regionFieldError,
    [touched, regionName, regionFieldError]
  );

  const otherFieldError: string | undefined = useMemo(
    () => getIn(errors, otherName) || customFieldErrors[otherName],
    [errors, otherName, customFieldErrors]
  );
  const showOtherFieldError: boolean = useMemo(
    () => getIn(touched, otherName) && !!otherFieldError,
    [touched, otherName, otherFieldError]
  );

  const fieldValue = useMemo(() => getIn(values, streetName), [
    values,
    streetName,
  ]);
  const countryValue = useMemo(() => getIn(values, countryName), [
    values,
    countryName,
  ]);

  const cityValue: string = useMemo(() => getIn(values, cityName) || "", [
    values,
    cityName,
  ]);

  const countryCode = useMemo(() => getCountryCode(countryValue), [
    countryValue,
  ]);

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  const [loadingAddress, setLoadingAddress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const {
    ready,
    init,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      location,
      componentRestrictions: countryCode
        ? {
            country: countryCode,
          }
        : undefined,
      radius: 38600000,
    },
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically,
    cache: false,
  });

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
        setError(statusMessage((err as unknown) as string));
      }
      return undefined;
    },
    [setValue]
  );

  const onAutocompleteChange = useCallback(
    async (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === "select-option") {
        const result = await handleSelect(
          (value as google.maps.places.AutocompletePrediction).description
        );
        if (result) {
          const longAddress = getLongAddressObject(result);
          const shortAddress = getShortAddressObject(result);

          const street = `${longAddress.street_number || ""}${
            longAddress.route
              ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
              : ""
          }`;
          setFieldValue("street", street);
          setValue(street, false);

          if (!disabledFields[cityName]) {
            setFieldValue(
              "city",
              longAddress.locality ||
                longAddress.administrative_area_level_3 ||
                longAddress.administrative_area_level_4 ||
                longAddress.postal_town ||
                longAddress.neighborhood ||
                longAddress.sublocality ||
                ""
            );
          }

          const regionValue =
            shortAddress.country === "GB"
              ? longAddress.administrative_area_level_2 ||
                longAddress.administrative_area_level_1
              : longAddress.administrative_area_level_1 ||
                longAddress.administrative_area_level_2;

          setFieldValue("region", regionValue || "");

          setFieldValue("zip", longAddress.postal_code || "");
        }
      } else if (reason === "clear") {
        setFieldValue("street", "");
      } else if (reason === "create-option") {
        //
      } else if (reason === "remove-option") {
        //
      } else if (reason === "blur") {
        //
      }
    },
    [handleSelect, setValue, setFieldValue, disabledFields, cityName]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = ((value as unknown) as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setError("");
      setValue(value);
      setFieldValue(streetName, value);
    },
    [streetName, setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(streetName, true);
  }, [streetName, setFieldTouched]);

  useEffect(() => {
    if (googleStatus === "ready") {
      init();
    }
  }, [init, googleStatus]);

  useEffect(() => {
    if (ready) {
      setLocation(new google.maps.LatLng(coordinations.lat, coordinations.lng));
    }
  }, [coordinations, ready]);

  const disabled = loadingAddress || isSubmitting;
  const otherFieldDisabled = !countryValue || disabled;

  const locationOptions = useMemo(() => {
    if (!disabledFields[cityName]) {
      return data;
    }
    return data.filter((d) =>
      JSON.stringify(d).toLowerCase().includes(cityValue.toLowerCase())
    );
  }, [data, disabledFields, cityName, cityValue]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          component={FormikCountrySelectInput}
          name={countryName}
          label="Shipping Country"
          disabled={disabled || !!disabledFields[countryName]}
          error={showCountryFieldError}
          helperText={countryFieldError}
          required
          useCountryCode={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete<
          google.maps.places.AutocompletePrediction,
          false,
          false,
          true
        >
          freeSolo
          noOptionsText="No Results Found"
          value={fieldValue}
          getOptionSelected={getOptionSelected}
          onChange={onAutocompleteChange}
          inputValue={fieldValue}
          onInputChange={onInputChange}
          loading={placesLoading}
          disabled={otherFieldDisabled}
          options={locationOptions}
          getOptionLabel={(
            option: google.maps.places.AutocompletePrediction | string
          ) => (typeof option === "string" ? option : option.description)}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name={streetName}
              autoComplete="off"
              onBlur={onInputBlur}
              error={showFieldError}
              helperText={showFieldError && fieldError}
              label="Shipping Street"
              required
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={cityName}
          label="Shipping City"
          disabled={otherFieldDisabled || !!disabledFields[cityName]}
          error={showCityFieldError}
          helperText={cityFieldError}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={regionName}
          label="Shipping Region"
          disabled={otherFieldDisabled}
          error={showRegionFieldError}
          helperText={regionFieldError}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={otherName}
          label="Apartment / Flat / Door / Other"
          disabled={otherFieldDisabled}
          error={showOtherFieldError}
          helperText={otherFieldError}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={zipName}
          label="Shipping Postal Code"
          disabled={otherFieldDisabled}
          error={showZipFieldError}
          helperText={zipFieldError}
          required
        />
      </Grid>
    </>
  );
};
