import React, { useCallback, useEffect, useState } from "react";
import { Formik, FormikConfig } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { pauseTreatmentValidationSchema } from "helpers";
import { pauseTreatment } from "services/treatments/pause-treatment";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { InitialValues } from "./types";
import { initialValues } from "./constants";
import { PauseTreatmentForm } from "./PauseTreatmentForm";
import { ActionButtons } from "./ActionButtons";

export const PauseTreatment = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const [submitData, setSubmitData] = useState(false);

  const resizeWidget = useCallback(() => {
    zohoServices.resizeWidget({
      width: 900,
      height: 520,
    });
  }, []);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const treatmentID = ids[0];
        const payload = {
          reasonForPausingTreatment: values.reason,
          contactClientLater: values.choice === "YES",
          notifyClientOn: moment(values.resumptionDate).format("YYYY-MM-DD"),
          treatmentID,
        };
        await pauseTreatment(payload);
      } catch (error) {
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        closeWidget(true);
        setSubmitting(false);
      }
    },
    [closeWidget, ids]
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={pauseTreatmentValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle title="Pause Treatment" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          <PauseTreatmentForm />
        </TreatmentWidgetContent>
        <TreatmentWidgetActions>
          <ActionButtons closeWidget={closeWidget} />
        </TreatmentWidgetActions>
      </TreatmentWidgetLayout>
    </Formik>
  );
};
