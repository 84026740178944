import { Box, makeStyles, Paper } from "@material-ui/core";
import Loader from "components/Loader";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { documentRequests } from "services";
import {
  DocumentStatusFilters,
  DocumentsType,
  DocumentTypeFilters,
  FiltersType,
  OwnershipFilters,
} from "./interface";
import {
  getInitialOwnershipFilters,
  getInitialStatusFilters,
  getInitialTypeFilters,
} from "./helpers";
import { Controllers } from "./Controllers";
import { Table } from "./Table";

const useStyles = makeStyles(({ spacing: s }) => {
  return {
    containerWrapper: {
      background: "white",
    },
    container: {},
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      background: "white",
      flex: 1,
    },
    paper: {
      flex: 1,
      position: "relative",
      marginTop: s(2),
      marginBottom: s(2),
      display: "flex",
      flexDirection: "column",
    },
    controller: {
      paddingTop: s(2),
    },
  };
});

export const DocumentsWidget = () => {
  const classes = useStyles();
  const [zohoId] = useSelector(zohoSelectors.getIds);
  const entity = useSelector(zohoSelectors.getEntity);

  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(200);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [documents, setDocuments] = useState<DocumentsType[]>([]);
  const [statusFilters, setStatusFilters] = useState<DocumentStatusFilters>(
    getInitialStatusFilters()
  );
  const [typeFilters, setTypeFilters] = useState<DocumentTypeFilters>(
    getInitialTypeFilters()
  );
  const [ownershipFilters, setOwnershipFilters] = useState<OwnershipFilters>(
    getInitialOwnershipFilters()
  );

  const onClearSearchAndFiltersClick = useCallback(() => {
    setPage(1);
    setSearch("");
    setStatusFilters(getInitialStatusFilters());
    setTypeFilters(getInitialTypeFilters());
    setOwnershipFilters(getInitialOwnershipFilters());
  }, []);

  const onFiltersChange = useCallback((nextStatusFilters: FiltersType) => {
    setPage(1);
    setStatusFilters(nextStatusFilters.localStatusFilters);
    setTypeFilters(nextStatusFilters.localeTypeFilters);
    setOwnershipFilters(nextStatusFilters.localeOwnershipFilters);
  }, []);

  const onSearchChange = useCallback((nextSearch: string) => {
    setPage(1);
    setSearch(nextSearch);
  }, []);

  const handleViewDocument = useCallback(async (s3Key: string) => {
    const newTab = window.open("about:blank", "_blank");
    setLoading(true);
    try {
      const {
        signedDownloadUrl,
      } = await documentRequests.fetchS3SignedUrlForDownload(s3Key);
      if (newTab) {
        newTab.location.href = signedDownloadUrl;
      }
    } catch {
      if (newTab) {
        newTab.close();
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const onChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, v: number) => {
      setPage(v);
    },
    []
  );

  const onRowsPerPageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPage(1);
      const v = Number(e.target.value);
      if (!Number.isNaN(v)) {
        setSize(v);
      }
    },
    []
  );

  const fetchDocuments = useCallback(async () => {
    if (zohoId) {
      try {
        setLoading(true);
        const res = await documentRequests.fetchDocuments({
          id: zohoId,
          page,
          size,
          search,
          statusFilters,
          typeFilters,
          entity,
          ownershipFilters,
        });

        setDocuments(res.documents);
        setTotal(res.total ?? 0);
      } catch {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [
    page,
    size,
    search,
    statusFilters,
    typeFilters,
    zohoId,
    entity,
    ownershipFilters,
  ]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.wrapper}>
        <Paper className={classes.paper} elevation={2}>
          <Loader open={loading} />
          <Controllers
            statusFilters={statusFilters}
            typeFilters={typeFilters}
            onFiltersChange={onFiltersChange}
            search={search}
            onSearchChange={onSearchChange}
            onClearSearchAndFiltersClick={onClearSearchAndFiltersClick}
            className={classes.controller}
            ownershipFilters={ownershipFilters}
          />
          <Table
            loading={loading}
            documents={documents}
            handleViewDocument={handleViewDocument}
            total={total}
            page={page}
            size={size}
            onChangePage={onChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
};
