import { useTheme, useMediaQuery } from "@material-ui/core";

export const useResponsive = () => {
  const theme = useTheme();
  const { breakpoints: b } = theme;

  return {
    theme,
    useMediaQuery,

    isXSDown: useMediaQuery(b.down("xs")),
    isXSUp: useMediaQuery(b.up("xs")),

    isSMDown: useMediaQuery(b.down("sm")),
    isSMUp: useMediaQuery(b.up("sm")),

    isMDDown: useMediaQuery(b.down("md")),
    isMDUp: useMediaQuery(b.up("md")),

    isLGDown: useMediaQuery(b.down("lg")),
    isLGUp: useMediaQuery(b.up("lg")),

    isXLDown: useMediaQuery(b.down("xl")),
    isXLUp: useMediaQuery(b.up("xl")),
  };
};
