import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { getFieldName, isStringEqual } from "helpers";
import React from "react";
import {
  FinalizeMatchingProductMedicine,
  ZohoMedUsageUnit,
  ZohoProduct,
} from "types";
import { ProductsList } from "./ProductsList";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
}));

interface Props {
  matchingProduct: FinalizeMatchingProductMedicine;
  name?: string;
  productsUrl: string;
  bacteriostaticProducts: ZohoProduct[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isMatchingProductVisible: boolean;
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean;
  matchingProductsLength: number;
}

export const MatchingProductForms = ({
  matchingProduct,
  name = "",
  productsUrl,
  bacteriostaticProducts,
  medUsageUnits,
  isMatchingProductVisible,
  isSelectedMatchingProductsDefaultBillingCyclesFailed,
  matchingProductsLength,
}: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={2}>
        <Typography className={classes.text}>
          <b>
            {isStringEqual(matchingProduct.Medicine.name, "other")
              ? matchingProduct.Other_Medicine_Type
              : matchingProduct.Medicine.name}
          </b>{" "}
          (
          {isStringEqual(
            matchingProduct.treatmentArea?.Treatment_Area?.name,
            "other"
          )
            ? matchingProduct.treatmentArea?.Other_Treatment_Area
            : matchingProduct.treatmentArea?.Treatment_Area?.name}
          )
        </Typography>
      </Box>
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={getFieldName("usageNotes", name)}
              label="Usage Notes"
              size="small"
              multiline
              rows={3}
              fullWidth
              helperText="Usage Notes are displayed below the dosing in the prescription"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={`${name}.notesForMedicineSelection`}
              label="Notes for Medicine Selection"
              size="small"
              multiline
              rows={3}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      {matchingProduct.products ? (
        <ProductsList
          products={matchingProduct.products}
          name={getFieldName("products", name)}
          productsUrl={productsUrl}
          treatmentTreatmentArea={matchingProduct.treatmentArea}
          bacteriostaticProducts={bacteriostaticProducts}
          productFieldName={getFieldName("selectedProduct", name)}
          medUsageUnits={medUsageUnits}
          isSelectedMatchingProductsDefaultBillingCyclesFailed={
            isSelectedMatchingProductsDefaultBillingCyclesFailed
          }
          isMatchingProductVisible={isMatchingProductVisible}
          matchingProductsLength={matchingProductsLength}
        />
      ) : null}
    </Box>
  );
};
