import {
  Box,
  Button,
  Collapse,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useCallback, useState } from "react";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  matchingPackagesLength: number;
  matchingProductsLength: number;
  clientMobile?: string;
  clientMobile2?: string;
}

const FinalizeTreatmentInfoText = ({
  matchingPackagesLength,
  matchingProductsLength,
}: Pick<Props, "matchingPackagesLength" | "matchingProductsLength">) => {
  if (matchingPackagesLength) {
    return (
      <>
        Unless another preference has been indicated by the client, choose the
        option that&apos;s closest to monthly billing cycle. If a monthly option
        is not available, choose an every-two-months option. Make sure to check
        the client notes if you are uncertain which option should be selected -
        the client may have indicated their preference earlier to their PCM or
        Doctor.
        <br />
        <br />
        By default, injection bundles and other supplementary items should be
        supplied by BMH. Only select Self-Supply for any items if the client has
        explicitly asked for it. Validate that the Dose Amounts for injections
        can be measured with a syringe in practice. If not, the Doctor may need
        to change the dosing slightly.
        <br />
        <br />
        If you want to make changes to any of the items or cannot use any of the
        calculated package options, use the &quot;Select from all Matching
        Products&quot; tab where you can create a completely new package by
        choosing items and adding dosing specifications manually.
        <br />
        <br />
        If you need to confirm the package selection with the client, always aim
        for a phone call instead of arranging these details in email. Make sure
        that:
        <ul>
          <li>
            The Client understands their treatment plan and how the subscription
            management, deliveries and payments will work.
          </li>
          <li>
            The client happily purchases all medication and equipment from BMH
          </li>
          <li>
            Help the client select the package option that is best suited to
            their specific needs and priorities.
          </li>
        </ul>
        <ul
          style={{
            paddingLeft: 20,
          }}
        >
          <li>
            Once you confirm your selection, the CTTP and Prescription(s) will
            be automatically generated and sent to the client&apos;s Doctor for
            validation and signature.
          </li>
        </ul>
      </>
    );
  }

  if (matchingProductsLength) {
    return (
      <>
        You can see the available items for each treatment area and the dosing
        they should be used with based on Doctor&apos;s request. Once you
        selected all items, the CTTP and Prescription(s) will be automatically
        generated and sent to the client&apos;s Doctor for signature.
      </>
    );
  }

  return (
    <>
      You can select any pharmacy, including manual pharmacies, and select items
      from any of them for this treatment.
      <ul>
        <li>
          If you can confirm with any pharmacy that other items are also
          available, you can add those items in the Products module and then
          select them here.
        </li>
        <li>
          Once you selected all items, the CTTP and Prescription(s) will be
          automatically generated and sent to the client&apos;s Doctor for
          signature.
        </li>
      </ul>
    </>
  );
};

const FinalizeTreatmentInfoTitle = ({
  matchingPackagesLength,
}: Pick<Props, "matchingPackagesLength">) => {
  if (matchingPackagesLength) {
    return (
      <>Check and validate if the preferred package options are correct.</>
    );
  }

  return (
    <>
      No suitable packages were found using a maximum of two pharmacies to
      deliver everything.
    </>
  );
};

export const FinalizeTreatmentInfo = ({
  matchingPackagesLength,
  clientMobile,
  clientMobile2,
  matchingProductsLength,
}: Props) => {
  const [showDetails, setShowDetails] = useState(true);

  const classes = useStyles();

  const handleShowDetails = useCallback(() => {
    setShowDetails((current) => !current);
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Alert severity="info">
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item xs={10}>
            <AlertTitle>
              <FinalizeTreatmentInfoTitle
                matchingPackagesLength={matchingPackagesLength}
              />
            </AlertTitle>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="text"
              onClick={handleShowDetails}
              style={{
                textDecoration: "underline",
                padding: 0,
              }}
            >
              {showDetails ? "HIDE" : "SHOW"} DETAILS
            </Button>
          </Grid>
          <Collapse in={showDetails}>
            <Grid item xs={10}>
              <Box pl={1} pr={1} pb={1}>
                <FinalizeTreatmentInfoText
                  matchingPackagesLength={matchingPackagesLength}
                  matchingProductsLength={matchingProductsLength}
                />
              </Box>
            </Grid>
          </Collapse>
        </Grid>
      </Alert>
      {clientMobile || clientMobile2 ? (
        <Box mt={2}>
          <Grid container spacing={4}>
            {clientMobile ? (
              <Grid item>
                <Typography>
                  Client Mobile: <strong>{clientMobile}</strong>
                </Typography>
              </Grid>
            ) : null}
            {clientMobile2 ? (
              <Grid item>
                <Typography>
                  Client Other Phone: <strong>{clientMobile2}</strong>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};
