import { Box } from "@material-ui/core";
import React from "react";
import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import { OfferingLabInstructions } from "./OfferingLabInstructions";
import { OfferingLabBloodDrawerOptions } from "./OfferingLabBloodDrawerOptions";
import { ZohoVariables } from "../types";

interface Props {
  labName?: string;
  bloodDrawers: BloodDrawer[];
  variables: Record<ZohoVariables, string>;
}

export const OfferingLab = ({ labName, bloodDrawers, variables }: Props) => {
  return (
    <>
      <Box mt={2}>
        <OfferingLabInstructions
          labName={labName}
          bloodDrawers={bloodDrawers}
          variables={variables}
        />
      </Box>
      <Box mt={2}>
        <OfferingLabBloodDrawerOptions bloodDrawers={bloodDrawers} />
      </Box>
    </>
  );
};
