import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { getFieldName } from "helpers";
import React from "react";

interface Props {
  name?: string;
}
export const TreatmentInstructionsField = ({ name }: Props) => {
  return (
    <Field
      component={TextField}
      name={getFieldName("instructions", name)}
      label="Important instructions"
      multiline
      rows={3}
      size="small"
      fullWidth
    />
  );
};
