import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  title: {
    backgroundColor: p.grey[50],
  },
  proceed: {
    color: "white",
    background: p.error.main,
  },
  content: {
    padding: s(4),
  },
  actions: {
    borderTop: `1px solid ${p.grey[200]}`,
  },
}));

interface Props extends DialogProps {
  onDelete: () => void;
  onClose: () => void;
}
export const DeleteDialog = ({ open, onDelete, onClose, ...props }: Props) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Dialog open={open} {...props}>
      <DialogTitle className={classes.title}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
          direction="row"
        >
          <Grid item>
            <Typography variant="subtitle2">
              Delete Supplementary Product
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography align="center">
          Are you sure you want to delete this product?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" onClick={handleClose} color="primary">
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          className={classes.proceed}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
