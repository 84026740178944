import React, { useCallback, useState } from "react";
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  StandardTextFieldProps,
  makeStyles,
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

const useStyle = makeStyles(({ spacing: s }) => ({
  textField: {
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: 10.5,
    },
    "& .MuiOutlinedInput-inputAdornedEnd": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: s(1),
    },
  },
}));

interface Props extends Omit<StandardTextFieldProps, "onClick" | "variant"> {
  variant?: TextFieldProps["variant"];
  placeholder?: string;
  value: string;
  onSearchIconClick: () => any;
  onSearchClearClick: () => any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

export const TableSearchInput: React.FC<Props> = ({
  className,
  placeholder,
  value,
  onSearchClearClick,
  onSearchIconClick,
  onChange,
  ...inputProps
}) => {
  const [focus, setFocus] = useState(false);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onSearchIconClick();
      }
    },
    [onSearchIconClick]
  );

  const onBlur = useCallback(() => {
    setFocus(false);
    onSearchIconClick();
  }, [onSearchIconClick]);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const classes = useStyle();

  return (
    <TextField
      className={classes.textField}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      InputProps={{
        className: `${className || ""}`,
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={onSearchClearClick}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={onSearchIconClick}
              color={focus ? "primary" : "default"}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...inputProps}
    />
  );
};
