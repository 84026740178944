import { n8nClient } from "apis";
import { zohoServices } from "services";

type RenewTreatmentPayload = {
  treatmentZohoID: string;
  crmUserId: string;
  treatmentPeriodInMonths: number | string;
};

export async function getTreatmentRenewalFirstPaymentDate(treatmentId: string) {
  try {
    const response = await zohoServices.getRecord<{
      Renewal_First_Payment_Date: string;
    }>({
      Entity: "Treatments",
      RecordID: treatmentId,
    });
    return response.Renewal_First_Payment_Date;
  } catch (error) {
    return "";
  }
}

export const renewTreatment = async (payload: RenewTreatmentPayload) => {
  const res = await n8nClient.post(`/renew-treatment`, payload);
  return res.data;
};
