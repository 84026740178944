import { Grid, Typography } from "@material-ui/core";
import React from "react";

interface TwoColumnDataProps {
  details: { title: string; value: string | React.ReactNode }[];
}

export const TwoColumnData: React.FC<TwoColumnDataProps> = ({ details }) => {
  return (
    <Grid spacing={1} container>
      {details.map((detail) => (
        <Grid item xs={6} key={detail.title}>
          <Typography variant="body2" gutterBottom={false}>
            {detail.title}: {detail.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
