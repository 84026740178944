import { useCallback } from "react";
import { useSelector } from "react-redux";
import print from "print-js";

import { pathwayRequests } from "redux/pathway";
import { zohoSelectors } from "redux/zoho";
import { notifications, zohoServices } from "services";

export const useTubeLabelsPrint = () => {
  const ids = useSelector(zohoSelectors.getIds);

  const printPDF = useCallback(
    async ({
      setLoading,
      onPrintDialogClose,
      onError,
      onLoadingEnd,
    }: {
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      onPrintDialogClose?: () => any;
      onError?: (error: any) => any;
      onLoadingEnd?: () => any;
    }) => {
      try {
        setLoading(true);
        const link = await pathwayRequests.fetchTubeLabelsPdf(ids);

        print({
          printable: link,
          onLoadingEnd: onLoadingEnd || (() => setLoading(false)),
          onError:
            onError ||
            (() => {
              notifications.notifyError("Error when loading PDF file");
              setLoading(false);
            }),
          onPrintDialogClose:
            onPrintDialogClose ||
            (() => {
              zohoServices.closePopup();
            }),
        });
      } catch (error) {
        setLoading(false);
      }
    },
    [ids]
  );

  return { printPDF };
};
