import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { InitialValues } from "../types";

export const StepFour = () => {
  const { values } = useFormikContext<InitialValues>();
  const { doctor, doctors } = values;
  const selectedDoctor = doctors.find((d) => d.value === doctor);
  const infoTextOne = useMemo(() => {
    if (values.isRecentBloodTestAcceptable === "No") {
      return `A new invoice for the repeat blood test will be automatically 
        generated and sent to client to collect payment.`;
    }
    if (values.doctor) {
      return `Client has been sent an email with the link to book a doctor's consultation with ${selectedDoctor?.label}`;
    }
    return null;
  }, [
    selectedDoctor?.label,
    values.doctor,
    values.isRecentBloodTestAcceptable,
  ]);

  const infoTextTwo = useMemo(() => {
    if (values.isRecentBloodTestAcceptable === "No") {
      return `Client will be sent an email to make payments for a new blood test.`;
    }
    return null;
  }, [values.isRecentBloodTestAcceptable]);

  return (
    <Box minHeight="200px" marginBottom={2}>
      <Box
        maxWidth="680px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        margin="100px auto"
        gridRowGap="16px"
      >
        <Typography
          variant="body1"
          color="primary"
          style={{ marginBottom: "8px", fontSize: "18px", textAlign: "center" }}
        >
          {infoTextOne}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          style={{ marginBottom: "8px", fontSize: "18px", textAlign: "center" }}
        >
          {infoTextTwo}
        </Typography>
      </Box>
    </Box>
  );
};
