import { Alert, AlertTitle } from "@material-ui/lab";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { treatmentServices, zohoServices } from "services";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { AxiosError } from "axios";
import Loader from "components/Loader";
import { ZohoBMHDoctor, ZohoTreatment } from "types";
import { PharmacySelectionAutomationActions } from "./PharmacySelectionAutomationActions";

export const PharmacySelectionAutomation = () => {
  const dispatch = useDispatch();
  const [id] = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [isFetchedAllData, setIsFetchedAllData] = useState(false);
  const [error, setError] = useState("");

  const view = useMemo(() => {
    if (error) {
      return "error";
    }

    if (!isFetchedAllData) {
      return "loading";
    }

    return "success";
  }, [isFetchedAllData, error]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [treatmentRes, currentUserRes] = await Promise.all([
        zohoServices.getRecord({ Entity: "Treatments", RecordID: id }),
        dispatch(zohoActions.fetchCurrentUser()),
      ]);
      const treatment = treatmentRes as ZohoTreatment;
      const currentUserId = (currentUserRes as any)?.payload.id as string;

      if (treatment.Owner.id !== currentUserId) {
        const doctorRes = await zohoServices.getRecord({
          Entity: "BMH_Doctors",
          RecordID: treatmentRes.Doctor.id,
        });
        const doctor = doctorRes as ZohoBMHDoctor;
        if (doctor.User.id !== currentUserId) {
          setError("You are not allowed to make changes to this treatment");
          return;
        }
      }

      await treatmentServices.startPharmacyAndMedicineSelection(id);
      setIsFetchedAllData(true);
    } catch (e) {
      const err = e as AxiosError;
      setError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <TreatmentWidgetLayout>
      <TreatmentWidgetTitle title="Start Pharmacy and Medicine Selection" />
      <TreatmentWidgetContent>
        <Loader open={loading} />
        {view === "error" ? (
          <Alert severity="error">
            <AlertTitle>
              Failed to start pharmacy and medicine selection.
            </AlertTitle>
            {error}
          </Alert>
        ) : null}
        {view === "loading" ? <Box height={500}>{/*  */}</Box> : null}
        {view === "success" ? (
          <Alert severity="success">
            Pharmacy and medicine selection started successfully.
          </Alert>
        ) : null}
      </TreatmentWidgetContent>
      {isFetchedAllData ? (
        <TreatmentWidgetActions>
          <PharmacySelectionAutomationActions view={view} />
        </TreatmentWidgetActions>
      ) : null}
    </TreatmentWidgetLayout>
  );
};
