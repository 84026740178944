import React, { memo, useCallback } from "react";
import { FieldProps } from "formik";
import { TextField } from "@material-ui/core";

interface CustomTextFieldProps extends FieldProps {
  onChangeAdditionalCall: (value: string) => any;
}

const CustomTextField = ({
  onChangeAdditionalCall,
  field,
  form,
  ...props
}: CustomTextFieldProps) => {
  const { name, onChange, ...otherFields } = field;
  const { error, touched } = form.getFieldMeta(field.name);
  const errorMessage = (touched && error) || "";

  const onInputChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      onChange(e);
      onChangeAdditionalCall(e.target.value);
    },
    [onChange, onChangeAdditionalCall]
  );

  return (
    <TextField
      error={!!errorMessage}
      helperText={errorMessage}
      name={name}
      onChange={onInputChange}
      {...otherFields}
      {...props}
    />
  );
};

export default memo(CustomTextField);
