import React from "react";
import {
  Typography,
  Button,
  ButtonProps,
  makeStyles,
  styled,
  Box,
  TableCell as TableCellRaw,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const useStyle = makeStyles(({ spacing: s }) => ({
  backIcon: {
    marginRight: s(1),
  },
}));

export const TABLE_HEADER_COLOR = "#7E9FCE";

export const TableTitle = () => {
  return <Typography variant="h5">Blood Tests Results</Typography>;
};

export const BackButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyle();
  return (
    <Button color="primary" variant="text" {...props}>
      <ArrowBack className={classes.backIcon} />
      BACK
    </Button>
  );
};

export const ViewWrapperBox = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const TableCell = styled(TableCellRaw)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  padding: "3px",
}));

export const BigTableCell = styled(TableCellRaw)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  padding: "6px",
}));
