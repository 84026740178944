import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import React, { useMemo } from "react";
import { ZohoTreatment } from "types";

export interface MakeChangesFormProps {
  treatment: ZohoTreatment | null;
  treatmentUrl?: string;
}

export const MakeChangesForm = ({
  treatment,
  treatmentUrl,
}: MakeChangesFormProps) => {
  const url = useMemo(() => {
    if (!treatment?.id || !treatmentUrl) {
      return "";
    }
    return treatmentUrl.replace("xxxxx", treatment?.id);
  }, [treatment?.id, treatmentUrl]);
  return (
    <Box mb={2}>
      <Box mb={2}>
        <Alert severity="info">
          {url ? (
            <AlertTitle>
              To make changes to the prescription, you need to update the
              client&apos;s Treatment{" "}
              <a href={url} rel="noreferrer" target="_blank">
                {treatment?.Name}
              </a>
            </AlertTitle>
          ) : null}
          <ul>
            <li>First, cancel this order and credit the paid amount.</li>
            <li>
              Then go to the client&apos;s treatment plan and Update the
              treatment with the new treatment details or new items selected. A
              new prescription and CTTP will be generated and sent for
              signatures immediately.
            </li>
          </ul>
        </Alert>
      </Box>
      <Box maxWidth={500}>
        <Field
          component={TextField}
          name="cancellationReason"
          label="Cancellation Reason"
          multiline
          rows={3}
          required
          size="small"
          fullWidth
        />
      </Box>
    </Box>
  );
};
