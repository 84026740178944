import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import printJS from "print-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { dispenseDrugsServices } from "services";

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const PrintPackingSlip: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [packingSlip, setPackingSlip] = useState("");

  const [id] = useSelector(zohoSelectors.getIds);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    return "pdfview";
  }, [fetchError]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const { url } = await dispenseDrugsServices.generatePackingSlip(id);
      setPackingSlip(url);
    } catch (error) {
      setFetchError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const printSlip = useCallback(() => {
    printJS({
      printable: packingSlip,
      type: "pdf",
    });
  }, [packingSlip]);

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <TreatmentWidgetLayout defaultWidth={450}>
      <TreatmentWidgetTitle title="Print Packing Slip" isCustomButton />
      <TreatmentWidgetContent>
        <Loader open={loading} />
        {view === "fetch-error" ? (
          <Alert severity="error">
            <AlertTitle>An error occurred while fetching data.</AlertTitle>
            {fetchError}
          </Alert>
        ) : null}
        {view === "pdfview" ? (
          <Box height={380}>
            {packingSlip && (
              <iframe
                name="Packing Slip"
                title="Packing Slip"
                src={packingSlip}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  background: "#EFEFEF",
                }}
              />
            )}
          </Box>
        ) : null}
      </TreatmentWidgetContent>
      <TreatmentWidgetActions containerClass={classes.actions}>
        <Box textAlign="right">
          <Button onClick={printSlip} variant="contained" color="primary">
            Print
          </Button>
        </Box>
      </TreatmentWidgetActions>
    </TreatmentWidgetLayout>
  );
};
