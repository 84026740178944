import { ThemeProvider } from "@material-ui/core/styles";
import theme from "theme";
import { HourglassEmpty } from "@material-ui/icons";
import React from "react";
import { Box, CssBaseline, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: s(4),
    backgroundColor: "white",
    borderRadius: 4,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
}));

const ConstructionView = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <HourglassEmpty fontSize="large" />
        <Box mt={2}>
          <Typography variant="h5" component="h1" align="center">
            Maintenance in Progress
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" align="center">
            We apologize for the inconvenience. The widget is currently
            undergoing maintenance. Please check back later.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const ConstructionPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConstructionView />
    </ThemeProvider>
  );
};
