import React, { useCallback, useEffect, useState } from "react";
import {
  styled,
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Tooltip,
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitleProps,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Field, Form, Formik, FormikConfig } from "formik";
import { FormikLowerCasedField } from "components/FomrikLowerCasedField";
import { useResponsive } from "hooks";
import {
  emailConfigActions,
  emailConfigSelectors,
} from "redux/settings/emailConfig";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { TextField } from "formik-material-ui";
import { getENText, VALIDATIONS } from "helpers";
import {
  Domain,
  EmailConfigNameEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { AppDispatch } from "redux/types";

import { notifications } from "services";
import MuiTheme from "theme";
import { SettingsContainer } from "components/SettingsContainer";
import { EmailConfigSelection } from "./EmailConfigSelection";

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 2, 3),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  "& > *:not(:first-child)": {
    marginTop: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up("lg")]: {
        maxWidth: "75%",
        flexBasis: "75%",
      },
    },
    dangerZone: {
      color: theme.palette.error.main,
    },
  })
);

enum EmailConfigTabDialogs {
  emailSuccess = "emailSuccess",
  senderNameSuccess = "senderNameSuccess",
  emailFailure = "emailFailure",
  dcsRequestSent = "dcsRequestSent",
  addDomain = "addDomain",
}

const DialogTitle = ({
  children,
}: Omit<DialogTitleProps, "disableTypography">) => (
  <MuiDialogTitle disableTypography>
    <Typography component="h2" variant="h5">
      {children}
    </Typography>
  </MuiDialogTitle>
);

const getEmailDomain = (email: string) => email.slice(email.indexOf("@") + 1);

const isDomainValid = (email: string, domains: Domain[]) =>
  !!domains.find((domain) => domain.domain === getEmailDomain(email));

const isDomain = (domain: string) =>
  /^@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    domain
  );

const EmailConfigTab = () => {
  const { isLGDown, useMediaQuery } = useResponsive();
  const isMdOrLg = useMediaQuery(MuiTheme.breakpoints.between("md", "lg"));
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<EmailConfigTabDialogs | null>(null);
  const [newDomain, setNewDomain] = useState("");
  const closeDialog = () => {
    setDialog(null);
  };
  const domains = useSelector(emailConfigSelectors.domainsSelectors.selectAll);
  const fromEmails = useSelector(
    emailConfigSelectors.fromEmailsSelectors.selectAll
  );
  const notiEmailConfig = useSelector(
    emailConfigSelectors.getEmailConfigByName(EmailConfigNameEnum.noti)
  );
  const customerEmailConfig = useSelector(
    emailConfigSelectors.getEmailConfigByName(EmailConfigNameEnum.customer)
  );
  const pathwayEmailConfig = useSelector(
    emailConfigSelectors.getEmailConfigByName(EmailConfigNameEnum.pathway)
  );
  const mcmEmailConfig = useSelector(
    emailConfigSelectors.getEmailConfigByName(EmailConfigNameEnum.mcm)
  );

  const onContactDCS = async () => {
    setLoading(true);
    const res = await dispatch(
      emailConfigActions.postDomains({ domain: newDomain })
    );
    setLoading(false);
    if (emailConfigActions.postDomains.fulfilled.match(res)) {
      setDialog(EmailConfigTabDialogs.dcsRequestSent);
    }
  };

  const onContactDCSClick = () => {
    setDialog(EmailConfigTabDialogs.addDomain);
  };

  const onSubmitDomain: FormikConfig<{
    domain: string;
  }>["onSubmit"] = async ({ domain }) => {
    setLoading(true);
    const res = await dispatch(
      emailConfigActions.postDomains({ domain: domain.slice(1) })
    );
    if (emailConfigActions.postDomains.fulfilled.match(res)) {
      setNewDomain(domain.slice(1));
      setDialog(EmailConfigTabDialogs.dcsRequestSent);
    }
    setLoading(false);
  };

  const onEmailConfigChange = useCallback(
    async ({
      id,
      fromId,
      replyTo,
      senderName,
    }: {
      id: string;
      fromId: string;
      replyTo: string;
      senderName: string;
    }) => {
      setLoading(true);
      const res = await dispatch(
        emailConfigActions.patchEmailConfigs({
          id,
          fromId,
          replyTo,
          senderName,
        })
      );
      const success = emailConfigActions.patchEmailConfigs.fulfilled.match(res);
      if (success) {
        notifications.notifySuccess(
          getENText("emailConfig.noti.success.updated")
        );
      }
      setLoading(false);
      return success;
    },
    [dispatch]
  );

  const onSubmitFromEmail: FormikConfig<{
    fromEmail: string;
  }>["onSubmit"] = async ({ fromEmail }, { resetForm }) => {
    if (isDomainValid(fromEmail, domains)) {
      setLoading(true);
      const res = await dispatch(
        emailConfigActions.postFromEmails({ email: fromEmail })
      );
      setLoading(false);
      if (emailConfigActions.postFromEmails.fulfilled.match(res)) {
        setDialog(EmailConfigTabDialogs.emailSuccess);
        resetForm();
      }
    } else {
      resetForm();
      setNewDomain(getEmailDomain(fromEmail));
      setDialog(EmailConfigTabDialogs.emailFailure);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.all([
        dispatch(emailConfigActions.getDomains()),
        dispatch(emailConfigActions.getFromEmails()),
      ]);
      await dispatch(emailConfigActions.getEmailConfigs());

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SettingsContainer>
      <Loader isFixed open={loading} />
      <StyledHeader>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" paragraph>
              Email Configuration
            </Typography>

            <Typography>
              You can use the below functionality to configure the email sender
              names and addresses on emails sent by the system automatically.
              <br />
              <br />
              <span className={classes.dangerZone}>
                <b>DANGER ZONE!</b>
                <br />
                Any changes made here will be immediately applied to all newly
                sent emails. If you are unsure of what you are doing, please
                contact DCS for help to make needed changes.
              </span>
            </Typography>
          </Grid>
          {domains.length > 0 && (
            <Grid item>
              <Box>
                <Box display="flex" alignItems="center">
                  <Tooltip title="New domains will not appear on the list or be usable until they are added by DCS">
                    <InfoOutlined
                      style={{ marginRight: "8px" }}
                      color="action"
                    />
                  </Tooltip>
                  <Typography>List of available domains:</Typography>
                </Box>
                <ul>
                  {domains.map((domain) => (
                    <li key={domain.id}>@{domain.domain}</li>
                  ))}
                </ul>
              </Box>
            </Grid>
          )}
        </Grid>
      </StyledHeader>
      <Divider />
      <Box px={2} pt={4} pb={3}>
        <Grid container spacing={4} justify="space-around">
          <Grid
            style={{
              background: MuiTheme.palette.background.default,
              borderRight: `1px solid ${MuiTheme.palette.divider}`,
              borderLeft: `1px solid ${MuiTheme.palette.divider}`,
              alignContent: "flex-start",
            }}
            container
            item
            xs={12}
            md={4}
            lg={3}
            spacing={4}
          >
            <Grid item xs={12}>
              <Formik<{ fromEmail: string }>
                onSubmit={onSubmitFromEmail}
                validate={({ fromEmail }) => {
                  try {
                    const value = VALIDATIONS.email.validateSync(fromEmail);
                    const exists = !!fromEmails.find(
                      (email) => email.email === value
                    );

                    return exists
                      ? {
                          fromEmail: getENText("validation.email.exist"),
                        }
                      : undefined;
                  } catch (error) {
                    return {
                      fromEmail: error.errors[0],
                    };
                  }
                }}
                initialValues={{
                  fromEmail: "",
                }}
                initialTouched={{
                  fromEmail: true,
                }}
              >
                {({ isValid, isSubmitting }) => (
                  <Form>
                    <StyledBox>
                      <Field
                        component={FormikLowerCasedField}
                        label="Add new from email address"
                        name="fromEmail"
                      />
                      <Typography variant="body2" color="textSecondary">
                        Use this field to add From addresses to the system. You
                        can add as much addresses as you want.
                      </Typography>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!isValid || isSubmitting}
                        >
                          Add
                        </Button>
                      </Box>
                    </StyledBox>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: MuiTheme.spacing(4) }}>
              <Button
                color="secondary"
                variant="text"
                onClick={onContactDCSClick}
                size="small"
              >
                Contact DCS
              </Button>{" "}
              if you want to add new email domain to the system or delete email
              addresses/domains.
            </Grid>
          </Grid>
          <Grid
            classes={{
              root: classes.root,
            }}
            container
            item
            xs={12}
            md={8}
            lg="auto"
            justify="space-around"
            spacing={4}
          >
            <Grid
              container
              item
              xs={12}
              md={6}
              direction={isMdOrLg ? "column" : undefined}
              justify={isMdOrLg ? "flex-start" : undefined}
              wrap={isMdOrLg ? "nowrap" : undefined}
            >
              <Grid
                item
                xs={12}
                style={{ flexBasis: isMdOrLg ? 0 : undefined }}
              >
                <Typography variant="h5" component="h2">
                  Test Results Notifications
                </Typography>
              </Grid>
              <Grid
                style={{
                  order: isLGDown ? 1 : undefined,
                  justifyContent: isLGDown ? "flex-end" : undefined,
                }}
                item
                xs={12}
                xl={6}
              >
                {notiEmailConfig && (
                  <EmailConfigSelection
                    fromEmails={fromEmails}
                    emailConfig={notiEmailConfig}
                    onEmailConfigChange={onEmailConfigChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  Select the default settings for the emails that are going to
                  be sent to client, when their results arrived. Those emails
                  may contain customer portal activation links and invitations
                  for pcm calls upon receiving results.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={6}
              direction={isMdOrLg ? "column" : undefined}
              justify={isMdOrLg ? "flex-start" : undefined}
              wrap={isMdOrLg ? "nowrap" : undefined}
            >
              <Grid
                item
                xs={12}
                style={{ flexBasis: isMdOrLg ? 0 : undefined }}
              >
                <Typography variant="h5" component="h2">
                  Customer Portal
                </Typography>
              </Grid>
              <Grid
                style={{
                  order: isLGDown ? 1 : undefined,
                  justifyContent: isLGDown ? "flex-end" : undefined,
                }}
                item
                xs={12}
                xl={6}
              >
                {customerEmailConfig && (
                  <EmailConfigSelection
                    fromEmails={fromEmails}
                    emailConfig={customerEmailConfig}
                    onEmailConfigChange={onEmailConfigChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  Select the default settings for the emails related to all the
                  operatios associated with the client portal. For example
                  welcoming email, passwort reset or email change.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={6}
              direction={isMdOrLg ? "column" : undefined}
              justify={isMdOrLg ? "flex-start" : undefined}
              wrap={isMdOrLg ? "nowrap" : undefined}
            >
              <Grid
                item
                xs={12}
                style={{ flexBasis: isMdOrLg ? 0 : undefined }}
              >
                <Typography variant="h5" component="h2">
                  Blood Test Order/ Blood Test Processing
                </Typography>
              </Grid>
              <Grid
                style={{
                  order: isLGDown ? 1 : undefined,
                  justifyContent: isLGDown ? "flex-end" : undefined,
                }}
                item
                xs={12}
                xl={6}
              >
                {pathwayEmailConfig && (
                  <EmailConfigSelection
                    fromEmails={fromEmails}
                    emailConfig={pathwayEmailConfig}
                    onEmailConfigChange={onEmailConfigChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  Select the default settings for the emails with blood test
                  order form, blood drawing instructions, confirmation email
                  that will be sent during Blood Test Order blueprint
                  processing, or with help of “Generate Order Form”, “Send
                  Instructions”, “Send Confirmation” buttons in Blood Test Order
                  record.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={6}
              direction={isMdOrLg ? "column" : undefined}
              justify={isMdOrLg ? "flex-start" : undefined}
              wrap={isMdOrLg ? "nowrap" : undefined}
            >
              <Grid
                item
                xs={12}
                style={{ flexBasis: isMdOrLg ? 0 : undefined }}
              >
                <Typography variant="h5" component="h2">
                  Scheduling PCM Calls
                </Typography>
              </Grid>
              <Grid
                style={{
                  order: isLGDown ? 1 : undefined,
                  justifyContent: isLGDown ? "flex-end" : undefined,
                }}
                item
                xs={12}
                xl={6}
              >
                {mcmEmailConfig && (
                  <EmailConfigSelection
                    fromEmails={fromEmails}
                    emailConfig={mcmEmailConfig}
                    onEmailConfigChange={onEmailConfigChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  Select the default settings for the emails that will notify
                  client and PCM about status of their meeting, such as
                  confirmations, cancellations, reschedulings and reminders.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={dialog === EmailConfigTabDialogs.emailSuccess}
        onClose={closeDialog}
      >
        <DialogTitle>Email successfully added</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New email address is now available to be used as default. Use the
            same field to add more addresses.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialog === EmailConfigTabDialogs.senderNameSuccess}
        onClose={closeDialog}
      >
        <DialogTitle>Sender Name successfully added</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New sender name is now available to be used as default. Use the same
            field to add more sender names.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialog === EmailConfigTabDialogs.emailFailure}
        onClose={closeDialog}
      >
        <DialogTitle>Email address can not be added</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email address can not be added because it’s domain is not verified
            in the system. Would you like to contact DCS to add and use this
            domain?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeDialog}>
            Cancel
          </Button>
          <Button color="primary" onClick={onContactDCS}>
            Contact DCS
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialog === EmailConfigTabDialogs.dcsRequestSent}
        onClose={closeDialog}
      >
        <DialogTitle>Request sent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email with request to add «@{newDomain}» domain is sent. Expect DCS
            to get in touch with you to verify details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialog === EmailConfigTabDialogs.addDomain}
        onClose={closeDialog}
        PaperProps={{
          style: {
            minWidth: "500px",
          },
        }}
      >
        <Formik
          onSubmit={onSubmitDomain}
          validate={({ domain }) => {
            if (!domain) {
              return {
                domain: getENText("validation.domain.required"),
              };
            }

            if (!isDomain(domain)) {
              return {
                domain: getENText("validation.domain.invalid"),
              };
            }

            return {};
          }}
          initialValues={{
            domain: "@",
          }}
        >
          {({ isValid, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogTitle>Enter domain name</DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  label="New Domain eg: @gmail.com"
                  name="domain"
                  helperText="eg: @gmail.com"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    if (value[0] !== "@") {
                      value = `@${value}`;
                    }
                    setFieldValue("domain", value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={closeDialog}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </SettingsContainer>
  );
};

export default EmailConfigTab;
