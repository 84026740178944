import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ZohoTreatment } from "types";

interface WarningAlertProps {
  inProgressTreatment: ZohoTreatment | null;
}

export const WarningAlert = ({ inProgressTreatment }: WarningAlertProps) => {
  if (!inProgressTreatment) {
    return null;
  }
  return (
    <Alert severity="warning">
      <AlertTitle>
        The client already has a treatment{" "}
        <a target="_blank" rel="noreferrer" href={inProgressTreatment.url}>
          {inProgressTreatment.Name}
        </a>{" "}
        that is {inProgressTreatment.State}.
      </AlertTitle>
      Update or Cancel or Pause the existing treatment to be able to resume this
      treatment.
    </Alert>
  );
};
