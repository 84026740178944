import { Box, makeStyles, Typography } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import React, { useMemo } from "react";
import {
  Decision,
  MedicationCategory,
  PrescriptionItem,
  ProductType,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";

interface MissingOrderItemProps {
  orderItem: TreatmentOrderItem;
  prescriptionItem?: PrescriptionItem;
}

export const useStyles = makeStyles(({ spacing: s }) => ({
  ul: {
    padding: s(0, 0, 0, 2),
    margin: 0,
  },
  container: {
    border: "1px solid #1D3961",
    borderRadius: "4px",
    backgroundColor: "#FFF",
    padding: s(1),
  },
  textDescription: {
    display: "flex",
    gap: s(2),
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: s(1),
  },
  itemTitle: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    padding: "6px 12px",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "0.7rem",
    textTransform: "uppercase",
    width: "fit-content",
  },
  decisionContainer: {
    padding: s(2),
    backgroundColor: "#f4f4f4",
    display: "flex",
    flexWrap: "wrap",
    gap: s(1),
  },
}));

export const MissingOrderItem: React.FC<MissingOrderItemProps> = ({
  orderItem,
  prescriptionItem,
}) => {
  const classes = useStyles();

  const medsCategory = useMemo(() => {
    if (
      orderItem.productItem.Medication_Category ===
      MedicationCategory.PRESCRIPTION
    ) {
      return "Prescription Medication";
    }
    if (
      orderItem.productItem.Medication_Category ===
      MedicationCategory.OVER_THE_COUNTER
    ) {
      return "Over The Counter Medication";
    }
    if (orderItem.productItem.Product_Type === ProductType.SUPPLEMENTARY) {
      return "Supplementary Medication";
    }
    return "Unknown";
  }, [orderItem.productItem]);

  return (
    <Box className={classes.container} mb={2}>
      <Box className={classes.textDescription}>
        <Box width="30%">
          <Typography variant="body1" color="primary" gutterBottom>
            {orderItem.productItem.Name_For_Prescription}
          </Typography>
        </Box>
        <Box width="25%">
          <TitleValue
            title="Quantity to Dispense:  "
            value={`${orderItem.Quantity}
          ${
            orderItem.productItem.Usage_Units_Per_Sellable_Unit
              ? ` x ${orderItem.productItem.Usage_Units_Per_Sellable_Unit} `
              : ""
          }
          ${orderItem.productItem.Meds_Usage_Unit_Name || ""}`}
            singleLine
          />
        </Box>
        <Box width="20%">
          <TitleValue
            title="Remaining on Prescription:  "
            value={
              (prescriptionItem?.Quantity_To_Dispense || 0) -
              (prescriptionItem?.Quantity_Dispensed || 0)
            }
            singleLine
          />
        </Box>
        <Box width="25%" textAlign="right">
          <Typography className={classes.itemTitle} variant="body2">
            {medsCategory}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.decisionContainer}>
        <Box width="15%">
          <TitleValue
            singleLine
            title="Missing Quantity: "
            value={orderItem.Missing_Quantity}
          />
        </Box>
        <Box
          width={
            [
              Decision.PARTIAL_DISPENSE_MARK_REST_OWED,
              Decision.PARTIAL_DISPENSE_SEND_TO_BMH,
            ].includes(orderItem.Decision as Decision)
              ? "30%"
              : "20%"
          }
        >
          <TitleValue
            singleLine
            title="Decision: "
            value={orderItem.Decision}
          />
        </Box>
        {orderItem.Suggested_Replacement?.name && (
          <Box width="30%">
            <TitleValue
              singleLine
              title="Suggested Replacement: "
              value={orderItem.Suggested_Replacement?.name}
            />
          </Box>
        )}
        {orderItem.Suggested_Replacement_Quantity && (
          <Box width="22%">
            <TitleValue
              singleLine
              title="Suggested Quantity: "
              value={orderItem.Suggested_Replacement_Quantity || 0}
            />
          </Box>
        )}
        {orderItem.Dispensing_Note && (
          <Box width="30%">
            <TitleValue
              singleLine
              title="Dispensing Note: "
              value={orderItem.Dispensing_Note}
            />
          </Box>
        )}
        {orderItem.Replacement?.name && (
          <Box width="30%">
            <TitleValue
              singleLine
              title="Replacement: "
              value={orderItem.Replacement?.name}
            />
          </Box>
        )}
        {orderItem.Replacement_Quantity && (
          <Box width="22%">
            <TitleValue
              singleLine
              title="Replacement Quantity: "
              value={orderItem.Replacement_Quantity || 0}
            />
          </Box>
        )}
        {orderItem.Replacement_Usage_Notes && (
          <Box width="30%">
            <TitleValue
              singleLine
              title="Replacement Usage Note: "
              value={orderItem.Replacement_Usage_Notes}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
