import { GetPathwayKitPackingDetails } from "@deep-consulting-solutions/bmh-constants";

export interface FormValues {
  reason: string;
  changeBloodTakingOptionFromFingerPrickToVenepuncture: boolean;
}
export enum PageEnum {
  Approval,
  Form,
  Error,
  Success,
  LoadingError,
  CreateInvoiceError,
  Loading,
}

export type BloodOrderType = typeof GetPathwayKitPackingDetails["Res"]["data"]["pathways"];

export enum RetestErrorEnum {
  NotClientError = "Not Client Error",
  ClientError = "Client Error",
}
