import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingsActions, settingsSelectors } from "redux/settings";
import { Column, RowActionsEnum } from "types";
import { AppDispatch } from "redux/types";
import { notifications } from "services";
import { getENText } from "helpers";
import { EditableTable } from "components/EditableTable";
import { isBoolean, upperCase } from "lodash";
import {
  StatusEnum,
  TestFailureCodeFE,
} from "@deep-consulting-solutions/bmh-constants";
import { SettingsContainer } from "components/SettingsContainer";

export const INITIAL_COLUMNS: Column<TestFailureCodeFE>[] = [
  {
    key: "failureCode",
    header: "Failure Code",
    info:
      "Failure Code Name. System recognizes failure codes in received result files if they present in this table. Please make sure to receive updates from each laboratory on failure codes and input them here for the correct work of the system. In case unknown failure codes already present in some test results, they will be properly highlighted even if failure code is added to the system later.",
    editable: true,
    align: "left",
    autoFocus: true,
    required: true,
  },
];

const TestFailureCodes = () => {
  const labs = useSelector(settingsSelectors.labsSelectors.selectAll);
  const columns: Column<TestFailureCodeFE>[] = useMemo(
    () => [
      ...INITIAL_COLUMNS,
      ...labs.map<Column<TestFailureCodeFE>>((lab) => ({
        key: lab.key,
        header: `${upperCase(lab.key)}`,
        info: "Whether the Failure Code belongs to this lab",
        editable: true,
        isBoolean: true,
        align: "center",
        required: false,
      })),
    ],
    [labs]
  );
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const rows = useSelector(
    settingsSelectors.testFailureCodeSelectors.selectAll
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(settingsActions.getLabs());
      await dispatch(settingsActions.getTestFailureCode());
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTestFailureCode = async (data: TestFailureCodeFE) => {
    setLoading(true);
    const res = await dispatch(settingsActions.updateTestFailureCode(data));
    setLoading(false);
    const successful = settingsActions.updateTestFailureCode.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.updated"));
    }
    return successful;
  };

  const createTestFailureCode = async (data: Omit<TestFailureCodeFE, "id">) => {
    setLoading(true);
    const res = await dispatch(settingsActions.createTestFailureCode(data));
    setLoading(false);
    const successful = settingsActions.createTestFailureCode.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.created"));
    }
    return successful;
  };

  const deleteTestFailureCode = async (id: string) => {
    setLoading(true);
    const res = await dispatch(settingsActions.deleteTestFailureCode({ id }));
    setLoading(false);
    const successful = settingsActions.deleteTestFailureCode.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.deleted"));
    }
    return successful;
  };

  const changeTestFailureCodeStatus = async (data: TestFailureCodeFE) => {
    setLoading(true);
    const res = await dispatch(
      settingsActions.changeTestFailureCodeStatus({
        id: data.id,
        status: data.status,
      })
    );
    setLoading(false);
    const successful = settingsActions.changeTestFailureCodeStatus.fulfilled.match(
      res
    );
    if (successful) {
      const action =
        data.status === StatusEnum.Active ? "Deactivated" : "Activated";
      notifications.notifySuccess(
        getENText("settings.noti.success.changeStatus", {
          action,
        })
      );
    }
    return successful;
  };

  const validateCell = useCallback(
    (
      row: TestFailureCodeFE,
      val: string | boolean,
      dataKey: keyof TestFailureCodeFE,
      required: boolean
    ) => {
      let error = false;
      let helperText = "";
      if (isBoolean(val)) {
        return {
          error,
          helperText,
        };
      }
      if (!val && required) {
        return {
          error: true,
          helperText: getENText("settings.field.required"),
        };
      }
      if (
        val &&
        rows
          .map(
            (r) =>
              r.id !== row.id && ((r[dataKey] as string) || "").toLowerCase()
          )
          .includes(val.toLowerCase())
      ) {
        error = true;
        helperText = getENText("settings.field.valueExist");
      }

      return {
        error,
        helperText,
      };
    },
    [rows]
  );

  return (
    <SettingsContainer>
      <EditableTable<TestFailureCodeFE>
        style={{
          minWidth: "450px",
        }}
        hasStatusFilter
        actions={[RowActionsEnum.Edit, RowActionsEnum.ChangeStatus]}
        handlers={{
          onRowUpdate: updateTestFailureCode,
          onRowCreate: createTestFailureCode,
          onRowDelete: deleteTestFailureCode,
          onRowChangeSatus: changeTestFailureCodeStatus,
        }}
        title="Set Up Test Failure Codes"
        description={
          <>
            Edit or Add Failure Codes.
            <br />
            Specify to which laboatories they belong.
          </>
        }
        loading={loading}
        columns={columns}
        rows={rows}
        entity="Failure Code"
        validateCell={validateCell}
        inactiveText="Inactive Failure Code won’t be active in the system and won’t be shown in Active Failure Codes Tab. However, Failure Code will be activated automatically in case such code is received in test result files."
      />
    </SettingsContainer>
  );
};

export default TestFailureCodes;
