import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import {
  createStyles,
  makeStyles,
  Radio,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { useFormikContext } from "formik";
import React, { useMemo, useCallback } from "react";
import { FormValues } from "../../types";

const useStyles = makeStyles(({ palette: p }) =>
  createStyles({
    success: {
      color: p.success.main,
    },
    error: {
      color: p.error.main,
    },
    radio: {},
  })
);

interface Props {
  bloodDrawer: BloodDrawer;
}
export const RowGroup = ({ bloodDrawer }: Props) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { price, specialInstructions, takeSampleToTheLab } = useMemo(
    () => ({
      specialInstructions: bloodDrawer.emailCustomInstructions || "",
      price: bloodDrawer.price || "",
      takeSampleToTheLab: bloodDrawer.takesSampleToLab || "",
    }),
    [bloodDrawer]
  );

  const isSelected = useMemo(() => {
    return values.bloodDrawer === bloodDrawer.id;
  }, [values.bloodDrawer, bloodDrawer.id]);

  const onSelect = useCallback(() => {
    setFieldValue("bloodDrawer", bloodDrawer.id);
  }, [setFieldValue, bloodDrawer.id]);

  const classes = useStyles();
  return (
    <TableRow>
      <TableCell>
        <Radio
          className={classes.radio}
          color="primary"
          size="small"
          checked={isSelected}
          onClick={onSelect}
        />
      </TableCell>
      <TableCell>
        <Typography>{bloodDrawer.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{price}</Typography>
      </TableCell>
      <TableCell>
        {bloodDrawer.isPrepaid ? (
          <CheckCircle className={classes.success} />
        ) : (
          <Cancel className={classes.error} />
        )}
      </TableCell>
      <TableCell>
        {bloodDrawer.providesOwnKit ? (
          <CheckCircle className={classes.success} />
        ) : (
          <Cancel className={classes.error} />
        )}
      </TableCell>
      <TableCell>
        <Typography>
          {takeSampleToTheLab ? (
            <CheckCircle className={classes.success} />
          ) : (
            <Cancel className={classes.error} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{specialInstructions}</Typography>
      </TableCell>
    </TableRow>
  );
};
