import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomTextareaField } from "components/CustomTextareaField";

export const ResumeTreatmentForm = () => {
  return (
    <Box height="320px">
      <Box>
        <Typography>
          By resuming the client&apos;s treatment, you agree to allow client to
          continue with the current treatment without changes. If you want the
          client to start a new treatment, update the treatment or create a new
          one
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          Reason for Continuing Treatment *
        </Typography>
        <Box>
          <CustomTextareaField name="reason" rows={4} rowsMax={10} />
        </Box>
      </Box>
    </Box>
  );
};
