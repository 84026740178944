import {
  BloodTestResult,
  ROUTE_CONTACT_RESULTS,
  GETTRResultsWithZohoIdRes,
  ROUTE_TR_S3_PRESIGNED_URL,
  GETTRS3PresignedUrlRes,
  POSTTRResultsRes,
  ROUTE_TR_DELETE_TEST_RESULTS,
  DELETETRResultsQuery,
  GetTestFailureCodesRes,
  testFailureCodesEndpoints,
  GetTestAbnormalFlagsRes,
  testAbnormalFlagsEndpoints,
  ROUTE_TR_SEARCH_OBSERVATIONS,
  GETTRSearchObservationsRes,
  GETTRSearchObservationsParams,
  ROUTE_TR_SEARCH_UNITS,
  GETTRSearchUnitsParams,
  GETTRSearchUnitsRes,
  ROUTE_TR_RESULT,
  PUTTRResultRes,
  testObxCodesEndpoints,
  ROUTE_TR_PATHWAY_RESULT,
  GETTRResultByPathwayRes,
  ROUTE_OBX_HISTORY,
  BloodTestResultItemHistory,
  GETSearchObxCodesByNameOrderedBySearchRes,
  ROUTE_PATHWAY_TR_RESULTS,
  POSTPathwayTRResultRes,
  PUTPathwayTRResultRes,
  ManualResult,
  PathwayManualResult,
  GETSearchTestAbnormalFlagsRes,
  ManualResultType,
  GetPathwayKitPackingDetails,
  PatchCreateBulkConfirmKitPackingJob,
  GetBulkConfirmKitPackingJobStatus,
  PostWaiveChargingClient,
  PostRetestRequiredClientError,
  PostRetestRequiredNotClientError,
  PostApproveChargingClientForRetest,
  PostGenerateRetestInvoice,
  GetTestObxCodeBmhNamesRes,
  GetTestObxCodeUnitsRes,
  GetLabsRes,
  labsEndpoints,
  GetPathways,
  BluePrintStagesEnum,
  GetTestObxCodesRes,
  GetTestObxCodeObservationIdsRes,
} from "@deep-consulting-solutions/bmh-constants";
import { stringify } from "querystring";

import { apiClient, silentClient } from "apis";

export const fetchBloodTestResults = async (zohoId: string) => {
  const res = await apiClient.get<GETTRResultsWithZohoIdRes>(
    ROUTE_CONTACT_RESULTS.replace(":zohoID", zohoId)
  );

  return res.data.data.results;
};

export const fetchResultPDFUrl = async ({ id }: { id: string }) => {
  const res = await apiClient.get<GETTRS3PresignedUrlRes>(
    ROUTE_TR_S3_PRESIGNED_URL.replace(":id", id)
  );

  return res.data.data.url;
};

export const searchObservationsByID = async (term: string) => {
  const query: GETTRSearchObservationsParams = {
    search: term,
  };
  const res = await apiClient.get<GETTRSearchObservationsRes>(
    `${ROUTE_TR_SEARCH_OBSERVATIONS}?${stringify(query as any)}`
  );

  return res.data.data.results;
};

export const searchResultByName = async (term: string) => {
  const query = {
    search: term,
    size: 10,
  };

  const res = await apiClient.get(
    `${testObxCodesEndpoints.searchByName}?${stringify(query as any)}`
  );

  return res.data.data.results as string[];
};

export const searchBmhNameOrderedBySearch = async (term: string) => {
  const query = {
    search: term,
    size: 10,
  };

  const res = await apiClient.get<GETSearchObxCodesByNameOrderedBySearchRes>(
    `${testObxCodesEndpoints.searchBmhNameOrderedBySearch}?${stringify(
      query as any
    )}`
  );

  return res.data.data;
};

export const searchUnitByName = async (term: string) => {
  const query: GETTRSearchUnitsParams = {
    search: term,
  };

  const res = await apiClient.get<GETTRSearchUnitsRes>(
    `${ROUTE_TR_SEARCH_UNITS}?${stringify(query as any)}`
  );

  return res.data.data.results;
};

export const searchAbnormalFlag = async (term: string) => {
  const query = {
    search: term,
    size: 10,
  };

  const res = await apiClient.get<GETSearchTestAbnormalFlagsRes>(
    `${testAbnormalFlagsEndpoints.getSearch}?${stringify(query as any)}`
  );

  return res.data.data;
};

export const getS3SignedUrl = async (file: File) => {
  const res = await apiClient.post<{ data: { url: string; key: string } }>(
    "/blood-test-result/signed-url",
    {
      fileName: file.name,
      contentType: file.type,
    }
  );

  return res.data.data;
};

export const uploadFileToS3 = async (data: File, url: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = (e) => {
      resolve(e);
    };
    xhr.onerror = reject;
    xhr.send(data);
  });
};

export const createNewResult = async ({
  zohoId,
  data,
}: {
  zohoId: string;
  data: ManualResult | Omit<PathwayManualResult, "name">;
}) => {
  if (data.type === ManualResultType.PathwayManualResult) {
    const res = await apiClient.post<POSTPathwayTRResultRes>(
      ROUTE_PATHWAY_TR_RESULTS.replace(":zohoID", zohoId),
      data
    );
    return res.data.data.result;
  }

  const res = await apiClient.post<POSTTRResultsRes>(
    ROUTE_CONTACT_RESULTS.replace(":zohoID", zohoId),
    data
  );

  return res.data.data.result;
};

export const updateResult = async ({
  data,
  target,
  zohoId,
}: {
  data: ManualResult | Omit<PathwayManualResult, "name">;
  target: BloodTestResult;
  zohoId: string;
}) => {
  if (data.type === ManualResultType.PathwayManualResult) {
    const res = await apiClient.patch<PUTPathwayTRResultRes>(
      ROUTE_PATHWAY_TR_RESULTS.replace(":zohoID", zohoId),
      data
    );
    return res.data.data.result;
  }

  const res = await apiClient.patch<PUTTRResultRes>(
    ROUTE_TR_RESULT.replace(":zohoID", zohoId).replace(":id", target.id),
    data
  );
  return res.data.data.result;
};

export const deleteResults = async (ids: string[]) => {
  const query: DELETETRResultsQuery = {
    ids: ids.join(","),
  };
  await apiClient.delete(
    `${ROUTE_TR_DELETE_TEST_RESULTS}?${stringify(query as any)}`
  );
};

export const fetchAbnormalFlags = async () => {
  const res = await apiClient.get<GetTestAbnormalFlagsRes>(
    testAbnormalFlagsEndpoints.getOrPost
  );

  return res.data.data;
};

export const fetchFailureCodes = async () => {
  const res = await apiClient.get<GetTestFailureCodesRes>(
    testFailureCodesEndpoints.getOrPost
  );

  return res.data.data;
};

export const fetchBloodTestResultByPathway = async (zohoId: string) => {
  const res = await apiClient.get<GETTRResultByPathwayRes>(
    ROUTE_TR_PATHWAY_RESULT.replace(":zohoID", zohoId)
  );

  return res.data.data.result;
};

export const fetchHistoricalObx = async (
  contactZohoID: string,
  obxId: string
) => {
  const res = await apiClient.get(
    ROUTE_OBX_HISTORY.replace(":zohoID", contactZohoID).replace(":id", obxId)
  );
  return res.data.data.results as BloodTestResultItemHistory[];
};

export const fetchBloodTestResultsByIDs = async (zohoIDs: string[]) => {
  const res = await apiClient.post<typeof GetPathwayKitPackingDetails["Res"]>(
    GetPathwayKitPackingDetails.ROUTE,
    {
      ids: zohoIDs,
    }
  );
  return res.data.data.pathways;
};

export const patchBloodTestResultShippingKitStatusesToPendingKitShippingByIDs = async (
  pathwayZohoIDs: string[]
) => {
  const res = await apiClient.patch<
    typeof GetBulkConfirmKitPackingJobStatus["Res"]
  >(PatchCreateBulkConfirmKitPackingJob.ROUTE, { pathwayZohoIDs });
  return res.data.data;
};

export const getBulkConfirmKitPackingJobStatus = async (
  jobId: string | number
) => {
  const res = await apiClient.get<
    typeof GetBulkConfirmKitPackingJobStatus["Res"]
  >(GetBulkConfirmKitPackingJobStatus.ROUTE.replace(":jobId", jobId as string));
  return res.data.data;
};

export const waiveChargingClientForRetest = async (zohoID: string) => {
  const res = await apiClient.post<typeof PostWaiveChargingClient.Res>(
    PostWaiveChargingClient.ROUTE.replace(":zohoID", zohoID)
  );

  return res.data.data;
};

export const postRetestRequiredClientError = async (
  zohoID: string,
  body: typeof PostRetestRequiredClientError["Body"]
) => {
  const res = await apiClient.post<typeof PostRetestRequiredClientError["Res"]>(
    PostRetestRequiredClientError.ROUTE.replace(":zohoID", zohoID),
    body
  );
  return res.data.data;
};

export const postRetestRequiredNotClientError = async (
  zohoID: string,
  body: typeof PostRetestRequiredNotClientError["Body"]
) => {
  const res = await silentClient.post<
    typeof PostRetestRequiredNotClientError["Res"]
  >(PostRetestRequiredNotClientError.ROUTE.replace(":zohoID", zohoID), body);
  return res.data.data;
};

export const postApproveChargingClientForRetest = async (zohoID: string) => {
  const res = await silentClient.post<
    typeof PostApproveChargingClientForRetest["Res"]
  >(PostApproveChargingClientForRetest.ROUTE.replace(":zohoID", zohoID));
  return res.data.data;
};

export const postGenerateRetestInvoice = async (zohoID: string) => {
  const res = await silentClient.post<typeof PostGenerateRetestInvoice["Res"]>(
    PostGenerateRetestInvoice.ROUTE.replace(":zohoID", zohoID)
  );
  return res.data.data;
};

export const getAllObxCodesBmhNames = async () => {
  const res = await apiClient.get<GetTestObxCodeBmhNamesRes>(
    testObxCodesEndpoints.bmhNames
  );

  return res.data.data;
};

export const getAllObxCodesUnits = async () => {
  const res = await apiClient.get<GetTestObxCodeUnitsRes>(
    testObxCodesEndpoints.units
  );

  return res.data.data;
};

export const getAllObservationIDs = async () => {
  const res = await apiClient.get<GetTestObxCodeObservationIdsRes>(
    `${testObxCodesEndpoints.observationIds}`
  );

  return res.data.data.observationIds;
};

export const getBloodTestingLabs = async () => {
  const res = await apiClient.get<GetLabsRes>(labsEndpoints.labs);
  return res.data.data;
};

export const getRelatedPendingManualResultsForContact = async ({
  contactZohoId,
}: {
  contactZohoId: string;
}) => {
  const query = {
    contactZohoId,
    stage: BluePrintStagesEnum.PENDING_MANUAL_RESULTS,
  };
  const res = await apiClient.get<typeof GetPathways["Res"]>(
    `${GetPathways.ROUTE}?${stringify(query)}`
  );
  return res.data.data.pathways;
};

export const fetchFullObxCodeNames = async () => {
  const res = await apiClient.get<GetTestObxCodesRes>(
    testObxCodesEndpoints.getOrPost
  );

  return res.data.data;
};

export const fetchLabsOrder = async () => {
  const res = await apiClient.get<{
    data: {
      lab: {
        id: string;
        zohoID: string;
        name: string;
        key: string;
      };
      rank: number;
    }[];
  }>(`/labs/display-order`);
  return res.data.data;
};
