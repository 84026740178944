import { CreateTreatment } from "components/Treatments";
import { Formik, FormikConfig, FormikErrors, FormikHelpers } from "formik";
import {
  clearSavedDefaultValues,
  getCompleteTreatmentDefaultValues,
  getCompleteTreatmentPayload,
  getDoctorName,
  getMissingRequiredFields,
  getSavedDefaultValues,
  getTreatmentValidationSchema,
  isStringEqual,
  validateTreatmentForm,
} from "helpers";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { notifications, treatmentServices, zohoServices } from "services";
import {
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoDiagnosisOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoTreatment,
  ZohoTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
} from "types";
import { AxiosError } from "axios";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { Box } from "@material-ui/core";
import { BlockWidgetAlert } from "./BlockWidgetAlert";

const dummyData = {
  doctor: null,
  diagnosis: [],
  treatmentTreatmentAreas: [],
  instructions: "",
  treatmentPeriod: null,
};

interface Props {
  isCustomButton?: boolean;
}

export const CompleteTreatmentPlan = ({ isCustomButton = false }: Props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [treatmentNotPendingDetails, setTreatmentNotPendingDetails] = useState(
    false
  );
  const [diagnosisOptions, setDiagnosisOptions] = useState<
    ZohoDiagnosisOption[]
  >([]);
  const [fetchError, setFetchError] = useState("");
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [id] = useSelector(zohoSelectors.getIds);
  const [treatment, setTreatment] = useState<ZohoTreatment | null>(null);
  const [doctors, setDoctors] = useState<ZohoBMHDoctor[]>([]);

  const [treatmentAreas, setTreatmentAreas] = useState<ZohoTreatmentArea[]>([]);
  const [dosingAdminRoutes, setDosingAdminRoutes] = useState<
    ZohoDosingAdminRoute[]
  >([]);
  const [medicineAdminRoutes, setMedicineAdminRoute] = useState<
    ZohoMedicineAdminRoute[]
  >([]);
  const [dosingTypes, setDosingTypes] = useState<ZohoDosingType[]>([]);

  const [doctor, setDoctor] = useState<ZohoBMHDoctor | null>(null);
  const [client, setClient] = useState<ZohoClientRecord | null>(null);
  const [treatmentTreatmentAreas, setTreatmentTreatmentAreas] = useState<
    ZohoTreatmentTreatmentArea[]
  >([]);
  const [suggestedMedicines, setSuggestedMedicines] = useState<
    ZohoTreatmentMedicine[]
  >([]);
  const [fetchedAllData, setIsFetchedAllData] = useState(false);
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);

  const missingRequiredFields = useMemo(() => {
    return getMissingRequiredFields(client);
  }, [client]);

  const hasMissingRequiredFields = useMemo(
    () => !!missingRequiredFields.length,
    [missingRequiredFields.length]
  );

  const initialValues = useMemo(() => {
    if (!fetchedAllData || !treatment) {
      return dummyData;
    }
    const savedDefaultValue = getSavedDefaultValues(
      "complete-treatment",
      id,
      [treatment.Modified_Time]
        .concat(treatmentTreatmentAreas.map((t) => t.Modified_Time))
        .concat(suggestedMedicines.map((s) => s.Modified_Time))
    );

    if (savedDefaultValue) {
      setImmediate(() => {
        setSavedDefaultValues({
          ...savedDefaultValue,
          doctor: doctor?.id
            ? { title: getDoctorName(doctor), value: doctor.id }
            : savedDefaultValue.doctor,
        });
      });
    }
    const defaultValues = getCompleteTreatmentDefaultValues(
      treatment,
      doctors,
      client,
      treatmentTreatmentAreas,
      suggestedMedicines,
      treatmentAreas,
      diagnosisOptions
    );
    return {
      ...defaultValues,
      doctor: doctor?.id
        ? { title: getDoctorName(doctor), value: doctor.id }
        : defaultValues.doctor,
    };
  }, [
    treatment,
    client,
    doctors,
    treatmentTreatmentAreas,
    suggestedMedicines,
    treatmentAreas,
    id,
    fetchedAllData,
    doctor,
    diagnosisOptions,
  ]);

  const validationSchema = useMemo(() => {
    return getTreatmentValidationSchema();
  }, []);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }

    if (treatmentNotPendingDetails || hasMissingRequiredFields) {
      return "initial-block";
    }
    return "form";
  }, [fetchError, treatmentNotPendingDetails, hasMissingRequiredFields]);

  const otherMedicineTypeId = useMemo(() => {
    const otherTreatmentArea = treatmentAreas.find((t) =>
      isStringEqual(t.Name, "Other")
    );
    if (!otherTreatmentArea) {
      return "";
    }

    return (
      otherTreatmentArea.medicineType.find((m) =>
        isStringEqual(m.Name, "Other")
      )?.id || ""
    );
  }, [treatmentAreas]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [widgetDetails, currentUserRes, { fields }] = await Promise.all([
        treatmentServices.fetchCompleteTreatmentWidgetDetails(id),
        dispatch(zohoActions.fetchCurrentUser()),
        zohoServices.getFields("Treatments"),
      ]);
      if (widgetDetails.treatment?.State !== "Pending Details") {
        setTreatmentNotPendingDetails(true);
      } else {
        setTreatment(widgetDetails.treatment);
        setClient(widgetDetails.contact);
        const doctorRes = await treatmentServices.fetchUserDoctorProfile(
          (currentUserRes as any)?.payload?.id
        );

        const diagnosisField = fields.find(
          (f: any) => f.api_name === "Diagnosis"
        );

        setDiagnosisOptions(diagnosisField.pick_list_values);
        setDoctors(widgetDetails.doctors);
        setTreatmentAreas(widgetDetails.treatmentAreas);
        setDosingAdminRoutes(widgetDetails.dosingAdminRoutes);
        setDosingTypes(widgetDetails.dosingTypes);
        setMedicineAdminRoute(widgetDetails.medicineAdminRoutes);
        const userDoctor = (widgetDetails.doctors as ZohoBMHDoctor[]).find(
          (d) => d.id === doctorRes.doctorId
        );
        setSuggestedMedicines(widgetDetails.suggestedMedicines);
        setTreatmentTreatmentAreas(widgetDetails.treatmentTreatmentArea);
        if (
          userDoctor &&
          isStringEqual(doctorRes.user.role.name, "BMH Doctors")
        ) {
          setDoctor(userDoctor);
        }
      }
      setIsFetchedAllData(true);
    } catch (error) {
      const err = error as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  const submitDetails = useCallback(
    async (
      values: any,
      formErrors: FormikErrors<any>,
      setFieldTouched: FormikHelpers<any>["setFieldTouched"],
      finalize?: boolean,
      draft?: boolean
    ) => {
      try {
        setLoading(true);
        if (!draft) {
          const isOk = validateTreatmentForm(formErrors, setFieldTouched);
          if (!isOk) {
            return;
          }
        }

        const payload = getCompleteTreatmentPayload(
          id,
          values,
          client!,
          user!,
          doctor,
          treatmentAreas,
          medicineAdminRoutes,
          otherMedicineTypeId,
          finalize,
          draft
        );
        await treatmentServices.completeTreatment(payload);
        clearSavedDefaultValues("complete-treatment", id);
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [
      client,
      user,
      doctor,
      treatmentAreas,
      medicineAdminRoutes,
      id,
      otherMedicineTypeId,
    ]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting, validateForm, setFieldTouched }) => {
      const errors = await validateForm();
      await submitDetails(values, errors, setFieldTouched);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <Box>
        <DefaultValuesStore widgetName="complete-treatment" identifier={id} />
        {savedDefaultValues && fetchedAllData ? (
          <SetInitialValues savedDefaultValues={savedDefaultValues} />
        ) : null}
        <CreateTreatment
          isCustomButton={isCustomButton}
          loading={loading}
          submitDetails={submitDetails}
          blockWidgetAlert={
            <BlockWidgetAlert missingRequiredFields={missingRequiredFields} />
          }
          treatmentAreas={treatmentAreas}
          dosingTypes={dosingTypes}
          dosingAdminRoutes={dosingAdminRoutes}
          medicineAdminRoutes={medicineAdminRoutes}
          doctor={doctor}
          doctors={doctors}
          createdTreatment={null}
          fetchError={fetchError}
          client={client}
          view={view}
          title="Complete Treatment Plan Details"
          submitButtonText="COMPLETE TREATMENT PLAN"
          cancelDialog={{
            title: "Cancel Completing Treatment Plan",
            text:
              "All the data you entered will be discarded and the Treatment Plan will not be completed at this time. Do you want to continue Treatment Plan completion, or do you want to cancel and discard all the data you entered?",
            proceedButtonText: "CANCEL AND DISCARD ALL DATA",
            cancelButtonText: "CONTINUE TREATMENT PLAN COMPLETION",
          }}
          otherMedicineTypeId={otherMedicineTypeId}
          diagnosisOptions={diagnosisOptions}
        />
      </Box>
    </Formik>
  );
};
