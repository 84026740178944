import {
  BloodTestResult,
  UnmatchedBloodTestResultStatusEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useMemo } from "react";
import { ComparisonView } from "./ComparisonView";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(2),
    width: "100%",
  },
  leftWarningBorder: {
    borderLeft: `6px solid ${p.warning.main}`,
    paddingLeft: s(1),
  },
  clotted: {
    color: p.warning.main,
  },
  abnormal: {
    color: p.error.main,
  },
  leftErrorBorder: {
    borderLeft: `6px solid ${p.error.main}`,
    paddingLeft: s(1),
  },
  info: {
    marginBottom: s(4),
  },
}));

interface Props {
  newResult?: BloodTestResult | null;
  existingResult?: BloodTestResult | null;
  currentResult?: BloodTestResult | null;
  oldResult?: BloodTestResult | null;
  stage: UnmatchedBloodTestResultStatusEnum;
  isComparisonWidget?: boolean;
}

export const Comparison = ({
  newResult,
  existingResult,
  currentResult,
  oldResult,
  stage,
  isComparisonWidget = false,
}: Props) => {
  const hasFailedResults = useMemo(() => {
    return newResult?.results.some((result) => result.isFailed);
  }, [newResult?.results]);

  const currentResultText = useMemo(() => {
    if (stage === UnmatchedBloodTestResultStatusEnum.DISCARDED) {
      return "the final result in the matching blood test order after discarding this file";
    }

    if (stage === UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT) {
      return "the final result in the matching blood test order after replacing the old result with the new result";
    }

    return "the final result in the matching blood test order after merging the new result with the old result";
  }, [stage]);

  const classes = useStyle();
  return (
    <Box className={classes.wrapper}>
      {isComparisonWidget ? (
        <div className={classes.info}>
          <Box>
            <Typography>
              Old Result: the result in the matching blood test order from an
              older HL7 file
            </Typography>
          </Box>
          <Box>
            <Typography>New Result: the result in this HL7 file</Typography>
          </Box>
          <Box>
            <Typography>Final Result: {currentResultText}</Typography>
          </Box>
        </div>
      ) : null}
      <Grid
        container
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        spacing={10}
      >
        <Grid
          item
          style={{
            flexShrink: 0,
          }}
          xs={5}
        >
          <Grid container spacing={6} wrap="nowrap">
            <Grid item>
              <div className={classes.leftWarningBorder}>
                <Typography>Rows with difference in results</Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography>
                <span className={classes.clotted}>Clotted</span> Failed Result
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6} wrap="nowrap">
            <Grid item>
              <div className={classes.leftErrorBorder}>
                <Typography>Rows with a missing result value</Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography>
                <span className={classes.abnormal}>0.76</span> Abnormal Result
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isComparisonWidget && hasFailedResults ? (
          <Grid item xs={5}>
            <Alert severity="error" icon={<Info fontSize="inherit" />}>
              The Blood Test Order has failed results and retest is being
              arranged. Cancel retest arrangements to be able to merge with
              existing results or replace existing result.
            </Alert>
          </Grid>
        ) : null}
      </Grid>
      <Box mt={2}>
        <ComparisonView
          newResult={newResult}
          existingResult={existingResult}
          stage={stage}
          currentResult={currentResult}
          oldResult={oldResult}
        />
      </Box>
    </Box>
  );
};
