import React, { useEffect, useMemo } from "react";
import { Field, FormikErrors, FormikProps, getIn } from "formik";
import {
  FormControlLabel,
  Grid,
  Divider,
  MenuItem,
  TextField as MuiTextField,
  Typography,
  makeStyles,
  Collapse,
  FormControl,
  FormLabel,
  Radio,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import {
  Lab,
  BloodTakingOptionEnum,
  GenderEnum,
  BloodDrawer,
  BloodDrawBilledStatus,
} from "@deep-consulting-solutions/bmh-constants";
import { TextField, CheckboxWithLabel, RadioGroup } from "formik-material-ui";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { getENText } from "helpers";
import { endOfYesterday } from "date-fns";
import { Contact, ZohoPathway } from "types";
import { Info } from "@material-ui/icons";

import { ShippingAddressFormByCountry } from "components/ShippingAddressForm";

import {
  FormValues,
  OrderTypeEnum,
  TestProfilesObrWithMappedLabs,
  ORDER_TYPE_DESCRIPTION,
  AfterResultConsultationEnum,
} from "./types";
import {
  shouldShowShippingAddress,
  showBDFeeCollectionRequired,
  validateTestProfilesTestToLab,
} from "./helpers";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  orderTypeSubtitle: {
    fontStyle: "italic",
    color: p.grey[600],
  },
  orderTypeRoot: {
    whiteSpace: "unset",
  },
  shippingAddressTitle: {
    marginBottom: s(1),
  },
  feeBannerWrapper: {
    padding: s(2),
    background: "#fef4e7",
    display: "flex",
    borderRadius: s(1),
  },
  feeBannerIcon: {
    marginRight: s(1),
    color: p.warning.main,
  },
  feeBannerInner: {},
  feeBannerTitle: {
    color: "#603e11",
    fontWeight: 600,
  },
  feeBannerSubtitle: {
    color: "#68461a",
    marginTop: s(1),
  },
  billedRadios: {
    marginTop: s(1),
  },
  billedRow: {
    display: "flex",
    alignItems: "flex-start",
  },
  billedWaived: {
    flex: 1,
    textAlign: "center",
  },
  billedInvoice: {
    width: 300,
  },
}));

export const specialNotesMax = 750;

interface FormFieldsProps {
  formProps: FormikProps<FormValues>;
  testProfiles: TestProfilesObrWithMappedLabs[];
  labs: Lab[];
  bloodDrawers: BloodDrawer[];
  client: Contact;
  isAddressChanged: boolean;
  pathway: ZohoPathway;
  validateInitialValues: (
    setErrors: (errors: FormikErrors<FormValues>) => void
  ) => Promise<void>;
}

export const FormFields = ({
  formProps,
  testProfiles,
  labs,
  bloodDrawers,
  client,
  isAddressChanged,
  pathway,
  validateInitialValues,
}: FormFieldsProps) => {
  const { values, touched, errors, setErrors } = formProps;
  const testProfilesError: string | undefined = getIn(errors, "testProfiles");

  const { isTestProfilesTouched } = useMemo(
    () => ({
      isTestProfilesTouched: getIn(touched, "testProfiles"),
    }),
    [touched]
  );

  const testProfileErrorText = useMemo(() => {
    const message = validateTestProfilesTestToLab(
      values.testProfiles,
      values.lab
    );
    if (
      typeof message === "string" ||
      testProfilesError === getENText("validation.testProfiles.notAssociated")
    ) {
      return message;
    }

    if (isTestProfilesTouched) {
      return testProfilesError;
    }

    return undefined;
  }, [
    testProfilesError,
    isTestProfilesTouched,
    values.testProfiles,
    values.lab,
  ]);

  const labError: string | undefined = getIn(errors, "lab");
  const isLabTouched = getIn(touched, "lab");
  const showLabError: boolean = useMemo(() => {
    if (!isLabTouched && labError === getENText("validation.lab.required")) {
      return false;
    }

    return !!labError;
  }, [isLabTouched, labError]);

  const bloodDrawerError: string | undefined = getIn(errors, "bloodDrawer");
  const isBloodDrawerTouched = getIn(touched, "bloodDrawer");

  const bloodDrawerErrorText = useMemo(() => {
    if (bloodDrawerError === getENText("validation.lab.notSameAsBloodDrawer")) {
      return "Incorrect Lab and Blood Drawer combination";
    }

    if (isBloodDrawerTouched) {
      return bloodDrawerError;
    }

    return undefined;
  }, [bloodDrawerError, isBloodDrawerTouched]);

  const sortedTestProfiles = useMemo(() => {
    const cloned = [...testProfiles];
    if (!values.lab) return cloned;
    cloned.sort((a, b) => {
      const isAAssociated = a.mappedLabs[values.lab!.id];
      const isBAssociated = b.mappedLabs[values.lab!.id];
      if (isAAssociated && !isBAssociated) return -1;
      if (!isAAssociated && isBAssociated) return 1;
      return 0;
    });
    return cloned;
  }, [testProfiles, values.lab]);

  const showAddress = useMemo(
    () => shouldShowShippingAddress(values.bloodDrawer),
    [values.bloodDrawer]
  );

  useEffect(() => {
    validateInitialValues(setErrors);
  }, [validateInitialValues, setErrors]);

  const classes = useStyles();
  const clientName =
    `${client.firstName ? `${client.firstName} ` : ""}${client.lastName}` ||
    "Client";

  const shouldShowFeeCollection = showBDFeeCollectionRequired(
    !!values.bloodDrawer?.isPrepaid,
    pathway.Blood_Draw_Fee_Collected
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Field
          name="lab"
          component={Autocomplete}
          options={labs}
          getOptionLabel={(option: Lab) => option.name}
          getOptionSelected={(option: Lab, value: Lab) =>
            option.id === value.id
          }
          style={{ width: "100%" }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name="lab"
              error={showLabError}
              helperText={showLabError && labError}
              label="Diagnostic Laboratory"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          multiple
          name="testProfiles"
          component={Autocomplete}
          options={sortedTestProfiles}
          getOptionLabel={(option: TestProfilesObrWithMappedLabs) => {
            return option.bmhObrCode;
          }}
          getOptionDisabled={(option: TestProfilesObrWithMappedLabs) => {
            return !!values.lab && !option.mappedLabs[values.lab.id];
          }}
          getOptionSelected={(
            option: TestProfilesObrWithMappedLabs,
            value: TestProfilesObrWithMappedLabs
          ) => {
            return option.id === value.id;
          }}
          style={{ width: "100%" }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name="testProfiles"
              error={!!testProfileErrorText}
              helperText={testProfileErrorText}
              label="Test Profiles"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="bloodDrawer"
          component={Autocomplete}
          options={bloodDrawers}
          getOptionLabel={(option: BloodDrawer) => option.name}
          getOptionSelected={(option: BloodDrawer, value: BloodDrawer) =>
            option.id === value.id
          }
          style={{ width: "100%" }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name="bloodDrawer"
              error={!!bloodDrawerErrorText}
              helperText={bloodDrawerErrorText}
              label="Blood Drawer"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          select
          SelectProps={{
            displayEmpty: true,
          }}
          name="bloodTakingOption"
          label="Blood Taking Option"
          required
        >
          {Object.values(BloodTakingOptionEnum).map((gender) => (
            <MenuItem key={gender} value={gender}>
              {gender}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel>After Results Consultation*</FormLabel>
          <Field component={RadioGroup} row name="afterResultConsultation">
            <FormControlLabel
              value={AfterResultConsultationEnum.PCM}
              control={<Radio color="primary" />}
              label={AfterResultConsultationEnum.PCM}
            />
            <FormControlLabel
              value={AfterResultConsultationEnum.DOCTOR}
              control={<Radio color="primary" />}
              label={AfterResultConsultationEnum.DOCTOR}
            />
            <FormControlLabel
              value={AfterResultConsultationEnum.NONE}
              control={<Radio color="primary" />}
              label={AfterResultConsultationEnum.NONE}
            />
          </Field>
        </FormControl>
      </Grid>
      {values.afterResultConsultation !== AfterResultConsultationEnum.NONE && (
        <>
          <Grid item xs={6}>
            <Field
              component={TextField}
              select
              SelectProps={{
                displayEmpty: true,
                renderValue: (value: any) => value,
              }}
              name="orderType"
              label="Client Type"
              required
            >
              {Object.values(OrderTypeEnum).map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  classes={{ root: classes.orderTypeRoot }}
                >
                  <div style={{}}>
                    <Typography>{type}</Typography>
                    <Typography
                      variant="caption"
                      className={classes.orderTypeSubtitle}
                    >
                      {ORDER_TYPE_DESCRIPTION[type]}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      <Grid item xs={12} sm={6}>
        <Field
          component={FormikDatePicker}
          disablePast={false}
          disableToolbar={false}
          disableFuture
          maxDate={endOfYesterday()}
          maxDateMessage={getENText("validation.dob.inPast")}
          label={`${clientName} - Date of Birth`}
          name="clientDob"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          select
          SelectProps={{
            displayEmpty: true,
          }}
          name="clientGender"
          label={`${clientName} - Gender`}
          required
        >
          <MenuItem value={GenderEnum.male}>Male</MenuItem>
          <MenuItem value={GenderEnum.female}>Female</MenuItem>
        </Field>
      </Grid>

      <Grid item xs={12}>
        <Field
          component={TextField}
          name="specialNotes"
          label="Special Notes (will appear on Order Form)"
          multiline
          max={specialNotesMax}
          helperText={getENText("validation.specialNotes.max", {
            max: specialNotesMax.toString(),
          })}
        />
      </Grid>

      {showAddress && (
        <>
          <Grid item xs={12}>
            <div className={classes.shippingAddressTitle}>
              <Typography variant="h5">Shipping Address</Typography>
            </div>

            <Divider />
          </Grid>

          <ShippingAddressFormByCountry formikProps={formProps} />

          <Grid item xs={12}>
            <Collapse in={isAddressChanged}>
              <Field
                component={CheckboxWithLabel}
                name="updateAddressForClient"
                type="checkbox"
                Label={{
                  label:
                    "You have edited the client's shipping address for this blood test order. Leave the box checked if you would like to also update the main profile shipping address accordingly for all other blood test orders and medication shipments, otherwise uncheck the box",
                }}
                color="primary"
              />
            </Collapse>
          </Grid>
        </>
      )}

      {shouldShowFeeCollection && (
        <Grid item xs={12}>
          <div className={classes.feeBannerWrapper}>
            <Info className={classes.feeBannerIcon} />
            <div className={classes.feeBannerInner}>
              <Typography className={classes.feeBannerTitle}>
                Blood Draw Fee Must Be Collected!
              </Typography>
              <Typography className={classes.feeBannerSubtitle}>
                Our records indicate that the Blood Drawer you selected requires
                a fee to be paid to them by BMH. BMH thus must bill the client
                for it in return. You therefore need to bill the client
                according to the terms of this Blood Drawer
              </Typography>
            </div>
          </div>

          <div className={classes.billedRadios}>
            <FormControl>
              <Field component={RadioGroup} row name="billedStatus">
                <div className={classes.billedRow}>
                  <FormControlLabel
                    value={BloodDrawBilledStatus.BILLED}
                    control={<Radio color="primary" />}
                    label="I have billed client for the blood draw fee"
                  />
                  <div className={classes.billedWaived}>
                    <FormControlLabel
                      value={BloodDrawBilledStatus.WAIVED}
                      control={<Radio color="primary" />}
                      label="I obtained management approval to waive the fee"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={BloodDrawBilledStatus.NOT_BILLED}
                      control={<Radio color="primary" />}
                      label="I didn't bill the client yet"
                    />
                    <Typography variant="body2">
                      The client will be invoiced automatically
                    </Typography>
                  </div>
                </div>
              </Field>
            </FormControl>
          </div>

          {values.billedStatus === BloodDrawBilledStatus.BILLED && (
            <div>
              <Field
                component={TextField}
                name="billInvoiceNumber"
                label="Blood Draw Invoice Number"
                required
                className={classes.billedInvoice}
              />
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};
