import React, { useMemo } from "react";
import { Chip } from "@material-ui/core";

interface OrderStatusProps {
  data: {
    State: string;
  };
}

export const OrderStatus = ({ data }: OrderStatusProps) => {
  const { State: orderState } = data;

  const owed = useMemo(() => orderState === "Owed", [orderState]);

  const detailsSpecified = useMemo(() => orderState === "Details Specified", [
    orderState,
  ]);

  const pendingResolvingMedicationUnavailability = useMemo(
    () => orderState === "Pending Resolving Medication Unavailability",
    [orderState]
  );

  const sentToPharmacy = useMemo(() => orderState === "Sent to Pharmacy", [
    orderState,
  ]);

  if (owed) {
    return (
      <Chip
        style={{
          borderRadius: "6px",
          backgroundColor: "#F44336",
          minWidth: "100px",
        }}
        color="primary"
        size="small"
        label="Owed"
      />
    );
  }
  if (detailsSpecified) {
    return (
      <Chip
        style={{ borderRadius: "6px", backgroundColor: "#4CAF50" }}
        color="primary"
        size="small"
        label="Details Specified"
      />
    );
  }
  if (sentToPharmacy) {
    return (
      <Chip
        style={{ borderRadius: "6px", backgroundColor: "#4CAF50" }}
        color="primary"
        size="small"
        label="Sent to Pharmacy"
      />
    );
  }
  if (pendingResolvingMedicationUnavailability) {
    return (
      <Chip
        style={{ borderRadius: "6px", backgroundColor: "#ED6C02" }}
        color="primary"
        size="small"
        label="Pending Resolving Medication Unavailability"
      />
    );
  }
  return (
    <Chip
      style={{ borderRadius: "6px", backgroundColor: "#FFF446" }}
      size="small"
      label="Being Processed"
    />
  );
};
