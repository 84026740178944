import React, { useCallback, useMemo } from "react";
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { getIn, useFormikContext } from "formik";

export const CustomRadioGroupField = ({
  name,
  options,
  helperText = "",
}: {
  name: string;
  options: { value: string; label: string }[];
  helperText?: string;
}) => {
  const {
    setFieldValue,
    values,
    isSubmitting,
    touched,
    errors,
  } = useFormikContext();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.target.value);
    },
    [name, setFieldValue]
  );

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  const error = fieldTouched && !!fieldError;

  const helpText = error ? fieldError : helperText;

  return (
    <FormControl component="fieldset">
      <RadioGroup name={name} value={getIn(values, name)} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            disabled={isSubmitting}
          />
        ))}
      </RadioGroup>
      <FormHelperText error>{helpText}</FormHelperText>
    </FormControl>
  );
};
