/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { zohoServices } from "services";
import { Formik, FormikConfig } from "formik";

import { DoctorField } from "components/Treatments/DoctorField/DoctorField";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { ZohoBMHDoctor, ZohoClientRecord } from "types";
import {
  venesectionTreatmentValidationSchema,
  getContactName,
  isStringEqual,
} from "helpers";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  createVenesectionTreatment,
  getClient,
  getDoctors,
  getUserDoctorProfile,
} from "services/treatments";
import { CustomTextareaField } from "components/CustomTextareaField/CustomTextareaField";
import Loader from "components/Loader";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import { ActionButtons } from "./ActionButtons";

const clientRequiredFieldsLabelMap = {
  shippingStreet: "Shipping Street",
  shippingCity: "Shipping City",
  shippingState: "Shipping Province",
  shippingZip: "Shipping Post Code",
  shippingCountry: "Shipping Country",
  phone: "Phone",
};

type ClientRequiredFields = {
  shippingStreet: string | null;
  shippingCity: string | null;
  shippingState: string | null;
  shippingZip: string | null;
  shippingCountry: string | null;
  phone: string | null;
};

export const CreateVenesectionTreatment = () => {
  const [loading, setLoading] = useState(true);
  const [
    requiredFields,
    setRequiredFields,
  ] = useState<ClientRequiredFields | null>(null);
  const [submitData, setSubmitData] = useState(false);
  const [doctors, setDoctors] = useState<ZohoBMHDoctor[]>([]);
  const [doctor, setDoctor] = useState<ZohoBMHDoctor | null>(null);
  const [client, setClient] = useState<ZohoClientRecord | null>(null);
  const contactId = useSelector(zohoSelectors.getIds)[0];
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [createdTreatmentUrl, setCreatedTreatmentUrl] = useState("");

  const clientFullName = useMemo(() => (client ? getContactName(client) : ""), [
    client,
  ]);

  const hasAllRequiredFields = useMemo(() => {
    if (!loading && requiredFields) {
      return Object.values(requiredFields).every((f) => !!f);
    }
    return false;
  }, [loading, requiredFields]);

  const falsyFieldsLabels = useMemo(() => {
    if (!loading && requiredFields) {
      return Object.entries(requiredFields)
        .filter(([, value]) => !value)
        .map(
          ([key]) =>
            clientRequiredFieldsLabelMap[key as keyof ClientRequiredFields]
        );
    }
    return [];
  }, [loading, requiredFields]);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          doctor: values.doctor.value,
          instructions: values.venesectionInstructions,
          client: contactId,
          userId: user?.id as string,
        };
        const newTreatmentUrl = await createVenesectionTreatment(payload);
        setCreatedTreatmentUrl(newTreatmentUrl);
      } catch (error) {
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [contactId, user?.id]
  );

  const fetchData = useCallback(async () => {
    try {
      const [doctorsRes, doctorRes, clientRes] = await Promise.all([
        getDoctors(),
        getUserDoctorProfile(user?.id as string),
        getClient(contactId),
      ]);

      if (clientRes) {
        setRequiredFields({
          shippingStreet: clientRes.Shipping_Street,
          shippingCity: clientRes.Shipping_City,
          shippingState: clientRes.Shipping_State,
          shippingZip: clientRes.Shipping_Zip,
          shippingCountry: clientRes.Shipping_Country,
          phone: clientRes.Phone || clientRes.Mobile,
        });
      }

      setDoctors(doctorsRes);
      setClient(clientRes);
      const userDoctor = doctorsRes.find((d) => d.id === doctorRes.doctorId);
      if (
        userDoctor &&
        isStringEqual(doctorRes?.user?.role.name, "BMH Doctors")
      ) {
        setDoctor(userDoctor);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [contactId, user?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Formik
      initialValues={{ doctor: null, venesectionInstructions: "" }}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={venesectionTreatmentValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle
          isCustomButton
          title="Create Venesection Treatment"
        />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "450px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {createdTreatmentUrl && (
                <Box height="0px">
                  <Alert severity="success">
                    New Treatment Created Successfully.
                  </Alert>
                </Box>
              )}
              {!loading && !hasAllRequiredFields && (
                <Alert severity="error">
                  <AlertTitle>
                    A Treatment cannot be created before entering the following
                    details for this Client:
                  </AlertTitle>
                  <Box component="ul" margin={0}>
                    {falsyFieldsLabels.map((label) => (
                      <li key={label}>{label}</li>
                    ))}
                  </Box>
                </Alert>
              )}
              {!loading && !createdTreatmentUrl && hasAllRequiredFields && (
                <Box display="flex" flexDirection="column">
                  <Typography>
                    Client Name: <strong>{clientFullName}</strong>
                  </Typography>
                  <Box maxWidth="400px" mt={doctor?.id ? 0 : 2}>
                    {!doctor?.id && (
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          marginBottom: "4px",
                        }}
                      >
                        Doctor *
                      </Typography>
                    )}
                    <DoctorField
                      doctors={doctors}
                      doctor={doctor}
                      showLabel={false}
                    />
                  </Box>
                  <Box maxWidth="700px" mt={doctor?.id ? 2 : 0}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", marginBottom: "4px" }}
                    >
                      Venesection Instructions *
                    </Typography>
                    <CustomTextareaField
                      name="venesectionInstructions"
                      rows={14}
                      rowsMax={18}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons
              closeWidget={closeWidget}
              createdTreatmentUrl={createdTreatmentUrl}
              hasAllRequiredFields={hasAllRequiredFields}
            />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
