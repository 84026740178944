import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Mcm } from "@deep-consulting-solutions/bmh-constants";

import type { ContactsState, AppState } from "redux/types";

import {
  getAssignableQualifiedMcmsRequest,
  assignMcmRequest,
} from "./requests";

const ENTITY = "contacts";

const initialState: ContactsState = {
  assignableQualifiedMcms: [],
};

const getAssignableQualifiedMcms = createAsyncThunk<Mcm[]>(
  `${ENTITY}/getAssignableQualifiedMcms`,
  async () => {
    const assignableQualifiedMcms = await getAssignableQualifiedMcmsRequest();
    return assignableQualifiedMcms;
  }
);

const assignMcm = createAsyncThunk<
  Record<string, never>,
  { mcmId: string; contactsZohoIds: string[] }
>(`${ENTITY}/assignMcm`, async ({ mcmId, contactsZohoIds }) => {
  const res = await assignMcmRequest({ mcmId, contactsZohoIds });
  return res;
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getAssignableQualifiedMcms.fulfilled, (state, action) => {
      state.assignableQualifiedMcms = action.payload.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }),
});

const getMcms = (state: AppState) => state.contacts.assignableQualifiedMcms;

export const contactsSelectors = {
  getMcms,
};

export const contactsActions = {
  getAssignableQualifiedMcms,
  assignMcm,
};

export default slice.reducer;
