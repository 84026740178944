import { TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { getFieldName, getValueFromObject, sortAlphabetically } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption } from "types";

interface Props {
  countries: string[];
  name?: string;
}

export const CountryAutoCompleteField = ({ countries, name }: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("country", name), [name]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(
    () =>
      sortAlphabetically(
        countries.map((country) => ({
          title: country,
          value: country,
        }))
      ),
    [countries]
  );

  const onChange = useCallback(
    (_: any, value: string, reason: AutocompleteChangeReason) => {
      if (reason === "select-option" && value) {
        setFieldValue(fieldName, value);
      }
      if (reason === "clear") {
        setFieldValue(fieldName, "");
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Field
      required
      name={fieldName}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={fieldName}
          variant="outlined"
          label="Country"
          helperText={fieldTouched && fieldError}
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
