import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { TreatmentOrderItemProps } from "../types";
import { pluralToSingular } from "../utils";

export const ChildItem = ({ data }: { data: TreatmentOrderItemProps }) => {
  const name = useMemo(
    () => data.Item.Name_For_Prescription || data.Item.Product_Name,
    [data.Item.Name_For_Prescription, data.Item.Product_Name]
  );
  const isInjectionBundle = data.Item.Product_Type === "Injection Bundle";
  const isBacteriostaticWater = name.toLowerCase().includes("bacteriostatic");

  const quantityInOrder = isInjectionBundle
    ? `${data.Quantity} Bundle`
    : data.Quantity;

  const customUnitPrice = data.Custom_Unit_Price || 0;

  const price = useMemo(
    () => parseFloat(data.Price_Used || customUnitPrice.toString()).toFixed(2),
    [customUnitPrice, data.Price_Used]
  );

  const title = isBacteriostaticWater
    ? "Bacteriostatic NACL Water"
    : "Injection Bundle";

  const unitVolume = `${data.Item.Usage_Unit_Volume} ml ${data.Item.Med_Usage_Unit?.name}`;
  const enoughFor =
    data.Item.Enough_For === "Month"
      ? `${data.Duration_Period} month`
      : data.Item.Enough_For;

  const syringeVolume = data.Item.Syringe_Volume_ml || "";
  return (
    <Box width="100%" bgcolor="#F4F4F4" p={2}>
      <Typography
        variant="body2"
        style={{ color: "#4c6890", marginBottom: "8px" }}
      >
        {title}
      </Typography>
      <Box
        display="flex"
        border={1}
        borderRadius="4px"
        width="100%"
        borderColor="rgba(76, 104, 144, 0.6)"
        bgcolor="#fff"
        p="12px"
        flexWrap="wrap"
        gridGap="16px"
      >
        <Box maxWidth={220}>
          <Typography variant="body2" style={{ color: "#4c6890" }}>
            Product
          </Typography>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {name}
          </Typography>
        </Box>
        <Box width={150}>
          <Typography variant="body2" style={{ color: "#4c6890" }}>
            Pharmacy
          </Typography>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {data.Item.Pharmacy?.name || "N/A"}
          </Typography>
        </Box>
        <Box width={140}>
          <Typography variant="body2" style={{ color: "#4c6890" }}>
            Quantity
          </Typography>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {quantityInOrder}
          </Typography>
        </Box>
        {isInjectionBundle && (
          <Box width={140}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Syringe Volume
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {syringeVolume}
            </Typography>
          </Box>
        )}
        {!isInjectionBundle && (
          <Box width={140}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Unit Volume
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {unitVolume}
            </Typography>
          </Box>
        )}
        {isInjectionBundle && (
          <Box width={120}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Enough For
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {enoughFor}
            </Typography>
          </Box>
        )}
        <Box width={100}>
          <Typography variant="body2" style={{ color: "#4c6890" }}>
            Price(£)
          </Typography>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {price}
          </Typography>
        </Box>
        <Box maxWidth={400}>
          <Typography variant="body2" style={{ color: "#4c6890" }}>
            Description
          </Typography>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {data.Item?.Description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TreatmentOrderItem = ({
  data,
}: {
  data: TreatmentOrderItemProps;
}) => {
  const name = useMemo(
    () => data.Item.Name_For_Prescription || data.Item.Product_Name,
    [data.Item.Name_For_Prescription, data.Item.Product_Name]
  );
  const usageNotes = useMemo(() => data.Usage_Note || "N/A", [data.Usage_Note]);
  const usageInstruction = useMemo(() => data.Usage_Instructions || "", [
    data.Usage_Instructions,
  ]);
  const customDosingInstructions = useMemo(
    () =>
      data.Specify_Custom_Dosing_Instructions
        ? data.Custom_Dosing_Instructions
        : "",
    [data.Specify_Custom_Dosing_Instructions, data.Custom_Dosing_Instructions]
  );
  const duration = useMemo(() => {
    const unit =
      data.Duration_Period === 1 && data.Duration_Unit
        ? pluralToSingular(data.Duration_Unit)
        : data.Duration_Unit;
    return data.Duration_Period && data.Duration_Unit
      ? `${data.Duration_Period} ${unit}`
      : "N/A";
  }, [data.Duration_Period, data.Duration_Unit]);
  const customUnitPrice = data?.Custom_Unit_Price || 0;
  const price = useMemo(
    () => parseFloat(data.Price_Used || customUnitPrice.toString()).toFixed(2),
    [customUnitPrice, data.Price_Used]
  );
  const pharmacy = useMemo(
    () => data.Pharmacy_Name || data.Item?.Pharmacy?.name || "N/A",
    [data.Item?.Pharmacy?.name, data.Pharmacy_Name]
  );

  const quantity = useMemo(() => {
    const usageUnit = data.Item.Usage_Units_Per_Sellable_Unit;
    const usageUnitName = data.Item.Med_Usage_Unit?.name;
    if (usageUnit && usageUnitName) {
      return `${data.Quantity} x ${usageUnit} ${usageUnitName}`;
    }
    return data.Quantity;
  }, [
    data.Item.Med_Usage_Unit?.name,
    data.Item.Usage_Units_Per_Sellable_Unit,
    data.Quantity,
  ]);

  return (
    <Box border={1} width="100%" borderColor="grey.300" p={2}>
      <Box display="flex" width="100%" flexWrap="wrap" gridGap="16px">
        <>
          <Box maxWidth={230}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Product
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {name}
            </Typography>
          </Box>
          <Box width={200}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Pharmacy
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {pharmacy}
            </Typography>
          </Box>
          <Box width={200}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Quantity
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {quantity}
            </Typography>
          </Box>
          <Box width={200}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Duration
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {duration}
            </Typography>
          </Box>
          <Box maxWidth={150}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Price(£)
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {price}
            </Typography>
          </Box>
          <Box minWidth={230} maxWidth={400}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Usage Instructions
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {customDosingInstructions || usageInstruction || "N/A"}
            </Typography>
          </Box>
          <Box minWidth={230} maxWidth={400}>
            <Typography variant="body2" style={{ color: "#4c6890" }}>
              Usage Notes
            </Typography>
            <Typography variant="body2" style={{ marginTop: 1 }}>
              {usageNotes}
            </Typography>
          </Box>
        </>
        {(data.childrenItems || []).map((t) => (
          <ChildItem key={t.id} data={t} />
        ))}
      </Box>
    </Box>
  );
};
