import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Cancel,
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { useFormikContext } from "formik";
import React, { useMemo, useCallback, useState } from "react";
import { getIsCountriesMatch } from "widgets/PathwaySpecifyDetailsBlueprint/helpers";
import { FormValues } from "../../types";
import { NurseInfoTable } from "./NurseInfoTable";

const useStyles = makeStyles(({ palette: p }) =>
  createStyles({
    success: {
      color: p.success.main,
    },
    error: {
      color: p.error.main,
    },
    radioWrapper: {
      verticalAlign: "top",
    },
  })
);

interface Props {
  bloodDrawer: BloodDrawer;
}
export const OfferingNurseRowGroup = ({ bloodDrawer }: Props) => {
  const [showInfo, setShowInfo] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { price, specialInstructions, takeSampleToTheLab } = useMemo(
    () => ({
      specialInstructions: bloodDrawer.emailCustomInstructions || "",
      price: bloodDrawer.price || "",
      takeSampleToTheLab: bloodDrawer.takesSampleToLab || "",
    }),
    [bloodDrawer]
  );

  const handleExpand = useCallback(() => {
    setShowInfo((current) => !current);
  }, []);

  const isSelected = useMemo(() => {
    return values.nurseBloodDrawer === bloodDrawer.id;
  }, [values.nurseBloodDrawer, bloodDrawer.id]);

  const onSelect = useCallback(() => {
    setFieldValue("nurseBloodDrawer", bloodDrawer.id);
  }, [setFieldValue, bloodDrawer.id]);

  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={showInfo ? 2 : undefined}
          valign={showInfo ? "top" : undefined}
          className={showInfo ? classes.radioWrapper : undefined}
        >
          <Radio
            color="primary"
            size="small"
            checked={isSelected}
            onClick={onSelect}
          />
        </TableCell>
        <TableCell>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item>
              <IconButton onClick={handleExpand}>
                {showInfo ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography>{bloodDrawer?.name || ""}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography>{price}</Typography>
        </TableCell>
        <TableCell>
          {bloodDrawer.isPrepaid ? (
            <CheckCircle className={classes.success} />
          ) : (
            <Cancel className={classes.error} />
          )}
        </TableCell>
        <TableCell>
          {bloodDrawer.providesOwnKit ? (
            <CheckCircle className={classes.success} />
          ) : (
            <Cancel className={classes.error} />
          )}
        </TableCell>
        <TableCell>
          <Typography>
            {takeSampleToTheLab ? (
              <CheckCircle className={classes.success} />
            ) : (
              <Cancel className={classes.error} />
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{specialInstructions}</Typography>
        </TableCell>
      </TableRow>

      {showInfo && (
        <TableRow>
          <TableCell colSpan={6}>
            <NurseInfoTable
              addresses={bloodDrawer.addresses.filter(
                (address) =>
                  getIsCountriesMatch(
                    address.country,
                    values.shippingCountry
                  ) && address.city === values.shippingCity
              )}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
