import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import React from "react";

interface TableHeaderTitle {
  title: string | React.ReactNode;
  align?: "left" | "center" | "right";
  id: string;
}

export interface RowData {
  name: string;
  align?: "left" | "center" | "right";
  [key: string]: string | number | React.ReactNode;
}

interface BasicTableProps {
  tableClassName?: string;
  tableHeaders: TableHeaderTitle[];
  tableData: RowData[];
  emptyMessage?: string;
}

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  defaultTableStyle: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: "4px",
  },
  tableHead: {
    backgroundColor: p.grey[100],
  },
  tableCell: {
    padding: s(1),
  },
}));

export const BasicTable: React.FC<BasicTableProps> = ({
  tableClassName = "",
  tableHeaders,
  tableData,
  emptyMessage = "",
}) => {
  const classes = useStyle();
  return (
    <TableContainer className={classes.defaultTableStyle}>
      <Table className={tableClassName} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell
                className={classes.tableCell}
                key={header.id}
                align={header.align || "left"}
              >
                {header.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.name}>
              {tableHeaders.map(({ id }) => (
                <TableCell
                  key={id}
                  className={classes.tableCell}
                  align={row.align || "left"}
                >
                  {row[id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {tableData.length === 0 && (
        <Box py={2} textAlign="center">
          <Typography variant="body2">
            {emptyMessage || "No data found"}
          </Typography>
        </Box>
      )}
    </TableContainer>
  );
};
