import { isEqual } from "lodash";
import clsx from "clsx";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { TableSearchInput } from "components/TableSearchInput";
import { ClearAllFiltersButton } from "components/TableFilters";

import {
  DocumentStatusFilters,
  DocumentStatusEnum,
  DocumentTypeFilters,
  DocumentTypeEnum,
  OwnershipFilters,
  DocumentOwnershipEnum,
} from "./interface";

import { Filters } from "./Filters";
import { StatusToTypeMapping } from "./helpers";

const useStyle = makeStyles<Theme>(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    padding: s(1, 2),
  },
  searchWrapper: {
    marginRight: "auto",
  },
  clearAllWrapper: {
    paddingTop: s(1),
  },
  searchInput: {
    paddingTop: 0,
    paddingBottom: 0,
    width: "300px",
  },
  searchInputRoot: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BDBDBD",
        borderWidth: "1px",
      },
    },
  },
  newDocumentWrapper: {
    textAlign: "right",
    display: "flex",
    alignItems: "center",
  },
  cloud: {
    marginLeft: s(2),
  },
  filter: {
    margin: s(0, 2),
  },
}));

interface ControllersProps {
  className?: string;
  statusFilters: DocumentStatusFilters;
  typeFilters: DocumentTypeFilters;
  ownershipFilters: OwnershipFilters;
  onFiltersChange: (nextFilters: {
    localStatusFilters: DocumentStatusFilters;
    localeTypeFilters: DocumentTypeFilters;
    localeOwnershipFilters: OwnershipFilters;
  }) => any;
  search: string;
  onSearchChange: (nextSearch: string) => any;
  onClearSearchAndFiltersClick: () => any;
}

export const Controllers: React.FC<ControllersProps> = ({
  statusFilters,
  onFiltersChange,
  search,
  onSearchChange,
  onClearSearchAndFiltersClick,
  className,
  typeFilters,
  ownershipFilters,
}) => {
  const [localStatusFilters, setLocalStatusFilters] = useState({
    ...statusFilters,
  });

  const [localeTypeFilters, setLocalTypeFilters] = useState({
    ...typeFilters,
  });

  const [localeOwnershipFilters, setLocaleOwnershipFilters] = useState({
    ...ownershipFilters,
  });

  const [localSearch, setLocalSearch] = useState(search);

  const resetFilters = useCallback(() => {
    setLocalStatusFilters({ ...statusFilters });
    setLocalTypeFilters({ ...typeFilters });
    setLocaleOwnershipFilters({ ...ownershipFilters });
  }, [statusFilters, typeFilters, ownershipFilters]);

  const onStatusFilterClick = useCallback((s: DocumentStatusEnum) => {
    setLocalStatusFilters((current) => {
      const cloned = { ...current };
      cloned[s] = !cloned[s];
      return cloned;
    });
  }, []);

  const onTypeFilterClick = useCallback((s: DocumentTypeEnum) => {
    setLocalTypeFilters((current) => {
      const cloned = { ...current };
      cloned[s] = !cloned[s];
      return cloned;
    });
  }, []);

  const onOwnershipFilterClick = useCallback((s: DocumentOwnershipEnum) => {
    setLocaleOwnershipFilters((current) => {
      const cloned = { ...current };
      cloned[s] = !cloned[s];
      return cloned;
    });
  }, []);

  const onLocalSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalSearch(e.target.value);
    },
    []
  );

  const onSearchIconClick = useCallback(() => {
    onSearchChange(localSearch);
  }, [onSearchChange, localSearch]);

  const onSearchClearClick = useCallback(() => {
    onSearchChange("");
  }, [onSearchChange]);

  const isStatusFiltersChanged = useMemo(() => {
    return !isEqual(localStatusFilters, statusFilters);
  }, [localStatusFilters, statusFilters]);

  const isTypeFiltersChanged = useMemo(() => {
    return !isEqual(localeTypeFilters, typeFilters);
  }, [localeTypeFilters, typeFilters]);

  const isClearAllFiltersDisabled = useMemo(() => {
    const haveStatusUnchecked = Object.keys(statusFilters).find(
      (key) => !statusFilters[key as DocumentStatusEnum]
    );

    const haveTypeUnchecked = Object.keys(typeFilters).find(
      (key) => !typeFilters[key as DocumentTypeEnum]
    );

    return !haveStatusUnchecked && !haveTypeUnchecked && search === "";
  }, [statusFilters, search, typeFilters]);

  const noTypeSelected = useMemo(() => {
    return Object.values(localeTypeFilters).every((typeFilter) => !typeFilter);
  }, [localeTypeFilters]);

  const statusFiltersList: DocumentStatusEnum[] = useMemo(() => {
    const statusFiltersKeys = Object.keys(
      localStatusFilters
    ) as DocumentStatusEnum[];
    if (noTypeSelected) {
      return statusFiltersKeys;
    }
    return statusFiltersKeys.filter((statusFilter) => {
      if (!StatusToTypeMapping[statusFilter].length) {
        return true;
      }
      return StatusToTypeMapping[statusFilter].some((typeFilter) => {
        return localeTypeFilters[typeFilter];
      });
    });
  }, [localStatusFilters, localeTypeFilters, noTypeSelected]);

  const applyFilters = useCallback(() => {
    onFiltersChange({
      localStatusFilters: Object.entries(
        localStatusFilters
      ).reduce<DocumentStatusFilters>((total, [key, value]) => {
        return {
          ...total,
          [key]: statusFiltersList.includes(key as DocumentStatusEnum)
            ? value
            : false,
        };
      }, localStatusFilters),
      localeTypeFilters,
      localeOwnershipFilters,
    });
  }, [
    localStatusFilters,
    onFiltersChange,
    localeTypeFilters,
    localeOwnershipFilters,
    statusFiltersList,
  ]);

  useEffect(() => {
    setLocalSearch(search);
  }, [search]);

  const classes = useStyle();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.searchWrapper}>
        <TableSearchInput
          variant="outlined"
          placeholder="Search Documents"
          className={classes.searchInput}
          classes={{
            root: classes.searchInputRoot,
          }}
          value={localSearch}
          onChange={onLocalSearchChange}
          onSearchIconClick={onSearchIconClick}
          onSearchClearClick={onSearchClearClick}
        />
      </div>
      <div className={classes.clearAllWrapper}>
        <ClearAllFiltersButton
          disabled={isClearAllFiltersDisabled}
          onClick={onClearSearchAndFiltersClick}
        />
      </div>
      <div className={classes.filter}>
        <Filters
          resetFilters={resetFilters}
          applyFilters={applyFilters}
          isStatusFiltersChanged={isStatusFiltersChanged}
          onStatusFilterClick={onStatusFilterClick}
          onTypeFilterClick={onTypeFilterClick}
          onOwnershipFilterClick={onOwnershipFilterClick}
          isTypeFiltersChanged={isTypeFiltersChanged}
          statusFilters={localStatusFilters}
          typeFilters={localeTypeFilters}
          ownershipFilters={ownershipFilters}
          statusFiltersList={statusFiltersList}
        />
      </div>
    </div>
  );
};
