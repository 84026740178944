import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import {
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoMedicineType,
} from "types";
import { MedicineInstructionsFields } from "./MedicineInstructionsFields";
import { AdministrationRouteFields } from "./AdministrationRouteFields";
import { DosingFields } from "./DosingFields";
import { CustomDosingInstructions } from "./CustomDosingInstructions";
import { SpecifyCustomDosingInstructions } from "./SpecifyCustomDosingInstructions";
import { FrequencyFields } from "./FrequencyFields";

interface Props {
  name?: string;
  medicineType: ZohoMedicineType;
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}

export const MedicineForm = ({
  name,
  medicineType,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
}: Props) => {
  const { values } = useFormikContext();

  const fieldName = useMemo(() => name || "", [name]);

  const medicineValues = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const specifyDosingInstructionsFieldValue = useMemo(
    () => medicineValues.specifyDosingInstructions,
    [medicineValues.specifyDosingInstructions]
  );

  return (
    <Box>
      <SpecifyCustomDosingInstructions name={name} />
      <DosingFields
        name={fieldName}
        dosingTypes={dosingTypes}
        medicineType={medicineType}
        specifyDosingInstructionsFieldValue={
          specifyDosingInstructionsFieldValue
        }
      />
      {specifyDosingInstructionsFieldValue ? (
        <CustomDosingInstructions name={name} />
      ) : null}
      {!specifyDosingInstructionsFieldValue ? (
        <>
          <FrequencyFields name={fieldName} />
          <AdministrationRouteFields
            name={name}
            dosingAdminRoutes={dosingAdminRoutes}
            medicineAdminRoutes={medicineAdminRoutes}
          />
        </>
      ) : null}
      <MedicineInstructionsFields name={name} />
    </Box>
  );
};
