import {
  TestProfilesObr,
  Lab,
  BloodTakingOptionEnum,
  GenderEnum,
  BloodDrawBilledStatus,
} from "@deep-consulting-solutions/bmh-constants";

import { ZohoModule } from "types";
import { AfterResultConsultationEnum, OrderTypeEnum } from "../types";

export interface TestProfilesObrWithMappedLabs extends TestProfilesObr {
  mappedLabs: {
    [id: string]: true;
  };
}
export enum PatientSourcedBloodDrawerEnum {
  PATIENT_SOURCED = "Patient Sourced",
}

export interface FormValues {
  testProfiles: TestProfilesObrWithMappedLabs[];
  lab: Lab | null;
  isOnPhone: boolean;
  shippingCountry: string;
  shippingCity: string;
  bloodDrawer: string;
  labBloodDrawer: string;
  nurseBloodDrawer: string;
  patientSourcedBloodDrawer: PatientSourcedBloodDrawerEnum;
  bloodTakingOption: BloodTakingOptionEnum;
  afterResultsConsultation: AfterResultConsultationEnum;
  gender: GenderEnum;
  dob: string;
  street: string;
  region: string;
  zip: string;
  other: string;
  billedStatus: BloodDrawBilledStatus;
  billInvoiceNumber: string;
  specialNotes: string;
  orderType: OrderTypeEnum;
  patientSourcedLab: Lab | null;
  city: string;
}

export interface ZohoPathwayTestProfile {
  Test_Profiles: ZohoModule;
  id: string;
}

export enum ErrorMessageEnum {
  NoLab = "No lab is available for the selected country.",
  NotAvailable = "Test profiles are not available in any lab in the selected country.",
}

export enum ZohoVariables {
  BD_General = "BD_General",
  BD_Mobile_Yes = "BD_Mobile_Yes",
  BD_Prepaid_Yes = "BD_Prepaid_Yes",
  BD_Prepaid_No = "BD_Prepaid_No",
  BD_Provides_Own_Kits_Yes = "BD_Provides_Own_Kits_Yes",
  BD_Provides_Own_Kits_No = "BD_Provides_Own_Kits_No",
  BD_Takes_Sample_to_the_Lab_Yes = "BD_Takes_Sample_to_the_Lab_Yes",
  BD_Takes_Sample_to_the_Lab_No = "BD_Takes_Sample_to_the_Lab_No",
  BD_PATIENT_SOURCED = "BD_Patient_Sourced",
  BD_PATIENT_SOURCED_UK = "BD_Patient_Sourced_UK",
  BD_UK_MAP = "BD_UK_Map",
  BTO_Venepuncture = "BTO_Venepuncture",
  Lab_Selection = "Lab_Selection",
}

export type PatientSourcedVariant = "patient-sourced" | "rest-fields";
