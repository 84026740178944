import { stringify } from "querystring";
import {
  getRouteUMResult,
  getRouteUMPossibleMatches,
  GETUMPossibleMatchesRes,
  ROUTE_UM_PATHWAY_SEARCH_FULL,
  GETUMPathwaySearchParams,
  GETUMPathwaySearchRes,
  getRouteUMRematch,
  getRouteUMDiscard,
  POSTUMDisatchRes,
  POSTUMMarkAsNewRes,
  getRouteUMMarkAsNew,
  PUTUMRestoreResultRes,
  getRouteUMRestoreResult,
  UnmatchedHL7,
  POSTUMReplaceOldFile,
  getRouteUMReplaceOldFile,
  GETComparisonResultsRes,
  ROUTE_COMPARISON_RESULTS,
  ROUTE_TR_BY_UNMATCHED_RESULT_ZOHO_ID_FOR_COMPARISON,
  GETTRByUnmatchedResultZohoIDForComparisonRes,
} from "@deep-consulting-solutions/bmh-constants";

import { apiClient, silentClient } from "apis";

export const fetchUnmatchResult = async ({
  zohoId,
}: {
  zohoId: string;
}): Promise<UnmatchedHL7> => {
  const res = await apiClient.get(getRouteUMResult(zohoId, true));

  return res.data.data.result;
};

export const fetchPossibleMatches = async ({ zohoId }: { zohoId: string }) => {
  const res = await apiClient.get<GETUMPossibleMatchesRes>(
    getRouteUMPossibleMatches(zohoId, true)
  );

  return res.data.data.matches;
};

export const fetchPathwaySearchResults = async (searchTerm: string) => {
  const params: GETUMPathwaySearchParams = {
    search: searchTerm,
  };

  const res = await apiClient.get<GETUMPathwaySearchRes>(
    `${ROUTE_UM_PATHWAY_SEARCH_FULL}?${stringify(params as any)}`
  );

  return res.data.data.pathways;
};

export const rematchHL7File = async (zohoId: string): Promise<UnmatchedHL7> => {
  const res = await apiClient.post(getRouteUMRematch(zohoId, true));

  return res.data.data.unmatchedBloodTestResult;
};

export const fetchMatchingBloodTestRecord = async (ids: string[]) => {
  const res = await apiClient.get<GETComparisonResultsRes>(
    `${ROUTE_COMPARISON_RESULTS}?ids=${ids.join(",")}`
  );

  return res.data.data.results;
};

export const postMergeWithExisting = async (
  zohoId: string
): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMReplaceOldFile>(
    `${getRouteUMResult(zohoId, true)}/append`
  );
  return res.data.data.unmatchedBloodTestResult;
};

export const postReplaceExisting = async (
  zohoId: string
): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMReplaceOldFile>(
    getRouteUMReplaceOldFile(zohoId, true)
  );

  return res.data.data.unmatchedBloodTestResult;
};

export const discardFile = async (
  zohoId: string,
  values: { reason: string }
): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMDisatchRes>(
    getRouteUMDiscard(zohoId, true),
    values
  );
  return res.data.data.result;
};

export const markAsNew = async (zohoId: string): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMMarkAsNewRes>(
    getRouteUMMarkAsNew(zohoId, true)
  );

  return res.data.data.result;
};

export const restoreOriginal = async (
  zohoId: string
): Promise<UnmatchedHL7> => {
  const res = await apiClient.put<PUTUMRestoreResultRes>(
    getRouteUMRestoreResult(zohoId, true)
  );
  return res.data.data.result;
};

export const saveUpdate = async (
  zohoId: string,
  updatedData: Partial<UnmatchedHL7>
): Promise<UnmatchedHL7> => {
  const res = await apiClient.patch(
    getRouteUMResult(zohoId, true),
    updatedData
  );

  return res.data.data.result;
};

export const replaceOldFile = async (zohoId: string): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMReplaceOldFile>(
    getRouteUMReplaceOldFile(zohoId, true)
  );

  return res.data.data.unmatchedBloodTestResult;
};

export const wipeAndRewriteOldFile = async (
  zohoId: string
): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMReplaceOldFile>(
    getRouteUMReplaceOldFile(zohoId, true)
  );

  return res.data.data.unmatchedBloodTestResult;
};

export const appendOldFile = async (zohoId: string): Promise<UnmatchedHL7> => {
  const res = await apiClient.post<POSTUMReplaceOldFile>(
    `${getRouteUMResult(zohoId, true)}/append`
  );

  return res.data.data.unmatchedBloodTestResult;
};

export const fetchPathwayByUnmatchedHl7ZohoID = async (zohoId: string) => {
  const res = await silentClient.get<GETTRByUnmatchedResultZohoIDForComparisonRes>(
    ROUTE_TR_BY_UNMATCHED_RESULT_ZOHO_ID_FOR_COMPARISON.replace(
      ":zohoID",
      zohoId
    )
  );
  return res.data.data;
};
