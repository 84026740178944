import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { zohoServices } from "services";
import { Formik, FormikConfig } from "formik";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  confirmOrderManifest,
  getTreatmentOrders,
} from "services/treatment-orders/bulk-order";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import { BulkOrderManifestTable } from "./BulkOrderManifestTable";
import { ActionButtons } from "./ActionButtons";
import { FormattedTreatmentOrder, InitialValues } from "./types";
import { MAX_LIMIT, PENDING_MANIFEST_STATE } from "./constants";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    padding: "8px 30px",
    margin: s(0),
  },
}));

export function BulkOrderManifest() {
  const classes = useStyles();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [error, setError] = useState(false);
  const ids = useSelector(zohoSelectors.getIds);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [treatmentOrders, setTreatmentOrders] = useState<
    FormattedTreatmentOrder[]
  >([]);
  const [rowCount, setRowCount] = useState(0);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const records = values.treatmentOrders.map(
          ({ id, courierManifestNumber }) => ({
            id,
            courierManifestNumber,
          })
        );
        const payload = {
          records,
        };
        await confirmOrderManifest(payload);
      } catch (err) {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget]
  );

  const areAllOrdersPendingManifest = useMemo(() => {
    return treatmentOrders.every((t) => t.state === PENDING_MANIFEST_STATE);
  }, [treatmentOrders]);

  const resizeWidget = useCallback(() => {
    zohoServices
      .resizeWidget({
        width: 1400,
        height: 700,
      })
      .then(() => {
        setTimeout(() => {
          if (!loading && wrapperRef?.current?.offsetHeight) {
            const height = wrapperRef?.current?.offsetHeight;

            zohoServices.resizeWidget({
              width: 1400,
              height: !loading && error ? height : 700,
            });
          }
        }, 100);
      });
  }, [error, loading]);

  const getData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);
      if (ids.length > MAX_LIMIT) {
        setError(true);
        return;
      }
      const treatmentOrdersRes = await getTreatmentOrders(ids);
      setInitialValues({
        treatmentOrders: treatmentOrdersRes,
      });
      setTreatmentOrders(treatmentOrdersRes);
      setRowCount(treatmentOrdersRes.length);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [ids]);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Loader open={submitData} />
      {loading && (
        <Box
          height="500px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && error && (
        <>
          <Alert severity="error">
            <AlertTitle>Maximum number of selected records exceeded</AlertTitle>
            You selected {ids.length} records. You can progress a maximum of{" "}
            {MAX_LIMIT} records at once. Please reduce the number of selected
            items to {MAX_LIMIT} or less.
          </Alert>
          <Box
            marginTop={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              style={{
                textTransform: "capitalize",
                minWidth: "120px",
              }}
              color="primary"
              variant="outlined"
              onClick={() => closeWidget()}
            >
              Close
            </Button>
          </Box>
        </>
      )}
      {!loading && initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <>
            <Box height="615px">
              {!areAllOrdersPendingManifest && (
                <Alert severity="error">
                  Some of the selected pharmacy orders are not Pending
                  Manifesting. All selected pharmacy orders must be Pending
                  Manifesting.
                </Alert>
              )}
              <BulkOrderManifestTable rowCount={rowCount} />
              <Box width="75%" marginTop="28px">
                {areAllOrdersPendingManifest && (
                  <Alert severity="info">
                    On confirmation, the state of the treatment pharmacy orders
                    will be updated but this will take few seconds after the
                    dialog is closed.
                  </Alert>
                )}
              </Box>
            </Box>
            <ActionButtons closeWidget={closeWidget} />
          </>
        </Formik>
      )}
    </div>
  );
}
