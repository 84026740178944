import { crhClient, n8nApiClient, silentClient } from "apis";
import { MigrateTreatmentData, MigrateTreatmentResponse } from "types";

export const fetchMigrateTreatmentWidgetDetails = async (
  treatmentId: string
) => {
  const res = await silentClient.get(
    `/functional-use-case/uib/migrate-treatment-widget-details/${treatmentId}`
  );
  return res.data.data as MigrateTreatmentData;
};

export const fetchPharmacyProducts = async (pharmacyIds: string[]) => {
  const res = await n8nApiClient.post("/pharmacy-products", { pharmacyIds });
  return res.data;
};

export const fetchPharmacies = async () => {
  const res = await crhClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/Pharmacy?limit=1000&fields=Id,AllowedCountries,PaymenttoPharmacy,ShippingCharges,NotAllowedCountries,Name,ZohoCrmId`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data;
};

export const migrateTreatment = async (payload: any) => {
  const res = await silentClient.post<MigrateTreatmentResponse>(
    "/treatments/migrate-treatment",
    payload
  );
  return res.data.data;
};

export const fetchDbTreatmentAreas = async () => {
  const res = await crhClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/TreatmentAreas?limit=1000&fields=Id,Name,ZohoCrmId`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data;
};
