import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import { Box, Button } from "@material-ui/core";
import { InitialValues } from "./types";
import { PENDING_MANIFEST_STATE } from "./constants";

export const ActionButtons = ({ closeWidget }: { closeWidget: () => void }) => {
  const {
    submitForm,
    isSubmitting,
    values,
  } = useFormikContext<InitialValues>();
  const areAllOrdersPendingManifest = useMemo(() => {
    return values.treatmentOrders.every(
      (t) => t.state === PENDING_MANIFEST_STATE
    );
  }, [values.treatmentOrders]);

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant={areAllOrdersPendingManifest ? "outlined" : "contained"}
        onClick={() => closeWidget()}
        disabled={isSubmitting}
      >
        Close
      </Button>
      {areAllOrdersPendingManifest && (
        <Button
          color="primary"
          variant="contained"
          onClick={submitForm}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Confirm"}
        </Button>
      )}
    </Box>
  );
};
