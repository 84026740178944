export type SaveDefaultValuesWidgetName =
  | "update-treatment"
  | "complete-treatment"
  | "create-treatment"
  | "finalize-treatment"
  | "migrate-treatment"
  | "dispense-order"
  | "request-changes-treatment"
  | "start-prescription-renewal";

export const getDefaultValuesKey = (
  widgetName: SaveDefaultValuesWidgetName,
  identifier: string
) => {
  return `${widgetName}-${identifier}`;
};

export const saveDefaultValues = <T = any>(
  widgetName: SaveDefaultValuesWidgetName,
  identifier: string,
  values: T
) => {
  const now = Date.now();
  localStorage.setItem(
    getDefaultValuesKey(widgetName, identifier),
    JSON.stringify({
      dateStored: now,
      values,
    })
  );

  return true;
};

export const clearSavedDefaultValues = (
  widgetName: SaveDefaultValuesWidgetName,
  identifier: string
) => {
  localStorage.removeItem(getDefaultValuesKey(widgetName, identifier));
};

export const getSavedDefaultValues = <T = any>(
  widgetName: SaveDefaultValuesWidgetName,
  identifier: string,
  recordsDates: (string | number)[] = []
) => {
  try {
    const savedWidgetValuesString = localStorage.getItem(
      getDefaultValuesKey(widgetName, identifier)
    );
    if (!savedWidgetValuesString) {
      return null;
    }
    const savedWidgetValue: {
      dateStored: number;
      values: T;
      identifier: string;
    } = JSON.parse(savedWidgetValuesString);

    const hasNewlyUpdatedRecord = recordsDates.some(
      (recordDate) =>
        new Date(recordDate).getTime() >= Number(savedWidgetValue.dateStored)
    );
    if (hasNewlyUpdatedRecord) {
      clearSavedDefaultValues(widgetName, identifier);
      return null;
    }
    return savedWidgetValue.values;
  } catch {
    return null;
  }
};
