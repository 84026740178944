import _truncate from "lodash/truncate";
import React, { memo, useMemo, useState, useCallback } from "react";
import {
  makeStyles,
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import {
  InsertDriveFile as FileIcon,
  CalendarToday as CalendarIcon,
} from "@material-ui/icons";
import { BloodTestResult } from "@deep-consulting-solutions/bmh-constants";

import InfoItem from "../InfoItem";
import {
  genComparisonData,
  shouldDisplayAbnormalFlag,
  processSampleCollectedDate,
} from "../TestResults.helpers";
import {
  BackButton,
  TABLE_HEADER_COLOR,
  ViewWrapperBox,
  TableCell,
} from "../TestResults.styles";
import { TABLE_META } from "./ComparisonView.types";

const truncateString = (str: string, long?: boolean) => {
  const truncated = _truncate(str, {
    length: long ? 14 : 10,
  });
  return {
    truncated,
    isTruncated: truncated !== str,
  };
};

interface StyleProps {
  count: number;
  dataWidth: number;
}

const useStyle = makeStyles(({ spacing: s, palette: p, typography: typo }) => ({
  topBar: {
    display: "flex",
    marginBottom: s(1),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    padding: s(2),
  },
  topActions: {
    marginLeft: "auto",
  },
  actionBtn: {
    marginRight: s(1),
    "&:last-child": {
      marginRight: s(0),
    },
  },
  infoGroups: {
    display: "flex",
    flexDirection: "column",
  },
  infoWrapper: {
    display: "flex",
    marginLeft: `${TABLE_META.test.width}px`,
  },
  info: ({ dataWidth }: StyleProps) => ({
    width: dataWidth,
    paddingLeft: s(1),
    paddingRight: s(1),
    paddingBottom: s(1),
    flex: 1,
  }),
  identifierCell: {
    ...typo.h6,
  },
  firstRowInfo: {
    paddingTop: s(1),
  },
  firstRecord: {
    borderLeft: `2px solid ${p.grey[500]}`,
  },
  oddRecord: {
    background: p.grey[100],
  },
  headerCell: {
    color: TABLE_HEADER_COLOR,
  },
  testCell: () => {
    const width = `${TABLE_META.test.width}px`;
    return {
      width,
      maxWidth: width,
      minWidth: width,
    };
  },
  testCellBody: {
    paddingLeft: s(1),
  },
  rangeCell: () => {
    const width = `${TABLE_META.range.width}px`;
    return {
      width,
      maxWidth: width,
      minWidth: width,
    };
  },
  unitCell: () => {
    const width = `${TABLE_META.unit.width}px`;
    return {
      width,
      maxWidth: width,
      minWidth: width,
    };
  },
  unitInner: {
    paddingRight: s(1),
  },
  resultCell: () => {
    const width = `${TABLE_META.result.width}px`;
    return {
      width,
      maxWidth: width,
      minWidth: width,
    };
  },
  resultInner: {
    paddingLeft: s(1),
  },
  resultContent: {
    paddingLeft: s(1),
    paddingRight: s(1),
    paddingTop: s(0.5),
    paddingBottom: s(0.5),
    borderRadius: s(1.5),
  },
  contentWrapper: {
    flex: 1,
    position: "relative",
  },
  contentInner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
  },
  contentBase: ({ count, dataWidth }: StyleProps) => ({
    width: `${TABLE_META.test.width + dataWidth * count + s(2)}px`,
    paddingLeft: s(1),
    paddingRight: s(1),
    background: p.common.white,
  }),
  tableContainer: {
    flex: 1,
  },
  cellNA: {
    color: p.grey[400],
  },
  abnormal: {
    background: p.error.light,
    color: p.common.white,
  },
}));

const getRecordClassName = (
  className: string,
  recordIndex: number,
  classes: { oddRecord: string }
) => {
  if (recordIndex % 2 === 0) return `${classes.oddRecord} ${className} `;
  return className;
};

interface ComparisonViewProps {
  comparisons: { [key: string]: BloodTestResult };
  goBack: () => any;
  dismissComparison: () => any;
}

const ComparisonView: React.FC<ComparisonViewProps> = ({
  comparisons,
  goBack,
  dismissComparison,
}) => {
  const [showRange, setShowRange] = useState(false);
  const [showCollectedDate, setShowCollectedDate] = useState(false);

  const { groups, mappedResults } = useMemo(
    () => genComparisonData(Object.values(comparisons)),
    [comparisons]
  );

  const onRangeToggle = useCallback(() => {
    setShowRange((s) => !s);
  }, []);

  const onCollectedDateToggle = useCallback(() => {
    setShowCollectedDate((s) => !s);
  }, []);

  const dataWidth =
    TABLE_META.result.width +
    TABLE_META.unit.width +
    (showRange ? TABLE_META.range.width : 0);

  const classes = useStyle({
    count: mappedResults.length,
    dataWidth,
  });

  return (
    <ViewWrapperBox>
      <Box className={classes.topBar}>
        <BackButton onClick={goBack} />
        <Box className={classes.topActions}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.actionBtn}
            onClick={onCollectedDateToggle}
          >
            {showCollectedDate ? "HIDE" : "SHOW"} SAMPLE COLLECTED DATE
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.actionBtn}
            onClick={onRangeToggle}
          >
            {showRange ? "HIDE" : "DISPLAY"} NORMAL RANGES
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={dismissComparison}
          >
            DISMISS COMPARISON
          </Button>
        </Box>
      </Box>

      <Box className={classes.contentWrapper}>
        <Box className={classes.contentInner}>
          <Box className={`${classes.contentBase} ${classes.infoGroups}`}>
            <Box className={classes.infoWrapper}>
              {mappedResults.map((r, index) => {
                return (
                  <Box
                    key={r.id}
                    className={`${getRecordClassName(
                      classes.info,
                      index,
                      classes
                    )} ${classes.firstRowInfo}`}
                    data-testid="test-results/comparison/record-name"
                  >
                    <InfoItem
                      name={r.name}
                      withTitle={r.name}
                      label="Record Name"
                      Icon={FileIcon}
                      dark
                    />
                  </Box>
                );
              })}
            </Box>
            <Collapse in={showCollectedDate}>
              <Box className={classes.infoWrapper}>
                {mappedResults.map((r, index) => {
                  const { date, time, warning } = processSampleCollectedDate(r);
                  return (
                    <Box
                      key={r.id}
                      className={getRecordClassName(
                        classes.info,
                        index,
                        classes
                      )}
                      data-testid="test-results/comparison/sample-collected-date"
                    >
                      <InfoItem
                        name={date}
                        subName={time}
                        errorMessage={warning}
                        label="Sample Collected"
                        Icon={CalendarIcon}
                        dark
                      />
                    </Box>
                  );
                })}
              </Box>
            </Collapse>
          </Box>

          <TableContainer
            className={`${classes.contentBase} ${classes.tableContainer}`}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.headerCell} ${classes.testCell}`}
                  >
                    Test
                  </TableCell>
                  {mappedResults.map((r, index) => {
                    return (
                      <React.Fragment key={r.id}>
                        <TableCell
                          className={getRecordClassName(
                            `${classes.headerCell} ${classes.resultCell}`,
                            index,
                            classes
                          )}
                        >
                          <Box className={classes.resultInner}>Result</Box>
                        </TableCell>
                        {showRange && (
                          <TableCell
                            className={getRecordClassName(
                              `${classes.headerCell} ${classes.rangeCell}`,
                              index,
                              classes
                            )}
                          >
                            N.Range
                          </TableCell>
                        )}
                        <TableCell
                          className={getRecordClassName(
                            `${classes.headerCell} ${classes.unitCell}`,
                            index,
                            classes
                          )}
                        >
                          <Box className={classes.unitInner}>Unit</Box>
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {groups.map(({ identifier, names }) => {
                  return (
                    <React.Fragment key={identifier}>
                      {!!identifier && (
                        <TableRow>
                          <TableCell className={classes.identifierCell}>
                            {identifier}
                          </TableCell>
                          {mappedResults.map((r, index) => {
                            return (
                              <TableCell
                                key={r.id}
                                className={getRecordClassName(
                                  "",
                                  index,
                                  classes
                                )}
                                colSpan={showRange ? 3 : 2}
                              />
                            );
                          })}
                        </TableRow>
                      )}
                      {names.map((name) => {
                        return (
                          <React.Fragment key={name}>
                            <TableRow>
                              <TableCell
                                className={`${classes.testCell} ${classes.testCellBody}`}
                              >
                                {name}
                              </TableCell>
                              {mappedResults.map((r, index) => {
                                const d = r.result[name] || {
                                  value: "N/A",
                                  unit: "N/A",
                                  normalRange: "N/A",
                                };
                                const isNAClass = r.result[name]
                                  ? ""
                                  : classes.cellNA;
                                const value = truncateString(d.value, true);
                                const normalRange = truncateString(
                                  d.normalRange,
                                  true
                                );
                                const unit = truncateString(d.unit);
                                return (
                                  <React.Fragment key={r.id}>
                                    <Tooltip
                                      title={
                                        value.isTruncated ||
                                        (!showRange && d.normalRange) ? (
                                          <div>
                                            {value.isTruncated && (
                                              <div>Result: {d.value}</div>
                                            )}
                                            {!showRange && d.normalRange && (
                                              <div>
                                                Normal Range: {d.normalRange}
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      <TableCell
                                        className={getRecordClassName(
                                          `${isNAClass} ${classes.resultCell}`,
                                          index,
                                          classes
                                        )}
                                      >
                                        <Box
                                          className={getRecordClassName(
                                            `${isNAClass} ${classes.resultInner}`,
                                            index,
                                            classes
                                          )}
                                        >
                                          <span
                                            className={`${
                                              classes.resultContent
                                            } ${
                                              shouldDisplayAbnormalFlag(
                                                d.abnormalFlag
                                              )
                                                ? classes.abnormal
                                                : ""
                                            }`}
                                          >
                                            {value.truncated}
                                          </span>
                                        </Box>
                                      </TableCell>
                                    </Tooltip>
                                    {showRange && (
                                      <Tooltip
                                        title={
                                          normalRange.isTruncated
                                            ? d.normalRange
                                            : ""
                                        }
                                      >
                                        <TableCell
                                          className={getRecordClassName(
                                            `${isNAClass} ${classes.rangeCell}`,
                                            index,
                                            classes
                                          )}
                                        >
                                          {normalRange.truncated}
                                        </TableCell>
                                      </Tooltip>
                                    )}
                                    <TableCell
                                      className={getRecordClassName(
                                        `${isNAClass} ${classes.unitCell}`,
                                        index,
                                        classes
                                      )}
                                    >
                                      <Tooltip
                                        title={unit.isTruncated ? d.unit : ""}
                                      >
                                        <Box className={classes.unitInner}>
                                          {unit.truncated}
                                        </Box>
                                      </Tooltip>
                                    </TableCell>
                                  </React.Fragment>
                                );
                              })}
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </ViewWrapperBox>
  );
};

export default memo(ComparisonView);
