import React, { useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles, Container, Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  PathwayRecordSearchResult,
  UnmatchedHL7,
  UnmatchedBloodTestResultStatusEnum,
  OriginalUnmatchedHL7,
  Pathway,
  BloodTestResult,
} from "@deep-consulting-solutions/bmh-constants";

import { zohoSelectors } from "redux/zoho";
import { unmatchedRequests } from "redux/unmatched";
import Loader from "components/Loader";

import { notifications } from "services";
import { fetchPathwayByUnmatchedHl7ZohoID } from "redux/unmatched/requests";
import {
  convertPossibleMatches,
  addPathwaySearchResultToRecords,
  rearrangeAndFilterPossibleMatches,
  composeDataToSend,
  replaceData,
} from "./Unmatched.helpers";
import {
  ConvertedClientRecord,
  ConvertedPathwayRecord,
  UnmatchedHL7Field,
} from "./Unmatched.types";
import FileDataTable from "./FileDataTable";
import PossibleMatches from "./PossibleMatches";
import SearchDialog from "./SearchDialog";
import ReplaceOldFileDialog from "./ReplaceOldFileDialog";
import FileOverwriteOption from "./FileOverwriteOption";
import { ReplaceExistingDialog } from "./ReplaceExistingDialog";
import { MergeWithExistingDialog } from "./MergeWithExistingDialog";
import { DiscardFileDialog } from "./DiscardFileDialog";
import { ComparisonWidget } from "./ComparisonWidget";
import { getBorder } from "./Unmatched.styles";

const useStyle = makeStyles(({ spacing: s, palette: p, breakpoints: b }) => ({
  fullWrapper: {
    background: p.common.white,
    width: "100%",
    minWidth: "1300px",
    height: "100%",
    minHeight: "100vh",
    position: "relative",
    maxWidth: "initial",
    padding: s(2),
  },
  comparisonWrapper: {
    width: "100%",
    minHeight: "100vh",
    marginTop: s(2),
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    position: "relative",
    border: getBorder(p),
  },
  wrapper: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    position: "relative",
    maxWidth: "initial",

    [b.down("sm")]: {
      flexDirection: "column",
    },
  },
  error: {
    width: "100%",
    textAlign: "center",
  },
  errorText: {
    color: p.error.main,
  },
}));

const Unmatched = () => {
  const ids = useSelector(zohoSelectors.getIds);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<UnmatchedHL7 | null>(null);
  const [originalData, setOriginalData] = useState<
    OriginalUnmatchedHL7 | undefined
  >();

  const [possibleClientsMap, setPossibleClientsMap] = useState<{
    [id: string]: ConvertedClientRecord;
  }>({});
  const [possiblePathwaysMap, setPossiblePathwaysMap] = useState<{
    [id: string]: ConvertedPathwayRecord;
  }>({});

  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [selectedPathwayId, setSelectedPathwayId] = useState<string | null>(
    null
  );
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [
    isReplaceWithExistingDialogOpen,
    setIsReplaceWithExistingDialogOpen,
  ] = useState(false);
  const [
    isMergeWithExistingDialogOpen,
    setIsMergeWithExistingDialogOpen,
  ] = useState(false);
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const [matchingBloodTestOrder, setMatchingBloodTestOrder] = useState<
    Pathway | undefined | null
  >(null);
  const [newResult, setNewResult] = useState<BloodTestResult | null>(null);
  const [existingResult, setExistingResult] = useState<BloodTestResult | null>(
    null
  );

  const [showReplaceOldFileDialog, setShowReplaceOldFileDialog] = useState(
    false
  );

  const [
    showFileOverwriteOptionDialog,
    setShowFileOverwriteOptionDialog,
  ] = useState(false);

  const handleCloseMergeWithExistingDialog = useCallback(() => {
    setIsMergeWithExistingDialogOpen(false);
  }, []);

  const handleCloseDiscardDialog = useCallback(() => {
    setIsDiscardDialogOpen(false);
  }, []);

  const handleCloseReplaceWithExistingDialog = useCallback(() => {
    setIsReplaceWithExistingDialogOpen(false);
  }, []);

  const onReplaceExistingClick = useCallback(() => {
    setIsReplaceWithExistingDialogOpen(true);
  }, []);

  const onMergeClick = useCallback(() => {
    setIsMergeWithExistingDialogOpen(true);
  }, []);

  const onDiscardClick = useCallback(() => {
    setIsDiscardDialogOpen(true);
  }, []);

  const updatePossibleMatches = useCallback(async () => {
    const newMatches = await unmatchedRequests.fetchPossibleMatches({
      zohoId: ids[0],
    });
    const { clientsMap, pathwaysMap } = convertPossibleMatches(newMatches);
    setPossibleClientsMap(clientsMap);
    setPossiblePathwaysMap(pathwaysMap);
    setSelectedClientId(null);
    setSelectedPathwayId(null);
  }, [ids]);

  const onManualValueUpdate = useCallback(
    async ({ field, value }: { field: UnmatchedHL7Field; value: string }) => {
      setLoading(true);
      try {
        const updatedData = await unmatchedRequests.saveUpdate(ids[0], {
          [field]: value,
        });
        await updatePossibleMatches();
        setData(updatedData);
        setLoading(false);
        return true;
      } catch {
        setLoading(false);
        return false;
      }
    },
    [ids, updatePossibleMatches]
  );

  const { possibleClients, availablePathways } = useMemo(() => {
    return rearrangeAndFilterPossibleMatches({
      possibleClientsMap,
      possiblePathwaysMap,
      selectedClientId,
    });
  }, [possiblePathwaysMap, possibleClientsMap, selectedClientId]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const [d, m] = await Promise.all([
        unmatchedRequests.fetchUnmatchResult({ zohoId: ids[0] }),
        unmatchedRequests.fetchPossibleMatches({ zohoId: ids[0] }),
      ]);

      const { clientsMap, pathwaysMap } = convertPossibleMatches(m);

      setData(d);
      setOriginalData(d.originalData);

      setPossibleClientsMap(clientsMap);
      setPossiblePathwaysMap(pathwaysMap);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [ids]);

  const onPathwaySelect = useCallback((p: ConvertedPathwayRecord) => {
    setSelectedClientId(p.client.id);
    setSelectedPathwayId(p.id);
  }, []);

  const onClientSelect = useCallback((c: ConvertedClientRecord) => {
    setSelectedClientId((id) => {
      if (c.id !== id) {
        setSelectedPathwayId(null);
      }
      return c.id;
    });
  }, []);

  const onReplaceClick = useCallback(async () => {
    if (!selectedPathwayId || !selectedClientId) return false;
    const replaced = replaceData(
      data as UnmatchedHL7,
      possibleClientsMap[selectedClientId],
      possiblePathwaysMap[selectedPathwayId]
    );

    setLoading(true);
    try {
      const updatedData = await unmatchedRequests.saveUpdate(
        ids[0],
        composeDataToSend(replaced)
      );
      setData(updatedData);
      setLoading(false);
      return true;
    } catch {
      setLoading(false);
      return false;
    }
  }, [
    ids,
    data,
    selectedPathwayId,
    selectedClientId,
    possiblePathwaysMap,
    possibleClientsMap,
  ]);

  const onSearchDialogOpen = useCallback(() => {
    setOpenSearchDialog(true);
  }, []);

  const onSearchDialogClose = useCallback(() => {
    setOpenSearchDialog(false);
  }, []);

  const onSearchResult = useCallback(
    (result: PathwayRecordSearchResult) => {
      const added = addPathwaySearchResultToRecords({
        result,
        pathwaysMap: possiblePathwaysMap,
        clientsMap: possibleClientsMap,
      });

      setPossibleClientsMap(added.clientsMap);
      setPossiblePathwaysMap(added.pathwaysMap);

      setSelectedClientId(result.client.id);
      setSelectedPathwayId(result.id);
      setOpenSearchDialog(false);
    },
    [possibleClientsMap, possiblePathwaysMap]
  );

  const onRestartMatchClick = useCallback(async () => {
    setLoading(true);
    try {
      const updated = await unmatchedRequests.rematchHL7File(ids[0]);
      setData(updated);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ids]);

  const onCompareResults = useCallback(async () => {
    setLoading(true);
    try {
      if (!data?.zohoID) {
        return;
      }
      const res = await fetchPathwayByUnmatchedHl7ZohoID(data.zohoID);

      const pathway = res.existingResult.pathway;
      setMatchingBloodTestOrder(pathway);
      setNewResult({
        results: res.newResult,
        sampleCollectedOn: data.sampleCollectedOn,
        name: data.testName,
      } as BloodTestResult);
      setExistingResult(res.existingResult);
      setIsComparing(true);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [data?.zohoID, data?.sampleCollectedOn, data?.testName]);

  const onStopComparing = useCallback(() => {
    setIsComparing(false);
    setMatchingBloodTestOrder(undefined);
  }, []);

  const handleDiscardFile = useCallback(
    async (values: { reason: string }) => {
      try {
        const update = await unmatchedRequests.discardFile(ids[0], values);
        setData(update);
        setIsDiscardDialogOpen(false);
        setIsComparing(false);
        setLoading(false);
        return true;
      } catch {
        return false;
      }
    },
    [ids]
  );

  const handleMergeWithExisting = useCallback(async () => {
    try {
      const res = await unmatchedRequests.postMergeWithExisting(ids[0]);
      setData(res);
      setIsMergeWithExistingDialogOpen(false);
      return true;
    } catch {
      return false;
    }
  }, [ids]);

  const handleReplaceExistingFiles = useCallback(async () => {
    try {
      const res = await unmatchedRequests.postReplaceExisting(ids[0]);
      setData(res);
      setIsReplaceWithExistingDialogOpen(false);
      return true;
    } catch {
      return false;
    }
  }, [ids]);

  const onMarkNewClick = useCallback(async () => {
    setLoading(true);
    try {
      const update = await unmatchedRequests.markAsNew(ids[0]);
      setData(update);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ids]);

  const onRestoreClick = useCallback(async () => {
    setLoading(true);
    try {
      let update = await unmatchedRequests.restoreOriginal(ids[0]);
      if (
        update.status ===
        UnmatchedBloodTestResultStatusEnum.PENDING_CONTENT_VALIDATION
      ) {
        update = await unmatchedRequests.markAsNew(ids[0]);
      }
      setData(update);
      await updatePossibleMatches();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ids, updatePossibleMatches]);

  const replaceOldFile = useCallback(async () => {
    try {
      const updated = await unmatchedRequests.replaceOldFile(ids[0]);
      setData(updated);
      return true;
    } catch {
      return false;
    }
  }, [ids]);

  const handleAppendOldFile = useCallback(async () => {
    try {
      const updated = await unmatchedRequests.appendOldFile(ids[0]);
      setData(updated);
      return true;
    } catch {
      return false;
    }
  }, [ids]);

  const handleWipeAndRewriteOldFile = useCallback(async () => {
    try {
      const updated = await unmatchedRequests.wipeAndRewriteOldFile(ids[0]);
      setData(updated);
      return true;
    } catch {
      return false;
    }
  }, [ids]);

  const onReplaceOldFileClick = useCallback(() => {
    setShowReplaceOldFileDialog(true);
  }, []);

  const onFileOverwriteOptionClick = useCallback(() => {
    setShowFileOverwriteOptionDialog(true);
  }, []);

  const onReplaceOldFileDialogClose = useCallback(() => {
    setShowReplaceOldFileDialog(false);
  }, []);

  const onFileOverwriteOptionClose = useCallback(() => {
    setShowFileOverwriteOptionDialog(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    notifications.setCommonOptions({
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
    });
  }, []);

  const disableUpdate =
    data?.status !== UnmatchedBloodTestResultStatusEnum.NEW ?? true;
  const classes = useStyle();

  return (
    <Container className={classes.fullWrapper}>
      <div className={classes.wrapper}>
        <Loader open={loading} />

        {!!data && (
          <>
            <FileDataTable
              data={data}
              originalData={originalData}
              onManualValueUpdate={onManualValueUpdate}
              onRestartMatchClick={onRestartMatchClick}
              onDiscardClick={onDiscardClick}
              onMarkNewClick={onMarkNewClick}
              onRestoreClick={onRestoreClick}
              onReplaceOldFileClick={onReplaceOldFileClick}
              onFileOverwriteOptionClick={onFileOverwriteOptionClick}
              onCompareResults={onCompareResults}
              isComparing={isComparing}
              onStopComparing={onStopComparing}
              onMergeClick={onMergeClick}
              onReplaceClick={onReplaceExistingClick}
              matchingBloodTestOrder={matchingBloodTestOrder}
              newResult={newResult}
              existingResult={existingResult}
            />

            <PossibleMatches
              pathways={availablePathways}
              selectedPathwayId={selectedPathwayId}
              onPathwaySelect={onPathwaySelect}
              clients={possibleClients}
              selectedClientId={selectedClientId}
              onClientSelect={onClientSelect}
              onReplaceClick={onReplaceClick}
              onSearchClick={onSearchDialogOpen}
              disableUpdate={disableUpdate}
            />

            <SearchDialog
              open={openSearchDialog}
              onClose={onSearchDialogClose}
              onOK={onSearchResult}
            />

            <ReplaceOldFileDialog
              open={showReplaceOldFileDialog}
              onClose={onReplaceOldFileDialogClose}
              replaceOldFile={replaceOldFile}
            />

            <FileOverwriteOption
              open={showFileOverwriteOptionDialog}
              onClose={onFileOverwriteOptionClose}
              handleWipeAndRewriteOldFile={handleWipeAndRewriteOldFile}
              handleAppendOldFile={handleAppendOldFile}
            />

            <ReplaceExistingDialog
              open={isReplaceWithExistingDialogOpen}
              onClose={handleCloseReplaceWithExistingDialog}
              handleReplaceExistingFiles={handleReplaceExistingFiles}
            />

            <MergeWithExistingDialog
              open={isMergeWithExistingDialogOpen}
              onClose={handleCloseMergeWithExistingDialog}
              handleMergeWithExisting={handleMergeWithExisting}
            />

            <DiscardFileDialog
              open={isDiscardDialogOpen}
              onClose={handleCloseDiscardDialog}
              handleDiscardFile={handleDiscardFile}
            />
          </>
        )}

        {!loading && !data && (
          <Box className={classes.error}>
            <Typography variant="h6" className={classes.errorText}>
              Sorry, the Unmatched Blood Test Result cannot be loaded!
            </Typography>
          </Box>
        )}
      </div>
      {!!data?.status &&
        [
          UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT,
          UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT,
          UnmatchedBloodTestResultStatusEnum.DISCARDED,
        ].includes(data.status) && (
          <div className={classes.comparisonWrapper}>
            <ComparisonWidget unmatchedHL7={data} />
          </div>
        )}
    </Container>
  );
};

export default Unmatched;
