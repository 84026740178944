import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function BloodIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14 2l6 6v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2h8m4 18V9h-5V4H6v16h12m-7.08-7.69c-.24-.77-.77-3.23.63-3.27 1.4-.04.48 3.12.48 3.12.39 1.49 2.02 2.56 2.02 2.56.5-.15 3.35-.48 2.95 1-.43 1.48-3.5.09-3.5.09-1.95.14-3.41.66-3.41.66-1.13 2.11-2.45 3.03-2.99 2.14-.67-1.11 2.13-2.54 2.13-2.54 1.45-2.35 1.67-3.72 1.69-3.76m.65.84c-.4 1.3-1.2 2.69-1.2 2.69.85-.34 2.71-.73 2.71-.73-1.14-1-1.49-1.95-1.51-1.96m3.14 2.17s1.75.65 1.79.39c.07-.27-1.33-.51-1.79-.39m-5.66 1.49c-.77.3-1.51 1.58-1.33 1.58.18.01.91-.6 1.33-1.58m2.52-5.55c0-.05.43-1.68 0-1.73-.3-.03-.01 1.69 0 1.73z" />
    </SvgIcon>
  );
}
