import {
  RecordAccessLogsWidget,
  init,
} from "@deep-consulting-solutions/records-access-logs";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  apiClient,
  silentClient,
  removeAuthorizationHeader,
  setAuthorizationHeader,
} from "apis";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { zohoServices } from "services";

const useStyles = makeStyles(() => {
  return {
    containerWrapper: {
      background: "white",
    },
    wrapper: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      background: "white",
      flex: 1,
    },
    container: {
      flex: 1,
    },
    title: {
      fontWeight: 500,
      fontSize: 16,
    },
  };
});

export const SettingsRecordAccessLogs = () => {
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      const [modulesRes, usersRes] = await Promise.all([
        zohoServices.getModules(),
        zohoServices.getUsers(),
      ]);
      setUsers(usersRes.users || []);
      setModules(modulesRes.modules || []);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init({
      appName: "BMH",
      apiClient,
      silentClient,
      setAuthorizationHeader,
      removeAuthorizationHeader,
    });
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  const classes = useStyles();

  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.wrapper} p={1} pb={0}>
        <Typography className={classes.title}>Record Access Log</Typography>
        <Loader open={loading} />
        {!loading ? (
          <Box className={classes.container}>
            <RecordAccessLogsWidget users={users} modules={modules} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
