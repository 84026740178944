import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import React, { useMemo } from "react";
import { FormValues } from "./types";
import { RenewalForm, RenewalFormProps } from "./RenewalForm";
import { MakeChangesForm, MakeChangesFormProps } from "./MakeChangesForm";

interface Props extends RenewalFormProps, MakeChangesFormProps {
  prescriptionUrl: string;
}

export const StartPrescriptionRenewalForm = ({
  isSelfSupplied,
  prescriptionUrl,
  unfulfilledOrdersCount,
  treatment,
  treatmentUrl,
  maxNumberOfRepetitions,
}: Props) => {
  const { values } = useFormikContext<FormValues>();

  const isRenew = useMemo(() => {
    return values.changeType === "renew";
  }, [values.changeType]);

  const isMakeChanges = useMemo(() => {
    return values.changeType === "make-changes";
  }, [values.changeType]);

  return (
    <Box>
      <Box mb={2}>
        <Box maxWidth="80%">
          <Alert severity="warning">
            <AlertTitle>
              Client&apos;s prescription has run out, but the client has already
              paid for an order from that prescription.
            </AlertTitle>
            Client is overdue to complete a regular check-up.
            <ul>
              <li>
                You can renew the prescription as it is and fulfil the
                client&apos;s order.
              </li>
              <li>
                If the client is just completing their regular check-up and
                their prescription needs to change, you can make the changes to
                their treatment and cancel and refund the paid oder.
              </li>
            </ul>
          </Alert>
        </Box>
      </Box>
      {prescriptionUrl ? (
        <Box mb={2}>
          <Button
            variant="text"
            href={prescriptionUrl}
            target="_blank"
            rel="noreferrer"
            component="a"
          >
            VIEW PRESCRIPTION RECORD
          </Button>
        </Box>
      ) : null}

      <Box mb={2}>
        <FormControl>
          <Field component={RadioGroup} name="changeType">
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="renew"
                  label={
                    <Typography>
                      Renew prescription with as-is items.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="make-changes"
                  label={<Typography>Make changes to prescription</Typography>}
                />
              </Grid>
            </Grid>
          </Field>
        </FormControl>
      </Box>

      {isRenew ? (
        <RenewalForm
          unfulfilledOrdersCount={unfulfilledOrdersCount}
          isSelfSupplied={isSelfSupplied}
          maxNumberOfRepetitions={maxNumberOfRepetitions}
        />
      ) : null}

      {isMakeChanges ? (
        <MakeChangesForm treatmentUrl={treatmentUrl} treatment={treatment} />
      ) : null}
    </Box>
  );
};
