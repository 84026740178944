import React, { memo, useCallback } from "react";
import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { BloodTestResult } from "@deep-consulting-solutions/bmh-constants";

import { truncateName } from "../../TestResults.helpers";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    padding: s(1),
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${p.grey[300]}`,
    "&:first-child": {
      borderTop: "none",
    },
  },
  closeBtn: {
    marginLeft: "auto",
  },
}));

interface ListItemProps {
  result: BloodTestResult;
  index: number;
  removeComparison: (id: string) => any;
}

const ListItem: React.FC<ListItemProps> = ({
  result,
  index,
  removeComparison,
}) => {
  const onCloseClick = useCallback(() => {
    removeComparison(result.id);
  }, [removeComparison, result]);

  const classes = useStyle();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body2">
        {index + 1}. {truncateName(result.name).truncated}
      </Typography>
      <IconButton
        className={classes.closeBtn}
        size="small"
        onClick={onCloseClick}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default memo(ListItem);
