import { Button, Grid, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback } from "react";

const useStyles = makeStyles(({ palette: p }) => ({
  danger: {
    color: p.error.main,
    borderColor: p.error.main,
    cursor: "pointer",
  },
  action: {
    width: "100%",
  },
}));

interface Props {
  view: "error" | "fetch-error" | "form";
  onSubmit: (values: any) => void;
}

export const RequestChangesActions = ({ view, onSubmit }: Props) => {
  const classes = useStyles();

  const { isValid, values } = useFormikContext();

  const { handleClose } = useTreatmentWidgetLayout();

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const onSave = useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  return (
    <>
      {view === "error" || view === "fetch-error" ? (
        <Button variant="outlined" onClick={onClickClose} color="primary">
          CLOSE
        </Button>
      ) : null}
      {view === "form" ? (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickClose}
              className={classes.danger}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSave}
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
