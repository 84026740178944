import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import React from "react";

export const TreatmentMedicinesWarning = () => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-start"
    >
      <Box maxWidth="80%">
        <Alert severity="warning">
          When suggesting medicines, please be aware that some medicines might
          not be available in the client&apos;s region. Check the products and
          pharmacies module for more information.
        </Alert>
      </Box>
    </Box>
  );
};
