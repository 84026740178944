import { BloodTestResultItem } from "@deep-consulting-solutions/bmh-constants";
import { Group } from "./types";

export const TABLE_META = {
  buttons: {
    width: 250,
  },
  result: {
    width: 150,
  },
  range: {
    width: 150,
  },
  unit: {
    width: 100,
  },
};

const mapResult = (
  newResult?: BloodTestResultItem,
  oldResult?: BloodTestResultItem,
  currentResult?: BloodTestResultItem
) => {
  const defaultResult = {
    value: ("" as unknown) as number,
    unit: "",
    failureCode: "",
    abnormalFlag: "",
    normalRange: "",
  };
  const newResultItem = newResult || defaultResult;
  const oldResultItem = oldResult || defaultResult;
  const currentResultItem = currentResult || defaultResult;
  const name = newResult?.name || oldResult?.name || currentResult?.name || "";
  const id = newResult?.id || oldResult?.id || currentResult?.id || "";

  return {
    id,
    name,
    existingResult: {
      result: oldResultItem.value as number,
      unit: oldResultItem.unit,
      failureCode: oldResultItem.failureCode,
      abnormalFlag: !!oldResultItem.abnormalFlag,
      range: oldResultItem.normalRange,
    },
    newResult: {
      result: newResultItem.value as number,
      unit: newResultItem.unit,
      failureCode: newResultItem.failureCode,
      abnormalFlag: !!newResultItem.abnormalFlag,
      range: newResultItem.normalRange,
    },
    ...(currentResult
      ? {
          currentResult: {
            result: currentResultItem.value as number,
            unit: currentResultItem.unit,
            failureCode: currentResultItem.failureCode,
            abnormalFlag: !!currentResultItem.abnormalFlag,
            range: currentResultItem.normalRange,
          },
        }
      : {}),
  };
};

export const createTableData = (
  newResults: BloodTestResultItem[],
  oldResults: BloodTestResultItem[],
  currentResults?: BloodTestResultItem[]
): Group[] => {
  try {
    const allResults = [
      ...newResults,
      ...oldResults,
      ...(currentResults || []),
    ];

    const identifiersAndNames = allResults.reduce<Record<string, string[]>>(
      (previous, current) => {
        let identifier: string[] = previous[current.identifier] || [];
        const namesSet = new Set(identifier);
        namesSet.add(current.name.toUpperCase());
        identifier = Array.from(namesSet);
        return { ...previous, [current.identifier]: identifier };
      },
      {}
    );

    const oldResultMap = oldResults.reduce<
      Record<string, Record<string, BloodTestResultItem>>
    >((previous, current) => {
      const identifier = previous[current.identifier] || {};
      identifier[current.name] = current;
      return { ...previous, [current.identifier]: identifier };
    }, {});

    const currentResultMap = currentResults?.reduce<
      Record<string, Record<string, BloodTestResultItem>>
    >((previous, current) => {
      const identifier = previous[current.identifier] || {};
      identifier[current.name] = current;
      return { ...previous, [current.identifier]: identifier };
    }, {});

    const newResultMap = newResults.reduce<
      Record<string, Record<string, BloodTestResultItem>>
    >((previous, current) => {
      const identifier = previous[current.identifier] || {};
      identifier[current.name] = current;
      return { ...previous, [current.identifier]: identifier };
    }, {});

    const group: Record<string, Group["tests"]> = {};

    const getResult = (
      identifier: string,
      name: string,
      resultsMap: Record<
        string,
        Record<string, BloodTestResultItem | undefined> | undefined
      > = {}
    ) => {
      const nameAndResult = resultsMap[identifier];
      if (nameAndResult) {
        return nameAndResult[name];
      }
      return undefined;
    };

    Object.keys(identifiersAndNames)
      .sort()
      .forEach((identifier) => {
        if (!group[identifier]) {
          group[identifier] = [];
        }

        group[identifier] = identifiersAndNames[identifier]
          .sort()
          .map((name) =>
            mapResult(
              getResult(identifier, name, newResultMap),
              getResult(identifier, name, oldResultMap),
              getResult(identifier, name, currentResultMap)
            )
          )
          .filter((result) => !!result.name);
      });
    return Object.entries(group).map(([key, value]) => ({
      identifier: key,
      tests: value,
    }));
  } catch {
    return [];
  }
};
