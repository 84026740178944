import {
  SendInstructionScenarioEnum,
  GETPathwaySendInstructionsScenarioRes,
} from "@deep-consulting-solutions/bmh-constants";

import { ZohoPathway, BloodDrawerTypeEnum } from "types";

export const SCENARIO_MAPPER = {
  [SendInstructionScenarioEnum.PSVP]: [
    'Client will be explained that they will need to arrange the blood test with the blood taker independently. For convenience client will be sent a list of blood takers on a <a href="https://www.google.com/maps/d/viewer?mid=12_FkckGdFRzF_KC09oVkqZR45BMrNDz8&ll=53.2111110063702%2C-1.4929690575387555&z=6" target="_blank">Custom Google Map</a> maintained by BMH.',
    "Client will be explained that they will be sent a blood test collection kit and they should only carry out blood sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
  ],
  [SendInstructionScenarioEnum.PSFP]: [
    "Client will be explained that they will need to collect their own blood using a finger prick collection kit.",
    "Client will be explained that they will be sent a blood test collection kit and they should only carry out blood sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
  ],
  [SendInstructionScenarioEnum.NS]: [
    "Client will be explained that they will need to arrange the blood test with <strong><<Blood Taker Company Name>></strong> who is a travelling nurse service and will meet the client at the place of convenience. Client will be provided <strong><<Blood Taker Company Name>></strong>'s phone number.",
    "Client will be explained that they will need to pay an additional fee to <strong><<Blood Taker Company Name>></strong>.",
    "Client will be explained that they will be sent a blood test collection kit and they should only carry out blood sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
  ],
  [SendInstructionScenarioEnum.NSSP]: [
    "Client will be provided instructions specific for <strong><<Blood Taker Company Name>></strong> Nurse Service (below is the instruction text that will be shown to client):",
    "<strong><<Special Nurse Service Instruction Text>></strong>",
    "Client will be explained that they will be sent a blood test collection kit and they should only carry out blood sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
  ],
  [SendInstructionScenarioEnum.A]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using their own equipment and will arrange the testing of the blood.",
    "Client will be explained that they will <strong>not</strong> need to pay any additional fees to <strong><<Blood Taker Company Name>></strong>.",
    "Client will be sent a Blood Test Order Form as attachment to the email and will be asked to print it out before visiting <strong><<Blood Taker Company Name>></strong>. Client will <strong>not</strong> need to do anything else other than printing the form and visiting <strong><<Blood Taker Company Name>></strong>.",
  ],
  [SendInstructionScenarioEnum.B]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using their own equipment and will arrange the testing of the blood.",
    "Client will be explained that they will need to pay an additional fee for blood drawing service to <strong><<Blood Taker Company Name>></strong>.",
    "Client will be sent a Blood Test Order Form as attachment to the email and will be asked to print it out before visiting <strong><<Blood Taker Company Name>></strong>. Client will <strong>not</strong> need to do anything else other than printing the form and visiting <strong><<Blood Taker Company Name>></strong>.",
  ],
  [SendInstructionScenarioEnum.C]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using the equipment in BMH provided kit and will return to client a completed kit which the client will need to send to the lab.",
    "Client will be explained that they will be sent a blood test collection kit and they should only visit <strong><<Blood Taker Company Name>></strong> for sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
    "Client will be explained that they will <strong>not</strong> need to pay any additional fees to <strong><<Blood Taker Company Name>></strong>.",
  ],
  [SendInstructionScenarioEnum.CL]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using the equipment in BMH provided kit and will arrange the blood testing at that point.",
    "Client will be explained that they will be sent a blood test collection kit and they should only visit <strong><<Blood Taker Company Name>></strong> for sample collection upon receiving such kit.",
    "Client will be explained that after blood collection, <strong><<Blood Taker Company Name>></strong> will handle the sample themselves and no further client action will be needed.",
    "Client will be explained that they will <strong>not</strong> need to pay any additional fees to <strong><<Blood Taker Company Name>></strong>.",
  ],
  [SendInstructionScenarioEnum.D]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using the equipment in BMH provided kit and will return to client a completed kit which the client will need to send to the lab.",
    "Client will be explained that they will be sent a blood test collection kit and they should only visit <strong><<Blood Taker Company Name>></strong> for sample collection upon receiving such kit.",
    "Client will be explained that upon collection it will be client responsibility to mail the filled sample kit to the lab using instructions provided.",
    "Client will be explained that they will need to pay an additional fee for blood drawing service to <strong><<Blood Taker Company Name>></strong>.",
  ],
  [SendInstructionScenarioEnum.DL]: [
    "Client will be explained that they will need to get their blood taken at <strong><<Blood Taker Company Name>></strong> who will arrange the blood collection using the equipment in BMH provided kit and will arrange the blood testing at that point.",
    "Client will be explained that they will be sent a blood test collection kit and they should only visit <strong><<Blood Taker Company Name>></strong> for sample collection upon receiving such kit.",
    "Client will be explained that after blood collection, <strong><<Blood Taker Company Name>></strong> will handle the sample themselves and no further client action will be needed.",
    "Client will be explained that they will need to pay an additional fee for blood drawing service to <strong><<Blood Taker Company Name>></strong>.",
  ],
};

export const getInstructionsForScenarioRes = ({
  code,
  bloodTakerCompanyName,
  nurseServiceSpecialInstruction,
}: GETPathwaySendInstructionsScenarioRes["data"]) => {
  return SCENARIO_MAPPER[code]
    .map((instruction) => {
      return instruction
        .replace(
          /<<Blood Taker Company Name>>/g,
          bloodTakerCompanyName || "Blood Drawer"
        )
        .replace(
          /<<Special Nurse Service Instruction Text>>/g,
          nurseServiceSpecialInstruction || ""
        );
    })
    .filter((instruction) => !!instruction);
};

export const BLOOD_DRAW_FEE_HEADER = "Blood Draw Fee Must Be Collected";
export const BLOOD_DRAW_FEE_CONTENT = [
  "Our records indicate that for the <strong><<Blood Taker Company Name>></strong>, additional Blood Draw Fee must be collected from the client.",
  "The records indicate that the fee has not been collected yet.",
  "Please check the records and verify if payment was collected on the Blood Draw Fee or if management approved waiving this fee in this case.",
  "The Fee must be collected before sending patient instructions",
];
export const getBloodDrawFeeTexts = ({
  bloodTakerCompanyName,
}: GETPathwaySendInstructionsScenarioRes["data"]) => {
  return {
    header: BLOOD_DRAW_FEE_HEADER,
    content: BLOOD_DRAW_FEE_CONTENT.map((content) =>
      content.replace(
        /<<Blood Taker Company Name>>/g,
        bloodTakerCompanyName || "Blood Drawer"
      )
    ),
  };
};

export const shouldHaveAttachment = (record: ZohoPathway) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { BD_Category, BD_Provides_Own_Kits } = record;

  return (
    BD_Provides_Own_Kits &&
    [
      BloodDrawerTypeEnum.LABORATORY,
      BloodDrawerTypeEnum.LAB_MANUAL_RESULTS,
      BloodDrawerTypeEnum.PARTNER_BLOOD_DRAWER,
    ].includes(BD_Category as any)
  );
};
