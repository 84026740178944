import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "widgets/DispenseDrugs";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Typography, IconButton } from "@material-ui/core";

interface RequestChangesDialogProps {
  toggleChangesDialog: (isOpen: boolean) => () => void;
  isOpen: boolean;
  onRequestChanges: (changes: string) => () => Promise<void>;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: 3,
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const RequestChangesDialog: React.FC<RequestChangesDialogProps> = ({
  toggleChangesDialog,
  isOpen,
  onRequestChanges,
}) => {
  const classes = useStyles();
  const [changes, setChanges] = useState("");

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={toggleChangesDialog(false)}
        aria-labelledby="request-changes-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          onClose={toggleChangesDialog(false)}
          id="request-changes-dialog-title"
        >
          Request Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Specify the changes that need to be made.
          </DialogContentText>
          <TextField
            className={classes.text}
            name="requestedChanges"
            label="Changes to be made to this order"
            onChange={(e) => setChanges(e.target.value)}
            size="small"
            required
            multiline
            rows={3}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={toggleChangesDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!changes}
            onClick={onRequestChanges(changes)}
            color="primary"
          >
            Request Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
