import { n8nClient } from "apis";

interface PauseTreatmentPayload {
  reasonForPausingTreatment: string;
  contactClientLater: boolean;
  notifyClientOn: string;
  treatmentID: string;
}
export const pauseTreatment = async (payload: PauseTreatmentPayload) => {
  const res = await n8nClient.post(
    `/treatments/pausing-treatment-flow`,
    payload
  );
  return res.data;
};
