/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Container,
} from "@material-ui/core";
import { Formik, Form, Field, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { splitEmails, getMultipleEmailsValidation } from "helpers";
import {
  BP_SEND_INSTRUCTIONS_STATE,
  BP_SEND_NS_INSTRUCTIONS_STATE,
} from "configs";
import { useWidgetResize, useUpdateBluePrint } from "hooks";
import { ZohoPathway, ZohoModuleEnum } from "types";
import { pathwayRequests } from "redux/pathway";
import { zohoServices, notifications } from "services";
import SendEmailWrapper, {
  useSendEmailStatus,
} from "components/SendEmailWrapper";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import Loader from "components/Loader";

import {
  useSendInstructionScenario,
  ScenarioText,
  FeeText,
  shouldHaveAttachment,
} from "../PathwaySendInstructions";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    paddingTop: s(1),
    paddingBottom: s(1),
  },
  section: {
    marginTop: s(2),
    display: "flex",
    flexDirection: "column",
  },
  input: {
    with: "100%",
    marginBottom: s(0.5),
  },
  caption: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    marginBottom: s(1),
    textAlign: "right",
  },
  send: {
    marginLeft: s(1),
  },
  clientEmail: {
    color: p.primary.main,
    fontWeight: 600,
  },
}));

interface FormValues {
  emails: string;
  text: string;
  feeCollected: boolean;
  clientEmail: string;
}

interface PathwaySendIntructionsBlueprintProps {
  isNurseService?: boolean;
}

const PathwaySendInstructionsBlueprint: React.FC<PathwaySendIntructionsBlueprintProps> = ({
  isNurseService,
}) => {
  const {
    pathway: pathwayRecord,
    initialLoading,
    loading,
    clientInstructions,
    hasError,
    clientArray,
    ids,
    feeText,
  } = useSendInstructionScenario();

  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const { updateBluePrint } = useUpdateBluePrint();
  const { status, failures, sendEmails } = useSendEmailStatus({ resize });

  const clientEmail = clientArray[0]?.email ?? "";

  const validationSchema = useMemo(() => {
    return Yup.lazy((values: FormValues) => {
      return Yup.object({
        emails: getMultipleEmailsValidation({
          isRequired: !values.clientEmail,
          existingEmails: values.clientEmail || undefined,
        }),
        text: Yup.string(),
        feeCollected: feeText ? Yup.bool().oneOf([true]) : Yup.bool(),
      }).nullable() as any;
    });
  }, [feeText]);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      const emails: string[] = [];
      if (clientEmail) emails.push(clientEmail);
      emails.push(...splitEmails(values.emails));

      if (feeText) {
        try {
          await zohoServices.updateRecord<ZohoPathway>({
            Entity: ZohoModuleEnum.PATHWAYS,
            id: ids[0],
            data: {
              Blood_Draw_Fee_Collected: true,
            },
          });
        } catch (error: any) {
          const message =
            error?.message ||
            error?.data?.message ||
            (error.data && error.data[0]?.message) ||
            "Uknown error";

          notifications.notifyError(message);
          return;
        }
      }

      sendEmails({
        request: () =>
          pathwayRequests.sendInstructionEmails({
            emails,
            zohoId: ids[0],
            text: values.text,
            attachBloodTestOrderForm: shouldHaveAttachment(pathwayRecord),
          }),
      });
    },
    [sendEmails, ids, clientEmail, pathwayRecord, feeText]
  );

  const closeWidget = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  const closeWidgetOnSuccess = useCallback(async () => {
    const isUpdated = await updateBluePrint(
      isNurseService
        ? BP_SEND_NS_INSTRUCTIONS_STATE
        : BP_SEND_INSTRUCTIONS_STATE
    );
    zohoServices.closePopup(isUpdated);
  }, [updateBluePrint, isNurseService]);

  useEffect(() => {
    if (hasError || (clientInstructions && clientInstructions.length > 0)) {
      resize();
    }
  }, [resize, clientInstructions, hasError]);

  const classes = useStyle();

  if (initialLoading) {
    return <Loader open />;
  }

  if (hasError) {
    return (
      <div>
        <Container innerRef={wrapperRef}>
          <Box py="4rem" textAlign="center">
            Something went wrong
          </Box>
        </Container>
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        clientEmail,
        emails: "",
        text: "",
        feeCollected: false,
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <WidgetButtonWrapper isBlueprint>
            <Container innerRef={wrapperRef}>
              <Loader open={loading} />
              <Form>
                <SendEmailWrapper
                  status={status}
                  failures={failures}
                  onClose={closeWidget}
                  onSuccess={closeWidgetOnSuccess}
                >
                  <Box className={classes.wrapper}>
                    {feeText && <FeeText {...feeText} />}

                    <Typography variant="h5">
                      Send Instructions and Blood Test Order Form to Client
                    </Typography>

                    <ScenarioText instructions={clientInstructions} />

                    {!!clientEmail && (
                      <>
                        <Box className={classes.section}>
                          <Typography variant="body1">
                            Client&apos;s email:{" "}
                            <span className={classes.clientEmail}>
                              {clientEmail}
                            </span>
                          </Typography>
                        </Box>

                        <Box className={classes.section}>
                          <Field
                            inputProps={{ "aria-label": "emails" }}
                            name="emails"
                            component={TextField}
                            className={classes.input}
                            placeholder="Type additional recipients' email addresses"
                          />
                          <Typography
                            variant="caption"
                            className={classes.caption}
                          >
                            Additional recipients&apos; email addresses. If you
                            want to enter multiple additional email addresses,
                            type them, separating each one from previous address
                            with comma. Example: abc@def.com, ghi@jkl.com
                          </Typography>
                        </Box>
                      </>
                    )}

                    {!clientEmail && (
                      <>
                        <Box className={classes.section}>
                          <Typography variant="body2" color="error">
                            Client does not have email. Please provide at least
                            1 email address.
                          </Typography>
                        </Box>

                        <Box className={classes.section}>
                          <Field
                            inputProps={{ "aria-label": "emails" }}
                            name="emails"
                            component={TextField}
                            className={classes.input}
                            placeholder="Type recipients' email addresses"
                          />
                          <Typography
                            variant="caption"
                            className={classes.caption}
                          >
                            Recipients&apos; email addresses. If you want to
                            enter additional email addresses, type them,
                            separating each one from previous address with
                            comma. Example: abc@def.com, ghi@jkl.com
                          </Typography>
                        </Box>
                      </>
                    )}

                    <Box className={classes.section}>
                      <Field
                        name="text"
                        component={TextField}
                        className={classes.input}
                        placeholder="Type message here or leave empty"
                        multiline
                      />
                    </Box>
                    <Box className={classes.buttons}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={closeWidget}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.send}
                        disabled={!isValid || isSubmitting}
                        type="submit"
                      >
                        Send
                      </Button>
                    </Box>
                  </Box>
                </SendEmailWrapper>
              </Form>
            </Container>
          </WidgetButtonWrapper>
        );
      }}
    </Formik>
  );
};

export default PathwaySendInstructionsBlueprint;
