import { Box } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { Column, RowActionsEnum } from "types";
import { getMissingQuantity, getValueFromObject } from "helpers";
import { useFormikContext } from "formik";
import {
  DispenseOrderPages,
  MissingState,
  OrderItemDispensedUnit,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";
import { EditableTable } from "./EditableTable";
import { DispensedUnits } from "./DispensedUnits";

interface OrderItemDispensedUnitsProps {
  fieldName: string;
  quantity: number;
  item: TreatmentOrderItem;
  manufacturers: string[];
}

export const OrderItemDispensedUnits: React.FC<OrderItemDispensedUnitsProps> = ({
  fieldName,
  quantity,
  item,
  manufacturers,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const variantFieldName = useMemo(() => `${fieldName}.variants`, [fieldName]);

  const columns: Column<OrderItemDispensedUnit>[] = useMemo(
    () => [
      {
        key: "manufacturer",
        header: "Manufacturer",
        editable: true,
        align: "left",
        info: "",
        required: true,
        select: true,
        options: manufacturers,
      },
      {
        key: "batchNumber",
        editable: true,
        header: "Batch Number",
        align: "left",
        info: "",
        required: true,
      },
      {
        key: "expiryDate",
        editable: true,
        header: "Expiry Date",
        align: "left",
        info: "",
        required: true,
        type: "date",
      },
    ],
    [manufacturers]
  );

  const allUnitSameBatchNumber: boolean = useMemo(() => {
    return getValueFromObject(`${fieldName}.allUnitSameBatchNumber`, values);
  }, [fieldName, values]);
  const variants: Omit<
    OrderItemDispensedUnit,
    "id" | "isDeletable"
  >[] = useMemo(() => {
    return getValueFromObject(variantFieldName, values);
  }, [values, variantFieldName]);

  const missingQuantity: number | null = useMemo(
    () => getValueFromObject(`${fieldName}.missingQuantity`, values),
    [values, fieldName]
  );
  const missingState: MissingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [values, fieldName]
  );
  const replacementQuantity: number | null = useMemo(
    () => getValueFromObject(`${fieldName}.replacementQuantity`, values),
    [values, fieldName]
  );
  const finalQuantity: number = useMemo(() => {
    if (missingState === MissingState.REPLACE) {
      return replacementQuantity || 0;
    }
    return (
      quantity -
      getMissingQuantity(missingQuantity, (values as any).currentPage)
    );
  }, [quantity, replacementQuantity, missingQuantity, missingState, values]);

  const disableAddNewButton = useMemo(() => {
    return (
      (allUnitSameBatchNumber && variants?.length === 1) ||
      variants.length === finalQuantity
    );
  }, [variants, allUnitSameBatchNumber, finalQuantity]);

  const dispenseUnits = useMemo(
    () =>
      variants.map((unit) => ({
        ...unit,
        id: String(Math.random()),
        isDeletable: true,
      })),
    [variants]
  );

  const updateDispensedUnit = useCallback(
    (data: OrderItemDispensedUnit) => {
      const mDispensedUnits = [...dispenseUnits];
      const index = mDispensedUnits.findIndex((row) => row.id === data.id);
      if (index === -1) {
        return false;
      }
      mDispensedUnits[index] = data;
      setFieldValue(variantFieldName, mDispensedUnits);
      return true;
    },
    [dispenseUnits, variantFieldName, setFieldValue]
  );

  const deleteDispensedunit = useCallback(
    (id: string) => {
      const mDispensedUnits = dispenseUnits.filter((row) => row.id !== id);
      setFieldValue(variantFieldName, mDispensedUnits);
      return true;
    },
    [dispenseUnits, variantFieldName, setFieldValue]
  );

  const createDispensedUnit = useCallback(
    (data: Omit<OrderItemDispensedUnit, "id">) => {
      const mDispensedUnits = [...dispenseUnits];
      setFieldValue(variantFieldName, [
        ...mDispensedUnits,
        {
          ...data,
          id: String(Math.random()),
          isDeletable: true,
        },
      ]);
      return true;
    },
    [dispenseUnits, variantFieldName, setFieldValue]
  );

  const validateCell = useCallback(
    (
      row: OrderItemDispensedUnit,
      val: string,
      dataKey: keyof OrderItemDispensedUnit,
      required: boolean
    ) => {
      let error = false;
      let helperText = "";
      if (((val as unknown) as number) !== 0 && !val && required) {
        return {
          error: true,
          helperText: `${dataKey} is required`,
        };
      }

      // only days in the future can be expiry date
      if (dataKey === "expiryDate") {
        const today = new Date();
        const expiryDate = new Date(val);
        if (expiryDate < today) {
          error = true;
          helperText = "Expiry date must be in the future";
        }
      }

      return {
        error,
        helperText,
      };
    },
    []
  );

  const checkMissingQuantity = useMemo(() => {
    return [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes((values as any).currentPage);
  }, [values]);

  if (
    checkMissingQuantity &&
    missingQuantity === item.Quantity &&
    missingState !== MissingState.REPLACE
  ) {
    return null;
  }

  return (
    <>
      <DispensedUnits
        item={item}
        fieldName={fieldName}
        quantity={finalQuantity}
      />
      <Box>
        <EditableTable<OrderItemDispensedUnit>
          columns={columns}
          actions={[RowActionsEnum.Edit]}
          handlers={{
            onRowUpdate: updateDispensedUnit,
            onRowDelete: deleteDispensedunit,
            onRowCreate: createDispensedUnit,
          }}
          title=""
          description=""
          rows={dispenseUnits}
          validateCell={validateCell}
          entity="Dispensed Unit"
          disableAddNewButton={disableAddNewButton}
        />
      </Box>
    </>
  );
};
