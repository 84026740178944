import React, { useCallback, useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import { zohoServices } from "services";
import { Formik, FormikConfig, useFormikContext } from "formik";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { getTreatmentOrderItems } from "services/treatments/medication-disposal";
import { CustomInputField } from "components/CustomInputField";
import { confirmPackingValidationSchema } from "helpers";
import {
  confirmOrderPacking,
  getCouriers,
  getDeliveryChangeNotes,
  getTreatmentOrder,
} from "services/treatments/confirm-order-packing";
import { CustomAutocompleteField } from "components/CustomAutocompleteField";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import {
  FormattedCourier,
  FormattedTreatmentOrder,
  InitialValues,
} from "./types";
import { ConfirmOrderPackingTable } from "./ConfirmOrderPackingTable";

const ActionButtons = ({ closeWidget }: { closeWidget: () => void }) => {
  const { submitForm, isSubmitting, isValid } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Close
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={submitForm}
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Confirm"}
      </Button>
    </Box>
  );
};

export function ConfirmOrderPacking() {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);
  const [
    treatmentOrder,
    setTreatmentOrder,
  ] = useState<FormattedTreatmentOrder | null>(null);
  const [deliveryChangeNotes, setDeliveryChangeNotes] = useState("");
  const [couriers, setCouriers] = useState<FormattedCourier[]>([]);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [rowCount, setRowCount] = useState(0);
  const [showDeliveryNotes, setShowDeliveryNotes] = useState(false);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          records: [
            {
              id: treatmentOrderId,
              courierOrderNumber: values.courierOrderNumber,
            },
          ],
          courierId: (values.courier as FormattedCourier).id,
        };
        await confirmOrderPacking(payload);
      } catch (error) {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentOrderId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const [
        treatmentOrderRes,
        treatmentOrderItems,
        formattedCouriers,
        deliveryChangeNotesRes,
      ] = await Promise.all([
        getTreatmentOrder(treatmentOrderId),
        getTreatmentOrderItems(treatmentOrderId),
        getCouriers(),
        getDeliveryChangeNotes(treatmentOrderId),
      ]);

      const hasMissingItems = treatmentOrderItems.some(
        (t) => t.missingQuantity
      );

      setShowDeliveryNotes(hasMissingItems);
      setInitialValues({
        courier: {
          id: treatmentOrderRes?.courierId || "",
          value: treatmentOrderRes?.courierId || "",
          label: treatmentOrderRes?.courier || "",
          title: treatmentOrderRes?.courier || "",
        },
        courierOrderNumber: treatmentOrderRes?.courierOrderNumber || "",
        treatmentOrderItems,
      });
      setTreatmentOrder(treatmentOrderRes);
      setCouriers(formattedCouriers);
      setDeliveryChangeNotes(deliveryChangeNotesRes);
      setRowCount(treatmentOrderItems.length);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={confirmPackingValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={1300}>
        <TreatmentWidgetTitle title="Confirm Order Packing" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading && (
            <Box
              height="480px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <>
              <Box minHeight="620px">
                <Box display="flex" flexDirection="column" gridRowGap="4px">
                  <Typography>
                    Order Number: {treatmentOrder?.orderNumber}
                  </Typography>
                  <Typography>
                    Patient Name: {treatmentOrder?.patient.name}
                  </Typography>
                  <Typography>Patient ID: BMH-13802-ID</Typography>
                  <Typography>
                    Patient Email: {treatmentOrder?.patient.email}
                  </Typography>
                  <Typography>
                    Patient Mobile: {treatmentOrder?.patient.phone}
                  </Typography>
                  <Typography>
                    Patient Shipping Address:{" "}
                    {treatmentOrder?.patient.shippingAddress}
                  </Typography>
                  <Typography style={{ marginTop: "24px" }}>
                    Make sure the items mentioned below was packed and prepared
                    for shipping for this order before saving.
                  </Typography>
                </Box>
                <ConfirmOrderPackingTable rowCount={rowCount} />
                {deliveryChangeNotes && showDeliveryNotes && (
                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Delivery Change Note
                    </Typography>
                    <Box width="900px">
                      <Markdown>{deliveryChangeNotes}</Markdown>
                    </Box>
                  </Box>
                )}
                <Box width="90%" margin="0px auto">
                  <Box
                    display="flex"
                    alignItems="center"
                    gridColumnGap="32px"
                    marginTop={2}
                  >
                    <Typography
                      style={{
                        width: "250px",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      Courier
                    </Typography>
                    <Box width="400px">
                      <CustomAutocompleteField
                        name="courier"
                        options={couriers}
                      />
                    </Box>
                  </Box>
                  <Box
                    marginTop={2}
                    display="flex"
                    alignItems="center"
                    gridColumnGap="32px"
                  >
                    <Typography
                      style={{
                        width: "250px",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      Courier Order Number
                    </Typography>
                    <Box width="400px">
                      <CustomInputField name="courierOrderNumber" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
}
