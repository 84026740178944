import { Box, Chip, TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption } from "types";

interface Props {
  name?: string;
}
export const NextCheckupTypeField = ({ name }: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("nextCheckupType", name), [
    name,
  ]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(() => {
    return [
      {
        value: "3 weeks check-up",
        title: "3 weeks check-up",
      },
      {
        value: "6 weeks check-up",
        title: "6 weeks check-up",
      },
      {
        value: "6 months check-up",
        title: "6 months check-up",
      },
      {
        value: "12 months check-up",
        title: "12 months check-up",
      },
    ];
  }, []);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, []);
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Box mb={2} maxWidth={600}>
      <Field
        required
        name={fieldName}
        component={Autocomplete}
        options={options}
        getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
        onChange={onChange}
        onBlur={() => {
          setFieldTouched(fieldName, true);
        }}
        color="primary"
        renderTags={(value: any[], getTagProps: (param: any) => any) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              label={typeof option === "string" ? option : option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            required
            name={fieldName}
            variant="outlined"
            label="Next Checkup Type"
            helperText={
              fieldTouched && typeof fieldError === "string" && fieldError
            }
            error={
              fieldTouched && typeof fieldError === "string" && !!fieldError
            }
            size="small"
          />
        )}
      />
    </Box>
  );
};
