import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  makeStyles,
  Box,
  Button,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Formik, Form, Field, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Mcm } from "@deep-consulting-solutions/bmh-constants";

import { Contact, ZohoClientRecord } from "types";
import { useWidgetResize } from "hooks";
import { zohoServices } from "services";
import Loader from "components/Loader";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import { getENText } from "helpers";
import { contactsActions, contactsSelectors } from "redux/contacts";
import { AppDispatch } from "redux/types";

enum AssignMcmStatus {
  SELECT,
  CONFIRM,
  INFO,
}

export interface AssignMcmProps {
  clientLoading: boolean;
  client?: Contact;
  zohoClients?: ZohoClientRecord[];
}

const useStyle = makeStyles(({ spacing: s }) => ({
  section: {
    marginTop: s(2),
  },
  input: {
    with: "100%",
    marginBottom: s(0.5),
  },
  selectedMcmDropdownPaper: {
    maxHeight: "calc(100% - 32px)",
  },
  formBtns: {
    marginTop: s(2),
    marginBottom: s(1),
    textAlign: "right",
  },
  send: {
    marginLeft: s(1),
  },
}));

interface FormValues {
  selectedMcm: string;
}

const validationSchema = Yup.object({
  selectedMcm: Yup.string().required(getENText("settings.field.required")),
});

const AssignMcm: React.FC<AssignMcmProps> = ({
  clientLoading,
  client,
  zohoClients,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const assignableQualifiedMcms = useSelector(contactsSelectors.getMcms);

  const [assignMcmStatus, setAssignMcmStatus] = useState(
    AssignMcmStatus.SELECT
  );
  const [selectedMcm, setSelectedMcm] = useState<Mcm>();
  const [loading, setLoading] = useState(false);

  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values) => {
      const mcmObj = assignableQualifiedMcms.find(
        (mcm) => mcm.id === values.selectedMcm
      );
      setSelectedMcm(mcmObj);
      setAssignMcmStatus(AssignMcmStatus.CONFIRM);
    },
    [assignableQualifiedMcms]
  );

  const handleAssignMcm = useCallback(async () => {
    setLoading(true);
    const contactsZohoIds =
      // eslint-disable-next-line no-nested-ternary
      zohoClients && zohoClients.length > 1
        ? zohoClients?.map((zohoClient) => zohoClient.id) || []
        : client?.zohoID
        ? [client.zohoID]
        : [];
    if (contactsZohoIds.length > 0) {
      const res = await dispatch(
        contactsActions.assignMcm({
          mcmId: selectedMcm?.id || "",
          contactsZohoIds,
        })
      );
      if (contactsActions.assignMcm.fulfilled.match(res)) {
        setAssignMcmStatus(AssignMcmStatus.INFO);
      }
    }
    setLoading(false);
  }, [client?.zohoID, dispatch, selectedMcm?.id, zohoClients]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(contactsActions.getAssignableQualifiedMcms());
      setLoading(false);
    })();
  }, [dispatch]);

  useEffect(() => {
    resize({ addHeight: 20 });
  }, [resize, assignMcmStatus]);

  const classes = useStyle();

  return (
    <Formik<FormValues>
      key={JSON.stringify(assignableQualifiedMcms)}
      enableReinitialize
      initialValues={{
        selectedMcm: client?.mcm?.id || assignableQualifiedMcms[0]?.id,
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {() => {
        return (
          <WidgetButtonWrapper>
            <Container innerRef={wrapperRef}>
              {loading || clientLoading ? (
                <Box margin="8rem 0">
                  <Loader open />
                </Box>
              ) : (
                (() => {
                  switch (assignMcmStatus) {
                    case AssignMcmStatus.CONFIRM:
                      return (
                        <Box>
                          <Box className={classes.section}>
                            <Typography variant="body1" color="textSecondary">
                              {zohoClients && zohoClients.length > 1
                                ? `You are going to assign PCM ${selectedMcm?.name} to ${zohoClients.length} Clients.`
                                : `You are going to assign PCM ${
                                    selectedMcm?.name
                                  } to client${
                                    client?.firstName
                                      ? ` ${client?.firstName}`
                                      : ""
                                  }${
                                    client?.lastName
                                      ? ` ${client?.lastName}`
                                      : ""
                                  }.`}
                            </Typography>
                          </Box>
                          <Box className={classes.formBtns}>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => closeWidget()}
                            >
                              Cancel
                            </Button>

                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.send}
                              onClick={handleAssignMcm}
                            >
                              Confirm
                            </Button>
                          </Box>
                        </Box>
                      );
                    case AssignMcmStatus.INFO:
                      return (
                        <Box>
                          <Box className={classes.section}>
                            <Typography variant="body1" color="textSecondary">
                              {zohoClients && zohoClients.length > 1
                                ? `PCM ${selectedMcm?.name} is now assigned to ${zohoClients.length} Clients.`
                                : `PCM ${
                                    selectedMcm?.name
                                  } is now assigned to client${
                                    client?.firstName
                                      ? ` ${client?.firstName}`
                                      : ""
                                  }${
                                    client?.lastName
                                      ? ` ${client?.lastName}`
                                      : ""
                                  }.`}
                            </Typography>
                          </Box>
                          <Box className={classes.formBtns}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.send}
                              onClick={() => closeWidget(true)}
                            >
                              Got it
                            </Button>
                          </Box>
                        </Box>
                      );
                    default:
                      if (assignableQualifiedMcms.length === 0) {
                        return (
                          <Box>
                            <Box className={classes.section}>
                              <Typography variant="body1" color="textSecondary">
                                No PCMs available.
                              </Typography>
                            </Box>
                            <Box className={classes.formBtns}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.send}
                                onClick={() => closeWidget()}
                              >
                                Ok
                              </Button>
                            </Box>
                          </Box>
                        );
                      }
                      return (
                        <Form>
                          <Box className={classes.section}>
                            <Field
                              component={TextField}
                              name="selectedMcm"
                              label="Selected PCM"
                              select
                              SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                  classes: {
                                    paper: classes.selectedMcmDropdownPaper,
                                  },
                                },
                              }}
                              className={classes.input}
                            >
                              {assignableQualifiedMcms.map((mcm) => (
                                <MenuItem key={mcm.id} value={mcm.id}>
                                  {mcm.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Box>
                          <Box className={classes.formBtns}>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => closeWidget()}
                              type="button"
                            >
                              Cancel
                            </Button>

                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.send}
                              type="submit"
                            >
                              Assign
                            </Button>
                          </Box>
                        </Form>
                      );
                  }
                })()
              )}
            </Container>
          </WidgetButtonWrapper>
        );
      }}
    </Formik>
  );
};

export default AssignMcm;
