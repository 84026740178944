import React, { useMemo } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { CustomMultiSelect } from "components/CustomMultiSelect";
import { CustomNumberField } from "components/CustomNumberField";
import { CustomInputField } from "components/CustomInputField";
import { CustomTextareaField } from "components/CustomTextareaField";
import { CustomAutocompleteField } from "components/CustomAutocompleteField";
import { CustomFileInput } from "components/CustomFileInput";
import { CustomPatientField } from "components/CustomPatientField";
import { InitialValues } from "./types";
import { externalDoctorOption, newPrescriptionItem } from "./constants";

export const CreateManualPrescriptionForm = () => {
  const { values, errors } = useFormikContext<InitialValues>();
  const showShowDeleteIcon = values.prescriptionItems.length > 1;
  const shouldDisableAddItemButton = useMemo(() => !!errors.prescriptionItems, [
    errors.prescriptionItems,
  ]);
  return (
    <>
      <Box mb={2}>
        <Typography
          style={{
            color: "#6B7280",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Requester
        </Typography>
        <Grid container spacing={3} style={{ width: "80%" }}>
          <Grid item sm={6}>
            <CustomInputField name="requesterName" label="Requester Name" />
          </Grid>
          <Grid item sm={6}>
            <CustomInputField name="requesterEmail" label="Requester Email" />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          style={{
            color: "#6B7280",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Prescription
        </Typography>
        <Grid container spacing={3} style={{ width: "80%" }}>
          <Grid item sm={6}>
            <CustomFileInput
              name="prescriptionDocument"
              label="Signed Prescription Document *"
              required
            />
          </Grid>
          <Grid item sm={6}>
            <CustomInputField
              name="numberOfRepetition"
              label="Number of Repetitions"
            />
          </Grid>
          <Grid item sm={6}>
            <CustomAutocompleteField
              name="doctor"
              label="Doctor"
              options={[externalDoctorOption, ...values.doctors]}
            />
          </Grid>
          <Grid item sm={6}>
            {values.doctor?.value === "external" && (
              <CustomInputField
                name="externalDoctorName"
                label="External Doctor Name"
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <CustomPatientField />
          </Grid>
          <Grid item sm={6}>
            <Field
              component={FormikDatePicker}
              name="patientDob"
              placeholder="Patient DOB"
              size="small"
              disablePast={false}
              disableFuture
              fullWidth
            />
          </Grid>
          {values.patient?.value === "new" && (
            <>
              <Grid item sm={6}>
                <CustomInputField
                  name="patientFirstName"
                  label="Patient First Name"
                />
              </Grid>
              <Grid item sm={6}>
                <CustomInputField
                  name="patientLastName"
                  label="Patient Last Name"
                />
              </Grid>
            </>
          )}
          <Grid item sm={6}>
            <CustomInputField
              name="patientFoodDrugAllergies"
              label="Patient drug and food Allergies"
              required={false}
            />
          </Grid>
          <Grid item sm={6}>
            <CustomMultiSelect
              options={values.allergiesOptions}
              name="patientAllergies"
              label="Allergies"
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "#6B7280",
                fontWeight: "bold",
              }}
            >
              Prescription Items
            </Typography>
          </Box>
        </Grid>
        <FieldArray name="prescriptionItems">
          {({ push, remove }) => (
            <>
              {values.prescriptionItems.length > 0 &&
                values.prescriptionItems.map((p, index) => (
                  <Paper
                    key={p.product?.id}
                    variant="outlined"
                    style={{
                      margin: "8px auto",
                      width: "100%",
                      padding: "16px",
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={3}>
                      <Grid item sm={showShowDeleteIcon ? 4 : 5}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="start"
                          width="100%"
                          mb={1}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              color: "#6B7280",
                            }}
                          >
                            Product *
                          </Typography>
                        </Box>
                        <CustomAutocompleteField
                          name={`prescriptionItems.${index}.product`}
                          options={values.products}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Box
                          display="flex"
                          alignItems="start"
                          color="text.secondary"
                          mb={1}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#6B7280",
                              fontWeight: "bold",
                            }}
                          >
                            Prescription Total Quantity *
                          </Typography>
                        </Box>
                        <CustomNumberField
                          name={`prescriptionItems.${index}.prescriptionTotalQuantity`}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Box>
                          <Box mb={1}>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#6B7280",
                                fontWeight: "bold",
                                width: "100%",
                              }}
                            >
                              Usage Notes *
                            </Typography>
                          </Box>
                          <CustomTextareaField
                            rows={1}
                            rowsMax={3}
                            name={`prescriptionItems.${index}.usageNotes`}
                          />
                        </Box>
                      </Grid>
                      {showShowDeleteIcon ? (
                        <Grid item sm={1}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="end"
                            alignItems="center"
                            height="100%"
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Paper>
                ))}
              <Box my={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={shouldDisableAddItemButton}
                  onClick={() => push(newPrescriptionItem)}
                >
                  Add Item
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      </Box>
    </>
  );
};
