import { createStyles, makeStyles, Typography } from "@material-ui/core";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { notifications, zohoServices } from "services";
import { AxiosError } from "axios";
import {
  InvoiceCreationFailureReason,
  BluePrintStagesEnum,
} from "@deep-consulting-solutions/bmh-constants";

import { postGenerateBloodDrawerInvoice } from "redux/pathway/requests";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { Actions } from "./Actions";
import { PageEnum } from "./types";
import { ErrorComponent } from "./ErrorComponent";
import { Success } from "./Success";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flex: 1,
      padding: s(2),
    },
    actions: {
      padding: s(2),
    },
    title: {
      padding: s(2),
    },
  })
);

export const GenerateInvoice = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const entity = useSelector(zohoSelectors.getEntity);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(PageEnum.Loading);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [failureReason, setFailureReason] = useState("");

  const onCancelClick = useCallback(async (reload?: boolean) => {
    await zohoServices.closePopup(reload === true);
  }, []);

  const onDone = useCallback(() => {
    try {
      onCancelClick(true);
    } catch {
      //
    }
  }, [onCancelClick]);

  const onHandleManually = useCallback(async () => {
    try {
      setLoading(true);
      await zohoServices.updateRecord({
        Entity: entity,
        id: ids[0],
        data: {
          Blueprint_Status:
            BluePrintStagesEnum.PENDING_BLOOD_DRAW_INVOICE_CREATION,
        },
      });
      onCancelClick(true);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [onCancelClick, entity, ids]);

  const handleUpdateToPendingItemsCreation = useCallback(() => {
    try {
      onCancelClick(true);
    } catch (e) {
      //
    } finally {
      //
    }
  }, [onCancelClick]);

  const onHandleApproval = useCallback(async () => {
    if (!ids?.length) {
      return;
    }
    try {
      setLoading(true);
      const res = await postGenerateBloodDrawerInvoice(ids[0]);
      setInvoiceLink(res.invoiceUrl || "");
      setPage(PageEnum.Success);
    } catch (e) {
      const error = e as AxiosError;
      const invoiceCreationFailureReason =
        error.response?.data?.data?.invoiceCreationFailureReason;
      if (invoiceCreationFailureReason) {
        setFailureReason(error.response?.data.message);
        setPage(
          invoiceCreationFailureReason ===
            InvoiceCreationFailureReason.MISSING_ITEMS
            ? PageEnum.Error
            : PageEnum.CreateInvoiceError
        );
      } else {
        notifications.notifyError(
          error.response?.data?.message || error.message
        );
      }
    } finally {
      setLoading(false);
    }
  }, [ids]);

  useEffect(() => {
    onHandleApproval();
  }, [onHandleApproval]);

  const classes = useStyles();
  return (
    <WidgetLayout>
      <WidgetTitle className={classes.title}>
        <Typography component="h1" variant="h4">
          Generate Invoice
        </Typography>
      </WidgetTitle>
      <WidgetContent className={classes.content}>
        <Loader open={loading} />
        {page === PageEnum.Error && (
          <ErrorComponent
            title="Invoice creation failed"
            body="Invoice creation failed because of missing items and the manager was notified to create the missing items in Zoho Books."
          />
        )}
        {page === PageEnum.CreateInvoiceError && (
          <ErrorComponent
            handleRetry={onHandleApproval}
            title="Invoice creation failed"
            failureReason={failureReason}
            body='Retry or Create the retest invoice on Zoho Books then confirm the creation using "Confirm Invoice Creation" button on the next Screen.'
          />
        )}
        {page === PageEnum.Success && <Success invoiceLink={invoiceLink} />}
      </WidgetContent>

      <WidgetActions className={classes.actions}>
        <Actions
          loading={loading}
          onDoneClick={onDone}
          page={page}
          handleUpdateToPendingItemsCreation={
            handleUpdateToPendingItemsCreation
          }
          onHandleManually={onHandleManually}
        />
      </WidgetActions>
    </WidgetLayout>
  );
};
