import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  createStyles,
  Button,
  lighten,
  darken,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Theme,
  IconButton,
} from "@material-ui/core";
import {
  Error,
  InfoOutlined,
  CheckCircle,
  ReportProblem,
  Close,
} from "@material-ui/icons";
import { StyledButton } from "components/StyledButton";
import { Color } from "@material-ui/lab";

export const iconsMap = {
  success: CheckCircle,
  error: Error,
  warning: ReportProblem,
  info: InfoOutlined,
};

interface StylesProps {
  type: Color;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    header: {
      position: "relative",
    },
    close: {
      position: "absolute",
      right: 0,
      bottom: theme.spacing(1),
    },
    messageContainer: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      backgroundColor: ({ type }) => lighten(theme.palette[type].light, 0.85),
      color: ({ type }: StylesProps) => darken(theme.palette[type].dark, 0.55),
    },
    messageIcon: {
      marginRight: theme.spacing(1.5),
      color: ({ type }: StylesProps) => theme.palette[type].main,
    },
    confirm: {
      color: theme.palette.background.paper,
      backgroundColor: ({ type }) => theme.palette[type].main,
      "&:hover": {
        backgroundColor: ({ type }) => theme.palette[type].dark,
      },
    },
  })
);

interface DialogConfirmProps {
  open: boolean;
  title: string;
  message: string;
  type: Color;
  confirmBtnLabel: string;
  confirmBtnIcon?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

export const DialogConfirm = ({
  open,
  title,
  message,
  type,
  onClose,
  onConfirm,
  confirmBtnLabel,
  confirmBtnIcon,
}: DialogConfirmProps) => {
  const classes = useStyles({ type });
  const Icon = iconsMap[type];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confrim-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle
        id="confrim-dialog-title"
        className={classes.header}
        disableTypography
      >
        <Typography component="h2" variant="h5">
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={2}
          className={classes.messageContainer}
        >
          <Icon className={classes.messageIcon} />
          <Typography variant="subtitle1" component="p" id="modal-description">
            {message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <StyledButton
          onClick={onConfirm}
          variant="contained"
          color={type}
          endIcon={confirmBtnIcon}
        >
          {confirmBtnLabel}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
