import { LabDisplayOrder } from "@deep-consulting-solutions/bmh-constants";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { DragHandle, Info } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s }) => {
  return {
    container: {
      display: "flex",
      padding: s(1),
    },
    item: {
      userSelect: "none",
      padding: s(2),
      margin: `0 ${s(1)}px 0 0`,
      background: "grey",
      color: "white",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: s(0.5),
      paddingBottom: s(0.5),
      borderRadius: s(3),
      display: "flex",
      alignItems: "center",
      fontSize: 12,
    },
    leftHandle: {
      marginRight: s(0.5),
      opacity: 0,
      fontSize: 12,
    },
    rightHandle: {
      marginLeft: s(0.5),
      fontSize: 12,
    },
    infoBox: {
      display: "flex",
      alignItems: "center",
    },
    infoIcon: {
      color: "grey",
      marginRight: s(0.5),
      fontSize: 20,
    },
    info: {
      fontSize: 12,
    },
  };
});

interface Props {
  labsOrder: LabDisplayOrder[];
  updateOrder: (labKeys: string[]) => Promise<boolean>;
}

export const LabsManualOrder: React.FC<Props> = ({
  labsOrder,
  updateOrder,
}) => {
  const [localOrder, setLocalOrder] = useState<LabDisplayOrder[]>(labsOrder);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) return;
      const original = [...localOrder];

      const updated = [...localOrder];
      const [removed] = updated.splice(result.source.index, 1);
      updated.splice(result.destination.index, 0, removed);
      setLocalOrder(updated);

      const isOK = await updateOrder(updated.map((l) => l.lab.key));
      if (!isOK) setLocalOrder(original);
    },
    [localOrder, updateOrder]
  );

  useEffect(() => {
    setLocalOrder(labsOrder);
  }, [labsOrder]);

  const classes = useStyles();

  if (!localOrder.length) return null;

  return (
    <div>
      <div className={classes.infoBox}>
        <Tooltip title="While entering a blood test result for an unknown lab in the client record, this is the order at which the observation id, unit, normal range will be searched to auto fill those fields on selection of a blood test result line item">
          <Info className={classes.infoIcon} />
        </Tooltip>
        <Typography className={classes.info}>
          Labs Order for Manual Results
        </Typography>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="manual-lab-order" direction="horizontal">
          {(dropProvided) => {
            return (
              <div
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
                className={classes.container}
              >
                {localOrder.map(({ lab }, index) => {
                  return (
                    <Draggable key={lab.id} draggableId={lab.id} index={index}>
                      {(dragProvided) => {
                        return (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.dragHandleProps}
                            {...dragProvided.draggableProps}
                            className={classes.item}
                          >
                            <DragHandle className={classes.leftHandle} />
                            {lab.key.toUpperCase()}
                            <DragHandle className={classes.rightHandle} />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {dropProvided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
