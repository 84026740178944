import React, { useMemo, useState, useCallback } from "react";
import {
  makeStyles,
  Theme,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Backup as AddIcon } from "@material-ui/icons";
import {
  BloodTestResult,
  BluePrintStagesEnum,
  Pathway,
} from "@deep-consulting-solutions/bmh-constants";

import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { useLoadingContext } from "../LoadingContext";
import {
  TEST_RESULTS_TABLE_META,
  BloodTestResultField,
} from "../TestResults.types";
import { ViewWrapperBox } from "../TestResults.styles";
import { sortByCollectedDate } from "../TestResults.helpers";
import ListViewItem from "./ListViewItem";
import ListViewHeaderCell from "./ListViewHeaderCell";

interface ListViewProps {
  results: BloodTestResult[];
  selected: { [id: string]: true };
  onRowSelect: (id: string) => any;
  onResultSelect: (r: BloodTestResult) => any;
  onCompareClick: () => any;
  openResultForm: () => any;
  openDeleteDialog: () => any;
  isPathway?: boolean;
  pathwayRecord?: Pathway;
}

const useStyle = makeStyles<Theme, ListViewProps>(
  ({ spacing: s, palette: p }) => ({
    topBar: {
      display: "flex",
      alignItems: "center",
      marginBottom: s(1),
      borderBottom: ({ isPathway }) => (isPathway ? "1px solid #eee" : "unset"),
      paddingBottom: "0.5rem",
      boxShadow: ({ isPathway }) =>
        isPathway ? "0 2px 8px -4px #eee" : "none",
    },
    addResultsBtn: {},
    addIcon: {
      marginRight: s(1),
    },
    titleActions: {
      marginLeft: "auto",
    },
    deleteBtn: {
      marginRight: s(2),
    },
    nodata: {
      textAlign: "center",
      color: p.grey[500],
    },
  })
);

const ListView: React.FC<ListViewProps> = (props) => {
  const {
    results,
    selected,
    onRowSelect,
    onResultSelect,
    onCompareClick,
    openResultForm,
    openDeleteDialog,
    isPathway,
    pathwayRecord,
  } = props;

  const { loading } = useLoadingContext();
  const { canCreate, canDelete } = useSelector(
    zohoSelectors.getBloodTestResultsPermissions
  );

  const [sortField, setSortField] = useState<BloodTestResultField>(
    "sampleCollectedOn"
  );
  const [sortDesc, setSortDesc] = useState(true);

  const onTableHeaderClick = useCallback((f: BloodTestResultField) => {
    setSortField((currentField) => {
      setSortDesc((desc) => {
        if (currentField === f) return !desc;
        return true;
      });
      return f;
    });
  }, []);

  const selectedArray = useMemo(() => Object.keys(selected), [selected]);

  const sortedResults = useMemo(() => {
    const cloned = [...results];

    return cloned.sort((a, b) => {
      if (sortField === "sampleCollectedOn") {
        return sortByCollectedDate(a, b, sortDesc);
      }

      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortField === "name") {
        if (sortDesc) {
          return nameA > nameB ? -1 : 1;
        }
        return nameA > nameB ? 1 : -1;
      }
      return 0;
    });
  }, [results, sortField, sortDesc]);

  const isPathwayAddResultDisabled = useMemo(() => {
    if (!isPathway || !pathwayRecord) return false;
    return !(
      pathwayRecord.lab?.manualResultsFlag &&
      (pathwayRecord.stage === BluePrintStagesEnum.PENDING_MANUAL_RESULTS ||
        pathwayRecord.stage === BluePrintStagesEnum.ORDER_CANCELLED)
    );
  }, [isPathway, pathwayRecord]);

  const classes = useStyle(props);

  return (
    <ViewWrapperBox>
      <Box className={classes.topBar}>
        {canCreate && (
          <Button
            className={classes.addResultsBtn}
            color="primary"
            variant="outlined"
            onClick={openResultForm}
            disabled={isPathwayAddResultDisabled}
          >
            <AddIcon className={classes.addIcon} />
            {isPathway ? " ADD RESULTS FROM LAB" : " ADD RESULTS"}
          </Button>
        )}

        {!isPathway && (
          <Box className={classes.titleActions}>
            {canDelete && (
              <Button
                className={classes.deleteBtn}
                color="primary"
                variant="outlined"
                disabled={selectedArray.length === 0}
                onClick={openDeleteDialog}
              >
                DELETE
              </Button>
            )}
            <Button
              color="primary"
              variant="outlined"
              disabled={selectedArray.length < 2}
              onClick={onCompareClick}
            >
              COMPARE
            </Button>
          </Box>
        )}
      </Box>
      {!isPathway && (
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                {Object.keys(TEST_RESULTS_TABLE_META).map((k) => {
                  return (
                    <ListViewHeaderCell
                      key={k}
                      field={k as BloodTestResultField}
                      sortDesc={sortField === k ? sortDesc : null}
                      onClick={onTableHeaderClick}
                    />
                  );
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !sortedResults.length && (
                <TableRow>
                  <TableCell colSpan={6} className={classes.nodata}>
                    No test result is found!
                  </TableCell>
                </TableRow>
              )}
              {!!sortedResults.length &&
                sortedResults.map((r) => {
                  return (
                    <ListViewItem
                      key={r.id}
                      result={r}
                      onSelect={onRowSelect}
                      checked={!!selected[r.id]}
                      onClick={onResultSelect}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ViewWrapperBox>
  );
};

export default ListView;
