import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Field } from "formik";
import { ZohoDosingType, ZohoMedicineType } from "types";
import { FormikNumberField } from "components/FormikNumberField";
import { useStyles } from "../styles";
import { DosingUnitField } from "../DosingUnitField";
import { DosingTypeField } from "./DosingTypeField";

interface Props {
  name?: string;
  dosingTypes: ZohoDosingType[];
  medicineType: ZohoMedicineType;
  specifyDosingInstructionsFieldValue: boolean;
}

export const DosingFields = ({
  name,
  dosingTypes,
  medicineType,
  specifyDosingInstructionsFieldValue,
}: Props) => {
  const classes = useStyles();

  return (
    <Box mb={2} className={classes.fieldsSection}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={3}>
          <DosingTypeField dosingTypes={dosingTypes} name={name} />
        </Grid>

        {!specifyDosingInstructionsFieldValue &&
        !medicineType.No_Dosage_Amount ? (
          <>
            <Grid item xs={3}>
              <Field
                component={FormikNumberField}
                name={`${name}.dosingAmount`}
                label="Dosing Amount"
                required
                type="integer"
                size="small"
                fullWidth
                min={0.01}
                numberFormatProps={{
                  fixedDecimalScale: false,
                  decimalScale: 2,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DosingUnitField dosingTypes={dosingTypes} name={name} />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};
