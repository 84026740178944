import { Lab } from "@deep-consulting-solutions/bmh-constants";
import React, { useCallback } from "react";

import { TableHeadCell } from "components/TableHeadCell";

import { SortColumn } from "./types";
import { tableCellStyle } from "./styles";

interface Props {
  lab: Lab;
  updateSort: (lab: string | null, column: SortColumn) => any;
  labOrderBy: string | null;
  columnOrderBy: SortColumn;
  order: "asc" | "desc";
}

export const LabHeadCells: React.FC<Props> = ({
  lab,
  updateSort,
  labOrderBy,
  columnOrderBy,
  order,
}) => {
  const handleNameSortClick = useCallback(() => {
    updateSort(lab.key, "name");
  }, [updateSort, lab.key]);

  const handleObsSortClick = useCallback(() => {
    updateSort(lab.key, "obs");
  }, [updateSort, lab.key]);

  return (
    <>
      <TableHeadCell
        header="OBX Code"
        info="Corresponding laboratory OBX code"
        hasSorting
        sortLabelProps={{
          active: labOrderBy === lab.key && columnOrderBy === "name",
          direction:
            labOrderBy === lab.key && columnOrderBy === "name" ? order : "asc",
          onClick: handleNameSortClick,
        }}
        noCapitalize
        style={tableCellStyle}
        align="center"
      />
      <TableHeadCell
        header="Observation ID"
        hasSorting
        sortLabelProps={{
          active: labOrderBy === lab.key && columnOrderBy === "obs",
          direction:
            labOrderBy === lab.key && columnOrderBy === "obs" ? order : "asc",
          onClick: handleObsSortClick,
        }}
        noCapitalize
        style={tableCellStyle}
        align="center"
      />
      <TableHeadCell
        header="Normal Range"
        noCapitalize
        style={tableCellStyle}
        align="center"
      />
      <TableHeadCell
        header="Unit"
        noCapitalize
        style={tableCellStyle}
        align="center"
      />
    </>
  );
};
