import React from "react";
import { useSelector } from "react-redux";

import { zohoSelectors } from "redux/zoho";
import SingleContact from "./SingleContact";
import MultipleContact from "./MultipleContact";

const ContactModule = () => {
  const ids = useSelector(zohoSelectors.getIds);

  if (ids.length === 1) return <SingleContact />;
  return <MultipleContact />;
};

export default ContactModule;
