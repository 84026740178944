import { n8nApiClient, apiClient } from "apis";
import { FinalizeTreatmentResponse, ZohoPrescription } from "types";

export const fetchCreateTreatmentWidgetDetails = async (clientId: string) => {
  const res = await n8nApiClient.get(
    `/create-treatment-plan-widget?zohoID=${clientId}`
  );
  return res.data;
};

export const fetchDoctors = async () => {
  const res = await n8nApiClient.get("/create-treatment-plan-widget/doctors");
  return res.data;
};

export const fetchTreatmentAreas = async () => {
  const res = await apiClient.get("/treatment-areas/crm/medicine-types");
  return res.data.data;
};

export const fetchUserDoctorProfile = async (userId: string) => {
  const res = await n8nApiClient.get(
    `/create-treatment-plan-widget/user?userID=${userId}`
  );
  return res.data;
};

export const fetchDosingAdminRoutes = async () => {
  const res = await n8nApiClient.get(
    "/create-treatment-plan-widget/dosing-admin-routes"
  );
  return res.data;
};

export const fetchDosingTypes = async () => {
  const res = await apiClient.get(
    "/dosing-types/crm/dosing-units-admin-routes"
  );
  return res.data.data;
};

export const fetchMedicineAdminRoutes = async () => {
  const res = await n8nApiClient.get(
    "/create-treatment-plan-widget/medicine-admin-routes"
  );
  return res.data;
};

export const createTreatment = async (payload: any) => {
  const res = await n8nApiClient.post("/create-treatment-plan-widget", payload);
  return res.data.data;
};

export const fetchCompleteTreatmentWidgetDetails = async (
  treatmentId: string
) => {
  const res = await apiClient.get(
    `/functional-use-case/uib/complete-treatment-widget-details/${treatmentId}`
  );
  return res.data.data;
};

export const completeTreatment = async (payload: any) => {
  const res = await n8nApiClient.post(
    "/complete-treatment-plan-widget",
    payload
  );
  return res.data;
};

export const fetchUpdateTreatmentWidgetDetails = async (
  treatmentId: string
) => {
  const res = await apiClient.get(
    `/functional-use-case/uib/update-treatment-widget-details/${treatmentId}`
  );
  return res.data.data;
};

export const fetchUpdateTreatmentWidgetDetailsTreatmentInfo = async (
  treatmentId: string,
  userID: string
) => {
  const res = await n8nApiClient.get(
    `/treatment-info/${treatmentId}?userID=${userID}`
  );
  return res.data.data;
};

export const updateTreatment = async (payload: any) => {
  const res = await apiClient.post("/treatments/update-treatment", payload);
  return res.data.data;
};

export const fetchFinalizeTreatmentWidgetDetails = async (
  treatmentId: string
) => {
  const res = await apiClient.get<FinalizeTreatmentResponse>(
    `/functional-use-case/uib/finalize-treatment-widget-details/${treatmentId}`
  );
  return res.data.data;
};

export const finalizeTreatment = async (values: any) => {
  const res = await n8nApiClient.post<FinalizeTreatmentResponse>(
    `/finalize-treatment`,
    values
  );
  return res.data.data;
};

export const startPharmacyAndMedicineSelection = async (
  treatmentId: string
) => {
  const res = await n8nApiClient.post<FinalizeTreatmentResponse>(
    "/trigger-pharmacy-selection-automation",
    {
      treatmentId,
    }
  );
  return res.data;
};

export const requestChangesForTreatment = async (payload: any) => {
  const res = await n8nApiClient.post("/change-treatment-plan", payload);
  return res.data.data;
};

export const fetchOriginalPrescription = async (id: string) => {
  const res = await n8nApiClient.post<{
    data: { originalPrescription: ZohoPrescription };
  }>(
    `/start-prescription-renewal-widget-original-prescription?prescriptionZohoID=${id}`
  );
  return res.data.data;
};

export const fetchUnfulfilledOrders = async (id: string) => {
  const res = await n8nApiClient.get(
    `/prescription/unfilfilled-orders?prescriptionZohoID=${id}`
  );
  return res.data;
};

export const fetchPrescriptionUrl = async (id: string) => {
  const res = await n8nApiClient.get(
    `/start-prescription-renewal-widget-prescription-url?prescriptionZohoID=${id}`
  );
  return res.data;
};

export const postStartPrescriptionRenewal = async (payload: any) => {
  const res = await n8nApiClient.post("/start-prescription-renewal", payload);
  return res.data.data;
};

export const postCancelTreatmentOrder = async (id: string, payload: any) => {
  const res = await n8nApiClient.post(
    `/treatment-orders/${id}/cancel-order`,
    payload
  );
  return res.data.data;
};
