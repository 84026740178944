import {
  BloodTestResult,
  UnmatchedBloodTestResultStatusEnum,
  UnmatchedHL7,
} from "@deep-consulting-solutions/bmh-constants";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AxiosError } from "axios";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { unmatchedRequests } from "redux/unmatched";
import { fetchPathwayByUnmatchedHl7ZohoID } from "redux/unmatched/requests";
import { zohoSelectors } from "redux/zoho";
import { Comparison } from "../FileDataTable/Comparison";

const useStyle = makeStyles(({ spacing: s }) => ({
  wrapper: {
    width: "100%",
    position: "relative",
  },
  title: {
    padding: s(2, 2, 0, 2),
  },
  warningPadding: {
    padding: s(0, 2),
  },
}));
interface Props {
  unmatchedHL7: UnmatchedHL7;
}
export const ComparisonWidget = ({ unmatchedHL7 }: Props) => {
  const ids = useSelector(zohoSelectors.getIds);
  const [data, setData] = useState<UnmatchedHL7 | null>(unmatchedHL7);
  const [errorMessage, setErrorMessage] = useState("");
  const [newResult, setNewResult] = useState<
    BloodTestResult | null | undefined
  >(null);
  const [oldResult, setOldResult] = useState<
    BloodTestResult | null | undefined
  >(null);
  const [currentResult, setCurrentResult] = useState<
    BloodTestResult | null | undefined
  >(null);
  const [existingResult, setExistingResult] = useState<
    BloodTestResult | null | undefined
  >(null);
  const [loading, setLoading] = useState(false);

  const showWarning = useMemo(() => {
    const status = unmatchedHL7?.status || data?.status;
    return (
      status &&
      ![
        UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT,
        UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT,
        UnmatchedBloodTestResultStatusEnum.DISCARDED,
      ].includes(status)
    );
  }, [data?.status, unmatchedHL7.status]);

  const getComparisonResults = useCallback(async () => {
    try {
      setLoading(true);
      if (!data?.zohoID || showWarning) {
        return;
      }
      const sampleCollectedOn =
        unmatchedHL7.sampleCollectedOn || data.sampleCollectedOn;
      const name = unmatchedHL7.testName || data.testName;
      const res = await fetchPathwayByUnmatchedHl7ZohoID(data.zohoID);
      setNewResult({
        results: res.newResult,
        sampleCollectedOn,
        name,
      } as BloodTestResult);
      setOldResult(res.oldResult);
      setCurrentResult(res.createdResult);
      setExistingResult(res.existingResult);
    } catch (err) {
      const error = err as AxiosError;
      setErrorMessage(error.response?.data.message);
    } finally {
      setLoading(false);
    }
  }, [
    data?.zohoID,
    showWarning,
    unmatchedHL7.sampleCollectedOn,
    unmatchedHL7.testName,
    data?.sampleCollectedOn,
    data?.testName,
  ]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (unmatchedHL7?.zohoID || !ids || !ids[0]) {
      return;
    }
    try {
      setErrorMessage("");
      const res = await unmatchedRequests.fetchUnmatchResult({
        zohoId: ids[0],
      });
      setData(res);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [ids, unmatchedHL7?.zohoID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getComparisonResults();
  }, [getComparisonResults]);

  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <Loader open={loading} />
      <Box>
        <Typography className={classes.title} variant="h4">
          Comparison Widget
        </Typography>
      </Box>

      {showWarning && !unmatchedHL7 && (
        <Box className={classes.warningPadding} mt={2}>
          <Alert severity="info">
            This widget is available only if the stage of the unmatched hl7 file
            is {UnmatchedBloodTestResultStatusEnum.MERGED_WITH_EXISTING_RESULT},{" "}
            {UnmatchedBloodTestResultStatusEnum.REPLACED_EXISTING_RESULT} or{" "}
            {UnmatchedBloodTestResultStatusEnum.DISCARDED}.
          </Alert>
        </Box>
      )}
      {!showWarning && !errorMessage && (
        <Box mt={2}>
          <Comparison
            newResult={newResult}
            oldResult={oldResult}
            currentResult={currentResult}
            existingResult={existingResult}
            isComparisonWidget
            stage={data!.status}
          />
        </Box>
      )}

      {!showWarning && !!errorMessage && (
        <Box className={classes.warningPadding} mt={2}>
          <Alert severity="info">{errorMessage}</Alert>
        </Box>
      )}
    </div>
  );
};
