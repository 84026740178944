import * as Yup from "yup";
import { Column, InitialValues } from "./types";

export const defaultColumns: Column[] = [
  { id: "itemName", label: "Item", minWidth: 120 },
  {
    id: "prescriptionTotalQty",
    label: "Prescription Total Qty",
    minWidth: 70,
  },
  {
    id: "prescriptionTotalDispensedQty",
    label: "Prescription Total Dispensed Qty",
    minWidth: 70,
  },
  {
    id: "remainingQty",
    label: "Remaining Qty including this order",
    minWidth: 70,
  },
  {
    id: "quantityInOrder",
    label: "Quantity in Order",
    minWidth: 70,
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 130,
  },
  {
    id: "usageNotes",
    label: "Usage Notes",
    minWidth: 180,
  },
];

export const defaultColumnsAlt = [
  ...defaultColumns,
  {
    id: "hasBundle" as const,
    label: "Actions",
    minWidth: 120,
  },
];

export const durationUnits = [
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
];

export const needleOptions = [
  {
    value: "23G 1''",
    label: '23G 1"',
  },
  {
    value: "25G 1''",
    label: '25G 1"',
  },
  {
    value: "25G 5/8''",
    label: '25G 5/8"',
  },
  {
    value: "30G 1''",
    label: '30G 1"',
  },
];

export const filterNeedleOptions = [
  {
    value: "18G 1.5''",
    label: '18G 1.5"',
  },
];

export const initValues: InitialValues = {
  legacyTreatment: {
    id: "",
    Name: "",
    Start_Of_Prescription_Date: "",
    Associated_Doctor: null,
    WebUrl: "",
    title: "",
    label: "",
    value: "",
  },
  legacyTreatments: [],
  legacyPrescription: {
    id: "",
    Name: "",
    WebUrl: "",
    prescriptionsLookUp: [],
    zohosign__Date_Completed: "",
    title: "",
    label: "",
    value: "",
  },
  legacyPrescriptions: [],
  formattedOrderItems: [],
  orderDetails: {
    invoice: {
      id: "",
      invoiceNumber: "",
      webUrl: "",
    },
    recurringInvoice: {
      id: "",
      name: "",
      webUrl: "",
    },
    doctor: {
      id: "",
      name: "",
      webUrl: "",
    },
    patient: {
      id: "",
      name: "",
      webUrl: "",
    },
  },
  shippingAddress: {
    street: "",
    city: "",
    provinceRegionState: "",
    postalCode: "",
    country: "",
  },
  everyItemHasPair: false,
  orderItemPrescriptionItemPairs: [],
};

export const orderDetailsValidationSchema = Yup.object().shape({
  legacyTreatment: Yup.object().required("Legacy Treatment is required"),
  legacyPrescription: Yup.object().required("Legacy Prescription is required"),
  formattedOrderItems: Yup.array().of(
    Yup.object().shape({
      hasBundle: Yup.boolean(),
      name: Yup.string().optional(),
      remainingQty: Yup.number(),
      duration: Yup.string().nullable(),
      durationPeriod: Yup.number()
        .nullable()
        .when("duration", {
          is: (val: string | null) => val === null,
          then: Yup.number()
            .required("Required")
            .integer("No decimal")
            .min(1, "Minimum 1"),
          otherwise: Yup.number().notRequired(),
        }),
      durationUnit: Yup.string()
        .nullable()
        .when("duration", {
          is: (val: string | null) => val === null,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }),
      prescriptionTotalQty: Yup.number().nullable().required("Required"),
      prescriptionTotalDispensedQty: Yup.number()
        .nullable()
        .required("Required"),
      usageNotes: Yup.string().required("Notes are required"),
      bundleDetails: Yup.object().shape({
        hasBundle: Yup.boolean(),
        isHCG: Yup.boolean(),
        numberOfDoses: Yup.number().when("hasBundle", {
          is: true,
          then: Yup.number()
            .required("Number of doses is required")
            .integer("Must be a whole number")
            .min(1, "Must be greater than or equal to 1"),
          otherwise: Yup.number().notRequired(),
        }),
        needle: Yup.string()
          .nullable()
          .when("hasBundle", {
            is: true,
            then: Yup.string().required("Needle is required"),
            otherwise: Yup.string().notRequired(),
          }),
        needleFilter: Yup.string()
          .nullable()
          .when("hasBundle", {
            is: true,
            then: Yup.string().required("Needle filter is required"),
            otherwise: Yup.string().notRequired(),
          }),
        extraNeedle: Yup.string()
          .nullable()
          .when(["hasBundle", "isHCG"], {
            is: (hasBundle: boolean, isHCG: boolean) => hasBundle && isHCG,
            then: Yup.string().required("Extra needle is required"),
            otherwise: Yup.string().notRequired(),
          }),
        extraNeedleFilter: Yup.string()
          .nullable()
          .when(["hasBundle", "isHCG"], {
            is: (hasBundle: boolean, isHCG: boolean) => hasBundle && isHCG,
            then: Yup.string().required("Field is required"),
            otherwise: Yup.string().notRequired(),
          }),
      }),
    })
  ),
});
