import { Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import { useStyles } from "widgets/DispenseDrugs";

interface AllUnitsHaveSameBNFieldProps {
  quantity: number;
  fieldName: string;
}

export const AllUnitsHaveSameBNField: React.FC<AllUnitsHaveSameBNFieldProps> = ({
  fieldName,
  quantity,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const dispensedUnits: number = useMemo(() => {
    const allUnits = getValueFromObject(`${fieldName}.variants`, values);
    return allUnits?.length || 0;
  }, [values, fieldName]);

  if (quantity > 1 && dispensedUnits <= 1) {
    return (
      <>
        <Field
          name={`${fieldName}.allUnitSameBatchNumber`}
          disabled={dispensedUnits > 1}
          component={CheckboxWithLabel}
          type="checkbox"
          className={classes.checkbox}
          Label={{
            label: (
              <Typography variant="body2">
                All units have the same (same manufacturer, expiry date and
                batch number)
              </Typography>
            ),
          }}
          color="primary"
        />
      </>
    );
  }

  return null;
};
