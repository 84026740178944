import { Done, PriorityHigh } from "@material-ui/icons";
import { styled } from "@material-ui/core";
import { Palette } from "@material-ui/core/styles/createPalette";

export const ROW_HEIGHT = 50;

export const getBorder = (p: Palette) => `1px solid ${p.grey[300]}`;

export const getBGHover = (p: Palette) => p.grey[100];

export const getCaptionColor = (p: Palette) => p.grey[500];

export const DoneIcon = styled(Done)(({ theme }) => ({
  fontSize: "50px",
  color: theme.palette.success.main,
}));

export const WarningIcon = styled(PriorityHigh)(({ theme }) => ({
  fontSize: "50px",
  color: theme.palette.error.main,
}));
