import * as Yup from "yup";
import { BillingData, PartialTreatmentOrderValues } from "./types";

// Define the validation schema for a single treatmentOrder
const treatmentOrderSchema = Yup.object().shape({
  Order_Date: Yup.string()
    .required("Order Date is required")
    .test(
      "is-valid-date",
      "Order Date is invalid",
      (value) => value !== "Invalid date"
    ),
  Late_Dispensing_Date: Yup.string()
    .when("lateDispensing", {
      is: true,
      then: Yup.string().required(
        "Late Dispensing Date is required when lateDispensing is true"
      ),
    })
    .test(
      "is-valid-date",
      "Late Dispensing Date is invalid",
      (value) => value !== "Invalid date"
    ),
});

const billingDataSchema = Yup.object().shape({
  treatmentOrders: Yup.array().of(treatmentOrderSchema),
});

export const validationSchema = Yup.object().shape({
  billingData: Yup.array().of(billingDataSchema),
});

export function formatDate(dateString: string) {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}

// This function helps in tracking changes and also flags if the Order_Date
//  and Late_Dispensing_Date specifically has been updated.
export function getChangedOrders(
  originalState: PartialTreatmentOrderValues[],
  finalState: PartialTreatmentOrderValues[]
): (PartialTreatmentOrderValues & {
  Order_Date_Updated: boolean;
  Late_Dispensing_Date_Updated: boolean;
})[] {
  return finalState
    .filter((finalOrder) => {
      const originalOrder = originalState.find(
        (order) => order.id === finalOrder.id
      );
      if (!originalOrder) return false;

      return (
        originalOrder.Urgent !== finalOrder.Urgent ||
        originalOrder.Late_Dispensing_Date !==
          finalOrder.Late_Dispensing_Date ||
        originalOrder.Order_Date !== finalOrder.Order_Date
      );
    })
    .map((finalOrder) => {
      const originalOrder = originalState.find(
        (order) => order.id === finalOrder.id
      );
      return {
        ...finalOrder,
        Order_Date_Updated: originalOrder?.Order_Date !== finalOrder.Order_Date,
        Late_Dispensing_Date_Updated:
          originalOrder?.Late_Dispensing_Date !==
          finalOrder.Late_Dispensing_Date,
      };
    });
}

export function extractTreatmentOrders(
  billingData: BillingData[]
): PartialTreatmentOrderValues[] {
  return billingData.reduce<PartialTreatmentOrderValues[]>(
    (acc, billingItem) => {
      const filteredOrders = billingItem.treatmentOrders.map((order) => ({
        id: order.id,
        State: order.State,
        Urgent: order.Urgent,
        Late_Dispensing_Date: order.Late_Dispensing_Date,
        Order_Date: order.Order_Date,
        Pharmacy: order?.Pharmacy?.id ? { id: order.Pharmacy.id } : null,
      }));

      return [...acc, ...filteredOrders];
    },
    []
  );
}

export function pluralToSingular(word: string) {
  if (word.endsWith("ies")) {
    // Handles cases like "Cities" to "City"
    return `${word.slice(0, -3)}y`;
  }
  if (word.endsWith("es")) {
    // Handles cases like "Wishes" to "Wish"
    return word.slice(0, -2);
  }
  if (word.endsWith("s") && !word.endsWith("ss")) {
    // Handles regular plurals like "Days" to "Day"
    return word.slice(0, -1);
  }
  // If the word doesn't match any known plural form, return the original word
  return word;
}
