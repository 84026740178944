import {
  RecordAccessLogsWidget,
  init,
} from "@deep-consulting-solutions/records-access-logs";
import { Box, makeStyles } from "@material-ui/core";
import {
  apiClient,
  silentClient,
  removeAuthorizationHeader,
  setAuthorizationHeader,
} from "apis";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { zohoServices } from "services";

const useStyles = makeStyles(() => {
  return {
    containerWrapper: {
      background: "white",
    },
    wrapper: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      background: "white",
      flex: 1,
    },
    container: {
      flex: 1,
    },
  };
});

export const RecordAccessLogs = () => {
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState<any[]>([]);
  const [users, setUsers] = useState([]);
  const entity = useSelector(zohoSelectors.getEntity);
  const [recordId] = useSelector(zohoSelectors.getIds);
  const records = useSelector(zohoSelectors.getRecords);

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      const [modulesRes, usersRes] = await Promise.all([
        zohoServices.getModules(),
        zohoServices.getUsers(),
      ]);
      setUsers(usersRes.users || []);
      setModules(modulesRes.modules || []);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init({
      appName: "BMH",
      apiClient,
      silentClient,
      setAuthorizationHeader,
      removeAuthorizationHeader,
    });
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  const classes = useStyles();

  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.wrapper} p={2} pt={0} pb={0}>
        <Loader open={loading} />
        {!loading ? (
          <Box className={classes.container}>
            <RecordAccessLogsWidget
              entity={entity}
              users={users}
              modules={modules}
              crmRecordId={recordId}
              recordName={(records[recordId] as any)?.Name}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
