import React, { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { getFieldName } from "helpers";
import { CountryAutoCompleteField } from "./CountryAutoCompleteField";

interface Props {
  country?: string | "";
  countries: string[];
  name?: string;
}

export const CountryField = ({ countries, country, name }: Props) => {
  const { setFieldValue } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("country", name), [name]);

  useEffect(() => {
    if (country) {
      setFieldValue(fieldName, { title: country, value: country });
    }
  }, [country, fieldName, setFieldValue]);

  return <CountryAutoCompleteField countries={countries} />;
};
