import { Chip, TextField, TextFieldProps } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  createFilterOptions,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { getValueFromObject, isStringEqual, sortAlphabetically } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption } from "types";

interface FieldOptionType extends AutoCompleteOption {
  inputValue?: string;
}

const filter = createFilterOptions<FieldOptionType>();

interface Props {
  name: string;
  size?: TextFieldProps["size"];
  helperText?: TextFieldProps["helperText"];
  label: TextFieldProps["label"];
  variant?: TextFieldProps["variant"];
  color?: "primary" | "secondary";
  otherOptionValue?: string;
  mapValues?: (v: AutoCompleteOption[]) => Record<string, any>[];
  options: AutoCompleteOption[];
  required?: boolean;
}

export const AutocompleteMultipleFreeSoloField = ({
  name: fieldName,
  size = "small",
  helperText = "Select from the list or enter new items.",
  label,
  variant = "outlined",
  color = "primary",
  otherOptionValue,
  mapValues,
  options,
  required = true,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldTouched = useMemo(
    () => getValueFromObject(fieldName, touched) === true,
    [fieldName, touched]
  );

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const fieldValue: AutoCompleteOption[] = useMemo(
    () => getValueFromObject(fieldName, values),
    [fieldName, values]
  );

  const helperTextIsError = useMemo(() => {
    return fieldTouched && typeof fieldError === "string" && !!fieldError;
  }, [fieldTouched, fieldError]);

  const helperTextInfo = useMemo(() => {
    if (fieldTouched && typeof fieldError === "string" && fieldError) {
      return fieldError;
    }
    return helperText || undefined;
  }, [fieldTouched, fieldError, helperText]);

  const sortedOptions = useMemo(() => {
    return sortAlphabetically(options);
  }, [options]);

  const handleChange = useCallback(
    (value: AutoCompleteOption[] | null, reason: AutocompleteChangeReason) => {
      if (value) {
        const mappedValues = mapValues ? mapValues(value) : value;
        if (reason === "select-option") {
          setFieldValue(fieldName, mappedValues);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, mappedValues);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, mappedValues);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, []);
      }
    },
    [fieldName, setFieldValue, mapValues]
  );

  const onChange = useCallback(
    (
      _: any,
      value: (FieldOptionType | string)[],
      reason: AutocompleteChangeReason
    ) => {
      const mappedValues = value?.reduce<AutoCompleteOption[]>((total, v) => {
        if (typeof v === "string") {
          if (!v) {
            return total;
          }
          const isExist = value?.some((i) =>
            typeof i === "string" ? false : isStringEqual(i.title, v)
          );
          if (isExist) {
            return total;
          }
          return total.concat({
            title: v,
            value: otherOptionValue || v,
          });
        }
        if (v && v.inputValue) {
          const isExist = value?.some((i) =>
            typeof i === "string" ? false : isStringEqual(i.title, v.inputValue)
          );
          if (isExist) {
            return total;
          }
          return total.concat({
            title: v.inputValue,
            value: otherOptionValue || v.inputValue,
          });
        }
        if (!v.title) {
          return total;
        }
        return total.concat(v);
      }, []);
      handleChange(mappedValues || null, reason);
    },
    [handleChange, otherOptionValue]
  );

  return (
    <Field
      required={required}
      name={fieldName}
      multiple
      freeSolo
      component={Autocomplete}
      options={sortedOptions}
      getOptionLabel={(option?: FieldOptionType | string) => {
        if (typeof option === "string") {
          return option;
        }
        if (option?.inputValue) {
          return option?.inputValue || "";
        }
        return option?.title || "";
      }}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      color={color}
      selectOnFocus
      clearOnBlur
      renderTags={(value: any[], getTagProps: (param: any) => any) =>
        value.map((option, index) => (
          <Chip
            color={color}
            label={typeof option === "string" ? option : option.title}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(option: any) => option.title}
      getOptionSelected={(
        option: AutoCompleteOption,
        value: AutoCompleteOption
      ) => {
        return option.value === value.value && option.title === value.title;
      }}
      filterOptions={(fieldOptions: FieldOptionType[], params: any) => {
        const filtered = filter(fieldOptions, params);
        if (params.inputValue !== "") {
          const isExist = fieldValue?.some((i) =>
            isStringEqual(i.title, params.inputValue)
          );
          if (!isExist) {
            filtered.push({
              inputValue: params.inputValue,
              value: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
        }
        return filtered;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required={required}
          name={fieldName}
          variant={variant}
          label={label}
          helperText={helperTextInfo}
          error={helperTextIsError}
          size={size}
        />
      )}
    />
  );
};
