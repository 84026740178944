import React, { memo } from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { NotInterested, WarningRounded } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "900px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  info: {
    color: "#F44336",
    fontWeight: "bold",
  },
  icon: {
    color: "#F44336",
    fontWeight: "bold",
    fontSize: "80px",
    marginBottom: "30px",
  },
  content: {
    paddingLeft: "120px",
    paddingRight: "120px",
    marginTop: "20px",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontSize: "18px",
  },
  button: {
    textTransform: "initial",
    color: "error",
  },
}));

interface Props {
  access?: boolean;
  handleRiskAcknowledged: () => void;
}

const TechnicalConfigRestrictionInfo = ({
  access = false,
  handleRiskAcknowledged,
}: Props) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {access ? (
        <>
          <WarningRounded className={classes.icon} />
          <Typography className={classes.info} variant="h3">
            ENTERING DANGER ZONE
          </Typography>
          <div className={classes.content}>
            <p className={classes.text}>
              The interface you are attempting to access is used to control
              highly sensitive and intricate back end components of the system.
            </p>
            <p className={classes.text}>
              Any mistakes made in the interface may lead to serious damage to
              the system, major data losses, and other disruptions to the system
              and business operation.
            </p>
            <p className={classes.text}>
              Only proceed if you are confident in what you are doing.
            </p>
          </div>
          <Button
            onClick={handleRiskAcknowledged}
            className={classes.button}
            variant="outlined"
          >
            I understand the risks. Continue.
          </Button>
        </>
      ) : (
        <>
          <NotInterested className={classes.icon} />
          <Typography className={classes.info} variant="h3">
            ACCESS DENIED
          </Typography>
          <div className={classes.content}>
            <p className={classes.text}>
              The interface you are attempting to access is used to control
              highly sensitive and intricate back end components of the system.
            </p>
            <p className={classes.text}>
              Only competent technical admins are permitted to access this
              interface, as any mistakes made in the interface may lead to
              serious damage to the system, major data losses, and other
              disruptions to the system and business operation. Your user is not
              designated as a technical admin and you may not access this
              interface.
            </p>
            <p className={classes.text}>
              If you believe that you should have access to this, please contact
              the technical admin.
            </p>
          </div>
        </>
      )}
    </Container>
  );
};

export default memo(TechnicalConfigRestrictionInfo);
