import { Typography } from "@material-ui/core";
import { getFieldName } from "helpers";
import React, { useMemo } from "react";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { useStyles } from "../MedicineForm/styles";

interface Props {
  name?: string;
}
export const OnlyOnceField = ({ name }: Props) => {
  const classes = useStyles();
  const fieldName = useMemo(() => getFieldName("onlyOnce", name), [name]);

  return (
    <Field
      name={fieldName}
      component={CheckboxWithLabel}
      type="checkbox"
      className={classes.checkbox}
      Label={{
        label: <Typography>Only Once</Typography>,
      }}
      color="primary"
    />
  );
};
