import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { MigrateTreatmentData, ZohoInvoice } from "types";
import { TreatmentDetails } from "./TreatmentDetails";
import { PatientPrescriptions } from "./PatientPrescriptions";
import { PatientPendingPaymentPharmInvoices } from "./PatientPendingPaymentPharmInvoices";
import { PatientCttps } from "./PatientCttps";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  tabItem: {
    textTransform: "none",
    maxWidth: "13.75rem",
    fontWeight: 600,
    fontSize: "0.8125rem",
  },
  tabContainer: {
    marginBottom: s(2),
    borderBottom: `1px solid ${p.grey[300]}`,
    backgroundColor: p.grey[100],
  },
  container: {
    marginBottom: s(5),
  },
}));

interface DataTabsProps {
  details: MigrateTreatmentData;
  invoices: ZohoInvoice[];
}

export const DataTabs: React.FC<DataTabsProps> = ({ details, invoices }) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const patientDetails = useMemo(
    () => ({
      patientName: details?.treatment.Contact.Full_Name,
      doctorName: details?.treatment.Associated_Doctor.name,
      repeats: details?.treatment.Repeats,
      patientRecordCrmLink: details?.treatment.Contact.recordCrmLink,
      patientRecordBooksLink: details?.treatment.Contact.recordBooksLink,
    }),
    [details]
  );

  const rxItems = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => {
        const rxNameKey = `RX_Item_${i + 1}`;
        const rxQtyKey = `RX_Qty_${i + 1}`;
        const rxDosingKey = `RX_${i + 1}_Dosing`;

        if (details?.treatment && details?.treatment[rxNameKey]) {
          return {
            id: i,
            item: details?.treatment[rxNameKey] as string,
            quantity: details?.treatment[rxQtyKey] as string,
            dosing: details?.treatment[rxDosingKey] as string,
            name: details?.treatment[rxNameKey] as string,
          };
        }
        return null;
      }).filter(Boolean),
    [details]
  );

  const prescriptions = useMemo(
    () =>
      details?.prescriptions.map((prescription) => ({
        id: prescription.id,
        prescription: prescription.Name,
        signingDate: prescription.Signing_Date,
        state: prescription.State,
        legacyPrescription: prescription["Legacy_Prescription.Name"],
        legacyRecordCrmLink: prescription.legacyPrescriptionRecordLink,
        recordCrmLink: prescription.recordCrmLink,
      })),
    [details]
  );

  const legacyPrescriptions = useMemo(
    () =>
      details?.signedLegacyPrescription.map((legacyPrescription) => ({
        id: legacyPrescription.id,
        Name: legacyPrescription.Name,
        dateSent: legacyPrescription.zohosign__Date_Completed,
        dateCompleted: legacyPrescription.zohosign__Date_Sent,
        recordCrmLink: legacyPrescription.recordCrmLink,
      })),
    [details]
  );

  const legacyCttps = useMemo(
    () =>
      details?.signedLegacyCTTP.map((cttp) => ({
        id: cttp.id,
        Name: cttp.Name,
        dateSent: cttp.zohosign__Date_Sent,
        dateCompleted: cttp.zohosign__Date_Completed,
        recordCrmLink: cttp.recordCrmLink,
      })),
    [details]
  );

  return (
    <Box className={classes.container}>
      <Tabs
        className={classes.tabContainer}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Treatment Details Tabs"
      >
        {[
          "Treatment Details",
          "Patient Pending Payment Pharmacy Invoices",
          "Patient Prescriptions",
          "Patient CTTPs",
        ].map((label, index) => (
          <Tab
            key={label}
            className={classes.tabItem}
            label={label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <TabPanel value={value} index={0}>
        <TreatmentDetails
          patientDetails={patientDetails}
          rxItems={rxItems}
          injectionSupplies={details?.treatment.Injection_Supplies}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PatientPendingPaymentPharmInvoices invoices={invoices} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PatientPrescriptions
          lastDoctorConsultation={details?.clientLatestMeeting?.Start_DateTime}
          prescriptions={prescriptions}
          signedLegacyPrescriptions={legacyPrescriptions}
          treatmentStartOfPrescriptionDate={details?.treatment.Date}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PatientCttps signedLegacyCttps={legacyCttps} />
      </TabPanel>
    </Box>
  );
};
