/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ZohoEntity, ZohoClientRecord, ZohoRecord, ZohoCUDReturn } from "types";

declare const ZOHO: any;

const init = () => {
  return new Promise<ZohoEntity>((resolve) => {
    ZOHO.embeddedApp.on("PageLoad", (data: ZohoEntity) => {
      resolve(data);
    });
    ZOHO.embeddedApp.init();
  });
};

const signatureForServerAPIWithUserID = async (): Promise<string | null> => {
  try {
    const result = await ZOHO.CRM.FUNCTIONS.execute(
      "signatureForServerAPIWithUserID",
      {}
    );
    if (result.code !== "success") {
      return null;
    }
    return result.details.output as string;
  } catch (error) {
    return null;
  }
};

// min-width is always 700px and can't be changed.
const resizeWidget = async ({
  height,
  width = 700,
}: {
  height: number;
  width?: number;
}) => {
  await ZOHO.CRM.UI.Resize({ height, width });
};

const getZohoRecords = async (Entity: string, RecordID: string | string[]) => {
  const res = await ZOHO.CRM.API.getRecord({
    Entity,
    RecordID,
  });
  return res.data as ZohoRecord[];
};

const getRecord = async <T = Record<string, any>>(args: {
  Entity: string;
  RecordID: string;
}) => {
  const res = await ZOHO.CRM.API.getRecord(args);
  return res.data[0] as T;
};

const getRelatedRecords = async <T = Record<string, any>>(args: {
  Entity: string;
  RecordID: string;
  RelatedList: string;
}): Promise<T[] | undefined> => {
  const res = await ZOHO.CRM.API.getRelatedRecords(args);
  return res.data;
};

const deleteRelatedRecord = async (args: {
  Entity: string;
  RecordID: string;
  RelatedList: string;
  RelatedRecordID: string;
}): Promise<ZohoCUDReturn> => {
  const res = await ZOHO.CRM.API.delinkRelatedRecord(args);
  return res.data[0];
};
const createRecord = async <T = Record<string, any>>({
  Entity,
  Trigger,
  data,
}: {
  Entity: string;
  Trigger?: "workflow" | "approval" | "blueprint"[];
  data: Partial<T>;
}): Promise<ZohoCUDReturn> => {
  const config: {
    Entity: string;
    APIData: Partial<T>;
    Trigger?: "workflow" | "approval" | "blueprint"[];
  } = {
    Entity,
    APIData: data,
  };
  if (Trigger) {
    config.Trigger = Trigger;
  }
  const res = await ZOHO.CRM.API.insertRecord(config);
  return res.data[0];
};

const updateRecord = async <T = Record<string, any>>({
  Entity,
  id,
  Trigger,
  data,
}: {
  Entity: string;
  id: string;
  Trigger?: "workflow" | "approval" | "blueprint"[];
  data: Partial<T>;
}): Promise<ZohoCUDReturn> => {
  const config: {
    Entity: string;
    APIData: Partial<T>;
    Trigger?: "workflow" | "approval" | "blueprint"[];
  } = {
    Entity,
    APIData: {
      id,
      ...data,
    },
  };
  if (Trigger) {
    config.Trigger = Trigger;
  }
  const res = await ZOHO.CRM.API.updateRecord(config);
  return res.data[0];
};

const deleteRecord = async ({
  Entity,
  id,
}: {
  Entity: string;
  id: string;
}): Promise<ZohoCUDReturn> => {
  const config: {
    Entity: string;
    RecordID: string;
  } = {
    Entity,
    RecordID: id,
  };

  const res = await ZOHO.CRM.API.deleteRecord(config);
  return res.data[0];
};

const getClientRecords = async ({ ids }: { ids: string[] }) => {
  const res = await ZOHO.CRM.API.getRecord({
    Entity: "Contacts",
    RecordID: ids,
  });
  return res.data as ZohoClientRecord[];
};

const closePopup = async (reload?: boolean) => {
  if (reload) {
    await ZOHO.CRM.UI.Popup.closeReload();
  } else {
    await ZOHO.CRM.UI.Popup.close();
  }
};

const getCurrentUser = async () => {
  const data = await ZOHO.CRM.CONFIG.getCurrentUser();
  return data.users[0];
};

const updateBlueprint = async ({
  Entity,
  RecordID,
  transitionName,
  nextFieldValue,
}: {
  Entity: string;
  RecordID: string;
  transitionName: string;
  nextFieldValue?: string;
}) => {
  try {
    const bp = await ZOHO.CRM.API.getBluePrint({
      Entity,
      RecordID,
    });

    const transition = bp.blueprint.transitions.find((tran: any) => {
      return (
        tran.name === transitionName &&
        (nextFieldValue ? tran.next_field_value === nextFieldValue : true)
      );
    });

    if (!transition) return false;
    await ZOHO.CRM.API.updateBluePrint({
      Entity,
      RecordID,
      BlueprintData: {
        blueprint: [
          {
            transition_id: transition.id,
          },
        ],
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const getVariables = async (
  variableNames: string[]
): Promise<Record<string, { value: string }>> => {
  try {
    const res: {
      Error: string;
      Success: { Content: Record<string, { value: string }> };
    } = await ZOHO.CRM.API.getOrgVariable(variableNames.join(","));

    return res.Success.Content;
  } catch (error) {
    return {};
  }
};

const getUsers = async (page = 1, per_page = 200) => {
  const data = await ZOHO.CRM.API.getAllUsers({
    Type: "AllUsers",
    page,
    per_page,
  });
  return data;
};

const getModules = async () => {
  const data = await ZOHO.CRM.META.getModules();
  return data;
};

const getFields = async (Entity: string) => {
  const data = await ZOHO.CRM.META.getFields({
    Entity,
  });
  return data;
};

export default {
  init,
  signatureForServerAPIWithUserID,
  resizeWidget,
  getClientRecords,
  closePopup,
  getCurrentUser,
  getZohoRecords,
  getRecord,
  updateBlueprint,
  getRelatedRecords,
  deleteRelatedRecord,
  updateRecord,
  createRecord,
  deleteRecord,
  getVariables,
  getModules,
  getUsers,
  getFields,
};
