import { makeStyles, TableCell, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  BLOOD_TEST_RESULTS_TABLE_META,
  BloodtestResultsTableKeys,
} from "./helpers";

const useStyle = makeStyles(() => ({
  header: {},
}));

interface Props {
  field: BloodtestResultsTableKeys;
}

export const ConfirmKitPackingTableHeadCell = ({ field }: Props) => {
  const label = useMemo(
    () => BLOOD_TEST_RESULTS_TABLE_META[field]?.label || "",
    [field]
  );

  const classes = useStyle();

  return (
    <TableCell>
      <Typography variant="body2" align="center" className={classes.header}>
        {label}
      </Typography>
    </TableCell>
  );
};
