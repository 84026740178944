import moment from "moment";
import { ZohoClientRecord } from "types";
import { Meeting } from "./types";
import { checkupType } from "./constants";

export function findLastPcmMeeting(meetings: Meeting[]): Meeting | null {
  const today = new Date();

  // Sort meetings by End_DateTime
  const sortedMeetings = meetings.sort(
    (a, b) =>
      new Date(a.End_DateTime).getTime() - new Date(b.End_DateTime).getTime()
  );

  // Find the last "MCM call" meeting before today
  const lastPcmMeeting = sortedMeetings.find(
    (meeting) =>
      new Date(meeting.End_DateTime) < today &&
      meeting.Meeting_type === "MCM call"
  );

  return lastPcmMeeting || null;
}

export function isNotificationDateInThePast(client: ZohoClientRecord): boolean {
  const today = new Date();
  const notificationDate = new Date(client.Notification_Date_for_Next_Consult);
  return notificationDate < today;
}

export function getNextCheckupDate(
  currentCheckupDate: string,
  currentCheckupType: string
): string {
  const date = moment(currentCheckupDate, "YYYY-MM-DD");
  if (currentCheckupType === checkupType.threeWeeks) {
    return date.add(3, "weeks").format("YYYY-MM-DD");
  }
  if (currentCheckupType === checkupType.sixWeeks) {
    return date.add(14, "weeks").format("YYYY-MM-DD");
  }
  if (currentCheckupType === checkupType.sixMonths) {
    return date.add(6, "months").format("YYYY-MM-DD");
  }
  if (currentCheckupType === checkupType.twelveMonths) {
    return date.add(12, "months").format("YYYY-MM-DD");
  }

  return date.add(3, "weeks").format("YYYY-MM-DD");
}

export function getNextCheckupType(currentCheckupType: string): string {
  if (currentCheckupType === checkupType.threeWeeks) {
    return checkupType.sixWeeks;
  }
  if (currentCheckupType === checkupType.sixWeeks) {
    return checkupType.sixMonths;
  }
  if (currentCheckupType === checkupType.sixMonths) {
    return checkupType.twelveMonths;
  }
  if (currentCheckupType === checkupType.twelveMonths) {
    return checkupType.twelveMonths;
  }

  return checkupType.threeWeeks;
}
