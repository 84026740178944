/* eslint-disable func-names */
import React from "react";
import {
  TextFieldProps as MuiTextFieldProps,
  TextField,
} from "@material-ui/core";
import { TextFieldProps } from "formik-material-ui";
import { getIn } from "formik";

// eslint-disable-next-line react/require-default-props
type FormikLowerCasedFieldProps = TextFieldProps & { error?: boolean };

function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  error,
  helperText,
  ...props
}: FormikLowerCasedFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const caretStart = e.target.selectionStart;
    const caretEnd = e.target.selectionEnd;
    e.target.value = e.target.value.toLowerCase();
    fieldOnChange(e);
    e.target.setSelectionRange(caretStart!, caretEnd!);
  };

  return {
    variant: props.variant,
    error: showError || !!error,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    onChange,
    ...field,
    ...props,
  };
}

export const FormikLowerCasedField = ({
  children,
  ...props
}: FormikLowerCasedFieldProps) => {
  return <TextField {...fieldToTextField(props)}>{children}</TextField>;
};
