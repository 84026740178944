import { Box, Typography, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { getFieldName, getValueFromObject, isStringEqual } from "helpers";
import React, { useMemo } from "react";
import {
  AutoCompleteOption,
  ZohoDosingType,
  ZohoTreatmentArea,
  ZohoDosingAdminRoute,
  ZohoMedicineAdminRoute,
} from "types";
import { MedicineTypesField } from "../MedicineTypesField";
import { MedicinesFields } from "../MedicinesFields";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
}));

interface Props {
  name?: string;
  treatmentAreas: ZohoTreatmentArea[];
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}
export const TreatmentTreatmentAreasMedicines = ({
  name,
  treatmentAreas,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const fieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const treatmentAreasById = useMemo(() => {
    return treatmentAreas.reduce<Record<string, ZohoTreatmentArea>>(
      (total, current) => {
        return { ...total, [current.id]: current };
      },
      {}
    );
  }, [treatmentAreas]);

  const treatmentAreasValues: AutoCompleteOption[] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  return (
    <Box mb={2}>
      {treatmentAreasValues.map((treatmentAreaValues, index) => (
        <Box
          className={classes.wrapper}
          mb={index === treatmentAreasValues.length - 1 ? 0 : 2}
          key={`${treatmentAreaValues.value}-${treatmentAreaValues.title}`}
        >
          <Box mb={2}>
            <Typography className={classes.text}>
              {treatmentAreaValues.title}{" "}
              {isStringEqual(
                treatmentAreasById[treatmentAreaValues.value].Name,
                "other"
              )
                ? ""
                : "Medication"}
            </Typography>
          </Box>
          <Box mb={2}>
            <MedicineTypesField
              name={`${fieldName}.${index}`}
              treatmentArea={treatmentAreasById[treatmentAreaValues.value]}
            />
          </Box>
          <MedicinesFields
            name={`${fieldName}.${index}`}
            treatmentArea={treatmentAreasById[treatmentAreaValues.value]}
            dosingTypes={dosingTypes}
            dosingAdminRoutes={dosingAdminRoutes}
            medicineAdminRoutes={medicineAdminRoutes}
          />
        </Box>
      ))}
    </Box>
  );
};
