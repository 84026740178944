import { Grid, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import { TitleValue } from "components/TitleValue";
import { FormikNumberField } from "components/FormikNumberField";
import { CustomUnitPriceField } from "components/CustomUnitPriceField";

interface Props {
  name?: string;
  unitPrice?: number;
  medUsageUnitName?: string;
  quantity?: number | null;
  quantityMaxWidth?: number;
  unitsPerSellableUnit?: number;
}
export const QuantityAndPricingFields = ({
  name,
  unitPrice,
  medUsageUnitName,
  quantity,
  quantityMaxWidth = 180,
  unitsPerSellableUnit,
}: Props) => {
  const { values } = useFormikContext();
  const quantityFieldName = useMemo(() => getFieldName("quantity", name), [
    name,
  ]);
  const customUnitPriceFieldName = useMemo(
    () => getFieldName("customUnitPrice", name),
    [name]
  );

  const quantityFieldValue = useMemo(
    () => quantity || getValueFromObject(quantityFieldName, values),
    [values, quantityFieldName, quantity]
  );

  const customUnitPriceFieldValue = useMemo(
    () => getValueFromObject(customUnitPriceFieldName, values),
    [values, customUnitPriceFieldName]
  );

  const unitPriceIsInvalid = useMemo(() => {
    return unitPrice === null || unitPrice === undefined;
  }, [unitPrice]);

  const totalPrice = useMemo(() => {
    if (unitPriceIsInvalid) {
      return undefined;
    }
    if (quantityFieldValue) {
      if (customUnitPriceFieldValue) {
        return Number(customUnitPriceFieldValue * quantityFieldValue).toFixed(
          2
        );
      }
      return Number(unitPrice! * quantityFieldValue).toFixed(2);
    }
    return Number(0).toFixed(2);
  }, [
    quantityFieldValue,
    customUnitPriceFieldValue,
    unitPrice,
    unitPriceIsInvalid,
  ]);

  return (
    <>
      {quantity ? null : (
        <Grid item>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid
              item
              style={{
                maxWidth: quantityMaxWidth,
              }}
            >
              <Field
                component={FormikNumberField}
                name={quantityFieldName}
                label="Quantity"
                type="integer"
                size="small"
                fullWidth
                required
                min={1}
                numberFormatProps={{
                  fixedDecimalScale: false,
                  decimalScale: 0,
                }}
              />
            </Grid>
            {medUsageUnitName && unitsPerSellableUnit ? (
              <Grid item>
                <Typography
                  variant="caption"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  x {unitsPerSellableUnit} {medUsageUnitName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}

      <Grid item>
        <TitleValue
          title="Unit Price (£):"
          value={unitPriceIsInvalid ? undefined : Number(unitPrice).toFixed(2)}
        />
      </Grid>
      <Grid item>
        <CustomUnitPriceField name={customUnitPriceFieldName} />
      </Grid>
      <Grid item>
        <TitleValue title="Total Price (£)" value={totalPrice} />
      </Grid>
    </>
  );
};
