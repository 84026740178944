import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Alert } from "@material-ui/lab";
import { Info } from "@material-ui/icons";
import { ZohoVariables } from "../types";

const useStyles = makeStyles(() => ({
  alert: {
    fontWeight: 500,
    "& .MuiAlert-icon": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
}));

interface Props {
  clientShippingCountry: string;
  variables: Record<ZohoVariables, string>;
}

export const Instructions = ({ clientShippingCountry, variables }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Alert
        severity="info"
        icon={<Info fontSize="inherit" />}
        className={classes.alert}
      >
        <Typography>
          {clientShippingCountry === "United Kingdom" ? (
            <>
              {variables[ZohoVariables.BD_PATIENT_SOURCED_UK]}
              <br />
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={variables[ZohoVariables.BD_UK_MAP]}
              >
                {variables[ZohoVariables.BD_UK_MAP]}
              </a>
            </>
          ) : (
            variables[ZohoVariables.BD_PATIENT_SOURCED]
          )}
        </Typography>
      </Alert>
    </>
  );
};
