import { useRef, useCallback } from "react";

import { zohoServices } from "services";

export type ResizeFn = (args?: {
  addHeight?: number;
  withDelay?: number;
}) => void;

export const useWidgetResize = <T extends HTMLElement>() => {
  const wrapperRef = useRef<T | null>(null);

  const resize: ResizeFn = useCallback((args) => {
    setTimeout(
      () => {
        const height = wrapperRef.current?.offsetHeight ?? 200;

        // height: height of Content
        // 40: vertical padding
        // addHeight: additional vertical padding, depending on component (for example: to display form errors...)
        zohoServices.resizeWidget({
          height: height + 40 + (args?.addHeight ?? 0),
        });
      },
      args && args.withDelay ? args.withDelay : 0
    );
  }, []);

  return { wrapperRef, resize };
};
