export const UK_LAT = 55.3781;
export const UK_LNG = 3.436;

export const COUNTRY_TO_CODE: Record<string, string> = {
  andorra: "AD",
  "united arab emirates": "AE",
  afghanistan: "AF",
  "antigua and barbuda": "AG",
  anguilla: "AI",
  albania: "AL",
  armenia: "AM",
  angola: "AO",
  antarctica: "AQ",
  argentina: "AR",
  "american samoa": "AS",
  austria: "AT",
  australia: "AU",
  aruba: "AW",
  "alland islands": "AX",
  azerbaijan: "AZ",
  "bosnia and herzegovina": "BA",
  barbados: "BB",
  bangladesh: "BD",
  belgium: "BE",
  "burkina faso": "BF",
  bulgaria: "BG",
  bahrain: "BH",
  burundi: "BI",
  benin: "BJ",
  "saint barthelemy": "BL",
  bermuda: "BM",
  "brunei darussalam": "BN",
  bolivia: "BO",
  brazil: "BR",
  bahamas: "BS",
  bhutan: "BT",
  "bouvet island": "BV",
  botswana: "BW",
  belarus: "BY",
  belize: "BZ",
  canada: "CA",
  "cocos (keeling) islands": "CC",
  congo: "CD",
  "central african republic": "CF",
  "congo, republic of the": "CG",
  switzerland: "CH",
  "cote d'ivoire": "CI",
  "cook islands": "CK",
  chile: "CL",
  cameroon: "CM",
  china: "CN",
  colombia: "CO",
  "costa rica": "CR",
  cuba: "CU",
  "cape verde": "CV",
  curacao: "CW",
  "christmas island": "CX",
  cyprus: "CY",
  "czech republic": "CZ",
  germany: "DE",
  djibouti: "DJ",
  denmark: "DK",
  dominica: "DM",
  "dominican republic": "DO",
  algeria: "DZ",
  ecuador: "EC",
  estonia: "EE",
  egypt: "EG",
  "western sahara": "EH",
  eritrea: "ER",
  spain: "ES",
  ethiopia: "ET",
  finland: "FI",
  fiji: "FJ",
  "falkland islands (malvinas)": "FK",
  "micronesia, federated states of": "FM",
  "faroe islands": "FO",
  france: "FR",
  gabon: "GA",
  "united kingdom": "GB",
  grenada: "GD",
  georgia: "GE",
  "french guiana": "GF",
  guernsey: "GG",
  ghana: "GH",
  gibraltar: "GI",
  greenland: "GL",
  gambia: "GM",
  guinea: "GN",
  guadeloupe: "GP",
  "equatorial guinea": "GQ",
  greece: "GR",
  "south georgia and the south sandwich islands": "GS",
  guatemala: "GT",
  guam: "GU",
  "guinea-bissau": "GW",
  guyana: "GY",
  "hong kong": "HK",
  "heard island and mcdonald islands": "HM",
  honduras: "HN",
  croatia: "HR",
  haiti: "HT",
  hungary: "HU",
  indonesia: "ID",
  ireland: "IE",
  israel: "IL",
  "isle of man": "IM",
  india: "IN",
  "british indian ocean territory": "IO",
  iraq: "IQ",
  "iran, islamic republic of": "IR",
  iceland: "IS",
  italy: "IT",
  jersey: "JE",
  jamaica: "JM",
  jordan: "JO",
  japan: "JP",
  kenya: "KE",
  kyrgyzstan: "KG",
  cambodia: "KH",
  kiribati: "KI",
  comoros: "KM",
  "saint kitts and nevis": "KN",
  "korea, democratic people's republic of": "KP",
  "korea, republic of": "KR",
  kuwait: "KW",
  "cayman islands": "KY",
  kazakhstan: "KZ",
  "lao people's democratic republic": "LA",
  lebanon: "LB",
  "saint lucia": "LC",
  liechtenstein: "LI",
  "sri lanka": "LK",
  liberia: "LR",
  lesotho: "LS",
  lithuania: "LT",
  luxembourg: "LU",
  latvia: "LV",
  libya: "LY",
  morocco: "MA",
  monaco: "MC",
  "moldova, republic of": "MD",
  montenegro: "ME",
  "saint martin (french part)": "MF",
  madagascar: "MG",
  "marshall islands": "MH",
  "macedonia, the former yugoslav republic of": "MK",
  mali: "ML",
  myanmar: "MM",
  mongolia: "MN",
  macao: "MO",
  "northern mariana islands": "MP",
  martinique: "MQ",
  mauritania: "MR",
  montserrat: "MS",
  malta: "MT",
  mauritius: "MU",
  maldives: "MV",
  malawi: "MW",
  mexico: "MX",
  malaysia: "MY",
  mozambique: "MZ",
  namibia: "NA",
  "new caledonia": "NC",
  niger: "NE",
  "norfolk island": "NF",
  nigeria: "NG",
  nicaragua: "NI",
  netherlands: "NL",
  norway: "NO",
  nepal: "NP",
  nauru: "NR",
  niue: "NU",
  "new zealand": "NZ",
  oman: "OM",
  panama: "PA",
  peru: "PE",
  "french polynesia": "PF",
  "papua new guinea": "PG",
  philippines: "PH",
  pakistan: "PK",
  poland: "PL",
  "saint pierre and miquelon": "PM",
  pitcairn: "PN",
  "puerto rico": "PR",
  "palestine, state of": "PS",
  portugal: "PT",
  palau: "PW",
  paraguay: "PY",
  qatar: "QA",
  reunion: "RE",
  romania: "RO",
  serbia: "RS",
  "russian federation": "RU",
  rwanda: "RW",
  "saudi arabia": "SA",
  "solomon islands": "SB",
  seychelles: "SC",
  sudan: "SD",
  sweden: "SE",
  singapore: "SG",
  "saint helena": "SH",
  slovenia: "SI",
  "svalbard and jan mayen": "SJ",
  slovakia: "SK",
  "sierra leone": "SL",
  "san marino": "SM",
  senegal: "SN",
  somalia: "SO",
  suriname: "SR",
  "south sudan": "SS",
  "sao tome and principe": "ST",
  "el salvador": "SV",
  "sint maarten (dutch part)": "SX",
  "syrian arab republic": "SY",
  swaziland: "SZ",
  "turks and caicos islands": "TC",
  chad: "TD",
  "french southern territories": "TF",
  togo: "TG",
  thailand: "TH",
  tajikistan: "TJ",
  tokelau: "TK",
  "timor-leste": "TL",
  turkmenistan: "TM",
  tunisia: "TN",
  tonga: "TO",
  turkey: "TR",
  "trinidad and tobago": "TT",
  tuvalu: "TV",
  "taiwan, province of china": "TW",
  "united republic of tanzania": "TZ",
  ukraine: "UA",
  uganda: "UG",
  "united states": "US",
  uruguay: "UY",
  uzbekistan: "UZ",
  "holy see (vatican city state)": "VA",
  "saint vincent and the grenadines": "VC",
  venezuela: "VE",
  "british virgin islands": "VG",
  "us virgin islands": "VI",
  vietnam: "VN",
  vanuatu: "VU",
  "wallis and futuna": "WF",
  samoa: "WS",
  yemen: "YE",
  mayotte: "YT",
  "south africa": "ZA",
  zambia: "ZM",
  zimbabwe: "ZW",
};

export const CODE_TO_COUNTRY: Record<string, string> = {
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua and Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Alland Islands",
  AZ: "Azerbaijan",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthelemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivia",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos (Keeling) Islands",
  CD: "Congo",
  CF: "Central African Republic",
  CG: "Congo, Republic of the",
  CH: "Switzerland",
  CI: "Cote d'Ivoire",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cape Verde",
  CW: "Curacao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands (Malvinas)",
  FM: "Micronesia, Federated States of",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia and the South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran, Islamic Republic of",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "Saint Kitts and Nevis",
  KP: "Korea, Democratic People's Republic of",
  KR: "Korea, Republic of",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Lao People's Democratic Republic",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova, Republic of",
  ME: "Montenegro",
  MF: "Saint Martin (French part)",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "Macedonia, the Former Yugoslav Republic of",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  PN: "Pitcairn",
  PR: "Puerto Rico",
  PS: "Palestine, State of",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russian Federation",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "Saint Helena",
  SI: "Slovenia",
  SJ: "Svalbard and Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "Sao Tome and Principe",
  SV: "El Salvador",
  SX: "Sint Maarten (Dutch part)",
  SY: "Syrian Arab Republic",
  SZ: "Swaziland",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turkey",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "United Republic of Tanzania",
  UA: "Ukraine",
  UG: "Uganda",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Holy See (Vatican City State)",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "US Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const countries = Object.values(CODE_TO_COUNTRY);

export const ukCountries = [
  "england",
  "uk",
  "scotland",
  "wales",
  "northern ireland",
  "united kingdom",
];
