import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useStyles } from "../styles";

interface Props {
  name?: string;
}

export const MedicineInstructionsFields = ({ name }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.fieldsSection}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name={`${name}.usageNotes`}
            label="Usage Notes"
            size="small"
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name={`${name}.notesForMedicineSelection`}
            label="Notes for Medicine Selection"
            size="small"
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};
