import { Alert } from "@material-ui/lab";
import { Box, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getFieldName,
  getManualMedicines,
  getSpecifyDosingInstructionsMedicines,
  getValueFromObject,
} from "helpers";
import React, { useMemo } from "react";
import { TreatmentFormValues, ZohoBMHDoctor } from "types";

const useStyles = makeStyles(() => ({
  b: {
    fontStyle: "italic",
    fontWeight: "bold",
  },
  buttonText: {
    textTransform: "capitalize",
  },
}));

interface Props {
  name?: string;
  doctor?: ZohoBMHDoctor | null;
  submitButtonText: string;
  otherMedicineTypeId: string;
}

export const TreatmentMedicinesInfo = ({
  name = "",
  doctor,
  submitButtonText,
  otherMedicineTypeId,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const fieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const isDoctor = useMemo(() => {
    return !!doctor?.id;
  }, [doctor?.id]);

  const manualMedicines = useMemo(() => {
    return getManualMedicines(treatmentAreasValues, otherMedicineTypeId);
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasManualMedicine = useMemo(() => {
    if (isDoctor) {
      return false;
    }
    return !!manualMedicines.length;
  }, [manualMedicines.length, isDoctor]);

  const manualMedicinesNames = useMemo(() => {
    return manualMedicines.join(", ");
  }, [manualMedicines]);

  const specifyDosingInstructionsMedicines = useMemo(() => {
    return getSpecifyDosingInstructionsMedicines(
      treatmentAreasValues,
      otherMedicineTypeId
    );
  }, [treatmentAreasValues, otherMedicineTypeId]);

  const hasSpecifyDosingInstruction = useMemo(() => {
    return !!specifyDosingInstructionsMedicines.length;
  }, [specifyDosingInstructionsMedicines.length]);

  const specifyDosingInstructionsMedicinesNames = useMemo(() => {
    return specifyDosingInstructionsMedicines.join(", ");
  }, [specifyDosingInstructionsMedicines]);

  const showInfo = useMemo(() => {
    return hasManualMedicine || hasSpecifyDosingInstruction;
  }, [hasManualMedicine, hasSpecifyDosingInstruction]);

  const view = useMemo(() => {
    if (hasManualMedicine && hasSpecifyDosingInstruction) {
      return "both";
    }
    if (hasManualMedicine) {
      return "manual";
    }
    return "specify-dosing-instructions";
  }, [hasManualMedicine, hasSpecifyDosingInstruction]);

  if (!showInfo) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection="row"
      alignItems="flex-end"
      mb={2}
    >
      <Box width="80%" maxWidth={700}>
        <Alert severity="info">
          Automatic calculation of matching products and packages will be
          skipped because{" "}
          {view === "manual" || view === "both" ? (
            <>
              <span className={classes.b}>{manualMedicines}</span>{" "}
              {specifyDosingInstructionsMedicines.length > 1 ? "are" : "is"}{" "}
              marked as manual{view === "both" ? " and " : ". "}
            </>
          ) : null}
          {view === "specify-dosing-instructions" || view === "both" ? (
            <>
              <span className={classes.b}>
                {specifyDosingInstructionsMedicinesNames}
              </span>{" "}
              {manualMedicinesNames.length > 1 ? "have" : "has"} custom dosing
              instructions.
            </>
          ) : null}{" "}
          The treatment will be progressed immediately to{" "}
          <b>Pending Review and Finalization</b> as soon as you press the &apos;
          <span className={classes.buttonText}>
            {submitButtonText.toLowerCase()}
          </span>
          &apos; button.
        </Alert>
      </Box>
    </Box>
  );
};
