import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { TreatmentOrderItem, useStyles } from "widgets/DispenseDrugs";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import { AllUnitsHaveSameBNField } from "../DispenseOrderItem/AllUnitsHaveSameBNField";

interface DispensedUnitsProps {
  item: TreatmentOrderItem;
  fieldName: string;
  quantity: number;
}

export const DispensedUnits: React.FC<DispensedUnitsProps> = ({
  item,
  fieldName,
  quantity,
}) => {
  const { values } = useFormikContext();
  const classes = useStyles();

  const missingQuantity: number = useMemo(
    () => getValueFromObject(`${fieldName}.missingQuantity`, values),
    [values, fieldName]
  );
  const variants = useMemo(
    () => getValueFromObject(`${fieldName}.variants`, values),
    [values, fieldName]
  );
  const quantityString = useMemo(() => {
    if (missingQuantity) {
      return `(${variants ? variants.length : 0} of ${quantity})`;
    }
    return `(${variants ? variants.length : 0} of ${item.Quantity})`;
  }, [item, missingQuantity, quantity, variants]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Typography variant="body2" gutterBottom>
          Dispensed Units{" "}
          <span className={classes.subTitle}>{quantityString}</span>
        </Typography>
      </Grid>
      <Grid item>
        <AllUnitsHaveSameBNField quantity={quantity} fieldName={fieldName} />
      </Grid>
    </Grid>
  );
};
