/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-danger */
import React, { useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";
import { Formik, Form, Field, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { VALIDATIONS, splitEmails } from "helpers";
import { useWidgetResize } from "hooks";
import { zohoServices, notifications } from "services";
import Loader from "components/Loader";
import SendEmailWrapper, {
  useSendEmailStatus,
} from "components/SendEmailWrapper";
import { pathwayRequests } from "redux/pathway";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";
import { ZohoPathway } from "types";
import { ZohoModuleEnum } from "@deep-consulting-solutions/bmh-constants";
import {
  FeeText,
  ScenarioText,
  useSendInstructionScenario,
} from "./useSendInstructionScenario";
import { shouldHaveAttachment } from "./helpers";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
  },
  input: {
    with: "100%",
    marginBottom: s(0.5),
  },
  caption: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    marginBottom: s(1),
    textAlign: "right",
  },
  send: {
    marginLeft: s(1),
  },
}));

interface FormValues {
  text: string;
  emails: string;
  feeCollected: boolean;
}

const PathwaySendInstructions = () => {
  const {
    pathway: pathwayRecord,
    initialLoading,
    loading,
    clientInstructions,
    hasError,
    clientArray,
    ids,
    feeText,
  } = useSendInstructionScenario();
  const { resize, wrapperRef } = useWidgetResize<HTMLDivElement>();
  const { status, failures, sendEmails } = useSendEmailStatus({ resize });

  const closeWidget = useCallback(() => {
    zohoServices.closePopup(!!feeText);
  }, [feeText]);

  const validationSchema = useMemo(() => {
    return Yup.lazy(() => {
      return Yup.object({
        emails: VALIDATIONS.multipleEmailsString,
        feeCollected: feeText ? Yup.bool().oneOf([true]) : Yup.bool(),
      }).nullable();
    });
  }, [feeText]);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      if (feeText) {
        try {
          await zohoServices.updateRecord<ZohoPathway>({
            Entity: ZohoModuleEnum.PATHWAYS,
            id: ids[0],
            data: {
              Blood_Draw_Fee_Collected: true,
            },
          });
        } catch (error: any) {
          const message =
            error?.message ||
            error?.data?.message ||
            (error.data && error.data[0]?.message) ||
            "Uknown error";

          notifications.notifyError(message);
          return;
        }
      }

      sendEmails({
        request: () =>
          pathwayRequests.sendInstructionEmails({
            emails: splitEmails(values.emails),
            zohoId: ids[0],
            text: values.text,
            attachBloodTestOrderForm: shouldHaveAttachment(pathwayRecord),
          }),
      });
    },
    [feeText, sendEmails, ids, pathwayRecord]
  );

  useEffect(() => {
    if (hasError || (clientInstructions && clientInstructions.length > 0)) {
      resize();
    }
  }, [resize, clientInstructions, hasError]);

  const classes = useStyle();

  if (initialLoading) {
    return <Loader open />;
  }

  if (hasError) {
    return (
      <div>
        <Container innerRef={wrapperRef}>
          <Box py="4rem" textAlign="center">
            Something went wrong
          </Box>
        </Container>
      </div>
    );
  }

  return (
    <Formik<FormValues>
      enableReinitialize
      validateOnMount
      initialValues={{
        emails: clientArray[0]?.email ?? "",
        text: "",
        feeCollected: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isValid }) => {
        return (
          <WidgetButtonWrapper>
            <Container innerRef={wrapperRef}>
              <Form>
                <Loader open={loading} />

                <SendEmailWrapper
                  status={status}
                  failures={failures}
                  onClose={closeWidget}
                  failureTitle="Unfortunately email was not delivered to client’s inbox."
                  failureHelper="Try again with another FROM email address (configure in CRM BMH Custom Settings) or contact DCS to resolve the problem."
                >
                  <Box>
                    {feeText && <FeeText {...feeText} />}

                    <Typography variant="h5">
                      Send Instructions to Client
                    </Typography>

                    <ScenarioText instructions={clientInstructions} />

                    <Box className={classes.section}>
                      <Field
                        inputProps={{ "aria-label": "emails" }}
                        name="emails"
                        component={TextField}
                        className={classes.input}
                      />
                      <Typography variant="caption" className={classes.caption}>
                        Recipients&apos; email addresses. If you want to enter
                        additional email addresses, type them, separating each
                        one from previous address with comma. Example:
                        abc@def.com, ghi@jkl.com
                      </Typography>
                    </Box>

                    <Box className={classes.section}>
                      <Field
                        name="text"
                        component={TextField}
                        className={classes.input}
                        placeholder="Type message here or leave empty"
                        multiline
                      />
                      <Typography variant="caption" className={classes.caption}>
                        Email with blood drawing instructions will be sent to
                        the client. Here you can add additional information.
                      </Typography>
                    </Box>

                    <Box className={classes.buttons}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={closeWidget}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.send}
                        disabled={!isValid}
                        type="submit"
                      >
                        Send
                      </Button>
                    </Box>
                  </Box>
                </SendEmailWrapper>
              </Form>
            </Container>
          </WidgetButtonWrapper>
        );
      }}
    </Formik>
  );
};

export default PathwaySendInstructions;
