import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

interface Props {
  treatmentIsLegacy: boolean;
  isDoctorNotAllowed: boolean;
  missingRequiredFields: string[];
}
export const BlockWidgetAlert = ({
  treatmentIsLegacy,
  isDoctorNotAllowed,
  missingRequiredFields,
}: Props) => {
  if (missingRequiredFields.length) {
    return (
      <Alert severity="error">
        <AlertTitle>
          This treatment cannot be updated without entering the following
          details for this Client:
        </AlertTitle>
        <Box component="ul" margin={0}>
          {missingRequiredFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Box>
      </Alert>
    );
  }

  if (treatmentIsLegacy) {
    return (
      <Alert severity="warning">
        This treatment cannot be updated with Update Treatment Widget.
      </Alert>
    );
  }

  if (isDoctorNotAllowed) {
    return (
      <Alert severity="warning">
        This user does not have the right permissions to update this treatment.
      </Alert>
    );
  }
  return null;
};
