import React from "react";
import { SenderIdentitiesManagementWidget } from "@deep-consulting-solutions/email-sender-management-mui-v4";

const getAPIUrl = () => {
  return `${process.env.REACT_APP_BASE_URL}`;
};

export const SenderIdentitiesManagement = () => {
  return <SenderIdentitiesManagementWidget apiUrl={getAPIUrl()} />;
};
