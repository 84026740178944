import React, { useCallback, useEffect } from "react";
import { Interval } from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  IconButton,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { displayTime } from "./helpers";
import { TimeInput } from "./TimeInput";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    inputsContainer: {
      "& >*:not(:last-child)": {
        marginRight: s(1),
      },
    },
    deleteBtn: {
      padding: s(1, 0.375),
    },
    editBtn: {
      marginLeft: s(1),
    },
  })
);

interface IntervalItemProps {
  interval: Interval;
  editing: boolean;
  dayOfWeek: string;
  onUpdateInterval: (dayOfWeek: string, interval: Interval) => any;
  onDeleteInterval: (dayOfWeek: string, intervalId: string) => any;
  setError: (key: string, err: string) => any;
  errors: { [key: string]: string };
  setEditing?: (id: string) => any;
}

export const IntervalItem = ({
  interval,
  editing,
  dayOfWeek,
  onUpdateInterval,
  onDeleteInterval,
  setError,
  errors,
  setEditing,
}: IntervalItemProps) => {
  const classes = useStyles();
  const startKey = `start${interval.id}`;
  const endKey = `end${interval.id}`;
  const startError = errors[startKey];
  const endError = errors[endKey];

  const onEditclick = useCallback(() => {
    if (setEditing) setEditing(interval.id);
  }, [interval.id, setEditing]);

  const onDeleteClick = useCallback(() => {
    onDeleteInterval(dayOfWeek, interval.id);
  }, [dayOfWeek, interval.id, onDeleteInterval]);

  useEffect(() => {
    if (Number.isNaN(interval.startHour)) {
      setError(startKey, "Invalid Time Format");
    } else {
      setError(startKey, "");
    }

    if (Number.isNaN(interval.endHour)) {
      setError(endKey, "Invalid Time Format");
    } else if (
      interval.endHour < interval.startHour ||
      (interval.endHour === interval.startHour &&
        interval.endMin <= interval.startMin)
    ) {
      setError(endKey, "End Time must be after Start Time");
    } else {
      setError(endKey, "");
    }
  }, [endKey, interval, setError, startKey]);

  return (
    <>
      <Box
        className={classes.inputsContainer}
        color="text.primary"
        display={!editing ? "none" : "flex"}
        alignItems="flex-start"
        justifyContent="center"
      >
        <TimeInput
          hours={interval.startHour}
          minutes={interval.startMin}
          onUpdateInterval={onUpdateInterval}
          setError={setError}
          error={!!startError}
          helperText={startError}
          dayOfWeek={dayOfWeek}
          interval={interval}
          name="start"
          fullWidth={false}
        />
        <Box display="flex" alignItems="center" height="40px">
          <Typography variant="body2">to</Typography>
        </Box>
        <TimeInput
          hours={interval.endHour}
          minutes={interval.endMin}
          onUpdateInterval={onUpdateInterval}
          setError={setError}
          error={!!endError}
          helperText={endError}
          dayOfWeek={dayOfWeek}
          interval={interval}
          name="end"
          fullWidth={false}
        />
        <IconButton
          className={classes.deleteBtn}
          size="small"
          onClick={onDeleteClick}
        >
          <Delete />
        </IconButton>
      </Box>
      <Box
        color="text.secondary"
        display={editing ? "none" : "flex"}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          {displayTime(interval.startHour, interval.startMin)}
        </Typography>
        <Box mx={3}>
          <Typography variant="body2">to</Typography>
        </Box>
        <Typography>
          {displayTime(interval.endHour, interval.endMin)}
        </Typography>
        {setEditing && (
          <IconButton
            className={classes.editBtn}
            size="small"
            aria-label="edit"
            onClick={onEditclick}
          >
            <Edit />
          </IconButton>
        )}
      </Box>
    </>
  );
};
