import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { enGB } from "date-fns/locale";
import { DeepPartial } from "@reduxjs/toolkit";

import store, { createStore } from "redux/store";
import theme from "theme";
import { notifications } from "services";
import { AppState } from "redux/types";
import { ErrorCatchPage } from "components/ErrorCatchPage";

export interface RootProps {
  initialState?: DeepPartial<AppState>;
  children: ReactNode;
}

const Root = (() => {
  let customizedStore: typeof store;

  return class RootWrapper extends React.PureComponent<RootProps> {
    static getStore() {
      return customizedStore;
    }

    render() {
      const { initialState, children } = this.props;

      customizedStore = initialState ? createStore(initialState) : store;

      return (
        <Provider store={customizedStore}>
          <SnackbarProvider
            ref={notifications.setRef}
            maxSnack={2}
            autoHideDuration={4000}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
              <ThemeProvider theme={theme}>
                <ErrorCatchPage>{children}</ErrorCatchPage>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </Provider>
      );
    }
  };
})();

export default Root;
