import React, { useMemo } from "react";
import {
  DispenseOrderPages,
  MissingState,
  ParentOrderItem,
  TreatmentOrder,
} from "widgets/DispenseDrugs";
import { DBPharmacyProduct } from "types";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import { OrderItemDispensedUnits } from "../OrderItemDispensedUnits";
import { HandleMissingItemsFields } from "./HandleMissingItemsFields";

interface SupplementaryItemProps {
  item: ParentOrderItem;
  fieldName: string;
  handleMissingItems?: boolean;
  treatmentOrder?: TreatmentOrder;
  replacements?: DBPharmacyProduct[];
  manufacturers: string[];
}

export const SupplementaryItem: React.FC<SupplementaryItemProps> = ({
  item,
  fieldName,
  manufacturers,
  handleMissingItems,
  treatmentOrder,
  replacements,
}) => {
  const { values } = useFormikContext();

  const missingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [fieldName, values]
  );

  const checkMissingQuantity = useMemo(() => {
    return [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes((values as any).currentPage);
  }, [values]);

  return (
    <>
      {![MissingState.DISPENSE_PARTIALLY, MissingState.REPLACE].includes(
        missingState
      ) &&
        checkMissingQuantity && (
          <OrderItemDispensedUnits
            quantity={item.Quantity}
            fieldName={fieldName}
            item={item}
            manufacturers={manufacturers}
          />
        )}

      {!checkMissingQuantity && (
        <OrderItemDispensedUnits
          quantity={item.Quantity}
          fieldName={fieldName}
          item={item}
          manufacturers={manufacturers}
        />
      )}

      {handleMissingItems && (
        <HandleMissingItemsFields
          item={item}
          manufacturers={manufacturers}
          fieldName={fieldName}
          treatmentOrder={treatmentOrder}
          replacements={replacements!}
        />
      )}
    </>
  );
};
