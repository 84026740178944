import { zohoServices } from "services";
import { n8nClient } from "apis";
import { BloodTestInvoice, BloodTestOrder } from "widgets/RegularCheckup/types";

export async function getMeeting(contactId: string) {
  try {
    const response = await zohoServices.getRelatedRecords<any>({
      Entity: "Contacts",
      RecordID: contactId,
      RelatedList: "Events",
    });
    return response ?? [];
  } catch (error) {
    return [];
  }
}

export async function getBloodTestOrder(contactId: string) {
  try {
    const response = await zohoServices.getRelatedRecords<BloodTestOrder>({
      Entity: "Contacts",
      RecordID: contactId,
      RelatedList: "Blood_Test_Orders",
    });
    return response ?? [];
  } catch (error) {
    return [];
  }
}

export async function getUpcomingConsultations(contactId: string) {
  try {
    const response = await n8nClient.get<{
      meetings: [];
    }>(`/upcoming-doctor-consultations?contactZohoId=${contactId}`);

    return response.data;
  } catch (error) {
    return { meetings: [] };
  }
}

export async function getLatestBloodTestInvoice(contactId: string) {
  try {
    const response = await n8nClient.get<{
      invoice: BloodTestInvoice;
    }>(`/client-latest-blood-test-invoice?contactZohoId=${contactId}`);

    return response.data.invoice;
  } catch (error) {
    return null;
  }
}

export async function confirmDontSendInvite(payload: any) {
  try {
    const response = await n8nClient.post(
      `/contacts/update-details-of-last-consultation`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function sendBookingLink(payload: any) {
  try {
    const response = await n8nClient.post(`/send-booking-link`, payload);

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function updateClientRecord(payload: any) {
  try {
    const response = await n8nClient.post(
      `/update-contact-manage-client`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function updateLastPCMDetails(payload: any) {
  try {
    const response = await n8nClient.post(
      `/contacts/update-details-of-last-consultation`,
      payload
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function voidLatestBloodTestInvoice(payload: any) {
  try {
    const response = await n8nClient.post(`/update-invoice-status`, payload);

    return response.data;
  } catch (error) {
    return null;
  }
}
