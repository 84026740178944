import { Box, Typography, makeStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { getDiagnosisList, getFieldName } from "helpers";
import React, { useEffect, useMemo } from "react";
import { ZohoClientRecord, ZohoDiagnosisOption } from "types";

const useStyles = makeStyles(({ palette: p }) => ({
  field: {
    color: p.primary.main,
  },
  text: {
    color: p.primary.light,
  },
}));

interface Props {
  client: ZohoClientRecord | null;
  name?: string;
  diagnosisOptions: ZohoDiagnosisOption[];
}

export const DiagnosisField = ({
  client,
  name = "",
  diagnosisOptions,
}: Props) => {
  const { validateField, getFieldMeta } = useFormikContext();

  const classes = useStyles();
  const fieldName = useMemo(() => getFieldName("diagnosis", name), [name]);
  const fieldMeta = useMemo(() => getFieldMeta(fieldName), [
    fieldName,
    getFieldMeta,
  ]);

  const diagnosisList = useMemo(
    () => getDiagnosisList(diagnosisOptions, client?.Gender),
    [client?.Gender, diagnosisOptions]
  );

  useEffect(() => {
    validateField(fieldName);
  }, [fieldName, validateField]);

  return (
    <Box mb={2}>
      <Typography variant="h5" className={classes.text}>
        Diagnosis*
      </Typography>

      {diagnosisList.map((diagnosis, index) => (
        <Box key={diagnosis.label}>
          <Field
            name={`${fieldName}.${index}.selected`}
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.field}
            Label={{
              label: <Typography>{diagnosis.label}</Typography>,
            }}
            color="primary"
          />
        </Box>
      ))}
      {fieldMeta.error && fieldMeta.touched && (
        <Typography color="error" variant="caption">
          {fieldMeta.error}
        </Typography>
      )}
    </Box>
  );
};
