import React, { useCallback, useMemo } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { TextField } from "@material-ui/core";

export const CustomTextareaField = ({
  name,
  label = "",
  helperText = "",
  required = true,
  rows = 1,
  rowsMax = 4,
  disabled = false,
}: {
  name: string;
  label?: string;
  helperText?: string;
  required?: boolean;
  rows?: number;
  rowsMax?: number;
  disabled?: boolean;
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue]
  );

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  const helpText = useMemo(() => {
    if (fieldTouched && fieldError) {
      return fieldError;
    }
    return helperText;
  }, [fieldError, fieldTouched, helperText]);

  return (
    <Field
      name={name}
      label={label}
      component={TextField}
      size="small"
      required={required}
      multiline
      rows={rows}
      rowsMax={rowsMax}
      value={getIn(values, name)}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      helperText={helpText}
      error={fieldTouched && !!fieldError}
      disabled={disabled}
    />
  );
};
