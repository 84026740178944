import { Box } from "@material-ui/core";
import {
  ClientDetails,
  DiagnosisField,
  DoctorField,
  RequestedChanges,
  TreatmentBillingCycleValidationInfo,
  TreatmentFields,
  TreatmentMedicinesInfo,
  TreatmentOrderDateChangeFields,
  TreatmentUpdateInfo,
  UpdateTreatmentMedicineAndProductsTab,
  UpdateTreatmentPlanTab,
} from "components/Treatments";
import React, { useEffect, useMemo } from "react";
import {
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoDiagnosisOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoMedUsageUnit,
  ZohoPharmacy,
  ZohoProduct,
  ZohoSelectedProduct,
  ZohoTreatment,
  ZohoTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoTreatmentOrder,
  ZohoTreatmentTreatmentArea,
} from "types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getUpdateTreatmentChanges } from "helpers";
import { useFormikContext } from "formik";
import { TabPanel } from "./TabPanel";

const getSentenceCaseForArray = (arr: ZohoTreatmentOrder[]) => {
  const [lastPart, ...firstPart] = arr.reverse();
  return (
    <>
      {(firstPart || []).reverse().map((i) => (
        <a href={i.recordCrmLink} target="_blank" rel="noreferrer">
          {i.Name}
        </a>
      ))}{" "}
      {firstPart?.length ? "and" : ""}{" "}
      <a href={lastPart.recordCrmLink} target="_blank" rel="noreferrer">
        {lastPart.Name}
      </a>
    </>
  );
};

interface Props {
  treatmentAreas: ZohoTreatmentArea[];
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
  doctor: ZohoBMHDoctor | null;
  doctors: ZohoBMHDoctor[];
  client: ZohoClientRecord | null;
  submitButtonText: string;
  hasPaidTreatmentOrders: boolean;
  treatment: ZohoTreatment | null;
  inProgressTreatmentOrders: ZohoTreatmentOrder[];
  paidTreatmentOrders: ZohoTreatmentOrder[];
  activeTab: number;
  previousState: string;
  pharmacies: ZohoPharmacy[];
  suggestedMedicines: ZohoTreatmentMedicine[];
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  bacteriostaticProducts: ZohoProduct[];
  supplementaryProducts: ZohoProduct[];
  setValuesChangeCalculation: (v: {
    requirePackageAndPharmacySelection: boolean;
    requirePrescriptionAndCttpChange: boolean;
    requirePrescriptionChange: boolean;
  }) => void;
  otherMedicineTypeId: string;
  medUsageUnits: ZohoMedUsageUnit[];
  selectedSupplementaryProducts: ZohoSelectedProduct[];
  diagnosisOptions: ZohoDiagnosisOption[];
}

export const UpdateTreatmentForm = ({
  treatmentAreas,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
  doctors,
  doctor,
  client,
  submitButtonText,
  hasPaidTreatmentOrders,
  treatment,
  inProgressTreatmentOrders,
  activeTab,
  previousState,
  pharmacies,
  suggestedMedicines,
  treatmentTreatmentAreasById,
  bacteriostaticProducts,
  supplementaryProducts,
  setValuesChangeCalculation,
  paidTreatmentOrders,
  otherMedicineTypeId,
  medUsageUnits,
  selectedSupplementaryProducts,
  diagnosisOptions,
}: Props) => {
  const { values, initialValues } = useFormikContext();

  const inProgressTreatmentOrdersWithFirstInvoiceNumber = useMemo(() => {
    if (!paidTreatmentOrders.length) {
      return [];
    }
    return paidTreatmentOrders.filter(
      (i) => i.Invoice_Number === paidTreatmentOrders[0].Invoice_Number
    );
  }, [paidTreatmentOrders]);

  const {
    requirePackageAndPharmacySelection,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
    treatmentHasAnyChange,
  } = useMemo(
    () => getUpdateTreatmentChanges(values, initialValues, "", activeTab),
    [values, initialValues, activeTab]
  );

  useEffect(() => {
    setValuesChangeCalculation({
      requirePackageAndPharmacySelection,
      requirePrescriptionAndCttpChange,
      requirePrescriptionChange,
    });
  }, [
    requirePackageAndPharmacySelection,
    setValuesChangeCalculation,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
  ]);

  return (
    <Box>
      <Box mb={4}>
        <ClientDetails client={client} />
        <Box mb={2}>
          {inProgressTreatmentOrders &&
          (requirePackageAndPharmacySelection ||
            requirePrescriptionAndCttpChange ||
            requirePrescriptionChange) ? (
            <Alert severity="warning">
              If you want to change the next order only, you can change it from
              &apos;Update Upcoming Orders&apos; widget.
            </Alert>
          ) : null}
        </Box>
        <RequestedChanges
          requestedChanges={treatment?.Requested_Changes || ""}
          doctor={doctor}
        />
        <DoctorField doctors={doctors} doctor={doctor} />
      </Box>
      <Box mb={4}>
        <DiagnosisField client={client} diagnosisOptions={diagnosisOptions} />
      </Box>
      <TabPanel value={activeTab} index={0}>
        <UpdateTreatmentPlanTab
          treatmentAreas={treatmentAreas}
          dosingTypes={dosingTypes}
          dosingAdminRoutes={dosingAdminRoutes}
          medicineAdminRoutes={medicineAdminRoutes}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <UpdateTreatmentMedicineAndProductsTab
          pharmacies={pharmacies}
          suggestedMedicines={suggestedMedicines}
          treatmentTreatmentAreasById={treatmentTreatmentAreasById}
          bacteriostaticProducts={bacteriostaticProducts}
          supplementaryProducts={supplementaryProducts}
          medUsageUnits={medUsageUnits}
          selectedSupplementaryProducts={selectedSupplementaryProducts}
        />
      </TabPanel>

      <Box mb={2}>
        <TreatmentFields />
      </Box>

      {hasPaidTreatmentOrders &&
      (requirePackageAndPharmacySelection ||
        requirePrescriptionAndCttpChange ||
        requirePrescriptionChange) ? (
        <Box mb={2}>
          <TreatmentOrderDateChangeFields />
        </Box>
      ) : null}

      {treatmentHasAnyChange && !requirePackageAndPharmacySelection ? (
        <TreatmentBillingCycleValidationInfo />
      ) : null}

      <TreatmentUpdateInfo
        previousState={previousState}
        treatment={treatment}
        doctor={doctor}
        treatmentHasAnyChange={treatmentHasAnyChange}
        requirePackageAndPharmacySelection={requirePackageAndPharmacySelection}
        requirePrescriptionAndCttpChange={requirePrescriptionAndCttpChange}
        requirePrescriptionChange={requirePrescriptionChange}
      />
      {paidTreatmentOrders.length &&
      (requirePackageAndPharmacySelection ||
        requirePrescriptionAndCttpChange ||
        requirePrescriptionChange) ? (
        <Box mb={2} width="80%" maxWidth={700}>
          <Alert severity="warning">
            <AlertTitle>
              Invoice{" "}
              <a
                href={paidTreatmentOrders[0]?.invoiceUrl}
                target="_blank"
                rel="noreferrer"
              >
                {paidTreatmentOrders[0]?.Invoice_Number}
              </a>{" "}
              is paid but the related order
              {paidTreatmentOrders?.length > 1 ? "s" : ""}{" "}
              {getSentenceCaseForArray(
                inProgressTreatmentOrdersWithFirstInvoiceNumber
              )}
              {paidTreatmentOrders?.length > 1 ? " are " : " is "} not yet
              delivered to the client.
            </AlertTitle>
            Cancel the order{paidTreatmentOrders.length > 1 ? "s" : ""} if
            needed.
          </Alert>
        </Box>
      ) : null}
      {treatmentHasAnyChange ? (
        <TabPanel value={activeTab} index={0}>
          <TreatmentMedicinesInfo
            submitButtonText={submitButtonText}
            otherMedicineTypeId={otherMedicineTypeId}
          />
        </TabPanel>
      ) : null}
    </Box>
  );
};
