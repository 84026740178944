import {
  Box,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { a11yProps, TabPanel } from "widgets/Treatments/MigrateTreatment";
import { PDFViewer } from "components/PDFViewer";
import React, { useCallback } from "react";
import { DispenseOrderPages } from "../types";

interface MedicineLabelsAndChangeNotesProps {
  medicineLabels: string;
  deliveryChangeNoteBase64: string;
  updateCurrentPage: (page: DispenseOrderPages) => () => void;
}

export const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  tabItem: {
    textTransform: "none",
    maxWidth: "13.75rem",
    fontWeight: 600,
    fontSize: "0.8125rem",
  },
  tabContainer: {
    marginBottom: s(2),
    borderBottom: `1px solid ${p.grey[300]}`,
    backgroundColor: p.grey[100],
  },
  labelContainer: {
    height: 400,
    backgroundColor: p.grey[100],
    border: `1px solid ${p.grey[500]}`,
    borderRadius: "4px",
    textAlign: "center",
    padding: s(2),
  },
}));

const MedicineLabelsAndNotes: React.FC<MedicineLabelsAndChangeNotesProps> = ({
  medicineLabels,
  updateCurrentPage,
  deliveryChangeNoteBase64,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const onClickBack = useCallback(() => {
    updateCurrentPage(DispenseOrderPages.HANDLE_MISSING_ITEMS)();
  }, [updateCurrentPage]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <IconButton onClick={onClickBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" color="primary">
          Medicine Labels
        </Typography>
      </Box>

      <Box mb={2}>
        <Alert severity="info">
          The items marked as &quot;Send to BMH&quot; will be separated into a
          new order and sent to BMH to resolve the unavailability.
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert severity="info">
          A new order will be created for the owed items to be dispensed as soon
          as possible.
        </Alert>
      </Box>

      <Tabs
        className={classes.tabContainer}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Medicine Labels Tabs"
      >
        {["Medicine Labels", "Delivery Change Note"].map((label, index) => (
          <Tab
            key={label}
            className={classes.tabItem}
            label={label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box className={classes.labelContainer}>
          {medicineLabels && <PDFViewer data={medicineLabels} height={320} />}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box height={400}>
          {deliveryChangeNoteBase64 && (
            <iframe
              name="Delivery Change Note"
              title="Delivery Change Note"
              src={deliveryChangeNoteBase64}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                background: "#EFEFEF",
              }}
            />
          )}
        </Box>
      </TabPanel>
    </>
  );
};

export default MedicineLabelsAndNotes;
