import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Button, Typography } from "@material-ui/core";
import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import {
  getWaiveStatus,
  submitWaiveShippingCharges,
} from "services/treatment-orders";
import { Alert } from "@material-ui/lab";

export const WaiveShippingCharges = () => {
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOrderPaid, setIsOrderPaid] = useState(false);
  const [isShippingChargesWaived, setIsShippingChargesWaived] = useState(false);

  const canWaive = !loading && !isOrderPaid && !isShippingChargesWaived;

  const resizeWidget = useCallback(() => {
    zohoServices.resizeWidget({
      width: 900,
      height: 520,
    });
  }, []);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getWaiveStatus(treatmentOrderId);
      if (res) {
        const { waiveShippingCharges, paymentStatus } = res;
        setIsShippingChargesWaived(waiveShippingCharges);
        setIsOrderPaid(paymentStatus === "Paid");
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitData(true);
      await submitWaiveShippingCharges(treatmentOrderId);
    } catch (error) {
      setSubmitData(false);
    } finally {
      setSubmitData(false);
      closeWidget(true);
    }
  }, [closeWidget, treatmentOrderId]);

  useEffect(() => {
    resizeWidget();
  }, [resizeWidget]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TreatmentWidgetLayout defaultWidth={700}>
      <TreatmentWidgetTitle title="Waive Shipping Charges" isCustomButton />
      <TreatmentWidgetContent>
        <Loader open={submitData} />
        {!loading && isOrderPaid && (
          <Alert severity="error">The order has already been paid</Alert>
        )}
        {!loading && !isOrderPaid && isShippingChargesWaived && (
          <Alert severity="error">Shipping charges already waived</Alert>
        )}
        {canWaive && (
          <Typography>
            Waiving shipping charges for this order will not affect other orders
            with the same order date from other pharmacies.
          </Typography>
        )}
      </TreatmentWidgetContent>
      {canWaive && (
        <TreatmentWidgetActions>
          <Box
            marginTop={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gridColumnGap="8px"
          >
            <Button
              style={{
                textTransform: "capitalize",
                minWidth: "120px",
              }}
              color="primary"
              variant="outlined"
              onClick={() => closeWidget()}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "capitalize",
                minWidth: "120px",
              }}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Waive
            </Button>
          </Box>
        </TreatmentWidgetActions>
      )}
    </TreatmentWidgetLayout>
  );
};
