import { UK_LAT, UK_LNG } from "configs";

export function getShortAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

export function getLongAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

export const DEFAULT_COORDS = { lat: UK_LAT, lng: UK_LNG };
