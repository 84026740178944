import { colors } from "@material-ui/core";
import React, { useMemo } from "react";

interface Props {
  doseVolume: string;
}

export const DoseVolume = ({ doseVolume }: Props) => {
  const color = useMemo(() => {
    const afterDecimalDigitArr =
      doseVolume?.replace("ml", "")?.trim()?.split(".") || [];

    const afterDecimalDigit = afterDecimalDigitArr[1] || "";

    if (
      afterDecimalDigit &&
      afterDecimalDigit.substring(afterDecimalDigit.length - 1) !== "5"
    ) {
      return colors.red[500];
    }
    return undefined;
  }, [doseVolume]);

  return (
    <span
      style={{
        color,
      }}
    >
      {doseVolume}
    </span>
  );
};
