export function formatDateToTimeZone(
  dateString: string,
  timeZone: string
): string {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone,
    hour12: false,
  });

  const parts = formatter.formatToParts(date);

  const year = parts.find((part) => part.type === "year")?.value || "";
  const month = parts.find((part) => part.type === "month")?.value || "";
  const day = parts.find((part) => part.type === "day")?.value || "";
  const hour =
    parts.find((part) => part.type === "hour")?.value.padStart(2, "0") || "";
  const minute =
    parts.find((part) => part.type === "minute")?.value.padStart(2, "0") || "";
  const second =
    parts.find((part) => part.type === "second")?.value.padStart(2, "0") || "";

  const formattedDateString = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;

  return formattedDateString;
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Extract parts individually
  const day = date.toLocaleDateString("en-US", {
    day: "numeric",
    timeZone: "UTC",
  });
  const month = date.toLocaleDateString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const weekday = date.toLocaleDateString("en-US", {
    weekday: "long",
    timeZone: "UTC",
  });

  return `${weekday}, ${day} ${month}`;
}

export function formatTime(dateString: string): string {
  const date = new Date(dateString);
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  let hours = utcHours % 12;
  hours = hours || 12; // Handle midnight (0 hours)
  const minutes = utcMinutes < 10 ? `0${utcMinutes}` : utcMinutes.toString();
  const ampm = utcHours >= 12 ? "PM" : "AM";
  return `${hours}:${minutes} ${ampm}`;
}

export function formatDuration(startString: string, endString: string): string {
  const start = new Date(startString);
  const end = new Date(endString);
  const durationInMinutes = Math.round(
    (end.getTime() - start.getTime()) / (1000 * 60)
  ); // Convert milliseconds to minutes
  const hours = Math.floor(durationInMinutes / 60);
  const remainingMinutes = durationInMinutes % 60;

  if (hours < 1) {
    return remainingMinutes === 0
      ? ""
      : `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
  }
  if (remainingMinutes === 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}`;
  }
  return `${hours} hour${hours !== 1 ? "s" : ""} ${remainingMinutes} minute${
    remainingMinutes !== 1 ? "s" : ""
  }`;
}
