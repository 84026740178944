import { apiClient, crhApiClient } from "apis";

const fetchTestProfiles = async () => {
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/MasterTestProfile?limit=6000`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data.list;
};

const fetchBloodTestingLab = async () => {
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/BloodTestingLab?limit=6000`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data.list;
};

const fetchBloodDrawer = async () => {
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/BloodDrawer?limit=6000`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data.list;
};

const fetchCheckoutPages = async () => {
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/MasterZohoCheckoutPage?sort=-CreatedAt&limit=6000&nested[BloodDrawer][fields]=Id,Name&nested[BloodTestingLab][fields]=Id,Name,Key&nested[MasterZohoCheckoutPageTestProfile List][fields]=Id,MasterTestProfile&nested[MasterTestProfile][fields]=Id,BmhName`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data.list;
};

const postCheckoutPage = async (values: {
  name: string;
  labKey: string;
  testProfiles: string[];
  bloodTakingOption: string;
  bloodDrawerName: string | null;
  bloodDrawFeeCollected: boolean;
  gender: string;
}) => {
  const res = await apiClient.post("/zoho-checkout-pages", values);
  return res.data.data;
};

const putCheckoutPage = async (
  id: string,
  values: {
    name: string;
    labKey: string;
    testProfiles: string[];
    bloodTakingOption: string;
    bloodDrawerName: string | null;
    bloodDrawFeeCollected: boolean;
    gender: string;
  }
) => {
  const res = await apiClient.patch(`/zoho-checkout-pages/${id}`, values);
  return res.data.data;
};

const deleteCheckoutPage = async (id: string) => {
  await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/bulk/v1/CustomBackend/MasterZohoCheckoutPageTestProfile/all?where=(CheckoutPageId,eq,${id})`,
    app: "NOCODB",
    method: "DELETE",
  });
  const res = await crhApiClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/MasterZohoCheckoutPage/${id}`,
    app: "NOCODB",
    method: "DELETE",
  });
  return res.data.data;
};

export const checkoutPageRequests = {
  fetchTestProfiles,
  fetchBloodTestingLab,
  fetchBloodDrawer,
  fetchCheckoutPages,
  postCheckoutPage,
  putCheckoutPage,
  deleteCheckoutPage,
};
