import {
  StatusEnum,
  Lab,
  TestObxCodes,
  TestFailureCodeFE,
  TestFailureCode,
  LabFailureCode,
  PatchTestFailureCodeReqBody,
} from "@deep-consulting-solutions/bmh-constants";
import { PatchTestObxCodesReqBody, FETestObxCode } from "types";

export const transformToFETestObxCode = (data: TestObxCodes) => {
  const result: FETestObxCode = {
    id: data.id,
    status: data.status,
    isDeletable: data.isDeletable,
    bmhCodeName: data.bmhCodeName,
    hasUpdated: false,
    labs: {},
  };
  data.labs.forEach((lab) => {
    result.labs[lab.key] = {
      key: lab.key,
      code: lab.code,
      isUpdated: lab.isUpdated,
      unit: lab.unit,
      observationAltIdentifier: lab.observationAltIdentifier,
      normalRange: lab.normalRange,
    };
    if (lab.isUpdated) result.hasUpdated = true;
  });
  return result;
};

export const testObxCodesResTransform = (
  id: string,
  reqBody: PatchTestObxCodesReqBody,
  data?: TestObxCodes
): TestObxCodes => {
  const d: TestObxCodes = {
    id,
    status: data ? data.status : StatusEnum.Active,
    isDeletable: data ? data.isDeletable : true,
    bmhCodeName: reqBody.bmhName,
    labs: reqBody.labCodes.map<any>((lab) => ({
      key: lab.labKey,
      code: lab.code,
      isUpdated: false,
      /**
       * ? Change is required here from constant update
       * ! Using default values at the moment
       */
      unit: "",
      normalRange: "",
      observationAltIdentifier: "",
    })),
  };

  return d;
};

export const getTestFailureCodeTransform = (
  data: TestFailureCode,
  labs: Lab[]
): TestFailureCodeFE => {
  const d: TestFailureCodeFE = {
    id: data.id,
    status: data.status,
    isDeletable: data.isDeletable,
    failureCode: data.failureCode,
  };
  labs.forEach((lab) => {
    d[lab.key] = !!data.labs.find((l) => l.key === lab.key);
  });

  return d;
};

export const testFailureCodeResTransform = (
  id: string,
  reqBody: Required<PatchTestFailureCodeReqBody>,
  labs: Lab[],
  data?: TestFailureCode
): TestFailureCode => {
  const d: TestFailureCode = {
    id,
    status: data ? data.status : StatusEnum.Active,
    isDeletable: data ? data.isDeletable : true,
    failureCode: reqBody.failureCode,
    labs: labs
      .map<LabFailureCode>((lab) => ({
        id: lab.id,
        code: lab.name,
        key: lab.key,
      }))
      .filter((lab) => reqBody.labKeys.includes(lab.key)),
  };
  return d;
};
