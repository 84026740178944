import {
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Button,
  DialogActions,
  makeStyles,
  DialogContent,
  fade,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Loader from "components/Loader";
import React, { useCallback, MouseEventHandler, useState } from "react";

interface Props extends DialogProps {
  handleMergeWithExisting: () => Promise<boolean>;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  mergeBtn: {
    background: p.warning.main,
    color: "white",
    border: `1px solid ${fade(p.warning.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${p.warning.main}`,
      background: p.warning.dark,
    },
    "&:disabled": {
      border: `1px solid ${p.warning.light}`,
      background: p.warning.light,
      color: "white",
    },
  },
  actionText: { color: p.error.main },
  title: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
}));

export const MergeWithExistingDialog = ({
  handleMergeWithExisting,
  onClose,
  ...props
}: Props) => {
  const [loading, setLoading] = useState(false);
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose]
  );

  const onMergeClick = useCallback(async () => {
    try {
      setLoading(true);
      await handleMergeWithExisting();
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [handleMergeWithExisting]);

  const classes = useStyle();
  return (
    <Dialog {...props} onClose={onClose}>
      <Loader open={loading} />
      <DialogTitle className={classes.title}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" align="center">
              Merge with Existing Results
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>
          This will add new file&apos;s results to the results already in
          system.
          <br />
          Some markers may not appear in the current results, those will be
          added from the new file&apos;s results. Conflicting results values
          (for the same marker) will be replaced with new file&apos;s results,
          however non-conflicting values from old file will remain as is.
          <br />
          <i>
            <u>
              Use this option when lab splits one order into multiple result
              files
            </u>
          </i>
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Grid container justify="space-between" spacing={2} alignItems="center">
          <Grid item>
            <Typography className={classes.actionText}>
              Warning! This action will be irreversible
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" spacing={2} alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onMergeClick}
                  className={classes.mergeBtn}
                >
                  MERGE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
