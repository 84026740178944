import * as Yup from "yup";
import { FormValues } from "./types";

export const getRenewPayload = (
  id: string,
  values: FormValues,
  isSelfSupplied: boolean
) => {
  if (isSelfSupplied) {
    return {
      zohoID: id,
    };
  }
  let pauseDate = values.pauseDate;
  if (pauseDate) {
    pauseDate = new Date(pauseDate).toLocaleString();
    const [day, month, year] = pauseDate.split(",")[0].split("/");
    pauseDate = `${year}-${month}-${day}`;
  }

  if (values.generateWithoutRegularCheckUp === "no") {
    return {
      zohoID: id,
      canContinueTreatmentWithNoCheckUp: values.generateWithoutRegularCheckUp,
    };
  }

  return {
    zohoID: id,
    canContinueTreatmentWithNoCheckUp: values.generateWithoutRegularCheckUp,
    numberOfRepetitions: values.numberOfRepetitions,
    pauseDate,
  };
};

const changeTypeValidation = Yup.string()
  .required("Please make changes to this prescription or renew prescription")
  .typeError("Please make changes to this prescription or renew prescription");

const generateWithoutRegularCheckUpValidation = Yup.string()
  .required(
    "Please select yes or no for generating prescription with multiple repeats"
  )
  .typeError(
    "Please select yes or no for generating prescription with multiple repeats"
  );

const pauseDateValidation = Yup.string()
  .required("Pause date is required")
  .typeError("Pause date is required");

const cancellationReasonValidation = Yup.string()
  .required("Cancellation reason is required")
  .typeError("Cancellation reason is required");

export const getValidationSchema = (
  isSelfSupplied: boolean,
  maxNumberOfRepetitions: number
) => {
  const numberOfRepetitionsValidation = Yup.number()
    .required("Number of repetitions is required")
    .typeError("Number of repetitions is required")
    .max(
      maxNumberOfRepetitions,
      `Number of repetitions must be less than ${maxNumberOfRepetitions}`
    )
    .min(0, "Number of repetitions must be greater than 0")
    .integer("Number of repetitions must be a whole number");

  return Yup.lazy((v) => {
    const values = v as FormValues;
    const isGenerateWithoutRegularCheckUpRequired =
      !isSelfSupplied && values.changeType === "renew";

    const isNumberOfRepetitionsRequired =
      !isSelfSupplied &&
      values.changeType === "renew" &&
      values.generateWithoutRegularCheckUp === "yes";

    const isPauseDateRequired =
      !isSelfSupplied &&
      values.changeType === "renew" &&
      values.generateWithoutRegularCheckUp === "yes";

    return Yup.object({
      changeType: changeTypeValidation,
      generateWithoutRegularCheckUp: isGenerateWithoutRegularCheckUpRequired
        ? generateWithoutRegularCheckUpValidation
        : Yup.string().nullable().optional(),
      numberOfRepetitions: isNumberOfRepetitionsRequired
        ? numberOfRepetitionsValidation
        : Yup.number().nullable().optional(),
      pauseDate: isPauseDateRequired
        ? pauseDateValidation
        : Yup.string().nullable().optional(),
      cancellationReason:
        values.changeType === "make-changes"
          ? cancellationReasonValidation
          : Yup.string().nullable().optional(),
    });
  });
};
