import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import Loader from "components/Loader";
import { useWidgetResize, useOrderFormPrint } from "hooks";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";

import PathwaySendEmail from "./PathwaySendEmail";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
  },
  button: {
    width: "100%",
    marginBottom: s(0.5),
  },
  caption: {
    color: p.grey[500],
  },
}));

interface PathwayGenerateFormProps {
  isBlueprint?: boolean;
}

const PathwayGenerateForm: React.FC<PathwayGenerateFormProps> = ({
  isBlueprint,
}) => {
  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();

  const [loading, setLoading] = useState(!!isBlueprint);
  const [showSendEmail, setShowSendEmail] = useState(!!isBlueprint);

  const { printPDF } = useOrderFormPrint();

  const onPrintClick = useCallback(() => {
    printPDF({
      setLoading,
    });
  }, [printPDF]);

  const onSendEmailClick = useCallback(() => {
    setShowSendEmail(true);
  }, []);

  useEffect(() => {
    resize();
  }, [resize]);

  const classes = useStyle();

  return (
    <WidgetButtonWrapper>
      <Container innerRef={wrapperRef}>
        <Loader open={loading} />

        {!showSendEmail && (
          <>
            <Typography variant="h5">
              What do you want to do with order form?
            </Typography>

            <Box className={classes.section}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={onPrintClick}
              >
                PRINT
              </Button>
              <Typography variant="caption" className={classes.caption}>
                Click here if you want to print the blood test order form.
              </Typography>
            </Box>

            <Box className={classes.section}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={onSendEmailClick}
              >
                SEND BY EMAIL
              </Button>

              <Typography variant="caption" className={classes.caption}>
                Click here if you want to send the blood test order form by
                email.
              </Typography>
            </Box>
          </>
        )}

        {showSendEmail && (
          <PathwaySendEmail
            setLoading={setLoading}
            loading={loading}
            resize={resize}
            isBlueprint={isBlueprint}
          />
        )}
      </Container>
    </WidgetButtonWrapper>
  );
};

export default PathwayGenerateForm;
