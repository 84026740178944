import {
  McmAvailability,
  GetMcmAvailabilityRes,
  PostOrPatchMcmAvailabilityRes,
  mcmAvailabilityEndpoints,
  mcmSpecialAvailabilityEndpoints,
  McmSpecialAvailability,
  McmAvailabilityWithSpecial,
} from "@deep-consulting-solutions/bmh-constants";
import { apiClient } from "apis";
import { IntervalsByDayMap } from "types";

const fetchMcmAvailability = async (): Promise<
  McmAvailabilityWithSpecial[]
> => {
  const res = await apiClient.get<GetMcmAvailabilityRes>(
    mcmAvailabilityEndpoints.getMcmAvailability
  );
  return res.data.data;
};

const postMcmAvailability = async (
  mcmdId: string,
  days: IntervalsByDayMap
): Promise<McmAvailability> => {
  const res = await apiClient.patch<PostOrPatchMcmAvailabilityRes>(
    mcmAvailabilityEndpoints.patchMcmAvailability(mcmdId),
    days
  );
  return res.data.data;
};

const postSpecialAvailability = async (
  mcmId: string,
  specialAvailability: Omit<McmSpecialAvailability, "id">
) => {
  const res = await apiClient.patch<{ data: McmSpecialAvailability }>(
    mcmSpecialAvailabilityEndpoints.patchMcmAvailability(mcmId),
    specialAvailability
  );

  return res.data.data;
};

export const mcmAvailabilityRequests = {
  fetch: fetchMcmAvailability,
  post: postMcmAvailability,
  postSpecialAvailability,
};
