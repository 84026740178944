import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import { Close, DeleteForever, Search } from "@material-ui/icons";
import {
  LabDisplayOrder,
  StatusEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { useDispatch, useSelector } from "react-redux";

import { SettingsContainer } from "components/SettingsContainer";
import Loader from "components/Loader";
import { TableHeadCell } from "components/TableHeadCell";
import { AppDispatch } from "redux/types";
import {
  fetchLabsOrderRequest,
  settingsActions,
  settingsSelectors,
  updateLabsOrderRequest,
} from "redux/settings";
import { FETestObxCode } from "types";
import { DialogConfirm } from "components/DialogConfirm";

import { isNumber, isString, orderBy } from "lodash";
import { notifications } from "services";
import { getENText } from "helpers";
import { LabsManualOrder } from "./LabsManualOrder";
import { MemoRow, FormRow } from "./Row";
import { LabHeadCells } from "./LabHeadCells";
import { SortColumn } from "./types";
import { tableCellStyle, ActionTableCell } from "./styles";
import { FormValues } from "./Row/FormRow";
import {
  composeObxCodeBodyFromFormValues,
  getAcknowledgeLabs,
  getMappedCodeNames,
} from "./helpers";

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50];

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    height: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: s(0, 3, 3),
  },
  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  headerTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableContainer: {
    position: "relative",
    flex: 1,
    overflowX: "scroll",
  },
  appBar: {
    position: "sticky",
    top: 0,
    padding: s(1, 3),
    borderBottom: `1px solid ${p.divider}`,
  },
  inactiveContainer: {
    padding: s(2, 3),
  },
  tabPanel: {
    padding: 0,
  },
  tableActionsRight: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  searchField: {
    marginLeft: s(1),
    width: "220px",
  },
  clearSearchBtn: {
    padding: "10px",
  },
  pagination: {
    position: "sticky",
    bottom: 0,
    right: 0,
    background: p.background.paper,
    zIndex: 1,
  },
  tableBody: {
    paddingBottom: 20,
  },
}));

export const TestOBXCodes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const labs = useSelector(settingsSelectors.labsSelectors.selectAll);
  const inputRows = useSelector(
    settingsSelectors.testObxCodeSelectors.selectAll
  );

  const [status, setStatus] = useState(StatusEnum.Active);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [labsOrder, setLabsOrder] = useState<LabDisplayOrder[]>([]);
  const [labOrderBy, setLabOrderBy] = useState<null | string>(null);
  const [columnOrderBy, setColumnOrderBy] = useState<SortColumn>(null);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const sortedLabsOrder = useMemo(
    () => labsOrder.sort((a, b) => (a.rank < b.rank ? -1 : 1)),
    [labsOrder]
  );

  const mappedCodeNames = useMemo(() => {
    return getMappedCodeNames(inputRows);
  }, [inputRows]);

  const updateLabsOrder = useCallback(async (labKeys: string[]) => {
    try {
      setLoading(true);
      const res = await updateLabsOrderRequest(labKeys);
      setLabsOrder(res);
      setLoading(false);
      return true;
    } catch {
      setLoading(false);
      return false;
    }
  }, []);

  const filteredRows = useMemo(() => {
    let rs = [...inputRows];

    rs = rs.filter((r) => r.status === status);

    if (search) {
      rs = rs.filter((r) => {
        const values = [r.bmhCodeName];
        Object.values(r.labs).forEach((l) => {
          values.push(
            l.code,
            l.normalRange,
            l.observationAltIdentifier,
            l.unit
          );
        });
        return values.some((val) => {
          if (
            isString(val) &&
            val.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          }
          if (isNumber(val) && val === Number(search)) {
            return true;
          }
          return false;
        });
      });
    }

    rs = orderBy(
      rs,
      (item) => {
        if (!labOrderBy) return item.bmhCodeName;
        const lab = item.labs[labOrderBy];
        if (!lab) {
          return order === "asc" ? undefined : "";
        }
        return columnOrderBy === "name"
          ? lab.code
          : lab.observationAltIdentifier;
      },
      order
    );

    return rs;
  }, [inputRows, status, search, labOrderBy, columnOrderBy, order]);

  const rows = useMemo(() => {
    return filteredRows.slice(page * size, page * size + size);
  }, [filteredRows, page, size]);

  const handleStatusChange = useCallback(
    (_event: React.ChangeEvent<any>, newStatus: StatusEnum) => {
      setStatus(newStatus);
      setPage(0);
    },
    []
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      setPage(0);
    },
    []
  );

  const handleClearTextClick = useCallback(() => {
    setSearch("");
    setPage(0);
  }, []);

  const updateSort = useCallback((lab: null | string, column: SortColumn) => {
    setLabOrderBy((labOrd) => {
      setColumnOrderBy((colOrd) => {
        setOrder((ord) => {
          const isAsc = lab === labOrd && column === colOrd && ord === "asc";
          return isAsc ? "desc" : "asc";
        });
        return column;
      });
      return lab;
    });
  }, []);

  const handleBMHCodeSortClick = useCallback(() => {
    updateSort(null, null);
  }, [updateSort]);

  const handleChangePage = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSize(Number(e.target.value));
      setPage(0);
    },
    []
  );

  const handleEditingRowChange = useCallback((rowID: string | null) => {
    if (rowID) {
      setIsCreating(false);
    }
    setEditingRow(rowID);
  }, []);

  const handleEditSaveClick = useCallback(
    async (values: FormValues, row: FETestObxCode) => {
      const body = composeObxCodeBodyFromFormValues(values);
      setLoading(true);
      const res = await dispatch(
        settingsActions.updateTestObxCode({ id: row.id, body })
      );
      setLoading(false);
      if (settingsActions.updateTestObxCode.fulfilled.match(res)) {
        setEditingRow(null);
        notifications.notifySuccess(getENText("settings.noti.success.updated"));
      }
    },
    [dispatch]
  );

  const handleNewAddClick = useCallback(() => {
    setIsCreating(true);
    setEditingRow(null);
  }, []);

  const handleNewCancelClick = useCallback(() => {
    setIsCreating(false);
  }, []);

  const handleNewSaveClick = useCallback(
    async (values: FormValues) => {
      const body = composeObxCodeBodyFromFormValues(values);
      setLoading(true);
      const res = await dispatch(settingsActions.createTestObxCode(body));
      setLoading(false);
      if (settingsActions.createTestObxCode.fulfilled.match(res)) {
        setIsCreating(false);
        notifications.notifySuccess(getENText("settings.noti.success.created"));
      }
    },
    [dispatch]
  );

  // Deactivate
  const [toBeDeactivated, setToBeDeactivated] = useState<FETestObxCode | null>(
    null
  );

  const handleDeactivateClick = useCallback((row: FETestObxCode) => {
    setToBeDeactivated(row);
    setEditingRow(null);
    setIsCreating(false);
  }, []);

  const handleDeactivateClose = useCallback(() => {
    setToBeDeactivated(null);
  }, []);

  const handleDeactivateConfirm = useCallback(async () => {
    if (!toBeDeactivated) return;
    try {
      setLoading(true);
      const res = await dispatch(
        settingsActions.changeTestObxCodeStatus({
          id: toBeDeactivated.id,
          nextStatus: StatusEnum.Inactive,
        })
      );
      setLoading(false);
      if (settingsActions.changeTestObxCodeStatus.fulfilled.match(res)) {
        setToBeDeactivated(null);
        notifications.notifySuccess(
          getENText("settings.noti.success.changeStatus", {
            action: "Deactivated",
          })
        );
      }
    } catch {
      setLoading(false);
    }
  }, [toBeDeactivated, dispatch]);

  // Activate
  const handleActivateClick = useCallback(
    async (row: FETestObxCode) => {
      setIsCreating(false);
      setEditingRow(null);
      try {
        setLoading(true);
        const res = await dispatch(
          settingsActions.changeTestObxCodeStatus({
            id: row.id,
            nextStatus: StatusEnum.Active,
          })
        );
        setLoading(false);
        if (settingsActions.changeTestObxCodeStatus.fulfilled.match(res)) {
          notifications.notifySuccess(
            getENText("settings.noti.success.changeStatus", {
              action: "Activated",
            })
          );
        }
      } catch {
        setLoading(false);
      }
    },
    [dispatch]
  );

  // Delete
  const [toBeDeleted, setToBeDeleted] = useState<FETestObxCode | null>(null);

  const handleDeleteClick = useCallback((row: FETestObxCode) => {
    setToBeDeleted(row);
    setEditingRow(null);
    setIsCreating(false);
  }, []);

  const handleDeleteClose = useCallback(() => {
    setToBeDeleted(null);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    if (!toBeDeleted) return;
    try {
      setLoading(true);
      const res = await dispatch(
        settingsActions.deleteTestObxCode({
          id: toBeDeleted.id,
        })
      );
      setLoading(false);
      if (settingsActions.deleteTestObxCode.fulfilled.match(res)) {
        setToBeDeleted(null);
        notifications.notifySuccess(getENText("settings.noti.success.deleted"));
      }
    } catch {
      setLoading(false);
    }
  }, [dispatch, toBeDeleted]);

  const handleAcknowledgeClick = useCallback(
    async (row: FETestObxCode) => {
      setIsCreating(false);
      setEditingRow(null);
      try {
        setLoading(true);
        const res = await dispatch(
          settingsActions.acknowledgeTestObxUpdate({
            id: row.id,
            updatedLabs: getAcknowledgeLabs(row),
          })
        );
        setLoading(false);
        if (settingsActions.acknowledgeTestObxUpdate.fulfilled.match(res)) {
          notifications.notifySuccess(
            getENText("settings.noti.success.acknowledge")
          );
        }
      } catch {
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [, orders] = await Promise.all([
          dispatch(settingsActions.getLabs()),
          fetchLabsOrderRequest(),
        ]);
        setLabsOrder(orders);
        await dispatch(settingsActions.fetchTestObxCodes());
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  const classes = useStyles();

  return (
    <SettingsContainer>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.headerTop}>
            <Typography variant="h4" component="h1" paragraph>
              Set Up Test OBX Codes
            </Typography>
            <Button
              color="primary"
              onClick={handleNewAddClick}
              disabled={isCreating}
            >
              Add New OBX Code
            </Button>
          </div>
          <Typography>Edit or Add OBX codes.</Typography>
        </div>

        <Divider />

        <Loader open={loading} isFixed />
        <div>
          <TabContext value={status as string}>
            <AppBar
              className={classes.appBar}
              position="static"
              color="inherit"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TabList
                  onChange={handleStatusChange}
                  aria-label="status tabs"
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab label={StatusEnum.Active} value={StatusEnum.Active} />
                  <Tab
                    label={StatusEnum.Inactive}
                    value={StatusEnum.Inactive}
                  />
                </TabList>
                <div className={classes.tableActionsRight}>
                  <LabsManualOrder
                    labsOrder={sortedLabsOrder}
                    updateOrder={updateLabsOrder}
                  />
                  <TextField
                    className={classes.searchField}
                    placeholder="Search"
                    fullWidth={false}
                    value={search}
                    onChange={handleSearchChange}
                    InputProps={{
                      margin: "dense",
                      "aria-label": "Clear",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="action" />
                        </InputAdornment>
                      ),
                      endAdornment: !!search && (
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.clearSearchBtn}
                            aria-label="search"
                            edge="end"
                            onClick={handleClearTextClick}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Box>
            </AppBar>
            {status === StatusEnum.Inactive && (
              <>
                <div className={classes.inactiveContainer}>
                  <Typography variant="subtitle2" paragraph>
                    Inactive Explanation
                  </Typography>
                  <Typography variant="body2">
                    Inactive OBX Code won’t be active in the system and won’t be
                    shown in Active OBX Codes Tab. However, OBX code will be
                    activated automatically in case such code is received in
                    test result files.
                  </Typography>
                </div>
                <Divider />
              </>
            )}
          </TabContext>
        </div>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" style={tableCellStyle}>
                  BMH
                </TableCell>
                {labs.map((lab) => {
                  return (
                    <TableCell
                      key={lab.id}
                      colSpan={4}
                      align="center"
                      style={tableCellStyle}
                    >
                      {lab.name} ({lab.key.toUpperCase()})
                    </TableCell>
                  );
                })}
                <ActionTableCell />
              </TableRow>
              <TableRow>
                <TableHeadCell
                  header="OBX Code Name"
                  info="Test Result OBX Name which is going to be displayed in test results record, PDF File and Customer Portal."
                  align="center"
                  hasSorting
                  sortLabelProps={{
                    active: labOrderBy === null,
                    direction: labOrderBy === null ? order : "asc",
                    onClick: handleBMHCodeSortClick,
                  }}
                  noCapitalize
                  style={tableCellStyle}
                />
                {labs.map((lab) => {
                  return (
                    <LabHeadCells
                      key={lab.id}
                      lab={lab}
                      labOrderBy={labOrderBy}
                      columnOrderBy={columnOrderBy}
                      order={order}
                      updateSort={updateSort}
                    />
                  );
                })}
                <ActionTableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {labs.length > 0 && isCreating && (
                <FormRow
                  labs={labs}
                  onNewSaveClick={handleNewSaveClick}
                  onCancelClick={handleNewCancelClick}
                  mappedCodeNames={mappedCodeNames}
                />
              )}
              {rows.map((row) => {
                return (
                  <MemoRow
                    key={row.id}
                    row={row}
                    labs={labs}
                    isEditing={row.id === editingRow}
                    onEditingRowUpdate={handleEditingRowChange}
                    onSaveClick={handleEditSaveClick}
                    onDeactivateClick={handleDeactivateClick}
                    onActivateClick={handleActivateClick}
                    onDeleteClick={handleDeleteClick}
                    onAcknowledgeUpdateClick={handleAcknowledgeClick}
                    mappedCodeNames={mappedCodeNames}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          component="div"
          count={filteredRows.length}
          page={page}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onChangePage={handleChangePage}
          rowsPerPage={size}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            fullWidth: false,
          }}
        />
      </div>
      <DialogConfirm
        open={!!toBeDeactivated}
        onClose={handleDeactivateClose}
        onConfirm={handleDeactivateConfirm}
        type="warning"
        title="Deactivate OBX Code"
        message="After you deactivate OBX Code it won’t be active in the system and won’t be shown in Active OBX Codes Tab. However, OBX code will be activated automatically in case such code is received in test result files. You can reactivate it at any moment."
        confirmBtnLabel="deactivate"
      />
      <DialogConfirm
        open={!!toBeDeleted}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        type="error"
        title="Delete OBX Code"
        message={`After you delete ${toBeDeleted?.bmhCodeName} OBX Code, it's permanently deleted and can't be undeleted.`}
        confirmBtnLabel="delete"
        confirmBtnIcon={<DeleteForever />}
      />
    </SettingsContainer>
  );
};
