import { Box, IconButton, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useCallback } from "react";
import { PDFViewer } from "components/PDFViewer";
import { useStyles } from "./MedicineLabelsAndChangeNotes";
import { DispenseOrderPages } from "../types";

interface MedicineLabelsProps {
  medicineLabels: string;
  updateCurrentPage: (page: DispenseOrderPages) => () => void;
}

export const MedicineLabels: React.FC<MedicineLabelsProps> = ({
  medicineLabels,
  updateCurrentPage,
}) => {
  const classes = useStyles();
  const onClickBack = useCallback(() => {
    updateCurrentPage(DispenseOrderPages.CONFIRM_DISPENSING)();
  }, [updateCurrentPage]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton onClick={onClickBack} color="primary">
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" color="primary">
          Medicine Labels
        </Typography>
      </Box>
      <Box className={classes.labelContainer}>
        {medicineLabels && <PDFViewer data={medicineLabels} height={320} />}
      </Box>
    </>
  );
};
