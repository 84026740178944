import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Root from "components/Root";

import { ConstructionPage } from "ConstructionPage";
import App from "./App";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_BASE_URL!, /^\//],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || "",
});

if (process.env.REACT_APP_CONSTRUCTION_STATE === "construction") {
  ReactDOM.render(<ConstructionPage />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Root>
      <App />
    </Root>,
    document.getElementById("root")
  );
}
