import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import Loader from "components/Loader";
import { Field, useFormikContext } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { getValueFromObject } from "helpers";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import React, { useCallback, useMemo } from "react";
import { zohoServices } from "services";

interface ProvideClarificationFormProps {
  loading: boolean;
  submitDetails: (values: any) => Promise<void>;
}

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const ProvideClarificationForm: React.FC<ProvideClarificationFormProps> = ({
  loading,
  submitDetails,
}) => {
  const classes = useStyles();
  const { values, isValid } = useFormikContext();
  const isPrescriptionIncorrect = useMemo(
    () => getValueFromObject("isPrescriptionIncorrect", values),
    [values]
  );
  const onProvideClarification = useCallback(() => {
    submitDetails(values);
  }, [values, submitDetails]);

  return (
    <TreatmentWidgetLayout defaultWidth={700}>
      <TreatmentWidgetTitle title="Provide Clarifications" />
      <TreatmentWidgetContent>
        <Loader open={loading} />

        <Box>
          <Typography variant="body2" gutterBottom>
            Note from Pharmacist on where clarification is needed:
          </Typography>
          <Typography variant="body2">
            <em>
              Please provide information about the prescription dosing and
              quantity.
            </em>
          </Typography>
          <Box mt={2}>
            <Field
              component={TextField}
              name="prescriptionDosingExplanation"
              label="Prescription Dosing Explanation"
              required
              size="small"
              multiline
              rows={3}
              fullWidth
            />
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              <em>
                If there are errors in the Prescription dosing/quantity, mark
                prescription as incorrect here!
              </em>
              <em>
                On Save, Processing Team will be tasked to correct the
                prescription.
              </em>
            </Typography>
          </Box>
          <Field
            component={CheckboxWithLabel}
            name="isPrescriptionIncorrect"
            type="checkbox"
            Label={{
              label: (
                <Typography variant="body2">Incorrect Prescription</Typography>
              ),
            }}
            color="primary"
          />
          {isPrescriptionIncorrect && (
            <Field
              component={TextField}
              name="explanationForIncorrectPrescription"
              label="Explanation for incorrect prescription"
              size="small"
              multiline
              required={isPrescriptionIncorrect}
              rows={3}
              fullWidth
            />
          )}
        </Box>
      </TreatmentWidgetContent>
      <TreatmentWidgetActions containerClass={classes.actions}>
        <Box textAlign="right">
          <Button
            onClick={() => zohoServices.closePopup(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            disabled={!isValid}
            onClick={onProvideClarification}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Box>
      </TreatmentWidgetActions>
    </TreatmentWidgetLayout>
  );
};
