import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { ZohoTreatment } from "types";

interface Props {
  inProgressTreatment: ZohoTreatment | null;
  missingRequiredFields: string[];
}

export const BlockWidgetAlert = ({
  inProgressTreatment,
  missingRequiredFields,
}: Props) => {
  if (missingRequiredFields.length) {
    return (
      <Alert severity="error">
        <AlertTitle>
          A Treatment cannot be created without entering the following details
          for this Client:
        </AlertTitle>
        <Box component="ul" margin={0}>
          {missingRequiredFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Box>
      </Alert>
    );
  }

  if (!inProgressTreatment) {
    return null;
  }
  return (
    <Alert severity="warning">
      <AlertTitle>
        The client already has a treatment{" "}
        <a target="_blank" rel="noreferrer" href={inProgressTreatment.url}>
          {inProgressTreatment.Name}
        </a>{" "}
        that is {inProgressTreatment.State}.
      </AlertTitle>
      You can decide to:
      <ul>
        <li>Update the existing plan which creates a new plan.</li>
        <li>Cancel or Pause the existing treatment then create a new one.</li>
      </ul>
    </Alert>
  );
};
