import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { DBPharmacyProduct, MedicineProduct, MedicineSelected } from "types";
import { TitleValue } from "components/TitleValue";
import { useFormikContext } from "formik";
import {
  checkIfBacteriostaticBundle,
  checkIfInjectionBundle,
  getUsageInstructions,
  getValueFromObject,
} from "helpers";
import { ProductFields } from "./ProductFields";
import { TextInputField } from "../TextInputField";

interface ProductFormProps {
  medicine: MedicineSelected;
  pharmProducts: DBPharmacyProduct[];
  fieldName: string;
  index: number;
  injectionBundles: DBPharmacyProduct[];
  supplementaryProducts: DBPharmacyProduct[];
  otherMedicineTypeId: string;
}

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  innerWrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
    paddingBottom: 0,
  },
  fieldsSection: {
    background: "white",
    padding: spacing(2),
    borderRadius: spacing(0.5),
  },
}));

export const ProductForm: React.FC<ProductFormProps> = ({
  medicine,
  pharmProducts,
  fieldName,
  supplementaryProducts,
  injectionBundles,
  otherMedicineTypeId,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const parentProduct: MedicineProduct = useMemo(
    () => getValueFromObject(fieldName, values),
    [values, fieldName]
  );
  const parentProductValue = pharmProducts.find(
    (pharmProduct) => pharmProduct.ZohoCrmId === parentProduct.product?.value
  );

  const filteredMainProducts = useMemo(() => {
    if (medicine?.value === otherMedicineTypeId) {
      return pharmProducts;
    }
    return pharmProducts.filter(
      (product) => product.MedicineTypes?.ZohoCrmId === medicine?.value
    );
  }, [pharmProducts, medicine, otherMedicineTypeId]);

  const filteredInjectionBundles = useMemo(() => {
    return injectionBundles.filter(
      (injectionBundle) =>
        injectionBundle.PharmacyId === parentProductValue?.PharmacyId &&
        medicine?.treatmentArea?.id === injectionBundle.TreatmentAreaId
    );
  }, [parentProductValue, injectionBundles, medicine]);

  const filteredSupplementaryProducts = useMemo(() => {
    return supplementaryProducts.filter(
      (supplementaryProduct) =>
        supplementaryProduct.PharmacyId === parentProductValue?.PharmacyId
    );
  }, [parentProductValue, supplementaryProducts]);

  const usageInstruction = useMemo(() => getUsageInstructions(medicine), [
    medicine,
  ]);

  return (
    <Box mb={2} className={classes.fieldsSection}>
      <Grid container justify="space-between">
        <Grid xs={6} item>
          <TitleValue title="Dose" value={usageInstruction} />
        </Grid>
        <Grid xs={6} item>
          <TextInputField
            name={`${fieldName}.usageNotes`}
            label="Usage Notes"
            required={false}
          />
          <FormHelperText>
            Usage Notes are displayed below the dosing in the prescription
          </FormHelperText>
        </Grid>
      </Grid>

      <Box mb={2}>
        <ProductFields
          name={fieldName}
          products={filteredMainProducts as any}
          dosingType={medicine?.dosingType?.value}
        />
      </Box>

      {parentProduct.injectionBundle && checkIfInjectionBundle(medicine) && (
        <Box pl={2} my={2}>
          <Typography variant="h6" color="primary">
            Injection Bundle
          </Typography>

          <Box mt={1} className={classes.innerWrapper}>
            <ProductFields
              name={fieldName}
              products={filteredInjectionBundles as any}
              dosingType={medicine.dosingType?.value}
              isInjectionBundle
            />
          </Box>
        </Box>
      )}

      {parentProduct.bacteriostatic && checkIfBacteriostaticBundle(medicine) && (
        <Box pl={2}>
          <Typography variant="h6" color="primary">
            Bacteriostatic NACl Water
          </Typography>

          <Box mt={1} className={classes.innerWrapper}>
            <ProductFields
              name={fieldName}
              products={filteredSupplementaryProducts as any}
              isBacteriostatic
              dosingType={medicine.dosingType?.value}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
