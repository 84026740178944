import React, { useCallback, useMemo } from "react";
import { TextField } from "@material-ui/core";
import { Field, useFormikContext, getIn } from "formik";

export const CustomInputField = ({
  name,
  label,
  required = true,
  disabled = false,
}: {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.stopPropagation();
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue]
  );

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  return (
    <Field
      component={TextField}
      name={name}
      label={label}
      required={required}
      size="small"
      fullWidth
      value={getIn(values, name)}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      helperText={fieldTouched && fieldError}
      error={fieldTouched && !!fieldError}
      disabled={disabled}
    />
  );
};
