import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { zohoServices } from "services";
import { Formik, FormikConfig } from "formik";

import { zohoSelectors } from "redux/zoho";
import { useSelector } from "react-redux";
import { renewTreatmentValidationSchema } from "helpers";
import { CustomNumberField } from "components/CustomNumberField/CustomNumberField";
import {
  getTreatmentRenewalFirstPaymentDate,
  renewTreatment,
} from "services/treatments/renew-treatment";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { ActionButtons } from "./ActionButtons";

type InitialValues = {
  treatmentPeriod: number | undefined;
};

export const RenewTreatment = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [renewalFirstPaymentDate, setRenewalFirstPaymentDate] = useState("");
  const [treatmentId] = useSelector(zohoSelectors.getIds);
  const user = useSelector(zohoSelectors.getCurrentUser);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const payload = {
          treatmentZohoID: treatmentId,
          crmUserId: user?.id as string,
          treatmentPeriodInMonths: values.treatmentPeriod as number,
        };

        await renewTreatment(payload);
      } catch (error) {
        setSubmitting(false);
      } finally {
        setSubmitData(false);
        closeWidget(true);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentId, user?.id]
  );

  const fetchData = useCallback(async () => {
    try {
      const renewalFirstPaymentDateResponse = await getTreatmentRenewalFirstPaymentDate(
        treatmentId
      );
      setRenewalFirstPaymentDate(renewalFirstPaymentDateResponse);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Formik
      initialValues={{ treatmentPeriod: undefined }}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={renewTreatmentValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={700}>
        <TreatmentWidgetTitle title="Renew Treatment" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height="100px">
              {renewalFirstPaymentDate && (
                <Typography style={{ marginBottom: "16px" }}>
                  <strong>First Payment Date</strong>:{" "}
                  {moment(renewalFirstPaymentDate).format("YYYY-MM-DD")}
                </Typography>
              )}
              <Box>
                <Typography variant="body2" style={{ marginBottom: "4px" }}>
                  Treatment Period in Months *
                </Typography>
                <Box maxWidth="300px">
                  <CustomNumberField name="treatmentPeriod" />
                </Box>
              </Box>
            </Box>
          )}
        </TreatmentWidgetContent>
        {!loading && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
