import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthorizationHeader } from "apis";
import { zohoServices } from "services";
import { AuthState, AppState } from "redux/types";

const ENTITY = "auth";

const getToken = createAsyncThunk<string | null, void, { state: AppState }>(
  `${ENTITY}/getToken`,
  async () => {
    const token = await zohoServices.signatureForServerAPIWithUserID();
    if (token) {
      setAuthorizationHeader(token);
    }
    return token;
  }
);

const refreshToken = createAsyncThunk<string | null, void, { state: AppState }>(
  `${ENTITY}/refreshToken`,
  async () => {
    const token = await zohoServices.signatureForServerAPIWithUserID();
    if (token) {
      setAuthorizationHeader(token);
    }
    return token;
  }
);

const initialState: AuthState = {
  token: null,
  error: null,
  loading: true,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getToken.pending, (state) => {
        state.token = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(getToken.fulfilled, (state, action) => {
        const token = action.payload;
        if (token) {
          state.token = token;
          state.error = null;
          state.loading = false;
        } else {
          state.loading = false;
          state.token = null;
          state.error = "Sorry, Something went wrong.";
        }
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        const token = action.payload;
        if (token) {
          state.token = token;
          state.error = null;
          state.loading = false;
        } else {
          state.loading = false;
          state.token = null;
          state.error = "Sorry, Something went wrong.";
        }
      }),
});

export const authSelectors = {
  token: (state: AppState) => state.auth.token,
  error: (state: AppState) => state.auth.error,
  loading: (state: AppState) => state.auth.loading,
};

export const authActions = {
  ...slice.actions,
  getToken,
  refreshToken,
};

export default slice.reducer;
