import { Button, Grid } from "@material-ui/core";
import React from "react";
import { PageEnum } from "../types";

interface Props {
  page: PageEnum;
  loading: boolean;
  onCancelClick: () => void;
  onHandleManually?: () => void;
  handleSubmit: () => void;
  onDoneClick?: () => void;
  isFormValid: boolean;
}

export const Actions = ({
  page,
  loading,
  onCancelClick,
  onHandleManually,
  handleSubmit,
  isFormValid,
  onDoneClick,
}: Props) => {
  if (page === PageEnum.LoadingError) {
    return null;
  }
  return (
    <Grid
      container
      spacing={2}
      justify="flex-end"
      alignItems="center"
      direction="row"
      wrap="nowrap"
    >
      {page === PageEnum.Form && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancelClick}
            disabled={loading}
          >
            Cancel
          </Button>
        </Grid>
      )}
      {page === PageEnum.Form && (
        <Grid item>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !isFormValid}
          >
            Proceed
          </Button>
        </Grid>
      )}
      {page === PageEnum.Success && !!onDoneClick && (
        <Grid item>
          <Button color="primary" disabled={loading} onClick={onDoneClick}>
            Okay
          </Button>
        </Grid>
      )}
      {page === PageEnum.Error && !!onHandleManually && (
        <Grid item>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={onHandleManually}
            color="primary"
          >
            Okay, I will create the invoice manually
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
