import React, { useEffect, useMemo } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getIn, useFormikContext } from "formik";
import moment from "moment";
import { createStyles, makeStyles } from "@material-ui/core/styles";

interface CustomDatePickerProps {
  name: string;
  format?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  disablePast?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiInputBase-root.MuiInputBase-adornedEnd": {
        paddingRight: "0px",
      },
    },
  })
);

export function CustomDatePicker({
  name,
  format = "dd/MM/yyyy",
  label,
  placeholder,
  disabled,
  disablePast = false,
}: CustomDatePickerProps) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const defaultValue = getIn(values, name);

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setFieldValue(name, moment(date).format("YYYY-MM-DD"));
    } else {
      setFieldValue(name, "");
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  return (
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format={format}
      margin="normal"
      label={label}
      value={selectedDate}
      disablePast={disablePast}
      onChange={handleDateChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      placeholder={placeholder}
      disabled={disabled}
      helperText={fieldTouched && fieldError}
      error={fieldTouched && !!fieldError}
      size="small"
      className={classes.root}
      style={{ width: "350px" }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  );
}
