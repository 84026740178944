import { PathwayRecordSearchResult } from "@deep-consulting-solutions/bmh-constants";
import React, { memo, useCallback } from "react";
import { MenuItem } from "@material-ui/core";

import { displayPathwayRecord } from "../Unmatched.helpers";

interface SearchResultProps {
  result: PathwayRecordSearchResult;
  onResultClick: (r: PathwayRecordSearchResult) => any;
}

const SearchResult: React.FC<SearchResultProps> = ({
  result,
  onResultClick,
}) => {
  const onClick = useCallback(() => {
    onResultClick(result);
  }, [result, onResultClick]);

  return <MenuItem onClick={onClick}>{displayPathwayRecord(result)}</MenuItem>;
};

export default memo(SearchResult);
