import * as Yup from "yup";
import React, { useState } from "react";
import { TextField } from "formik-material-ui";
import { Form, Field, Formik, FormikConfig } from "formik";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  DialogActions,
} from "@material-ui/core";

import Loader from "components/Loader";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { zohoServices } from "services";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { useUpdateBluePrint } from "hooks";
import { pathwayRequests } from "redux/pathway";
import { BP_CONFIRM_KIT_INVOICE_CREATION } from "configs";

const useStyles = makeStyles(({ palette: p, spacing: s, typography: t }) => ({
  title: {
    fontWeight: 500,
    color: p.primary.main,
    fontFamily: t.fontFamily,
  },
  content: {
    overflow: "unset",
    marginBottom: s(3),
  },
  text: {
    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
}));

interface ConfirmKitInvoiceFormFields {
  invoiceNumber: string;
}

const validationSchema = Yup.object<ConfirmKitInvoiceFormFields>().shape({
  invoiceNumber: Yup.string().trim().required("Invoice Number is Required"),
});

export const ConfirmKitInvoiceCreation = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [id] = useSelector(zohoSelectors.getIds);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pathwayInfo, setPathwayInfo] = useState({
    bloodTakingOption: "",
    newBloodTestOrderLink: "",
  });

  const closeWidget = () => {
    (async () => {
      await zohoServices.closePopup(true);
    })();
  };

  const handleFormSubmit: FormikConfig<ConfirmKitInvoiceFormFields>["onSubmit"] = async (
    values,
    { setSubmitting }
  ) => {
    try {
      const res = await pathwayRequests.createConfirmKitPackingInvoice(
        id,
        values
      );
      setPathwayInfo(res);
      setShowConfirmation(true);
    } catch {
      //
    } finally {
      setSubmitting(false);
    }
  };

  const { updateBluePrint } = useUpdateBluePrint();

  const handleOkay = async () => {
    setLoading(true);
    await updateBluePrint(BP_CONFIRM_KIT_INVOICE_CREATION);
    closeWidget();
  };

  return (
    <Formik<ConfirmKitInvoiceFormFields>
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
      initialValues={{ invoiceNumber: "" }}
    >
      {({ isValid, submitForm, isSubmitting }) => {
        return (
          <Form>
            <WidgetLayout fullWidth hideBackdrop>
              <Loader open={loading || isSubmitting} />

              <WidgetTitle disableTypography>
                <Typography variant="h5" className={classes.title}>
                  Confirm Kit Invoice Creation
                </Typography>
              </WidgetTitle>

              {!showConfirmation ? (
                <>
                  <WidgetContent className={classes.content}>
                    <Box width="50%">
                      <Field
                        required
                        name="invoiceNumber"
                        component={TextField}
                        label="Retest Kit Invoice Number"
                      />
                    </Box>
                  </WidgetContent>

                  <DialogActions>
                    <Button
                      type="submit"
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        zohoServices.closePopup();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      disabled={!isValid}
                      onClick={submitForm}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <WidgetContent className={classes.content}>
                    <Box className={classes.text}>
                      <Typography>
                        Invoice creation confirmed successfully.
                      </Typography>

                      <Typography>
                        A retest blood test order was created in stage Awaiting
                        Blood Kit Payment.{" "}
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={pathwayInfo.newBloodTestOrderLink}
                        >
                          Link to the new blood test order.
                        </a>
                      </Typography>

                      <Typography>
                        An email was sent to the client to inform him that he
                        needs to pay for the blood draw kit for{" "}
                        {pathwayInfo.bloodTakingOption} test with the invoice
                        attached to it and the payment link.
                      </Typography>
                    </Box>
                  </WidgetContent>

                  <WidgetActions>
                    <Button type="button" color="primary" onClick={handleOkay}>
                      Okay
                    </Button>
                  </WidgetActions>
                </>
              )}
            </WidgetLayout>
          </Form>
        );
      }}
    </Formik>
  );
};
