import moment from "moment";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik, FormikConfig } from "formik";
import {
  getBloodTestOrder,
  getLatestBloodTestInvoice,
  getMeeting,
  getUpcomingConsultations,
  sendBookingLink,
  updateLastPCMDetails,
  updateClientRecord,
  voidLatestBloodTestInvoice,
} from "services/client-checkup";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { notifications, zohoServices } from "services";
import { getClient, getDoctors } from "services/treatments";
import { getDoctorName } from "helpers";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import {
  checkupType,
  defaultValidationSchema,
  stepOneValidation,
  stepThreeValidation,
  stepTwoValidation,
} from "./constants";
import { ActionButtons } from "./ActionButtons";
import { InitialValues } from "./types";
import { ActionButtonsAlt } from "./ActionButtonsAlt";
import {
  findLastPcmMeeting,
  getNextCheckupDate,
  getNextCheckupType,
  isNotificationDateInThePast,
} from "./utils";
import { StepFour, StepOne, StepThree, StepTwo } from "./steps";

export const RegularCheckup = () => {
  const [loading, setLoading] = useState(true);
  const [submitData, setSubmitData] = useState(false);
  const [contactId] = useSelector(zohoSelectors.getIds);
  const [activeStep, setActiveStep] = React.useState(0);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState("");

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const validationSchema = useMemo(() => {
    if (activeStep === 0) {
      return stepOneValidation;
    }
    if (activeStep === 1) {
      return stepTwoValidation;
    }
    if (activeStep === 2) {
      return stepThreeValidation;
    }
    return defaultValidationSchema;
  }, [activeStep]);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        const purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn = [
          "Discuss Blood Test Results",
          "6 weeks check in",
        ].includes(values.purposeOfLastConsultation);

        const isRecentBloodTestAcceptable =
          values.isRecentBloodTestAcceptable === "Yes";
        const actionTypeIsUpdateDetails =
          values.checkupActionType === "update-details";

        const shouldSendDoctorInvite = values.shouldSendDoctorInvite === "Yes";

        if (activeStep === 0) {
          handleNext();
          return;
        }

        if (
          activeStep === 1 &&
          values.checkupActionType === "update-checkup-date"
        ) {
          // Update next checkup data
          const payload = {
            contactZohoId: contactId,
            nextCheckupDate: moment(values.nextCheckupDate).format(
              "YYYY-MM-DD"
            ),
          };
          setSubmitData(true);
          await updateClientRecord(payload);
          closeWidget(true);
          return;
        }

        if (activeStep === 1 && values.checkupActionType === "cancel-checkup") {
          if (
            values.latestBloodTestOrderInvoice &&
            !values.isLatestBloodTestInvoicePaid
          ) {
            const payload = {
              invoiceId: values.latestBloodTestOrderInvoice?.invoice_id,
            };
            setSubmitData(true);
            await voidLatestBloodTestInvoice(payload);
            return;
          }
          if (values.hasUpcomingDoctorConsultation) {
            closeWidget(true);
            return;
          }
          const currentCheckupDate = values.nextCheckupDate;
          const currentCheckupType = values.nextCheckupType;
          const payload = {
            contactZohoId: contactId,
            nextCheckupDate: getNextCheckupDate(
              currentCheckupDate,
              currentCheckupType
            ),
            nextCheckupType: getNextCheckupType(currentCheckupType),
            lastCheckuptype: currentCheckupType,
          };
          setSubmitData(true);
          await updateClientRecord(payload);
          closeWidget();
        }

        if (activeStep === 1 && actionTypeIsUpdateDetails) {
          if (!purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn) {
            const payload = {
              clientId: contactId,
              clientNotes: values.clientNotes,
              lastPurposeBloodTestOr6wCheckin: false,
            };
            // Perform save action
            setSubmitData(true);
            await updateLastPCMDetails(payload);
            closeWidget(true);
            return;
          }
          if (
            purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
            !isRecentBloodTestAcceptable
          ) {
            const payload = {
              clientId: contactId,
              isRecentBloodTestAcceptable: false,
              clientNotes: values.clientNotes,
              sendInviteToDoctorConsult: false,
              lastPurposeBloodTestOr6wCheckin: true,
            };
            // Perform Request Client to Repeat Blood Test
            await updateLastPCMDetails(payload);
            handleNext();
            return;
          }
          if (
            purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
            isRecentBloodTestAcceptable &&
            shouldSendDoctorInvite
          ) {
            handleNext();
            return;
          }
          if (
            purposeOfLastConsultationIsBloodTestOrSixWeekCheckIn &&
            isRecentBloodTestAcceptable &&
            !shouldSendDoctorInvite
          ) {
            const payload = {
              clientId: contactId,
              isRecentBloodTestAcceptable,
              clientNotes: values.clientNotes,
              sendInviteToDoctorConsult: shouldSendDoctorInvite,
              lastPurposeBloodTestOr6wCheckin: true,
            };
            // Perform Save Action
            setSubmitData(true);
            await updateLastPCMDetails(payload);
            closeWidget(true);
            return;
          }
        }

        if (activeStep === 2) {
          // Send Booking link
          const payload = {
            contactZohoId: contactId,
            doctorZohoId: values.doctor,
            note: values.clientNotes,
          };
          await sendBookingLink(payload);
          handleNext();
          return;
        }

        if (activeStep === 3) {
          closeWidget(true);
          return;
        }
      } catch (error) {
        notifications.notifyError(
          "Something went wrong and the action could not be completed."
        );
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [activeStep, closeWidget, contactId, handleNext]
  );

  const fetchData = useCallback(async () => {
    try {
      const [
        doctorsRes,
        client,
        meetings,
        consultations,
        bloodTestOrders,
        latestBloodTestOrderInvoice,
      ] = await Promise.all([
        getDoctors(),
        getClient(contactId),
        getMeeting(contactId),
        getUpcomingConsultations(contactId),
        getBloodTestOrder(contactId),
        getLatestBloodTestInvoice(contactId),
      ]);
      const doctors = doctorsRes.map((d) => ({
        value: d.id,
        label: getDoctorName(d),
      }));
      const hasUpcomingDoctorConsultation = consultations?.meetings?.length > 0;
      const formattedMeetings = meetings.map((m) => ({
        id: m?.id,
        End_DateTime: m?.End_DateTime,
        Start_DateTime: m?.Start_DateTime,
        Purpose_of_Consultation: m.Purpose_of_Consultation,
        Meeting_type: m?.Meeting_type,
      }));
      const lastPcmMeeting = findLastPcmMeeting(formattedMeetings);
      const associatedBloodTestOrder = bloodTestOrders.find(
        (b) =>
          b.Source_Invoice_Number ===
          latestBloodTestOrderInvoice?.invoice_number
      );

      const isNotificationDateForNextConsultPast = client
        ? isNotificationDateInThePast(client)
        : true;

      const checkupActionTypeOptions = [];

      if (lastPcmMeeting) {
        checkupActionTypeOptions.push({
          value: "update-details" as const,
          label: "Update Details of Last PCM Consultation",
        });
      }

      if (client?.Next_Check_up_Type) {
        checkupActionTypeOptions.push({
          value: "update-checkup-date" as const,
          label: "Update Next Check-up Date",
        });
        checkupActionTypeOptions.push({
          value: "cancel-checkup" as const,
          label: "Cancel Check-up",
        });
      }

      if (checkupActionTypeOptions.length === 0) {
        setErrorMessage(`
          No actions can be performed on this widget because client does not have any 
          last pcm consultation or next-checkup-type.
        `);
        return;
      }

      const initValues = {
        checkupActionTypeOptions,
        doctors,
        checkupActionType: checkupActionTypeOptions[0].value,
        purposeOfLastConsultation:
          lastPcmMeeting?.Purpose_of_Consultation || "",
        isRecentBloodTestAcceptable: null,
        clientNotes: "",
        shouldSendDoctorInvite: null,
        doctor: "",
        nextCheckupType: client?.Next_Check_up_Type || checkupType.emptyValue,
        nextCheckupDate:
          client?.Next_Check_up_Date ?? moment().format("YYYY-MM-DD"),
        isNotificationDateForNextConsultPast,
        latestBloodTestOrderInvoice,
        isLatestBloodTestInvoicePaid:
          latestBloodTestOrderInvoice?.status === "paid",
        hasUpcomingDoctorConsultation,
        associatedBloodTestOrder,
      };

      setInitialValues(initValues);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [contactId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle isCustomButton title="Manage Client Checkup" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading && (
            <Box
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "450px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && errorMessage && (
            <Alert severity="warning">{errorMessage}</Alert>
          )}
          {!loading && initialValues && (
            <>
              {activeStep === 0 && <StepOne />}
              {activeStep === 1 && <StepTwo />}
              {activeStep === 2 && <StepThree />}
              {activeStep === 3 && <StepFour />}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && initialValues && (
          <TreatmentWidgetActions>
            <ActionButtonsAlt activeStep={activeStep} goBack={handleBack} />
            <ActionButtons activeStep={activeStep} goBack={handleBack} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
