/* eslint-disable react/no-danger */
import React, { useMemo, useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import { Field } from "formik";
import { GETPathwaySendInstructionsScenarioRes } from "@deep-consulting-solutions/bmh-constants";

import { usePathwayRecord, useGetPathwayClients } from "hooks";
import { pathwayRequests } from "redux/pathway";

import { getBloodDrawFeeTexts, getInstructionsForScenarioRes } from "./helpers";

export const useSendInstructionScenario = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [scenarioData, setScenarioData] = useState<
    GETPathwaySendInstructionsScenarioRes["data"] | null
  >(null);
  const [clientInstructions, setClientInstructions] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);

  const { pathway } = usePathwayRecord();
  const { clientArray, ids } = useGetPathwayClients({ setLoading });

  const feeText = useMemo(() => {
    if (!scenarioData) return null;
    if (!pathway.Blood_Draw_Fee_Collected && pathway.BD_Prepaid_by_BMH) {
      return getBloodDrawFeeTexts(scenarioData);
    }
    return null;
  }, [
    scenarioData,
    pathway.Blood_Draw_Fee_Collected,
    pathway.BD_Prepaid_by_BMH,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const res = await pathwayRequests.fetchPathwaySendInstructionsScenario(
          ids[0]
        );
        setScenarioData(res);
        setClientInstructions(getInstructionsForScenarioRes(res));
      } catch (err) {
        setHasError(true);
      } finally {
        setInitialLoading(false);
      }
    })();
  }, [ids]);

  return {
    initialLoading,
    loading,
    setLoading,
    scenarioData,
    clientInstructions,
    hasError,
    pathway,
    clientArray,
    ids,
    feeText,
  };
};

const useStyles = makeStyles(
  ({ spacing: s, typography: typo, palette: p }) => ({
    feeWrapper: {
      marginBottom: s(2),
      paddingBottom: s(2),
      borderBottom: `1px solid ${p.grey[300]}`,
    },
    feeTitle: {
      color: p.error.main,
      fontWeight: 600,
    },
    feeContent: {
      color: p.error.main,
      marginTop: s(1),
      ...typo.body1,
    },
    feeCollected: {
      marginTop: s(2),
      fontSize: typo.caption.fontSize,
    },
    feeLabel: {
      ...typo.body2,
    },
  })
);

interface FreeTextProps {
  header: string;
  content: string[];
}

export const FeeText: React.FC<FreeTextProps> = ({ header, content }) => {
  const classes = useStyles();

  return (
    <div className={classes.feeWrapper}>
      <Typography
        variant="h5"
        color="error"
        align="center"
        className={classes.feeTitle}
      >
        {header}
      </Typography>
      <div className={classes.feeContent}>
        {content.map((text) => (
          <div
            key={text}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ))}
      </div>
      <div className={classes.feeCollected}>
        <Field
          name="feeCollected"
          component={CheckboxWithLabel}
          Label={{
            label:
              "I confirm that either the Blood Draw Fee has been collected or waived by management. (REQUIRED)",
            classes: {
              label: classes.feeLabel,
            },
          }}
          type="checkbox"
          color="primary"
        />
      </div>
    </div>
  );
};

const useScenarioTextStyles = makeStyles(({ spacing: s, palette: p }) => ({
  clientInstructionsTitle: {
    margin: s(2, 0),
    lineHeight: "20px",
    letterSpacing: "0.4px",
  },
  clientInstructions: {
    marginBottom: s(4),
    fontSize: "0.75rem",
    lineHeight: "20px",
    letterSpacing: "0.4px",
    color: p.text.secondary,
  },
  clientInstruction: {
    margin: s(1, 0),
    whiteSpace: "pre-line",
  },
}));

interface ScenarioTextProps {
  instructions: string[];
}

export const ScenarioText: React.FC<ScenarioTextProps> = ({ instructions }) => {
  const classes = useScenarioTextStyles();

  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.clientInstructionsTitle}
      >
        You are about to send the client instructions for blood test. The
        following will be communicated to the client in a system generated
        email:
      </Typography>
      <ul className={classes.clientInstructions}>
        {instructions.map((clientInstruction) => (
          <li key={clientInstruction} className={classes.clientInstruction}>
            <div
              dangerouslySetInnerHTML={{
                __html: clientInstruction,
              }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
