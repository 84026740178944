import { AppBar, Box, Grid, Tab, Tabs, makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import {
  ZohoPharmacy,
  FinalizeMatchingProductMedicine,
  ZohoProduct,
  FinalizeZohoOtherTreatmentMedicine,
  FinalizeMatchingMedPackage,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
  AutoCompleteOption,
  FinalizeWidgetValues,
  ZohoMedUsageUnit,
} from "types";
import {
  BillingCycleUnitField,
  PharmaciesField,
  FinalizeTreatmentBillingCycleValidationInfo,
} from "components/Treatments";
import { useFormikContext } from "formik";
import {
  getIsSelectedMatchingProductsDefaultBillingCyclesFailed,
  getMatchingProductsSelectedValues,
  getValueFromObject,
} from "helpers";
import { TabPanel } from "./TabPanel";
import { NoCombinationForms } from "./NoCombinationForms";
import { MatchingProductsForms } from "./MatchingProductsForms";
import { SupplementaryProductsForm } from "./SupplementaryProductsForm";
import { OtherTreatmentMedicinesForm } from "./OtherTreatmentMedicinesForm";
import { MatchingPackagesForms } from "./MatchingPackagesForms";
import { FinalizeTreatmentInfo } from "./FinalizeTreatmentInfo";

const useStyles = makeStyles(() => ({
  tab: {
    width: "fit-content",
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

interface Props {
  setActiveTab: (activeTab: number) => void;
  activeTab: number;
  pharmacies: ZohoPharmacy[];
  suggestedMedicines: (ZohoTreatmentMedicine & {
    treatmentArea: ZohoTreatmentTreatmentArea;
  })[];
  supplementaryProducts: ZohoProduct[];
  matchingPackages: FinalizeMatchingMedPackage[];
  matchingProducts: FinalizeMatchingProductMedicine[];
  productsUrl: string;
  bacteriostaticProducts: ZohoProduct[];
  otherTreatmentMedicines: FinalizeZohoOtherTreatmentMedicine[];
  otherProducts: ZohoProduct[];
  medUsageUnits: ZohoMedUsageUnit[];
  clientMobile?: string;
  clientMobile2?: string;
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  isNoCombination: boolean;
  isMatchingProducts: boolean;
  isMatchingPackages: boolean;
}

export const FinalizeTreatmentForms = ({
  activeTab,
  setActiveTab,
  matchingPackages,
  matchingProducts,
  pharmacies,
  productsUrl,
  bacteriostaticProducts,
  supplementaryProducts,
  otherTreatmentMedicines,
  otherProducts,
  suggestedMedicines,
  medUsageUnits,
  clientMobile,
  clientMobile2,
  treatmentTreatmentAreasById,
  isNoCombination,
  isMatchingProducts,
  isMatchingPackages,
}: Props) => {
  const classes = useStyles();

  const { values } = useFormikContext();

  const handleChange = useCallback(
    (event, newValue: number) => {
      setActiveTab(newValue);
    },
    [setActiveTab]
  );

  const matchingProductsValue: FinalizeWidgetValues["matchingProducts"] = useMemo(() => {
    return getValueFromObject("matchingProducts", values);
  }, [values]);

  const selectedPackageValueId: string = useMemo(() => {
    return getValueFromObject("selectedPackage", values);
  }, [values]);

  const allPharmacies: AutoCompleteOption[] = useMemo(() => {
    return getValueFromObject("pharmacies", values);
  }, [values]);

  const selectedPharmacies: AutoCompleteOption[] = useMemo(() => {
    if (isNoCombination) {
      return allPharmacies;
    }
    const allowedPharmaciesIds: string[] = [];
    if (isMatchingPackages) {
      const selectedPackageValue = matchingPackages.find(
        (v) => v.id === selectedPackageValueId
      );
      selectedPackageValue?.products.forEach((product) => {
        allowedPharmaciesIds.push(
          product.matchingMedProduct.matchingMedProduct.product.Pharmacy.id
        );
      });
    } else {
      const selectedMatchingProductsIds = matchingProductsValue.map(
        (matchingProductValue) => matchingProductValue.selectedProduct
      );
      matchingProducts.forEach((matchingProduct) => {
        matchingProduct.products.forEach((product) => {
          if (selectedMatchingProductsIds.includes(product.id)) {
            allowedPharmaciesIds.push(
              product.matchingProduct.product.Pharmacy.id
            );
          }
        });
      });
    }
    return allPharmacies.filter((a) => allowedPharmaciesIds.includes(a.value));
  }, [
    matchingPackages,
    matchingProducts,
    allPharmacies,
    selectedPackageValueId,
    matchingProductsValue,
    isMatchingPackages,
    isNoCombination,
  ]);

  const medUsageUnitsById = useMemo(() => {
    return medUsageUnits.reduce<Record<string, ZohoMedUsageUnit>>(
      (total, medUsageUnit) => {
        return { ...total, [medUsageUnit.id]: medUsageUnit };
      },
      {}
    );
  }, [medUsageUnits]);

  const isSelectedMatchingProductsDefaultBillingCyclesFailed = useMemo(() => {
    if (!isMatchingProducts) {
      return false;
    }
    return getIsSelectedMatchingProductsDefaultBillingCyclesFailed(
      matchingProducts,
      getMatchingProductsSelectedValues(
        (values as FinalizeWidgetValues).matchingProducts
      )
    );
  }, [isMatchingProducts, matchingProducts, values]);

  const manualSelectionIndex = useMemo(() => {
    if (matchingPackages.length && matchingProducts.length) {
      return 2;
    }
    if (matchingPackages.length || matchingProducts.length) {
      return 1;
    }
    return 0;
  }, [matchingPackages.length, matchingProducts.length]);

  return (
    <Box>
      {matchingPackages.length || matchingProducts.length ? (
        <Box mb={4}>
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"
            >
              {matchingPackages.length ? (
                <Tab
                  className={classes.tab}
                  label="Matching Packages"
                  {...a11yProps(0)}
                />
              ) : null}
              {matchingProducts.length ? (
                <Tab
                  className={classes.tab}
                  label="Matching Products"
                  {...a11yProps(matchingPackages.length ? 1 : 0)}
                />
              ) : null}
              <Tab
                className={classes.tab}
                label="Manual Selection"
                {...a11yProps(manualSelectionIndex)}
              />
            </Tabs>
          </AppBar>
        </Box>
      ) : null}
      {isNoCombination ? (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item>
              <PharmaciesField
                zohoPharmacies={pharmacies}
                maxWidth={500}
                helperText="Pharmacies available in client country. Select the pharmacies from which you want to select the products."
              />
            </Grid>
            <Grid item>
              <BillingCycleUnitField />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {matchingPackages.length ? (
        <TabPanel value={activeTab} index={0}>
          <MatchingPackagesForms
            matchingPackages={matchingPackages}
            bacteriostaticProducts={bacteriostaticProducts}
            name="matchingPackages"
            packageFieldName="selectedPackage"
            medUsageUnits={medUsageUnitsById}
            isMatchingPackagesVisible={isMatchingPackages}
          />
        </TabPanel>
      ) : null}
      {matchingProducts.length ? (
        <TabPanel value={activeTab} index={matchingPackages.length ? 1 : 0}>
          <MatchingProductsForms
            matchingProducts={matchingProducts}
            productsUrl={productsUrl}
            bacteriostaticProducts={bacteriostaticProducts}
            name="matchingProducts"
            medUsageUnits={medUsageUnitsById}
            isMatchingProductVisible={isMatchingProducts}
            isSelectedMatchingProductsDefaultBillingCyclesFailed={
              isSelectedMatchingProductsDefaultBillingCyclesFailed
            }
          />
        </TabPanel>
      ) : null}
      {suggestedMedicines.length ? (
        <TabPanel value={activeTab} index={manualSelectionIndex}>
          <NoCombinationForms
            suggestedMedicines={suggestedMedicines}
            treatmentTreatmentAreasById={treatmentTreatmentAreasById}
            bacteriostaticProducts={bacteriostaticProducts}
            medUsageUnits={medUsageUnitsById}
          />
        </TabPanel>
      ) : null}
      {otherTreatmentMedicines.length ? (
        <OtherTreatmentMedicinesForm
          products={otherProducts}
          otherTreatmentMedicines={otherTreatmentMedicines}
          name="otherTreatmentMedicines"
          selectedPharmacies={selectedPharmacies}
          initialSelectedPharmacies={selectedPharmacies}
          medUsageUnits={medUsageUnitsById}
          allowChangeBillingCycleUnit={
            isMatchingProducts && matchingProducts.length > 1
          }
        />
      ) : null}
      <SupplementaryProductsForm
        products={supplementaryProducts}
        selectedPharmacies={selectedPharmacies}
        initialSelectedPharmacies={selectedPharmacies}
        medUsageUnits={medUsageUnitsById}
        allowChangeBillingCycleUnit={
          isMatchingProducts && matchingProducts.length > 1
        }
      />
      {!isMatchingProducts ? (
        <Box mb={2}>
          <FinalizeTreatmentInfo
            matchingPackagesLength={matchingPackages.length}
            clientMobile={clientMobile}
            clientMobile2={clientMobile2}
            matchingProductsLength={matchingProducts.length}
          />
        </Box>
      ) : null}
      <FinalizeTreatmentBillingCycleValidationInfo
        isNoCombination={isNoCombination}
        isMatchingProduct={isMatchingProducts}
        isMatchingPackages={isMatchingPackages}
        isSelectedMatchingProductsDefaultBillingCyclesFailed={
          isSelectedMatchingProductsDefaultBillingCyclesFailed
        }
        matchingProducts={matchingProducts}
      />
    </Box>
  );
};
