import React from "react";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  ROUTE_GET_EMAIL_LOGS_BY_CONTACT,
  EmailLogsWidget,
} from "@deep-consulting-solutions/email-logs-web";

import { COMPANY_NAME } from "configs";

const getAPIUrl = (id: string) => {
  return `${
    process.env.REACT_APP_BASE_URL
  }${ROUTE_GET_EMAIL_LOGS_BY_CONTACT.replace(":zohoID", id)}`;
};

export const ContactEmailLogs = () => {
  const ids = useSelector(zohoSelectors.getIds);

  return (
    <EmailLogsWidget
      apiUrl={getAPIUrl(ids[0])}
      rowsPerPage={5}
      showSubtitle
      companyName={COMPANY_NAME}
    />
  );
};
