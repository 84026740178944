import React, { useMemo, memo } from "react";
import { makeStyles, TableCellProps, Tooltip, Box } from "@material-ui/core";
import { InfoOutlined as WarningIcon } from "@material-ui/icons";
import { AbnormalFlag } from "@deep-consulting-solutions/bmh-constants";

import { TableCell } from "./TestResults.styles";
import { shouldDisplayAbnormalFlag } from "./TestResults.helpers";

interface StyleProps {
  abnormal: boolean;
  isFailed?: boolean;
}

const useStyle = makeStyles(({ palette: p, typography: typo, spacing: s }) => ({
  wrapper: ({ abnormal, isFailed }: StyleProps) => {
    let color = "transparent";

    if (abnormal) {
      color = p.error.light;
    } else if (isFailed) {
      color = p.warning.light;
    }

    return {
      borderLeft: `10px solid ${color}`,
      width: "40px",
    };
  },
  inner: {
    display: "flex",
    alignItems: "center",
  },
  icon: ({ abnormal, isFailed }: StyleProps) => ({
    opacity: abnormal || isFailed ? 1 : 0,
    fontSize: typo.body1.fontSize,
    marginLeft: s(1),
  }),
}));

interface WarningCellProps extends TableCellProps {
  abnormal?: AbnormalFlag;
  isFailed?: boolean;
}

const WarningCell: React.FC<WarningCellProps> = ({
  abnormal: inputAbnormal,
  isFailed,
  className,
  ...props
}) => {
  const abnormalFlag = useMemo(
    () =>
      shouldDisplayAbnormalFlag(inputAbnormal) ? inputAbnormal : undefined,
    [inputAbnormal]
  );

  const title = useMemo(() => {
    if (abnormalFlag) {
      return `Abnormal flag: ${abnormalFlag.flag}${
        abnormalFlag.meaning ? ` (${abnormalFlag.meaning})` : ""
      }`;
    }
    if (isFailed) {
      return `This is a failure code`;
    }
    return "";
  }, [abnormalFlag, isFailed]);

  const classes = useStyle({ abnormal: !!abnormalFlag, isFailed });
  const fullClassName = `${classes.wrapper} ${className || ""}`;

  return (
    <Tooltip title={title}>
      <TableCell className={fullClassName} {...props}>
        <Box className={classes.inner}>
          <WarningIcon className={classes.icon} />
        </Box>
      </TableCell>
    </Tooltip>
  );
};

export default memo(WarningCell);
