import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useCallback, useMemo } from "react";
import { SupplementaryProductValues } from "types";
import { ProductFields } from "./ProductFields";
import { useStyles } from "../MedicineForm/styles";
import { OtherFieldsProductDetails } from "./OtherFields";

interface SupplementaryProductProps {
  name?: string;
  products: OtherFieldsProductDetails[];
}

export const SupplementaryProduct: React.FC<SupplementaryProductProps> = ({
  name,
  products,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("supplementaryProducts", name), [
    name,
  ]);

  const supplemenaryProductsValue: SupplementaryProductValues[] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const addSupplementaryProduct = useCallback(() => {
    const newItem: SupplementaryProductValues = {
      product: null,
      customUnitPrice: null,
      onlyOnce: false,
      selfSupply: false,
      period: null,
      quantity: null,
      keyId: Math.random().toString(),
      billingCycleUnit: null,
    };

    const newValue = supplemenaryProductsValue.concat(newItem);
    setFieldValue(fieldName, newValue);
  }, [supplemenaryProductsValue, fieldName, setFieldValue]);

  const deleteSupplementaryProduct = useCallback(
    (productIndex: number) => () => {
      const newValue = supplemenaryProductsValue.filter(
        (_, i) => i !== productIndex
      );
      setFieldValue(fieldName, newValue);
    },
    [supplemenaryProductsValue, fieldName, setFieldValue]
  );

  return (
    <Box>
      <Button
        onClick={addSupplementaryProduct}
        variant="outlined"
        color="primary"
      >
        Add Supplementary Products
      </Button>

      <Box mt={2}>
        {supplemenaryProductsValue.map((_, index) => (
          <Box className={classes.fieldsSection} key={_?.keyId}>
            <ProductFields
              name={`${fieldName}.${index}`}
              products={products}
              isSupplementary
              deleteProduct={deleteSupplementaryProduct(index)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
