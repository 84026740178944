import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

import { useGetClient } from "hooks";
import AssignMcm from "../AssignMcm";

const SingleContact = () => {
  const [loading, setLoading] = useState(true);

  const { client } = useGetClient({ setLoading });

  if (!loading && !client) {
    return (
      <Box p={8}>
        <Typography variant="body1" color="textSecondary">
          Something went wrong!
        </Typography>
      </Box>
    );
  }

  return <AssignMcm clientLoading={loading} client={client} />;
};

export default SingleContact;
