import React, { memo, useState, useCallback, useMemo } from "react";
import {
  makeStyles,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  BloodTestResult,
  BloodTestResultItemHistory,
} from "@deep-consulting-solutions/bmh-constants";

import { BackButton, ViewWrapperBox } from "../TestResults.styles";
import {
  genHistoricalData,
  genHistoricalDataFromItems,
  sortByCollectedDate,
} from "../TestResults.helpers";

import {
  HISTORY_VIEW_TABLE_META,
  HistoryViewTableField,
} from "./HistoryView.types";
import HistoryViewTableHeader from "./HistoryViewTableHeader";
import HistoryViewRow from "./HistoryViewRow";

const useStyle = makeStyles(() => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fakeBtn: {
    opacity: 0,
  },
  tableContainer: {
    flex: 1,
  },
}));

interface HistoryViewProps {
  selected?: string;
  results?: BloodTestResult[];
  items?: BloodTestResultItemHistory[];
  goBack: () => any;
}

const HistoryView: React.FC<HistoryViewProps> = ({
  selected,
  results,
  items,
  goBack,
}) => {
  const [sortField, setSortField] = useState<HistoryViewTableField>(
    "collected"
  );
  const [sortDesc, setSortDesc] = useState(true);

  const { data, hasWarning } = useMemo(() => {
    if (results && selected) {
      return genHistoricalData(results, selected);
    }
    return genHistoricalDataFromItems(items || []);
  }, [results, selected, items]);

  const sortedItems = useMemo(() => {
    const cloned = [...data];

    return cloned.sort((a, b) => {
      return sortByCollectedDate(a, b, sortDesc);
    });
  }, [data, sortDesc]);

  const onSortClick = useCallback((field: HistoryViewTableField) => {
    setSortField((currentField) => {
      setSortDesc((currentSort) => {
        return currentField === field ? !currentSort : true;
      });
      return field;
    });
  }, []);

  const classes = useStyle();

  return (
    <ViewWrapperBox>
      <Box className={classes.topBar}>
        <BackButton onClick={goBack} />
        <Typography variant="h5">{sortedItems[0]?.name || ""}</Typography>
        <BackButton onClick={() => {}} className={classes.fakeBtn} disabled />
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {hasWarning && <HistoryViewTableHeader />}
              {Object.keys(HISTORY_VIEW_TABLE_META).map((f) => {
                return (
                  <HistoryViewTableHeader
                    key={f}
                    field={f as HistoryViewTableField}
                    desc={sortField === f ? sortDesc : undefined}
                    onClick={onSortClick}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => {
              return (
                <HistoryViewRow
                  key={item.id}
                  item={item}
                  hasWarning={hasWarning}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ViewWrapperBox>
  );
};

export default memo(HistoryView);
