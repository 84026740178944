import { format } from "date-fns";

import {
  DocumentOwnershipEnum,
  DocumentStatusEnum,
  DocumentStatusFilters,
  DocumentTypeEnum,
  DocumentTypeFilters,
  OwnershipFilters,
} from "./interface";

export const getInitialStatusFilters = (statusStr?: string) => {
  const map: { [key in DocumentStatusEnum]?: boolean } = {};

  if (statusStr !== "nil") {
    statusStr?.split(",").forEach((s) => {
      map[s as DocumentStatusEnum] = true;
    });
  }

  const statusFilters: DocumentStatusFilters = (() => {
    const obj: Record<string, boolean> = {};

    Object.values(DocumentStatusEnum).forEach((value) => {
      obj[value] = false;
    });

    return obj as DocumentStatusFilters;
  })();

  Object.keys(statusFilters).forEach((s) => {
    const status = s as DocumentStatusEnum;
    statusFilters[status] = statusStr ? !!map[status] : false;
  });

  return statusFilters;
};

export const getInitialOwnershipFilters = (ownershipStr?: string) => {
  const map: { [key in DocumentOwnershipEnum]?: boolean } = {};

  if (ownershipStr !== "nil") {
    ownershipStr?.split(",").forEach((s) => {
      map[s as DocumentOwnershipEnum] = true;
    });
  }

  const ownershipFilters: OwnershipFilters = (() => {
    const obj: Record<string, boolean> = {};

    Object.values(DocumentOwnershipEnum).forEach((value) => {
      obj[value] = false;
    });

    return obj as OwnershipFilters;
  })();

  Object.keys(ownershipFilters).forEach((s) => {
    const ownership = s as DocumentOwnershipEnum;
    ownershipFilters[ownership] = ownershipStr ? !!map[ownership] : false;
  });

  return ownershipFilters;
};

export const getInitialTypeFilters = (typeStr?: string) => {
  const map: { [key in DocumentTypeEnum]?: boolean } = {};

  if (typeStr !== "nil") {
    typeStr?.split(",").forEach((s) => {
      map[s as DocumentTypeEnum] = true;
    });
  }

  const typeFilters: DocumentTypeFilters = (() => {
    const obj: Record<string, boolean> = {};

    Object.values(DocumentTypeEnum).forEach((value) => {
      obj[value] = false;
    });

    return obj as DocumentTypeFilters;
  })();

  Object.keys(typeFilters).forEach((s) => {
    const type = s as DocumentTypeEnum;
    typeFilters[type] = typeStr ? !!map[type] : false;
  });

  return typeFilters;
};

export const formatDocumentTime = (time: string) => {
  try {
    return format(new Date(time), "dd LLL yyyy");
    // return format(new Date(time), "dd LLL yyyy hh:mm a");
  } catch (e) {
    return "invalid date";
  }
};

export const StatusToTypeMapping: Record<
  DocumentStatusEnum,
  DocumentTypeEnum[]
> = {
  [DocumentStatusEnum.Generated]: [],
  [DocumentStatusEnum.Pending_Doctor_Signing]: [
    DocumentTypeEnum.Prescription,
    DocumentTypeEnum.CTTP,
  ],
  [DocumentStatusEnum.Signed_by_Doctor]: [
    DocumentTypeEnum.Prescription,
    DocumentTypeEnum.CTTP,
  ],
  [DocumentStatusEnum.Pending_Client_Signing]: [DocumentTypeEnum.CTTP],
  [DocumentStatusEnum.Signed]: [
    DocumentTypeEnum.CTTP,
    DocumentTypeEnum.Injection_Guides,
  ],
};

export const sortAlphabetically = (list: string[]): string[] => {
  return list.sort((a, b) => a.localeCompare(b));
};
