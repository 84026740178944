import { useFormikContext } from "formik";
import { getFinalizeTreatmentBillingCycleValidation } from "helpers";
import React, { useMemo } from "react";

import { FinalizeMatchingProductMedicine } from "types";
import { BillingCycleValidationInfo } from "./BillingCycleValidationInfo";

interface Props {
  name?: string;
  isNoCombination: boolean;
  isMatchingProduct: boolean;
  isMatchingPackages: boolean;
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean;
  matchingProducts: FinalizeMatchingProductMedicine[];
}

export const FinalizeTreatmentBillingCycleValidationInfo = ({
  name = "",
  isNoCombination,
  isMatchingProduct,
  isMatchingPackages,
  isSelectedMatchingProductsDefaultBillingCyclesFailed,
  matchingProducts,
}: Props) => {
  const { values } = useFormikContext();

  const { selfSupplyFailed, isMultipleFailed } = useMemo(
    () =>
      getFinalizeTreatmentBillingCycleValidation(
        values,
        name,
        isNoCombination,
        isMatchingProduct,
        isMatchingPackages,
        isSelectedMatchingProductsDefaultBillingCyclesFailed,
        matchingProducts
      ),
    [
      values,
      name,
      isNoCombination,
      isMatchingProduct,
      isMatchingPackages,
      isSelectedMatchingProductsDefaultBillingCyclesFailed,
      matchingProducts,
    ]
  );

  return (
    <BillingCycleValidationInfo
      selfSupplyFailed={selfSupplyFailed}
      isMultipleFailed={isMultipleFailed}
    />
  );
};
