import axios from "axios";

const client = axios.create({ baseURL: process.env.REACT_APP_CRH_BASE_URL });
const silentAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_CRH_BASE_URL,
});

export const setCRHAuthorizationHeader = (token: string) => {
  client.defaults.headers.Authorization = `Bearer ${token}`;
  silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeCRHAuthorizationHeader = () => {
  delete client.defaults.headers.Authorization;
  delete silentAxiosClient.defaults.headers.Authorization;
};

export const crhApiClient = client;
export const crhSilentClient = silentAxiosClient;
