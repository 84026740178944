import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useState } from "react";
import { RequestChangesDialog } from "./RequestChangesDialog";

interface Props {
  view: "fetch-error" | "form";
  onSubmit: () => Promise<void>;
  requestChanges: (values: any) => Promise<void>;
}

const useStyles = makeStyles(({ palette: p }) => ({
  dangerBtn: {
    borderColor: p.error.main,
    color: p.error.main,
  },
  lastItem: {
    paddingRight: "0px !important",
  },
}));

export const PerformFinalChecksActions: React.FC<Props> = ({
  view,
  onSubmit,
  requestChanges,
}) => {
  const classes = useStyles();
  const [isRequestChangesOpen, setIsRequestChangesOpen] = useState(false);
  const { handleClose } = useTreatmentWidgetLayout();

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const onApproveDispensedOrder = useCallback(async () => {
    await onSubmit();
  }, [onSubmit]);

  const toggleChangesDialog = useCallback(
    (isOpen: boolean) => () => {
      setIsRequestChangesOpen(isOpen);
    },
    []
  );

  const onRequestChanges = useCallback(
    (changes: string) => async () => {
      toggleChangesDialog(false)();
      await requestChanges(changes);
    },
    [requestChanges, toggleChangesDialog]
  );

  return (
    <>
      <Box mx={2} maxWidth="100%">
        {" "}
        {["fetch-error"].includes(view) ? (
          <Button variant="outlined" onClick={onClickClose}>
            CLOSE
          </Button>
        ) : null}
        {view === "form" ? (
          <>
            <Grid container justify="space-between" alignItems="center">
              <Grid xs={3} item>
                <Button
                  className={classes.dangerBtn}
                  variant="outlined"
                  onClick={onClickClose}
                  color="primary"
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid xs={9} item>
                <Box textAlign="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleChangesDialog(true)}
                  >
                    Request Changes
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onApproveDispensedOrder}
                  >
                    Approve Dispensed Order
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Box>
      <RequestChangesDialog
        toggleChangesDialog={toggleChangesDialog}
        isOpen={isRequestChangesOpen}
        onRequestChanges={onRequestChanges}
      />
    </>
  );
};
