import { useCallback, useState } from "react";

import { SendGridEmailStatus } from "types";
import { ResizeFn } from "hooks";

import { SendStatusEnum } from "./SendEmailWrapper.types";

export const useSendEmailStatus = ({ resize }: { resize: ResizeFn }) => {
  const [status, setSendStatus] = useState<SendStatusEnum | null>(null);
  const [failures, setFailures] = useState<SendGridEmailStatus[]>([]);

  const sendEmails = useCallback(
    async ({ request }: { request: () => Promise<SendGridEmailStatus[]> }) => {
      setSendStatus(SendStatusEnum.sending);
      resize();

      try {
        const f = await request();

        if (!f.length) {
          setSendStatus(SendStatusEnum.ok);
        } else {
          setFailures(f);
          setSendStatus(SendStatusEnum.error);
        }

        resize();
      } catch (error) {
        setSendStatus(null);
        resize();
      }
    },
    [resize]
  );

  return { status, failures, sendEmails };
};
