import {
  TestProfilesObr,
  Lab,
  BloodDrawer,
  BloodTakingOptionEnum,
  GenderEnum,
  BloodDrawBilledStatus,
  PathwayValidationErrorCodes,
  PatchPathwaySpecifyDetails,
} from "@deep-consulting-solutions/bmh-constants";
import * as Yup from "yup";

import { getENText } from "helpers";

import { Contact, ZohoPathway } from "types";
import { COUNTRIES } from "@deep-consulting-solutions/dcs-web-ui";
import { pathwayRequests } from "redux/pathway";
import { format } from "date-fns";
import { notifications } from "services";
import { DATE_REQUEST_FORMAT } from "configs";
import {
  TestProfilesObrWithMappedLabs,
  FormValues,
  ZohoPathwayTestProfile,
  ZohoVariables,
  PatientSourcedBloodDrawerEnum,
} from "./types";
import {
  getAfterResultConsultationOption,
  getIsCountryAllowed,
  isCountryChanged,
  shouldShowShippingAddress,
  getIsCountryInUnitedKingdom,
} from "../helpers";
import { AfterResultConsultationEnum, OrderTypeEnum } from "../types";

export const mapLabsInTestProfiles = (
  rawTestProfiles?: TestProfilesObr[] | null
): TestProfilesObrWithMappedLabs[] => {
  return (rawTestProfiles || []).map((testProfile) => {
    const mapped: TestProfilesObrWithMappedLabs["mappedLabs"] = {};
    (testProfile.labs || []).forEach(({ id }) => {
      mapped[id] = true;
    });
    return {
      ...testProfile,
      mappedLabs: mapped,
    };
  });
};

export const getTestProfilesFromPathwayTestProfiles = (
  pathwayTestProfiles: ZohoPathwayTestProfile[],
  testProfiles: TestProfilesObrWithMappedLabs[]
) => {
  const mapped: { [bmhObrCode: string]: TestProfilesObrWithMappedLabs } = {};
  testProfiles.forEach((tp) => {
    mapped[tp.bmhObrCode] = tp;
  });

  const results: TestProfilesObrWithMappedLabs[] = [];
  pathwayTestProfiles.forEach((ptp) => {
    if (ptp.Test_Profiles.name && mapped[ptp.Test_Profiles.name]) {
      results.push(mapped[ptp.Test_Profiles.name]);
    }
  });

  return results;
};

export const validateTestProfilesTestToLab = (
  testProfiles: FormValues["testProfiles"],
  lab: FormValues["lab"]
) => {
  if (!lab) return true;

  const invalids: string[] = [];
  testProfiles.forEach((p) => {
    if (!p.mappedLabs[lab.id]) {
      invalids.push(p.bmhObrCode);
    }
  });

  if (!invalids.length) return true;
  return getENText("validation.testProfiles.someNotAssociated", {
    testProfiles: invalids.join(", "),
    toBe: invalids.length > 1 ? "are" : "is",
    lab: lab.name,
  });
};

export const getShouldShowFirstLabField = (
  pathway: ZohoPathway,
  testProfiles: FormValues["testProfiles"],
  shippingCountry: FormValues["shippingCountry"],
  initialValueLab?: Lab
) => {
  if (
    pathway.Created_by_System &&
    pathway.Diagnostic_Laboratory?.id &&
    !pathway.Blood_Drawer?.id &&
    initialValueLab &&
    getIsCountryAllowed(
      shippingCountry,
      initialValueLab.allowedCountries,
      initialValueLab.notAllowedCountries
    ) &&
    validateTestProfilesTestToLab(testProfiles, initialValueLab)
  ) {
    return true;
  }

  return false;
};

export const validateTestProfilesTest = (
  { testProfiles, lab, patientSourcedLab, shippingCountry }: FormValues,
  pathway: ZohoPathway,
  initialValueLab?: Lab
) => {
  return Yup.array()
    .required(getENText("validation.testProfiles.required"))
    .min(1, getENText("validation.testProfiles.required"))
    .test({
      name: "are-associated",
      test() {
        const shouldShowFirstLabField = getShouldShowFirstLabField(
          pathway,
          testProfiles,
          shippingCountry,
          initialValueLab
        );
        const message = validateTestProfilesTestToLab(
          testProfiles,
          shouldShowFirstLabField ? lab : patientSourcedLab
        );
        if (message === true) {
          return true;
        }
        return this.createError({
          message,
          path: "testProfiles",
        });
      },
    });
};

export const getFilteredLabsByCountry = (
  labs: Lab[],
  shippingCountry: string
): Lab[] => {
  return labs.filter((lab) => {
    return getIsCountryAllowed(
      shippingCountry,
      lab.allowedCountries,
      lab.notAllowedCountries
    );
  });
};

export const getFilteredLabsByCountryAndTestProfile = (
  filteredLabsByCountry: Lab[],
  testProfiles: TestProfilesObrWithMappedLabs[]
): Lab[] => {
  return filteredLabsByCountry.filter((filteredLabByCountry) =>
    testProfiles.every(
      (testProfile) => testProfile.mappedLabs[filteredLabByCountry.id]
    )
  );
};

export const getSelectedBloodDrawerLabs = (
  filteredLabsByCountryAndTestProfile: Lab[],
  bloodDrawer: BloodDrawer
): Lab[] => {
  const bloodDrawerLabIds = [
    ...bloodDrawer.associatedLabs,
    ...(bloodDrawer.lab ? [bloodDrawer.lab] : []),
  ].map((lab) => lab.id);

  return filteredLabsByCountryAndTestProfile.filter(
    (filteredLabByCountryAndTestProfile) =>
      bloodDrawerLabIds.includes(filteredLabByCountryAndTestProfile.id)
  );
};

export const getSelectedBloodDrawerDefaultLab = (
  filteredLabsByBloodDrawer: Lab[]
) => {
  if (filteredLabsByBloodDrawer.length === 0) {
    return null;
  }
  if (filteredLabsByBloodDrawer.length === 1) {
    return filteredLabsByBloodDrawer[0];
  }
  const labsSortedByPreference = filteredLabsByBloodDrawer.sort(
    (a, b) => Number(a?.preference || 0) - Number(b?.preference || 0)
  );

  return (
    labsSortedByPreference.find((lab) => Number(lab.preference || 0) > 0) ||
    null
  );
};

export const getValidationSchema = (
  labs: Lab[],
  pathway: ZohoPathway,
  initialValueLab?: Lab
) =>
  Yup.lazy((values: FormValues) => {
    const validationObject: { [key: string]: any } = {
      testProfiles: validateTestProfilesTest(values, pathway, initialValueLab),
      patientSourcedLab: Yup.object().nullable(),
      lab: Yup.object()
        .nullable()
        .test({
          name: "check validation",
          message: getENText("validation.lab.invalid"),
          test(v: unknown) {
            const value = v as Lab | undefined | null;
            if (!value?.id) {
              return true;
            }
            if (
              !getShouldShowFirstLabField(
                pathway,
                this.parent.testProfiles,
                this.parent.shippingCountry,
                initialValueLab
              )
            ) {
              return true;
            }
            if (
              value?.id &&
              getFilteredLabsByCountryAndTestProfile(
                getFilteredLabsByCountry(labs, this.parent.shippingCountry),
                this.parent.testProfiles
              ).find((lab) => lab.id === value.id)
            ) {
              return true;
            }

            return false;
          },
        })
        .test({
          name: "check validation",
          message: getENText("validation.lab.required"),
          test(v: unknown) {
            const value = v as Lab | undefined | null;
            if (
              getShouldShowFirstLabField(
                pathway,
                this.parent.testProfiles,
                this.parent.shippingCountry,
                initialValueLab
              ) &&
              !value?.id
            ) {
              return false;
            }
            return true;
          },
        }),
      shippingCountry: Yup.string()
        .required(getENText("validation.shippingCountry.required"))
        .test({
          name: "check validation",
          message: getENText("validation.shippingCountry.invalid"),
          test(v: unknown) {
            const value = v as string | null | undefined;
            if (
              COUNTRIES.find(
                (country) =>
                  country.label.toLowerCase() === value?.toLowerCase()
              )
            ) {
              return true;
            }

            if (getIsCountryInUnitedKingdom(value)) {
              return true;
            }

            return false;
          },
        }),
      bloodTakingOption: Yup.string().required(
        getENText("validation.bloodTakingOption.required")
      ),
      afterResultsConsultation: Yup.string().required(
        getENText("validation.afterResultsConsultation.required")
      ),
      orderType: Yup.string().when("afterResultsConsultation", {
        is: (afterResultsConsultation: AfterResultConsultationEnum) =>
          afterResultsConsultation !== AfterResultConsultationEnum.NONE,
        then: Yup.string().required(getENText("validation.orderType.required")),
        otherwise: Yup.string().nullable(),
      }),
      gender: Yup.string().required(getENText("validation.gender.required")),
      dob: Yup.string()
        .nullable()
        .test({
          name: "check validation",
          message: getENText("validation.dob.provideValid"),
          test(value: unknown) {
            return !!Date.parse(value as string);
          },
        }),
      shippingCity: Yup.string().nullable(),
      street: Yup.string().nullable(),
      region: Yup.string().nullable(),
      zip: Yup.string().nullable(),
      billedStatus: Yup.string().nullable(),
      billInvoiceNumber: Yup.string().nullable(),
    };

    return (Yup.object(validationObject) as unknown) as Yup.Schema<
      FormValues,
      // eslint-disable-next-line @typescript-eslint/ban-types
      object
    >;
  });

export const isPathwayAddressAvailable = (pathway: ZohoPathway) => {
  return Boolean(
    pathway.Shipping_Country || pathway.Shipping_City || pathway.Shipping_Street
  );
};

export const toBePopulatedAddressData = (
  pathway: ZohoPathway,
  client: Contact
) => {
  if (isPathwayAddressAvailable(pathway)) {
    return {
      street: pathway.Shipping_Street || "",
      shippingCity: pathway.Shipping_City || "",
      city: pathway.Shipping_City || "",
      shippingCountry: pathway.Shipping_Country || "",
      region: pathway.Shipping_Province || "",
      other: pathway.Shipping_Apartment_Flat_Door_Other || "",
      zip: pathway.Shipping_Post_Code || "",
    };
  }

  return {
    street: client?.shippingAddress?.street || "",
    shippingCity: client?.shippingAddress?.city || "",
    city: client?.shippingAddress?.city || "",
    shippingCountry: client?.shippingAddress.country || "",
    region: client?.shippingAddress.region || "",
    other: client?.shippingAddress.street2 || "",
    zip: client?.shippingAddress.zip || "",
  };
};

export const getInitialValues = (
  labs: Lab[],
  pathway: ZohoPathway,
  client: Contact
): FormValues => {
  return {
    testProfiles: [],
    lab:
      labs.find((l) => l.zohoID === pathway.Diagnostic_Laboratory?.id) || null,
    isOnPhone: false,
    bloodDrawer: "",
    labBloodDrawer: "",
    nurseBloodDrawer: "",
    patientSourcedBloodDrawer: PatientSourcedBloodDrawerEnum.PATIENT_SOURCED,
    bloodTakingOption:
      pathway.Blood_Taking_Option || ("" as BloodTakingOptionEnum),
    afterResultsConsultation:
      getAfterResultConsultationOption(
        pathway.Offer_MCM_Call,
        pathway.Doctor_Consultation_Needed
      ) || ("" as AfterResultConsultationEnum),
    gender: client.gender || ("" as GenderEnum),
    dob: client.dob || client.dateOfBirth || "",
    billedStatus: pathway.Billed_Status || ("" as BloodDrawBilledStatus),
    billInvoiceNumber: pathway.Blood_Draw_Invoice_Number || "",
    specialNotes: pathway.Special_Notes || "",
    orderType: pathway.Type_of_Order || ("" as OrderTypeEnum),
    patientSourcedLab: null,
    ...toBePopulatedAddressData(pathway, client),
  };
};

export const getFilteredBloodDrawersBasedOnPreselectedLabs = (
  filteredBloodDrawersAndCountry: BloodDrawer[],
  filteredLabsByCountryAndTestProfile: Lab[],
  preselectedLab?: Lab | null
) => {
  if (preselectedLab) {
    return filteredBloodDrawersAndCountry.filter(
      (filteredBloodDrawerAndCountry) =>
        filteredBloodDrawerAndCountry.lab?.id === preselectedLab?.id ||
        !!filteredBloodDrawerAndCountry.associatedLabs.find(
          (associatedLab) => associatedLab.id === preselectedLab?.id
        )
    );
  }
  return filteredBloodDrawersAndCountry.filter(
    (filteredBloodDrawerAndCountry) =>
      !!filteredLabsByCountryAndTestProfile.find(
        (filteredLabByCountryAndTestProfile) =>
          filteredBloodDrawerAndCountry.lab?.id ===
          filteredLabByCountryAndTestProfile.id
      ) ||
      !!filteredBloodDrawerAndCountry.associatedLabs.find((associatedLab) =>
        filteredLabsByCountryAndTestProfile.find(
          (filteredLabByCountryAndTestProfile) =>
            associatedLab.id === filteredLabByCountryAndTestProfile.id
        )
      )
  );
};

export const getSortedLabBloodDrawers = (bloodDrawers: BloodDrawer[]) => {
  const bloodDrawersAlphabetically = bloodDrawers.sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  });

  const separateByProperties = bloodDrawersAlphabetically.reduce<{
    prepaidByBMHAndProvidesOwnKit: BloodDrawer[];
    prepaidByBMH: BloodDrawer[];
    providesOwnKits: BloodDrawer[];
    others: BloodDrawer[];
  }>(
    (previous, current) => {
      const prepaidByBMHAndProvidesOwnKit =
        !!current.isPrepaid && !!current.providesOwnKit;
      const prepaidByBMH = !!current.isPrepaid && !current.providesOwnKit;
      const providesOwnKits = !current.isPrepaid && !!current.providesOwnKit;

      if (prepaidByBMHAndProvidesOwnKit) {
        return {
          ...previous,
          prepaidByBMHAndProvidesOwnKit: [
            ...previous.prepaidByBMHAndProvidesOwnKit,
            current,
          ],
        };
      }

      if (prepaidByBMH) {
        return {
          ...previous,
          prepaidByBMH: [...previous.prepaidByBMH, current],
        };
      }

      if (providesOwnKits) {
        return {
          ...previous,
          providesOwnKits: [...previous.providesOwnKits, current],
        };
      }

      return {
        ...previous,
        others: [...previous.others, current],
      };
    },
    {
      prepaidByBMHAndProvidesOwnKit: [],
      prepaidByBMH: [],
      providesOwnKits: [],
      others: [],
    }
  );

  return [
    ...separateByProperties.prepaidByBMHAndProvidesOwnKit,
    ...separateByProperties.prepaidByBMH,
    ...separateByProperties.providesOwnKits,
    ...separateByProperties.others,
  ];
};

export const defaultZohoCRMVariables: Record<ZohoVariables, string> = {
  [ZohoVariables.BD_General]: "",
  [ZohoVariables.BD_Mobile_Yes]: "",
  [ZohoVariables.BD_Prepaid_Yes]: "",
  [ZohoVariables.BD_Prepaid_No]: "",
  [ZohoVariables.BD_Provides_Own_Kits_Yes]: "",
  [ZohoVariables.BD_Provides_Own_Kits_No]: "",
  [ZohoVariables.BD_Takes_Sample_to_the_Lab_Yes]: "",
  [ZohoVariables.BD_Takes_Sample_to_the_Lab_No]: "",
  [ZohoVariables.BD_PATIENT_SOURCED]: "",
  [ZohoVariables.BD_PATIENT_SOURCED_UK]: "",
  [ZohoVariables.BD_UK_MAP]: "",
  [ZohoVariables.BTO_Venepuncture]: "",
  [ZohoVariables.Lab_Selection]: "",
};

export const validateValues = async (
  pathwayZohoId: string,
  testProfiles: TestProfilesObrWithMappedLabs[],
  labID: string,
  bloodDrawerID: string
) => {
  try {
    await pathwayRequests.validatePathwayDetails({
      zohoId: pathwayZohoId,
      ids: {
        testProfileIDs: testProfiles.map((t) => t.id),
        labID,
        bloodDrawerID,
      },
    });
    return true;
  } catch (error: any) {
    const message =
      error?.response?.data?.message || error?.message || "Unknown error";
    switch (message) {
      case PathwayValidationErrorCodes.CODE_TEST_PROFILE_NOT_ASSOCIATED:
        return notifications.notifyError(
          getENText("validation.testProfiles.notAssociated")
        );

      case PathwayValidationErrorCodes.CODE_ASSOCIATION_NOT_ACTIVE:
        return notifications.notifyError(
          getENText("validation.testProfiles.associationNotctive")
        );

      case PathwayValidationErrorCodes.CODE_BLOOD_DRAWER_NOT_CORRESPONDING:
        return notifications.notifyError(
          getENText("validation.lab.notSameAsBloodDrawer")
        );

      case PathwayValidationErrorCodes.CODE_LAB_NOT_EXISTS:
        return notifications.notifyError(getENText("validation.lab.notExist"));

      case PathwayValidationErrorCodes.CODE_BLOOD_DRAWER_NOT_EXISTS:
        return notifications.notifyError(
          getENText("validation.bloodDrawer.notExist")
        );

      case PathwayValidationErrorCodes.CODE_TEST_PROFILE_NOT_EXISTS:
        return notifications.notifyError(
          getENText("validation.testProfiles.notExist")
        );

      default:
        return notifications.notifyError(message);
    }
  }
};

export const isAddressChanged = (
  {
    shippingCity,
    shippingCountry,
    other,
    region,
    street,
    zip,
  }: Pick<
    FormValues,
    "shippingCity" | "shippingCountry" | "other" | "region" | "street" | "zip"
  >,
  pathway: ZohoPathway,
  client: Contact | null
) => {
  if (isPathwayAddressAvailable(pathway)) {
    return (
      shippingCity !== (pathway.Shipping_City || "") ||
      isCountryChanged(shippingCountry, pathway.Shipping_Country) ||
      region !== (pathway.Shipping_Province || "") ||
      other !== (pathway.Shipping_Apartment_Flat_Door_Other || "") ||
      street !== (pathway.Shipping_Street || "") ||
      zip !== (pathway.Shipping_Post_Code || "")
    );
  }
  return (
    street !== (client?.shippingAddress?.street || "") ||
    shippingCity !== (client?.shippingAddress?.city || "") ||
    isCountryChanged(shippingCountry, client?.shippingAddress.country) ||
    region !== (client?.shippingAddress.region || "") ||
    other !== (client?.shippingAddress.street2 || "") ||
    zip !== (client?.shippingAddress.zip || "")
  );
};

export const getShouldShowBillingForm = (
  bloodDrawerFeeCollected?: boolean,
  selectedBloodDrawer?: BloodDrawer
) => {
  return selectedBloodDrawer?.isPrepaid && !bloodDrawerFeeCollected;
};

export const composeSpecifyDetailsBody = (
  values: FormValues,
  client: Contact,
  pathway: ZohoPathway,
  pathwayTestProfileRecords: ZohoPathwayTestProfile[],
  disableCity: boolean,
  bloodDrawer?: BloodDrawer
) => {
  const requireAddress = shouldShowShippingAddress(bloodDrawer);
  const shippingCity = disableCity ? values.shippingCity : values.city;
  const updateClientToo = isAddressChanged(
    {
      ...values,
      shippingCity,
    },
    pathway,
    client
  );

  const pathwayTestProfilesToDelete: string[] = [];
  const testProfilesToLink: string[] = [];
  values.testProfiles.forEach((tp) => {
    if (
      !pathwayTestProfileRecords.find((pt) => pt.Test_Profiles.id === tp.zohoID)
    ) {
      testProfilesToLink.push(tp.zohoID);
    }
  });
  pathwayTestProfileRecords.forEach((r) => {
    if (!values.testProfiles.find((tp) => tp.zohoID === r.Test_Profiles.id)) {
      pathwayTestProfilesToDelete.push(r.id);
    }
  });

  const dob = new Date(values.dob).getTime();

  const labZohoID =
    values.patientSourcedLab?.zohoID || values.lab?.zohoID || "";

  const body: typeof PatchPathwaySpecifyDetails["Body"] = {
    pathway: {
      Diagnostic_Laboratory: { id: labZohoID },
      Blood_Drawer: { id: bloodDrawer?.zohoID || "" },
      Blood_Taking_Option: values.bloodTakingOption,
      Offer_MCM_Call:
        values.afterResultsConsultation === AfterResultConsultationEnum.PCM,
      Doctor_Consultation_Needed:
        values.afterResultsConsultation === AfterResultConsultationEnum.DOCTOR,
      ...(values.afterResultsConsultation !== AfterResultConsultationEnum.NONE
        ? {
            Type_of_Order: values.orderType,
          }
        : {}),
      BD_Category: bloodDrawer?.category,
      BD_Prepaid_by_BMH: bloodDrawer?.isPrepaid,
      BD_Provides_Own_Kits: bloodDrawer?.providesOwnKit,
      Send_Order_Form_to_Blood_Drawer: bloodDrawer?.sendOrderFormToBloodDrawer,
      Special_Notes: values.specialNotes,
      ...(getShouldShowBillingForm(
        pathway.Blood_Draw_Fee_Collected,
        bloodDrawer
      )
        ? {
            Billed_Status: values.billedStatus,
            ...(values.billedStatus === BloodDrawBilledStatus.BILLED
              ? {
                  Blood_Draw_Invoice_Number: values.billInvoiceNumber,
                }
              : {}),
          }
        : {}),
      Shipping_Country: values.shippingCountry, // * always send shipping country
      ...(requireAddress && {
        Shipping_Street: values.street,
        Shipping_City: shippingCity,
        Shipping_Province: values.region,
        Shipping_Post_Code: values.zip,
        Shipping_Apartment_Flat_Door_Other: values.other,
      }),
    },
    contact: {
      zohoId: client.zohoID,
      Date_of_Birth: format(dob, DATE_REQUEST_FORMAT),
      Gender: values.gender === GenderEnum.female ? "Female" : "Male",
      ...(requireAddress &&
        updateClientToo && {
          Shipping_Street: values.street,
          Shipping_Street_2: values.other,
          Shipping_City: shippingCity,
          Shipping_Country: values.shippingCountry,
          Shipping_State: values.region,
          Shipping_Zip: values.zip,
        }),
    },
    pathwayTestProfilesToDelete,
    testProfilesToLink,
  };

  return body;
};

const getPatientSourcedLabError = (
  v: Lab | null,
  labs: Lab[],
  selectedBloodDrawer?: BloodDrawer,
  shouldShowFirstLabField?: boolean
) => {
  const value = v as Lab | undefined | null;

  if (shouldShowFirstLabField || !selectedBloodDrawer) {
    return undefined;
  }
  if (
    value?.id &&
    getSelectedBloodDrawerLabs(labs, selectedBloodDrawer).find(
      (lab) => lab.id === value.id
    )
  ) {
    return undefined;
  }

  return getENText("validation.patientSourcedLab.invalid");
};

const getBilledStatusError = (
  value: string,
  bloodDrawerFeeCollected?: boolean,
  selectedBloodDrawer?: BloodDrawer
) => {
  if (
    getShouldShowBillingForm(bloodDrawerFeeCollected, selectedBloodDrawer) &&
    !value
  ) {
    return getENText("validation.billedStatus.required");
  }

  return undefined;
};

const getBillInvoiceNumberError = (
  values: Pick<FormValues, "billedStatus" | "billInvoiceNumber">,
  bloodDrawerFeeCollected?: boolean,
  selectedBloodDrawer?: BloodDrawer
) => {
  if (
    getShouldShowBillingForm(bloodDrawerFeeCollected, selectedBloodDrawer) &&
    values.billedStatus === BloodDrawBilledStatus.BILLED &&
    !values.billInvoiceNumber
  ) {
    return getENText("validation.billInvoiceNumber.required");
  }

  return undefined;
};

const getAddressErrors = (
  providesOwnKit: boolean,
  values: Pick<FormValues, "city" | "region" | "zip" | "street">
) => {
  if (!providesOwnKit) {
    const getError = (field: "city" | "region" | "zip" | "street") =>
      values[field]
        ? undefined
        : getENText(`validation.${field}.required` as any);
    return {
      city: getError("city"),
      region: getError("region"),
      zip: getError("zip"),
      street: getError("street"),
    };
  }

  return undefined;
};

const getShippingCityError = (
  showOfferingNurseService: boolean,
  value: string
) => {
  if (showOfferingNurseService && !value) {
    return getENText("validation.shippingCity.required");
  }

  return undefined;
};

export const handleExtraValidation = (
  values: Pick<
    FormValues,
    | "billedStatus"
    | "billInvoiceNumber"
    | "patientSourcedLab"
    | "shippingCity"
    | "region"
    | "zip"
    | "street"
    | "city"
  >,
  labs: Lab[],
  showOfferingNurseService: boolean,
  bloodDrawerFeeCollected?: boolean,
  selectedBloodDrawer?: BloodDrawer,
  shouldShowFirstLabField?: boolean,
  disableCity?: boolean
) => {
  const providesOwnKit = !!selectedBloodDrawer?.providesOwnKit;
  const errors: Record<string, string | undefined> = {
    patientSourcedLab: getPatientSourcedLabError(
      values.patientSourcedLab,
      labs,
      selectedBloodDrawer,
      shouldShowFirstLabField
    ),
    billedStatus: getBilledStatusError(
      values.billedStatus,
      bloodDrawerFeeCollected,
      selectedBloodDrawer
    ),
    shippingCity: getShippingCityError(
      showOfferingNurseService,
      values.shippingCity
    ),
    billInvoiceNumber: getBillInvoiceNumberError(
      {
        billedStatus: values.billedStatus,
        billInvoiceNumber: values.billInvoiceNumber,
      },
      bloodDrawerFeeCollected,
      selectedBloodDrawer
    ),
    ...getAddressErrors(providesOwnKit, {
      ...values,
      city: disableCity ? values.shippingCity : values.city,
    }),
  };

  return errors;
};
