import React, { useCallback } from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { AddCircleOutline as AddIcon } from "@material-ui/icons";

import { EMPTY_TEST_ROW, FormResultItem } from "./ResultForm.types";

interface AddBtnProps {
  push: (item: FormResultItem) => any;
  isPathway?: boolean;
}

const AddBtn: React.FC<AddBtnProps> = ({ push }) => {
  const onClick = useCallback(() => {
    push(EMPTY_TEST_ROW);
  }, [push]);

  return (
    <Tooltip title="Add">
      <IconButton color="primary" onClick={onClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AddBtn;
