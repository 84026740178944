import React from "react";
import { PortalsWidget } from "@deep-consulting-solutions/portals-web";
import { useSelector } from "react-redux";

import { zohoSelectors } from "redux/zoho";
import { DATE_DISPLAY_FORMAT } from "configs";

export const ClientAdmin = () => {
  const entityType = useSelector(zohoSelectors.getEntity);
  const [entityId] = useSelector(zohoSelectors.getIds);

  return (
    <PortalsWidget
      baseURL={process.env.REACT_APP_BASE_URL!}
      staticPortals={{
        Customer: {
          display: "Customer Portal",
        },
      }}
      initData={{ entityType, entityId }}
      dateFormat={DATE_DISPLAY_FORMAT}
      tokenAuthMethod="signatureForServerAPIWithUserID"
    />
  );
};
