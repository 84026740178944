import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Contact, ZohoMeetingRecord } from "types";
import { zohoSelectors } from "redux/zoho";
import { fetchClientsFromZohoIDs } from "redux/contacts/requests";

export const useGetMeetingClient = ({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [client, setClient] = useState<Contact>();
  const ids = useSelector(zohoSelectors.getIds);
  const records = useSelector(zohoSelectors.getRecords);
  const meetingId = ids[0];
  const contactId = ((records[meetingId] as unknown) as ZohoMeetingRecord)
    .Who_Id.id;

  const getClient = useCallback(async () => {
    setLoading(true);

    try {
      const data = await fetchClientsFromZohoIDs(contactId);
      setClient(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [contactId, setLoading]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return {
    id: contactId,
    client,
  };
};
