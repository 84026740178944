import React, { useCallback, useMemo, useRef } from "react";
import { Formik, Field, FormikConfig, FormikProps } from "formik";
import * as Yup from "yup";
import { Lab } from "@deep-consulting-solutions/bmh-constants";
import { TableRow, Tooltip } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import { FETestObxCode } from "types";

import { StyledIconButton } from "components/StyledIconButton";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { TableCell } from "../styles";
import { MappedCodeNames } from "../helpers";

export interface FormValues {
  bmhCodeName: string;
  labs: {
    key: string;
    isManual: boolean;
    code: string;
    unit: string;
    observationAltIdentifier: string;
    normalRange: string;
  }[];
}

interface Props {
  labs: Lab[];
  row?: FETestObxCode;
  onUpdateSaveClick?: (values: FormValues, row: FETestObxCode) => any;
  onNewSaveClick?: (values: FormValues) => any;
  onCancelClick: () => any;
  mappedCodeNames: MappedCodeNames;
}

export const FormRow: React.FC<Props> = ({
  labs,
  row,
  onNewSaveClick,
  onUpdateSaveClick,
  onCancelClick,
  mappedCodeNames,
}) => {
  const mapped = useMemo(() => {
    const newMap: {
      bmhCodeNames: { [name: string]: true };
      labs: { [labKey: string]: { [name: string]: true } };
    } = { bmhCodeNames: {}, labs: {} };

    const run = (input: { [id: string]: string }, exceptId?: string) => {
      const cloned = { ...input };
      if (exceptId) delete cloned[exceptId];
      const reverse: { [name: string]: true } = {};
      Object.values(cloned).forEach((v) => {
        reverse[v] = true;
      });
      return reverse;
    };

    newMap.bmhCodeNames = run(mappedCodeNames.bmhCodeNames, row?.id);
    Object.entries(mappedCodeNames.labs).forEach(([labKey, current]) => {
      newMap.labs[labKey] = run(current, row?.id);
    });
    return newMap;
  }, [mappedCodeNames, row?.id]);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const validationSchema = useMemo(() => {
    return Yup.lazy((values: FormValues) => {
      return Yup.object({
        bmhCodeName: Yup.string()
          .required("Required field")
          .test("is unique", "Value already exists", () => {
            return !(
              values.bmhCodeName &&
              mapped.bmhCodeNames[values.bmhCodeName.toLowerCase()]
            );
          }),
        labs: Yup.array().of(
          Yup.lazy((currentItem: FormValues["labs"][0]) => {
            return Yup.object({
              code: Yup.string().test(
                "is unique",
                "Value already exists",
                () => {
                  return !(
                    currentItem.code &&
                    mapped.labs[currentItem.key] &&
                    mapped.labs[currentItem.key][currentItem.code.toLowerCase()]
                  );
                }
              ),
            }).nullable() as any;
          })
        ),
      }).nullable() as any;
    });
  }, [mapped]);

  const handleSubmitClick = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values, helpers) => {
      helpers.setSubmitting(false);
      if (row && onUpdateSaveClick) {
        onUpdateSaveClick(values, row);
      }
      if (!row && onNewSaveClick) {
        onNewSaveClick(values);
      }
    },
    [onUpdateSaveClick, onNewSaveClick, row]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        bmhCodeName: row?.bmhCodeName || "",
        labs: labs.map((l) => {
          const isManual = !!l.isManual;
          const data = row?.labs[l.key];
          if (!data) {
            return {
              key: l.key,
              isManual,
              code: "",
              unit: "",
              observationAltIdentifier: "",
              normalRange: "",
            };
          }
          return {
            key: l.key,
            isManual,
            code: data.code,
            unit: data.unit || "",
            observationAltIdentifier: data.observationAltIdentifier || "",
            normalRange: data.normalRange || "",
          };
        }),
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount
      innerRef={formRef}
    >
      {({ values, isValid }) => {
        return (
          <TableRow>
            <TableCell>
              <Field
                component={TextField}
                name="bmhCodeName"
                required
                placeholder="BMH OBX Code Name"
                size="small"
                style={{ minWidth: "190px" }}
              />
            </TableCell>
            {labs.map((lab, index) => {
              return (
                <React.Fragment key={lab.id}>
                  <TableCell>
                    <Field
                      component={TextField}
                      name={`labs.${index}.code`}
                      placeholder="OBX Code"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {values.labs[index].isManual ? (
                      <Field
                        component={TextField}
                        name={`labs.${index}.observationAltIdentifier`}
                        placeholder="Observation ID"
                        size="small"
                        style={{ minWidth: "135px" }}
                      />
                    ) : (
                      values.labs[index].observationAltIdentifier
                    )}
                  </TableCell>
                  <TableCell>
                    {values.labs[index].isManual ? (
                      <Field
                        component={TextField}
                        name={`labs.${index}.normalRange`}
                        placeholder="Normal Range"
                        size="small"
                        style={{ minWidth: "130px" }}
                      />
                    ) : (
                      values.labs[index].normalRange
                    )}
                  </TableCell>
                  <TableCell>
                    {values.labs[index].isManual ? (
                      <Field
                        component={TextField}
                        name={`labs.${index}.unit`}
                        placeholder="Unit"
                        size="small"
                        style={{ minWidth: "60px" }}
                      />
                    ) : (
                      values.labs[index].unit
                    )}
                  </TableCell>
                </React.Fragment>
              );
            })}
            <TableCell>
              {isValid ? (
                <Tooltip title="Save">
                  <StyledIconButton
                    aria-label="save"
                    color="success"
                    size="small"
                    onClick={handleSubmitClick}
                  >
                    <CheckCircle />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                <StyledIconButton
                  aria-label="save"
                  color="success"
                  disabled
                  size="small"
                >
                  <CheckCircle />
                </StyledIconButton>
              )}

              <Tooltip title="Cancel">
                <StyledIconButton
                  color="error"
                  aria-label="cancel"
                  onClick={onCancelClick}
                  size="small"
                  style={{ marginLeft: 8 }}
                >
                  <Cancel />
                </StyledIconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      }}
    </Formik>
  );
};
