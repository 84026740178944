export const BP_SEND_INSTRUCTIONS_STATE = "Send Instructions";
export const BP_SEND_NS_INSTRUCTIONS_STATE = "Send Instruction";
export const BP_SEND_ORDER_FORM_STATE = "Send Order to BD";
export const BP_SPECIFY_DETAILS_TRANSITION = "Specify Details";
export const BP_SPECIFY_DETAILS_CLOSE_DELAY = 1000; // ms;
export const BP_RETEST_REQUIRED_CLIENT_ERROR = "Retest Required - Client Error";
export const BP_RETEST_REQUIRED_NOT_CLIENT_ERROR =
  "Retest Required - Not Client Error";
export const BP_CONFIRM_KIT_INVOICE_CREATION = "Confirm Kit Invoice Creation";
export const BP_CONFIRM_INVOICE_CREATION = "Confirm Invoice creation";
export const BP_APPROVE_CHARGING_CLIENT_FOR_RETEST =
  "Approve Charging Client for Retest";
export const BP_APPROVE_CHARGING_CLIENT_FOR_RETEST_TO_PENDING_ITEMS_CREATION =
  "Approve Charging Client for Retest!";
export const BP_GENERATE_INVOICE = "Generate Invoice";

/**
 * * FIELD_NAMES
 */
export const BP_FN_RETEST_ARRANGEMENTS_MADE = "Retest Arrangements Made";
export const BP_FN_PENDING_RETEST_INVOICE_CREATION =
  "Pending Retest Invoice Creation";
export const BP_FN_PENDING_ITEMS_CREATION = "Pending Items Creation";
