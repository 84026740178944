import { Box, FormControlLabel, Radio, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Field, useFormikContext } from "formik";
import moment from "moment";
import { RadioGroup } from "formik-material-ui";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import { SignedLegacyCTTP } from "types";
import { useStyles } from "../MedicineForm/styles";

interface CTTPSelectProps {
  name?: string;
  legacyCttps: SignedLegacyCTTP[];
}

interface LegacyCttpProps {
  name: string;
  date: string;
  link: string;
}

export const LegacyCttp: React.FC<LegacyCttpProps> = ({ name, date, link }) => {
  const classes = useStyles();
  return (
    <Typography variant="body1">
      <strong>
        {name} Date Completed: {moment(date).format("DD/MM/YYYY")}
      </strong>{" "}
      <a href={link} target="_blank" rel="noopener noreferrer">
        <LaunchIcon className={classes.verticalAlignBottom} fontSize="small" />
      </a>
    </Typography>
  );
};

export const CTTPSelect: React.FC<CTTPSelectProps> = ({
  name,
  legacyCttps,
}) => {
  const classes = useStyles();
  const { values, getFieldMeta } = useFormikContext();
  const fieldName = useMemo(() => getFieldName("legacyCttp", name), [name]);
  const sortedLegacyCttps = useMemo(
    () =>
      legacyCttps.sort(
        (a, b) =>
          new Date(b.zohosign__Date_Completed).getTime() -
          new Date(a.zohosign__Date_Completed).getTime()
      ),
    [legacyCttps]
  );

  const fieldMeta = useMemo(() => getFieldMeta(fieldName), [
    fieldName,
    getFieldMeta,
  ]);

  const manualOrAuto = useMemo(() => {
    return getValueFromObject("manualOrAutoSelection", values);
  }, [values]);

  if (manualOrAuto !== "manual") {
    return null;
  }

  return (
    <>
      <Field component={RadioGroup} row name={fieldName}>
        {sortedLegacyCttps.map((legacyCttp) => (
          <FormControlLabel
            key={legacyCttp.id}
            className={fieldMeta.error && fieldMeta.touched ? "" : classes.mb2}
            value={legacyCttp.id}
            control={<Radio color="primary" />}
            label={
              <LegacyCttp
                name={legacyCttp.Name}
                date={legacyCttp.zohosign__Date_Completed}
                link={legacyCttp.recordCrmLink}
              />
            }
          />
        ))}
      </Field>
      {fieldMeta.error && fieldMeta.touched && (
        <Box mb={2}>
          <Typography color="error" variant="caption">
            {fieldMeta.error}
          </Typography>
        </Box>
      )}
    </>
  );
};
