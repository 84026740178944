import React, { memo, useMemo } from "react";
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { BloodTestResultItem } from "@deep-consulting-solutions/bmh-constants";

import { TABLE_HEADER_COLOR, TableCell } from "../../TestResults.styles";
import { groupBloodTestItems } from "../../TestResults.helpers";
import { OBX_TABLE_META, OBXTableField } from "./OBXTable.types";
import OBXTableGroup from "./OBXTableGroup";

const useStyle = makeStyles(({ spacing: s }) => ({
  wrapper: {
    marginTop: s(2),
    flex: 1,
  },
  tableHeader: {
    color: TABLE_HEADER_COLOR,
  },
}));

interface OBXTableProps {
  items: BloodTestResultItem[];
  goToHistory: (obx: BloodTestResultItem) => any;
}

const OBXTable: React.FC<OBXTableProps> = ({ items, goToHistory }) => {
  const { array, hasWarning } = useMemo(() => groupBloodTestItems(items), [
    items,
  ]);

  const classes = useStyle();

  return (
    <TableContainer className={classes.wrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {hasWarning && <TableCell />}
            {Object.keys(OBX_TABLE_META).map((k) => {
              const key = k as OBXTableField;
              const { label } = OBX_TABLE_META[key];

              return (
                <TableCell key={key} className={classes.tableHeader}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {array.map(({ id, items: its }) => (
            <OBXTableGroup
              key={id}
              identifier={id}
              items={its}
              hasWarning={hasWarning}
              goToHistory={goToHistory}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(OBXTable);
