import { Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    list: {
      padding: s(0),
      margin: s(0),
      paddingLeft: s(4),
    },
  })
);

interface Props {
  longText: string;
  longText2?: string;
}

export const ShowCRMVariable = ({ longText, longText2 = "" }: Props) => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {longText.split("- ").map((text) =>
        text.trim() ? (
          <li key={text}>
            <Typography>{text.trim()}</Typography>
          </li>
        ) : null
      )}
      {longText2.split("- ").map((text) =>
        text.trim() ? (
          <li key={text}>
            <Typography>{text.trim()}</Typography>
          </li>
        ) : null
      )}
    </ul>
  );
};
