import React, { useMemo } from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { getValueFromObject } from "helpers";
import { useFormikContext } from "formik";
import { Alert } from "@material-ui/lab";
import { AutoCompleteOption } from "types";
import {
  ProductSelect,
  OtherFieldsProductDetails,
  QuantityAndPricingFields,
  OnlyOnceField,
  SelfSupplyField,
  BillingCyclePeriod,
  OtherFields,
} from "../TreatmentMedicines";

interface Props {
  name: string;
  products: OtherFieldsProductDetails[];
  dosingType?: string;
  deleteProduct?: () => void;
  isInjectionBundle?: boolean;
  isBacteriostatic?: boolean;
  isSupplementary?: boolean;
  initialSelectedPharmacies: AutoCompleteOption[];
  selectedPharmacies: AutoCompleteOption[];
  allowChangeBillingCycleUnit?: boolean;
}

const useStyles = makeStyles(({ palette: p }) => ({
  deleteBtn: {
    backgroundColor: p.error.main,
    color: p.error.contrastText,
  },
}));

export const ProductFields = ({
  name,
  products,
  dosingType,
  isBacteriostatic,
  isInjectionBundle,
  isSupplementary,
  deleteProduct,
  initialSelectedPharmacies,
  selectedPharmacies,
  allowChangeBillingCycleUnit,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const fieldValue = useMemo(() => getValueFromObject(name, values), [
    values,
    name,
  ]);

  const productsByZohoCrmId = useMemo(() => {
    return products.reduce<Record<string, OtherFieldsProductDetails>>(
      (total, current) => {
        return { ...total, [current.ZohoCrmId]: current };
      },
      {}
    );
  }, [products]);

  const selectedProduct = useMemo(() => {
    return productsByZohoCrmId[fieldValue?.product?.value] || null;
  }, [fieldValue, productsByZohoCrmId]);

  const selectedPharmacy = useMemo(() => {
    if (!selectedProduct?.pharmacyZohoId) {
      return null;
    }
    return (
      selectedPharmacies.find(
        (s) => s.value === selectedProduct.pharmacyZohoId
      ) || null
    );
  }, [selectedProduct?.pharmacyZohoId, selectedPharmacies]);

  const showPharmacyWarning = useMemo(() => {
    return !selectedPharmacy?.value
      ? false
      : initialSelectedPharmacies.every(
          (i) => i.value !== selectedPharmacy?.value
        );
  }, [selectedPharmacy?.value, initialSelectedPharmacies]);

  const unitPrice = useMemo(() => {
    if (!selectedProduct) {
      return undefined;
    }
    return Number(selectedProduct.UnitPrice || 0);
  }, [selectedProduct]);

  return (
    <Box>
      <Box mb={2}>
        {showPharmacyWarning ? (
          <Alert severity="warning">
            <b>{selectedPharmacy?.title}</b> pharmacy wasn&apos;t selected for
            any products in this treatment and adding it will cause the addition
            of another shipping fee.
          </Alert>
        ) : null}
      </Box>
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item xs={4}>
            <ProductSelect products={products} name={name} />
          </Grid>
          <Grid item>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
              wrap="nowrap"
            >
              <QuantityAndPricingFields
                name={name}
                unitPrice={unitPrice}
                medUsageUnitName={selectedProduct?.MedsUsageUnit.Name}
                unitsPerSellableUnit={selectedProduct?.unitsPerSellableUnit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!isInjectionBundle && !isBacteriostatic ? (
        <Box mb={2}>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <BillingCyclePeriod
                name={name}
                allowChangeBillingCycleUnit={allowChangeBillingCycleUnit}
              />
            </Grid>
            <Grid item>
              <OnlyOnceField name={name} />
            </Grid>

            {isSupplementary ? (
              <Grid
                style={{
                  flex: 1,
                }}
                item
              >
                <Box textAlign="right">
                  <Button
                    onClick={deleteProduct}
                    className={classes.deleteBtn}
                    variant="contained"
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item>
                <SelfSupplyField name={name} />
              </Grid>
            )}
          </Grid>
        </Box>
      ) : null}
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {selectedProduct && (
            <OtherFields
              selectedProduct={selectedProduct}
              dosingType={dosingType}
              isInjectionBundle={isInjectionBundle}
              isBacteriostatic={isBacteriostatic}
              ignoreDoseVolume
              forceDoubleLines
            />
          )}
          {(isInjectionBundle || isBacteriostatic) && (
            <Grid item>
              <SelfSupplyField name={name} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
