import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import { apiClient } from "apis";
import { getTestFailureCodeTransform, transformToFETestObxCode } from "helpers";
import { toNumber, transform } from "lodash";
import { AppState, SettingsState } from "redux/types";
import {
  Lab,
  GetLabsRes,
  labsEndpoints,
  GetTestObxCodesRes,
  PatchTestObxCodesRes,
  testObxCodesEndpoints,
  TestFailureCodeFE,
  GetTestFailureCodesRes,
  PatchTestFailureCodesRes,
  testFailureCodesEndpoints,
  TestAbnormalFlag,
  GetTestAbnormalFlagsRes,
  PatchTestAbnormalFlagsRes,
  testAbnormalFlagsEndpoints,
  StatusEnum,
  testTubesQtyEndpoints,
  TestTubesQty,
  PatchTestTubesQtyRes,
  GetTestTubesQtyRes,
  GetLabsDisplayOrderRes,
  PutUpdateLabDisplayOrderRes,
  PutUpdateLabDisplayOrderBody,
} from "@deep-consulting-solutions/bmh-constants";
import { AxiosResponse } from "axios";
import { FETestObxCode } from "types";

const ENTITY = "settings";

const labsAdapter = createEntityAdapter<Lab>();
const labsSelectors = labsAdapter.getSelectors<AppState>(
  (state) => state.settings.labs
);
const getLabs = createAsyncThunk<Lab[], void>(`${ENTITY}/getLabs`, async () => {
  const res = await apiClient.get<GetLabsRes>(labsEndpoints.labs);
  return res.data.data;
});

/// Test Obx Code

const testObxCodeAdapter = createEntityAdapter<FETestObxCode>();
const testObxCodeSelectors = testObxCodeAdapter.getSelectors<AppState>(
  (state) => state.settings.testObxCode
);

const fetchTestObxCodes = createAsyncThunk<FETestObxCode[], void>(
  `${ENTITY}/fetchTestObxCodes`,
  async () => {
    const res = await apiClient.get<GetTestObxCodesRes>(
      testObxCodesEndpoints.getOrPost
    );
    return res.data.data.map((d) => transformToFETestObxCode(d));
  }
);

const updateTestObxCode = createAsyncThunk<
  FETestObxCode,
  { id: string; body: any }
>(`${ENTITY}/updateTestObxCode`, async ({ id, body }) => {
  const res = await apiClient.patch<PatchTestObxCodesRes>(
    testObxCodesEndpoints.patchOrDelete(id),
    body
  );
  return transformToFETestObxCode(res.data.data);
});

const createTestObxCode = createAsyncThunk<FETestObxCode, any>(
  `${ENTITY}/createTestObxCode`,
  async (body) => {
    const res = await apiClient.post<PatchTestObxCodesRes>(
      testObxCodesEndpoints.getOrPost,
      body
    );
    return transformToFETestObxCode(res.data.data);
  }
);

const changeTestObxCodeStatus = createAsyncThunk<
  FETestObxCode,
  { id: string; nextStatus: StatusEnum }
>(`${ENTITY}/changeTestObxCodeStatus`, async ({ id, nextStatus }) => {
  let res: AxiosResponse<PatchTestObxCodesRes>;
  if (nextStatus === StatusEnum.Active) {
    res = await apiClient.post<PatchTestObxCodesRes>(
      testObxCodesEndpoints.activate(id)
    );
  } else {
    res = await apiClient.post<PatchTestObxCodesRes>(
      testObxCodesEndpoints.deactivate(id)
    );
  }
  return transformToFETestObxCode(res.data.data);
});

const deleteTestObxCode = createAsyncThunk<void, { id: string }>(
  `${ENTITY}/deleteTestObxCode`,
  async ({ id }) => {
    await apiClient.delete(testObxCodesEndpoints.patchOrDelete(id));
  }
);

const acknowledgeTestObxUpdate = createAsyncThunk<
  void,
  { id: string; updatedLabs: FETestObxCode["labs"] }
>(`${ENTITY}/acknowledgeTestObxUpdate`, async ({ id }) => {
  await apiClient.post(testObxCodesEndpoints.acknowledgeUpdate(id));
});

/// Test Failure Code

const testFailureCodeAdapter = createEntityAdapter<TestFailureCodeFE>();
const testFailureCodeSelectors = testFailureCodeAdapter.getSelectors<AppState>(
  (state) => state.settings.testFailureCode
);

const getTestFailureCode = createAsyncThunk<
  TestFailureCodeFE[],
  void,
  { state: AppState }
>(`${ENTITY}/getTestFailureCode`, async (_, { getState }) => {
  const labs = labsSelectors.selectAll(getState());
  const res = await apiClient.get<GetTestFailureCodesRes>(
    testFailureCodesEndpoints.getOrPost
  );
  return res.data.data.map((d) => getTestFailureCodeTransform(d, labs));
});

const updateTestFailureCode = createAsyncThunk<
  TestFailureCodeFE,
  TestFailureCodeFE,
  { state: AppState }
>(
  `${ENTITY}/updateTestFailureCode`,
  async ({ id, isDeletable, ...data }, { getState }) => {
    const labs = labsSelectors.selectAll(getState());
    const res = await apiClient.patch<PatchTestFailureCodesRes>(
      testFailureCodesEndpoints.patchOrDelete(id),
      {
        failureCode: data.failureCode,
        labKeys: labs.filter((lab) => !!data[lab.key]).map((lab) => lab.key),
      }
    );
    return getTestFailureCodeTransform(res.data.data, labs);
  }
);

const changeTestFailureCodeStatus = createAsyncThunk<
  TestFailureCodeFE,
  { id: string; status: StatusEnum },
  { state: AppState }
>(
  `${ENTITY}/changeTestFailureCodeStatus`,
  async ({ id, status }, { getState }) => {
    const labs = labsSelectors.selectAll(getState());
    let res: AxiosResponse<PatchTestFailureCodesRes>;
    if (status === StatusEnum.Active) {
      res = await apiClient.post<PatchTestFailureCodesRes>(
        testFailureCodesEndpoints.deactivate(id)
      );
    } else {
      res = await apiClient.post<PatchTestFailureCodesRes>(
        testFailureCodesEndpoints.activate(id)
      );
    }

    return getTestFailureCodeTransform(res.data.data, labs);
  }
);

const createTestFailureCode = createAsyncThunk<
  TestFailureCodeFE,
  Omit<TestFailureCodeFE, "id">,
  { state: AppState }
>(`${ENTITY}/createTestFailureCode`, async (data, { getState }) => {
  const labs = labsSelectors.selectAll(getState());
  const res = await apiClient.post<PatchTestFailureCodesRes>(
    testFailureCodesEndpoints.getOrPost,
    {
      failureCode: data.failureCode,
      labKeys: labs.filter((lab) => !!data[lab.key]).map((lab) => lab.key),
    }
  );
  return getTestFailureCodeTransform(res.data.data, labs);
});

const deleteTestFailureCode = createAsyncThunk<void, { id: string }>(
  `${ENTITY}/deleteTestFailureCode`,
  async ({ id }) => {
    await apiClient.delete(testFailureCodesEndpoints.patchOrDelete(id));
  }
);

const testAbnormalFlagAdapter = createEntityAdapter<TestAbnormalFlag>();
const testAbnormalFlagSelectors = testAbnormalFlagAdapter.getSelectors<AppState>(
  (state) => state.settings.testAbnormalFlag
);

const getTestAbnormalFlag = createAsyncThunk<TestAbnormalFlag[], void>(
  `${ENTITY}/getTestAbnormalFlag`,
  async () => {
    const res = await apiClient.get<GetTestAbnormalFlagsRes>(
      testAbnormalFlagsEndpoints.getOrPost
    );
    return res.data.data;
  }
);

const updateTestAbnormalFlag = createAsyncThunk<
  TestAbnormalFlag,
  TestAbnormalFlag
>(
  `${ENTITY}/updateTestAbnormalFlag`,
  async ({ id, isDeletable, isNew, status, ...data }) => {
    const res = await apiClient.patch<PatchTestAbnormalFlagsRes>(
      testAbnormalFlagsEndpoints.patchOrDelete(id),
      {
        ...data,
      }
    );
    return res.data.data;
  }
);

const changeTestAbnormalFlagStatus = createAsyncThunk<
  TestAbnormalFlag,
  { id: string; status: StatusEnum }
>(`${ENTITY}/changeTestAbnormalFlagStatus`, async ({ id, status }) => {
  const res = await apiClient.patch<PatchTestAbnormalFlagsRes>(
    testAbnormalFlagsEndpoints.patchStatus(id),
    {
      isActive: status !== StatusEnum.Active,
    }
  );
  return res.data.data;
});

const createTestAbnormalFlag = createAsyncThunk<
  TestAbnormalFlag,
  Omit<TestAbnormalFlag, "id">
>(`${ENTITY}/createTestAbnormalFlag`, async ({ ...data }) => {
  const res = await apiClient.post<PatchTestAbnormalFlagsRes>(
    testAbnormalFlagsEndpoints.getOrPost,
    {
      ...data,
    }
  );
  return res.data.data;
});

const deleteTestAbnormalFlag = createAsyncThunk<void, { id: string }>(
  `${ENTITY}/deleteTestAbnormalFlag`,
  async ({ id }) => {
    await apiClient.delete(testAbnormalFlagsEndpoints.patchOrDelete(id));
  }
);

const testTubeLabelsQtyAdapter = createEntityAdapter<TestTubesQty>();
const testTubeLabelsQtySelectors = testTubeLabelsQtyAdapter.getSelectors<AppState>(
  (state) => state.settings.testTubeLabelsQty
);

const getTestTubesQty = createAsyncThunk<TestTubesQty[], void>(
  `${ENTITY}/getTestTubesQty`,
  async () => {
    const res = await apiClient.get<GetTestTubesQtyRes>(
      testTubesQtyEndpoints.getOrPost
    );
    return res.data.data;
  }
);

const updateTestTubesQty = createAsyncThunk<TestTubesQty, TestTubesQty>(
  `${ENTITY}/updateTestTubesQty`,
  async ({ id, bmhObrCode, ...data }) => {
    const res = await apiClient.patch<PatchTestTubesQtyRes>(
      testTubesQtyEndpoints.patchOrDelete(id),
      transform<any, any>(data, (acc, curr, key) => {
        if (key === "bmhObrCode") {
          acc[key] = curr;
        } else {
          acc[key] = toNumber(curr);
        }
      })
    );
    return res.data.data;
  }
);

// eslint-disable-next-line @typescript-eslint/ban-types
const slice = createSlice<SettingsState, SliceCaseReducers<SettingsState>>({
  name: ENTITY,
  initialState: {
    labs: labsAdapter.getInitialState(),
    testObxCode: testObxCodeAdapter.getInitialState(),
    testFailureCode: testFailureCodeAdapter.getInitialState(),
    testAbnormalFlag: testAbnormalFlagAdapter.getInitialState(),
    testTubeLabelsQty: testTubeLabelsQtyAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getLabs.fulfilled, (state, action) => {
        labsAdapter.setAll(state.labs, action.payload);
      })
      .addCase(fetchTestObxCodes.fulfilled, (state, action) => {
        testObxCodeAdapter.setAll(state.testObxCode, action.payload);
      })
      .addCase(updateTestObxCode.fulfilled, (state, action) => {
        testObxCodeAdapter.updateOne(state.testObxCode, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(createTestObxCode.fulfilled, (state, action) => {
        testObxCodeAdapter.addOne(state.testObxCode, action.payload);
      })
      .addCase(changeTestObxCodeStatus.fulfilled, (state, action) => {
        testObxCodeAdapter.updateOne(state.testObxCode, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(deleteTestObxCode.fulfilled, (state, action) => {
        testObxCodeAdapter.removeOne(state.testObxCode, action.meta.arg.id);
      })
      .addCase(acknowledgeTestObxUpdate.fulfilled, (state, action) => {
        testObxCodeAdapter.updateOne(state.testObxCode, {
          id: action.meta.arg.id,
          changes: {
            hasUpdated: false,
            labs: action.meta.arg.updatedLabs,
          },
        });
      })
      .addCase(getTestFailureCode.fulfilled, (state, action) => {
        testFailureCodeAdapter.setAll(state.testFailureCode, action.payload);
      })
      .addCase(updateTestFailureCode.fulfilled, (state, action) => {
        testFailureCodeAdapter.updateOne(state.testFailureCode, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(changeTestFailureCodeStatus.fulfilled, (state, action) => {
        testFailureCodeAdapter.updateOne(state.testFailureCode, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(createTestFailureCode.fulfilled, (state, action) => {
        testFailureCodeAdapter.addOne(state.testFailureCode, action.payload);
      })
      .addCase(deleteTestFailureCode.fulfilled, (state, action) => {
        testFailureCodeAdapter.removeOne(
          state.testFailureCode,
          action.meta.arg.id
        );
      })
      .addCase(getTestAbnormalFlag.fulfilled, (state, action) => {
        testAbnormalFlagAdapter.setAll(state.testAbnormalFlag, action.payload);
      })
      .addCase(updateTestAbnormalFlag.fulfilled, (state, action) => {
        testAbnormalFlagAdapter.updateOne(state.testAbnormalFlag, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(changeTestAbnormalFlagStatus.fulfilled, (state, action) => {
        testAbnormalFlagAdapter.updateOne(state.testAbnormalFlag, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      })
      .addCase(createTestAbnormalFlag.fulfilled, (state, action) => {
        testAbnormalFlagAdapter.addOne(state.testAbnormalFlag, action.payload);
      })
      .addCase(deleteTestAbnormalFlag.fulfilled, (state, action) => {
        testAbnormalFlagAdapter.removeOne(
          state.testAbnormalFlag,
          action.meta.arg.id
        );
      })
      .addCase(getTestTubesQty.fulfilled, (state, action) => {
        testTubeLabelsQtyAdapter.setAll(
          state.testTubeLabelsQty,
          action.payload
        );
      })
      .addCase(updateTestTubesQty.fulfilled, (state, action) => {
        testTubeLabelsQtyAdapter.updateOne(state.testTubeLabelsQty, {
          id: action.meta.arg.id,
          changes: action.payload,
        });
      }),
});

export const settingsActions = {
  ...slice.actions,
  getLabs,
  fetchTestObxCodes,
  updateTestObxCode,
  createTestObxCode,
  changeTestObxCodeStatus,
  deleteTestObxCode,
  acknowledgeTestObxUpdate,
  getTestFailureCode,
  updateTestFailureCode,
  changeTestFailureCodeStatus,
  createTestFailureCode,
  deleteTestFailureCode,
  getTestAbnormalFlag,
  updateTestAbnormalFlag,
  changeTestAbnormalFlagStatus,
  createTestAbnormalFlag,
  deleteTestAbnormalFlag,
  getTestTubesQty,
  updateTestTubesQty,
};

export const settingsSelectors = {
  labsSelectors,
  testObxCodeSelectors,
  testFailureCodeSelectors,
  testAbnormalFlagSelectors,
  testTubeLabelsQtySelectors,
};

export default slice.reducer;

export const fetchLabsOrderRequest = async () => {
  const res = await apiClient.get<GetLabsDisplayOrderRes>(
    labsEndpoints.getOrUpdateLabsOrder
  );
  return res.data.data;
};

export const updateLabsOrderRequest = async (labKeys: string[]) => {
  const body: PutUpdateLabDisplayOrderBody = { labKeys };
  const res = await apiClient.put<PutUpdateLabDisplayOrderRes>(
    labsEndpoints.getOrUpdateLabsOrder,
    body
  );
  return res.data.data;
};
