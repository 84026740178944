import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Divider,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { pdfTemplatesRequests } from "redux/settings/pdfTemplates";
import { PdfTemplate } from "@deep-consulting-solutions/bmh-constants";
import Loader from "components/Loader";
import { format, parse } from "date-fns";
import { SettingsContainer } from "components/SettingsContainer";
import PDFIcon from "components/PDFIcon";
import { InfoOutlined } from "@material-ui/icons";

const COLUMNS: { header: string; align?: TableCellProps["align"] }[] = [
  {
    header: "Template Name",
  },
  {
    header: "Last Modified",
    align: "right",
  },
];

const useStyles = makeStyles(({ spacing: s, palette: p }) =>
  createStyles({
    headCell: {
      color: p.primary.light,
    },
    bodyRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: p.action.hover,
      },
    },
    table: {
      maxWidth: 800,
      borderRight: `1px solid ${p.divider}`,
    },
    tableContainer: {
      flex: 1,
      marginBottom: s(3),
    },
    icon: {
      marginRight: s(2),
    },
    dangerZone: {
      color: p.error.main,
    },
  })
);

export const PDFTemplates = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<PdfTemplate[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const temp = await pdfTemplatesRequests.fetchPDFTemplates();
        setTemplates(temp);
      } catch {
        //
      }
      setLoading(false);
    })();
  }, []);
  return (
    <>
      <Loader isFixed open={loading} />
      <SettingsContainer display="flex" flexDirection="column" pb={1}>
        <Box pb={3} px={2}>
          <Typography variant="h4" component="h1" paragraph>
            Auto Generated Document Templates
          </Typography>

          <Typography>
            You can use the below functionality to configure templates for PDF
            documents automatically generated by the system. The templates are
            generated using PDF Generator API service and the below links will
            allow you to access editing within that service.
            <br />
            <br />
            <span className={classes.dangerZone}>
              <b>DANGER ZONE!</b>
              <br />
              Any changes made to the templates, once saved, will be immediately
              applied to all newly generated documents. While some templates are
              rather simple and simple changes to them can be performed by an
              unfamiliar user, many templates contain advanced elements such as
              complex logic configurations, tables, QR codes and barcodes, etc.
              which should only be configured by users with advanced technical
              knowledge of the system.
              <br />
              Merge field names should not be changed as doing so will break the
              system.
              <br />
              Mistakes made while using this functionality can have serious
              implications to the business operation. If you are unsure about
              what you are doing, please contact DCS with your requested changes
              and DCS team will implement them seamlessly.
            </span>
          </Typography>
        </Box>

        <Divider />
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {COLUMNS.map(({ header, align }) => (
                  <TableCell
                    className={classes.headCell}
                    key={header}
                    align={align}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.length > 0 &&
                templates.map((template) => (
                  <TableRow className={classes.bodyRow} key={template.id}>
                    <TableCell align={COLUMNS[0].align}>
                      <Link
                        color="textPrimary"
                        href={template.editorUrl}
                        target="_blank"
                      >
                        <Box display="flex" alignItems="center">
                          <PDFIcon className={classes.icon} />
                          <Typography component="h2">
                            {template.name}
                          </Typography>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell align={COLUMNS[1].align}>
                      {format(
                        parse(
                          template.modified,
                          "yyyy-MM-dd HH:mm:ss",
                          new Date()
                        ),
                        "MMMM dd, yyyy hh:mm"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {!loading && !templates.length && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center" color="textSecondary">
                      No PDF Templates Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box px={2} color="text.secondary" display="flex" alignItems="center">
          <InfoOutlined className={classes.icon} />
          <Typography variant="body2">
            If you are going to clone existing templates to use new versions of
            them, instead of editing current operating ones, please, contact DCS
            in order to set them up for automatic operations.
            <br />
            If you will make changes in existing templates they will continue to
            operate without problems.
          </Typography>
        </Box>
      </SettingsContainer>
    </>
  );
};
