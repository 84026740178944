import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { getFieldName, getValueFromObject } from "helpers";
import { Field, useFormikContext } from "formik";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { ChangeFirstInvoiceDateAfterSigningField } from "./ChangeFirstInvoiceDateAfterSigningField";

interface Props {
  name?: string;
}

export const TreatmentOrderDateChangeFields = ({ name = "" }: Props) => {
  const { values } = useFormikContext();

  const changeFirstInvoiceDateAfterSigningFieldName = useMemo(
    () => getFieldName("changeFirstInvoiceDateAfterSigning", name),
    [name]
  );

  const changeFirstInvoiceDateAfterSigningFieldValue = useMemo(
    () =>
      getValueFromObject(changeFirstInvoiceDateAfterSigningFieldName, values),
    [changeFirstInvoiceDateAfterSigningFieldName, values]
  );

  return (
    <Box mb={2}>
      <ChangeFirstInvoiceDateAfterSigningField name={name} />
      {!changeFirstInvoiceDateAfterSigningFieldValue ? (
        <Box maxWidth={500}>
          <Field
            component={FormikDatePicker}
            name={getFieldName("firstPaymentDate", name)}
            label="First Payment Date"
            required
            disablePast
            placeholder="DD/MM/YYYY"
            size="small"
            fullWidth
            helperText="The date at which the first invoice will be created and charged after Documents signing (if documents were signed later then then it will be the day of signing)"
          />
        </Box>
      ) : null}
    </Box>
  );
};
