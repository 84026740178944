import { useFormikContext } from "formik";
import { saveDefaultValues, SaveDefaultValuesWidgetName } from "helpers";
import { useEffect, useMemo } from "react";

interface Props {
  widgetName: SaveDefaultValuesWidgetName;
  identifier: string;
  blockSaving?: boolean;
}

export const DefaultValuesStore = ({
  widgetName,
  identifier,
  blockSaving = false,
}: Props) => {
  const { touched, values } = useFormikContext();

  const anyFieldTouched = useMemo(() => {
    return !!Object.values(touched).length;
  }, [touched]);

  useEffect(() => {
    if (anyFieldTouched && !blockSaving && identifier) {
      saveDefaultValues(widgetName, identifier, values);
    }
  }, [anyFieldTouched, widgetName, values, blockSaving, identifier]);
  return null;
};
