export enum DocumentStatusEnum {
  Generated = "Generated",
  Pending_Doctor_Signing = "Pending Doctor Signing",
  Signed_by_Doctor = "Signed by Doctor",
  Pending_Client_Signing = "Pending Client Signing",
  Signed = "Signed",
}

export enum DocumentTypeEnum {
  Prescription = "Prescription",
  CTTP = "CTTP",
  Injection_Guides = "Injection Guides",
  Signer_Identity_Certificates = "Signer Identity Certificates",
}

export enum DocumentOwnershipEnum {
  Client = "Client",
  Doctor = "Doctor",
}

export type DocumentStatusFilters = {
  [key in DocumentStatusEnum]: boolean;
};

export type DocumentTypeFilters = {
  [key in DocumentTypeEnum]: boolean;
};

export type OwnershipFilters = {
  [key in DocumentOwnershipEnum]: boolean;
};

export interface DocumentsType {
  SignerIdentityCertificates: {
    AddedOn: string;
    DocumentState: DocumentStatusEnum;
    FileName: string;
    Ownership: string;
    S3Key: string;
    Signer: string;
  }[];
  DisplayInPortal: boolean;
  Id: string;
  Type: DocumentTypeEnum;
  FileName: string;
  SigningCompletionDate: string;
  Signers: string;
  AddedOn: string;
  S3Key: string;
  DocumentState: DocumentStatusEnum;
}

export interface FiltersType {
  localStatusFilters: DocumentStatusFilters;
  localeTypeFilters: DocumentTypeFilters;
  localeOwnershipFilters: OwnershipFilters;
}

export type Columns =
  | "FileName"
  | "Type"
  | "Signers"
  | "DocumentState"
  | "SigningCompletionDate"
  | "AddedOn"
  | "DisplayInPortal"
  | "SignerIdentityCertificates";

export const TABLE_META: {
  [key in Columns]: {
    label: string;
  };
} = {
  FileName: {
    label: "File Name",
  },
  Type: {
    label: "Document Type",
  },
  Signers: {
    label: "Signers",
  },
  DocumentState: {
    label: "Status",
  },
  AddedOn: {
    label: "Added On",
  },
  SigningCompletionDate: {
    label: "Signing Completion Date",
  },
  DisplayInPortal: {
    label: "Display in Portal",
  },
  SignerIdentityCertificates: {
    label: "Signer Identity Certificates",
  },
};
