import { Box, Typography, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { TreatmentMedicineAndProduct } from "components/Treatments/TreatmentMedicinesAndProducts/TreatmentMedicineAndProduct";
import { useFormikContext } from "formik";
import { getFieldName } from "helpers";
import React from "react";
import {
  AutoCompleteOption,
  ZohoProduct,
  FinalizeZohoOtherTreatmentMedicine,
  ZohoMedUsageUnit,
} from "types";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  fieldsSection: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  products: ZohoProduct[];
  otherTreatmentMedicines: FinalizeZohoOtherTreatmentMedicine[];
  name?: string;
  selectedPharmacies: AutoCompleteOption[];
  initialSelectedPharmacies: AutoCompleteOption[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  allowChangeBillingCycleUnit: boolean;
}

export const OtherTreatmentMedicinesForm = ({
  products,
  otherTreatmentMedicines,
  name = "",
  selectedPharmacies,
  initialSelectedPharmacies,
  medUsageUnits,
  allowChangeBillingCycleUnit,
}: Props) => {
  const classes = useStyles();
  const { initialValues } = useFormikContext<any>();

  if (!initialValues.otherTreatmentMedicines?.length) {
    return null;
  }

  return (
    <Box mt={4} className={classes.wrapper}>
      <Typography color="primary">Other Treatment Medicine</Typography>
      <Box mt={2} maxWidth={700}>
        <Alert severity="info">
          <AlertTitle>
            For some of the medicines the exact strength and product could not
            be automatically selected. Select the appropriate medicine items and
            quantities.
          </AlertTitle>
          Try to set a billing cycle that is the same as that of the package you
          selected.
        </Alert>
      </Box>

      {otherTreatmentMedicines.map((otherTreatmentMedicine, index) => (
        <Box mt={2} key={otherTreatmentMedicine.id}>
          <TreatmentMedicineAndProduct
            suggestedMedicine={{
              ...otherTreatmentMedicine,
              products,
            }}
            name={getFieldName(index, name)}
            treatmentTreatmentArea={
              otherTreatmentMedicine.treatmentTreatmentArea
            }
            bacteriostaticProducts={[]}
            initialSelectedPharmacies={initialSelectedPharmacies}
            selectedPharmacies={selectedPharmacies}
            medUsageUnits={medUsageUnits}
            allowChangeBillingCycleUnit={allowChangeBillingCycleUnit}
          />
        </Box>
      ))}
    </Box>
  );
};
