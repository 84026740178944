import {
  Mcm,
  GetAssignableQualifiedMcmsRes,
  ROUTE_ASSIGNABLE_QUALIFIED_MCMS_CRM,
  PatchContactsMcmBody,
  PatchContactsMcmRes,
  ROUTE_CONTACTS_MCM_CRM,
} from "@deep-consulting-solutions/bmh-constants";

import { apiClient } from "apis";
import { Contact } from "types";

export const fetchClientsFromZohoIDs = async (id: string): Promise<Contact> => {
  const res = await apiClient.get(`/contacts/crm/${id}`);
  return res.data.data.contact;
};

export const getAssignableQualifiedMcmsRequest = async (): Promise<Mcm[]> => {
  const res = await apiClient.get<GetAssignableQualifiedMcmsRes>(
    ROUTE_ASSIGNABLE_QUALIFIED_MCMS_CRM
  );
  return res.data.data;
};

export const assignMcmRequest = async (
  data: PatchContactsMcmBody
): Promise<Record<string, never>> => {
  const res = await apiClient.patch<PatchContactsMcmRes>(
    ROUTE_CONTACTS_MCM_CRM,
    data
  );
  return res.data.data;
};
