import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";

import { ZohoPathway } from "types";
import Loader from "components/Loader";
import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { testResultsRequests } from "redux/testResults";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";

const useStyles = makeStyles(({ palette: p, spacing: s, typography: t }) => ({
  title: {
    fontWeight: 500,
    color: p.primary.main,
    fontFamily: t.fontFamily,
  },
  sub: {
    fontSize: 16,
    marginBottom: s(1),
  },
  content: {
    overflow: "unset",
    marginBottom: s(3),
  },
  text: {
    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
}));

export const WaiveChargingClientForRetest = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];
  const pathways = useSelector(zohoSelectors.getRecords);
  const pathway = (pathways[pathwayZohoId] as unknown) as ZohoPathway;
  const [postWaiveInformation, setPostWaiveInformation] = useState({
    invoiceURL: "",
    newBloodTestOrderLink: "",
  });

  const closeWidget = () => {
    (async () => {
      await zohoServices.closePopup(true);
    })();
  };

  const waive = async () => {
    setLoading(true);

    try {
      const data = await testResultsRequests.waiveChargingClientForRetest(
        pathwayZohoId
      );

      setPostWaiveInformation(data);
      setShowConfirmation(true);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <WidgetLayout>
      <Loader open={loading} />

      {!showConfirmation ? (
        <>
          <WidgetTitle disableTypography>
            <Typography variant="h5" className={classes.title}>
              Waive Charging Client for Retest
            </Typography>
          </WidgetTitle>

          <WidgetContent className={classes.content}>
            <Typography className={classes.sub} variant="h6">
              Retest Decision Explanation and Failure Reason
            </Typography>

            <Typography>
              {pathway.Retest_Decision_Explanation_and_Failure_Reason}
            </Typography>
          </WidgetContent>

          <WidgetActions>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => {
                zohoServices.closePopup();
              }}
            >
              Cancel
            </Button>

            <Button type="button" color="primary" onClick={waive}>
              Waive
            </Button>
          </WidgetActions>
        </>
      ) : (
        <>
          <WidgetTitle disableTypography>
            <Typography variant="h5" className={classes.title}>
              Confirm Retest Reason
            </Typography>
          </WidgetTitle>

          <WidgetContent className={classes.content}>
            <Box className={classes.text}>
              <Typography>
                A retest blood test order was created in stage{" "}
                {!postWaiveInformation.invoiceURL
                  ? "Pending Contact with Client"
                  : "Awaiting Blood Kit Payment."}{" "}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={postWaiveInformation.newBloodTestOrderLink}
                >
                  Link to the new blood test order
                </a>
                {!postWaiveInformation.invoiceURL
                  ? " and it will be progressed automatically."
                  : "."}
              </Typography>

              {postWaiveInformation.invoiceURL && (
                <Typography>
                  An invoice was created for the blood draw kit.{" "}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={postWaiveInformation.invoiceURL}
                  >
                    Link to the invoice.
                  </a>
                </Typography>
              )}

              {postWaiveInformation.invoiceURL && (
                <Typography>
                  An email was sent to the client to inform him that he needs to
                  pay for the blood draw kit for {pathway.Blood_Taking_Option}{" "}
                  test with the invoice attached to it and the payment link.
                </Typography>
              )}
            </Box>
          </WidgetContent>

          <WidgetActions>
            <Button type="button" color="primary" onClick={closeWidget}>
              Okay
            </Button>
          </WidgetActions>
        </>
      )}
    </WidgetLayout>
  );
};
