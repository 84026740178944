import React, { useEffect, useMemo } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { getClientData } from "services/treatment-orders";
import { TextField } from "@material-ui/core";
import { InitialValues, Patient } from "widgets/CreateManualPrescription/types";

export const CustomPatientField: React.FC = () => {
  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [loading, setLoading] = React.useState(false);
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext<InitialValues>();

  const name = "patient";

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);

  const helpText = useMemo(() => {
    if (fieldTouched && fieldError) {
      return fieldError;
    }
    return `Search for patient or enter "New" to add a new patient`;
  }, [fieldError, fieldTouched]);

  const onInputChange = React.useCallback(
    async (event: any, newInputValue: string) => {
      if (
        newInputValue.length >= 3 &&
        newInputValue.toLowerCase().trim() !== "new"
      ) {
        setLoading(true);
        const contacts = await getClientData(newInputValue);
        if (contacts.length > 0) {
          setPatients(contacts);
          setFieldValue("patients", contacts);
        }
        setLoading(false);
      }
    },
    [setFieldValue]
  );

  const onChange = React.useCallback(
    (event: any, newValue: Patient | null) => {
      setFieldValue(name, newValue);
      if (newValue && newValue.value !== "new") {
        setFieldValue("patientDob", newValue.DateOfBirth);
        setFieldValue(
          "patientFoodDrugAllergies",
          newValue.DrugandFoodAllergies || ""
        );
        setFieldValue("patientAllergies", newValue.Allergies?.split(",") || []);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (values.patients.length > 0) {
      setPatients(values.patients);
    }
  }, [values.patients]);

  return (
    <Field
      required
      name={name}
      loading={loading}
      placeholder="Search for Patient"
      component={Autocomplete}
      options={patients}
      filterOptions={(opts: Patient[]) => [
        {
          FirstName: "New",
          LastName: "",
          Email: "",
          Id: "new",
          value: "new",
          title: "New",
        },
        ...opts,
      ]}
      getOptionLabel={(option: Patient) => option.title}
      value={values.patient}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      getOptionSelected={(option: Patient, value: Patient) => {
        return option.value === value.value && option.title === value.title;
      }}
      onInputChange={onInputChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          required
          name="patient"
          variant="outlined"
          label="Patient"
          helperText={helpText}
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
