import { TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { getFieldName, getValueFromObject, sortAlphabetically } from "helpers";
import React, { useCallback, useEffect, useMemo } from "react";
import { AutoCompleteOption, ZohoDosingType } from "types";

interface Props {
  name?: string;
  dosingTypes: ZohoDosingType[];
}

export const DosingUnitField = ({ dosingTypes, name }: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("dosingUnit", name), [name]);

  const dosingTypeFieldName = useMemo(() => getFieldName("dosingType", name), [
    name,
  ]);

  const dosingTypeFieldValue = useMemo(
    () => getValueFromObject(dosingTypeFieldName, values),
    [dosingTypeFieldName, values]
  );

  const fieldValue = useMemo(() => getValueFromObject(fieldName, values), [
    fieldName,
    values,
  ]);

  const dosingUnits = useMemo(() => {
    if (!dosingTypeFieldValue?.value) {
      return [];
    }
    const dosingType = dosingTypes.find(
      (d) => d.id === dosingTypeFieldValue?.value
    );
    return dosingType?.dosingTypeUnit || [];
  }, [dosingTypeFieldValue?.value, dosingTypes]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(() => {
    return sortAlphabetically(
      dosingUnits.map((dosingUnit) => ({
        title: dosingUnit.Dosing_Units.name,
        value: dosingUnit.Dosing_Units.id,
      }))
    );
  }, [dosingUnits]);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  useEffect(() => {
    if (!fieldValue?.value) {
      return;
    }
    const valueIsInOptions = options.some((o) => o.title === fieldValue?.title);

    if (!valueIsInOptions) {
      setFieldValue(fieldName, null);
      setFieldTouched(fieldName, false);
    }
  }, [
    fieldValue,
    fieldValue?.value,
    setFieldValue,
    fieldName,
    options,
    setFieldTouched,
  ]);

  useEffect(() => {
    return () => {
      return fieldValue;
    };
  }, [fieldValue]);

  return (
    <Field
      required
      name={fieldName}
      component={Autocomplete}
      options={options}
      disabled={!options.length}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      color="primary"
      getOptionSelected={(option: any, value: any) => {
        return option.value === value.value && option.title === value.title;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={fieldName}
          variant="outlined"
          label="Dosing Unit"
          helperText={fieldTouched && fieldError}
          error={fieldTouched && !!fieldError}
          size="small"
          fullWidth
        />
      )}
    />
  );
};
