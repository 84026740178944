import React, { memo, useCallback } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import {
  TABLE_HEADER_COLOR,
  BigTableCell as TableCell,
} from "../TestResults.styles";

import {
  HistoryViewTableField,
  HISTORY_VIEW_TABLE_META,
} from "./HistoryView.types";

interface StyleProps {
  desc?: boolean;
  sortable?: boolean;
}

const useStyle = makeStyles(({ palette: p }) => ({
  wrapper: ({ sortable }: StyleProps) => ({
    background: p.common.white,
    "&:hover": {
      cursor: sortable ? "pointer" : "default",
    },
    color: TABLE_HEADER_COLOR,
  }),
  inner: {
    display: "flex",
    alignItems: "center",
  },
  sort: ({ desc }: StyleProps) => ({
    transition: "0.2s",
    opacity: desc === undefined ? 0 : 1,
    transform: `rotate(${desc ? 0 : 180}deg)`,
    fontWeight: 600,
  }),
}));

interface HistoryViewTableHeaderProps {
  field?: HistoryViewTableField;
  desc?: boolean;
  onClick?: (field: HistoryViewTableField) => any;
}

const HistoryViewTableHeader: React.FC<HistoryViewTableHeaderProps> = ({
  field,
  desc,
  onClick,
}) => {
  const { label, sortable } = field
    ? HISTORY_VIEW_TABLE_META[field]
    : {
        label: "",
        sortable: false,
      };

  const onRowClick = useCallback(() => {
    if (onClick && field) onClick(field);
  }, [onClick, field]);

  const classes = useStyle({ desc, sortable });

  return (
    <TableCell
      className={classes.wrapper}
      onClick={sortable ? onRowClick : undefined}
    >
      {label && (
        <Box className={classes.inner}>
          <Typography variant="h6">{label}</Typography>

          {sortable && <ExpandMore className={classes.sort} />}
        </Box>
      )}
    </TableCell>
  );
};

export default memo(HistoryViewTableHeader);
