import { BluePrintStagesEnum } from "@deep-consulting-solutions/bmh-constants";
import { format } from "date-fns";
import {
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useMemo } from "react";
import { BloodTestResultWithConfirmKitInfo } from "redux/testResults";

const getDate = (date: string) => {
  try {
    const parsed = Date.parse(date);
    if (Number.isNaN(parsed)) {
      throw new Error("date is not a valid date string or number");
    }
    return new Date(date);
  } catch {
    return undefined;
  }
};

interface Style {
  isNotPendingKitShipping: boolean;
}

const useStyle = makeStyles<Theme, Style>(() => ({
  name: {},
  wrapper: {
    background: ({ isNotPendingKitShipping }) =>
      isNotPendingKitShipping ? "rgb(253, 236, 234)" : undefined,
  },
  stage: {
    color: ({ isNotPendingKitShipping }) =>
      isNotPendingKitShipping ? "rgb(97, 26, 21)" : undefined,
  },
}));

interface Props {
  bloodTestOrder: BloodTestResultWithConfirmKitInfo;
  showFirstColumn: boolean;
}

export const ConfirmKitPackingTableRow = ({
  bloodTestOrder,
  showFirstColumn,
}: Props) => {
  const { isNotPendingKitShipping, dob, testProfiles } = useMemo(() => {
    const date = getDate(bloodTestOrder.contact.dob);
    return {
      isNotPendingKitShipping:
        bloodTestOrder.stage !== BluePrintStagesEnum.PENDING_KIT_SHIPPING,
      dob: date ? format(date, "dd MMMM yyyy") : "",
      testProfiles: bloodTestOrder.testProfiles
        .map((testProfile) => testProfile.bmhCode)
        .join(", "),
    };
  }, [bloodTestOrder]);

  const classes = useStyle({ isNotPendingKitShipping });
  return (
    <TableRow className={classes.wrapper}>
      {showFirstColumn ? (
        <TableCell>
          <Typography
            variant="body2"
            align="center"
            className={clsx(classes.stage, classes.name)}
          >
            {bloodTestOrder.stage}
          </Typography>
        </TableCell>
      ) : null}
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {bloodTestOrder.sampleID}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {bloodTestOrder.contact.firstName} {bloodTestOrder.contact.lastName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {bloodTestOrder.contact.patientID}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {bloodTestOrder.contact.gender}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {dob}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {testProfiles}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="center" className={classes.name}>
          {bloodTestOrder.bloodTakingOption}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
