import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { TitleValue } from "components/TitleValue";
import { DBPharmacyProduct } from "types";

export type OtherFieldsProductDetails = Pick<
  DBPharmacyProduct,
  | "SyringeVolumeml"
  | "DosagePerml"
  | "PharmacyName"
  | "UsageUnitVolumeml"
  | "EnoughFor"
  | "UnitPrice"
  | "ZohoCrmId"
  | "ProductName"
  | "Description"
> & {
  MedsUsageUnit: { Name: string; DiscardRemaining: boolean };
  pharmacyZohoId?: string;
  unitsPerSellableUnit?: number;
  MedsUsageUnitName?: string;
  UsageUnitsPerSellableUnit?: number;
};

interface Props {
  isInjectionBundle?: boolean;
  isBacteriostatic?: boolean;
  selectedProduct: OtherFieldsProductDetails;
  dosingType?: string;
  ignoreDoseVolume?: boolean;
  forceDoubleLines?: boolean;
}
export const OtherFields = ({
  selectedProduct,
  isInjectionBundle,
  isBacteriostatic,
  dosingType,
  ignoreDoseVolume = false,
  forceDoubleLines = false,
}: Props) => {
  const doseVolume = useMemo(() => selectedProduct?.DosagePerml || "", [
    selectedProduct,
  ]);

  const isSingleLine = useMemo(() => {
    return forceDoubleLines ? false : isInjectionBundle || isBacteriostatic;
  }, [forceDoubleLines, isInjectionBundle, isBacteriostatic]);

  return (
    <>
      {selectedProduct?.Description && (
        <Grid item xs={4}>
          <TitleValue
            title="Description:"
            value={selectedProduct.Description}
            singleLine={isSingleLine}
          />
        </Grid>
      )}
      {selectedProduct?.PharmacyName && (
        <Grid item xs={2}>
          <TitleValue
            title="Pharmacy:"
            value={selectedProduct.PharmacyName}
            singleLine={isSingleLine}
          />
        </Grid>
      )}
      {dosingType === "Injection" &&
        !isInjectionBundle &&
        !isBacteriostatic &&
        !ignoreDoseVolume && (
          <Grid item xs={2}>
            <TitleValue
              title="Dose Volume:"
              value={doseVolume}
              singleLine={isSingleLine}
            />
          </Grid>
        )}
      {isBacteriostatic && (
        <Grid item xs={2}>
          <TitleValue
            title="Unit Volume:"
            value={selectedProduct.UsageUnitVolumeml || "N/A"}
            singleLine={isSingleLine}
          />
        </Grid>
      )}
      {isInjectionBundle && (
        <>
          <Grid item xs={2}>
            <TitleValue
              title="Enough For:"
              value={selectedProduct.EnoughFor || "N/A"}
              singleLine={isSingleLine}
            />
          </Grid>
          <Grid item xs={2}>
            <TitleValue
              title="Syringe Volume:"
              value={selectedProduct.SyringeVolumeml || "N/A"}
              singleLine={isSingleLine}
            />
          </Grid>
        </>
      )}
      {selectedProduct?.MedsUsageUnit?.DiscardRemaining && (
        <Grid item>
          <Typography>(Discard Remaining)</Typography>
        </Grid>
      )}
    </>
  );
};
