import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getFieldName,
  getValueFromObject,
  updateTreatmentMedicineProducts,
} from "helpers";
import React, { useEffect, useMemo, useRef } from "react";
import {
  TreatmentMedicineFormValues,
  DBPharmacyProduct,
  MedicineSelected,
  MedicineProduct,
  DBPharmacy,
  AutoCompleteOption,
  ZohoTreatmentTreatmentArea,
  DBTreatmentArea,
} from "types";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SupplementaryProduct } from "./SupplementaryProducts";
import { ProductForm } from "./ProductForm";
import { BillingCycleUnitField } from "../BillingCycleUnitField";
import { PharmaciesField } from "../PharmaciesField";
import { TreatmentBillingCycleValidationInfo } from "../TreatmentBillingCycleValidationInfo";

interface Props {
  name?: string;
  pharmProducts: DBPharmacyProduct[];
  pharmacies: DBPharmacy[];
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  dbTreatmentAreas: DBTreatmentArea[];
}

export interface TreatmentAreaValue {
  title: string;
  value: string;
  medicinesSelected: TreatmentMedicineFormValues[];
}

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    background: p.grey[100],
    borderRadius: spacing(0.5),
  },
  mb2: {
    marginBottom: spacing(2),
  },
}));

export const TreatmentMedicines: React.FC<Props> = ({
  name,
  pharmProducts,
  pharmacies,
  treatmentTreatmentAreasById,
  dbTreatmentAreas,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("treatmentMedicines", name), [
    name,
  ]);

  const treatmentAreaFieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const treatmentAreasValues: TreatmentAreaValue[] = useMemo(() => {
    return getValueFromObject(treatmentAreaFieldName, values) || [];
  }, [treatmentAreaFieldName, values]);

  const otherMedicineTypeId = useMemo(() => {
    const otherTreatmentAreaId =
      dbTreatmentAreas.find((area) => area.Name === "Other")?.ZohoCrmId || "";
    const otherTreatmentArea =
      treatmentTreatmentAreasById[otherTreatmentAreaId];
    return (otherTreatmentArea as any)?.medicineType.find(
      (t: any) => t.Name === "Other"
    )?.id;
  }, [dbTreatmentAreas, treatmentTreatmentAreasById]);

  const pharmaciesValue: AutoCompleteOption[] = useMemo(() => {
    return getValueFromObject("pharmacies", values) || [];
  }, [values]);

  const filteredPharmProducts = useMemo(() => {
    const selectedPharmacies = pharmaciesValue.map((pharmacy) => {
      const detailedPharm = pharmacies.find(
        (pharm) => pharm.ZohoCrmId === pharmacy.value
      );
      return detailedPharm?.Id || "";
    });
    return pharmProducts.filter((pharmProduct) =>
      selectedPharmacies.includes(pharmProduct.PharmacyId)
    );
  }, [pharmaciesValue, pharmacies, pharmProducts]);

  const medicines = useMemo(() => {
    return treatmentAreasValues.reduce(
      (acc: MedicineSelected[], treatmentAreaValue: TreatmentAreaValue) => {
        return acc.concat(
          treatmentAreaValue.medicinesSelected.map((medicineSelected) => ({
            ...medicineSelected,
            treatmentArea: {
              name: treatmentAreaValue.title,
              id: treatmentAreaValue.value,
            },
          }))
        );
      },
      []
    );
  }, [treatmentAreasValues]);

  const fieldValues: MedicineProduct[] = useMemo(() => {
    return getValueFromObject(fieldName, values) || [];
  }, [fieldName, values]);

  const medicineProducts = useMemo(() => {
    return fieldValues.map((fieldValue) => {
      const medicine = medicines.find(
        (medicineProduct) => medicineProduct.value === fieldValue.medicineId
      );
      return {
        ...fieldValue,
        medicine,
      };
    });
  }, [fieldValues, medicines]);

  const injectionBundles: DBPharmacyProduct[] = useMemo(() => {
    const filteredProducts = filteredPharmProducts.filter(
      (pharmProduct) => pharmProduct.ProductType === "Injection Bundle"
    );

    return filteredProducts.map((product) => {
      const treatmentArea = dbTreatmentAreas.find(
        (area) => area.Id === product.TreatmentAreaId
      );
      return {
        ...product,
        TreatmentAreaId: treatmentArea?.ZohoCrmId || product.TreatmentAreas,
      };
    });
  }, [filteredPharmProducts, dbTreatmentAreas]);

  const supplementaryProducts = useMemo(
    () =>
      filteredPharmProducts.filter(
        (pharmProduct) => pharmProduct.ProductType === "Supplementary"
      ),
    [filteredPharmProducts]
  );

  const manualOrAuto = useMemo(() => {
    return getValueFromObject("manualOrAutoSelection", values);
  }, [values]);

  const fieldValuesRef = useRef(fieldValues);
  fieldValuesRef.current = fieldValues;

  useEffect(() => {
    if (manualOrAuto === "manual") {
      updateTreatmentMedicineProducts(
        treatmentAreasValues,
        fieldValuesRef.current,
        setFieldValue
      );
    }
  }, [treatmentAreasValues, manualOrAuto, setFieldValue]);

  if (manualOrAuto !== "manual") {
    return null;
  }

  return (
    <Box mt={4} mb={2}>
      <Grid container spacing={4}>
        <Grid item>
          <PharmaciesField dbPharmacies={pharmacies} />
        </Grid>
        <Grid item>
          <BillingCycleUnitField />
        </Grid>
      </Grid>
      <Box mb={2}>
        <Typography variant="h5">Products</Typography>
        <Typography variant="body1">
          Products should match the selected CTTP
        </Typography>
      </Box>
      {medicineProducts.map((product, index) => (
        <Accordion
          defaultExpanded
          className={clsx(classes.wrapper, classes.mb2)}
          key={`${product.product?.value}@#${product.product?.title}@#${product?.medicine?.title}`}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {product?.medicine?.title}{" "}
              <span>({product?.medicine?.treatmentArea.name})</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <ProductForm
                index={index}
                medicine={product.medicine!}
                pharmProducts={filteredPharmProducts}
                injectionBundles={injectionBundles}
                supplementaryProducts={supplementaryProducts}
                fieldName={`${fieldName}.${index}`}
                otherMedicineTypeId={otherMedicineTypeId}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      <Box className={classes.wrapper} padding={2} mb={4}>
        <Box mb={2}>
          <Box mb={2}>
            <Typography>Supplementary Products</Typography>
          </Box>
          <SupplementaryProduct products={supplementaryProducts} />
        </Box>
      </Box>

      <TreatmentBillingCycleValidationInfo />
    </Box>
  );
};
