import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import React, { useMemo } from "react";

interface VoidRadioInputFieldProps {
  fieldName: string;
}

export enum VoidOrNot {
  VOID = "void",
  NOT_VOID = "not_void",
}

export const VoidRadioInputField: React.FC<VoidRadioInputFieldProps> = ({
  fieldName,
}) => {
  const { getFieldMeta } = useFormikContext();
  const fieldMeta = useMemo(() => getFieldMeta(fieldName), [
    fieldName,
    getFieldMeta,
  ]);

  return (
    <>
      <Field component={RadioGroup} row name={fieldName}>
        <FormControlLabel
          value={VoidOrNot.VOID}
          control={<Radio color="primary" />}
          label="Void"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <FormControlLabel
          value={VoidOrNot.NOT_VOID}
          control={<Radio color="primary" />}
          label="Don't Void"
        />
      </Field>
      {fieldMeta.error && fieldMeta.touched && (
        <Typography color="error" variant="caption">
          {fieldMeta.error}
        </Typography>
      )}
    </>
  );
};
