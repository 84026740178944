import { getENText } from "helpers";
import * as Yup from "yup";
import { FormValues, RetestErrorEnum } from "./types";

export const initialValues: FormValues = {
  reason: "",
  changeBloodTakingOptionFromFingerPrickToVenepuncture: false,
};

export const validationSchema = Yup.object({
  reason: Yup.string().required(
    getENText("retestRequiredNotClientError.reason.required")
  ),
  changeBloodTakingOptionFromFingerPrickToVenepuncture: Yup.boolean(),
});

const infoComponentText: Record<RetestErrorEnum, string> = {
  [RetestErrorEnum.NotClientError]:
    "When you click confirm, an invoice for a blood draw kit will be automatically created and sent to the client with an explanation about how they need to repeat the test.",
  [RetestErrorEnum.ClientError]:
    "When you click confirm, charging the client for the retest will be sent to management for approval.",
};

export const getInfoComponentText = (variant: RetestErrorEnum) =>
  infoComponentText[variant];

export const getPayload = (values: FormValues, isFingerPrick: boolean) => {
  const payload: {
    reason: string;
    changeBloodTakingOptionFromFingerPrickToVenepuncture?: boolean;
  } = {
    reason: values.reason,
  };

  if (isFingerPrick) {
    payload.changeBloodTakingOptionFromFingerPrickToVenepuncture =
      values.changeBloodTakingOptionFromFingerPrickToVenepuncture;
  }

  return payload;
};
