export const TABLE_META = {
  test: {
    width: 250,
  },
  result: {
    width: 150,
  },
  range: {
    width: 150,
  },
  unit: {
    width: 100,
  },
};
