import React, { memo, useCallback } from "react";
import { makeStyles, Typography, Radio, Link } from "@material-ui/core";

import { ConvertedPathwayRecord } from "../../Unmatched.types";
import { ROW_HEIGHT, getBGHover } from "../../Unmatched.styles";
import {
  getPathwayRecordLink,
  displayPathwayRecord,
} from "../../Unmatched.helpers";

interface StyleProps {
  selected: boolean;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  selectRow: ({ selected }: StyleProps) => ({
    height: `${ROW_HEIGHT}px`,
    display: "flex",
    alignItems: "center",
    paddingLeft: s(1),
    paddingRight: s(1),
    transition: "0.2s",
    background: selected ? p.grey[200] : undefined,

    "&:last-child": {
      borderBottom: "none",
    },

    "&:hover": {
      background: getBGHover(p),
    },
  }),
  radio: {
    marginLeft: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface PossiblePathwayRowProps {
  pathway: ConvertedPathwayRecord;
  selected: boolean;
  onSelect: (p: ConvertedPathwayRecord) => any;
  disableUpdate: boolean;
}

const PossiblePathwayRow: React.FC<PossiblePathwayRowProps> = ({
  pathway,
  selected,
  onSelect,
  disableUpdate,
}) => {
  const onRowClick = useCallback(() => {
    onSelect(pathway);
  }, [onSelect, pathway]);

  const classes = useStyle({ selected });

  return (
    <div className={classes.selectRow} data-testid="possible-pathway">
      <Link href={getPathwayRecordLink(pathway)} target="_blank">
        <Typography>{displayPathwayRecord(pathway)}</Typography>
      </Link>
      <Radio
        className={classes.radio}
        color="primary"
        size="small"
        checked={selected}
        onClick={onRowClick}
        disabled={disableUpdate}
      />
    </div>
  );
};

export default memo(PossiblePathwayRow);
