import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import { InitialValues } from "./types";

type ActionButtonsProps = {
  activeStep: number;
  goBack: () => void;
};

export const ActionButtonsAlt = ({
  activeStep,
  goBack,
}: ActionButtonsProps) => {
  const {
    isValid,
    submitForm,
    isSubmitting,
    values,
  } = useFormikContext<InitialValues>();
  const show =
    activeStep !== 0 &&
    ["cancel-checkup", "update-checkup-date"].includes(
      values.checkupActionType
    );

  return (
    <>
      {show ? (
        <Box
          marginY={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridColumnGap="8px"
        >
          <Button
            style={{
              textTransform: "capitalize",
              minWidth: "110px",
            }}
            color="primary"
            variant="outlined"
            onClick={goBack}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "capitalize",
              minWidth: "110px",
            }}
            color="primary"
            variant="contained"
            onClick={submitForm}
            disabled={!isValid || isSubmitting}
          >
            Confirm
          </Button>
        </Box>
      ) : null}
    </>
  );
};
