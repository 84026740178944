import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ spacing, palette: p }) => {
  return {
    fieldsSection: {
      background: "white",
      padding: spacing(2),
      borderRadius: spacing(0.5),
    },
    checkbox: {
      color: p.primary.main,
    },
    noVerticalPadding: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    mb2: {
      marginBottom: spacing(2),
    },
    verticalAlignBottom: {
      verticalAlign: "text-bottom",
    },
  };
});
