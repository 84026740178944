import React, { memo } from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

import { ConvertedPathwayRecord } from "../../Unmatched.types";
import { ROW_HEIGHT, getBorder, getCaptionColor } from "../../Unmatched.styles";
import PossiblePathwayRow from "./PossiblePathwayRow";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  row: {
    height: `${ROW_HEIGHT}px`,
    display: "flex",
    alignItems: "center",
    borderTop: getBorder(p),
    paddingLeft: s(1),
    paddingRight: s(1),
  },
  selectWrapper: {
    height: `${ROW_HEIGHT * 3}px`,
    borderTop: getBorder(p),
    overflow: "auto",
  },
  searchButton: {
    marginLeft: s(1),
  },
  caption: {
    color: getCaptionColor(p),
    marginLeft: "auto",
  },
  nodata: {
    color: p.grey[500],
    textAlign: "center",
    marginTop: s(1),
  },
}));

interface PossiblePathwaysProps {
  pathways: ConvertedPathwayRecord[];
  selectId: string | null;
  onSelect: (p: ConvertedPathwayRecord) => any;
  onSearchClick: () => any;
  disableUpdate: boolean;
}

const PossiblePathways: React.FC<PossiblePathwaysProps> = ({
  pathways,
  selectId,
  onSelect,
  onSearchClick,
  disableUpdate,
}) => {
  const classes = useStyle();

  return (
    <div data-testid="possible-pathways">
      <Box className={classes.row}>
        <Typography variant="h6">
          Blood Test Order Records ({pathways.length})
        </Typography>
        <Tooltip title="Search for pathways">
          <IconButton
            className={classes.searchButton}
            size="small"
            onClick={onSearchClick}
            disabled={disableUpdate}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="body2" className={classes.caption}>
          Select for data replacing
        </Typography>
      </Box>

      <Box className={classes.selectWrapper}>
        {!pathways.length && (
          <Typography variant="body2" className={classes.nodata}>
            No matching Blood Test Order records are found!
          </Typography>
        )}
        {pathways
          .sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA > nameB ? 1 : -1;
          })
          .map((p) => {
            return (
              <PossiblePathwayRow
                key={p.id}
                pathway={p}
                onSelect={onSelect}
                selected={selectId === p.id}
                disableUpdate={disableUpdate}
              />
            );
          })}
      </Box>
    </div>
  );
};

export default memo(PossiblePathways);
