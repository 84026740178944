import {
  UnmatchedHL7,
  ClientRecord,
  PathwayRecord,
} from "@deep-consulting-solutions/bmh-constants";

export type UnmatchedHL7Field = keyof UnmatchedHL7;

export const FILE_FIELDS_META: {
  [key in UnmatchedHL7Field]?: {
    name: string;
    disabled?: boolean;
  };
} = {
  sampleId: {
    name: "Sample ID",
  },
  sampleCollectedOn: {
    name: "Date of Test",
    disabled: true,
  },
  patientId: {
    name: "Patient ID",
  },
  firstName: {
    name: "First Name",
  },
  lastName: {
    name: "Last Name",
  },
  dob: {
    name: "Date of Birth",
  },
  gender: {
    name: "Gender",
  },
};

export type ConvertedClientRecord = Omit<ClientRecord, "pathways">;

export interface ConvertedPathwayRecord extends PathwayRecord {
  client: ConvertedClientRecord;
}
