import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { apiClient } from "apis";
import {
  Email,
  Domain,
  EmailConfig,
  GetDomainsRes,
  GetFromEmailsRes,
  PostFromEmailsRes,
  GetEmailConfigsRes,
  PatchEmailConfigsRes,
  EmailConfigNameEnum,
  emailConfigEndpoints,
} from "@deep-consulting-solutions/bmh-constants";
import { AppState, EmailConfigState } from "redux/types";

const ENTITY = "email-config";

const fromEmailsAdapter = createEntityAdapter<Email>();
const domainsAdapter = createEntityAdapter<Domain>();
const emailConfigsAdapter = createEntityAdapter<EmailConfig>({
  selectId: (emailConfig) => emailConfig.name,
});

const emailConfigsInitialState = {
  fromEmails: fromEmailsAdapter.getInitialState(),
  domains: domainsAdapter.getInitialState(),
  emailConfigs: emailConfigsAdapter.getInitialState(),
};

const fromEmailsSelectors = fromEmailsAdapter.getSelectors<AppState>(
  (state) => state.emailConfig.fromEmails
);
const domainsSelectors = domainsAdapter.getSelectors<AppState>(
  (state) => state.emailConfig.domains
);
const emailConfigsSelectors = emailConfigsAdapter.getSelectors<AppState>(
  (state) => state.emailConfig.emailConfigs
);

const getFromEmails = createAsyncThunk<Email[], void>(
  `${ENTITY}/getFromEmails`,
  async () => {
    const res = await apiClient.get<GetFromEmailsRes>(
      emailConfigEndpoints.fromEmails
    );
    return res.data.data.fromEmails;
  }
);

const postFromEmails = createAsyncThunk<Email, { email: string }>(
  `${ENTITY}/postFromEmails`,
  async ({ email }) => {
    const res = await apiClient.post<PostFromEmailsRes>(
      emailConfigEndpoints.fromEmails,
      {
        email,
      }
    );
    return res.data.data;
  }
);

const getDomains = createAsyncThunk<Domain[], void>(
  `${ENTITY}/getDomains`,
  async () => {
    const res = await apiClient.get<GetDomainsRes>(
      emailConfigEndpoints.domains
    );
    return res.data.data.verifiedDomains;
  }
);

const postDomains = createAsyncThunk<void, { domain: string }>(
  `${ENTITY}/postDomains`,
  async ({ domain }) => {
    await apiClient.post(emailConfigEndpoints.domains, { domain });
  }
);

const getEmailConfigs = createAsyncThunk<EmailConfig[], void>(
  `${ENTITY}/getEmailConfigs`,
  async () => {
    const res = await apiClient.get<GetEmailConfigsRes>(
      emailConfigEndpoints.emailConfigs
    );
    return res.data.data;
  }
);

const patchEmailConfigs = createAsyncThunk<
  EmailConfig,
  { id: string; fromId: string; replyTo: string; senderName: string }
>(
  `${ENTITY}/patchEmailConfigs`,
  async ({ id, fromId, replyTo, senderName }) => {
    const res = await apiClient.patch<PatchEmailConfigsRes>(
      emailConfigEndpoints.patchEmailConfigs(id),
      {
        fromId,
        replyTo: replyTo || null,
        senderName,
      }
    );
    return res.data.data;
  }
);

const slice = createSlice<
  EmailConfigState,
  SliceCaseReducers<EmailConfigState>
>({
  name: ENTITY,
  initialState: emailConfigsInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getFromEmails.fulfilled, (state, action) => {
        fromEmailsAdapter.setAll(state.fromEmails, action.payload);
      })
      .addCase(postFromEmails.fulfilled, (state, action) => {
        fromEmailsAdapter.addOne(state.fromEmails, action.payload);
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        domainsAdapter.setAll(state.domains, action.payload);
      })
      .addCase(getEmailConfigs.fulfilled, (state, action) => {
        emailConfigsAdapter.setAll(state.emailConfigs, action.payload);
      })
      .addCase(patchEmailConfigs.fulfilled, (state, action) => {
        emailConfigsAdapter.upsertOne(state.emailConfigs, action.payload);
      }),
});

const getEmailConfigByName = (name: EmailConfigNameEnum) => (state: AppState) =>
  emailConfigsSelectors.selectById(state, name);

export const emailConfigActions = {
  ...slice.actions,
  getFromEmails,
  postFromEmails,
  getDomains,
  postDomains,
  getEmailConfigs,
  patchEmailConfigs,
};

export const emailConfigSelectors = {
  fromEmailsSelectors,
  domainsSelectors,
  emailConfigsSelectors,
  getEmailConfigByName,
};

export default slice.reducer;
