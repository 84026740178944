import { Box, Grid } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import {
  getAlcoholWipesQuantityString,
  getDurationString,
  getInjectionBundleQuantityString,
} from "helpers";
import React, { useMemo } from "react";
import { DBPharmacyProduct } from "types";
import { NumberInputField } from "components/Treatments";
import {
  ParentOrderItem,
  TreatmentOrder,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";
import { InjectionDetails } from "./InjectionDetails";
import { HandleMissingItemsFields } from "./HandleMissingItemsFields";

interface ChildInjectionBundleItemProps {
  childItem: TreatmentOrderItem;
  parentItem: ParentOrderItem;
  manufacturers: string[];
  name: string;
  index: number;
  handleMissingItems?: boolean;
  treatmentOrder?: TreatmentOrder;
  replacements?: DBPharmacyProduct[];
}

export const ChildInjectionBundleItem: React.FC<ChildInjectionBundleItemProps> = ({
  childItem,
  parentItem,
  manufacturers,
  name,
  index,
  handleMissingItems,
  treatmentOrder,
  replacements,
}) => {
  const durationString = useMemo(() => getDurationString(childItem), [
    childItem,
  ]);
  const quantityString = useMemo(
    () => getInjectionBundleQuantityString(parentItem),
    [parentItem]
  );
  const wipesQuantityString = useMemo(
    () => getAlcoholWipesQuantityString(parentItem),
    [parentItem]
  );
  const fieldName = useMemo(() => `${name}.children.${index}`, [name, index]);

  return (
    <>
      <Box mb={2}>
        <Grid spacing={3} container>
          <Grid item xs={4}>
            <TitleValue title="Name" value={childItem.Prescription_Name} />
          </Grid>
          <Grid item xs={2}>
            <TitleValue title="Quantity" value={childItem.Quantity} />
          </Grid>
          <Grid item xs={3}>
            <TitleValue title="Enough For" value={durationString} />
          </Grid>
          {handleMissingItems && (
            <Grid item xs={3}>
              <Box ml="auto" maxWidth={180}>
                <NumberInputField
                  label="Missing Quantity"
                  name={`${fieldName}.missingQuantity`}
                  required={false}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mb={2}>
        {handleMissingItems && (
          <HandleMissingItemsFields
            manufacturers={manufacturers}
            item={childItem}
            fieldName={fieldName}
            treatmentOrder={treatmentOrder}
            replacements={replacements!}
          />
        )}
      </Box>
      <Box mb={2}>
        <InjectionDetails
          childItem={childItem}
          fieldName={fieldName}
          quantityString={quantityString}
          wipesQuantityString={wipesQuantityString as string}
        />
      </Box>
      {childItem.productItem?.Extra_Syringe_Volume_ml &&
        childItem.productItem?.Extra_Injection_Type && (
          <InjectionDetails
            childItem={childItem}
            fieldName={fieldName}
            quantityString={quantityString}
            wipesQuantityString={wipesQuantityString as string}
            hasExtraSyringe
          />
        )}
    </>
  );
};
