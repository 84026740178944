import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyle = makeStyles(({ spacing: s }) => ({
  clearBtn: {
    textTransform: "none",
    marginRight: s(2),
  },
}));

interface ClearAllFiltersButtonProps {
  onClick: () => any;
  disabled?: boolean;
}

export const ClearAllFiltersButton: React.FC<ClearAllFiltersButtonProps> = ({
  onClick,
  disabled,
}) => {
  const classes = useStyle();
  return (
    <Button
      className={classes.clearBtn}
      variant="outlined"
      onClick={onClick}
      color="primary"
      disabled={disabled}
    >
      Clear all filters
    </Button>
  );
};
