import { Box, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  reason: string;
}

export const Approval = ({ reason }: Props) => {
  return (
    <>
      <Typography variant="subtitle2">
        Retest Decision Explanation and Failure Reason
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">{reason}</Typography>
      </Box>
    </>
  );
};
