import { crhClient, n8nApiClient, silentClient } from "apis";
import { zohoServices } from "services";
import {
  FetchManufacturersResponse,
  FetchPicklistResponse,
  FetchReplacementProductsResponse,
  FetchTreatmentOrdersResponse,
  ProductItem,
  ProductItemVariant,
  TreatmentOrder,
} from "widgets/DispenseDrugs";

export const fetchPicklist = async (orderId: string) => {
  const res = await n8nApiClient.post<FetchPicklistResponse>(
    `/get-picklist-medicine-label-doc`,
    {
      documentType: "Picklist",
      zohoID: orderId,
      module: "Treatment_Orders",
    }
  );

  return res.data;
};

export const fetchTreatmentOrders = async (orderId: string) => {
  const res = await silentClient.get<FetchTreatmentOrdersResponse>(
    `/functional-use-case/uib/get-treatment-order-details/${orderId}`
  );
  return res.data.data;
};

export const fetchReplacementProducts = async (pharmacy: string) => {
  const res = await crhClient.post<FetchReplacementProductsResponse>("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/Products?where=(PharmacyName,eq,${pharmacy})~and(State,eq,Active)&nested[MedicineTypes][fields]=Id,Name&limit=1000`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data.data?.list || [];
};

export const fetchManufacturers = async () => {
  const res = await n8nApiClient.get<FetchManufacturersResponse>(
    `/get-manufacturers-picklist-values`
  );

  return res.data.manufacturers;
};

export const fetchMedicineLabels = async (labels: any, orderId: string) => {
  const payload = {
    documentType: "Medicine Label",
    zohoID: orderId,
    module: "Treatment_Orders_Items",
    dynamicData: labels,
  };
  const res = await n8nApiClient.post<{ url: string }>(
    "/get-picklist-medicine-label-doc",
    payload
  );
  return res.data;
};

export const sendToBmh = async (payload: any) => {
  const res = await n8nApiClient.post("/resolve-unavailability", payload);
  return res.data;
};

export const markAsOwed = async (orderId: string) => {
  const res = await n8nApiClient.post(
    `/treatment-order/${orderId}/mark-as-owed`
  );
  return res.data;
};

export const printFinalCheck = async (payload: any) => {
  const res = await silentClient.post(
    "/functional-use-case/uib/print-final-checks",
    payload
  );
  return res.data;
};

export async function fetchTreatmentOrder(orderId: string) {
  const response = await zohoServices.getRecord<TreatmentOrder>({
    Entity: "Treatment_Orders",
    RecordID: orderId,
  });
  return response;
}

export async function fetchTreatmentOrderItems(orderId: string) {
  const response = await zohoServices.getRelatedRecords<any>({
    Entity: "Treatment_Orders",
    RecordID: orderId,
    RelatedList: "Treatment_Order_Items",
  });
  return response;
}

export async function fetchTreatmentOrderItem(itemId: string) {
  const response = await zohoServices.getRecord<any>({
    Entity: "Treatment_Orders_Items",
    RecordID: itemId,
  });
  return response;
}

export async function fetchRecordCrmLink(recordId: string, module: string) {
  const res = await n8nApiClient.get<{ crmLink: string }>(
    `/crm-link/${module}/${recordId}`
  );
  return res.data;
}

export async function requestChanges(payload: {
  requestedChanges: string;
  treatmentOrderId: string;
}) {
  const res = await n8nApiClient.post("/request-order-changes", payload);
  return res.data;
}

export async function approveDispensedOrder(orderId: string) {
  const res = await n8nApiClient.post(
    `/treatment-order/${orderId}/approve-dispensed-order`
  );
  await n8nApiClient.post("/treatment-order/email-delivery-change-note", {
    treatmentOrderId: orderId,
  });
  await zohoServices.updateBlueprint({
    Entity: "Treatment_Orders",
    RecordID: orderId,
    transitionName: "Perform Final Check",
  });
  return res.data;
}

export const fetchProductByGTIN = async (gtin: string) => {
  const res = await crhClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/Products?where=(GTIN,eq,${gtin})&[fields]=ZohoCrmId`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data?.data;
};

export const fetchProductByBarcode = async (barcode: string) => {
  const res = await crhClient.post("/crm", {
    url: `/api/v1/db/data/v1/CustomBackend/Products?where=(Barcode,eq,${barcode})&[fields]=ZohoCrmId`,
    app: "NOCODB",
    method: "GET",
  });
  return res.data?.data;
};

export const generatePackingSlip = async (orderId: string) => {
  const res = await n8nApiClient.post<{ url: string }>(
    `/generate-packing-slip-document`,
    {
      treatmentOrderId: orderId,
    }
  );
  return res.data;
};

export async function fetchProduct(productId: string) {
  const response = await zohoServices.getRecord<ProductItem>({
    Entity: "Products",
    RecordID: productId,
  });
  return response;
}

export const findSimilarProductVariants = async (payload: {
  Product: string;
  Manufacturer: string;
  Barcode: string;
}) => {
  const res = await n8nApiClient.post<{ variants: ProductItemVariant[] }>(
    `/find-similar-product-variants`,
    payload
  );
  return res.data;
};

export const saveVariant = async (payload: {
  Product: string;
  Manufacturer: string;
  Barcode: string;
  Barcode_Type: string;
  GTIN: string;
}) => {
  const res = await n8nApiClient.post<{ variants: ProductItemVariant[] }>(
    `/create-product-variant`,
    payload
  );
  return res.data;
};

export async function requestClarification(payload: {
  prescriptionZohoId: string;
  pharmacyNotes: string;
}) {
  const res = await n8nApiClient.post(
    "/request-prescription-clarification",
    payload
  );
  return res.data;
}

export async function provideClarification(payload: {
  prescriptionZohoId: string;
  dosingExplanation: string;
  incorrect: boolean;
  incorrectExplanation: string;
}) {
  const res = await n8nApiClient.post("/provide-clarification", payload);
  return res.data;
}
