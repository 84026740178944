import React, { useMemo } from "react";
import { ZohoBMHDoctor, ZohoTreatment } from "types";

import { Box, Typography } from "@material-ui/core";

interface Props {
  newTreatmentPlan: ZohoTreatment | null;
  doctor: ZohoBMHDoctor | null;
  newTreatmentDoctor: string;
  hasPaidTreatmentOrders: boolean;
  paidTreatmentOrdersMapped: any[];
  isSuccessWithNoNewTreatmentPlan: boolean;
}

export const NewTreatmentPlanInfo = ({
  newTreatmentPlan,
  doctor,
  newTreatmentDoctor,
  hasPaidTreatmentOrders,
  paidTreatmentOrdersMapped,
  isSuccessWithNoNewTreatmentPlan,
}: Props) => {
  const treatmentOrdersText = useMemo(() => {
    if (hasPaidTreatmentOrders) {
      return (
        <>
          The orders (
          {paidTreatmentOrdersMapped.map((o) => (
            <a target="_blank" rel="noreferrer" href={o?.recordCrmLink}>
              {o.Name}
            </a>
          ))}
          ) related to this treatment were already paid and are still in
          progress. Cancel and refund or credit them if needed.
        </>
      );
    }
    return "";
  }, [hasPaidTreatmentOrders, paidTreatmentOrdersMapped]);

  const text = useMemo(() => {
    if (!newTreatmentPlan?.treatmentState) {
      return "";
    }
    if (doctor?.id) {
      return "";
    }

    if (newTreatmentPlan.treatmentState === "Pending Doctor Approval") {
      return `It needs to be approved by ${newTreatmentDoctor} to be progressed to medicine products and packages selection.`;
    }

    if (newTreatmentPlan.treatmentState === "Details Specified") {
      return `The matching medicine products and packages are being calculated automatically. ${treatmentOrdersText}`;
    }
    return `This new treatment is ${newTreatmentPlan.treatmentState}. ${treatmentOrdersText}`;
  }, [
    doctor?.id,
    newTreatmentPlan?.treatmentState,
    newTreatmentDoctor,
    treatmentOrdersText,
  ]);

  if (isSuccessWithNoNewTreatmentPlan) {
    return (
      <Box>
        <Typography align="center">Treatment Updated Successfully.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography align="center">
        A new treatment named{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={newTreatmentPlan?.treatmentUrl}
        >
          {newTreatmentPlan?.treatmentName}
        </a>{" "}
        was created. {text}
      </Typography>
    </Box>
  );
};
