import React, { memo, useCallback } from "react";
import { makeStyles, Button, ButtonProps, Tooltip } from "@material-ui/core";

import { testResultsRequests } from "redux/testResults";

import { truncateFileName } from "../TestResults.helpers";
import { useLoadingContext } from "../LoadingContext";

const truncatePDFName = (name?: string) => {
  if (!name) {
    return {
      truncated: "OPEN PDF",
      isTruncated: false,
    };
  }
  return truncateFileName(name);
};

const useStyle = makeStyles(() => ({
  btn: ({ hasName }: { hasName: boolean }) => ({
    whiteSpace: "nowrap",
    fontStyle: "underline",
    textTransform: "none",
    "&:hover": {
      fontStyle: hasName ? "underline" : undefined,
    },
  }),
}));

interface OpenPDFBtnProps extends Omit<ButtonProps, "onClick"> {
  id: string;
  pdfName?: string;
}

const OpenPDFBtn: React.FC<OpenPDFBtnProps> = ({
  id,
  className,
  pdfName,
  ...props
}) => {
  const { setLoading } = useLoadingContext();

  const onClick = useCallback(async () => {
    setLoading(true);
    try {
      const link = await testResultsRequests.fetchResultPDFUrl({ id });
      setLoading(false);
      window.open(link, "_blank");
    } catch {
      setLoading(false);
    }
  }, [setLoading, id]);

  const classes = useStyle({ hasName: !!pdfName });
  const fullClass = `${classes.btn} ${className || ""}`;
  const value = truncatePDFName(pdfName);

  return (
    <Tooltip title={value.isTruncated ? pdfName || "" : ""}>
      <Button
        className={fullClass}
        variant="text"
        onClick={onClick}
        color="primary"
        size={pdfName ? "small" : "medium"}
        {...props}
      >
        {value.truncated}
      </Button>
    </Tooltip>
  );
};

export default memo(OpenPDFBtn);
