import React from "react";
import { Box, Typography, Grid, Paper } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";

import { CountryField } from "components/Treatments/CountryField";
import { countries } from "configs";
import { CustomInputField } from "components/CustomInputField";
import { CustomCheckboxField } from "components/CustomCheckboxField";
import { CustomNumberField } from "components/CustomNumberField";
import { CustomTextareaField } from "components/CustomTextareaField";
import { InitialValues } from "./types";

export const OrderForm = () => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <Box minHeight="500px">
      <Grid container spacing={3} style={{ width: "80%" }}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "#6B7280",
                fontWeight: "bold",
              }}
            >
              Patient Shipping Address
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <CountryField countries={countries} />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="province" label="Province" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="city" label="City" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="postalCode" label="Postal Code" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="street" label="Street" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField
            name="apartment"
            label="Apartment / Flat / Floor / Other"
            required={false}
          />
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "#6B7280",
                fontWeight: "bold",
              }}
            >
              Order Items
            </Typography>
          </Box>
        </Grid>
        <FieldArray name="orderItems">
          {() => (
            <>
              {values.orderItems.length > 0 &&
                values.orderItems.map((orderItem, index) => (
                  <Paper
                    key={orderItem.id}
                    variant="outlined"
                    style={{
                      margin: "8px auto",
                      width: "100%",
                      padding: "16px",
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={3}>
                      <Grid item sm={1}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="start"
                        >
                          <CustomCheckboxField
                            name={`orderItems.${index}.isSelected`}
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={4}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="start"
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              color: "#6B7280",
                            }}
                          >
                            Item
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            {orderItem.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={3}>
                        <Box
                          display="flex"
                          alignItems="start"
                          color="text.secondary"
                          mb={1}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#6B7280",
                              fontWeight: "bold",
                            }}
                          >
                            Quantity *
                          </Typography>
                        </Box>
                        <CustomNumberField
                          name={`orderItems.${index}.quantity`}
                          disabled={orderItem.remainingQty === 0}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Box>
                          <Box mb={1}>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#6B7280",
                                fontWeight: "bold",
                                width: "100%",
                              }}
                            >
                              Usage Notes *
                            </Typography>
                          </Box>
                          <CustomTextareaField
                            name={`orderItems.${index}.notes`}
                            rows={1}
                            rowsMax={3}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </>
          )}
        </FieldArray>
      </Box>
    </Box>
  );
};
