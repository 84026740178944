import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { ZohoDosingAdminRoute, ZohoMedicineAdminRoute } from "types";
import { getFieldName, getValueFromObject } from "helpers";
import { useStyles } from "../styles";
import { AdministrationRouteField } from "./AdministrationRouteField/AdministrationRouteField";

interface Props {
  name?: string;
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}

export const AdministrationRouteFields = ({
  name,
  dosingAdminRoutes,
  medicineAdminRoutes,
}: Props) => {
  const { values } = useFormikContext();
  const classes = useStyles();

  const dosingTypeFieldName = useMemo(() => getFieldName("dosingType", name), [
    name,
  ]);

  const dosingTypeFieldValue = useMemo(
    () => getValueFromObject(dosingTypeFieldName, values),
    [values, dosingTypeFieldName]
  );

  const administrationRouteFieldName = useMemo(
    () => getFieldName("administrationRoute", name),
    [name]
  );

  const administrationRouteFieldValue = useMemo(
    () => getValueFromObject(administrationRouteFieldName, values),
    [values, administrationRouteFieldName]
  );

  const bodyPart = useMemo(() => {
    if (!administrationRouteFieldValue?.value || !dosingTypeFieldValue?.value) {
      return false;
    }
    const dosingAdminRoute = dosingAdminRoutes.find(
      (d) =>
        d.Administration_Routes.id === administrationRouteFieldValue.value &&
        d.Dosing_Types.id === dosingTypeFieldValue.value
    );
    return dosingAdminRoute?.Body_Part === "Required" ||
      dosingAdminRoute?.Body_Part === "Optional"
      ? dosingAdminRoute?.Body_Part
      : false;
  }, [
    dosingAdminRoutes,
    administrationRouteFieldValue?.value,
    dosingTypeFieldValue?.value,
  ]);

  return (
    <Box mb={2} className={classes.fieldsSection}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={3}>
          <AdministrationRouteField
            dosingAdminRoutes={dosingAdminRoutes}
            name={name}
            medicineAdminRoutes={medicineAdminRoutes}
          />
        </Grid>
        <Grid item xs={3}>
          {bodyPart ? (
            <Field
              component={TextField}
              name={`${name}.bodyPart`}
              label="Body Part"
              disabled={!administrationRouteFieldValue}
              required={bodyPart === "Required"}
              size="small"
              fullWidth
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
