import React, { useCallback, useEffect, useState } from "react";
import { notifications, zohoServices } from "services";
import { Formik, FormikConfig } from "formik";
import { Box, CircularProgress, Link } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { manualOrderValidationSchema } from "helpers";
import { ukCountries } from "configs";
import { zohoSelectors } from "redux/zoho";
import { useSelector } from "react-redux";
import { ManualOrderRequestPayload } from "types";
import {
  createManualOrder,
  getPrescription,
  getPrescriptionRelatedOrder,
} from "services/treatment-orders";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { ActionButtons } from "./ActionButtons";
import { CreateManualOrderForm } from "./CreateManualOrderForm";
import { InitialValues } from "./types";

export const CreateManualOrder = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [relatedOrder, setRelatedOrder] = useState<{
    orderName: string;
    orderUrl: string;
  } | null>(null);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [country, setCountry] = useState("");

  const [prescriptionId] = useSelector(zohoSelectors.getIds);

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const items = values?.orderItems
          .filter((i) => i.isSelected)
          .map((i) => ({
            prescriptionItemId: i.id,
            quantity: i.quantity as number,
            usageNotes: i.notes,
          })) as ManualOrderRequestPayload["items"];

        const payload = {
          prescriptionId,
          street: values?.street,
          apartment: values?.apartment,
          city: values?.city,
          province: values?.province,
          postCode: values?.postalCode,
          country: values?.country?.value || "",
          items,
        };

        await createManualOrder(payload);
        closeWidget(true);
      } catch (error) {
        notifications.notifyError(
          "Something went wrong and the action could not be completed."
        );
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, prescriptionId]
  );

  const fetchData = useCallback(async () => {
    try {
      const [relatedOrderRes, prescriptionData] = await Promise.all([
        getPrescriptionRelatedOrder(prescriptionId),
        getPrescription(prescriptionId),
      ]);

      if (relatedOrderRes?.hasPendingDispensingOrder) {
        setRelatedOrder({
          orderName: relatedOrderRes.orderName,
          orderUrl: relatedOrderRes.orderUrl,
        });
        return;
      }

      if (prescriptionData) {
        const { PrescriptionItems, Patient } = prescriptionData;
        let countryLabel = (Patient.Shipping_Country || "").toLowerCase();
        countryLabel = ukCountries.includes(countryLabel)
          ? "United Kingdom"
          : countryLabel;
        const formatterPrescriptionItems = PrescriptionItems.map((p) => {
          let isSelected = true;
          const remainingQty = p.Quantity_To_Dispense - p.Quantity_Dispensed;
          if (remainingQty <= 0) {
            isSelected = false;
          }
          return {
            id: p.id,
            isSelected,
            name: p.Product.name,
            remainingQty,
            quantity: undefined,
            notes: p.Usage_Notes || "",
          };
        });

        const initialValuesFromPrescriptionData = {
          country: countryLabel
            ? { title: countryLabel, value: countryLabel }
            : null,
          province: Patient.Shipping_State || "",
          city: Patient.Shipping_City || "",
          postalCode: Patient.Shipping_Zip || "",
          street: Patient.Shipping_Street || "",
          apartment: "",
          orderItems: formatterPrescriptionItems,
        };
        setCountry(countryLabel);
        setInitialValues(initialValuesFromPrescriptionData);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [prescriptionId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={manualOrderValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={relatedOrder ? 750 : 1400}>
        <TreatmentWidgetTitle isCustomButton title="Create Manual Order" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading && (
            <Box
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "450px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && relatedOrder && (
            <Alert severity="error" style={{ width: "700px" }}>
              You can&apos;t create an order for this prescription until you
              finish dispensing of order{" "}
              <Link
                href={relatedOrder.orderUrl}
                target="_blank"
                rel="noreferrer"
              >
                {relatedOrder.orderName}
              </Link>
              .
            </Alert>
          )}
          {!loading && !relatedOrder && (
            <>
              <CreateManualOrderForm country={country} />
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && !relatedOrder && (
          <TreatmentWidgetActions>
            <ActionButtons />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
