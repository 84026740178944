import React, { memo, useCallback, useMemo } from "react";
import {
  BloodTestResult,
  BloodTestResultInputMethodEnum,
} from "@deep-consulting-solutions/bmh-constants";
import {
  makeStyles,
  TableRow,
  Checkbox,
  Typography,
  Tooltip,
  Box,
} from "@material-ui/core";
import { HowToVote as ManualIcon } from "@material-ui/icons";

import { formatDateTimeToDisplayInCompanyTZ } from "helpers";

import OpenPDFBtn from "../OpenPDFBtn";
import { TableCell } from "../TestResults.styles";
import {
  truncateName,
  processSampleCollectedDate,
  shouldShowPDFButton,
} from "../TestResults.helpers";
import { DataWithWarning } from "../DataWithWarning";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  nameCell: {
    minWidth: "200px",
    maxWidth: "300px",
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    color: p.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  name: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    flex: 1,
  },
  manualIcon: {
    marginRight: s(1),
  },
}));

interface ListViewItemProps {
  result: BloodTestResult;
  onSelect: (id: string) => any;
  checked: boolean;
  onClick: (r: BloodTestResult) => any;
}

const ListViewItem: React.FC<ListViewItemProps> = ({
  result,
  onSelect,
  checked,
  onClick,
}) => {
  const selectRow = useCallback(() => {
    onSelect(result.id);
  }, [onSelect, result]);

  const clickResult = useCallback(() => {
    onClick(result);
  }, [onClick, result]);

  const { date, warning } = useMemo(() => processSampleCollectedDate(result), [
    result,
  ]);
  const by = result.lastUpdateBy || result.createdBy;
  const at = result.lastUpdateAt || result.createdAt;

  const classes = useStyle();
  const truncatedName = truncateName(result.name, true);
  const truncatedLab = truncateName(result.labName, true);

  return (
    <TableRow>
      <TableCell onClick={selectRow}>
        <Checkbox color="primary" checked={checked} />
      </TableCell>

      <TableCell className={classes.nameCell} onClick={clickResult}>
        <Box className={classes.nameWrapper}>
          <Tooltip
            title={truncatedName.isTruncated ? result.name : ""}
            placement="bottom-start"
          >
            <Typography variant="body2" className={classes.name}>
              {truncatedName.truncated}
            </Typography>
          </Tooltip>
          {result.inputMethod === BloodTestResultInputMethodEnum.manual && (
            <Tooltip
              title={`${by.name} - ${
                formatDateTimeToDisplayInCompanyTZ(at).date
              }`}
            >
              <ManualIcon className={classes.manualIcon} />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <Tooltip title={truncatedLab.isTruncated ? result.labName : ""}>
        <TableCell>{truncatedLab.truncated}</TableCell>
      </Tooltip>
      <TableCell>{result.bloodTakingOption}</TableCell>
      <TableCell>
        <DataWithWarning warning={warning}>{date}</DataWithWarning>
      </TableCell>
      <TableCell>
        {!!shouldShowPDFButton(result) && <OpenPDFBtn id={result.id} />}
      </TableCell>
    </TableRow>
  );
};

export default memo(ListViewItem);
