import React from "react";
import {
  makeStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import {
  PureTableFilterCheckbox,
  TableFiltersWrapper,
} from "components/TableFilters";
import {
  DocumentOwnershipEnum,
  DocumentStatusEnum,
  DocumentStatusFilters,
  DocumentTypeEnum,
  DocumentTypeFilters,
  OwnershipFilters,
} from "./interface";
import { sortAlphabetically } from "./helpers";

const useStyle = makeStyles(({ spacing: s, breakpoints: b, palette: p }) => ({
  filterWrapper: {},
  filterBtn: {
    color: p.primary.main,
    marginTop: s(1),
    textTransform: "none",
  },
  filterGroup: {
    marginBottom: s(1),
    display: "flex",
    flexFlow: "row wrap",
    width: 600,
  },
  statusWrapper: {
    [b.down("xs")]: {
      justifyContent: "space-between",
    },
    flexFlow: "column",
  },

  box: {
    padding: s(2),
    flex: 1,
  },
  label: {},
}));

interface FiltersProps {
  resetFilters: () => any;
  applyFilters: () => any;
  isStatusFiltersChanged?: boolean;
  isTypeFiltersChanged?: boolean;
  onStatusFilterClick: (s: DocumentStatusEnum) => any;
  onTypeFilterClick: (s: DocumentTypeEnum) => any;
  onOwnershipFilterClick: (s: DocumentOwnershipEnum) => any;
  statusFilters: DocumentStatusFilters;
  typeFilters: DocumentTypeFilters;
  ownershipFilters: OwnershipFilters;
  statusFiltersList: DocumentStatusEnum[];
}

export const Filters: React.FC<FiltersProps> = ({
  resetFilters,
  applyFilters,
  isStatusFiltersChanged,
  isTypeFiltersChanged,
  onStatusFilterClick,
  onTypeFilterClick,
  statusFilters,
  typeFilters,
  statusFiltersList,
}) => {
  const classes = useStyle();

  return (
    <TableFiltersWrapper
      label={undefined}
      className={classes.filterWrapper}
      resetFilters={resetFilters}
      applyFilters={applyFilters}
      disabled={!(isStatusFiltersChanged || isTypeFiltersChanged)}
      isClearFiltersDisabled
      btnProps={{
        variant: "text",
        className: classes.filterBtn,
        color: "primary",
      }}
    >
      <FormControl className={classes.filterGroup}>
        <Box className={classes.box}>
          <FormLabel color="primary" className={classes.label}>
            Type
          </FormLabel>
          <FormGroup row className={classes.statusWrapper}>
            {sortAlphabetically(Object.keys(typeFilters)).map((s) => {
              return (
                <FormControlLabel
                  key={s}
                  label={s}
                  control={
                    <PureTableFilterCheckbox
                      name={s}
                      checked={!!typeFilters[s as DocumentTypeEnum]}
                      onFilterClick={onTypeFilterClick}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
        <Box className={classes.box}>
          <FormLabel color="primary" className={classes.label}>
            Status
          </FormLabel>
          <FormGroup row className={classes.statusWrapper}>
            {sortAlphabetically(statusFiltersList).map((s) => {
              return (
                <FormControlLabel
                  key={s}
                  label={s}
                  control={
                    <PureTableFilterCheckbox
                      name={s}
                      checked={!!statusFilters[s as DocumentStatusEnum]}
                      onFilterClick={onStatusFilterClick}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
      </FormControl>
    </TableFiltersWrapper>
  );
};
