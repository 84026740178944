import { FormikProps } from "formik";
import { UK_LAT, UK_LNG } from "configs";
import { VALIDATIONS } from "helpers";

export function getShortAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

export function getLongAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

export function statusMessage(status: string) {
  switch (status) {
    case "ZERO_RESULTS":
      return "No Results Found";
    case "UNKNOWN_ERROR":
      return "Netwrok or Server Error";
    case "ERROR":
      return "Network Error";
    default:
      return "Server Error";
  }
}

export const DEFAULT_COORDS = { lat: UK_LAT, lng: UK_LNG };
export interface DefaultShippingAddressFormValues {
  street: string;
  city: string;
  region: string;
  other: string;
  zip: string;
  country: string;
}

export const getShippingAddressFormValidationSchema = (
  convertKeyMap: {
    [key in keyof DefaultShippingAddressFormValues]?: any;
  } = {}
) => {
  const validations: any = {
    street: VALIDATIONS.street,
    city: VALIDATIONS.city,
    region: VALIDATIONS.region,
    other: VALIDATIONS.other,
    zip: VALIDATIONS.zip,
    country: VALIDATIONS.country,
  };

  Object.entries(convertKeyMap).forEach(([from, to]) => {
    if (validations[from] && to && from !== to) {
      validations[to] = validations[from];
      delete validations[from];
    }
  });

  return validations;
};

export interface ShippingAddressFormValues<
  FormValues extends Record<string, any>
> {
  formikProps: FormikProps<FormValues>;
  streetName?: string;
  cityName?: string;
  regionName?: string;
  otherName?: string;
  zipName?: string;
  countryName?: string;
  coordinations?: { lat: number; lng: number };
  disabledFields?: Record<string, boolean>;
  customFieldErrors?: Record<string, string | undefined>;
}
