import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { OrderItemDispensedUnits } from "components/Dispensing/OrderItemDispensedUnits";
import React from "react";
import { TreatmentOrderItem } from "widgets/DispenseDrugs";

interface DispensePartiallyProps {
  fieldName: string;
  item: TreatmentOrderItem;
  manufacturers: string[];
}

export const DispensePartially: React.FC<DispensePartiallyProps> = ({
  fieldName,
  item,
  manufacturers,
}) => {
  return (
    <Box ml={4}>
      <Box mb={2}>
        <Alert severity="warning">
          The rest of the quantity will be marked as owed.
        </Alert>
      </Box>
      <OrderItemDispensedUnits
        quantity={item.Quantity}
        fieldName={fieldName}
        manufacturers={manufacturers}
        item={item}
      />
    </Box>
  );
};
