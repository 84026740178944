import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Done as SuccessIcon } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(({ palette: p }) => ({
  successIcon: {
    fontSize: "100px",
    color: p.success.main,
  },
}));

interface Props {
  isBlueprint: boolean;
}

export const GuidedSpecifyingOfDetailsSuccess = ({ isBlueprint }: Props) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SuccessIcon className={classes.successIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h5" align="center">
          Blood Test Order Details are successfully updated.
          {!isBlueprint && (
            <>
              <br />
              Please reload the page to see the latest updates.
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
