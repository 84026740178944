import {
  GetPdfTemplatesRes,
  pdfTemplatesEndpoints,
} from "@deep-consulting-solutions/bmh-constants";
import { apiClient } from "apis";

const fetchPDFTemplates = async () => {
  const res = await apiClient.get<GetPdfTemplatesRes>(
    pdfTemplatesEndpoints.get
  );

  return res.data.data;
};

export const pdfTemplatesRequests = {
  fetchPDFTemplates,
};
