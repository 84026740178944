import { Grid } from "@material-ui/core";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useEffect, useMemo } from "react";
import { Field, useFormikContext } from "formik";
import { AutoCompleteOption } from "types";
import { TitleValue } from "components/TitleValue";
import { FormikNumberField } from "components/FormikNumberField";
import { BillingCycleUnitField } from "../BillingCycleUnitField";

interface Props {
  name?: string;
  allowChangeBillingCycleUnit?: boolean;
  maxBillingCyclePeriodWidth?: number;
}
export const BillingCyclePeriod = ({
  name,
  allowChangeBillingCycleUnit = false,
  maxBillingCyclePeriodWidth,
}: Props) => {
  const { values, setFieldValue } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("period", name), [name]);

  const onlyOnceFieldName = useMemo(() => getFieldName("onlyOnce", name), [
    name,
  ]);

  const globalBillingCycleUnit: AutoCompleteOption = useMemo(
    () => getValueFromObject("billingCycleUnit", values),
    [values]
  );

  const onlyOnce: boolean = useMemo(
    () => getValueFromObject(onlyOnceFieldName, values),
    [values, onlyOnceFieldName]
  );

  const period: number | null = useMemo(
    () => getValueFromObject(fieldName, values),
    [values, fieldName]
  );

  const globalBillingCycleUnitText = useMemo(() => {
    return globalBillingCycleUnit?.title || "";
  }, [globalBillingCycleUnit?.title]);

  const disabled = useMemo(() => !!onlyOnce, [onlyOnce]);

  useEffect(() => {
    setImmediate(() => {
      if (onlyOnce && period) {
        setFieldValue(fieldName, "");
      }
    });
  }, [onlyOnce, setFieldValue, period, fieldName]);

  return (
    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
      <Grid item>
        {allowChangeBillingCycleUnit ? (
          <BillingCycleUnitField
            name={name}
            mb={0}
            isChildField
            minWidth={180}
          />
        ) : (
          <TitleValue
            title="Billing Cycle Unit:"
            value={globalBillingCycleUnitText}
          />
        )}
      </Grid>
      <Grid
        item
        style={{
          flex: 1,
          maxWidth: maxBillingCyclePeriodWidth,
        }}
      >
        <Field
          component={FormikNumberField}
          name={fieldName}
          label="Period (x)"
          type="integer"
          size="small"
          fullWidth
          required
          min={1}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 0,
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
