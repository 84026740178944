import { Column } from "./types";

export const DEFAULT_ROWS_PER_PAGE = 5;

export const selectOptions = [
  {
    value: "Destroying",
    label: "Destroyed",
  },
  {
    value: "Sent Back to Wholesaler",
    label: "Sent Back to Wholesaler",
  },
];

export const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 170,
  },
  {
    id: "batchAndExpiryDate",
    label: "Batch Numbers and Expiry Dates",
    minWidth: 200,
  },
];
