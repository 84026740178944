import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { CustomTextareaField } from "components/CustomTextareaField";
import { Alert } from "@material-ui/lab";
import { CustomRadioGroupField } from "components/CustomRadioGroupField";
import { CustomNumberField } from "components/CustomNumberField";
import { InitialValues } from "./types";
import { radioOptions } from "./constants";

export const ReplacementOrderForm = () => {
  const { values }: { values: InitialValues } = useFormikContext();
  const infoText = useMemo(() => {
    if (values.replacementType === "full-payment") {
      return `
      An invoice for the total order charges will be automatically generated and sent to client.
      On successful payment, a replacement order will be automatically sent to the pharmacy.
      `;
    }
    if (values.replacementType === "custom-payment") {
      return `
      An invoice for the custom amount will be automatically generated and sent to client. 
      On successful payment, a replacement order will be automatically sent to the pharmacy.
      `;
    }
    return `
    Client will not be invoiced. When you click Create, a replacement order will be automatically sent to the pharmacy
    `;
  }, [values.replacementType]);

  return (
    <Box minHeight="450px">
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginBottom: "4px" }}
        >
          Replacement Reason *
        </Typography>
        <Box>
          <CustomTextareaField
            name="replacementReason"
            rows={4}
            rowsMax={7}
            helperText="Why does the order needs to be resent?"
          />
        </Box>
      </Box>
      <Box marginY={3}>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginBottom: "4px" }}
        >
          Replacement Payment *
        </Typography>
        <Box>
          <CustomRadioGroupField
            name="replacementType"
            options={radioOptions}
            helperText="Does the client need to pay for the replacement order?"
          />
        </Box>
      </Box>
      {values.replacementType === "custom-payment" && (
        <Box marginY={3}>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", marginBottom: "4px" }}
          >
            Replacement Price *
          </Typography>
          <Box width="320px">
            <CustomNumberField name="replacementPrice" />
          </Box>
        </Box>
      )}
      <Alert severity="info" style={{ margin: "16px 0px" }}>
        {infoText}
      </Alert>
    </Box>
  );
};
