import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { TestRow } from "./TestRow";
import { Group } from "./types";

interface Props {
  group: Group;
  showRange: boolean;
  showCurrentResult: boolean;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  existing: {
    background: p.grey[100],
  },
  identifier: {
    fontWeight: 700,
  },
  cell: {
    padding: s(0),
  },
}));

export const RowGroup = ({
  group: { identifier, tests },
  showRange,
  showCurrentResult,
}: Props) => {
  const classes = useStyle();

  return (
    <>
      <TableRow>
        <TableCell className={classes.cell}>
          <Typography className={classes.identifier}>{identifier}</Typography>
        </TableCell>
        <TableCell colSpan={showRange ? 3 : 2} className={classes.existing}>
          {" "}
        </TableCell>
        <TableCell colSpan={showRange ? 3 : 2}> </TableCell>

        {showCurrentResult ? (
          <TableCell colSpan={showRange ? 3 : 2} className={classes.existing}>
            {" "}
          </TableCell>
        ) : null}
      </TableRow>
      {tests.map((test) => (
        <TestRow key={test.name} test={test} showRange={showRange} />
      ))}
    </>
  );
};
