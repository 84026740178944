import React, { useCallback } from "react";
import { Interval } from "@deep-consulting-solutions/bmh-constants";
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from "@material-ui/pickers";
import { AccessTime } from "@material-ui/icons";
import { getHours, getMinutes } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { createStyles, makeStyles } from "@material-ui/core";
import { setTime } from "./helpers";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    timeBtn: {
      position: "absolute",
      padding: s(1),
      right: 0,
    },
    helperText: {
      margin: s(0.5, 0.25, 0),
    },
  })
);

interface TimeInputProps
  extends Omit<KeyboardTimePickerProps, "onChange" | "value"> {
  hours: number;
  minutes: number;
  onUpdateInterval: (dayOfWeek: string, interval: Interval) => any;
  setError: (key: string, err: string) => any;
  dayOfWeek: string;
  interval: Interval;
  name: string;
}

export const TimeInput = ({
  hours,
  minutes,
  onUpdateInterval,
  setError,
  dayOfWeek,
  interval,
  name,
  error,
  helperText,
  ...props
}: TimeInputProps) => {
  const classes = useStyles();

  const onChange = useCallback(
    (date: MaterialUiPickersDate) => {
      onUpdateInterval(dayOfWeek, {
        ...interval,
        [`${name}Hour`]: getHours(date as Date),
        [`${name}Min`]: getMinutes(date as Date),
      });
    },
    [dayOfWeek, interval, name, onUpdateInterval]
  );

  const showError = !!error;

  return (
    <KeyboardTimePicker
      error={showError}
      helperText={showError && helperText}
      size="small"
      variant="inline"
      placeholder="08:00 AM"
      mask="__:__ _M"
      value={setTime(hours, minutes)}
      onChange={onChange}
      KeyboardButtonProps={{
        className: classes.timeBtn,
        "aria-label": "open time dialog",
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      keyboardIcon={<AccessTime />}
      {...props}
    />
  );
};
