import { BloodTakingOptionEnum } from "@deep-consulting-solutions/bmh-constants";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import ErrorMessage from "components/ErrorMessage";
import Loader from "components/Loader";
import { Form, Formik, FormikConfig } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchBloodTestResultsByIDs,
  postRetestRequiredNotClientError,
} from "redux/testResults/requests";
import { zohoSelectors } from "redux/zoho";
import { zohoServices } from "services";
import { AxiosError } from "axios";
import { useUpdateBluePrint } from "hooks";
import {
  BP_CONFIRM_KIT_INVOICE_CREATION,
  BP_RETEST_REQUIRED_NOT_CLIENT_ERROR,
} from "configs";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { getPayload, initialValues, validationSchema } from "./helpers";
import { ErrorComponent, Actions, FormComponent, Success } from "./components";
import { BloodOrderType, FormValues, PageEnum, RetestErrorEnum } from "./types";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flex: 1,
      padding: s(2),
    },
    actions: {
      padding: s(2),
    },
    title: {
      padding: s(2),
    },
  })
);

export const RetestRequiredNotClientError = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const { updateBluePrint } = useUpdateBluePrint();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(PageEnum.Form);
  const [bloodTestOrders, setBloodTestOrders] = useState<BloodOrderType>([]);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [newBloodOrderLink, setNewBloodOrderLink] = useState("");
  const [failureReason, setFailureReason] = useState("");

  const { bloodTestOrder, isFingerprick } = useMemo(() => {
    const bloodTestOrderFromArray = bloodTestOrders[0];
    if (!bloodTestOrderFromArray) {
      return { bloodTestOrder: null, isFingerprick: false };
    }
    return {
      bloodTestOrder: bloodTestOrderFromArray,
      isFingerprick:
        bloodTestOrderFromArray.bloodTakingOption ===
        BloodTakingOptionEnum.fingerprick,
    };
  }, [bloodTestOrders]);

  const onCancelClick = useCallback(async (reload?: boolean) => {
    await zohoServices.closePopup(reload === true);
  }, []);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      if (!bloodTestOrder?.zohoID) {
        return;
      }
      try {
        setLoading(true);
        const payload = getPayload(values, isFingerprick);
        const res = await postRetestRequiredNotClientError(
          bloodTestOrder?.zohoID,
          payload
        );
        setInvoiceLink(res.invoiceURL);
        setNewBloodOrderLink(res.newBloodTestOrderLink);
        setPage(PageEnum.Success);
      } catch (e) {
        const error = e as AxiosError;
        setFailureReason(error.response?.data?.message);
        setPage(PageEnum.Error);
      } finally {
        setLoading(false);
      }
    },
    [bloodTestOrder?.zohoID, isFingerprick]
  );

  const onHandleManually = useCallback(async () => {
    try {
      setLoading(true);
      await updateBluePrint(BP_RETEST_REQUIRED_NOT_CLIENT_ERROR);
      onCancelClick();
    } catch {
      setLoading(false);
    }
  }, [onCancelClick, updateBluePrint]);

  const onDone = useCallback(async () => {
    try {
      setLoading(true);
      await updateBluePrint(BP_RETEST_REQUIRED_NOT_CLIENT_ERROR);
      await updateBluePrint(BP_CONFIRM_KIT_INVOICE_CREATION);
      onCancelClick(true);
    } catch {
      setLoading(false);
    }
  }, [onCancelClick, updateBluePrint]);

  const getData = useCallback(async () => {
    if (!ids.length) {
      return;
    }
    try {
      setLoading(true);
      const res = await fetchBloodTestResultsByIDs(ids);
      setBloodTestOrders(res);
      setPage(PageEnum.Form);
    } catch (error) {
      setPage(PageEnum.LoadingError);
    } finally {
      setLoading(false);
    }
  }, [ids]);

  useEffect(() => {
    getData();
  }, [getData]);

  const classes = useStyles();
  return (
    <WidgetLayout>
      <Loader open={loading} />

      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, values }) => {
          return (
            <Form className={classes.form}>
              <WidgetTitle className={classes.title}>
                <Typography component="h1" variant="h4">
                  Confirm Retest Reason
                </Typography>
              </WidgetTitle>
              <WidgetContent className={classes.content}>
                {page === PageEnum.Form && (
                  <FormComponent
                    isFingerprick={isFingerprick}
                    variant={RetestErrorEnum.NotClientError}
                  />
                )}
                {page === PageEnum.Error && (
                  <ErrorComponent
                    handleRetry={() => handleSubmit()}
                    failureReason={failureReason}
                    title="Invoice creation failed"
                    body='Retry or Create the blood draw kit invoice on Zoho Books then confirm the creation using "Confirm Kit Invoice Creation" button on the next Screen.'
                  />
                )}
                {page === PageEnum.Success && (
                  <Success
                    newBloodOrderLink={newBloodOrderLink}
                    invoiceLink={invoiceLink}
                    isFingerprick={isFingerprick}
                    bloodTakingOption={bloodTestOrder?.bloodTakingOption}
                    isBloodTakingOptionChanged={
                      values.changeBloodTakingOptionFromFingerPrickToVenepuncture
                    }
                    isPayForBloodDrawerEmailSent
                    isBloodDrawerProvidesOwnKitSuccessVariant={!invoiceLink}
                  />
                )}

                {page === PageEnum.LoadingError && (
                  <ErrorMessage
                    message="Failed to get the data necessary for the form, please retry."
                    onButtonClick={getData}
                  />
                )}
              </WidgetContent>

              <WidgetActions className={classes.actions}>
                <Actions
                  loading={loading}
                  onHandleManually={onHandleManually}
                  onCancelClick={onCancelClick}
                  onDoneClick={onDone}
                  page={page}
                  isFormValid={isValid}
                  handleSubmit={() => handleSubmit()}
                />
              </WidgetActions>
            </Form>
          );
        }}
      </Formik>
    </WidgetLayout>
  );
};
