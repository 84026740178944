import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  infoGroup: {
    paddingTop: s(2),
    paddingBottom: s(2),
    borderBottom: `1px solid ${p.grey[300]}`,

    "&:last-child": {
      borderBottom: "none",
    },
  },
  groupTitle: {
    color: p.primary.light,
  },
  groupContent: {
    paddingTop: s(1),
    paddingBottom: s(1),
  },
}));

export const InfoGroup: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyle();

  return (
    <Box className={classes.infoGroup}>
      <Typography variant="subtitle2" className={classes.groupTitle}>
        {title}
      </Typography>
      <Box className={classes.groupContent}>{children}</Box>
    </Box>
  );
};
