import { Box, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import { getFieldName } from "helpers";
import React, { useMemo } from "react";
import { useStyles } from "../MedicineForm/styles";

interface ManualOrAutoProductSelectionProps {
  name?: string;
}

interface TitleAndDescriptionProps {
  title: string;
  description: string;
}

const TitleAndDescription: React.FC<TitleAndDescriptionProps> = ({
  title,
  description,
}) => {
  return (
    <div>
      <Typography variant="body1">
        <strong>{title}</strong>
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </div>
  );
};

export enum ManualOrAuto {
  MANUAL = "manual",
  AUTO = "auto",
}

export const ManualOrAutoProductSelection: React.FC<ManualOrAutoProductSelectionProps> = ({
  name,
}) => {
  const classes = useStyles();
  const { getFieldMeta } = useFormikContext();
  const fieldName = useMemo(() => getFieldName("manualOrAutoSelection", name), [
    name,
  ]);
  const fieldMeta = useMemo(() => getFieldMeta(fieldName), [
    fieldName,
    getFieldMeta,
  ]);

  return (
    <Field component={RadioGroup} row name={fieldName}>
      <FormControlLabel
        className={classes.mb2}
        value={ManualOrAuto.AUTO}
        control={<Radio color="primary" />}
        label={
          <TitleAndDescription
            title="Calculate Matching Medicine Products and Packages Automatically"
            description="After that you would be able to select a medicine package from the found packages then a new CTTP and Prescription will be generated for the selected products"
          />
        }
      />
      <FormControlLabel
        value={ManualOrAuto.MANUAL}
        control={<Radio color="primary" />}
        label={
          <TitleAndDescription
            title="Select One of the Patient's Existing CTTPs and Select Its Products Manually"
            description="Make sure that the entered medicines details and selected products and their billing cycles are matching the selected CTTP otherwise the CTTP would be invalid."
          />
        }
      />
      {fieldMeta.error && fieldMeta.touched && (
        <Box mt={1}>
          <Typography color="error" variant="body2">
            {fieldMeta.error}
          </Typography>
        </Box>
      )}
    </Field>
  );
};
