import React from "react";
import {
  EmailLogsWidget,
  ROUTE_GET_EMAIL_LOGS,
} from "@deep-consulting-solutions/email-logs-web";

import { COMPANY_NAME } from "configs";

const getAPIUrl = () => {
  return `${process.env.REACT_APP_BASE_URL}${ROUTE_GET_EMAIL_LOGS}`;
};

export const SettingsEmailLogs = () => {
  return (
    <EmailLogsWidget
      apiUrl={getAPIUrl()}
      rowsPerPage={10}
      showHeader
      companyName={COMPANY_NAME}
    />
  );
};
