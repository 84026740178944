import React, { memo } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";

import {
  ConvertedClientRecord,
  ConvertedPathwayRecord,
} from "../Unmatched.types";
import { getBorder, ROW_HEIGHT } from "../Unmatched.styles";
import PossibleClients from "./PossibleClients";
import PossiblePathways from "./PossiblePathways";

const useStyle = makeStyles(({ palette: p, spacing: s, breakpoints: b }) => ({
  possibleWrapper: {
    width: `calc(30% - ${s(2)}px)`,
    marginLeft: s(2),
    borderRight: getBorder(p),
    borderBottom: getBorder(p),
    borderLeft: getBorder(p),
    [b.down("sm")]: {
      width: "100%",
      marginLeft: 0,
      marginTop: s(2),
    },
  },
  row: {
    height: `${ROW_HEIGHT}px`,
    display: "flex",
    alignItems: "center",
    borderTop: getBorder(p),
    paddingLeft: s(1),
    paddingRight: s(1),
  },
  replaceButton: {
    marginLeft: "auto",
  },
  noteWrapper: {
    padding: s(1),
  },
}));

interface PossibleMatchesProps {
  pathways: ConvertedPathwayRecord[];
  selectedPathwayId: string | null;
  onPathwaySelect: (p: ConvertedPathwayRecord) => any;
  clients: ConvertedClientRecord[];
  selectedClientId: string | null;
  onClientSelect: (c: ConvertedClientRecord) => any;
  onReplaceClick: () => any;
  onSearchClick: () => any;
  disableUpdate: boolean;
}

const PossibleMatches: React.FC<PossibleMatchesProps> = ({
  pathways,
  selectedPathwayId,
  onPathwaySelect,
  clients,
  selectedClientId,
  onClientSelect,
  onReplaceClick,
  onSearchClick,
  disableUpdate,
}) => {
  const classes = useStyle();

  return (
    <Box className={classes.possibleWrapper}>
      <Box className={classes.row}>
        <Typography variant="h6">Possible Matching Records</Typography>
        <Button
          className={classes.replaceButton}
          color="primary"
          variant="outlined"
          disabled={disableUpdate || !selectedPathwayId || !selectedClientId}
          onClick={onReplaceClick}
        >
          REPLACE THE DATA
        </Button>
      </Box>
      <PossiblePathways
        pathways={pathways}
        selectId={selectedPathwayId}
        onSelect={onPathwaySelect}
        onSearchClick={onSearchClick}
        disableUpdate={disableUpdate}
      />
      <PossibleClients
        clients={clients}
        selectId={selectedClientId}
        onSelect={onClientSelect}
        disableUpdate={disableUpdate}
      />
      <Box className={classes.noteWrapper}>
        <Typography variant="caption">
          This part provides possible matching records, based on received data.
          You can open records by clicking on them to compare data and select
          matching records as a “donor” to replace mismatched date in file. In
          case Blood Test Order is selected, Client record will be associated
          automatically.
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(PossibleMatches);
