import * as Yup from "yup";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { dispenseDrugsServices, notifications, zohoServices } from "services";
import { AxiosError } from "axios";
import { Formik, FormikConfig } from "formik";
import { ProvideClarificationForm } from "components/Dispensing";

export const ProvideClarifications: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [id] = useSelector(zohoSelectors.getIds);

  const initialValues = useMemo(() => {
    return {
      prescriptionDosingExplanation: "",
      isPrescriptionIncorrect: false,
      explanationForIncorrectPrescription: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      prescriptionDosingExplanation: Yup.string().required(
        "Prescription Dosing Explanation is required"
      ),
      isPrescriptionIncorrect: Yup.boolean(),
      explanationForIncorrectPrescription: Yup.string().when(
        "isPrescriptionIncorrect",
        {
          is: true,
          then: Yup.string().required(
            "Explanation for Incorrect Prescription is required"
          ),
        }
      ),
    });
  }, []);

  const submitDetails = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const res = await dispenseDrugsServices.provideClarification({
          prescriptionZohoId: id,
          dosingExplanation: values.prescriptionDosingExplanation,
          incorrect: values.isPrescriptionIncorrect,
          incorrectExplanation: values.explanationForIncorrectPrescription,
        });
        if (res.error) {
          throw new Error(res.message);
        }
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      await submitDetails(values);
      setSubmitting(false);
    },
    [submitDetails]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validationContext={initialValues}
      validateOnMount
      enableReinitialize
    >
      <ProvideClarificationForm
        loading={loading}
        submitDetails={submitDetails}
      />
    </Formik>
  );
};
