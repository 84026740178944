import { useFormikContext } from "formik";
import { getUpdateTreatmentBillingCycleValidation } from "helpers";
import React, { useMemo } from "react";
import { BillingCycleValidationInfo } from "./BillingCycleValidationInfo";

interface Props {
  name?: string;
}

export const TreatmentBillingCycleValidationInfo = ({ name = "" }: Props) => {
  const { values } = useFormikContext();

  const { selfSupplyFailed, isMultipleFailed } = useMemo(
    () => getUpdateTreatmentBillingCycleValidation(values, name),
    [values, name]
  );

  return (
    <BillingCycleValidationInfo
      selfSupplyFailed={selfSupplyFailed}
      isMultipleFailed={isMultipleFailed}
    />
  );
};
