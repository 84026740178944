import React, { memo, useState, useCallback, useMemo } from "react";
import { makeStyles, Box, Button } from "@material-ui/core";
import {
  BloodTestResult,
  BloodTestResultItem,
  BloodTestResultInputMethodEnum,
  BluePrintStagesEnum,
  BloodTestResultWithPathway,
} from "@deep-consulting-solutions/bmh-constants";

import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import OpenPDFBtn from "../OpenPDFBtn";
import { BackButton, ViewWrapperBox } from "../TestResults.styles";
import ResultDetailsInfo from "./ResultDetailsInfo";
import OBXTable from "./OBXTable";
import ComparisonNoti from "./ComparisonNoti";
import { shouldShowPDFButton } from "../TestResults.helpers";

interface StyleProps {
  showGoToComparison: boolean;
  isOnPathway?: boolean;
}

const useStyle = makeStyles(({ spacing: s }) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    padding: s(2),
  },
  topBar: {
    display: "flex",
  },
  topActions: {
    marginLeft: "auto",
  },
  goToComparisonBtn: ({ showGoToComparison }: StyleProps) => ({
    opacity: showGoToComparison ? 1 : 0,
    transition: "0.2s",
    marginRight: s(1),
  }),
  backIcon: {
    marginRight: s(1),
  },
  infoWrapper: {},
  infoRow: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    paddingLeft: s(1),
    paddingRight: s(1),
    marginBottom: s(1),
  },
  infoLeft: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoRight: {
    width: "30%",
    paddingLeft: s(2),
  },
  actions: {
    textAlign: "right",
  },
  openPDFBtn: ({ isOnPathway }: StyleProps) => ({
    marginLeft: isOnPathway ? "auto" : s(1),
  }),
  infoUpperItem: {
    width: `calc(50% - ${s(1)}px)`,
  },
  infoLowerItem: {
    width: `calc(33.33% - ${s(4 / 3)}px)`,
  },
  companyItem: {
    width: "100%",
  },
  editBtn: {
    marginRight: s(1),
  },
}));

interface ResultDetailsViewProps {
  result: BloodTestResultWithPathway;
  toListView?: () => any;
  comparisons?: { [key: string]: BloodTestResult };
  addToComparisons?: (r: BloodTestResult) => any;
  removeFromComparisons?: (id: string) => any;
  goToComparison?: () => any;
  goToHistory: (obx: BloodTestResultItem) => any;
  onResultEdit?: (result: BloodTestResultWithPathway) => any;
  dismissSelectedResultForComparison?: () => any;
  isPathway?: boolean;
  pathwayStage?: BluePrintStagesEnum;
}

const ResultDetailsView: React.FC<ResultDetailsViewProps> = ({
  result,
  toListView,
  comparisons,
  addToComparisons,
  removeFromComparisons,
  goToComparison,
  goToHistory,
  onResultEdit,
  dismissSelectedResultForComparison,
  isPathway,
  pathwayStage,
}) => {
  const { canEdit } = useSelector(zohoSelectors.getBloodTestResultsPermissions);
  const [showComparisons, setShowComparisons] = useState(false);

  const showComparisonNoti = useCallback(() => {
    setShowComparisons(true);
  }, []);

  const onDismissNotiClick = useCallback(() => {
    if (dismissSelectedResultForComparison)
      dismissSelectedResultForComparison();
    setShowComparisons(false);
  }, [dismissSelectedResultForComparison]);

  const onAddToComparisonsClick = useCallback(() => {
    if (!addToComparisons) return;
    addToComparisons(result);
    setShowComparisons(true);
  }, [addToComparisons, result]);

  const isEditDisabled = useMemo(() => {
    if (!isPathway && !pathwayStage) return false;
    return !(
      pathwayStage === BluePrintStagesEnum.PENDING_MANUAL_RESULTS ||
      pathwayStage === BluePrintStagesEnum.RESULTS_RECEIVED_OK ||
      pathwayStage === BluePrintStagesEnum.RESULTS_FAILED ||
      pathwayStage === BluePrintStagesEnum.RETEST_ARRANGEMENTS_CANCELLED
    );
  }, [pathwayStage, isPathway]);

  const onEditClick = useCallback(() => {
    if (!onResultEdit) return;
    onResultEdit(result);
  }, [onResultEdit, result]);

  const isManual =
    result.inputMethod === BloodTestResultInputMethodEnum.manual ||
    result.inputMethod === BloodTestResultInputMethodEnum.pathwayManual;
  const isOnPathway = !goToComparison;
  const showGoToComparison = Object.keys(comparisons || {}).length > 1;
  const classes = useStyle({ showGoToComparison, isOnPathway });

  return (
    <ViewWrapperBox>
      <Box className={classes.topBar}>
        <>
          {!isPathway && <BackButton onClick={toListView} />}
          <Box className={classes.topActions}>
            <Button
              color="primary"
              className={classes.goToComparisonBtn}
              onClick={goToComparison}
              disabled={!showGoToComparison}
            >
              GO TO COMPARISON
            </Button>
          </Box>
          {isManual && canEdit && (
            <Button
              color="primary"
              variant="text"
              className={classes.editBtn}
              onClick={onEditClick}
              disabled={isEditDisabled}
            >
              EDIT RESULTS
            </Button>
          )}
          {!isPathway && (
            <Button
              color="primary"
              variant="outlined"
              onClick={onAddToComparisonsClick}
              disabled={!!(comparisons || {})[result.id]}
            >
              ADD TO COMPARISON
            </Button>
          )}
        </>
        {shouldShowPDFButton(result) && (
          <OpenPDFBtn id={result.id} className={classes.openPDFBtn} />
        )}
      </Box>
      <ResultDetailsInfo result={result} isOnPathway={isOnPathway} />

      <OBXTable items={result.results} goToHistory={goToHistory} />

      {!!comparisons && !!removeFromComparisons && (
        <ComparisonNoti
          comparisons={comparisons}
          removeFromComparisons={removeFromComparisons}
          show={showComparisons}
          showComparisonNoti={showComparisonNoti}
          onDismissNotiClick={onDismissNotiClick}
        />
      )}
    </ViewWrapperBox>
  );
};

export default memo(ResultDetailsView);
