import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import {
  FinalizeMatchingProductMedicine,
  ZohoMedUsageUnit,
  ZohoProduct,
} from "types";
import { getFieldName } from "helpers";
import { useFormikContext } from "formik";
import { MatchingProductForms } from "./MatchingProductForms";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
}));

interface Props {
  matchingProducts: FinalizeMatchingProductMedicine[];
  productsUrl: string;
  bacteriostaticProducts: ZohoProduct[];
  name?: string;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isMatchingProductVisible: boolean;
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean;
}

export const MatchingProductsForms = ({
  matchingProducts,
  productsUrl,
  bacteriostaticProducts,
  name = "",
  medUsageUnits,
  isMatchingProductVisible,
  isSelectedMatchingProductsDefaultBillingCyclesFailed,
}: Props) => {
  const classes = useStyles();
  const { initialValues } = useFormikContext<any>();

  if (!initialValues.matchingProducts?.length) {
    return null;
  }

  return (
    <Box>
      {matchingProducts.map((matchingProduct, index) => (
        <Box
          mb={index === matchingProducts.length - 1 ? 0 : 2}
          key={matchingProduct.id}
          className={classes.wrapper}
        >
          <MatchingProductForms
            matchingProduct={matchingProduct}
            productsUrl={productsUrl}
            bacteriostaticProducts={bacteriostaticProducts}
            name={getFieldName(index, name)}
            medUsageUnits={medUsageUnits}
            isMatchingProductVisible={isMatchingProductVisible}
            isSelectedMatchingProductsDefaultBillingCyclesFailed={
              isSelectedMatchingProductsDefaultBillingCyclesFailed
            }
            matchingProductsLength={matchingProducts.length}
          />
        </Box>
      ))}
    </Box>
  );
};
