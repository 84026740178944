import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Grid, TextField as MuiTextField } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { getIn, Field } from "formik";
import { TextField } from "formik-material-ui";
import { FormikCountrySelectInput } from "@deep-consulting-solutions/dcs-web-ui";

import { useScript } from "hooks";

import {
  getLongAddressObject,
  getShortAddressObject,
  statusMessage,
  DEFAULT_COORDS,
  ShippingAddressFormValues,
} from "./helpers";

(window as any).dummyPlacesLoaded = () => {};

export const ShippingAddressForm = <FormValues extends Record<string, any>>({
  formikProps: {
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
  },
  streetName = "street",
  cityName = "city",
  regionName = "region",
  otherName = "other",
  zipName = "zip",
  countryName = "country",
  coordinations = DEFAULT_COORDS,
}: ShippingAddressFormValues<FormValues>) => {
  const googleStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=dummyPlacesLoaded`
  );
  const fieldError: string | undefined = useMemo(
    () => getIn(errors, streetName),
    [errors, streetName]
  );
  const showFieldError: boolean = useMemo(
    () => getIn(touched, streetName) && !!fieldError,
    [touched, streetName, fieldError]
  );
  const fieldValue = useMemo(() => getIn(values, streetName), [
    values,
    streetName,
  ]);

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  const [loadingAddress, setLoadingAddress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const {
    ready,
    init,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      location,
      radius: 38600000,
    },
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically
  });

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
        setError(statusMessage((err as unknown) as string));
      }
      return undefined;
    },
    [setValue]
  );

  const onAutocompleteChange = useCallback(
    async (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === "select-option") {
        const result = await handleSelect(
          (value as google.maps.places.AutocompletePrediction).description
        );
        if (result) {
          const longAddress = getLongAddressObject(result);
          const shortAddress = getShortAddressObject(result);

          const street = `${longAddress.street_number || ""}${
            longAddress.route
              ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
              : ""
          }`;
          setFieldValue("street", street);
          setValue(street, false);

          setFieldValue(
            "city",
            longAddress.locality ||
              longAddress.administrative_area_level_3 ||
              longAddress.administrative_area_level_4 ||
              longAddress.postal_town ||
              longAddress.neighborhood ||
              longAddress.sublocality ||
              ""
          );

          const regionValue =
            shortAddress.country === "GB"
              ? longAddress.administrative_area_level_2 ||
                longAddress.administrative_area_level_1
              : longAddress.administrative_area_level_1 ||
                longAddress.administrative_area_level_2;

          setFieldValue("region", regionValue || "");

          setFieldValue("zip", longAddress.postal_code || "");

          setFieldValue("country", longAddress.country || "");
        }
      } else if (reason === "clear") {
        setFieldValue("street", "");
      } else if (reason === "create-option") {
        //
      } else if (reason === "remove-option") {
        //
      } else if (reason === "blur") {
        //
      }
    },
    [handleSelect, setValue, setFieldValue]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = ((value as unknown) as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setError("");
      setValue(value);
      setFieldValue(streetName, value);
    },
    [streetName, setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(streetName, true);
  }, [streetName, setFieldTouched]);

  useEffect(() => {
    if (googleStatus === "ready") {
      init();
    }
  }, [init, googleStatus]);

  useEffect(() => {
    if (ready) {
      setLocation(new google.maps.LatLng(coordinations.lat, coordinations.lng));
    }
  }, [coordinations, ready]);

  const disabled = loadingAddress || isSubmitting;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Autocomplete<
          google.maps.places.AutocompletePrediction,
          false,
          false,
          true
        >
          freeSolo
          noOptionsText="No Results Found"
          value={fieldValue}
          getOptionSelected={getOptionSelected}
          onChange={onAutocompleteChange}
          inputValue={fieldValue}
          onInputChange={onInputChange}
          loading={placesLoading}
          disabled={disabled}
          options={data}
          getOptionLabel={(
            option: google.maps.places.AutocompletePrediction | string
          ) => (typeof option === "string" ? option : option.description)}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name={streetName}
              autoComplete="off"
              onBlur={onInputBlur}
              error={showFieldError}
              helperText={showFieldError && fieldError}
              label="Shipping Street"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={cityName}
          label="Shipping City"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={regionName}
          label="Shipping Region"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={otherName}
          label="Apartment / Flat / Door / Other"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name={zipName}
          label="Shipping Postal Code"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={FormikCountrySelectInput}
          name={countryName}
          label="Shipping Country"
          disabled={disabled}
          required
          useCountryCode={false}
        />
      </Grid>
    </>
  );
};
