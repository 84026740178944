import React, { useEffect, useCallback, useState, useMemo } from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import { Formik, Form, Field, FormikConfig } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import { BP_SEND_ORDER_FORM_STATE } from "configs";
import { ResizeFn, useUpdateBluePrint } from "hooks";
import { pathwayRequests } from "redux/pathway";
import { notifications, zohoServices } from "services";
import SendEmailWrapper, {
  useSendEmailStatus,
} from "components/SendEmailWrapper";
import { VALIDATIONS, splitEmails, getMultipleEmailsValidation } from "helpers";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { Client, ZohoBloodDrawer, ZohoModuleEnum, ZohoPathway } from "types";
import ErrorMessage from "components/ErrorMessage";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {},
  section: {
    marginTop: s(2),
    display: "flex",
    flexDirection: "column",
  },
  input: {
    with: "100%",
    marginBottom: s(0.5),
  },
  caption: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    marginBottom: s(1),
    textAlign: "right",
  },
  send: {
    marginLeft: s(1),
  },
  bloodDrawerEmail: {
    color: p.primary.main,
    fontWeight: 600,
  },
}));

interface FormValues {
  emails: string;
  additionalEmails: string;
  text: string;
}

interface PathwaySendEmailProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  resize: ResizeFn;
  isBlueprint?: boolean;
}

const PathwaySendEmail: React.FC<PathwaySendEmailProps> = ({
  setLoading,
  loading,
  resize,
  isBlueprint,
}) => {
  const { updateBluePrint } = useUpdateBluePrint();
  const { status, failures, sendEmails } = useSendEmailStatus({ resize });
  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];
  const pathways = useSelector(zohoSelectors.getRecords);
  const pathway = (pathways[pathwayZohoId] as unknown) as
    | ZohoPathway
    | undefined;
  const [client, setClient] = useState<Client | null>(null);
  const [bloodDrawer, setBloodDrawer] = useState<ZohoBloodDrawer | null>(null);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      const emails: string[] = [];

      if (isBlueprint) {
        if (bloodDrawer && bloodDrawer.Email) {
          emails.push(bloodDrawer.Email);
          emails.push(...splitEmails(values.additionalEmails));
        } else {
          emails.push(...splitEmails(values.emails));
        }
      } else {
        emails.push(...splitEmails(values.emails));
      }

      await sendEmails({
        request: () =>
          pathwayRequests.sendEmail({
            zohoId: ids[0],
            emails,
            text: values.text,
            isBloodDrawer: !!isBlueprint,
          }),
      });
    },
    [sendEmails, ids, isBlueprint, bloodDrawer]
  );

  const closeWidget = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  const closeWidgetOnSuccess = useCallback(async () => {
    const isUpdated = await updateBluePrint(BP_SEND_ORDER_FORM_STATE);
    zohoServices.closePopup(isUpdated);
  }, [updateBluePrint]);

  const validationSchema = useMemo(() => {
    return Yup.object({
      emails: VALIDATIONS.multipleEmailsString,
      additionalEmails: getMultipleEmailsValidation({
        existingEmails: bloodDrawer?.Email || "",
      }),
    });
  }, [bloodDrawer?.Email]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isBlueprint && pathway && pathway.Blood_Drawer) {
        try {
          const bd = await zohoServices.getRecord<ZohoBloodDrawer>({
            Entity: ZohoModuleEnum.BLOOD_DRAWER,
            RecordID: pathway.Blood_Drawer.id,
          });

          setBloodDrawer(bd);
        } catch (error) {
          const message =
            error?.message ||
            error?.data?.message ||
            (error.data && error.data[0]?.message) ||
            "Uknown error";

          notifications.notifyError(message);
        }
      } else {
        const data = await pathwayRequests.fetchClientsFromZohoIDs(ids);
        setClient(data[0]);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resize({
      addHeight: 20,
    });
  }, [resize, loading]);

  const classes = useStyle();

  const DefaultEmailInput = useMemo(
    () => () => {
      return (
        <Box className={classes.section}>
          <Field
            inputProps={{ "aria-label": "emails" }}
            name="emails"
            component={TextField}
            className={classes.input}
            placeholder="Type recipients' email addresses"
          />
          <Typography variant="caption" className={classes.caption}>
            Recipients&apos; email addresses. If you want to enter additional
            email addresses, type them, separating each one from previous
            address with comma. Example: abc@def.com, ghi@jkl.com
          </Typography>
        </Box>
      );
    },
    [classes.caption, classes.input, classes.section]
  );

  if (isBlueprint && !loading) {
    if (!bloodDrawer) {
      return (
        <ErrorMessage message="Blood drawer is not allocated to this Blood Test Order. Please assign the Blood drawer first and then attempt to send the order form again." />
      );
    }
  }

  const hasEmail = !!bloodDrawer?.Email;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        emails: isBlueprint ? bloodDrawer?.Email ?? "" : client?.email ?? "",
        additionalEmails: "",
        text: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form>
            <SendEmailWrapper
              status={status}
              failures={failures}
              onClose={closeWidget}
              onSuccess={isBlueprint ? closeWidgetOnSuccess : closeWidget}
              isBloodDrawer={!!isBlueprint}
            >
              <Box className={classes.wrapper}>
                <Typography variant="h5">
                  Send Blood Test Order Form to{" "}
                  {isBlueprint ? "Blood Drawer" : "Client"}
                </Typography>

                {!loading && !isBlueprint && <DefaultEmailInput />}

                {!loading && isBlueprint && (
                  <>
                    <Box className={classes.section}>
                      {hasEmail && (
                        <Typography variant="body1">
                          Blood drawer&apos;s email:{" "}
                          <span className={classes.bloodDrawerEmail}>
                            {bloodDrawer?.Email}
                          </span>
                        </Typography>
                      )}
                      {!hasEmail && (
                        <Typography variant="body2" color="error">
                          Blood drawer does not have email. Please provide at
                          least 1 email address.
                        </Typography>
                      )}
                    </Box>

                    {hasEmail && (
                      <Box className={classes.section}>
                        <Field
                          inputProps={{ "aria-label": "emails" }}
                          name="additionalEmails"
                          component={TextField}
                          className={classes.input}
                          placeholder="Type additional recipients' email addresses"
                        />
                        <Typography
                          variant="caption"
                          className={classes.caption}
                        >
                          Additional recipients&apos; email addresses. If you
                          want to enter multiple additional email addresses,
                          type them, separating each one from previous address
                          with comma. Example: abc@def.com, ghi@jkl.com
                        </Typography>
                      </Box>
                    )}

                    {!hasEmail && <DefaultEmailInput />}
                  </>
                )}

                <Box className={classes.section}>
                  <Field
                    name="text"
                    component={TextField}
                    className={classes.input}
                    placeholder="Type message here or leave empty"
                    multiline
                  />
                </Box>
                <Box className={classes.buttons}>
                  <Button variant="text" color="primary" onClick={closeWidget}>
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.send}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </SendEmailWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PathwaySendEmail;
