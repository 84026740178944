import React, { useCallback, useRef, useState } from "react";
import { Box, Button, MenuItem, styled } from "@material-ui/core";
import { Email, EmailConfig } from "@deep-consulting-solutions/bmh-constants";
import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as Yup from "yup";
import { VALIDATIONS } from "helpers";
import { TextField } from "formik-material-ui";
import { isEqual } from "lodash";

const StyledForm = styled(Form)(({ theme }) => ({
  "& > *:not(:first-child):not(:last-child)": {
    marginTop: theme.spacing(2),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  justifyContent: "flex-end",
  "& > *:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

interface FormValues {
  fromId: string;
  replyTo: string;
  senderName: string;
}

const validationSchema = Yup.object({
  fromId: VALIDATIONS.fromEmail,
  replyTo: VALIDATIONS.emailNotRequired,
  senderName: VALIDATIONS.senderName,
});

interface EmailConfigSelectionProps {
  fromEmails: Email[];
  emailConfig: EmailConfig;
  onEmailConfigChange: (changes: {
    id: string;
    fromId: string;
    replyTo: string;
    senderName: string;
  }) => Promise<boolean>;
}

export const EmailConfigSelection = ({
  fromEmails,
  emailConfig,
  onEmailConfigChange,
}: EmailConfigSelectionProps) => {
  const [editing, setEditing] = useState(false);
  const toggleEditing = useCallback(() => {
    setEditing((prev) => !prev);
  }, []);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      const success = await onEmailConfigChange({
        id: emailConfig.id,
        fromId: values.fromId,
        replyTo: values.replyTo,
        senderName: values.senderName,
      });
      if (success) {
        toggleEditing();
      }
    },
    [emailConfig.id, onEmailConfigChange, toggleEditing]
  );

  const onCancelClick = useCallback(() => {
    formRef.current?.resetForm();
    toggleEditing();
  }, [toggleEditing]);

  return (
    <>
      <Formik<FormValues>
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          fromId: emailConfig.fromEmail?.id ?? "",
          replyTo: emailConfig.replyTo || "",
          senderName: emailConfig.senderName || "",
        }}
        enableReinitialize
      >
        {({ isSubmitting, values, initialValues }) => (
          <StyledForm>
            <Field
              component={TextField}
              name="fromId"
              label="From Address"
              helperText="Select default From email address"
              select
              disabled={!editing || isSubmitting}
            >
              {fromEmails.map((email) => (
                <MenuItem key={email.id} value={email.id}>
                  {email.email}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={TextField}
              name="replyTo"
              label="Reply To Address"
              helperText="Type in default Reply To email address"
              disabled={!editing || isSubmitting}
            />
            <Field
              component={TextField}
              name="senderName"
              label="Sender Name"
              helperText="Type in default Sender Name"
              disabled={!editing || isSubmitting}
            />
            <StyledBox>
              {editing ? (
                <>
                  <Button variant="text" onClick={onCancelClick}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isEqual(values, initialValues)}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button color="primary" onClick={toggleEditing}>
                  Edit
                </Button>
              )}
            </StyledBox>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};
