import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import React, { useCallback } from "react";
import Loader from "components/Loader";
import { LabKeyField, LabKeyFieldProps } from "./LabKeyField";
import { TestProfilesField, TestProfilesFieldProps } from "./TestProfilesField";
import { BloodTakingOptionField } from "./BloodTakingOptionField";
import { GenderField } from "./GenderField";
import {
  BloodDrawerNameField,
  BloodDrawerNameFieldProps,
} from "./BloodDrawerNameField";

const useStyles = makeStyles(({ palette: p }) => {
  return {
    checkbox: {
      color: p.primary.main,
    },
  };
});

export interface AddCheckoutPageDialogProps
  extends DialogProps,
    LabKeyFieldProps,
    TestProfilesFieldProps,
    BloodDrawerNameFieldProps {
  onClose: () => void;
  title?: string;
}

export const AddCheckoutPageDialog = ({
  open,
  labKeys,
  onClose,
  testProfiles,
  bloodDrawers,
  title = "Add Checkout Page",
}: AddCheckoutPageDialogProps) => {
  const { handleSubmit, resetForm, isSubmitting } = useFormikContext();

  const onSave = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleClose = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Loader open={isSubmitting} />
        <Grid
          container
          spacing={2}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              required
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <LabKeyField labKeys={labKeys} />
          </Grid>
          <Grid item xs={6}>
            <TestProfilesField testProfiles={testProfiles} />
          </Grid>
          <Grid item xs={6}>
            <BloodTakingOptionField />
          </Grid>
          <Grid item xs={6}>
            <GenderField />
          </Grid>
          <Grid item xs={6}>
            <BloodDrawerNameField bloodDrawers={bloodDrawers} />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="bloodDrawFeeCollected"
              component={CheckboxWithLabel}
              type="checkbox"
              className={classes.checkbox}
              Label={{
                label: <Typography>Blood Drawer Fee Collected</Typography>,
              }}
              color="primary"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          CANCEL
        </Button>
        <Button color="primary" variant="contained" onClick={onSave}>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
