import React, { useMemo } from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { getFieldName, getValueFromObject } from "helpers";
import { useFormikContext } from "formik";
import { ProductSelect } from "./ProductSelectField";
import { BillingCyclePeriod } from "./BillingCyclePeriodField";
import { OnlyOnceField } from "./OnlyOnceField";
import { SelfSupplyField } from "./SelfSupplyField";
import { QuantityAndPricingFields } from "./QuantityAndPricingFields";
import { OtherFields, OtherFieldsProductDetails } from "./OtherFields";

interface Props {
  name?: string;
  products: OtherFieldsProductDetails[];
  dosingType?: string;
  deleteProduct?: () => void;
  isInjectionBundle?: boolean;
  isBacteriostatic?: boolean;
  isSupplementary?: boolean;
}

const useStyles = makeStyles(({ palette: p }) => ({
  deleteBtn: {
    backgroundColor: p.error.main,
    color: p.error.contrastText,
  },
}));

const productField = "product";
const injectionBundleField = "injectionBundle";
const bacteriostaticField = "bacteriostatic";

export const ProductFields = ({
  name,
  products,
  dosingType,
  isBacteriostatic,
  isInjectionBundle,
  isSupplementary,
  deleteProduct,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const field = useMemo(() => {
    if (isInjectionBundle) {
      return injectionBundleField;
    }
    if (isBacteriostatic) {
      return bacteriostaticField;
    }
    if (isSupplementary) {
      return "";
    }
    return productField;
  }, [isBacteriostatic, isInjectionBundle, isSupplementary]);

  const fieldName = useMemo(() => {
    if (isSupplementary) {
      return name!;
    }
    if (isInjectionBundle || isBacteriostatic) {
      return getFieldName(field, name);
    }
    return getFieldName(name!);
  }, [field, name, isSupplementary, isInjectionBundle, isBacteriostatic]);

  const fieldValue = useMemo(() => getValueFromObject(fieldName, values), [
    values,
    fieldName,
  ]);

  const productsByZohoCrmId = useMemo(() => {
    return products.reduce<Record<string, OtherFieldsProductDetails>>(
      (total, current) => {
        return { ...total, [current.ZohoCrmId]: current };
      },
      {}
    );
  }, [products]);

  const selectedProduct = useMemo(() => {
    return productsByZohoCrmId[fieldValue?.product?.value] || null;
  }, [fieldValue, productsByZohoCrmId]);

  return (
    <Box>
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item xs={4}>
            <ProductSelect products={products} name={fieldName} />
          </Grid>
          <Grid item>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
              wrap="nowrap"
            >
              <QuantityAndPricingFields
                name={fieldName}
                unitPrice={
                  selectedProduct?.UnitPrice
                    ? Number(selectedProduct?.UnitPrice)
                    : 0
                }
                medUsageUnitName={selectedProduct?.MedsUsageUnitName}
                unitsPerSellableUnit={
                  selectedProduct?.UsageUnitsPerSellableUnit
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!isInjectionBundle && !isBacteriostatic ? (
        <Box mb={2}>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <BillingCyclePeriod name={fieldName} />
            </Grid>
            <Grid item>
              <OnlyOnceField name={fieldName} />
            </Grid>

            {isSupplementary ? (
              <Grid
                style={{
                  flex: 1,
                }}
                item
              >
                <Box textAlign="right">
                  <Button
                    onClick={deleteProduct}
                    className={classes.deleteBtn}
                    variant="contained"
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item>
                <SelfSupplyField name={fieldName} />
              </Grid>
            )}
          </Grid>
        </Box>
      ) : null}

      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        {selectedProduct && (
          <OtherFields
            selectedProduct={selectedProduct}
            dosingType={dosingType}
            isInjectionBundle={isInjectionBundle}
            isBacteriostatic={isBacteriostatic}
            forceDoubleLines
          />
        )}
        {(isInjectionBundle || isBacteriostatic) && (
          <Grid item>
            <SelfSupplyField name={fieldName} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
