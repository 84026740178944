import { useFormikContext } from "formik";
import { getFieldErrorNames } from "helpers";
import React, { useEffect } from "react";

export const ScrollToFieldError: React.FC = () => {
  const { setFieldTouched, isValid, errors, values } = useFormikContext();

  useEffect(() => {
    if (isValid) return;
    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;
    const finalName =
      fieldErrorNames[0] === "diagnosis"
        ? "diagnosis.0.selected"
        : fieldErrorNames[0];
    const element: HTMLInputElement | null = document.querySelector(
      `input[name='${finalName}']`
    );
    if (!element) return;

    setFieldTouched(finalName, true);

    element.dispatchEvent(new Event("focus", { bubbles: true }));
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    element.focus();
  }, [(values as any).submitCount]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
