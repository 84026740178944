import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import { RouteProps, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "redux/types";
import { authActions, authSelectors } from "redux/auth";
import { getENText } from "helpers";
import {
  zohoActions,
  transformInitZohoPayload,
  zohoSelectors,
} from "redux/zoho";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import { ZohoUserProfileEnum } from "types";

import TechnicalConfigRestrictionInfo from "components/TechnicalConfigRestrictionInfo";
import { RouteServiceEnum } from "./ServiceRoute.types";
import { InfoMessage } from "./InfoMessage";
import { checkForUserProfiles, convertToArray } from "./helpers";
import { getTechnicalAdminUsers } from "./request";

interface ServiceRouteProps extends RouteProps {
  init?: boolean;
  technicalConfigRestriction?: boolean;
  entity?: boolean;
  services?: RouteServiceEnum | RouteServiceEnum[];
  onlyForTabs?: {
    tabs: string | string[];
    errorMessage?: string | ((currentTab: string) => string);
  };
  notForProfiles?: ZohoUserProfileEnum | ZohoUserProfileEnum[];
  onlyForProfiles?: ZohoUserProfileEnum | ZohoUserProfileEnum[];
}

const ServiceRoute: React.FC<ServiceRouteProps> = ({
  init,
  entity,
  services,
  component,
  onlyForTabs,
  notForProfiles: notForProfilesRaw,
  onlyForProfiles: onlyForProfilesRaw,
  render,
  technicalConfigRestriction = false,
  ...props
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const notForProfiles = useMemo(() => convertToArray(notForProfilesRaw), [
    notForProfilesRaw,
  ]);
  const onlyForProfiles = useMemo(() => convertToArray(onlyForProfilesRaw), [
    onlyForProfilesRaw,
  ]);
  const [widgetAccess, setWidgetAccess] = useState(false);
  const [adminUsersLoading, setAdminUsersLoading] = useState(false);
  const [riskAcknowledged, setRiskAcknowledged] = useState(false);
  const tk = useSelector(authSelectors.token);
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [infoMessage, setInfoMessage] = useState<any>();

  const returnWithError = useCallback(
    (errorMessage = "Something went wrong") => {
      setError((current: any) => current || errorMessage);
      return false;
    },
    []
  );

  const returnWithInfoMessage = useCallback(
    (message = "Something went wrong") => {
      setInfoMessage((current: any) => current || message);
      return false;
    },
    []
  );

  const initZoho = useCallback(async () => {
    const res = await dispatch(zohoActions.initZoho());

    if (!zohoActions.initZoho.fulfilled.match(res)) {
      return returnWithError();
    }

    const entityError = "Cannot find zoho entity";

    if (onlyForTabs) {
      const { tabs, errorMessage } = onlyForTabs;
      if (!res.payload) {
        return returnWithError(entityError);
      }

      const tabArray = Array.isArray(tabs) ? tabs : [tabs];

      if (!tabArray.includes(res.payload.Entity)) {
        const message =
          typeof errorMessage === "function"
            ? errorMessage(res.payload.Entity)
            : errorMessage || getENText("default.notAllowed.widget");
        return returnWithInfoMessage(message);
      }
    }

    if (entity) {
      if (!res.payload) {
        return returnWithError(entityError);
      }
      const { ids } = transformInitZohoPayload(res.payload);
      if (!ids || !ids.length) {
        return returnWithError(entityError);
      }
      return ids;
    }
    return true;
  }, [entity, dispatch, returnWithError, onlyForTabs, returnWithInfoMessage]);

  const getToken = useCallback(async () => {
    const res = await dispatch(authActions.getToken());
    if (!authActions.getToken.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho token");
    }
    return true;
  }, [returnWithError, dispatch]);

  const getAdminUsers = useCallback(async () => {
    if (!technicalConfigRestriction || !tk || !user?.id) {
      return;
    }
    try {
      setAdminUsersLoading(true);
      const res = await getTechnicalAdminUsers();
      setWidgetAccess(res.includes(user.id));
      return;
    } catch {
      setError("Checking technical admin users failed");
    } finally {
      setAdminUsersLoading(false);
    }
  }, [tk, user?.id, technicalConfigRestriction]);

  const fetchCurrentUser = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchCurrentUser());
    if (!zohoActions.fetchCurrentUser.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Current User.");
    }

    if (
      (notForProfiles || onlyForProfiles) &&
      !checkForUserProfiles(res.payload, onlyForProfiles, notForProfiles)
    ) {
      return returnWithInfoMessage(
        "Sorry! You do not have access to this feature!"
      );
    }

    return true;
  }, [
    returnWithError,
    dispatch,
    returnWithInfoMessage,
    notForProfiles,
    onlyForProfiles,
  ]);

  const fetchRecords = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchRecords());
    if (!zohoActions.fetchRecords.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Records");
    }
    return true;
  }, [returnWithError, dispatch]);

  const runServices = useCallback(async () => {
    setLoading(true);
    try {
      const tasks: Promise<boolean>[] = [];

      if (init) {
        const inited = await initZoho();

        if (!inited) {
          setLoading(false);
          return;
        }
        tasks.push(getToken());
      }

      let serviceArray: RouteServiceEnum[] = [];
      if (services) {
        serviceArray = Array.isArray(services) ? services : [services];
      }

      const needUserProfileData = !!(
        notForProfiles ||
        onlyForProfiles ||
        technicalConfigRestriction
      );
      if (needUserProfileData) tasks.push(fetchCurrentUser());
      serviceArray.forEach((service) => {
        if (!needUserProfileData || service === RouteServiceEnum.currentUser) {
          tasks.push(fetchCurrentUser());
        }
        if (service === RouteServiceEnum.records) {
          tasks.push(fetchRecords());
        }
      });

      await Promise.all(tasks);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [
    init,
    services,
    initZoho,
    getToken,
    fetchCurrentUser,
    fetchRecords,
    notForProfiles,
    onlyForProfiles,
    technicalConfigRestriction,
  ]);

  useEffect(() => {
    runServices();
  }, [runServices]);

  useEffect(() => {
    getAdminUsers();
  }, [getAdminUsers]);

  const handleRiskAcknowledged = useCallback(() => {
    setRiskAcknowledged(true);
  }, []);

  const getComponent = (): {
    component?: typeof component | (() => JSX.Element);
    render?: typeof render;
  } => {
    if (loading || adminUsersLoading) {
      return {
        component: () => <Loader open />,
      };
    }

    if (technicalConfigRestriction && !riskAcknowledged) {
      return {
        component: () => (
          <TechnicalConfigRestrictionInfo
            handleRiskAcknowledged={handleRiskAcknowledged}
            access={widgetAccess}
          />
        ),
      };
    }

    if (infoMessage) {
      return {
        component: () => <InfoMessage message={infoMessage} />,
      };
    }

    if (error) {
      return {
        component: () => <ErrorMessage message={error} />,
      };
    }

    return {
      component,
      render,
    };
  };

  return <Route {...getComponent()} {...props} />;
};

export default memo(ServiceRoute);
