import React from "react";
import {
  makeStyles,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePaginationProps,
} from "@material-ui/core";

import { OverflowWrapper } from "components/OverflowWrapper";

import { PureHeadCell } from "./HeadCell";
import { PureBodyRow } from "./BodyRow";
import { DocumentsType, Columns, TABLE_META } from "../interface";

interface StyleProps {
  loading: boolean;
  hasData: boolean;
}

const useStyle = makeStyles(({ palette: p, typography: typo }) => ({
  wrapper: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: ({ loading, hasData }: StyleProps) => ({
    flex: loading || hasData ? 1 : 0,
    overflowX: "hidden",
  }),
  tableBody: ({ loading, hasData }: StyleProps) => ({
    opacity: !loading && hasData ? 1 : 0,
    transition: "0.2s",
  }),
  noData: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noDataTitle: {
    ...typo.body1,
    color: p.grey["600"],
  },
  pagination: {
    width: "100%",
  },
}));

interface TableProps {
  loading: boolean;
  documents: DocumentsType[];
  handleViewDocument: (documentId: string) => void;
  total: number;
  page: number;
  size: number;
  onChangePage: TablePaginationProps["onChangePage"];
  onRowsPerPageChange: TablePaginationProps["onChangeRowsPerPage"];
}

export const Table: React.FC<TableProps> = ({
  loading,
  documents,
  handleViewDocument,
}) => {
  const classes = useStyle({ loading, hasData: !!documents.length });
  return (
    <OverflowWrapper className={classes.wrapper}>
      <TableContainer className={classes.tableContainer}>
        <MuiTable
          stickyHeader
          style={{
            tableLayout: "fixed",
          }}
        >
          <TableHead>
            <TableRow>
              {Object.keys(TABLE_META).map((k) => {
                const key = k as Columns;
                return <PureHeadCell key={key} column={key} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {documents.map((document, index) => {
              return (
                <PureBodyRow
                  key={document.Id}
                  isOdd={!(index % 2)}
                  document={document}
                  handleViewDocument={handleViewDocument}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!loading && !documents.length && (
        <div className={classes.noData}>
          <Typography className={classes.noDataTitle}>
            No documents found
          </Typography>
        </div>
      )}
    </OverflowWrapper>
  );
};
