import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import { useSelector } from "react-redux";

import { zohoServices } from "services";
import { zohoSelectors } from "redux/zoho";
import { replacementOrderValidationSchema } from "helpers";
import { Alert } from "@material-ui/lab";
import { getTreatmentOrder } from "services/treatment-orders";
import { createReplacementOrder } from "services/treatments/replacement-order";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import Loader from "components/Loader";
import { InitialValues } from "./types";
import { validStates } from "./constants";
import { ReplacementOrderForm } from "./ReplacementOrderForm";
import { ActionButtons } from "./ActionButtons";

export const CreateReplacmentOrder = () => {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [treatmentOrderId] = useSelector(zohoSelectors.getIds);

  const initialValues = {
    replacementReason: "",
    replacementType: "full-payment",
    replacementPrice: undefined,
  };

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      setSubmitData(true);
      const replacementTypeMap = {
        "full-payment": "Full Payment",
        "custom-payment": "Custom Payment",
        "no-payment": "No Payment",
      };
      try {
        const payload = {
          replacementReason: values.replacementReason,
          replacementPayment: replacementTypeMap[values.replacementType],
          replacementPrice: values.replacementPrice || "",
        };
        await createReplacementOrder(treatmentOrderId, payload);
      } catch (error) {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      } finally {
        closeWidget(true);
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [closeWidget, treatmentOrderId]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMessage("");
      const treatmentOrderRes = await getTreatmentOrder(treatmentOrderId);
      if (treatmentOrderRes?.ReplacementCreated) {
        setErrorMessage("A replacement has already been created.");
        return;
      }
      if (!validStates.includes(treatmentOrderRes?.State || "this")) {
        setErrorMessage(`
          Replacement can't be created in ${treatmentOrderRes?.State} state. Replacement orders can only be created if order state is Not Received by Client,
          Returned, or Disposed.
        `);
        return;
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [treatmentOrderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={replacementOrderValidationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={900}>
        <TreatmentWidgetTitle isCustomButton title="Create Replacement Order" />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading ? (
            <Box
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!loading && errorMessage && (
                <Alert severity="warning">{errorMessage}</Alert>
              )}
              {!loading && !errorMessage && <ReplacementOrderForm />}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && !errorMessage && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
