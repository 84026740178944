import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Interval,
  McmAvailabilityWithSpecial,
  McmSpecialAvailability,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Badge,
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  CancelOutlined,
  CheckCircle,
  Edit,
  Today,
} from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";
import { StyledIconButton } from "components/StyledIconButton";
import { IntervalsByDayMap } from "types";
import { IntervalItem } from "./IntervalItem";
import { DAYS, makeIntervalsByDay } from "./helpers";
import { SpecialAvailabilityDialog } from "./SpecialAvailabilityDialog";

const useStyles = makeStyles(({ spacing: s, palette: p }) =>
  createStyles({
    tableHeader: {
      background: p.common.white,
      zIndex: 1,
    },
    rowHeader: {
      left: 0,
      position: "sticky",
      minWidth: "200px",
      width: "200px",
      verticalAlign: "text-top",
      paddingLeft: s(1),
      paddingRight: s(1),
    },
    btnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& >*:not(:first-child)": {
        marginLeft: s(1),
      },
    },
    name: {
      marginRight: s(1),
      lineHeight: "30px",
      color: p.primary.main,
      fontWeight: 500,
    },
    cell: {
      "&:not(:last-child) > *": {
        position: "relative",
        "&::after": {
          content: '""',
          borderRight: `1px solid ${p.grey["300"]}`,
          position: "absolute",
          top: 0,
          bottom: 0,
          right: s(-1.5),
        },
      },
      padding: s(2, 1.5),
      width: "352px",
      minWidth: "352px",
    },
    itemsContainer: {
      "& >*:not(:last-child)": {
        paddingBottom: s(2),
      },
    },
    badge: {
      backgroundColor: p.success.main,
      height: s(1.5),
      minWidth: s(1.5),
    },
  })
);

interface AvailabilityRowProps {
  mcm: McmAvailabilityWithSpecial;
  onSaveAvailability: (
    availabilityId: string,
    days: IntervalsByDayMap
  ) => Promise<boolean>;
  onSaveSpecialAvailability: (
    mcmId: string,
    specialAvailability: Omit<McmSpecialAvailability, "id">
  ) => Promise<boolean>;
}

export const AvailabilityRow = ({
  mcm,
  onSaveAvailability,
  onSaveSpecialAvailability,
}: AvailabilityRowProps) => {
  const classes = useStyles();
  const initialDays = useMemo(() => makeIntervalsByDay(mcm.days), [mcm.days]);
  const [days, setDays] = useState(initialDays);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const hasError = useMemo(() => Object.values(errors).some((err) => !!err), [
    errors,
  ]);
  const setError = useCallback((key: string, err: string) => {
    setErrors((prev) => ({
      ...prev,
      [key]: err,
    }));
  }, []);

  useEffect(() => {
    setDays(initialDays);
  }, [mcm.days, initialDays]);

  const [editing, setEditing] = useState(false);
  const toggleEditing = useCallback(() => {
    setEditing((prev) => !prev);
  }, []);

  const onCreateInterval = useCallback((dayOfWeek: string) => {
    setDays((prev) => ({
      ...prev,
      [dayOfWeek]: prev[dayOfWeek].concat([
        { id: uuidv4(), startHour: 8, startMin: 0, endHour: 9, endMin: 0 },
      ]),
    }));
  }, []);

  const onCreateClick = useCallback(
    (dayOfWeek: string) => {
      onCreateInterval(dayOfWeek);
    },
    [onCreateInterval]
  );

  const onCancelClick = useCallback(() => {
    setDays(initialDays);
    toggleEditing();
  }, [initialDays, toggleEditing]);

  const onUpdateInterval = useCallback(
    (dayOfWeek: string, interval: Partial<Interval>) => {
      setDays((prev) => ({
        ...prev,
        [dayOfWeek]: prev[dayOfWeek].map((int) => {
          if (int.id === interval.id) {
            return {
              ...int,
              ...interval,
            };
          }
          return int;
        }),
      }));
    },
    []
  );

  const onDeleteInterval = useCallback(
    (dayOfWeek: string, intervalId: string) => {
      setDays((prev) => ({
        ...prev,
        [dayOfWeek]: prev[dayOfWeek].filter((int) => int.id !== intervalId),
      }));
    },
    []
  );

  const onSaveClick = useCallback(async () => {
    const saved = await onSaveAvailability(mcm.id, days);
    if (saved) {
      toggleEditing();
    }
  }, [mcm.id, days, onSaveAvailability, toggleEditing]);

  const disableSave = useMemo(() => hasError || isEqual(days, initialDays), [
    days,
    hasError,
    initialDays,
  ]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTableRowElement>) => {
      const element = e.target as HTMLElement;
      if (e.keyCode === 13 && disableSave) {
        return;
      }
      if (
        e.keyCode === 13 &&
        element.tagName === "BUTTON" &&
        element.getAttribute("aria-label") !== "save"
      ) {
        return;
      }
      if (e.keyCode === 13) {
        onSaveClick();
      } else if (e.keyCode === 27) {
        onCancelClick();
      }
    },
    [disableSave, onCancelClick, onSaveClick]
  );

  const [specialOpen, setSpecialOpen] = useState(false);
  const closeSpecial = useCallback(() => {
    setSpecialOpen(false);
  }, []);
  const openSpecial = useCallback(() => {
    setSpecialOpen(true);
  }, []);

  const hideBadge = useMemo(
    () =>
      !mcm.specialAvailabilities.length ||
      !mcm.specialAvailabilities.some((s) => !!s.intervals.length),
    [mcm.specialAvailabilities]
  );

  return (
    <TableRow onKeyDown={onKeyDown}>
      <TableCell
        className={`${classes.tableHeader} ${classes.rowHeader}`}
        component="th"
        scope="row"
      >
        <Box
          justifyContent="space-between"
          display="flex"
          alignItems="flex-start"
          mb={1}
        >
          <Typography className={classes.name} variant="body2">
            {mcm.name}
          </Typography>
          <Box className={classes.btnBox}>
            {editing ? (
              <>
                <StyledIconButton
                  size="small"
                  color="info"
                  disabled={disableSave}
                  onClick={onSaveClick}
                  aria-label="save"
                >
                  <CheckCircle />
                </StyledIconButton>
                <IconButton
                  size="small"
                  aria-label="cancel"
                  onClick={onCancelClick}
                >
                  <CancelOutlined />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  size="small"
                  aria-label="edit"
                  onClick={toggleEditing}
                >
                  <Edit />
                </IconButton>
                <Box height="30px" width="30px" />
              </>
            )}
          </Box>
        </Box>
        <Badge
          classes={{
            badge: classes.badge,
          }}
          invisible={hideBadge}
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            endIcon={<Today />}
            onClick={openSpecial}
          >
            Special Availability
          </Button>
        </Badge>
      </TableCell>
      {DAYS.map((dayOfWeek) => {
        const intervals = days[dayOfWeek];
        return (
          <TableCell key={dayOfWeek} className={classes.cell}>
            <Box className={classes.itemsContainer}>
              {intervals.length > 0 &&
                intervals.map((interval) => (
                  <IntervalItem
                    key={interval.id}
                    interval={interval}
                    editing={editing}
                    dayOfWeek={dayOfWeek}
                    onUpdateInterval={onUpdateInterval}
                    onDeleteInterval={onDeleteInterval}
                    setError={setError}
                    errors={errors}
                  />
                ))}
              {!intervals.length && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography color="textSecondary" align="center">
                    Unavailable
                  </Typography>
                </Box>
              )}
            </Box>
            {editing && (
              <Box display="flex" justifyContent="flex-end" pt={1} mb={-1}>
                <StyledIconButton
                  onClick={() => {
                    onCreateClick(dayOfWeek);
                  }}
                  size="small"
                  color="info"
                  aria-label="add interval"
                >
                  <AddCircleOutline />
                </StyledIconButton>
              </Box>
            )}
          </TableCell>
        );
      })}
      {specialOpen && (
        <SpecialAvailabilityDialog
          mcm={mcm}
          open
          onClose={closeSpecial}
          onSaveSpecialAvailability={onSaveSpecialAvailability}
        />
      )}
    </TableRow>
  );
};
