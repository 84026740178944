import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";

import { zohoServices } from "services";
import { useWidgetResize, useTubeLabelsPrint } from "hooks";
import Loader from "components/Loader";
import WidgetButtonWrapper from "components/WidgetButtonWrapper";

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  section: {
    marginTop: s(2),
  },
  text: {
    color: p.grey[500],
  },
  buttons: {
    marginTop: s(2),
    textAlign: "right",
  },
  lastButton: {
    marginLeft: s(1),
  },
}));

const PathwayPrintTubeLabels = () => {
  const { wrapperRef, resize } = useWidgetResize<HTMLDivElement>();
  const { printPDF } = useTubeLabelsPrint();

  const [loading, setLoading] = useState(false);

  const onProceedClick = useCallback(() => {
    printPDF({
      setLoading,
    });
  }, [printPDF]);

  const closeWidget = useCallback(() => {
    zohoServices.closePopup();
  }, []);

  useEffect(() => {
    resize();
  }, [resize]);

  const classes = useStyle();

  return (
    <WidgetButtonWrapper>
      <Container innerRef={wrapperRef}>
        <Loader open={loading} />

        <Typography variant="h5">Tube Labels Printing</Typography>

        <Box className={classes.section}>
          <Typography variant="body1" className={classes.text}>
            A Tube Label will be generated for each tube, after which you can
            print the labels. Tubes quantity depends on the selected Test
            Profiles.
          </Typography>
        </Box>

        <Box className={classes.buttons}>
          <Button variant="text" color="primary" onClick={closeWidget}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.lastButton}
            onClick={onProceedClick}
          >
            Proceed
          </Button>
        </Box>
      </Container>
    </WidgetButtonWrapper>
  );
};

export default PathwayPrintTubeLabels;
