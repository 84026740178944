import { Alert, AlertTitle } from "@material-ui/lab";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { notifications, treatmentServices, zohoServices } from "services";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { AxiosError } from "axios";
import Loader from "components/Loader";
import { ZohoPrescription, ZohoTreatment, ZohoTreatmentOrder } from "types";
import { Formik, FormikConfig } from "formik";
import { clearSavedDefaultValues, getSavedDefaultValues } from "helpers";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { StartPrescriptionRenewalActions } from "./StartPrescriptionRenewalActions";
import { StartPrescriptionRenewalForm } from "./StartPrescriptionRenewalForm";
import { FormValues } from "./types";
import { getRenewPayload, getValidationSchema } from "./helpers";

const dummyData: FormValues = {
  changeType: null,
  generateWithoutRegularCheckUp: null,
  numberOfRepetitions: null,
  pauseDate: null,
  cancellationReason: "",
};

export const StartPrescriptionRenewal = () => {
  const [id] = useSelector(zohoSelectors.getIds);
  const entity = useSelector(zohoSelectors.getEntity);

  const [loading, setLoading] = useState(true);
  const [isFetchedAllData, setIsFetchedAllData] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);
  const [prescription, setPrescription] = useState<ZohoPrescription | null>(
    null
  );
  const [treatment, setTreatment] = useState<ZohoTreatment | null>(null);

  const [unfulfilledOrdersCount, setUnfulfilledOrdersCount] = useState(0);
  const [treatmentUrl, setTreatmentUrl] = useState("");
  const [prescriptionUrl, setPrescriptionUrl] = useState("");

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }

    return "form";
  }, [fetchError]);

  const isSelfSupplied = useMemo(() => {
    return prescription?.Prescription_Type === "Self-Supplied";
  }, [prescription?.Prescription_Type]);

  const maxNumberOfRepetitions = useMemo(() => {
    return unfulfilledOrdersCount - 1 > 0 ? unfulfilledOrdersCount - 1 : 0;
  }, [unfulfilledOrdersCount]);

  const initialValues = useMemo(() => {
    if (!isFetchedAllData) {
      return dummyData;
    }
    const savedDefaultValue = getSavedDefaultValues(
      "start-prescription-renewal",
      id,
      []
    );
    setImmediate(() => {
      setSavedDefaultValues(savedDefaultValue);
    });
    return {
      ...dummyData,
      numberOfRepetitions: maxNumberOfRepetitions,
    };
  }, [id, isFetchedAllData, maxNumberOfRepetitions]);

  const validationSchema = useMemo(() => {
    if (!isFetchedAllData) {
      return null;
    }
    return getValidationSchema(isSelfSupplied, maxNumberOfRepetitions);
  }, [isFetchedAllData, isSelfSupplied, maxNumberOfRepetitions]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const treatmentOrderRes = await zohoServices.getRecord({
        Entity: entity,
        RecordID: id,
      });
      const tOrder = treatmentOrderRes as ZohoTreatmentOrder;
      const [treatmentRes, prescriptionRes] = await Promise.all([
        tOrder.Treatment?.id
          ? zohoServices.getRecord({
              Entity: "Treatments",
              RecordID: tOrder.Treatment.id,
            })
          : new Promise((resolve) => resolve({})),
        zohoServices.getRecord({
          Entity: "Prescriptions",
          RecordID: tOrder.Prescription.id,
        }),
      ]);
      const t = treatmentRes as ZohoTreatment;
      const p = prescriptionRes as ZohoPrescription;

      const [unfulfilledOrdersRes, prescriptionUrlRes] = await Promise.all([
        treatmentServices.fetchUnfulfilledOrders(p.id),
        treatmentServices.fetchPrescriptionUrl(p.id),
      ]);

      setTreatment(t);
      setPrescription(p);
      setUnfulfilledOrdersCount(unfulfilledOrdersRes.unfulfilledOrders || 0);
      setTreatmentUrl(unfulfilledOrdersRes.treatmentUrl);
      setPrescriptionUrl(prescriptionUrlRes.url);

      setIsFetchedAllData(true);
    } catch (e) {
      const err = e as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, entity]);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      if (!id) {
        return;
      }

      try {
        setLoading(true);
        if (values.changeType === "renew") {
          const payload = getRenewPayload(id, values, isSelfSupplied);
          await treatmentServices.postStartPrescriptionRenewal(payload);
        } else {
          const payload = {
            cancellationReason: values.cancellationReason,
          };
          await treatmentServices.postCancelTreatmentOrder(id, payload);
        }
        clearSavedDefaultValues("start-prescription-renewal", id);
        zohoServices.closePopup(true);
      } catch (e) {
        const err = e as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
    [id, isSelfSupplied]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <TreatmentWidgetLayout defaultWidth={1000}>
        <TreatmentWidgetTitle title="Start Prescription Renewal" />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          <DefaultValuesStore
            widgetName="start-prescription-renewal"
            identifier={id}
          />
          {savedDefaultValues && isFetchedAllData ? (
            <SetInitialValues savedDefaultValues={savedDefaultValues} />
          ) : null}
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}
          {view === "form" ? (
            <StartPrescriptionRenewalForm
              isSelfSupplied={isSelfSupplied}
              prescriptionUrl={prescriptionUrl}
              unfulfilledOrdersCount={unfulfilledOrdersCount}
              treatment={treatment}
              treatmentUrl={treatmentUrl}
              maxNumberOfRepetitions={maxNumberOfRepetitions}
            />
          ) : null}
        </TreatmentWidgetContent>
        <TreatmentWidgetActions>
          <StartPrescriptionRenewalActions
            view={view}
            isSelfSupplied={isSelfSupplied}
          />
        </TreatmentWidgetActions>
      </TreatmentWidgetLayout>
    </Formik>
  );
};
