import React from "react";
import { FormikProps } from "formik";
import {
  Lab,
  PathwayRes,
  TestAbnormalFlag,
} from "@deep-consulting-solutions/bmh-constants";
import { MapObxCode, MapObxCodeDetails } from "../TestResults.types";

export interface FormResultItem {
  name: MapObxCode | null;
  identifier: string | MapObxCodeDetails;
  unit: string | MapObxCodeDetails;
  abnormalFlag: TestAbnormalFlag | null;
  isFailed: boolean;
  disableIsFailed: boolean;

  value: string;
  normalRange: string;
  notes: string;
}

export interface FormValues {
  name: string;
  showInPortal: boolean;
  labName: string;
  lab?: Lab;
  relatedPathway: PathwayRes | null;
  relatedPathwaysByLab: {
    [labId: string]: PathwayRes[];
  };
  collectedDate: Date | null;
  collectedTime: Date | null;
  receivedDate: Date | null;
  receivedTime: Date | null;
  reportedDate: Date | null;
  reportedTime: Date | null;
  results: FormResultItem[];
}

export interface PathwayFormValues {
  name: string;
  showInPortal: boolean;
  labName: string;
  lab?: Lab;
  collectedDate: Date | null;
  collectedTime: Date | null;
  receivedDate: Date | null;
  receivedTime: Date | null;
  reportedDate: Date | null;
  reportedTime: Date | null;
  results: FormResultItem[];
}

export const EMPTY_TEST_ROW: FormResultItem = {
  identifier: "",
  name: null,
  value: "",
  normalRange: "",
  unit: "",
  notes: "",
  abnormalFlag: null,
  isFailed: false,
  disableIsFailed: false,
};

export type FormRefProps = FormikProps<FormValues | PathwayFormValues>;

export type FormRef = React.RefObject<FormRefProps>;
