import { Box } from "@material-ui/core";
import { TreatmentMedicinesAndProducts } from "components/Treatments";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import React, { useMemo } from "react";
import {
  AutoCompleteOption,
  ZohoMedUsageUnit,
  ZohoProduct,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
} from "types";

interface Props {
  bacteriostaticProducts: ZohoProduct[];
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  suggestedMedicines: ZohoTreatmentMedicine[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
}

export const NoCombinationForms = ({
  bacteriostaticProducts,
  treatmentTreatmentAreasById,
  suggestedMedicines,
  medUsageUnits,
}: Props) => {
  const { values, initialValues } = useFormikContext<any>();

  const selectedPharmacies = useMemo(
    () =>
      (getValueFromObject("pharmacies", values) as AutoCompleteOption[]) || [],
    [values]
  );

  const initialSelectedPharmacies = useMemo(() => {
    return (
      (getValueFromObject(
        "pharmacies",
        initialValues
      ) as AutoCompleteOption[]) || []
    );
  }, [initialValues]);

  if (!initialValues.treatmentMedicines?.length) {
    return null;
  }

  return (
    <Box>
      <TreatmentMedicinesAndProducts
        suggestedMedicines={suggestedMedicines}
        treatmentTreatmentAreasById={treatmentTreatmentAreasById}
        bacteriostaticProducts={bacteriostaticProducts}
        selectedPharmacies={selectedPharmacies}
        initialSelectedPharmacies={initialSelectedPharmacies}
        medUsageUnits={medUsageUnits}
      />
    </Box>
  );
};
