import React, { useState } from "react";

import { useGetZohoClients } from "hooks";
import AssignMcm from "../AssignMcm";

const MultipleContact = () => {
  const [loading, setLoading] = useState(false);

  const { clients: zohoClients } = useGetZohoClients({
    setLoading,
  });

  return <AssignMcm clientLoading={loading} zohoClients={zohoClients} />;
};

export default MultipleContact;
