import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { zohoServices } from "services";
import { ZohoEntity, ZohoRecord } from "types";
import { AppState, ZohoState } from "redux/types";

const ENTITY = "zoho";

const initZoho = createAsyncThunk<ZohoEntity | null>(
  `${ENTITY}/initZoho`,
  async () => {
    const data = await zohoServices.init();
    return data;
  }
);
export const transformInitZohoPayload = (payload: ZohoEntity) => {
  let ids: string[] | null = null;
  if (payload.EntityId) {
    ids = Array.isArray(payload.EntityId)
      ? payload.EntityId
      : [payload.EntityId];
  }
  return {
    ids,
    entity: payload.Entity || null,
  };
};

const fetchCurrentUser = createAsyncThunk<any, void, { state: AppState }>(
  `${ENTITY}/fetchCurrentUser`,
  async () => {
    const data = await zohoServices.getCurrentUser();

    return data;
  }
);

const fetchRecords = createAsyncThunk<ZohoRecord[], void, { state: AppState }>(
  `${ENTITY}/get-zoho-records`,
  async (_, { getState }) => {
    const { ids, entity } = getState().zoho;
    const data = await zohoServices.getZohoRecords(entity, ids);
    return data;
  }
);

const initialState: ZohoState = {
  ids: [],
  entity: "",
  currentUser: null,
  records: {},
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(initZoho.fulfilled, (state, action) => {
        if (action.payload) {
          const { ids, entity } = transformInitZohoPayload(action.payload);
          if (ids) state.ids = ids;
          if (entity) state.entity = entity;
        }
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      })
      .addCase(fetchRecords.fulfilled, (state, action) => {
        state.records = action.payload.reduce(
          (map: ZohoState["records"], record) => ({
            ...map,
            [record.id]: record,
          }),
          {}
        );
      }),
});

const getIds = (state: AppState) => state.zoho.ids;
const getEntity = (state: AppState) => state.zoho.entity;
const getCurrentUser = (state: AppState) => state.zoho.currentUser;
const getBloodTestResultsPermissions = createSelector(
  getCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      };
    }

    if (
      currentUser.profile.name === "BMH Staff" ||
      currentUser.profile.name === "BMH Doctors"
    ) {
      return {
        canView: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      };
    }

    if (currentUser.profile.name === "BMH Staff with Blood Test Entry") {
      return {
        canView: true,
        canCreate: true,
        canEdit: true,
        canDelete: false,
      };
    }

    return {
      canView: true,
      canCreate: true,
      canEdit: true,
      canDelete: true,
    };
  }
);
const getRecords = (state: AppState) => state.zoho.records;

export const zohoActions = {
  initZoho,
  fetchCurrentUser,
  fetchRecords,
};

export const zohoSelectors = {
  getIds,
  getEntity,
  getCurrentUser,
  getRecords,
  getBloodTestResultsPermissions,
};

export default slice.reducer;
