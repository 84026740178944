import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomRadioGroupField } from "components/CustomRadioGroupField";
import { Field, useFormikContext } from "formik";
import { CustomTextareaField } from "components/CustomTextareaField";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { specialPurposeOfConsultationValues, yesNoOptions } from "../constants";
import { InitialValues } from "../types";
import { useCancelCheckup } from "../hooks";

export const StepTwo = () => {
  const { values } = useFormikContext<InitialValues>();
  const { nextCheckupType, purposeOfLastConsultation } = values;

  const shouldShowIsRecentBloodTestAcceptable = useMemo(() => {
    return specialPurposeOfConsultationValues.includes(
      purposeOfLastConsultation
    );
  }, [purposeOfLastConsultation]);

  const { textOne, textTwo, textThree } = useCancelCheckup();

  return (
    <>
      {values.checkupActionType === "update-details" && (
        <Box minHeight="430px" marginBottom={3}>
          {shouldShowIsRecentBloodTestAcceptable && (
            <>
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", marginBottom: "8px" }}
              >
                Is the recent blood test acceptable for a Doctor&apos;s consult?
                *
              </Typography>
              <Box>
                <CustomRadioGroupField
                  name="isRecentBloodTestAcceptable"
                  options={yesNoOptions}
                />
              </Box>
            </>
          )}
          <Box>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", marginBottom: "8px" }}
            >
              Client Notes *
            </Typography>
            <CustomTextareaField
              name="clientNotes"
              rows={4}
              rowsMax={7}
              helperText="Add comments about the last concluded PCM consultation here..."
            />
          </Box>
          {values.isRecentBloodTestAcceptable === "Yes" && (
            <Box mt={2}>
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", marginBottom: "8px" }}
              >
                Do you want to send an invite to client for a Doctor&apos;s
                consult? *
              </Typography>
              <Box>
                <CustomRadioGroupField
                  name="shouldSendDoctorInvite"
                  options={yesNoOptions}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
      {values.checkupActionType === "cancel-checkup" && (
        <Box minHeight="250px" marginBottom={3}>
          <Box
            maxWidth="680px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            margin="100px auto"
            gridRowGap="16px"
          >
            <Typography
              variant="body1"
              color="primary"
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              {textOne}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              {textTwo}
            </Typography>
            {textThree && (
              <Typography
                variant="body1"
                color="primary"
                style={{
                  marginBottom: "8px",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                {textThree}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {values.checkupActionType === "update-checkup-date" && (
        <Box minHeight="438px" marginBottom={2}>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginBottom: "24px" }}
          >
            Please enter the new date for the next checkup
          </Typography>
          <Box display="flex" alignItems="center" gridColumnGap="32px">
            <Typography color="primary" style={{ width: "170px" }}>
              Next Checkup Type:
            </Typography>
            <Typography color="primary" style={{ width: "350px" }}>
              {nextCheckupType}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gridColumnGap="32px"
            style={{ marginTop: "24px" }}
          >
            <Typography color="primary" style={{ width: "170px" }}>
              Next Checkup Date:
            </Typography>
            <Box width="300px">
              <Field
                component={FormikDatePicker}
                name="nextCheckupDate"
                required
                disablePast
                placeholder="DD/MM/YYYY"
                size="small"
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
