import { BloodTakingOptionEnum } from "@deep-consulting-solutions/bmh-constants";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import ErrorMessage from "components/ErrorMessage";
import Loader from "components/Loader";
import { Form, Formik, FormikConfig } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchBloodTestResultsByIDs,
  postRetestRequiredClientError,
} from "redux/testResults/requests";
import { zohoSelectors } from "redux/zoho";
import { zohoServices } from "services";
import { useUpdateBluePrint } from "hooks";
import { BP_RETEST_REQUIRED_CLIENT_ERROR } from "configs";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { getPayload, initialValues, validationSchema } from "./helpers";
import { FormComponent, Actions } from "./components";
import { BloodOrderType, FormValues, PageEnum, RetestErrorEnum } from "./types";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flex: 1,
      padding: s(2),
    },
    actions: {
      padding: s(2),
    },
    title: {
      padding: s(2),
    },
  })
);

export const RetestRequiredClientError = () => {
  const ids = useSelector(zohoSelectors.getIds);
  const { updateBluePrint } = useUpdateBluePrint();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(PageEnum.Form);
  const [bloodTestOrders, setBloodTestOrders] = useState<BloodOrderType>([]);

  const { isFingerprick, bloodTestOrder } = useMemo(() => {
    const bloodTestOrderFromArray = bloodTestOrders[0];
    if (!bloodTestOrderFromArray) {
      return { isFingerprick: false, bloodTestOrder: null };
    }
    return {
      isFingerprick:
        bloodTestOrderFromArray.bloodTakingOption ===
        BloodTakingOptionEnum.fingerprick,
      bloodTestOrder: bloodTestOrderFromArray,
    };
  }, [bloodTestOrders]);

  const onCancelClick = useCallback(async (reload?: boolean) => {
    await zohoServices.closePopup(reload === true);
  }, []);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      if (!bloodTestOrder?.zohoID) {
        return;
      }
      try {
        setLoading(true);
        const payload = getPayload(values, isFingerprick);
        await postRetestRequiredClientError(bloodTestOrder.zohoID, payload);
        await updateBluePrint(BP_RETEST_REQUIRED_CLIENT_ERROR);
        onCancelClick(true);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    },
    [onCancelClick, bloodTestOrder?.zohoID, isFingerprick, updateBluePrint]
  );

  const getData = useCallback(async () => {
    if (!ids.length) {
      return;
    }
    try {
      setLoading(true);
      const res = await fetchBloodTestResultsByIDs(ids);
      setBloodTestOrders(res);
      setPage(PageEnum.Form);
    } catch (error) {
      setPage(PageEnum.LoadingError);
    } finally {
      setLoading(false);
    }
  }, [ids]);

  useEffect(() => {
    getData();
  }, [getData]);

  const classes = useStyles();
  return (
    <WidgetLayout>
      <Loader open={loading} />

      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid }) => {
          return (
            <Form className={classes.form}>
              <WidgetTitle className={classes.title}>
                <Typography component="h1" variant="h4">
                  Confirm Retest Reason
                </Typography>
              </WidgetTitle>
              <WidgetContent className={classes.content}>
                {page === PageEnum.Form && (
                  <FormComponent
                    isFingerprick={isFingerprick}
                    variant={RetestErrorEnum.ClientError}
                  />
                )}

                {page === PageEnum.LoadingError && (
                  <ErrorMessage
                    message="Failed to get the data necessary for the form, please retry."
                    onButtonClick={getData}
                  />
                )}
              </WidgetContent>

              <WidgetActions className={classes.actions}>
                <Actions
                  loading={loading}
                  onCancelClick={onCancelClick}
                  page={page}
                  isFormValid={isValid}
                  handleSubmit={() => handleSubmit()}
                />
              </WidgetActions>
            </Form>
          );
        }}
      </Formik>
    </WidgetLayout>
  );
};
