import React from "react";
import { FieldsReplicationWidget } from "@deep-consulting-solutions/crm-fields-replication-mui-v4";
import { Box } from "@material-ui/core";

const getAPIUrl = () => {
  return `${process.env.REACT_APP_BASE_URL}`;
};

export const FieldsReplication = () => {
  return (
    <Box paddingY={2}>
      <FieldsReplicationWidget apiUrl={getAPIUrl()} />
    </Box>
  );
};
