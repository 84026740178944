import React from "react";
import {
  makeStyles,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableCell,
} from "@material-ui/core";

import { OverflowWrapper } from "components/OverflowWrapper";

import { PureHeadCell } from "./HeadCell";
import { PureBodyRow } from "./BodyRow";
import { CheckoutPage, Columns, TABLE_META } from "../types";
import { TestProfilesFieldProps } from "../AddCheckoutPageDialog/TestProfilesField";

interface StyleProps {
  loading: boolean;
  hasData: boolean;
}

const useStyle = makeStyles(({ palette: p, typography: typo }) => ({
  wrapper: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: ({ loading, hasData }: StyleProps) => ({
    flex: loading || hasData ? 1 : 0,
    overflowX: "hidden",
  }),
  tableBody: ({ loading, hasData }: StyleProps) => ({
    opacity: !loading && hasData ? 1 : 0,
    transition: "0.2s",
  }),
  noData: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noDataTitle: {
    ...typo.body1,
    color: p.grey["600"],
  },
  pagination: {
    width: "100%",
  },
}));

interface TableProps {
  loading: boolean;
  checkOutPages: CheckoutPage[];
  handleChangeCheckoutPage: (
    checkoutPage: CheckoutPage,
    action: "delete" | "edit"
  ) => void;
  testProfiles: TestProfilesFieldProps["testProfiles"];
}

export const Table: React.FC<TableProps> = ({
  loading,
  checkOutPages,
  testProfiles,
  handleChangeCheckoutPage,
}) => {
  const classes = useStyle({ loading, hasData: !!checkOutPages.length });
  return (
    <OverflowWrapper className={classes.wrapper}>
      <TableContainer className={classes.tableContainer}>
        <MuiTable
          stickyHeader
          style={{
            tableLayout: "fixed",
          }}
        >
          <TableHead>
            <TableRow>
              {Object.keys(TABLE_META).map((k) => {
                const key = k as Columns;
                return <PureHeadCell key={key} column={key} />;
              })}
              <TableCell>{/*  */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {checkOutPages.map((checkOutPage, index) => {
              return (
                <PureBodyRow
                  key={checkOutPage.Id}
                  isOdd={!(index % 2)}
                  checkOutPage={checkOutPage}
                  testProfiles={testProfiles}
                  handleChangeCheckoutPage={handleChangeCheckoutPage}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!loading && !checkOutPages.length && (
        <div className={classes.noData}>
          <Typography className={classes.noDataTitle}>
            No Checkout Pages found
          </Typography>
        </div>
      )}
    </OverflowWrapper>
  );
};
