import { FormattedTreatmentOrder, TreatmentOrder } from "./types";

export function formatTreatmentOrder(
  treatmentOrder: TreatmentOrder
): FormattedTreatmentOrder {
  const patient = treatmentOrder.Patient;
  const shippingAddress = [
    treatmentOrder.Street,
    treatmentOrder.Apartment_Floor_Door_Other,
    treatmentOrder.City,
    treatmentOrder.Province_Region_State,
    treatmentOrder.Postal_Code_Zip,
    treatmentOrder.Country,
  ];
  const patientShippingAddress = shippingAddress.filter(Boolean).join(", ");

  const formattedTreatmentOrder = {
    id: treatmentOrder.id,
    state: treatmentOrder.State,
    orderNumber: treatmentOrder.Name,
    patientName: `${patient?.First_Name || ""} ${patient?.Last_Name || ""}`,
    patientID: patient?.Patient_ID || "N/A",
    patientMobile: patient?.Phone || patient?.Mobile || "N/A",
    patientEmail: patient?.Email || "",
    patientShippingAddress,
    courierOrderNumber: treatmentOrder?.Courier_Order_Number || "",
    courier: treatmentOrder["Courier.Name"] || "",
    courierId: treatmentOrder["Courier.id"] || "",
    courierManifestNumber: treatmentOrder.Courier_Manifest_Number || "",
  };
  return formattedTreatmentOrder;
}
