import { createStyles, makeStyles, Typography } from "@material-ui/core";
import ErrorMessage from "components/ErrorMessage";
import Loader from "components/Loader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  postApproveChargingClientForRetest,
  postGenerateRetestInvoice,
} from "redux/testResults/requests";
import { zohoSelectors } from "redux/zoho";
import { notifications, zohoServices } from "services";
import { AxiosError } from "axios";
import { InvoiceCreationFailureReason } from "@deep-consulting-solutions/bmh-constants";

import { ZohoPathway } from "types";
import {
  WidgetActions,
  WidgetContent,
  WidgetLayout,
  WidgetTitle,
} from "layouts";
import { Actions } from "./Actions";
import { PageEnum } from "../types";
import { ErrorComponent, Success } from "../components";
import { Approval } from "./Approval";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      flex: 1,
      padding: s(2),
    },
    actions: {
      padding: s(2),
    },
    title: {
      padding: s(2),
    },
  })
);

interface Props {
  isGenerateInvoice?: boolean;
}

export const GenerateInvoice = ({ isGenerateInvoice = true }: Props) => {
  const ids = useSelector(zohoSelectors.getIds);
  const entity = useSelector(zohoSelectors.getEntity);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(PageEnum.Loading);
  const [bloodTestOrder, setBloodTestOrder] = useState<ZohoPathway | null>(
    null
  );
  const [invoiceLink, setInvoiceLink] = useState("");
  const [failureReason, setFailureReason] = useState("");

  const reason = useMemo(() => {
    return bloodTestOrder?.Retest_Decision_Explanation_and_Failure_Reason || "";
  }, [bloodTestOrder]);

  const onCancelClick = useCallback(async (reload?: boolean) => {
    await zohoServices.closePopup(reload === true);
  }, []);

  const onDone = useCallback(() => {
    try {
      onCancelClick(true);
    } catch {
      //
    }
  }, [onCancelClick]);

  const onHandleManually = useCallback(() => {
    try {
      onCancelClick(true);
    } catch {
      //
    }
  }, [onCancelClick]);

  const handleUpdateToPendingItemsCreation = useCallback(() => {
    try {
      onCancelClick(true);
    } catch (e) {
      //
    } finally {
      //
    }
  }, [onCancelClick]);

  const handleFlow = useCallback(async () => {
    if (!bloodTestOrder?.id) {
      return;
    }
    try {
      const res = isGenerateInvoice
        ? await postGenerateRetestInvoice(bloodTestOrder.id)
        : await postApproveChargingClientForRetest(bloodTestOrder.id);
      setInvoiceLink(res.invoiceUrl || "");
      setPage(PageEnum.Success);
    } catch (e) {
      const error = e as AxiosError;
      const invoiceCreationFailureReason =
        error.response?.data?.data?.invoiceCreationFailureReason;
      if (invoiceCreationFailureReason) {
        setFailureReason(invoiceCreationFailureReason);
        setPage(
          invoiceCreationFailureReason ===
            InvoiceCreationFailureReason.MISSING_ITEMS
            ? PageEnum.Error
            : PageEnum.CreateInvoiceError
        );
      } else {
        notifications.notifyError(
          error.response?.data?.message || error.message
        );
      }
    }
  }, [bloodTestOrder?.id, isGenerateInvoice]);

  const onHandleApproval = useCallback(async () => {
    try {
      setLoading(true);
      await handleFlow();
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [handleFlow]);

  const getData = useCallback(async () => {
    if (!ids.length) {
      return;
    }
    try {
      setLoading(true);
      const res = await zohoServices.getRecord<ZohoPathway>({
        Entity: entity,
        RecordID: ids[0],
      });
      setBloodTestOrder(res);
      if (!isGenerateInvoice) {
        setPage(PageEnum.Approval);
      }
    } catch (error) {
      setPage(PageEnum.LoadingError);
    } finally {
      setLoading(false);
    }
  }, [ids, isGenerateInvoice, entity]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (isGenerateInvoice) {
      onHandleApproval();
    }
  }, [onHandleApproval, isGenerateInvoice]);

  const classes = useStyles();
  return (
    <WidgetLayout>
      <WidgetTitle className={classes.title}>
        <Typography component="h1" variant="h4">
          Approve Charging Client for Retest
        </Typography>
      </WidgetTitle>
      <WidgetContent className={classes.content}>
        <Loader open={loading} />
        {page === PageEnum.Approval && <Approval reason={reason} />}
        {page === PageEnum.LoadingError && (
          <ErrorMessage
            message="Failed to get the data necessary for the form, please retry."
            onButtonClick={getData}
          />
        )}
        {page === PageEnum.Error && (
          <ErrorComponent
            title="Invoice creation failed"
            body="Invoice creation failed because of missing items and the manager was notified to create the missing item in Zoho Books."
          />
        )}
        {page === PageEnum.CreateInvoiceError && (
          <ErrorComponent
            handleRetry={onHandleApproval}
            title="Invoice creation failed"
            failureReason={failureReason}
            body='Retry or Create the retest invoice on Zoho Books then confirm the creation using "Confirm Kit Invoice Creation" button on the next Screen.'
          />
        )}
        {page === PageEnum.Success && (
          <Success retestInvoiceLink={invoiceLink} isPayForRetestEmailSent />
        )}
      </WidgetContent>

      <WidgetActions className={classes.actions}>
        <Actions
          loading={loading}
          onCancelClick={onCancelClick}
          onDoneClick={onDone}
          page={page}
          handleUpdateToPendingItemsCreation={
            handleUpdateToPendingItemsCreation
          }
          onHandleManually={onHandleManually}
          onHandleApproval={onHandleApproval}
        />
      </WidgetActions>
    </WidgetLayout>
  );
};
