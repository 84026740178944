import React, { useMemo } from "react";
import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { FormikNumberField } from "components/FormikNumberField";
import { getFieldName, getValueFromObject, isStringEqual } from "helpers";
import { AutoCompleteOption } from "types";
import { useStyles } from "../styles";
import { FrequencyField } from "./FrequencyField";
import { TimeOfDayField } from "./TimeOfDayField";
import { PatternOfUseField } from "./PatternOfUseField";

interface Props {
  name?: string;
}

export const FrequencyFields = ({ name }: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const patternOfUseFieldName = useMemo(
    () => getFieldName("patternOfUse", name),
    [name]
  );

  const frequencyOfUseFieldName = useMemo(
    () => getFieldName("frequencyOfUse", name),
    [name]
  );

  const frequencyOfUseFieldValue: AutoCompleteOption | null = useMemo(
    () => getValueFromObject(frequencyOfUseFieldName, values),
    [frequencyOfUseFieldName, values]
  );

  const patternOfUseFieldValue = useMemo(
    () => getValueFromObject(patternOfUseFieldName, values),
    [patternOfUseFieldName, values]
  );

  const isWeekFrequency = useMemo(() => {
    return frequencyOfUseFieldValue?.value.toLowerCase().includes("week");
  }, [frequencyOfUseFieldValue?.value]);

  return (
    <Box mb={2} className={classes.fieldsSection}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={3}>
          <FrequencyField name={name} />
        </Grid>

        <Grid item xs={3}>
          <Field
            component={FormikNumberField}
            name={`${name}.numberOfUse`}
            label="Number (X)"
            required
            type="integer"
            size="small"
            fullWidth
            min={0}
            numberFormatProps={{
              fixedDecimalScale: false,
              decimalScale: isStringEqual(
                frequencyOfUseFieldValue?.title || "",
                "Every 'X' Days"
              )
                ? 1
                : 0,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimeOfDayField name={name} />
        </Grid>
      </Grid>
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={3}>
            <PatternOfUseField name={name} />
          </Grid>

          {patternOfUseFieldValue?.value === "temporary" ? (
            <Grid item xs={3}>
              <Field
                component={FormikNumberField}
                name={`${name}.numberOfDays`}
                label={`Number of ${isWeekFrequency ? "Weeks" : "Days"}`}
                required
                type="integer"
                size="small"
                fullWidth
                min={0}
                numberFormatProps={{
                  fixedDecimalScale: true,
                  decimalScale: 0,
                }}
              />
            </Grid>
          ) : null}

          {patternOfUseFieldValue?.value === "cyclical" ? (
            <>
              <Grid item xs={3}>
                <Field
                  component={FormikNumberField}
                  name={`${name}.cyclicalUseDays`}
                  label="Use For"
                  required
                  type="integer"
                  size="small"
                  fullWidth
                  min={0}
                  numberFormatProps={{
                    fixedDecimalScale: true,
                    decimalScale: 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>
                          {isWeekFrequency ? "Weeks" : "Days"}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  component={FormikNumberField}
                  name={`${name}.cyclicalStopDays`}
                  label="Pause For"
                  required
                  type="integer"
                  size="small"
                  fullWidth
                  min={0}
                  numberFormatProps={{
                    fixedDecimalScale: true,
                    decimalScale: 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>
                          {isWeekFrequency ? "Weeks" : "Days"}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};
