import React, { memo, useState, useCallback } from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Loader from "components/Loader";

import { DoneIcon, WarningIcon } from "../Unmatched.styles";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  text: {
    color: p.grey[600],
  },
  actions: {
    paddingRight: s(2),
    paddingBottom: s(2),
  },
  subWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subtext: {
    marginTop: s(2),
  },
  subBtn: {
    marginTop: s(2),
  },
}));

interface ReplaceOldFileDialogProps extends DialogProps {
  replaceOldFile: () => Promise<boolean>;
  onClose: () => any;
}

const ReplaceOldFileDialog: React.FC<ReplaceOldFileDialogProps> = ({
  replaceOldFile,
  onClose,
  ...props
}) => {
  const [loadingState, setLoadingState] = useState<null | 0 | -1 | 1>(null);

  const onCloseClick = useCallback(() => {
    onClose();
    setLoadingState(null);
  }, [onClose]);

  const onProceedClick = useCallback(async () => {
    setLoadingState(0);
    const isOK = await replaceOldFile();
    setLoadingState(isOK ? 1 : -1);
  }, [replaceOldFile]);

  const classes = useStyle();

  return (
    <Dialog onClose={onCloseClick} {...props}>
      <Loader open={loadingState === 0} />

      {!loadingState && (
        <>
          <DialogTitle disableTypography>
            <Typography variant="h5">Replace Old File</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" className={classes.text}>
              Existing blood test result record will be substituted with the new
              file. Make sure that you contacted the laboratory to confirm that
              replacing must happen!
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button variant="text" onClick={onCloseClick}>
              CANCEL
            </Button>
            <Button color="primary" onClick={onProceedClick}>
              PROCEED
            </Button>
          </DialogActions>
        </>
      )}

      {!!loadingState && (
        <DialogContent className={classes.subWrapper}>
          {loadingState > 0 ? <DoneIcon /> : <WarningIcon />}
          <Typography variant="body2" className={classes.subtext}>
            {loadingState > 0
              ? "File was successfully replaced!"
              : "Something went wrong!"}
          </Typography>
          <Button
            color="primary"
            className={classes.subBtn}
            onClick={onCloseClick}
          >
            {loadingState > 0 ? "GOT IT!" : "BACK"}
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default memo(ReplaceOldFileDialog);
