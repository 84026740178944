import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { removeN8NAuthorizationHeader, setN8NAuthorizationHeader } from "./n8n";
import { removeCRHAuthorizationHeader, setCRHAuthorizationHeader } from "./crh";

const client = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

const silentAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let cancelToken: CancelTokenSource | undefined;

silentAxiosClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (cancelToken) {
      cancelToken.cancel("Canceling previous request");
    }

    cancelToken = axios.CancelToken.source();
    config.cancelToken = cancelToken.token;

    return config;
  },
  (error) => Promise.reject(error)
);

export const setAuthorizationHeader = (token: string) => {
  client.defaults.headers.Authorization = `Bearer ${token}`;
  silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  setN8NAuthorizationHeader(token);
  setCRHAuthorizationHeader(token);
};

export const removeAuthorizationHeader = () => {
  delete client.defaults.headers.Authorization;
  delete silentAxiosClient.defaults.headers.Authorization;
  removeN8NAuthorizationHeader();
  removeCRHAuthorizationHeader();
};

export const apiClient = client;
export const silentClient = silentAxiosClient;
export {
  n8nApiClient,
  n8nSilentClient,
  n8nApiClient as n8nClient,
} from "./n8n";

export {
  crhApiClient,
  crhSilentClient,
  crhApiClient as crhClient,
} from "./crh";
