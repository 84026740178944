import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { Alert } from "@material-ui/lab";
import { FormValues } from "./types";

const useStyles = makeStyles(({ palette: p }) => ({
  danger: {
    color: p.error.main,
    borderColor: p.error.main,
    cursor: "pointer",
  },
  action: {
    width: "100%",
  },
}));

interface Props {
  view: "fetch-error" | "form";
  isSelfSupplied: boolean;
}

export const StartPrescriptionRenewalActions = ({
  view,
  isSelfSupplied,
}: Props) => {
  const classes = useStyles();

  const { values, handleSubmit, isValid } = useFormikContext<FormValues>();

  const { handleClose } = useTreatmentWidgetLayout();

  const isRenew = useMemo(() => {
    return values.changeType === "renew";
  }, [values.changeType]);

  const isMakeChanges = useMemo(() => {
    return values.changeType === "make-changes";
  }, [values.changeType]);

  const showPcmWarning = useMemo(() => {
    return (
      (isSelfSupplied || values.generateWithoutRegularCheckUp === "no") &&
      isRenew
    );
  }, [values.generateWithoutRegularCheckUp, isRenew, isSelfSupplied]);

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const onSave = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <>
      {view === "fetch-error" ? (
        <Button variant="outlined" onClick={onClickClose} color="primary">
          CLOSE
        </Button>
      ) : null}
      {view === "form" ? (
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={onClickClose}
              className={classes.danger}
            >
              CLOSE
            </Button>
          </Grid>
          <Grid item>
            <Grid
              container
              justify="flex-end"
              alignItems="flex-start"
              spacing={2}
              wrap="nowrap"
            >
              {showPcmWarning || isMakeChanges ? (
                <Grid
                  item
                  style={{
                    flex: 1,
                  }}
                >
                  <Box display="flex" justifyContent="flex-end">
                    <Box maxWidth="80%">
                      {showPcmWarning ? (
                        <Alert severity="warning">
                          Make sure client&apos;s PCM is aware of the delay in
                          regular checkup. Click on renew to generate a
                          prescription without repeats so that the paid order
                          can be fulfilled and to pause the treatment.
                        </Alert>
                      ) : null}
                      {isMakeChanges ? (
                        <Alert severity="warning">
                          When you click &quot;
                          <strong>Credit and Cancel Order</strong>&quot; a
                          credit note will be automatically generated for the
                          client with the amount they paid for this order.
                        </Alert>
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSave}
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  {isRenew ? "Renew" : "Credit and Cancel Order"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
