import { Box, Typography } from "@material-ui/core";
import React from "react";

export const FormsDialog = () => {
  return (
    <Box>
      <Typography align="center">What do you want to update?</Typography>
    </Box>
  );
};
