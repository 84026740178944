import {
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  DialogActions,
  Button,
  DialogContent,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Formik, FormikConfig, Field } from "formik";
import { TextField } from "formik-material-ui";
import React, { useCallback, MouseEventHandler, useState } from "react";
import Loader from "components/Loader";
import { initialValues, validationSchema } from "./helpers";
import { FormValues } from "./types";

interface Props extends DialogProps {
  handleDiscardFile: (values: FormValues) => Promise<boolean>;
}

const useStyle = makeStyles(({ spacing: s }) => ({
  title: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
}));

export const DiscardFileDialog = ({
  handleDiscardFile,
  onClose,
  ...props
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose]
  );

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      try {
        setLoading(true);
        await handleDiscardFile(values);
      } catch {
        //
      } finally {
        setLoading(false);
      }
    },
    [handleDiscardFile]
  );
  const classes = useStyle();

  return (
    <Dialog {...props} onClose={onClose}>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <>
              <Loader open={loading} />
              <DialogTitle className={classes.title}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h4" align="center">
                      Discard File
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent className={classes.content}>
                <Typography>
                  Discarding the file will keep the blood test order details and
                  results unchanged.
                </Typography>

                <Box mt={2} data-testid="discard-dialog-reason">
                  <Field
                    component={TextField}
                    name="reason"
                    label="Retest for Discarding*"
                    placeholder="Add text"
                    multiline
                    rows={5}
                  />
                </Box>
              </DialogContent>

              <DialogActions className={classes.actions}>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={!isValid}
                      data-testid="discard-dialog-button"
                    >
                      DISCARD
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
