import {
  Container,
  DialogActions,
  DialogActionsProps,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { getParentElement } from "helpers";
import { useWidgetLayout } from "../useWidgetLayout";

interface Props extends DialogActionsProps {
  children: any;
}

export const WidgetActions = ({ children, ...props }: Props) => {
  const { setActionsDimension } = useWidgetLayout();

  const elementID = useMemo(() => {
    return String(Math.random());
  }, []);

  const handleMutation = useCallback(
    (entries: MutationRecord[]) => {
      const entry = entries[0];

      const element = getParentElement(
        (entry.target as unknown) as HTMLElement,
        elementID
      );

      if (element) {
        setActionsDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
      }
    },
    [elementID, setActionsDimension]
  );

  const observer = useMemo(() => {
    return new MutationObserver(handleMutation);
  }, [handleMutation]);

  const wrapperRef = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        setActionsDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
        observer.observe(element, {
          childList: true,
          attributes: true,
          subtree: true,
        });
      }
    },
    [observer, setActionsDimension]
  );

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return (
    <DialogActions {...props}>
      <Container id={elementID} ref={wrapperRef}>
        {children || null}
      </Container>
    </DialogActions>
  );
};
