import { getENText } from "helpers";
import * as Yup from "yup";
import { FormValues } from "./types";

export const initialValues: FormValues = {
  reason: "",
};

export const validationSchema = Yup.object({
  reason: Yup.string().required(getENText("reason.required")),
});
