import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { Field } from "formik";
import { getFieldName } from "helpers";
import React, { useMemo } from "react";

interface Props {
  name: string;
  label: string;
  namePrefix?: string;
}

export const DateInputField = ({ name, namePrefix, label }: Props) => {
  const fieldName = useMemo(() => getFieldName(name, namePrefix), [
    name,
    namePrefix,
  ]);

  return (
    <Field
      component={FormikDatePicker}
      name={fieldName}
      label={label}
      required
      placeholder="DD/MM/YYYY"
      displayFormat="dd/MM/yyyy"
      size="small"
      fullWidth
    />
  );
};
