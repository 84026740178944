import React from "react";
import { EmailQuarantineManagerWidget } from "@deep-consulting-solutions/email-sender-management-mui-v4";

const getAPIUrl = () => {
  return `${process.env.REACT_APP_BASE_URL}`;
};

export const EmailQuarantineManager: React.FC = () => {
  return <EmailQuarantineManagerWidget apiUrl={getAPIUrl()} />;
};
