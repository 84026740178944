import React, { useCallback, useState } from "react";
import { FieldProps } from "formik";
import { TextField } from "@material-ui/core";

const MultilineField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = form.getFieldMeta(name);

  const [focus, setFocus] = useState(false);

  const onInputFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.target.scrollTop = 0;
      setFocus(false);
      onBlur(e);
    },
    [onBlur]
  );

  return (
    <TextField
      name={name}
      value={value}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onChange={onChange}
      error={touched && !!error}
      helperText={touched && error}
      multiline
      rowsMax={focus ? 3 : 1}
      {...props}
    />
  );
};

export default MultilineField;
