import { Box } from "@material-ui/core";
import { SupplementaryProducts } from "components/Treatments";
import React from "react";
import { AutoCompleteOption, ZohoMedUsageUnit, ZohoProduct } from "types";

interface Props {
  products: ZohoProduct[];
  selectedPharmacies: AutoCompleteOption[];
  initialSelectedPharmacies: AutoCompleteOption[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  allowChangeBillingCycleUnit: boolean;
}

export const SupplementaryProductsForm = ({
  products,
  selectedPharmacies,
  initialSelectedPharmacies,
  medUsageUnits,
  allowChangeBillingCycleUnit,
}: Props) => {
  return (
    <Box mb={2} mt={4}>
      <SupplementaryProducts
        supplementaryProducts={products}
        selectedPharmacies={selectedPharmacies}
        initialSelectedPharmacies={initialSelectedPharmacies}
        showAlertInfo
        medUsageUnits={medUsageUnits}
        allowChangeBillingCycleUnit={allowChangeBillingCycleUnit}
      />
    </Box>
  );
};
