import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import {
  ClientDetails,
  DiagnosisField,
  DoctorField,
  TreatmentFields,
  TreatmentMedicinesInfo,
  TreatmentMedicinesWarning,
  TreatmentTreatmentAreasMedicines,
} from "components/Treatments";
import { TreatmentTreatmentAreasField } from "components/Treatments/TreatmentTreatmentAreasField/TreatmentTreatmentAreasField";

import {
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import React, { useCallback } from "react";
import {
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoDiagnosisOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoTreatment,
  ZohoTreatmentArea,
} from "types";
import { FormikErrors, FormikHelpers, useFormikContext } from "formik";
import { CreateTreatmentActions } from "./CreateTreatmentActions";

interface Props {
  isCustomButton?: boolean;
  loading: boolean;
  submitDetails: (
    values: any,
    formErrors: FormikErrors<any>,
    setFieldTouched: FormikHelpers<any>["setFieldTouched"],
    finalize?: boolean,
    draft?: boolean
  ) => Promise<void>;
  treatmentAreas: ZohoTreatmentArea[];
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
  doctor: ZohoBMHDoctor | null;
  doctors: ZohoBMHDoctor[];
  createdTreatment: ZohoTreatment | null;
  fetchError: string;
  client: ZohoClientRecord | null;
  view: "success" | "initial-block" | "fetch-error" | "form";
  blockWidgetAlert: JSX.Element;
  title: string;
  submitButtonText: string;
  cancelDialog: {
    title: string;
    text: string;
    proceedButtonText: string;
    cancelButtonText: string;
  };
  otherMedicineTypeId: string;
  diagnosisOptions: ZohoDiagnosisOption[];
}

export const CreateTreatment = ({
  isCustomButton = false,
  loading,
  submitDetails,
  treatmentAreas,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
  doctors,
  doctor,
  createdTreatment,
  fetchError,
  client,
  view,
  blockWidgetAlert,
  title,
  submitButtonText,
  cancelDialog,
  otherMedicineTypeId,
  diagnosisOptions,
}: Props) => {
  const { validateForm, setFieldTouched, setSubmitting } = useFormikContext();

  const handleSubmitClick = useCallback(
    async (values: any, finalize?: boolean, draft?: boolean) => {
      setSubmitting(true);
      const errors = await validateForm();
      await submitDetails(values, errors, setFieldTouched, finalize, draft);
      setSubmitting(false);
    },
    [submitDetails, validateForm, setFieldTouched, setSubmitting]
  );

  return (
    <TreatmentWidgetLayout cancelDialog={cancelDialog} defaultWidth={1000}>
      <TreatmentWidgetTitle title={title} isCustomButton={isCustomButton} />
      <TreatmentWidgetContent>
        <Loader open={loading} />
        {view === "fetch-error" ? (
          <Alert severity="error">
            <AlertTitle>An error occurred while fetching data.</AlertTitle>
            {fetchError}
          </Alert>
        ) : null}
        {view === "success" ? (
          <Alert severity="success">
            <AlertTitle>New Treatment Plan Created Successfully.</AlertTitle>
            Medicine product packages are calculated and Processing team has
            been notified to confirm selection for prescription generation
          </Alert>
        ) : null}
        {view === "initial-block" ? blockWidgetAlert : null}
        {view === "form" ? (
          <>
            <Box mb={4}>
              <ClientDetails client={client} />
              <DoctorField doctors={doctors} doctor={doctor} />
            </Box>
            <Box mb={4}>
              <DiagnosisField
                client={client}
                diagnosisOptions={diagnosisOptions}
              />
            </Box>
            <Box mb={4}>
              <Box mb={2} maxWidth={500}>
                <TreatmentTreatmentAreasField treatmentAreas={treatmentAreas} />
              </Box>
              <Box mb={2}>
                <TreatmentMedicinesWarning />
              </Box>
              <TreatmentTreatmentAreasMedicines
                treatmentAreas={treatmentAreas}
                dosingTypes={dosingTypes}
                dosingAdminRoutes={dosingAdminRoutes}
                medicineAdminRoutes={medicineAdminRoutes}
              />
            </Box>
            <Box mb={2}>
              <TreatmentFields />
            </Box>
            <TreatmentMedicinesInfo
              submitButtonText={submitButtonText}
              doctor={doctor}
              otherMedicineTypeId={otherMedicineTypeId}
            />
          </>
        ) : null}
      </TreatmentWidgetContent>
      <CreateTreatmentActions
        view={view}
        onSubmit={handleSubmitClick}
        doctor={doctor}
        createdTreatmentUrl={createdTreatment?.url}
        submitButtonText={submitButtonText}
        otherMedicineTypeId={otherMedicineTypeId}
      />
    </TreatmentWidgetLayout>
  );
};
