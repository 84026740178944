import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import {
  createStyles,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useMemo, useCallback } from "react";
import { CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW } from "../../constants";
import { getSortedLabBloodDrawers } from "../../helpers";
import { FormValues } from "../../types";
import { OfferingNurseRowGroup } from "./OfferingNurseRowGroup";

const useStyles = makeStyles(() =>
  createStyles({
    radio: {},
  })
);

interface Props {
  bloodDrawers: BloodDrawer[];
  isClientWantsSourcedBloodDrawerSelectDisabled: boolean;
}

export const OfferingNurseBloodDrawerOptions = ({
  bloodDrawers,
  isClientWantsSourcedBloodDrawerSelectDisabled,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const isClientWantsSourcedBloodDrawer = useMemo(() => {
    return values.nurseBloodDrawer === CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW;
  }, [values.nurseBloodDrawer]);

  const onClientWantsSourcedBloodDrawerSelect = useCallback(() => {
    setFieldValue("nurseBloodDrawer", CLIENTS_WANTS_PATIENT_SOURCED_BLOOD_DRAW);
  }, [setFieldValue]);

  const sortedBloodDrawers = useMemo(
    () => getSortedLabBloodDrawers(bloodDrawers),
    [bloodDrawers]
  );

  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader>
        {bloodDrawers.length ? (
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>

              <TableCell>
                <Typography>Blood Drawer</Typography>
              </TableCell>
              <TableCell>
                <Typography>Price</Typography>
              </TableCell>
              <TableCell>
                <Typography>Prepaid by BMH</Typography>
              </TableCell>
              <TableCell>
                <Typography>Provides Own Kits</Typography>
              </TableCell>
              <TableCell>
                <Typography>Takes Sample to the Lab</Typography>
              </TableCell>
              <TableCell>
                <Typography>Special Instructions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {sortedBloodDrawers.map((bloodDrawer) => (
            <OfferingNurseRowGroup
              key={bloodDrawer.id}
              bloodDrawer={bloodDrawer}
            />
          ))}

          <TableRow>
            <TableCell>
              <Radio
                className={classes.radio}
                color="primary"
                size="small"
                checked={isClientWantsSourcedBloodDrawer}
                onClick={onClientWantsSourcedBloodDrawerSelect}
                disabled={isClientWantsSourcedBloodDrawerSelectDisabled}
              />
            </TableCell>
            <TableCell colSpan={6}>
              <Typography>Client wants patient sourced blood draw</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
