import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import {
  getFieldName,
  getShowBacteriostatic,
  getShowInjectionBundle,
  getValueFromObject,
  isStringEqual,
  mapZohoCrmProductsToDbProducts,
} from "helpers";
import React, { useCallback, useMemo } from "react";
import {
  ZohoTreatmentTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoProduct,
  AutoCompleteOption,
  ZohoMedUsageUnit,
} from "types";
import { TextField } from "formik-material-ui";
import { ProductFields } from "./ProductFields";
import { DeliveredProductDetails } from "../DeliveredProductDetails";

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
  productWrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  name?: string;
  suggestedMedicine: ZohoTreatmentMedicine;
  treatmentTreatmentArea: ZohoTreatmentTreatmentArea;
  bacteriostaticProducts: ZohoProduct[];
  initialSelectedPharmacies: AutoCompleteOption[];
  selectedPharmacies: AutoCompleteOption[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  allowChangeBillingCycleUnit?: boolean;
}

export const TreatmentMedicineAndProduct = ({
  name = "",
  suggestedMedicine,
  treatmentTreatmentArea,
  bacteriostaticProducts,
  initialSelectedPharmacies,
  selectedPharmacies,
  medUsageUnits,
  allowChangeBillingCycleUnit = false,
}: Props) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const { treatmentAreaName, medicineName } = useMemo(() => {
    return {
      medicineName: isStringEqual(suggestedMedicine.Medicine.name, "other")
        ? suggestedMedicine.Other_Medicine_Type
        : suggestedMedicine.Medicine.name,
      treatmentAreaName: isStringEqual(
        treatmentTreatmentArea.Treatment_Area.name,
        "other"
      )
        ? treatmentTreatmentArea.Other_Treatment_Area
        : treatmentTreatmentArea.Treatment_Area.name,
    };
  }, [
    suggestedMedicine.Medicine.name,
    suggestedMedicine.Other_Medicine_Type,
    treatmentTreatmentArea.Treatment_Area.name,
    treatmentTreatmentArea.Other_Treatment_Area,
  ]);

  const selectedProduct = useMemo(
    () => getValueFromObject(getFieldName("product", name), values),
    [values, name]
  );

  const productsById = useMemo(
    () =>
      suggestedMedicine.products?.reduce<Record<string, ZohoProduct>>(
        (total, current) => {
          return { ...total, [current.id]: current };
        },
        {}
      ) || {},
    [suggestedMedicine.products]
  );

  const selectedProductPharmacy = useMemo(
    () =>
      selectedProduct?.value
        ? productsById[selectedProduct?.value]?.Pharmacy?.id
        : null,
    [productsById, selectedProduct?.value]
  );

  const filterAndMapProducts = useCallback(
    (ps: ZohoProduct[], isChildProduct = false) => {
      return mapZohoCrmProductsToDbProducts(
        ps?.filter((p) => {
          if (isChildProduct && p.Pharmacy?.id !== selectedProductPharmacy) {
            return false;
          }
          return selectedPharmacies.some((s) => s.value === p.Pharmacy.id);
        }),
        medUsageUnits
      );
    },
    [selectedPharmacies, selectedProductPharmacy, medUsageUnits]
  );

  const suggestedMedicineProducts = useMemo(() => {
    return filterAndMapProducts(suggestedMedicine.products || []);
  }, [suggestedMedicine.products, filterAndMapProducts]);

  const suggestedMedicineInjectionProducts = useMemo(() => {
    return filterAndMapProducts(suggestedMedicine.injectionBundles || [], true);
  }, [suggestedMedicine.injectionBundles, filterAndMapProducts]);

  const bacteriostaticProductsOptions = useMemo(() => {
    return filterAndMapProducts(bacteriostaticProducts || [], true);
  }, [bacteriostaticProducts, filterAndMapProducts]);

  const showInjectionBundle = useMemo(() => {
    if (!selectedProduct?.value) {
      return false;
    }
    return getShowInjectionBundle(
      suggestedMedicine.Dosing_Type.name,
      treatmentTreatmentArea.Treatment_Area.name
    );
  }, [
    suggestedMedicine.Dosing_Type,
    treatmentTreatmentArea?.Treatment_Area,
    selectedProduct?.value,
  ]);

  const showBact = useMemo(() => {
    if (!selectedProduct?.value) {
      return false;
    }
    return getShowBacteriostatic(
      suggestedMedicine.Dosing_Type.name,
      treatmentTreatmentArea.Treatment_Area.name
    );
  }, [
    suggestedMedicine.Dosing_Type,
    treatmentTreatmentArea?.Treatment_Area,
    selectedProduct?.value,
  ]);

  const isMedicineDelivered = useMemo(() => {
    if (!suggestedMedicine.product?.delivered) {
      return false;
    }
    if (
      showInjectionBundle &&
      !suggestedMedicine.product?.injectionBundle?.delivered
    ) {
      return false;
    }
    if (showBact && !suggestedMedicine.product?.bacteriostatic?.delivered) {
      return false;
    }
    return true;
  }, [
    suggestedMedicine.product?.delivered,
    showInjectionBundle,
    suggestedMedicine.product?.injectionBundle?.delivered,
    showBact,
    suggestedMedicine.product?.bacteriostatic?.delivered,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.text}>
              <b>{medicineName}</b> ({treatmentAreaName})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {suggestedMedicine.Specify_Custom_Dosing_Instructions ? (
              <Typography variant="body2">
                Custom Dosing Instructions:
                <br />
                <b>{suggestedMedicine.Custom_Dosing_Instructions}</b>
              </Typography>
            ) : (
              <Typography variant="body2">
                Dose:
                <br />
                <b>{suggestedMedicine.dose}</b>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={`${name}.usageNotes`}
              label="Usage Notes"
              size="small"
              multiline
              rows={3}
              fullWidth
              helperText="Usage Notes are displayed below the dosing in the prescription"
              disabled={isMedicineDelivered}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={`${name}.notesForMedicineSelection`}
              label="Notes for Medicine Selection"
              size="small"
              multiline
              rows={3}
              disabled={isMedicineDelivered}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.productWrapper}>
        <Box>
          {suggestedMedicine.product?.delivered ? (
            <DeliveredProductDetails
              selectedProduct={suggestedMedicine.product}
              isInjection={isStringEqual(
                suggestedMedicine.Dosing_Type.name,
                "injection"
              )}
              medUsageUnits={medUsageUnits}
            />
          ) : (
            <ProductFields
              name={name}
              products={suggestedMedicineProducts || []}
              dosingType={suggestedMedicine.Dosing_Type.name}
              initialSelectedPharmacies={initialSelectedPharmacies}
              selectedPharmacies={selectedPharmacies}
              allowChangeBillingCycleUnit={allowChangeBillingCycleUnit}
            />
          )}
        </Box>
        {showInjectionBundle ? (
          <Box mt={4} className={classes.productWrapper}>
            <Box mb={2}>
              <Typography variant="subtitle2">Injection Bundle</Typography>
            </Box>

            {suggestedMedicine.product?.injectionBundle?.delivered ? (
              <DeliveredProductDetails
                selectedProduct={suggestedMedicine.product.injectionBundle}
                isInjectionBundle
                medUsageUnits={medUsageUnits}
              />
            ) : (
              <ProductFields
                name={getFieldName("injectionBundle", name)}
                products={suggestedMedicineInjectionProducts || []}
                dosingType={suggestedMedicine.Dosing_Type.name}
                isInjectionBundle
                initialSelectedPharmacies={initialSelectedPharmacies}
                selectedPharmacies={selectedPharmacies}
              />
            )}
          </Box>
        ) : null}
        {showBact ? (
          <Box mt={2} className={classes.productWrapper}>
            <Box mb={2}>
              <Typography variant="subtitle2">Bacteriostatic Water</Typography>
            </Box>
            {suggestedMedicine.product?.bacteriostatic?.delivered ? (
              <DeliveredProductDetails
                selectedProduct={suggestedMedicine.product.bacteriostatic}
                isBacteriostatic
                medUsageUnits={medUsageUnits}
              />
            ) : (
              <ProductFields
                name={getFieldName("bacteriostatic", name)}
                products={bacteriostaticProductsOptions}
                dosingType={suggestedMedicine.Dosing_Type.name}
                isBacteriostatic
                initialSelectedPharmacies={initialSelectedPharmacies}
                selectedPharmacies={selectedPharmacies}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
