import { makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";

const useStyle = makeStyles(({ spacing: s }) => ({
  wrapper: {
    marginBottom: s(4),
  },
  alert: {
    fontWeight: 500,
  },
}));

interface Props {
  isAllPendingKitShipping: boolean;
}

export const ConfirmKitPackingStatus = ({ isAllPendingKitShipping }: Props) => {
  const classes = useStyle();

  if (isAllPendingKitShipping) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Alert
        severity="error"
        icon={<Info fontSize="inherit" />}
        className={classes.alert}
      >
        Some of the selected blood test orders are not pending kit shipping. All
        selected blood test orders must be Pending Kit Shipping.
      </Alert>
    </div>
  );
};
