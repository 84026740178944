import * as Yup from "yup";
import React, { useState } from "react";
import { TextField } from "formik-material-ui";
import { Form, Field, Formik, FormikConfig } from "formik";
import {
  Box,
  Button,
  Dialog,
  Typography,
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import Loader from "components/Loader";
import { zohoServices } from "services";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { useUpdateBluePrint } from "hooks";
import { pathwayRequests } from "redux/pathway";
import { BP_CONFIRM_INVOICE_CREATION } from "configs";

const useStyles = makeStyles(({ palette: p, spacing: s, typography: t }) => ({
  title: {
    fontWeight: 500,
    color: p.primary.main,
    fontFamily: t.fontFamily,
  },
  content: {
    overflow: "unset",
    marginBottom: s(3),
  },
  text: {
    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
}));

interface ConfirmInvoiceFormFields {
  invoiceNumber: string;
}

const validationSchema = Yup.object<ConfirmInvoiceFormFields>().shape({
  invoiceNumber: Yup.string().trim().required("Invoice Number is Required"),
});

export const ConfirmInvoiceCreation = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { updateBluePrint } = useUpdateBluePrint();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [pathwayZohoId] = useSelector(zohoSelectors.getIds);

  const closeWidget = () => {
    (async () => {
      await zohoServices.closePopup(true);
    })();
  };

  const handleOkay = async () => {
    setLoading(true);
    await updateBluePrint(BP_CONFIRM_INVOICE_CREATION);
    closeWidget();
  };

  const handleFormSubmit: FormikConfig<ConfirmInvoiceFormFields>["onSubmit"] = async (
    values,
    { setSubmitting }
  ) => {
    try {
      await pathwayRequests.postConfirmBloodDrawInvoiceCreation(
        pathwayZohoId,
        values.invoiceNumber
      );
      setShowConfirmation(true);
    } catch {
      //
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<ConfirmInvoiceFormFields>
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
      initialValues={{ invoiceNumber: "" }}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <Form>
          <Dialog open fullWidth hideBackdrop>
            <Loader open={loading || isSubmitting} />

            <DialogTitle disableTypography>
              <Typography variant="h5" className={classes.title}>
                Confirm Invoice Creation
              </Typography>
            </DialogTitle>

            {!showConfirmation ? (
              <>
                <DialogContent className={classes.content}>
                  <Box width="50%">
                    <Field
                      required
                      name="invoiceNumber"
                      component={TextField}
                      label="Blood Drawer Invoice Number"
                    />
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      zohoServices.closePopup();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={!isValid}
                    onClick={submitForm}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent className={classes.content}>
                  <Box className={classes.text}>
                    <Typography>
                      Invoice creation confirmed successfully.
                    </Typography>
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button type="button" color="primary" onClick={handleOkay}>
                    Okay
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
