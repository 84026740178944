import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { activateScanner, deactivateScanner } from "helpers";
import React, { useCallback, useEffect } from "react";
import { BarcodeData } from "widgets/DispenseDrugs";
import { notifications } from "services";

interface ProductScannerProps {
  setScannedProduct: (product: BarcodeData) => void;
}

export const ProductScanner: React.FC<ProductScannerProps> = ({
  setScannedProduct,
}) => {
  const onScanSuccess = useCallback(
    (data: BarcodeData) => {
      try {
        setScannedProduct(data);
      } catch (error) {
        notifications.notifyError("Error processing scanned barcode");
      }
    },
    [setScannedProduct]
  );

  const onScanError = useCallback(() => {}, []);

  useEffect(() => {
    activateScanner(onScanSuccess, onScanError);

    return () => {
      deactivateScanner();
    };
  }, [onScanSuccess, onScanError]);

  return (
    <>
      <Box mb={2}>
        <Alert severity="info">
          Scanning an item will fill the batch number and expiry date
          automatically. Batch numbers and expiry dates can also be entered
          manually.
        </Alert>
      </Box>
    </>
  );
};
