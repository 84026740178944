import { Button, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getFinalizeTreatmentBillingCycleValidation,
  getIsSelectedMatchingProductsDefaultBillingCyclesFailed,
  getMatchingProductsSelectedValues,
  validateTreatmentForm,
} from "helpers";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { FinalizeMatchingProductMedicine, FinalizeWidgetValues } from "types";

interface Props {
  view: "fetch-error" | "form" | "success" | "initial-block";
  isNoCombination: boolean;
  isMatchingProducts: boolean;
  isMatchingPackages: boolean;
  matchingProducts: FinalizeMatchingProductMedicine[];
}

export const FinalizeTreatmentActions: React.FC<Props> = ({
  view,
  isNoCombination,
  isMatchingProducts,
  isMatchingPackages,
  matchingProducts,
}) => {
  const {
    values,
    submitForm,
    validateForm,
    setFieldTouched,
    setSubmitting,
  } = useFormikContext();

  const { handleClose } = useTreatmentWidgetLayout();

  const isSelectedMatchingProductsDefaultBillingCyclesFailed = useMemo(() => {
    if (!isMatchingProducts) {
      return false;
    }
    return getIsSelectedMatchingProductsDefaultBillingCyclesFailed(
      matchingProducts,
      getMatchingProductsSelectedValues(
        (values as FinalizeWidgetValues).matchingProducts
      )
    );
  }, [isMatchingProducts, matchingProducts, values]);

  const { selfSupplyFailed, isMultipleFailed } = useMemo(
    () =>
      getFinalizeTreatmentBillingCycleValidation(
        values,
        "",
        isNoCombination,
        isMatchingProducts,
        isMatchingPackages,
        isSelectedMatchingProductsDefaultBillingCyclesFailed,
        matchingProducts
      ),
    [
      values,
      isNoCombination,
      isMatchingProducts,
      isMatchingPackages,
      matchingProducts,
      isSelectedMatchingProductsDefaultBillingCyclesFailed,
    ]
  );

  const isValid = useMemo(() => {
    return !selfSupplyFailed && !isMultipleFailed;
  }, [selfSupplyFailed, isMultipleFailed]);

  const onClickCloseSuccess = useCallback(() => {
    handleClose(true);
  }, [handleClose]);

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const onFinalize = useCallback(async () => {
    try {
      setSubmitting(true);
      const errors = await validateForm();
      const isOk = validateTreatmentForm(errors, setFieldTouched);
      if (!isOk) {
        return;
      }
      await submitForm();
    } catch {
      //
    } finally {
      setSubmitting(false);
    }
  }, [submitForm, validateForm, setFieldTouched, setSubmitting]);

  return (
    <>
      {["fetch-error", "initial-block"].includes(view) ? (
        <Button variant="outlined" onClick={onClickClose} color="primary">
          CLOSE
        </Button>
      ) : null}
      {view === "form" ? (
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={onClickClose} color="primary">
              CLOSE
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={onFinalize}
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              FINALIZE
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {view === "success" ? (
        <Button
          onClick={onClickCloseSuccess}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      ) : null}
    </>
  );
};
