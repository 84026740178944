import { Button, Grid, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

const useStyle = makeStyles(({ spacing: s }) => ({
  alertWrapper: {
    marginBottom: s(4),
  },
  alert: {
    fontWeight: 500,
  },
  wrapper: {},
}));

interface Props {
  maxNumberOfRecordsAllowed?: number;
  numberOfRecordsSelected: number;
  onClose: () => void;
}

export const RecordsLimitExceeded = ({
  maxNumberOfRecordsAllowed = 200,
  numberOfRecordsSelected,
  onClose,
}: Props) => {
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <div className={classes.alertWrapper}>
        <Alert
          severity="error"
          icon={<Info fontSize="inherit" />}
          className={classes.alert}
        >
          <AlertTitle>Maximum number of selected records exceeded</AlertTitle>
          You selected {numberOfRecordsSelected} records. You can progress a
          maximum of {maxNumberOfRecordsAllowed} records at once. Please reduce
          the number of selected items to {maxNumberOfRecordsAllowed} or less.
        </Alert>
      </div>

      <Grid
        container
        spacing={2}
        justify="flex-end"
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <Button color="primary" onClick={onClose}>
            OKAY
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
