import React from "react";
import { EmailSenderManagementWidget } from "@deep-consulting-solutions/email-sender-management-mui-v4";

const getAPIUrl = () => {
  return `${process.env.REACT_APP_BASE_URL}`;
};

export const EmailSenderManagement = () => {
  return <EmailSenderManagementWidget apiUrl={getAPIUrl()} />;
};
