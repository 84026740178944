import React, { memo } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Card,
  IconButton,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { BloodTestResult } from "@deep-consulting-solutions/bmh-constants";

import ListItem from "./ListItem";

interface StyleProps {
  show: boolean;
  hasComparison: boolean;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: ({ show, hasComparison }: StyleProps) => {
    let right = "-200px";
    let opacity = 0;
    if (hasComparison) {
      right = show ? "20px" : "-160px";
      opacity = show ? 1 : 0.7;
    }

    return {
      position: "fixed",
      bottom: "20px",
      right,
      width: "200px",
      opacity,
      transition: "0.2s",
      "&:hover": {
        opacity: 1,
        cursor: show ? undefined : "pointer",
      },
    };
  },
  titleWrapper: {
    background: p.primary.main,
    padding: s(1),
    borderTopLeftRadius: s(1),
    borderTopRightRadius: s(1),
  },
  titleInner: ({ show }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    color: p.primary.contrastText,
    opacity: show ? 1 : 0,
    transition: "0.2s",
  }),
  dismissBtn: {
    color: p.error.main,
    marginLeft: "auto",
  },
  contentWrapper: {
    background: p.common.white,
    display: "flex",
    alignItems: "center",
  },
  showBtn: ({ show }: StyleProps) => ({
    paddingLeft: s(1),
    opacity: show ? 0 : 1,
    display: show ? "none" : undefined,
    transition: "0.2s",
  }),
  list: ({ show }: StyleProps) => ({
    flex: 1,
    opacity: show ? 1 : 0,
    transition: "0.2s",
  }),
}));

interface ComparisonNotiProps {
  comparisons: { [key: string]: BloodTestResult };
  removeFromComparisons: (id: string) => any;
  show: boolean;
  showComparisonNoti: () => any;
  onDismissNotiClick: () => any;
}

const ComparisonNoti: React.FC<ComparisonNotiProps> = ({
  comparisons,
  removeFromComparisons,
  show,
  showComparisonNoti,
  onDismissNotiClick,
}) => {
  const classes = useStyle({
    show,
    hasComparison: !!Object.keys(comparisons).length,
  });

  return (
    <Card className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <Box className={classes.titleInner}>
          <Typography variant="body2">Comparison List</Typography>
          <Button
            size="small"
            variant="text"
            className={classes.dismissBtn}
            onClick={onDismissNotiClick}
          >
            DISMISS
          </Button>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        <IconButton
          className={classes.showBtn}
          size="small"
          onClick={showComparisonNoti}
        >
          <ArrowBackIos />
        </IconButton>
        <Box className={classes.list}>
          {Object.values(comparisons).map((comp, index) => {
            return (
              <ListItem
                result={comp}
                index={index}
                removeComparison={removeFromComparisons}
              />
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

export default memo(ComparisonNoti);
