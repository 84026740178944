import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

import { ConvertedClientRecord } from "../../Unmatched.types";
import { ROW_HEIGHT, getBorder, getCaptionColor } from "../../Unmatched.styles";
import PossibleClientRow from "./PossibleClientRow";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  row: {
    height: `${ROW_HEIGHT}px`,
    display: "flex",
    alignItems: "center",
    borderTop: getBorder(p),
    paddingLeft: s(1),
    paddingRight: s(1),
  },
  selectWrapper: {
    height: `${ROW_HEIGHT * 3}px`,
    borderTop: getBorder(p),
    borderBottom: getBorder(p),
    overflow: "auto",
  },
  caption: {
    color: getCaptionColor(p),
    marginLeft: "auto",
  },
  nodata: {
    color: p.grey[500],
    textAlign: "center",
    marginTop: s(1),
  },
}));

interface PossibleClientsProps {
  clients: ConvertedClientRecord[];
  selectId: string | null;
  onSelect: (c: ConvertedClientRecord) => any;
  disableUpdate: boolean;
}

const PossibleClients: React.FC<PossibleClientsProps> = ({
  clients,
  selectId,
  onSelect,
  disableUpdate,
}) => {
  const classes = useStyle();

  return (
    <div data-testid="possible-clients">
      <Box className={classes.row}>
        <Typography variant="h6">Client Records ({clients.length})</Typography>
        <Typography variant="body2" className={classes.caption}>
          Select for data replacing
        </Typography>
      </Box>

      <Box className={classes.selectWrapper}>
        {!clients.length && (
          <Typography variant="body2" className={classes.nodata}>
            No matching client records are found!
          </Typography>
        )}
        {clients.map((c) => {
          return (
            <PossibleClientRow
              key={c.id}
              client={c}
              selected={selectId === c.id}
              onSelect={onSelect}
              disableUpdate={disableUpdate}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default memo(PossibleClients);
