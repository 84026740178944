import React, { DOMAttributes, memo, useCallback, useState } from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  Chip,
  colors,
  Theme,
  Button,
  TableContainer,
  Table,
  TableHead,
  Typography,
  Collapse,
  TableBody,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { DocumentStatusEnum, DocumentsType } from "../interface";
import { formatDocumentTime } from "../helpers";

const TABLE_ROW_COLOR = "#f7fafc";

interface StyleProps {
  DocumentState: DocumentStatusEnum;
  isOdd: boolean;
  DisplayInPortal: boolean;
  showSic: boolean;
}

const useStyle = makeStyles<Theme, StyleProps>(
  ({ palette: p, spacing: s }) => ({
    row: ({ isOdd }) => ({
      background: isOdd ? undefined : TABLE_ROW_COLOR,
    }),
    fileName: {
      cursor: "pointer",
      background: "none",
      border: "none",
      outline: "none",
      color: "blue",
      textAlign: "left",
      textDecoration: "underline",
    },
    chip: {
      background: ({ DocumentState }) =>
        [DocumentStatusEnum.Signed].includes(DocumentState)
          ? colors.green[200]
          : colors.blue[200],
    },
    displayInPortal: {
      color: ({ DisplayInPortal }) =>
        DisplayInPortal ? colors.green[500] : colors.red[500],
    },
    tableContainer: {
      flex: 1,
      padding: s(4),
    },
    text: {
      color: p.text.primary,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    sicIcon: {
      transformOrigin: "center",
      transform: ({ showSic }) => `rotate(${showSic ? 180 : 0}deg)`,
      transition: "all 0.1s",
    },
  })
);

const sicColumns = ["File Name", "Signer", "Ownership", "Status", "Added On"];

interface BodyRowProps {
  document: DocumentsType;
  isOdd: boolean;
  handleViewDocument: (documentId: string) => void;
}

export const BodyRow: React.FC<BodyRowProps> = ({
  document: {
    AddedOn,
    Signers,
    FileName,
    Type,
    SigningCompletionDate,
    DocumentState,
    S3Key,
    DisplayInPortal,
    SignerIdentityCertificates,
  },
  isOdd,
  handleViewDocument,
}) => {
  const [showSic, setShowSic] = useState(false);

  const handleClick: DOMAttributes<HTMLButtonElement>["onClick"] = useCallback(
    (e) => {
      e.preventDefault();
      handleViewDocument(S3Key);
    },
    [handleViewDocument, S3Key]
  );

  const handleClickSic: (
    s3Key: string
  ) => DOMAttributes<HTMLButtonElement>["onClick"] = useCallback(
    (s3Key: string) => (e) => {
      e.preventDefault();
      handleViewDocument(s3Key);
    },
    [handleViewDocument]
  );

  const handleShowSic = useCallback(() => {
    setShowSic((c) => !c);
  }, []);

  const classes = useStyle({ isOdd, DocumentState, DisplayInPortal, showSic });

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <span
            style={{
              width: "100%",
              display: "block",
              wordWrap: "break-word",
              inlineSize: "100%",
              overflowWrap: "break-word",
              wordBreak: "break-all",
              hyphens: "manual",
            }}
          >
            {S3Key ? (
              <button
                type="button"
                className={classes.fileName}
                onClick={handleClick}
              >
                {FileName}
              </button>
            ) : (
              FileName
            )}
          </span>
        </TableCell>
        <TableCell>{Type}</TableCell>
        <TableCell>{Signers}</TableCell>
        <TableCell>
          <Chip className={classes.chip} label={DocumentState} />
        </TableCell>
        <TableCell>{AddedOn ? formatDocumentTime(AddedOn) : "N/A"}</TableCell>
        <TableCell>
          {SigningCompletionDate
            ? formatDocumentTime(SigningCompletionDate)
            : "N/A"}
        </TableCell>
        <TableCell className={classes.displayInPortal}>
          {DisplayInPortal ? "Yes" : "No"}
        </TableCell>
        <TableCell>
          {SignerIdentityCertificates?.length ? (
            <Button onClick={handleShowSic} variant="text" color="primary">
              <KeyboardArrowDown className={classes.sicIcon} /> SIC
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
      {SignerIdentityCertificates?.length ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={showSic}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {sicColumns.map((label) => (
                        <TableCell key={label} className={classes.cellHeader}>
                          <Typography className={classes.text}>
                            {label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SignerIdentityCertificates.map(
                      (SignerIdentityCertificate, index) => (
                        <TableRow
                          style={{
                            background: !(index % 2)
                              ? undefined
                              : TABLE_ROW_COLOR,
                          }}
                        >
                          <TableCell>
                            {SignerIdentityCertificate.S3Key ? (
                              <button
                                type="button"
                                className={classes.fileName}
                                onClick={handleClickSic(
                                  SignerIdentityCertificate.S3Key
                                )}
                              >
                                {SignerIdentityCertificate.FileName}
                              </button>
                            ) : (
                              SignerIdentityCertificate.FileName
                            )}
                          </TableCell>
                          <TableCell>
                            {SignerIdentityCertificate.Signer}
                          </TableCell>
                          <TableCell>
                            {SignerIdentityCertificate.Ownership}
                          </TableCell>
                          <TableCell>
                            <Chip
                              style={{
                                background: [
                                  DocumentStatusEnum.Signed,
                                ].includes(
                                  SignerIdentityCertificate.DocumentState
                                )
                                  ? colors.green[200]
                                  : colors.blue[200],
                              }}
                              label={SignerIdentityCertificate.DocumentState}
                            />
                          </TableCell>
                          <TableCell>
                            {SignerIdentityCertificate.AddedOn
                              ? formatDocumentTime(
                                  SignerIdentityCertificate.AddedOn
                                )
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export const PureBodyRow = memo(BodyRow);
