import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useFormikContext } from "formik";
import { Box } from "@material-ui/core";
import { getTreatmentOrderItems } from "services/treatments/medication-disposal";
import { CustomInputField } from "components/CustomInputField";
import { InitialValues, FormattedTreatmentOrderItem } from "./types";
import {
  DEFAULT_ROWS_PER_PAGE,
  PENDING_MANIFEST_STATE,
  defaultColumns,
  defaultColumnsAlt,
} from "./constants";
import { OrderItemsDialog } from "./OrderItemsDialog";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    width: "100%",
    margin: s(2, 0),
  },
  pagination: {
    "& .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot": {
      width: "85px",
    },
  },
}));

export function BulkOrderManifestTable({ rowCount }: { rowCount: number }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orderItems, setOrderItems] = useState<FormattedTreatmentOrderItem[]>(
    []
  );

  const { values } = useFormikContext<InitialValues>();

  const closeDialog = useCallback(() => setIsOpen(false), []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = async (treatmentOrderId: string) => {
    try {
      setLoading(true);
      setIsOpen(true);
      const treatmentOrderItems = await getTreatmentOrderItems(
        treatmentOrderId
      );
      setOrderItems(treatmentOrderItems);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const areAllOrdersPendingManifest = useMemo(() => {
    return values.treatmentOrders.every(
      (t) => t.state === PENDING_MANIFEST_STATE
    );
  }, [values.treatmentOrders]);

  const cols = useMemo(
    () => (areAllOrdersPendingManifest ? defaultColumnsAlt : defaultColumns),
    [areAllOrdersPendingManifest]
  );

  return (
    <>
      <OrderItemsDialog
        closeDialog={closeDialog}
        loading={loading}
        isOpen={isOpen}
        orderItems={orderItems}
      />
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {cols.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.treatmentOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const trueIndex = page * rowsPerPage + index;
                  const isPendingManifest =
                    row.state === PENDING_MANIFEST_STATE;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      style={{
                        cursor: isPendingManifest ? "pointer" : "auto",
                        background: !isPendingManifest
                          ? "#FCEDEC"
                          : "transparent",
                      }}
                      onClick={
                        isPendingManifest
                          ? (e) => {
                              if (e.target instanceof HTMLInputElement) {
                                return;
                              }
                              handleRowClick(row.id);
                            }
                          : undefined
                      }
                    >
                      {cols.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id}>
                            {column.id === "courierManifestNumber" ? (
                              <Box width="180px">
                                <CustomInputField
                                  name={`treatmentOrders.${trueIndex}.courierManifestNumber`}
                                />
                              </Box>
                            ) : (
                              <>{value}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {rowCount > DEFAULT_ROWS_PER_PAGE && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
}
