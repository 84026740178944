import { useFormikContext } from "formik";
import { useEffect } from "react";

interface Props {
  savedDefaultValues: Record<string, any> | null;
}

export const SetInitialValues = ({ savedDefaultValues }: Props) => {
  const { setValues } = useFormikContext();

  useEffect(() => {
    if (savedDefaultValues) {
      setImmediate(() => {
        setValues(savedDefaultValues);
      });
    }
  }, [setValues, savedDefaultValues]);
  return null;
};
