import { Box } from "@material-ui/core";
import React from "react";
import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import { Instructions } from "./Instructions";
import { BloodDrawerOptions } from "./BloodDrawerOptions";
import { ZohoVariables } from "../types";

interface Props {
  labName?: string;
  bloodDrawers: BloodDrawer[];
  variables: Record<ZohoVariables, string>;
}

export const OfferingMobile = ({ labName, bloodDrawers, variables }: Props) => {
  return (
    <>
      <Box mt={2}>
        <Instructions
          labName={labName}
          bloodDrawers={bloodDrawers}
          variables={variables}
        />
      </Box>
      <Box mt={2}>
        <BloodDrawerOptions bloodDrawers={bloodDrawers} />
      </Box>
    </>
  );
};
