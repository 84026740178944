import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { useStyles } from "../styles";

interface Props {
  name?: string;
}

export const SpecifyCustomDosingInstructions = ({ name }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.fieldsSection}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
          <Field
            name={`${name}.specifyDosingInstructions`}
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.checkbox}
            Label={{
              label: (
                <Typography>Specify Custom Dosing Instructions</Typography>
              ),
            }}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
