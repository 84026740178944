import React from "react";
import { Box, Typography, Grid, Paper } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";

import { CountryField } from "components/Treatments/CountryField";
import { countries } from "configs";
import { CustomInputField } from "components/CustomInputField";
import { CustomCheckboxField } from "components/CustomCheckboxField";
import { CustomNumberField } from "components/CustomNumberField";
import { CustomTextareaField } from "components/CustomTextareaField";
import { InitialValues } from "./types";

export const CreateManualOrderForm = ({ country }: { country: string }) => {
  const { values }: { values: InitialValues } = useFormikContext();
  return (
    <>
      <Grid container spacing={3} style={{ width: "80%" }}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "#6B7280",
                fontWeight: "bold",
              }}
            >
              Patient Shipping Address
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <CountryField countries={countries} country={country} />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="province" label="Province" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="city" label="City" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="postalCode" label="Postal Code" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField name="street" label="Street" />
        </Grid>
        <Grid item sm={6}>
          <CustomInputField
            name="apartment"
            label="Apartment / Flat / Floor / Other"
            required={false}
          />
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "#6B7280",
                fontWeight: "bold",
              }}
            >
              Order Items
            </Typography>
          </Box>
        </Grid>
        <FieldArray name="orderItems">
          {() => (
            <>
              {values.orderItems.length > 0 &&
                values.orderItems.map((orderItem, index) => (
                  <Paper
                    key={orderItem.id}
                    variant="outlined"
                    style={{
                      margin: "8px auto",
                      width: "100%",
                      padding: "16px",
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={3}>
                      <Grid item sm={1}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="start"
                        >
                          <CustomCheckboxField
                            name={`orderItems.${index}.isSelected`}
                            disabled={orderItem.remainingQty === 0}
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={3}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="start"
                        >
                          <Typography
                            component="h3"
                            style={{
                              fontWeight: "bold",
                              color: "#6B7280",
                            }}
                          >
                            Item
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            {orderItem.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={3}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            color="text.secondary"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "bold",
                                color:
                                  orderItem.remainingQty === 0
                                    ? "crimson"
                                    : "#6B7280",
                              }}
                            >
                              Prescription Remaining Qty
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              textAlign: "center",
                              color:
                                orderItem.remainingQty === 0
                                  ? "crimson"
                                  : "#6B7280",
                            }}
                          >
                            {orderItem.remainingQty}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={2}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            color="text.secondary"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                textAlign: "center",
                                color: "#6B7280",
                                fontWeight: "bold",
                              }}
                            >
                              Quantity *
                            </Typography>
                          </Box>
                          <Grid item>
                            <CustomNumberField
                              name={`orderItems.${index}.quantity`}
                              disabled={orderItem.remainingQty === 0}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item sm={3}>
                        <Box>
                          <Box mb={1}>
                            <Typography
                              variant="body1"
                              style={{
                                textAlign: "center",
                                color: "#6B7280",
                                fontWeight: "bold",
                                width: "100%",
                              }}
                            >
                              Usage Notes *
                            </Typography>
                          </Box>
                          <Grid item>
                            <CustomTextareaField
                              name={`orderItems.${index}.notes`}
                              disabled={orderItem.remainingQty === 0}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </>
          )}
        </FieldArray>
      </Box>
    </>
  );
};
