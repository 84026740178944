import { Tooltip, Typography } from "@material-ui/core";
import { FormikNumberField } from "components/FormikNumberField";
import { Field } from "formik";
import React, { useCallback, useState } from "react";

interface Props {
  name: string;
}

export const CustomUnitPriceField = ({ name }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Tooltip
      open={isFocused}
      placement="top"
      title={
        <Typography variant="caption">
          Custom unit price will be saved in the item and it will override the
          current unit price of the product.
        </Typography>
      }
    >
      <div
        onFocus={handleFocus}
        onMouseOver={handleFocus}
        onMouseOut={handleBlur}
        onBlur={handleBlur}
      >
        <Field
          component={FormikNumberField}
          name={name}
          label="Custom Unit Price (£)"
          type="integer"
          size="small"
          fullWidth
          min={0.01}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
        />
      </div>
    </Tooltip>
  );
};
