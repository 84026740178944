/* eslint-disable react/no-array-index-key */
import { omit } from "lodash";
import clsx from "clsx";
import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  makeStyles,
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  Grid,
  TextField as MuiTextField,
  FormHelperText,
} from "@material-ui/core";
import {
  AttachFile as AttachFileIcon,
  QueryBuilder as ClockIcon,
  PictureAsPdf as PDFIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { Form, Formik, FormikConfig, Field, FieldArray, getIn } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { KeyboardTimePicker } from "formik-material-ui-pickers";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import {
  BloodTestResult,
  TestFailureCode,
  TestAbnormalFlag,
  Pathway,
  Lab,
  TestObxCodes,
  PathwayRes,
  BloodTestResultWithPathway,
  ManualResult,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";

import { notifications } from "services";
import { getENText } from "helpers";
import { testResultsRequests } from "redux/testResults";

import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@material-ui/lab";
import {
  LabOrder,
  mapFullObxCodes,
  mapRelatedPathwaysByLab,
  truncateFileName,
} from "../TestResults.helpers";
import { ViewWrapperBox } from "../TestResults.styles";
import { useLoadingContext } from "../LoadingContext";
import OpenPDFBtn from "../OpenPDFBtn";
import {
  getValidationSchema,
  composeBloodTestResultName,
  composeManualRequest,
  composePathwayManualRequest,
  getInitialValues,
} from "./ResultForm.helpers";

import {
  FormValues,
  PathwayFormValues,
  FormResultItem,
  FormRefProps,
} from "./ResultForm.types";

import { InfoGroup } from "./ResultForm.styles";
import FieldWithTitle from "./FieldWithTitle";
import TestRow from "./TestRow";
import AddBtn from "./AddBtn";
import { MapObxCode } from "../TestResults.types";

const useStyle = makeStyles(({ spacing: s, palette: p, typography: typo }) => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    padding: s(1),
    borderBottom: `1px solid ${p.grey[300]}`,
  },
  topActions: {
    marginLeft: "auto",
  },
  cancelBtn: {
    marginRight: s(1),
  },
  infoGroupsWrapper: {
    flex: 1,
    position: "relative",
  },
  infoGroupsInner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: "auto",
    padding: s(1),
  },
  infoGroup: {},
  groupTitle: {
    color: p.primary.light,
  },
  groupContent: {
    paddingTop: s(1),
    paddingBottom: s(1),
  },
  groupRow: {
    display: "flex",
    paddingTop: s(1),
    paddingBottom: s(1),
  },
  pdfWarpper: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    maxWidth: "400px",
  },
  currentPdf: {
    marginBottom: s(1),
    whiteSpace: "pre-wrap",
    textAlign: "right",
  },
  pdf: {
    display: "flex",
    alignItems: "center",
  },
  attachBtn: {
    ...typo.body2,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  attachIcon: {
    marginRight: s(1),
  },
  nameField: {
    width: "100%",
  },
  labNameField: {
    width: 300,
    fontSize: typo.body1.fontSize,
    marginRight: s(3),
    display: "flex",
    flexDirection: "column",
  },
  labNameFieldSmall: {
    width: 200,
  },
  testResultName: {
    display: "inline",
    "&:not(:last-child)": {
      marginRight: s(1),
    },
  },
  dateField: {
    width: "230px",
    marginRight: s(3),
    "&:last-child": {
      marginRight: 0,
    },
  },
  testResultWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  testResultField: {
    width: "150px",
  },
  testResultSmallField: {
    width: "100px",
  },
  testResultBigField: {
    width: "200px",
  },
  pdfIcon: {
    color: `rgba(97, 7, 7, 0.54)`,
    marginRight: s(1),
  },
  pdfName: {
    marginRight: s(0.5),
  },
  pdfCloseBtn: {
    color: p.error.light,
  },
}));
interface ResultFormProps {
  zohoId: string;
  closeResultForm: (newResult?: BloodTestResult) => any;
  targetResult: BloodTestResultWithPathway | null;
  abnormalFlags: TestAbnormalFlag[];
  failureCodes: TestFailureCode[];
  isPathway?: boolean;
  pathwayRecord?: Pathway;
  obxUnits: string[];
  labs: Lab[];
  labsOrder: LabOrder[];
  unmappedObxCodesBmhNames: TestObxCodes[];
  observationIDs: string[];
  relatedPathways?: PathwayRes[];
  refetchRelatedBloodTestOrder: () => Promise<any>;
}

const isUsingAbnormalFlag = (
  abnormal?: string | PathwayFormValues["results"][0]["abnormalFlag"]
) => {
  if (!abnormal) return false;
  if (typeof abnormal === "string") return !!abnormal;
  return !!abnormal.id;
};

const ResultForm: React.FC<ResultFormProps> = ({
  zohoId,
  closeResultForm,
  targetResult,
  observationIDs,
  obxUnits,
  abnormalFlags,
  failureCodes,
  isPathway,
  pathwayRecord,
  labs,
  labsOrder,
  unmappedObxCodesBmhNames,
  relatedPathways,
  refetchRelatedBloodTestOrder,
}) => {
  const filteredRelatedPathways = useMemo(() => {
    if (!relatedPathways) return undefined;
    return relatedPathways.filter((p) => {
      if (
        targetResult &&
        targetResult.relatedBloodTestOrder &&
        targetResult.relatedBloodTestOrder.id === p.id
      ) {
        return true;
      }
      return !p.bloodTestResults.length;
    });
  }, [relatedPathways, targetResult]);
  const relatedPathwaysByLab = useMemo(
    () => mapRelatedPathwaysByLab(filteredRelatedPathways),
    [filteredRelatedPathways]
  );
  const abnormalMap = useMemo(() => {
    return abnormalFlags.reduce(
      (map: { [id: string]: TestAbnormalFlag }, a) => ({
        ...map,
        [a.id]: a,
      }),
      {}
    );
  }, [abnormalFlags]);

  const { setLoading } = useLoadingContext();
  const formRef = useRef<FormRefProps>(null);
  const oldAutoGeneratedName = useRef<string>("");

  const [file, setFile] = useState<File | null>(null);

  const labId = getIn(formRef.current?.values, "labId");
  const selectedLab = useMemo(() => {
    if (!labId) return undefined;
    return labs.find((l) => l.id === labId);
  }, [labId, labs]);
  const obxCodesBmhNames = useMemo(() => {
    return mapFullObxCodes(
      unmappedObxCodesBmhNames,
      labsOrder,
      labs,
      selectedLab
    );
  }, [labsOrder, selectedLab, unmappedObxCodesBmhNames, labs]);
  const mappedObxCodesBmhNames = useMemo(() => {
    const m: { [bmhName: string]: MapObxCode } = {};
    obxCodesBmhNames.forEach((c) => {
      m[c.bmhName] = c;
    });
    return m;
  }, [obxCodesBmhNames]);

  const failureMap = useMemo(() => {
    return failureCodes.reduce(
      (map: { [failureCode: string]: TestFailureCode }, f) => ({
        ...map,
        [f.failureCode.toLowerCase()]: f,
      }),
      {}
    );
  }, [failureCodes]);
  const updateName = useCallback(
    (
      isNameTouched: boolean,
      values: FormValues,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) => {
      const { labName } = values;
      if (
        isPathway ||
        !labName ||
        !values.collectedDate ||
        // eslint-disable-next-line no-restricted-globals
        isNaN(values.collectedDate as any)
      )
        return;
      if (
        values.lab &&
        values.relatedPathwaysByLab &&
        values.relatedPathwaysByLab[values.lab.id]
      ) {
        if (!values.relatedPathway) return;
      }
      const newName = composeBloodTestResultName(
        values.collectedDate,
        labName,
        values.relatedPathwaysByLab &&
          values.lab &&
          values.relatedPathwaysByLab[values.lab.id]
          ? values.relatedPathway
          : undefined
      );
      if (!isNameTouched || values.name === oldAutoGeneratedName.current) {
        setFieldValue("name", newName, true);
      }
      oldAutoGeneratedName.current = newName;
    },
    [isPathway]
  );

  const fileName = useMemo(() => {
    if (!file) {
      return {
        truncated: "",
        isTruncated: false,
      };
    }
    return truncateFileName(file.name);
  }, [file]);

  const onFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length) {
      setFile(files[0]);
      notifications.notifySuccess(getENText("results.manualAdd.pdf.attached"));
    } else {
      notifications.notifyError(getENText("results.manualAdd.pdf.notAttached"));
    }
  }, []);

  const onFileRemove = useCallback(() => {
    setFile(null);
  }, []);

  const onSaveClick = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  const onFormSubmit: FormikConfig<
    FormValues | PathwayFormValues
  >["onSubmit"] = useCallback(
    async (values) => {
      setLoading(true);
      let s3Key = "";
      try {
        if (file) {
          const s3Data = await testResultsRequests.getS3SignedUrl(file);
          await testResultsRequests.uploadFileToS3(file, s3Data.url);
          s3Key = s3Data.key;
        }

        let newResult: BloodTestResult;

        const results: FormResultItem[] = [];
        values.results.forEach((result: FormResultItem) => {
          const cloned = { ...result };
          delete (cloned as any).disableIsFailed;
          results.push(cloned);
        });

        const calculatedValues: FormValues | PathwayFormValues = {
          ...values,
          results,
        };

        const data = isPathway
          ? omit(
              composePathwayManualRequest(
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                calculatedValues as PathwayFormValues
              ),
              "name"
            )
          : composeManualRequest(
              calculatedValues as FormValues,
              s3Key,
              relatedPathwaysByLab
            );

        if (targetResult) {
          newResult = await testResultsRequests.updateResult({
            zohoId,
            target: targetResult,
            data,
          });
          notifications.notifySuccess(getENText("results.manualAdd.updated"));
        } else {
          newResult = await testResultsRequests.createNewResult({
            zohoId,
            data,
          });
          const isEmailSent = isPathway
            ? true
            : !!(data as ManualResult).relatedBloodTestOrderId;
          notifications.notifySuccess(
            getENText(
              isEmailSent
                ? "results.manualAdd.added"
                : "results.manualAdd.added.notify"
            )
          );
        }

        if (!isPathway) await refetchRelatedBloodTestOrder();
        closeResultForm(newResult);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [
      file,
      setLoading,
      closeResultForm,
      targetResult,
      zohoId,
      isPathway,
      relatedPathwaysByLab,
      refetchRelatedBloodTestOrder,
    ]
  );

  const onCancelClick = useCallback(() => {
    closeResultForm();
  }, [closeResultForm]);

  const onFailureFound = useCallback(
    (index: number, failure?: TestFailureCode) => {
      if (failure) {
        formRef.current?.setFieldValue(`results.${index}.isFailed`, true);
        formRef.current?.setFieldValue(
          `results.${index}.disableIsFailed`,
          true
        );
      } else {
        formRef.current?.setFieldValue(
          `results.${index}.disableIsFailed`,
          false
        );

        if (!formRef.current?.touched.results?.[index]?.isFailed) {
          formRef.current?.setFieldValue(`results.${index}.isFailed`, false);
        }
      }
    },
    []
  );

  const initialValues = useMemo(() => {
    return getInitialValues(
      targetResult,
      labs,
      failureMap,
      abnormalMap,
      mappedObxCodesBmhNames,
      relatedPathwaysByLab,
      isPathway,
      pathwayRecord
    );
  }, [
    targetResult,
    failureMap,
    abnormalMap,
    isPathway,
    pathwayRecord,
    relatedPathwaysByLab,
    mappedObxCodesBmhNames,
    labs,
  ]);

  const validationSchema = useMemo(
    () =>
      getValidationSchema(
        oldAutoGeneratedName.current,
        !!targetResult,
        isPathway
      ),
    [isPathway, targetResult]
  );

  const shouldDisableFields = useMemo(() => {
    if (isPathway) return true;
    if (targetResult && targetResult.relatedBloodTestOrder) return true;
    return false;
  }, [targetResult, isPathway]);

  const classes = useStyle();

  useEffect(() => {
    if (targetResult) {
      if (
        targetResult.name ===
        composeBloodTestResultName(
          new Date(targetResult.sampleCollectedOn),
          targetResult.labName
        )
      ) {
        oldAutoGeneratedName.current = targetResult.name;
      }
    }
  }, [targetResult]);

  return (
    <Formik<FormValues | PathwayFormValues>
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {(props) => {
        const {
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        } = props;
        const { lab } = values;
        const needRelatedPathway = !!(
          relatedPathwaysByLab &&
          lab &&
          relatedPathwaysByLab[lab.id]
        );

        const foundRelatedPathways =
          (!isPathway &&
            relatedPathwaysByLab &&
            lab &&
            relatedPathwaysByLab[lab.id]) ||
          undefined;

        const showRelatedPathwayField = !!(
          !targetResult && foundRelatedPathways
        );
        const getDisableName = () => {
          if (values.lab === null && values.labName) {
            return false; // User typed a lab that does not exist
          }

          if (targetResult?.relatedBloodTestOrder) return true;

          /**
           * Calculate based on previous value and selected lab from known labs
           */
          return (
            (!values.lab ||
              !values.collectedDate ||
              !!(
                relatedPathwaysByLab && relatedPathwaysByLab[values.lab.id]
              )) &&
            values.name === oldAutoGeneratedName.current
          );
        };

        return (
          <ViewWrapperBox>
            <Box className={classes.topBar}>
              <Typography variant="subtitle2">
                {targetResult ? "Edit Test Result" : "Add New Test Result"}
              </Typography>
              <Box className={classes.topActions}>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.cancelBtn}
                  onClick={onCancelClick}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSaveClick}
                >
                  SAVE
                </Button>
              </Box>
            </Box>
            <Box className={classes.infoGroupsWrapper}>
              <Box className={classes.infoGroupsInner}>
                <Form>
                  {isPathway && targetResult && (
                    <InfoGroup title="Manual results addition details">
                      <Box className={classes.groupRow} alignItems="center">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classes.testResultName}
                        >
                          Test Result Name:
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.testResultName}
                        >
                          {targetResult.name}
                        </Typography>
                      </Box>
                    </InfoGroup>
                  )}
                  <InfoGroup title="Laboratory information">
                    <Box className={classes.groupRow}>
                      <div
                        className={clsx(
                          classes.labNameField,
                          showRelatedPathwayField && classes.labNameFieldSmall
                        )}
                      >
                        <Typography variant="caption" style={{ opacity: 0 }}>
                          No Title
                        </Typography>
                        <Field
                          required
                          name="labName"
                          component={Autocomplete}
                          freeSolo
                          options={labs}
                          getOptionLabel={(option?: Lab) => option?.name || ""}
                          onChange={(
                            _: any,
                            value: Lab,
                            reason: AutocompleteChangeReason
                          ) => {
                            if (reason === "select-option" && value) {
                              setFieldValue("lab", value);
                              if (
                                relatedPathwaysByLab &&
                                relatedPathwaysByLab[value.id]
                              ) {
                                setFieldValue("showInPortal", true);
                              }
                              setFieldValue("labName", value.name);
                            }
                            if (reason === "clear") {
                              setFieldValue("lab", null);
                              setFieldValue("labName", "");
                            }
                          }}
                          inputValue={values.labName || ""}
                          onInputChange={(
                            _: any,
                            value: string,
                            reason: AutocompleteInputChangeReason
                          ) => {
                            if (reason === "input") {
                              setFieldValue("labName", value);
                              setFieldValue("lab", null);
                            }
                          }}
                          onBlur={() => {
                            setFieldTouched("labName", true);
                            updateName(
                              touched.name || false,
                              values as any,
                              setFieldValue
                            );
                          }}
                          disabled={shouldDisableFields}
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) => (
                            <MuiTextField
                              {...params}
                              required
                              name="labName"
                              variant="outlined"
                              label="Laboratory Name"
                              helperText={touched.labName && errors.labName}
                              error={touched.labName && !!errors.labName}
                            />
                          )}
                        />
                      </div>

                      {!isPathway && (
                        <>
                          {targetResult?.relatedBloodTestOrder && (
                            <div className={clsx(classes.labNameField)}>
                              <Typography
                                variant="caption"
                                style={{ opacity: 0 }}
                              >
                                no title
                              </Typography>
                              <MuiTextField
                                name="relatedPathway"
                                label="Related Blood Test Order"
                                value={
                                  (targetResult.relatedBloodTestOrder as any)
                                    .sampleID
                                }
                                required
                                disabled
                              />
                              <FormHelperText>
                                Client&apos;s {values.lab?.name} orders Pending
                                Manual Results
                              </FormHelperText>
                            </div>
                          )}
                          {showRelatedPathwayField && (
                            <div className={clsx(classes.labNameField)}>
                              <Typography
                                variant="caption"
                                style={{ opacity: 0 }}
                              >
                                no title
                              </Typography>
                              <Field
                                required
                                name="relatedPathway"
                                component={Autocomplete}
                                options={foundRelatedPathways || []}
                                getOptionLabel={(option: PathwayRes) => {
                                  return (option as any)?.sampleID || "";
                                }}
                                onBlur={() => {
                                  setFieldTouched("relatedPathway", true);
                                  updateName(
                                    touched.name || false,
                                    values as any,
                                    setFieldValue
                                  );
                                }}
                                renderInput={(
                                  params: AutocompleteRenderInputParams
                                ) => (
                                  <MuiTextField
                                    {...params}
                                    name="relatedPathway"
                                    label="Related Blood Test Order"
                                    helperText={
                                      (touched as any).relatedPathway &&
                                      (errors as any).relatedPathway
                                    }
                                    required
                                    error={
                                      (touched as any).relatedPathway &&
                                      !!(errors as any).relatedPathway
                                    }
                                  />
                                )}
                              />
                              <FormHelperText>
                                Client&apos;s {values.lab?.name} orders Pending
                                Manual Results
                              </FormHelperText>
                            </div>
                          )}
                        </>
                      )}
                      <FieldWithTitle
                        title="Sample collected*"
                        className={classes.dateField}
                        name="collectedDate"
                        component={FormikDatePicker}
                        placeholder="dd/mm/yyyy"
                        required
                        isDate
                        useNormalField
                        fieldProps={{
                          onChange: (d: Date) => {
                            setFieldValue("collectedDate", d);
                            updateName(
                              touched.name || false,
                              {
                                ...values,
                                collectedDate: d,
                              } as any,
                              setFieldValue
                            );
                          },
                        }}
                      />

                      <FieldWithTitle
                        title="Sample received"
                        className={classes.dateField}
                        name="receivedDate"
                        component={FormikDatePicker}
                        placeholder="dd/mm/yyyy"
                        isDate
                      />

                      <FieldWithTitle
                        title="Results reported*"
                        className={classes.dateField}
                        name="reportedDate"
                        component={FormikDatePicker}
                        placeholder="dd/mm/yyyy"
                        required
                        isDate
                      />
                    </Box>

                    {(!!values.collectedDate ||
                      !!values.receivedDate ||
                      !!values.reportedDate) && (
                      <Box className={classes.groupRow}>
                        <div
                          className={clsx(
                            classes.labNameField,
                            showRelatedPathwayField && classes.labNameFieldSmall
                          )}
                        />
                        {!isPathway && (
                          <>
                            {targetResult?.relatedBloodTestOrder && (
                              <div className={clsx(classes.labNameField)} />
                            )}
                            {showRelatedPathwayField &&
                              foundRelatedPathways && (
                                <div className={classes.labNameField} />
                              )}
                          </>
                        )}
                        <Field
                          name="collectedTime"
                          component={KeyboardTimePicker}
                          className={classes.dateField}
                          keyboardIcon={<ClockIcon />}
                          placeholder="hh:mm"
                          ampm={false}
                          style={{
                            visibility: values.collectedDate
                              ? "visible"
                              : "hidden",
                          }}
                        />
                        <Field
                          name="receivedTime"
                          component={KeyboardTimePicker}
                          className={classes.dateField}
                          keyboardIcon={<ClockIcon />}
                          placeholder="hh:mm"
                          ampm={false}
                          style={{
                            visibility: values.receivedDate
                              ? "visible"
                              : "hidden",
                          }}
                        />
                        <Field
                          name="reportedTime"
                          component={KeyboardTimePicker}
                          className={classes.dateField}
                          keyboardIcon={<ClockIcon />}
                          placeholder="hh:mm"
                          ampm={false}
                          style={{
                            visibility: values.reportedDate
                              ? "visible"
                              : "hidden",
                          }}
                        />
                      </Box>
                    )}
                  </InfoGroup>

                  {!isPathway && (
                    <InfoGroup title="Manual results addition details">
                      <Box className={classes.groupRow} alignItems="center">
                        <Grid container alignItems="center">
                          <Grid item xs={9}>
                            <Box>
                              <Field
                                name="name"
                                component={TextField}
                                label="Test Result Name"
                                placeholder="Client’s provided results"
                                required
                                className={classes.nameField}
                                disabled={getDisableName()}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Field
                              name="showInPortal"
                              component={CheckboxWithLabel}
                              Label={{
                                label: (
                                  <Typography variant="body2">
                                    Display result in Client Portal
                                  </Typography>
                                ),
                              }}
                              type="checkbox"
                              id="test-results-form-showInPortal"
                              color="primary"
                              disabled={!!needRelatedPathway}
                            />
                          </Grid>
                        </Grid>
                        <Box className={classes.pdfWarpper}>
                          {targetResult && targetResult.s3Key && (
                            <OpenPDFBtn
                              id={targetResult.id}
                              pdfName={targetResult.s3Key}
                              className={classes.currentPdf}
                            />
                          )}
                          {!needRelatedPathway && (
                            <Box className={classes.pdf}>
                              {!file && (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.attachBtn}
                                  component="label"
                                >
                                  <AttachFileIcon
                                    className={classes.attachIcon}
                                  />
                                  ATTACH PDF
                                  <input
                                    type="file"
                                    accept="application/pdf"
                                    hidden
                                    onChange={onFileUpload}
                                  />
                                </Button>
                              )}
                              {!!file && (
                                <>
                                  <PDFIcon className={classes.pdfIcon} />
                                  <Tooltip
                                    title={
                                      fileName.isTruncated ? file.name : ""
                                    }
                                  >
                                    <Typography
                                      variant="body2"
                                      color="primary"
                                      className={classes.pdfName}
                                    >
                                      {fileName.truncated}
                                    </Typography>
                                  </Tooltip>
                                  <IconButton
                                    size="small"
                                    className={classes.pdfCloseBtn}
                                    onClick={onFileRemove}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </InfoGroup>
                  )}

                  <InfoGroup title="Test results">
                    <FieldArray
                      name="results"
                      render={({ push, remove }) => {
                        return (
                          <div>
                            {values.results.map((v, index, arr) => {
                              return (
                                <TestRow
                                  key={index}
                                  index={index}
                                  formikProps={props}
                                  obxCodesBmhNames={obxCodesBmhNames}
                                  observationIDs={observationIDs}
                                  onMinusClick={
                                    index === 0 && arr.length === 1
                                      ? undefined
                                      : remove
                                  }
                                  abnormalFlags={abnormalFlags}
                                  failureMap={failureMap}
                                  onFailureFound={onFailureFound}
                                  disableAbnormalFlags={v.isFailed}
                                  disableIsFailed={
                                    isUsingAbnormalFlag(v.abnormalFlag) ||
                                    v.disableIsFailed
                                  }
                                  obxUnits={obxUnits}
                                />
                              );
                            })}
                            <AddBtn push={push} isPathway={isPathway} />
                          </div>
                        );
                      }}
                    />
                  </InfoGroup>
                </Form>
              </Box>
            </Box>
          </ViewWrapperBox>
        );
      }}
    </Formik>
  );
};

export default ResultForm;
