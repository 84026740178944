import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import React, { useCallback, useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
  },
}));

interface Props {
  data: string;
  height: number;
  scale?: number;
}

export const PDFViewer: React.FC<Props> = ({ data, height, scale = 1 }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  const onDocumentLoadSuccess = useCallback(({ numPages: num }) => {
    setNumPages(num);
  }, []);

  const onGoToNextPage = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, []);

  const onGoToPreviousPage = useCallback(() => {
    setPageNumber((prev) => prev - 1);
  }, []);

  return (
    <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
      <Page
        scale={scale}
        className={classes.page}
        height={height}
        pageNumber={pageNumber}
      />
      <Box mt={1}>
        <Grid justify="space-between" alignItems="center" container>
          <Grid item>
            <IconButton
              disabled={pageNumber === 1}
              onClick={onGoToPreviousPage}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              Page {pageNumber} of {numPages}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={pageNumber === numPages}
              onClick={onGoToNextPage}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Document>
  );
};
