/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogProps,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline, Close as CloseIcon } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { add, endOfDay, format, isAfter, isPast } from "date-fns";
import {
  Interval,
  McmAvailabilityWithSpecial,
  McmSpecialAvailability,
} from "@deep-consulting-solutions/bmh-constants";
import { DATE_REQUEST_FORMAT, DATE_SPECIAL_DISPLAY_FORMAT } from "configs";
import { v4 as uuidv4 } from "uuid";
import { StyledIconButton } from "components/StyledIconButton";
import { isEqual } from "lodash";
import { makeIntervalsByDay } from "./helpers";
import { IntervalItem } from "./IntervalItem";

const isDateInThePast = (date?: Date | null) => {
  return date ? isPast(endOfDay(date)) : true;
};

const isDateAfterThreeMonths = (date?: Date | null) => {
  return date ? isAfter(date, add(new Date(), { days: 90 })) : true;
};

const isEqualDates = (
  dateL: Date | string | null,
  dateR: Date | string | null
) => {
  if (!dateL || !dateR) {
    return false;
  }
  return (
    format(new Date(dateL), DATE_REQUEST_FORMAT) ===
    format(new Date(dateR), DATE_REQUEST_FORMAT)
  );
};

const useStyles = makeStyles(({ spacing: s, palette: p }) =>
  createStyles({
    content: {
      padding: s(3, 2),
      display: "flex",
      flexWrap: "wrap",
      "@media only screen and (max-width: 820px)": {
        padding: s(3, 0, 0),
      },
    },
    contentLeft: {
      flex: 1,
      "@media only screen and (min-width: 800px)": {
        borderRight: `1px solid ${p.divider}`,
      },
      padding: s(2),
      "@media only screen and (max-width: 799px)": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > :first-child": {
          alignSelf: "flex-start",
        },
      },
    },
    contentRight: {
      display: "flex",
      flexDirection: "column",
      padding: s(2),
      width: "360px",
      minWidth: "360px",
      "@media only screen and (max-width: 799px)": {
        width: "100%",
      },
    },
    closeBtn: {
      position: "absolute",
      right: s(1),
      top: s(1),
    },
    btnBox: {
      display: "flex",
      alignItems: "center",
      "& >*:not(:first-child)": {
        marginLeft: s(1),
      },
    },
    actions: {
      paddingTop: s(2),
      justifyContent: "flex-end",
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    calendarContainer: {
      margin: s(3, 0),
      width: "fit-content",
    },
    circle: {
      height: s(3),
      width: s(3),
      minWidth: s(3),
      borderRadius: s(1.5),
    },
    regular: {
      backgroundColor: "rgb(243, 242, 242)",
    },
    special: {
      border: `2px solid rgb(123, 198, 126)`,
    },
    legendItem: {
      marginBottom: s(2),
    },
    dateBtnContainer: {
      width: 36,
      height: 36,
    },
    dateBtn: {
      width: 32,
      height: 32,
      fontSize: "12px",
      margin: "0 2px",
      color: "inherit",
      padding: s(0.5),
    },
    selected: {
      backgroundColor: "rgba(11, 190, 227, 0.2)",
      color: p.secondary.dark,
      "&:hover": {
        backgroundColor: "rgba(11, 190, 227, 0.3)",
      },
    },
    selectedWithSpecialInterval: {
      border: `2px solid rgb(123, 198, 126)`,
      background: `rgba(123, 198, 126, 0.8)`,
      color: p.common.white,
      "&:hover": {
        background: `rgba(123, 198, 126, 1)`,
      },
    },
    notInMonth: {
      opacity: 0,
      pointerEvents: "none",
    },
    itemsContainer: {
      "& >*:not(:last-child)": {
        paddingBottom: s(2),
      },
    },
  })
);

interface SpecialAvailabilityDialogProps extends DialogProps {
  onClose: () => any;
  mcm: McmAvailabilityWithSpecial;
  onSaveSpecialAvailability: (
    mcmId: string,
    specialAvailability: Omit<McmSpecialAvailability, "id">
  ) => Promise<boolean>;
}

export const SpecialAvailabilityDialog = ({
  open,
  onClose,
  mcm,
  onSaveSpecialAvailability,
  ...dialogProps
}: SpecialAvailabilityDialogProps) => {
  const classes = useStyles();
  const daysMap = useMemo(() => makeIntervalsByDay(mcm.days), [mcm.days]);
  const initialSpecialMap = useMemo(
    () =>
      mcm.specialAvailabilities.reduce((m, c) => {
        m[c.date] = c.intervals;
        return m;
      }, {} as { [key: string]: Interval[] }),
    [mcm.specialAvailabilities]
  );

  const [specialMap, setSpecialMap] = useState(initialSpecialMap);

  useEffect(() => {
    setSpecialMap(initialSpecialMap);
  }, [initialSpecialMap]);

  const [editableIntervals, setEditableIntervals] = useState<{
    [key: string]: boolean;
  }>({});
  const setIntervalEditing = useCallback((id: string) => {
    setEditableIntervals((prev) => ({
      ...prev,
      [id]: true,
    }));
  }, []);

  const onCancelClick = useCallback(() => {
    setSpecialMap(initialSpecialMap);
    setEditableIntervals({});
  }, [initialSpecialMap]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const selectedDateString = useMemo(
    () => (selectedDate ? format(selectedDate, DATE_REQUEST_FORMAT) : null),
    [selectedDate]
  );

  const handleDateChange = useCallback(
    (date: Date | null) => {
      onCancelClick();
      setSelectedDate(date);
    },
    [onCancelClick]
  );

  const selectedDateIntervals = useMemo(
    () => (selectedDateString ? specialMap[selectedDateString] || [] : []),
    [selectedDateString, specialMap]
  );

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const hasError = useMemo(() => Object.values(errors).some((err) => !!err), [
    errors,
  ]);
  const setError = useCallback((key: string, err: string) => {
    setErrors((prev) => ({
      ...prev,
      [key]: err,
    }));
  }, []);

  const intervalsNotChanged = useMemo(
    () =>
      !!(
        selectedDateString &&
        isEqual(
          specialMap[selectedDateString],
          initialSpecialMap[selectedDateString]
        )
      ),
    [initialSpecialMap, selectedDateString, specialMap]
  );

  const disableSave = useMemo(() => hasError || intervalsNotChanged, [
    hasError,
    intervalsNotChanged,
  ]);

  const onCreateInterval = useCallback((date: string, id: string) => {
    setSpecialMap((prev) => ({
      ...prev,
      [date]: (prev[date] || []).concat([
        { id, startHour: 8, startMin: 0, endHour: 9, endMin: 0 },
      ]),
    }));
  }, []);

  const onUpdateInterval = useCallback(
    (date: string, interval: Partial<Interval>) => {
      setSpecialMap((prev) => ({
        ...prev,
        [date]: prev[date].map((int) => {
          if (int.id === interval.id) {
            return {
              ...int,
              ...interval,
            };
          }
          return int;
        }),
      }));
    },
    []
  );

  const onDeleteInterval = useCallback((date: string, intervalId: string) => {
    setSpecialMap((prev) => ({
      ...prev,
      [date]: prev[date].filter((int) => int.id !== intervalId),
    }));
  }, []);

  const onCreateClick = useCallback(() => {
    if (selectedDateString) {
      const id = uuidv4();
      onCreateInterval(selectedDateString, id);
      setIntervalEditing(id);
    }
  }, [onCreateInterval, setIntervalEditing, selectedDateString]);

  const onSave = useCallback(async () => {
    if (selectedDateString) {
      const saved = await onSaveSpecialAvailability(mcm.id, {
        date: selectedDateString,
        intervals: specialMap[selectedDateString],
      });
      if (saved) {
        setEditableIntervals({});
      }
    }
  }, [mcm.id, onSaveSpecialAvailability, selectedDateString, specialMap]);

  const renderDay = useCallback(
    (
      day: Date | null,
      _selectedDay: Date | null,
      dayInCurrentMonth: boolean
    ): JSX.Element => {
      const isInPast = isDateInThePast(day);
      const isAfterThreeMonths = isDateAfterThreeMonths(day);
      const specialIntervals = day
        ? specialMap[format(day, DATE_REQUEST_FORMAT)]
        : [];
      const hasSpecialInterval = !!(
        specialIntervals && specialIntervals.length
      );

      let className = classes.dateBtn;
      if (!dayInCurrentMonth) {
        className += ` ${classes.notInMonth}`;
      } else if (!isInPast) {
        if (isEqualDates(day, selectedDate)) {
          if (hasSpecialInterval) {
            className += ` ${classes.selectedWithSpecialInterval}`;
          } else {
            className += ` ${classes.selected}`;
          }
        } else if (day) {
          if (hasSpecialInterval) {
            className += ` ${classes.special}`;
          }
          const days = daysMap[day.getDay()];
          if (days && days.length) {
            className += ` ${classes.regular}`;
          }
        }
      }

      return (
        <div className={classes.dateBtnContainer}>
          <IconButton
            className={className}
            disabled={!dayInCurrentMonth || isInPast || isAfterThreeMonths}
          >
            <Typography variant="body2" color="inherit">
              {day?.getDate() ?? ""}
            </Typography>
          </IconButton>
        </div>
      );
    },
    [
      classes.dateBtn,
      classes.dateBtnContainer,
      classes.notInMonth,
      classes.regular,
      classes.selected,
      classes.special,
      classes.selectedWithSpecialInterval,
      daysMap,
      selectedDate,
      specialMap,
    ]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" {...dialogProps}>
      <IconButton
        className={classes.closeBtn}
        aria-label="close"
        onClick={onClose}
        size="small"
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.content}>
        <Box className={classes.contentLeft}>
          <Typography variant="subtitle2">
            {mcm.name} Special Availability
          </Typography>
          <div>
            <Paper className={classes.calendarContainer} elevation={2}>
              <DatePicker
                disableToolbar
                disablePast
                variant="static"
                value={selectedDate}
                onChange={handleDateChange}
                renderDay={renderDay}
                maxDate={add(new Date(), { days: 90 })}
              />
            </Paper>

            <div className={`${classes.btnBox} ${classes.legendItem}`}>
              <div className={`${classes.circle} ${classes.regular}`} />
              <Typography variant="caption" color="textSecondary">
                Regular Availability
              </Typography>
            </div>
            <div className={`${classes.btnBox} ${classes.legendItem}`}>
              <div className={`${classes.circle} ${classes.special}`} />
              <Typography variant="caption" color="textSecondary">
                Special Availability
              </Typography>
            </div>
            <div className={`${classes.btnBox} ${classes.legendItem}`}>
              <div
                className={`${classes.circle} ${classes.regular} ${classes.special}`}
              />
              <Typography variant="caption" color="textSecondary">
                Special Availability superimposed on Regular Availability
              </Typography>
            </div>
          </div>
        </Box>
        <Box className={classes.contentRight}>
          {selectedDateString && (
            <>
              <Typography variant="subtitle2" color="primary">
                {format(new Date(selectedDate!), DATE_SPECIAL_DISPLAY_FORMAT)}
              </Typography>
              <Box flex="1" mt={3}>
                <Box className={classes.itemsContainer}>
                  {selectedDateIntervals.length > 0 &&
                    selectedDateIntervals.map((interval) => (
                      <IntervalItem
                        key={interval.id}
                        interval={interval}
                        editing={!!editableIntervals[interval.id]}
                        setEditing={setIntervalEditing}
                        dayOfWeek={selectedDateString}
                        onUpdateInterval={onUpdateInterval}
                        onDeleteInterval={onDeleteInterval}
                        setError={setError}
                        errors={errors}
                      />
                    ))}
                </Box>
                <Box display="flex" justifyContent="flex-end" pt={1} mb={-1}>
                  <StyledIconButton
                    onClick={onCreateClick}
                    size="small"
                    color="info"
                    aria-label="add interval"
                  >
                    <AddCircleOutline />
                  </StyledIconButton>
                </Box>
              </Box>
              <div className={`${classes.btnBox} ${classes.actions}`}>
                <Button
                  variant="text"
                  color="primary"
                  disabled={intervalsNotChanged}
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
                <Button color="primary" disabled={disableSave} onClick={onSave}>
                  Save
                </Button>
              </div>
            </>
          )}
        </Box>
      </div>
    </Dialog>
  );
};
