import React from "react";
import { Box, Typography } from "@material-ui/core";

import { CustomRadioGroupField } from "components/CustomRadioGroupField";
import { useFormikContext } from "formik";
import { InitialValues } from "../types";

export const StepOne = () => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <Box minHeight="430px" marginBottom={3}>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginBottom: "8px" }}
      >
        Please select the action you would like to perform
      </Typography>
      <Box>
        <CustomRadioGroupField
          name="checkupActionType"
          options={values.checkupActionTypeOptions}
        />
      </Box>
    </Box>
  );
};
