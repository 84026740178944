import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import {
  billingCycleUnitOptions,
  getFieldName,
  getValueFromObject,
  isStringEqual,
} from "helpers";
import React, { useEffect, useMemo } from "react";
import {
  AutoCompleteOption,
  FinalizeMatchingMedPackage,
  ZohoMedUsageUnit,
  ZohoProduct,
} from "types";
import { Alert } from "@material-ui/lab";
import { MatchingPackageProductForms } from "./MatchingPackageProductForms";

interface Props {
  matchingPackage: FinalizeMatchingMedPackage;
  name?: string;
  bacteriostaticProducts: ZohoProduct[];
  packageFieldName: string;
  isCheapest: boolean;
  isBestBillingPackage: boolean;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isMatchingPackagesVisible: boolean;
}

const useStyles = makeStyles(({ palette: p, spacing }) => ({
  wrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
  text: {
    color: p.primary.light,
  },
  cheapest: {
    color: colors.green["500"],
    fontWeight: "bolder",
  },
}));

export const MatchingPackageForms = ({
  matchingPackage,
  name = "",
  bacteriostaticProducts,
  packageFieldName,
  isCheapest,
  isBestBillingPackage,
  medUsageUnits,
  isMatchingPackagesVisible,
}: Props) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const selectedPackage: string = useMemo(
    () => getValueFromObject("selectedPackage", values),
    [values]
  );

  const billingCycleUnit: AutoCompleteOption = useMemo(
    () => getValueFromObject("billingCycleUnit", values),
    [values]
  );

  const packageFirstProduct = useMemo(() => {
    return matchingPackage.products[0];
  }, [matchingPackage.products]);

  const packageBillingCycle = useMemo(() => {
    return packageFirstProduct?.matchingMedProduct.matchingMedProduct
      .Billing_Cycle_Unit;
  }, [
    packageFirstProduct?.matchingMedProduct.matchingMedProduct
      .Billing_Cycle_Unit,
  ]);

  const hasEarlyFirstRepeatMatchingMedProduct = useMemo(() => {
    return matchingPackage.products?.some(
      (p) => !!p.matchingMedProduct.matchingMedProduct.Early_First_Repeat
    );
  }, [matchingPackage.products]);

  useEffect(() => {
    setImmediate(() => {
      if (
        selectedPackage === matchingPackage.id &&
        !isStringEqual(billingCycleUnit?.value, packageBillingCycle) &&
        isMatchingPackagesVisible
      ) {
        setFieldValue(
          "billingCycleUnit",
          billingCycleUnitOptions.find((b) =>
            isStringEqual(b.value, packageBillingCycle)
          )
        );
      }
    });
  }, [
    setFieldValue,
    selectedPackage,
    billingCycleUnit?.value,
    matchingPackage.id,
    packageBillingCycle,
    isMatchingPackagesVisible,
  ]);

  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl>
              <Field component={RadioGroup} name={packageFieldName}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value={matchingPackage.id}
                  label={<Typography>{matchingPackage.Name}</Typography>}
                />
              </Field>
            </FormControl>
          </Grid>
          {isCheapest || isBestBillingPackage ? (
            <Grid item>
              <Typography variant="body1" className={classes.cheapest}>
                {isBestBillingPackage ? "Best Billing" : ""}
                {isBestBillingPackage && isCheapest ? " - " : ""}
                {isCheapest ? "Cheapest" : ""}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      {hasEarlyFirstRepeatMatchingMedProduct ? (
        <Box mb={2}>
          <Alert severity="info">
            To make sure the medicine will not run out from the client, the
            first repetition will be done 1 week earlier.
          </Alert>
        </Box>
      ) : null}
      {matchingPackage.products.map((matchingProduct, index) => (
        <Box
          mt={index === 0 ? 0 : 2}
          key={matchingProduct.id}
          className={classes.wrapper}
        >
          <MatchingPackageProductForms
            matchingProduct={matchingProduct}
            bacteriostaticProducts={bacteriostaticProducts}
            name={getFieldName(index, getFieldName("products", name))}
            medUsageUnits={medUsageUnits}
          />
        </Box>
      ))}
    </Box>
  );
};
