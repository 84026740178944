import {
  TestProfilesObr,
  Lab,
  BloodTakingOptionEnum,
  GenderEnum,
  BloodDrawer,
  BloodDrawBilledStatus,
} from "@deep-consulting-solutions/bmh-constants";

import { DefaultShippingAddressFormValues } from "components/ShippingAddressForm";

import { ZohoModule } from "types";

export enum AfterResultConsultationEnum {
  PCM = "Offer PCM Consultation",
  DOCTOR = "Schedule Doctor Consultation On Completion",
  NONE = "None",
}

export enum OrderTypeEnum {
  Therapy = "Therapy",
  "Non Therapy" = "Non Therapy",
}

export const ORDER_TYPE_DESCRIPTION: {
  [type in OrderTypeEnum]: string;
} = {
  [OrderTypeEnum["Non Therapy"]]:
    "Client is not a current BMH patient and PCM Call will be up-sold as free no obligation offer for prospective patient",
  [OrderTypeEnum.Therapy]:
    "Client is existing patient with BMH and PCM Call will be provided as normal",
};

export interface TestProfilesObrWithMappedLabs extends TestProfilesObr {
  mappedLabs: {
    [id: string]: true;
  };
}

export interface FormValues extends DefaultShippingAddressFormValues {
  testProfiles: TestProfilesObrWithMappedLabs[];
  lab: Lab | null;
  bloodDrawer: BloodDrawer | null;
  bloodTakingOption: BloodTakingOptionEnum | "";
  orderType: OrderTypeEnum | "";
  clientDob: Date | null;
  clientGender: GenderEnum | "";
  specialNotes: string;
  updateAddressForClient: boolean;
  billedStatus: BloodDrawBilledStatus | "";
  billInvoiceNumber: string;
  afterResultConsultation: AfterResultConsultationEnum;
}

export interface ZohoPathwayTestProfile {
  Test_Profiles: ZohoModule;
  id: string;
}
