import { setHours, setMinutes } from "date-fns";
import { Day } from "@deep-consulting-solutions/bmh-constants";
import { IntervalsByDayMap } from "types";

export const setTime = (hours: number, minutes: number, date = new Date()) =>
  setMinutes(setHours(date, hours), minutes);

export const from24To12 = (hours: number) =>
  hours > 12 ? hours - 12 : hours || 12;

export const formatTime = (hoursOrMin: number) =>
  String(hoursOrMin).length === 1 ? `0${hoursOrMin}` : `${hoursOrMin}`;

export const displayTime = (hours: number, minutes: number) =>
  `${formatTime(from24To12(hours))}:${formatTime(minutes)} ${
    hours < 12 ? "AM" : "PM"
  }`;

export const DAYS = ["1", "2", "3", "4", "5", "6", "0"];

export const makeIntervalsByDay = (days: Day[]) =>
  DAYS.reduce((acc, curr) => {
    acc[curr] = days[Number(curr)]?.intervals ?? [];
    return acc;
  }, {} as IntervalsByDayMap);
