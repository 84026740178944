import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";

const useStyle = makeStyles(({ palette: p }) => ({
  btn: {
    background: p.error.main,
    color: "white",
  },
}));

export interface DeleteCheckoutPageDialogProps extends DialogProps {
  onClose: () => void;
  handleDelete: () => void;
}

export const DeleteCheckoutPageDialog = ({
  open,
  onClose,
  handleDelete,
}: DeleteCheckoutPageDialogProps) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onDelete = useCallback(() => {
    handleDelete();
  }, [handleDelete]);

  const classes = useStyle();

  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          Warning: Deleting a checkout page will cause blood test order creation
          from that checkout page to stop.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={onDelete}
          className={classes.btn}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
