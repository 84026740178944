import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

const useStyle = makeStyles(() => ({
  alert: {
    fontWeight: 500,
  },
}));

interface Props {
  handleRetry?: () => void;
  failureReason?: string;
  title: string;
  body: string;
}
export const ErrorComponent = ({
  handleRetry,
  failureReason,
  title,
  body,
}: Props) => {
  const classes = useStyle();
  return (
    <Box>
      <Alert
        severity="error"
        icon={<Info fontSize="inherit" />}
        className={classes.alert}
      >
        <AlertTitle>{title}</AlertTitle>
        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="center"
          direction="row"
          wrap="nowrap"
        >
          <Grid item>
            {!!failureReason && <Typography>{failureReason}.</Typography>}
            <Typography>{body}</Typography>
          </Grid>
          {!!handleRetry && (
            <Grid item>
              <Button color="primary" onClick={handleRetry}>
                Retry
              </Button>
            </Grid>
          )}
        </Grid>
      </Alert>
    </Box>
  );
};
