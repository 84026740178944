import { Box, makeStyles, Typography } from "@material-ui/core";
import { BasicTable } from "components/BasicTable";
import moment from "moment";
import React, { useMemo } from "react";

interface SignedLegacyCttps {
  id: string;
  Name: string;
  recordCrmLink: string;
  dateSent: string;
  dateCompleted: string;
}

interface PatientCttpsProps {
  signedLegacyCttps: SignedLegacyCttps[];
}

const useStyle = makeStyles(({ spacing: s }) => ({
  subHeading: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: s(1),
  },
  mb3: {
    marginBottom: s(3),
  },
}));

export const PatientCttps: React.FC<PatientCttpsProps> = ({
  signedLegacyCttps,
}) => {
  const classes = useStyle();

  const memoizedLegacyCttps = useMemo(
    () =>
      signedLegacyCttps.map((signedLegacyCttp) => ({
        ...signedLegacyCttp,
        name: signedLegacyCttp.Name,
        Name: (
          <a
            href={signedLegacyCttp.recordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {signedLegacyCttp.Name}
          </a>
        ),
        dateSent: moment(signedLegacyCttp.dateSent).format("DD/MM/YYYY"),
        dateCompleted: moment(signedLegacyCttp.dateCompleted).format(
          "DD/MM/YYYY"
        ),
      })),
    [signedLegacyCttps]
  );

  return (
    <>
      <Box className={classes.mb3}>
        <Typography variant="h2" className={classes.subHeading}>
          Signed Legacy CTTPs ({signedLegacyCttps.length})
        </Typography>
        <BasicTable
          tableHeaders={[
            { title: "CTTP", id: "Name" },
            { title: "Date sent", id: "dateSent" },
            { title: "Date completed", id: "dateCompleted" },
          ]}
          tableData={memoizedLegacyCttps}
          emptyMessage={
            memoizedLegacyCttps.length === 0
              ? "No Signed Legacy CTTPs Found"
              : ""
          }
        />
      </Box>
    </>
  );
};
