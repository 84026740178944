import { FormattedTreatmentOrder, TreatmentOrder } from "./types";

export function formatTreatmentOrder(
  rawTreatmentOrder: TreatmentOrder
): FormattedTreatmentOrder {
  const address = [
    rawTreatmentOrder.Street,
    rawTreatmentOrder.Apartment_Floor_Door_Other,
    rawTreatmentOrder.City,
    rawTreatmentOrder.Province_Region_State,
    rawTreatmentOrder.Postal_Code_Zip,
    rawTreatmentOrder.Country,
  ];
  const formatted = {
    orderNumber: rawTreatmentOrder.Name,
    changesRequested: rawTreatmentOrder.Changes_Requested,
    courierOrderNumber: rawTreatmentOrder?.Courier_Order_Number || "",
    courier: rawTreatmentOrder["Courier.Name"] || "",
    courierId: rawTreatmentOrder["Courier.id"] || "",
    patient: {
      name: `${rawTreatmentOrder.Patient.First_Name} ${rawTreatmentOrder.Patient.Last_Name}`,
      id: rawTreatmentOrder.Patient.Patient_ID,
      phone:
        rawTreatmentOrder.Patient.Phone || rawTreatmentOrder.Patient.Mobile,
      email: rawTreatmentOrder.Patient.Email,
      shippingAddress: address.filter(Boolean).join(", ") || "",
    },
  };
  return formatted;
}
