import {
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useMemo } from "react";
import { Result, Test } from "./types";

interface Props {
  test: Test;
  showRange: boolean;
}

interface StyleProps {
  anyHasMissingResult: boolean;
  isDifferentResult: boolean;
}

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  noPadding: {
    padding: s(0),
  },
  leftBorder: ({ anyHasMissingResult, isDifferentResult }: StyleProps) => {
    if (anyHasMissingResult) {
      return {
        borderLeft: `6px solid ${p.error.main}`,
        paddingLeft: s(1),
      };
    }
    if (isDifferentResult) {
      return {
        borderLeft: `6px solid ${p.warning.main}`,
        paddingLeft: s(1),
      };
    }
    return {
      borderLeft: `6px solid transparent`,
      paddingLeft: s(1),
    };
  },
}));

const useCellStyle = makeStyles(({ palette: p, spacing: s }) => ({
  existing: {
    background: p.grey[100],
  },
  cell: {
    padding: s(0, 2),
  },
  nA: {
    color: p.grey[500],
    fontSize: 10,
  },
  error: {
    color: p.error.main,
  },
  warning: {
    color: p.warning.main,
  },
}));

interface CellProps {
  result: Result;
  field: "unit" | "result" | "range";
  isExistingResult?: boolean;
  showRange: boolean;
}

const GetCell = ({
  result,
  field,
  isExistingResult = false,
  showRange,
}: CellProps) => {
  const classes = useCellStyle();
  const text = result[field];
  return (
    <TableCell
      className={clsx(
        isExistingResult ? classes.existing : undefined,
        classes.cell
      )}
    >
      {!!text && field === "result" && !!result.abnormalFlag ? (
        <Tooltip
          title={
            <Typography>
              Abnormal Flag: Below low normal{" "}
              {!showRange ? (
                <>
                  <br /> Normal Range: {result.range}
                </>
              ) : null}
            </Typography>
          }
        >
          <Typography className={classes.error}>{text}</Typography>
        </Tooltip>
      ) : (
        <Typography
          className={
            result.failureCode && field === "result"
              ? classes.warning
              : undefined
          }
        >
          {!!result.failureCode && result.failureCode}
          {!result.failureCode &&
            (!text ? <span className={classes.nA}>N/A</span> : text)}
        </Typography>
      )}
    </TableCell>
  );
};

export const TestRow = ({
  test: { name, existingResult, newResult, currentResult },
  showRange,
}: Props) => {
  const { anyHasMissingResult, isDifferentResult } = useMemo(() => {
    const oldAndNew = {
      anyHasMissingResult: !(existingResult.result && newResult.result),
      isDifferentResult: !(
        existingResult.result === newResult.result &&
        existingResult.unit === newResult.unit
      ),
    };
    if (!currentResult) {
      return oldAndNew;
    }
    return {
      anyHasMissingResult: oldAndNew.anyHasMissingResult
        ? true
        : !currentResult.result,
      isDifferentResult: oldAndNew.isDifferentResult
        ? true
        : !(
            existingResult.result === currentResult.result &&
            existingResult.unit === currentResult.unit
          ),
    };
  }, [existingResult, newResult, currentResult]);

  const classes = useStyle({ anyHasMissingResult, isDifferentResult });

  return (
    <TableRow>
      <TableCell className={classes.noPadding}>
        <div className={classes.leftBorder}>
          <Typography>{name}</Typography>
        </div>
      </TableCell>

      <GetCell
        result={existingResult}
        field="result"
        isExistingResult
        showRange={showRange}
      />
      {showRange && (
        <GetCell
          result={existingResult}
          field="range"
          isExistingResult
          showRange={showRange}
        />
      )}
      <GetCell
        result={existingResult}
        field="unit"
        isExistingResult
        showRange={showRange}
      />

      <GetCell result={newResult} field="result" showRange={showRange} />
      {showRange && (
        <GetCell result={newResult} field="range" showRange={showRange} />
      )}
      <GetCell result={newResult} field="unit" showRange={showRange} />

      {currentResult ? (
        <>
          <GetCell
            result={currentResult}
            field="result"
            isExistingResult
            showRange={showRange}
          />
          {showRange && (
            <GetCell
              result={currentResult}
              field="range"
              isExistingResult
              showRange={showRange}
            />
          )}
          <GetCell
            result={currentResult}
            field="unit"
            isExistingResult
            showRange={showRange}
          />
        </>
      ) : null}
    </TableRow>
  );
};
