import React, { useCallback, useEffect, useMemo, useState } from "react";
import { notifications, zohoServices } from "services";
import { Formik, FormikConfig, useFormikContext } from "formik";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import Loader from "components/Loader";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import { Alert } from "@material-ui/lab";
import { InitialValues, TreatmentResponse } from "./types";
import { TreatmentOrderTable } from "./TreatmentOrderTable";
import { updateOrders, getTreatmentData } from "./requests";
import {
  extractTreatmentOrders,
  getChangedOrders,
  validationSchema,
} from "./utils";

const ActionButtons = ({ closeWidget }: { closeWidget: () => void }) => {
  const { submitForm, isSubmitting, isValid } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          submitForm();
          if (!isValid) {
            notifications.notifyError(
              "Please make sure all required fields are filled correctly"
            );
          }
        }}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Update"}
      </Button>
    </Box>
  );
};

export function UpdateUpcomingOrders() {
  const [submitData, setSubmitData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [treatmentId] = useSelector(zohoSelectors.getIds);
  const [initialValues, setInitialValues] = useState<InitialValues | null>(
    null
  );
  const [treatmentData, setTreatmentData] = useState<TreatmentResponse | null>(
    null
  );

  const hasValidState = useMemo(
    () =>
      ["Active", "Pending First Payment"].includes(treatmentData?.State || ""),
    [treatmentData?.State]
  );

  const hasOrdersCreated = useMemo(() => treatmentData?.Orders_Created, [
    treatmentData?.Orders_Created,
  ]);

  const shouldProceed = hasValidState && hasOrdersCreated;

  const closeWidget = useCallback((reload?: boolean) => {
    zohoServices.closePopup(reload);
  }, []);

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values: InitialValues, { setSubmitting }) => {
      try {
        setSubmitData(true);
        const initialOrderValues = values.initialTreatmentOrderValues;
        const finalOrderValues = extractTreatmentOrders(values.billingData);
        const changedOrders = getChangedOrders(
          initialOrderValues,
          finalOrderValues
        );
        if (changedOrders.length === 0) {
          notifications.notifyInfo("No change was made");
          return;
        }
        await updateOrders({
          treatmentId,
          treatmentOrders: changedOrders,
        });
        closeWidget(true);
      } catch (error) {
        notifications.notifyError(
          "Something went wrong and the action could not be completed"
        );
      } finally {
        setSubmitData(false);
        setSubmitting(false);
      }
    },
    [treatmentId, closeWidget]
  );

  const fetchData = useCallback(async (zohoTreatmentId: string) => {
    const res = await getTreatmentData(zohoTreatmentId);

    if (res) {
      const initialTreatmentOrderValues = extractTreatmentOrders(
        res.billingData
      );

      setTreatmentData(res);
      setInitialValues({
        billingData: res.billingData,
        // This is not modified just in case I want to do a reset
        initialBillingData: res.billingData,
        initialTreatmentOrderValues,
      });
    }

    return res;
  }, []);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(treatmentId);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [fetchData, treatmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle
          isCustomButton
          title="Update Upcoming Treatment Orders"
        />
        <TreatmentWidgetContent>
          <Loader open={submitData} />
          {loading && (
            <Box
              height="480px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && !shouldProceed && (
            <Alert severity="error">
              Treatment must be Active or Pending First Payment to use this
              widget.
            </Alert>
          )}
          {!loading && shouldProceed && (
            <>
              {(treatmentData?.billingData || []).length > 0 && (
                <TreatmentOrderTable />
              )}
            </>
          )}
        </TreatmentWidgetContent>
        {!loading && shouldProceed && (
          <TreatmentWidgetActions>
            <ActionButtons closeWidget={closeWidget} />
          </TreatmentWidgetActions>
        )}
      </TreatmentWidgetLayout>
    </Formik>
  );
}
