export const OBX_TABLE_META = {
  test: {
    label: "Test",
  },
  result: {
    label: "Result",
  },
  normal: {
    label: "Normal Range",
  },
  unit: {
    label: "Unit",
  },
  abnormal: {
    label: "Abnormal flags",
  },
  history: {
    label: null,
  },
};

export type OBXTableField = keyof typeof OBX_TABLE_META;

export const TABLE_COLUMN_COUNT = Object.keys(OBX_TABLE_META).length;
