import React, { useCallback, useEffect, useState } from "react";
import {
  McmAvailabilityWithSpecial,
  McmSpecialAvailability,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Loader from "components/Loader";
import { SettingsContainer } from "components/SettingsContainer";
import { mcmAvailabilityRequests } from "redux/settings/mcmAvailability";
import { DayEnum, IntervalsByDayMap } from "types";
import { notifications } from "services";
import { getENText } from "helpers";
import { AvailabilityRow } from "./AvailabilityRow";

const useStyles = makeStyles(({ palette: p, spacing: s }) =>
  createStyles({
    tableHeader: {
      color: p.primary.light,
      fontWeight: 500,
      background: p.common.white,
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 2,
      padding: 0,
      height: 41,
      borderBottom: "none",
    },
    firstCell: {
      minWidth: "200px",
      width: "200px",
      borderBottom: "none",
    },
    headerBox: {
      height: 41,
      width: "100%",
      borderBottom: `1px solid ${p.text.disabled}`,
      padding: s(2),
    },
    firstCellBox: {
      borderBottom: "none",
    },
    columnHeader: {
      top: 0,
      left: 200,
      position: "sticky",
    },
    tableContainer: {
      maxHeight: "calc(100vh - 141px)",
    },
  })
);

export const MCMAvailability = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<McmAvailabilityWithSpecial[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const d = await mcmAvailabilityRequests.fetch();
        setData(d);
      } catch {
        //
      }
      setLoading(false);
    })();
  }, []);

  const onSaveAvailability = useCallback(
    async (mcmId: string, days: IntervalsByDayMap) => {
      setLoading(true);
      try {
        const mcm = await mcmAvailabilityRequests.post(mcmId, days);
        setData((prev) =>
          prev.map((m) => {
            if (m.id === mcmId) {
              return { ...mcm, specialAvailabilities: m.specialAvailabilities };
            }
            return m;
          })
        );
        setLoading(false);
        notifications.notifySuccess(getENText("mcm.noti.success.updated"));
        return true;
      } catch {
        setLoading(false);
        return false;
      }
    },
    []
  );

  const onSaveSpecialAvailability = useCallback(
    async (
      mcmId: string,
      specialAvailability: Omit<McmSpecialAvailability, "id">
    ) => {
      setLoading(true);
      try {
        const newSpecialAvailability = await mcmAvailabilityRequests.postSpecialAvailability(
          mcmId,
          specialAvailability
        );
        setData((prev) =>
          prev.map((m) => {
            if (m.id === mcmId) {
              if (!newSpecialAvailability.date) {
                return {
                  ...m,
                  specialAvailabilities: m.specialAvailabilities.filter(
                    (sa) => sa.date !== specialAvailability.date
                  ),
                };
              }
              const index = m.specialAvailabilities.findIndex(
                (s) => s.id === newSpecialAvailability.id
              );
              const newSpecialAvailabilities =
                index !== -1
                  ? m.specialAvailabilities.map((s) => {
                      if (s.id === newSpecialAvailability.id) {
                        return newSpecialAvailability;
                      }
                      return s;
                    })
                  : m.specialAvailabilities.concat(newSpecialAvailability);
              return { ...m, specialAvailabilities: newSpecialAvailabilities };
            }
            return m;
          })
        );
        setLoading(false);
        notifications.notifySuccess(
          getENText("mcmSpecial.noti.success.updated")
        );
        return true;
      } catch (error) {
        setLoading(false);
        return false;
      }
    },
    []
  );

  return (
    <>
      <Loader open={loading} isFixed />
      <SettingsContainer>
        <Box pb={3} px={2}>
          <Typography variant="h4" component="h1" paragraph>
            PCM Calendar Availability
          </Typography>

          <Typography>
            Set regular time availability intervals for each PCM. This
            information will be displayed as available slots in booking page for
            clients to book a PCM call.
          </Typography>
        </Box>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes.tableHeader} ${classes.firstCell}`}
                >
                  <div
                    className={`${classes.headerBox} ${classes.firstCellBox}`}
                  />
                </TableCell>
                {Object.values(DayEnum).map((day) => (
                  <TableCell
                    className={`${classes.tableHeader} ${classes.columnHeader}`}
                    key={day}
                  >
                    <div className={classes.headerBox}>{day}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((mcm) => (
                  <AvailabilityRow
                    key={mcm.id}
                    mcm={mcm}
                    onSaveAvailability={onSaveAvailability}
                    onSaveSpecialAvailability={onSaveSpecialAvailability}
                  />
                ))}
              {!data.length && !loading && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography color="textSecondary" align="center">
                      No PCMs Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SettingsContainer>
    </>
  );
};
