import { Button } from "@material-ui/core";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback } from "react";

interface Props {
  view: "success" | "error" | "loading";
}

export const PharmacySelectionAutomationActions = ({ view }: Props) => {
  const { handleClose } = useTreatmentWidgetLayout();

  const onClickCloseSuccess = useCallback(() => {
    handleClose(true);
  }, [handleClose]);

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  if (view === "loading") {
    return null;
  }

  return (
    <>
      {view === "error" ? (
        <Button variant="outlined" onClick={onClickClose} color="primary">
          CLOSE
        </Button>
      ) : null}
      {view === "success" ? (
        <Button
          onClick={onClickCloseSuccess}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      ) : null}
    </>
  );
};
