export type BloodtestResultsTableKeys =
  | "stage"
  | "sampleID"
  | "clientName"
  | "patientID"
  | "gender"
  | "dob"
  | "bloodTests"
  | "bloodTakingOption";

export const BLOOD_TEST_RESULTS_TABLE_META: {
  [P in BloodtestResultsTableKeys]: { label: string };
} = {
  stage: {
    label: "Stage",
  },
  sampleID: {
    label: "Order/Sample ID",
  },
  clientName: {
    label: "Client Name",
  },
  patientID: {
    label: "Patient ID",
  },
  gender: {
    label: "Gender",
  },
  dob: {
    label: "Date of Birth",
  },
  bloodTests: {
    label: "Blood Tests",
  },
  bloodTakingOption: {
    label: "Blood Taking Option",
  },
};
