import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: "none",
    },
    fileItem: {
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
    },
  })
);

interface FileInputProps {
  name: string;
  label: string;
  multiple?: boolean;
  required?: boolean;
  accept?: string;
}

export const CustomFileInput: React.FC<FileInputProps> = ({
  name,
  label,
  multiple = false,
  required = false,
  accept = "application/pdf",
}) => {
  const classes = useStyles();
  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext<any>();

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      if (files) {
        const fileArray = Array.from(files);
        setFieldValue(name, fileArray);
        setFieldTouched(name, true);
      }
    },
    [setFieldValue, name, setFieldTouched]
  );

  const handleFileDelete = useCallback(
    (index: number) => {
      const updatedFiles = [...(values[name] || [])];
      updatedFiles.splice(index, 1);
      setFieldValue(name, updatedFiles);
    },
    [setFieldValue, name, values]
  );

  return (
    <>
      <input
        accept={accept}
        id={name}
        className={classes.input}
        multiple={multiple}
        type="file"
        onChange={handleFileChange}
      />
      <FormLabel htmlFor={name}>
        <span style={{ display: "block", marginBottom: "8px" }}>{label}</span>
        <Box display="flex" alignItems="center" gridColumnGap="16px">
          <Button
            variant="contained"
            style={{ textTransform: "capitalize" }}
            component="p"
          >
            Upload
          </Button>
          {(values[name] || []).length === 0 && (
            <Typography variant="body2">No File Selected</Typography>
          )}
          {!multiple && (
            <Box>
              {(values[name] || []).map((file: File, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} className={classes.fileItem}>
                  <Typography variant="body2">{file.name}</Typography>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFileDelete(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </FormLabel>
      {required && touched[name] && !values[name] && (
        <FormHelperText error>{errors[name]}</FormHelperText>
      )}
      {multiple && (
        <Box>
          {(values[name] || []).map((file: File, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} className={classes.fileItem}>
              <Typography variant="body2">{file.name}</Typography>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleFileDelete(index)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
