import React, { memo } from "react";
import {
  makeStyles,
  Box,
  Typography,
  BoxProps,
  Tooltip,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";

import { DataWithWarning } from "./DataWithWarning";

interface StyleProps {
  dark?: boolean;
}

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {},
  dataRow: ({ dark }: StyleProps) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: s(2),
    paddingRight: s(2),
    paddingTop: s(1),
    paddingBottom: s(1),
    background: p.grey[dark ? 200 : 100],
    borderRadius: `${s(2)}px`,
  }),
  icon: {
    marginRight: s(2),
    color: p.grey[600],
  },
  subName: {
    color: p.grey[500],
    marginRight: s(1),
  },
  name: {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "right",
  },
  labelRow: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: s(1),
    color: p.grey[500],
  },
}));

interface InfoItemProps extends BoxProps {
  name: string;
  withTitle?: string | string[];
  subName?: string;
  label: string;
  Icon?: SvgIconComponent;
  IconElement?: JSX.Element;
  dark?: boolean;
  errorMessage?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({
  name,
  withTitle,
  subName,
  label,
  Icon,
  IconElement,
  className,
  dark,
  errorMessage,
  ...others
}) => {
  const title = withTitle || [];
  const titles = Array.isArray(title) ? title : [title];

  const classes = useStyle({ dark });
  const combinedClass = `${classes.wrapper} ${className || ""}`;
  return (
    <Box className={combinedClass} {...others}>
      <Tooltip
        title={
          titles.length ? (
            <>
              {titles.map((str) => (
                <div key={str}>{str}</div>
              ))}
            </>
          ) : (
            ""
          )
        }
      >
        <Box className={classes.dataRow}>
          {Icon && <Icon className={classes.icon} />}
          {IconElement}
          <Typography variant="body2" className={classes.name}>
            {!!subName && <span className={classes.subName}>{subName}</span>}

            {name}
          </Typography>
        </Box>
      </Tooltip>
      <Box className={classes.labelRow}>
        <DataWithWarning warning={errorMessage}>
          <Typography variant="body2">{label}</Typography>
        </DataWithWarning>
      </Box>
    </Box>
  );
};

export default memo(InfoItem);
