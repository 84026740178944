import { Box, TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import {
  billingCycleUnitOptions,
  getFieldName,
  getValueFromObject,
} from "helpers";
import React, { useCallback, useEffect, useMemo } from "react";
import { AutoCompleteOption } from "types";

interface Props {
  name?: string;
  mb?: number;
  minWidth?: number;
  isChildField?: boolean;
}
export const BillingCycleUnitField = ({
  name,
  mb = 2,
  minWidth = 200,
  isChildField = false,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("billingCycleUnit", name), [
    name,
  ]);

  const onlyOnceFieldName = useMemo(() => getFieldName("onlyOnce", name), [
    name,
  ]);

  const onlyOnce: boolean = useMemo(
    () => getValueFromObject(onlyOnceFieldName, values),
    [values, onlyOnceFieldName]
  );

  const fieldValue: AutoCompleteOption | null = useMemo(
    () => getValueFromObject(fieldName, values),
    [values, fieldName]
  );

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const disabled = useMemo(() => isChildField && !!onlyOnce, [
    onlyOnce,
    isChildField,
  ]);

  const onChange = useCallback(
    (_: any, value: AutoCompleteOption, reason: AutocompleteChangeReason) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  useEffect(() => {
    setImmediate(() => {
      if (onlyOnce && fieldValue?.value && isChildField) {
        setFieldValue(fieldName, null);
      }
    });
  }, [onlyOnce, setFieldValue, fieldName, isChildField, fieldValue?.value]);

  return (
    <Box mb={mb} minWidth={minWidth}>
      <Field
        required
        name={fieldName}
        component={Autocomplete}
        disableClearable
        options={billingCycleUnitOptions}
        getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
        onChange={onChange}
        disabled={disabled}
        onBlur={() => {
          setFieldTouched(fieldName, true);
        }}
        color="primary"
        getOptionSelected={(option: any, value: any) => {
          return option.value === value.value && option.title === value.title;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            required
            name={fieldName}
            variant="outlined"
            label="Billing Cycle Unit"
            helperText={
              fieldTouched && typeof fieldError === "string" && fieldError
            }
            error={
              fieldTouched && typeof fieldError === "string" && !!fieldError
            }
            size="small"
          />
        )}
      />
    </Box>
  );
};
