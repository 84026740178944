import React, { memo, useMemo } from "react";
import { makeStyles, TableRow } from "@material-ui/core";
import { BloodTestResultItemHistory } from "@deep-consulting-solutions/bmh-constants";

import WarningCell from "../WarningCell";
import { BigTableCell as TableCell } from "../TestResults.styles";
import { processSampleCollectedDate } from "../TestResults.helpers";
import { DataWithWarning } from "../DataWithWarning";

const useStyle = makeStyles(() => ({
  cellDate: {
    width: "200px",
  },
}));

interface HistoryViewRowProps {
  item: BloodTestResultItemHistory;
  hasWarning: boolean;
}

const HistoryViewRow: React.FC<HistoryViewRowProps> = ({
  item,
  hasWarning,
}) => {
  const { date, time, warning } = useMemo(
    () => processSampleCollectedDate(item),
    [item]
  );

  const classes = useStyle();

  return (
    <TableRow>
      {hasWarning && <WarningCell abnormal={item.abnormalFlag} />}
      <TableCell className={classes.cellDate}>
        <DataWithWarning warning={warning}>
          {date} {time}
        </DataWithWarning>
      </TableCell>
      <TableCell>{item.value}</TableCell>
      <TableCell>{item.normalRange}</TableCell>
      <TableCell>{item.unit}</TableCell>
      <TableCell>
        {item.abnormalFlag?.meaning || item.abnormalFlag?.flag || ""}
      </TableCell>
    </TableRow>
  );
};

export default memo(HistoryViewRow);
