import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import {
  createStyles,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useMemo, useCallback } from "react";
import { DO_NOT_USE_MOBILE_DRAWER_VALUE } from "../../constants";
import { FormValues } from "../../types";
import { RowGroup } from "./RowGroup";

const useStyles = makeStyles(() =>
  createStyles({
    radio: {},
  })
);

interface Props {
  bloodDrawers: BloodDrawer[];
}

export const BloodDrawerOptions = ({ bloodDrawers }: Props) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const isNoUseMobileDrawersSelected = useMemo(() => {
    return values.bloodDrawer === DO_NOT_USE_MOBILE_DRAWER_VALUE;
  }, [values.bloodDrawer]);

  const onNoUseMobileDrawersSelect = useCallback(() => {
    setFieldValue("bloodDrawer", DO_NOT_USE_MOBILE_DRAWER_VALUE);
  }, [setFieldValue]);

  const sortedBloodDrawers = useMemo(
    () =>
      bloodDrawers.sort((a, b) => Number(a.price || 0) - Number(b.price || 0)),
    [bloodDrawers]
  );

  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>

            <TableCell>
              <Typography>Blood Drawer</Typography>
            </TableCell>
            <TableCell>
              <Typography>Price</Typography>
            </TableCell>
            <TableCell>
              <Typography>Prepaid by BMH</Typography>
            </TableCell>
            <TableCell>
              <Typography>Provides Own Kits</Typography>
            </TableCell>
            <TableCell>
              <Typography>Takes Sample to the Lab</Typography>
            </TableCell>
            <TableCell>
              <Typography>Special Instructions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedBloodDrawers.map((bloodDrawer) => (
            <RowGroup key={bloodDrawer.id} bloodDrawer={bloodDrawer} />
          ))}
          <TableRow>
            <TableCell>
              <Radio
                className={classes.radio}
                color="primary"
                size="small"
                checked={isNoUseMobileDrawersSelected}
                onClick={onNoUseMobileDrawersSelect}
              />
            </TableCell>
            <TableCell colSpan={6}>
              <Typography>
                Client doesn&apos;t want to use any of the mobile blood drawers
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
