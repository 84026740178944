import { format } from "date-fns";
import {
  TestAbnormalFlag,
  ManualResult,
  PathwayManualResult,
  ManualResultType,
  BloodTestResultWithPathway,
  TestFailureCode,
  PathwayRes,
  Pathway,
  BloodTestResultItem,
  Lab,
} from "@deep-consulting-solutions/bmh-constants";
import * as Yup from "yup";

import { formatDateForRequest, formatTimeForRequest, getENText } from "helpers";
import { BLOOD_TEST_RESULT_NAME_DATE_FORMAT } from "configs";

import {
  EMPTY_TEST_ROW,
  FormResultItem,
  FormValues,
  PathwayFormValues,
} from "./ResultForm.types";
import { MapObxCode } from "../TestResults.types";

const composeDate = (dateDate: Date | null, timeDate: Date | null) => {
  if (!dateDate) return undefined;
  let str = formatDateForRequest(dateDate);

  if (timeDate) {
    str += `T${formatTimeForRequest(timeDate)}:00`;
  } else {
    str += "T00:00:00";
  }

  return str;
};

export const composeManualResultItem = ({
  identifier,
  name,
  value,
  normalRange,
  unit,
  abnormalFlag,
  notes,
  isFailed,
}: FormResultItem) => {
  return {
    obxId: name?.id || "",
    identifier:
      typeof identifier === "string" ? identifier : identifier.observationID,
    unit: typeof unit === "string" ? unit : unit.unit,
    value,
    normalRange,
    abnormalFlagId: abnormalFlag?.id || undefined,
    notes,
    isFailed,
  };
};

export const composeManualRequest = (
  values: FormValues,
  s3Key: string,
  relatedPathwaysByLab?: {
    [labId: string]: PathwayRes[];
  }
): ManualResult => {
  return {
    name: values.name,
    showInPortal: values.showInPortal,
    labName: values.lab ? undefined : values.labName,
    labId: values.lab?.id,
    relatedBloodTestOrderId:
      values.relatedPathway &&
      values.lab &&
      relatedPathwaysByLab &&
      relatedPathwaysByLab[values.lab.id]
        ? values.relatedPathway.id
        : undefined,
    sampleCollectedOn: composeDate(
      values.collectedDate,
      values.collectedTime
    ) as string,
    sampleReceivedByLabOn: composeDate(
      values.receivedDate,
      values.receivedTime
    ),
    resultReportedOn: composeDate(
      values.reportedDate,
      values.reportedTime
    ) as string,
    results: values.results.map((item) => {
      return composeManualResultItem(item);
    }),
    s3Key: s3Key || undefined,
    type: ManualResultType.ManualResult,
  };
};

export const composePathwayManualRequest = (
  values: PathwayFormValues
): PathwayManualResult => {
  return {
    name: values.name,
    showInPortal: true,
    sampleCollectedOn: composeDate(
      values.collectedDate,
      values.collectedTime
    ) as string,
    sampleReceivedByLabOn: composeDate(
      values.receivedDate,
      values.receivedTime
    ),
    resultReportedOn: composeDate(
      values.reportedDate,
      values.reportedTime
    ) as string,
    results: values.results.map((item) => {
      return composeManualResultItem(item);
    }),
    type: ManualResultType.PathwayManualResult,
  };
};

export const composeBloodTestResultName = (
  sampleCollectionDate: Date,
  labName: string,
  relatedPathway?: PathwayRes | null
) => {
  return `${
    relatedPathway
      ? `${relatedPathway.pathwayTestProfiles
          .map((t) => t.testProfile.bmhName)
          .join(", ")} `
      : ""
  }Test Results from ${format(
    sampleCollectionDate,
    BLOOD_TEST_RESULT_NAME_DATE_FORMAT
  )} - ${labName}`;
};

export const getValidationSchema = (
  oldAutoGeneratedName: string,
  hasTargetResult: boolean,
  isPathway?: boolean
) => {
  if (isPathway) {
    return Yup.object({
      name: Yup.string().notRequired(),
      labName: Yup.string()
        .required(getENText("results.manualAdd.required"))
        .nullable(),

      collectedDate: Yup.mixed().test(
        "not empty",
        getENText("results.manualAdd.required"),
        (value) => !!value
      ),
      reportedDate: Yup.mixed().test(
        "not empty",
        getENText("results.manualAdd.required"),
        (value) => !!value
      ),
      results: Yup.array().of(
        Yup.object({
          name: Yup.string()
            .nullable()
            .test(
              "nameRequired",
              getENText("results.manualAdd.required"),
              () => true
            ),
          value: Yup.string().required(getENText("results.manualAdd.required")),
          unit: Yup.string()
            .required(getENText("results.manualAdd.required"))
            .nullable(),
          normalRange: Yup.string().required(
            getENText("results.manualAdd.required")
          ),
        }).nullable()
      ),
    });
  }

  return Yup.lazy<FormValues>((values) => {
    return Yup.object({
      name: Yup.string().test(
        "name",
        getENText("results.manualAdd.required"),
        // eslint-disable-next-line func-names
        function (value) {
          const isNameDisabled =
            (!this.parent.labName || !this.parent.collectedDate) &&
            (value || "") === oldAutoGeneratedName;
          return isNameDisabled ? true : !!value;
        }
      ),
      labName: Yup.string()
        .required(getENText("results.manualAdd.required"))
        .nullable(),
      relatedPathway:
        !hasTargetResult &&
        values.lab &&
        values.relatedPathwaysByLab[values.lab.id]
          ? Yup.object().required("This is required").nullable()
          : Yup.object().nullable(),
      collectedDate: Yup.mixed().test(
        "not empty",
        getENText("results.manualAdd.required"),
        (value) => !!value
      ),
      reportedDate: Yup.mixed().test(
        "not empty",
        getENText("results.manualAdd.required"),
        (value) => !!value
      ),
      results: Yup.array().of(
        Yup.object({
          name: Yup.string()
            .nullable()
            .test(
              "nameRequired",
              getENText("results.manualAdd.required"),
              (val) => !!val
            ),
          obx: Yup.object({ id: Yup.string() })
            .nullable()
            .test(
              "obxIdRequired",
              getENText("results.manualAdd.required"),
              () => true
            ),
          value: Yup.string().required(getENText("results.manualAdd.required")),
          unit: Yup.string()
            .required(getENText("results.manualAdd.required"))
            .nullable(),
          normalRange: Yup.string().required(
            getENText("results.manualAdd.required")
          ),
        }).nullable()
      ),
    }) as any;
  });
};

export const mapBloodTestResultItem = (
  {
    notes,
    abnormalFlag,
    isFailed,
    name,
    obxId,
    identifier,
    unit,
    ...r
  }: BloodTestResultItem,
  mappedObxCodeNames: {
    [bmhName: string]: MapObxCode;
  },
  failureMap: {
    [x: string]: TestFailureCode;
  },
  abnormalMap: {
    [x: string]: TestAbnormalFlag;
  }
) => {
  const obxCode = mappedObxCodeNames[name];
  const details =
    obxCode &&
    obxCode.details.find((d) => {
      return d.observationID === identifier && d.unit === unit;
    });
  const ab = (abnormalFlag && abnormalMap[abnormalFlag.id]) || null;
  return {
    ...r,
    normalRange: r.normalRange || "",
    name: obxCode || name,
    identifier: details || identifier || "",
    unit: details || unit || "",
    notes: notes || "",
    abnormalFlag: ab,
    obx: {
      id: obxId || "",
      bmhCodeName: name,
    },
    isFailed: !!isFailed,
    disableIsFailed: !!failureMap[r.value],
  };
};

export const getInitialValues = (
  targetResult: BloodTestResultWithPathway | null,
  labs: Lab[],
  failureMap: {
    [x: string]: TestFailureCode;
  },
  abnormalMap: {
    [x: string]: TestAbnormalFlag;
  },
  mappedObxCodeNames: {
    [bmhName: string]: MapObxCode;
  },
  relatedPathwaysByLab?: {
    [labId: string]: PathwayRes[];
  },
  isPathway?: boolean,
  pathwayRecord?: Pathway
): FormValues | PathwayFormValues => {
  if (!targetResult) {
    if (isPathway) {
      return {
        name: "",
        showInPortal: true,
        labName: pathwayRecord?.labName || "",
        collectedDate: null,
        collectedTime: null,
        receivedDate: null,
        receivedTime: null,
        reportedDate: null,
        reportedTime: null,
        results: [EMPTY_TEST_ROW],
      };
    }
    return {
      name: "",
      showInPortal: true,
      labName: "",
      collectedDate: null,
      collectedTime: null,
      receivedDate: null,
      receivedTime: null,
      reportedDate: null,
      reportedTime: null,
      results: [EMPTY_TEST_ROW],
      relatedPathwaysByLab: relatedPathwaysByLab || {},
      relatedPathway: null,
    };
  }

  const collected = new Date(targetResult.sampleCollectedOn);
  const reported = new Date(targetResult.resultReportedOn);
  const received = targetResult.sampleReceivedByLabOn
    ? new Date(targetResult.sampleReceivedByLabOn)
    : null;

  if (isPathway) {
    return {
      name: targetResult.name,
      showInPortal: true,
      labName: targetResult.labName,
      collectedDate: collected,
      collectedTime: collected,
      receivedDate: received,
      receivedTime: received,
      reportedDate: reported,
      reportedTime: reported,
      results: targetResult.results.map((item) => {
        return mapBloodTestResultItem(
          item,
          mappedObxCodeNames,
          failureMap,
          abnormalMap
        );
      }),
    };
  }

  const lab = labs.find((l) => {
    return l.name === targetResult.labName;
  });

  return {
    name: targetResult.name,
    showInPortal: !!targetResult.showInPortal,
    labName: targetResult.labName,
    lab,
    collectedDate: collected,
    collectedTime: collected,
    receivedDate: received,
    receivedTime: received,
    reportedDate: reported,
    reportedTime: reported,
    results: targetResult.results.map((item) => {
      return mapBloodTestResultItem(
        item,
        mappedObxCodeNames,
        failureMap,
        abnormalMap
      );
    }),
    relatedPathwaysByLab: relatedPathwaysByLab || {},
    relatedPathway: targetResult.relatedBloodTestOrder as any,
  };
};
