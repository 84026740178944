import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingsActions, settingsSelectors } from "redux/settings";
import { Column, RowActionsEnum } from "types";
import { AppDispatch } from "redux/types";
import { notifications } from "services";
import { getENText } from "helpers";
import { EditableTable } from "components/EditableTable";
import { isBoolean } from "lodash";
import {
  TestAbnormalFlag,
  StatusEnum,
} from "@deep-consulting-solutions/bmh-constants";
import { SettingsContainer } from "components/SettingsContainer";

export const INITIAL_COLUMNS: Column<TestAbnormalFlag>[] = [
  {
    key: "abnormalFlag",
    header: "Abnormal Flag",
    info:
      "Abnormal flag index value. This value represents the flag in the received results files.",
    editable: true,
    align: "left",
    autoFocus: true,
    required: true,
  },
  {
    key: "meaning",
    header: "Meaning",
    info:
      "Explanation of the abnormal flag. It will be shown in test results records, PDF Files and Customer Portal.",
    editable: true,
    align: "left",
    required: true,
  },
  {
    key: "isHighlighted",
    header: "Highlight in Red",
    info:
      "Whether the abnormal flag must be highlighted in red in test results.",
    isBoolean: true,
    editable: true,
    align: "center",
    required: false,
  },
];

const TestAbnormalFlags = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const rows = useSelector(
    settingsSelectors.testAbnormalFlagSelectors.selectAll
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(settingsActions.getTestAbnormalFlag());
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTestAbnormalFlag = async (data: TestAbnormalFlag) => {
    setLoading(true);
    const res = await dispatch(settingsActions.updateTestAbnormalFlag(data));
    setLoading(false);
    const successful = settingsActions.updateTestAbnormalFlag.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.updated"));
    }
    return successful;
  };

  const createTestAbnormalFlag = async (data: Omit<TestAbnormalFlag, "id">) => {
    setLoading(true);
    const res = await dispatch(settingsActions.createTestAbnormalFlag(data));
    setLoading(false);
    const successful = settingsActions.createTestAbnormalFlag.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.created"));
    }
    return successful;
  };

  const deleteTestAbnormalFlag = async (id: string) => {
    setLoading(true);
    const res = await dispatch(settingsActions.deleteTestAbnormalFlag({ id }));
    setLoading(false);
    const successful = settingsActions.deleteTestAbnormalFlag.fulfilled.match(
      res
    );
    if (successful) {
      notifications.notifySuccess(getENText("settings.noti.success.deleted"));
    }
    return successful;
  };

  const changeTestAbnormalFlagStatus = async (data: TestAbnormalFlag) => {
    setLoading(true);
    const res = await dispatch(
      settingsActions.changeTestAbnormalFlagStatus({
        id: data.id,
        status: data.status,
      })
    );
    setLoading(false);
    const successful = settingsActions.changeTestAbnormalFlagStatus.fulfilled.match(
      res
    );
    if (successful) {
      const action =
        data.status === StatusEnum.Active ? "Deactivated" : "Activated";
      notifications.notifySuccess(
        getENText("settings.noti.success.changeStatus", {
          action,
        })
      );
    }
    return successful;
  };

  const validateCell = useCallback(
    (
      row: TestAbnormalFlag,
      val: string | boolean,
      dataKey: keyof TestAbnormalFlag,
      required: boolean
    ) => {
      let error = false;
      let helperText = "";
      if (isBoolean(val)) {
        return {
          error,
          helperText,
        };
      }
      if (!val && required) {
        return {
          error: true,
          helperText: getENText("settings.field.required"),
        };
      }
      if (
        val &&
        rows
          .map(
            (r) =>
              r.id !== row.id && ((r[dataKey] as string) || "").toLowerCase()
          )
          .includes(val.toLowerCase())
      ) {
        error = true;
        helperText = getENText("settings.field.valueExist");
      }

      return {
        error,
        helperText,
      };
    },
    [rows]
  );

  return (
    <SettingsContainer>
      <EditableTable<TestAbnormalFlag>
        hasStatusFilter
        actions={[RowActionsEnum.Edit, RowActionsEnum.ChangeStatus]}
        handlers={{
          onRowUpdate: updateTestAbnormalFlag,
          onRowCreate: createTestAbnormalFlag,
          onRowDelete: deleteTestAbnormalFlag,
          onRowChangeSatus: changeTestAbnormalFlagStatus,
          onAcknowledgeUpdate: updateTestAbnormalFlag,
        }}
        title="Set Up Test Abnormal Flags"
        description="Edit or Add Abnormal Flags."
        loading={loading}
        columns={INITIAL_COLUMNS}
        rows={rows}
        entity="Abnormal Flag"
        validateCell={validateCell}
        inactiveText="Inactive Abnormal Flag won’t be active in the system and won’t be shown in Active Abnormal Flags Tab. However, Abnormal Flag will be activated automatically in case such flag is received in test result files."
      />
    </SettingsContainer>
  );
};

export default TestAbnormalFlags;
