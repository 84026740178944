import { FETestObxCode, FETestObxCodeLab } from "types";
import type { FormValues } from "./Row/FormRow";

export const composeObxCodeBodyFromFormValues = (data: FormValues) => {
  return {
    bmhName: data.bmhCodeName,
    labCodes: data.labs.map((lab) => {
      return {
        labKey: lab.key,
        code: lab.code,
        ...(lab.isManual
          ? {
              unit: lab.unit,
              observationAltIdentifier: lab.observationAltIdentifier,
              normalRange: lab.normalRange,
            }
          : {}),
      };
    }),
  };
};

export const getAcknowledgeLabs = (row: FETestObxCode) => {
  const labs: { [labKey: string]: FETestObxCodeLab } = {};
  Object.entries(row.labs).forEach(([labKey, lab]) => {
    labs[labKey] = {
      ...lab,
      isUpdated: false,
    };
  });
  return labs;
};

export interface MappedCodeNames {
  bmhCodeNames: { [id: string]: string };
  labs: {
    [labKey: string]: { [id: string]: string };
  };
}

export const getMappedCodeNames = (rows: FETestObxCode[]) => {
  const map: MappedCodeNames = {
    bmhCodeNames: {},
    labs: {},
  };

  rows.forEach(({ id, bmhCodeName, labs }) => {
    map.bmhCodeNames[id] = bmhCodeName.toLowerCase();
    Object.entries(labs).forEach(([labKey, lab]) => {
      if (!map.labs[labKey]) map.labs[labKey] = {};
      map.labs[labKey][id] = lab.code.toLowerCase();
    });
  });

  return map;
};
