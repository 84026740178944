import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import WarningIcon from "@material-ui/icons/Warning";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import { FieldArray, getIn, useFormikContext } from "formik";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { CustomNumberField } from "components/CustomNumberField";
import { CustomSelectField } from "components/CustomSelectField";
import { CustomTextareaField } from "components/CustomTextareaField";
import { defaultColumns, defaultColumnsAlt, durationUnits } from "./constants";
import { InitialValues, TableRowsProps } from "./types";
import { BundleDetails } from "./BundleDetails";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {
    width: "100%",
    margin: s(2, 0),
  },
  dialogCloseButton: {
    position: "absolute",
    right: s(1),
    top: s(1),
    color: p.grey[500],
  },
}));

const TableRows = ({ columns, handleButtonClick }: TableRowsProps) => {
  const { values, setFieldValue } = useFormikContext<InitialValues>();
  return (
    <FieldArray name="formattedOrderItems">
      {() => (
        <>
          {values.formattedOrderItems.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  const remainingQty = row.remainingQty;
                  return (
                    <TableCell key={column.id}>
                      {column.id === "prescriptionTotalQty" && (
                        <Box width="70px">
                          <CustomNumberField
                            name={`formattedOrderItems.${index}.prescriptionTotalQty`}
                            handleChange={(currentValue) => {
                              const currentPrescriptionTotalQtyFieldName = `formattedOrderItems.${index}.prescriptionTotalQty`;
                              const currentPrescriptionTotalDispensedQtyFieldName = `formattedOrderItems.${index}.prescriptionTotalDispensedQty`;
                              const remainingQtyFieldName = `formattedOrderItems.${index}.remainingQty`;
                              if (currentValue < 1) {
                                setFieldValue(
                                  currentPrescriptionTotalQtyFieldName,
                                  1
                                );
                                return;
                              }

                              const currentPrescriptionTotalDispensedQty = getIn(
                                values,
                                `formattedOrderItems.${index}.prescriptionTotalDispensedQty`
                              ) as number;

                              if (
                                currentPrescriptionTotalDispensedQty ===
                                undefined
                              ) {
                                setFieldValue(
                                  currentPrescriptionTotalDispensedQtyFieldName,
                                  0
                                );
                                setFieldValue(
                                  remainingQtyFieldName,
                                  currentValue
                                );
                              }

                              if (
                                currentValue <
                                currentPrescriptionTotalDispensedQty
                              ) {
                                setFieldValue(
                                  currentPrescriptionTotalQtyFieldName,
                                  currentPrescriptionTotalDispensedQty
                                );
                                return;
                              }

                              const currentPrescriptionTotalQty = currentValue;
                              if (
                                currentPrescriptionTotalQty > 0 &&
                                currentPrescriptionTotalDispensedQty >= 0
                              ) {
                                const currentRemainingQty =
                                  currentPrescriptionTotalQty -
                                  currentPrescriptionTotalDispensedQty;
                                const val =
                                  currentRemainingQty >= 0
                                    ? currentRemainingQty
                                    : 0;
                                setFieldValue(remainingQtyFieldName, val);
                              }
                            }}
                          />
                        </Box>
                      )}
                      {column.id === "prescriptionTotalDispensedQty" && (
                        <Box width="70px">
                          <CustomNumberField
                            name={`formattedOrderItems.${index}.prescriptionTotalDispensedQty`}
                            handleChange={(currentValue) => {
                              const currentPrescriptionTotalDispensedQtyFieldName = `formattedOrderItems.${index}.prescriptionTotalDispensedQty`;
                              const currentPrescriptionTotalQtyFieldName = `formattedOrderItems.${index}.prescriptionTotalQty`;
                              const remainingQtyFieldName = `formattedOrderItems.${index}.remainingQty`;
                              if (currentValue < 0) {
                                setFieldValue(
                                  currentPrescriptionTotalDispensedQtyFieldName,
                                  0
                                );
                                return;
                              }

                              const currentPrescriptionTotalQty = getIn(
                                values,
                                currentPrescriptionTotalQtyFieldName
                              ) as number;

                              if (currentPrescriptionTotalQty === undefined) {
                                setFieldValue(
                                  currentPrescriptionTotalQtyFieldName,
                                  currentValue
                                );
                                setFieldValue(remainingQtyFieldName, 0);
                              }

                              if (currentValue > currentPrescriptionTotalQty) {
                                setFieldValue(
                                  currentPrescriptionTotalDispensedQtyFieldName,
                                  currentPrescriptionTotalQty
                                );
                              }

                              const currentPrescriptionTotalDispensedQty = currentValue;
                              if (
                                currentPrescriptionTotalDispensedQty >= 0 &&
                                currentPrescriptionTotalQty > 0
                              ) {
                                const currentRemainingQty =
                                  currentPrescriptionTotalQty -
                                  currentPrescriptionTotalDispensedQty;
                                const val =
                                  currentRemainingQty >= 0
                                    ? currentRemainingQty
                                    : 0;
                                setFieldValue(remainingQtyFieldName, val);
                              }
                            }}
                          />
                        </Box>
                      )}
                      {column.id === "duration" && value && <>{value}</>}
                      {column.id === "duration" && !value && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap="16px"
                        >
                          <CustomNumberField
                            name={`formattedOrderItems.${index}.durationPeriod`}
                            label="Period"
                          />
                          <CustomSelectField
                            name={`formattedOrderItems.${index}.durationUnit`}
                            options={durationUnits}
                            label="Units"
                          />
                        </Box>
                      )}
                      {column.id === "usageNotes" && (
                        <CustomTextareaField
                          name={`formattedOrderItems.${index}.usageNotes`}
                          rows={4}
                        />
                      )}
                      {column.id === "remainingQty" && (
                        <Box width="70px" display="flex" alignItems="center">
                          {remainingQty === 0 && <WarningIcon color="error" />}
                          <Typography
                            color={remainingQty === 0 ? "error" : "initial"}
                            style={{ marginLeft: "8px", fontSize: "20px" }}
                          >
                            {value}
                          </Typography>
                        </Box>
                      )}
                      {column.id === "hasBundle" && value && (
                        <Button
                          color="primary"
                          style={{
                            textTransform: "capitalize",
                            minWidth: "120px",
                            fontSize: "13px",
                          }}
                          variant="contained"
                          onClick={() =>
                            handleButtonClick(
                              `formattedOrderItems.${index}.bundleDetails`
                            )
                          }
                        >
                          Bundle Details
                        </Button>
                      )}
                      {["itemName", "quantityInOrder"].includes(column.id) && (
                        <>{value}</>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </>
      )}
    </FieldArray>
  );
};

export function OrderItemsTable() {
  const [bundleDetailsFieldname, setBundleDetailsFieldname] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { values } = useFormikContext<InitialValues>();

  const hasActions = values.formattedOrderItems.some((i) => i.hasBundle);

  const cols = hasActions ? defaultColumnsAlt : defaultColumns;

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const updateBundleDetails = useCallback(() => {
    setOpen(false);
  }, []);

  const handleBundleDetailsButtonClick = useCallback((fieldName: string) => {
    setBundleDetailsFieldname(fieldName);
    setOpen(true);
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {cols.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRows
                columns={cols}
                handleButtonClick={handleBundleDetailsButtonClick}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={open} onClose={closeDialog} maxWidth="xl" fullWidth>
          <DialogTitle>
            <Typography style={{ fontWeight: "bold" }}>
              Bundle Details
            </Typography>
            <IconButton
              className={classes.dialogCloseButton}
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BundleDetails fieldName={bundleDetailsFieldname} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={closeDialog}>
              Close
            </Button>
            <Button color="primary" onClick={updateBundleDetails}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
}
