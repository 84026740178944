import {
  IconButton,
  Menu,
  MenuItem,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { memo, useCallback, useState } from "react";
import { Lab } from "@deep-consulting-solutions/bmh-constants";
import { CheckCircle, MoreHoriz } from "@material-ui/icons";

import { FETestObxCode, StatusEnum } from "types";
import { StyledChip } from "components/StyledChip";

import { StyledIconButton } from "components/StyledIconButton";
import { ActionTableCell, TableCell } from "../styles";
import { FormRow, FormValues } from "./FormRow";
import { MappedCodeNames } from "../helpers";

interface Props {
  row: FETestObxCode;
  labs: Lab[];
  isEditing: boolean;
  onEditingRowUpdate: (rowID: string | null) => any;
  onSaveClick: (values: FormValues, row: FETestObxCode) => any;
  onDeactivateClick: (row: FETestObxCode) => any;
  onActivateClick: (row: FETestObxCode) => any;
  onDeleteClick: (row: FETestObxCode) => any;
  onAcknowledgeUpdateClick: (row: FETestObxCode) => any;
  mappedCodeNames: MappedCodeNames;
}

export const Row: React.FC<Props> = ({
  row,
  labs,
  isEditing,
  onEditingRowUpdate,
  onSaveClick,
  onDeactivateClick,
  onActivateClick,
  onDeleteClick,
  onAcknowledgeUpdateClick,
  mappedCodeNames,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEditClick = useCallback(() => {
    onEditingRowUpdate(row.id);
    handleMenuClose();
  }, [onEditingRowUpdate, row.id, handleMenuClose]);

  const handleCancelClick = useCallback(() => {
    onEditingRowUpdate(null);
  }, [onEditingRowUpdate]);

  const handleDeactivateClick = useCallback(() => {
    onDeactivateClick(row);
    handleMenuClose();
  }, [onDeactivateClick, row, handleMenuClose]);

  const handleActivateClick = useCallback(() => {
    onActivateClick(row);
    handleMenuClose();
  }, [onActivateClick, row, handleMenuClose]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(row);
    handleMenuClose();
  }, [onDeleteClick, row, handleMenuClose]);

  const handleAcknowledgeUpdateClick = useCallback(() => {
    onAcknowledgeUpdateClick(row);
  }, [onAcknowledgeUpdateClick, row]);

  if (isEditing) {
    return (
      <FormRow
        labs={labs}
        row={row}
        onUpdateSaveClick={onSaveClick}
        onCancelClick={handleCancelClick}
        mappedCodeNames={mappedCodeNames}
      />
    );
  }

  return (
    <TableRow>
      <TableCell>{row.bmhCodeName}</TableCell>
      {labs.map((lab) => {
        const {
          observationAltIdentifier,
          unit,
          normalRange,
          code,
          isUpdated,
        } = row.labs[lab.key] || {
          code: "",
          normalRange: "",
          unit: "",
          observationAltIdentifier: "",
        };
        return (
          <React.Fragment key={lab.id}>
            <TableCell>
              {isUpdated ? (
                <StyledChip color="success">{code}</StyledChip>
              ) : (
                <>{code}</>
              )}
            </TableCell>
            <TableCell>{observationAltIdentifier}</TableCell>
            <TableCell>{normalRange}</TableCell>
            <TableCell>{unit}</TableCell>
          </React.Fragment>
        );
      })}
      <ActionTableCell align="center">
        {row.hasUpdated && (
          <Tooltip title="Okay">
            <StyledIconButton
              color="success"
              aria-label="okay"
              onClick={handleAcknowledgeUpdateClick}
            >
              <CheckCircle />
            </StyledIconButton>
          </Tooltip>
        )}
        {!row.hasUpdated && (
          <>
            <Tooltip title="Actions">
              <IconButton
                aria-label="actions"
                aria-controls="actions-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                size="small"
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              {row.status === StatusEnum.Active && (
                <MenuItem onClick={handleDeactivateClick}>Deactivate</MenuItem>
              )}
              {row.status === StatusEnum.Inactive && (
                <MenuItem onClick={handleActivateClick}>Activate</MenuItem>
              )}
              {row.isDeletable && (
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
              )}
            </Menu>
          </>
        )}
      </ActionTableCell>
    </TableRow>
  );
};

export const MemoRow = memo(Row);
