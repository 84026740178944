import React, { memo, useState, useCallback } from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";

import Loader from "components/Loader";

import { Close } from "@material-ui/icons";
import { DoneIcon, WarningIcon } from "../Unmatched.styles";

const useStyle = makeStyles(({ palette: p, spacing: s }) => ({
  text: {
    color: p.grey[600],
  },
  actions: {
    padding: s(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    padding: s(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  subWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subtext: {
    marginTop: s(2),
  },
  subBtn: {
    marginTop: s(2),
  },
  append: {
    padding: s(2, 4),
    width: s(25),
  },
  wipe: {
    padding: s(2, 4),
    width: s(25),
    color: "white",
    background: p.error.main,
  },
  warning: {
    color: p.error.main,
  },
  wipeGroup: {
    marginTop: s(4),
  },
  flexOne: {
    flex: 1,
  },
  content: {
    padding: s(2, 4),
  },
}));

interface ReplaceOldFileDialogProps extends DialogProps {
  handleWipeAndRewriteOldFile: () => Promise<boolean>;
  handleAppendOldFile: () => Promise<boolean>;
  onClose: () => any;
}

const ReplaceOldFileDialog: React.FC<ReplaceOldFileDialogProps> = ({
  handleAppendOldFile,
  handleWipeAndRewriteOldFile,
  open,
  onClose,
  ...props
}) => {
  const [loadingState, setLoadingState] = useState(false);
  const [choice, setChoice] = useState<"append" | "wipe">("append");
  const [isSuccessful, setIsSuccessful] = useState<boolean | undefined>();

  const onCloseClick = useCallback(() => {
    onClose();
    setTimeout(() => {
      // reset state shortly after close
      setIsSuccessful(undefined);
      setChoice("append");
      setLoadingState(false);
    }, 500);
  }, [onClose]);

  const onAppendOldFile = useCallback(async () => {
    setLoadingState(true);
    setChoice("append");
    const isOk = await handleAppendOldFile();
    setLoadingState(false);
    setIsSuccessful(isOk);
  }, [handleAppendOldFile]);

  const onWipeAndRewriteOldFile = useCallback(async () => {
    setLoadingState(true);
    setChoice("wipe");
    const isOk = await handleWipeAndRewriteOldFile();
    setLoadingState(false);
    setIsSuccessful(isOk);
  }, [handleWipeAndRewriteOldFile]);

  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onCloseClick} maxWidth="lg" {...props}>
      <Loader open={loadingState} />

      {isSuccessful === undefined && (
        <>
          <DialogTitle disableTypography className={classes.title}>
            <div className={classes.flexOne} />
            <Typography variant="h5" align="center" className={classes.flexOne}>
              Select Overwrite Option
            </Typography>
            <Grid
              container
              justify="flex-end"
              className={classes.flexOne}
              direction="row"
              alignItems="center"
            >
              <Close onClick={onCloseClick} />
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Grid container justify="space-between" direction="row" spacing={2}>
              <Grid item xs={5}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <Button
                      className={classes.append}
                      disabled={loadingState}
                      onClick={onAppendOldFile}
                      color="primary"
                    >
                      Append
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" className={classes.text}>
                  This will add new file&apos;s results to the results already
                  in system.
                  <br />
                  New file&apos;s results values will be added.
                  <br />
                  Conflicting results values (for the same code) will be
                  overwritten with new file&apos;s results, however
                  non-conflicting values from old file will remain as is.
                  <br />
                  <i>
                    <u>
                      Use this option when lab splits one order into multiple
                      result files
                    </u>
                  </i>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              direction="row"
              className={classes.wipeGroup}
              spacing={2}
            >
              <Grid item xs={5}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <Button
                      className={classes.wipe}
                      disabled={loadingState}
                      onClick={onWipeAndRewriteOldFile}
                    >
                      Wipe and Rewrite
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" className={classes.text}>
                  This will completely wipe the old file&apos;s results and put
                  the new file&apos;s results in their place.
                  <br />
                  All old file&apos;s results will be <b>completely removed</b>,
                  even the non conflicting ones. The new file&apos;s results
                  will be added in their place.
                  <br />
                  <i>
                    <u>
                      Use this option only if the old file&apos;s results were
                      erroneous and need to be discarded completely
                    </u>
                  </i>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Typography
              variant="body2"
              align="center"
              className={classes.warning}
              color="secondary"
            >
              Warning: Please assess carefully which option to take. This
              actions taken upon selection will be irreversible
            </Typography>
          </DialogActions>
        </>
      )}

      {isSuccessful !== undefined && (
        <DialogContent className={classes.subWrapper}>
          {isSuccessful === true ? <DoneIcon /> : <WarningIcon />}
          <Typography variant="body2" className={classes.subtext}>
            {isSuccessful === true
              ? `${
                  choice === "append"
                    ? "File was successfully appended"
                    : "File was successfully rewritten"
                }`
              : "Something went wrong!"}
          </Typography>
          <Button
            color="primary"
            className={classes.subBtn}
            onClick={onCloseClick}
          >
            {isSuccessful === true ? "GOT IT!" : "BACK"}
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default memo(ReplaceOldFileDialog);
