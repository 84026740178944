import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { getFieldName } from "helpers";
import React, { useMemo } from "react";

interface Props {
  name: string;
  label: string;
  namePrefix?: string;
  required?: boolean;
}

export const TextInputField = ({
  name,
  namePrefix,
  label,
  required = true,
}: Props) => {
  const fieldName = useMemo(() => getFieldName(name, namePrefix), [
    name,
    namePrefix,
  ]);

  return (
    <Field
      component={TextField}
      name={fieldName}
      label={label}
      required={required}
      type="text"
      size="small"
      fullWidth
    />
  );
};
