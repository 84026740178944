import { useContext } from "react";
import { WidgetLayoutContext } from "./context";

export const useWidgetLayout = () => {
  const {
    setActionsDimension,
    setTitleDimension,
    setContentDimension,
  } = useContext(WidgetLayoutContext);

  return {
    setActionsDimension,
    setTitleDimension,
    setContentDimension,
  };
};
