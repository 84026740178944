import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Lab,
  BloodDrawer,
  PathwayValidationErrorCodes,
} from "@deep-consulting-solutions/bmh-constants";
import { Dialog } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { pathwayRequests } from "redux/pathway";
import ErrorMessage from "components/ErrorMessage";
import Loader from "components/Loader";
import { Contact, ZohoPathway } from "types";
import { zohoServices } from "services";
import { fetchClientsFromZohoIDs } from "redux/contacts/requests";

import { ZohoPathwayTestProfile, TestProfilesObrWithMappedLabs } from "./types";
import {
  getIsCountryAllowed,
  getTestProfilesFromPathwayTestProfiles,
  mapLabsInTestProfiles,
} from "./helpers";
import { NonGuidedSpecifyingOfDetails } from "./NonGuidedSpecifyingOfDetails";
import { GuidedSpecifyingOfDetails } from "./GuidedSpecifyingOfDetails";

const TEST_PROFILES_RELATED_LIST = "Test_Profiles116";

interface Props {
  isBlueprint?: boolean;
}

export const PathwaySpecifyDetails: React.FC<Props> = ({
  isBlueprint = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<Contact | null>(null);
  const [testProfiles, setTestProfiles] = useState<
    TestProfilesObrWithMappedLabs[] | null
  >(null);
  const [labs, setLabs] = useState<Lab[] | null>(null);
  const [bloodDrawers, setBloodDrawers] = useState<BloodDrawer[] | null>(null);
  const [pathwayTestProfileRecords, setPathwayTestProfileRecords] = useState<
    ZohoPathwayTestProfile[]
  >([]);

  const ids = useSelector(zohoSelectors.getIds);
  const pathwayZohoId = ids[0];
  const entity = useSelector(zohoSelectors.getEntity);
  const pathways = useSelector(zohoSelectors.getRecords);
  const pathway = (pathways[pathwayZohoId] as unknown) as ZohoPathway;
  const [
    validationError,
    setValidationError,
  ] = useState<PathwayValidationErrorCodes | null>(null);

  const validateValues = useCallback(
    async (
      zohoId: string,
      testProfileIDs: string[],
      labID: string,
      bloodDrawerID: string
    ) => {
      try {
        await pathwayRequests.validatePathwayDetails({
          zohoId,
          ids: {
            testProfileIDs,
            labID,
            bloodDrawerID,
          },
        });
      } catch (error: any) {
        const message =
          error?.response?.data?.message || error?.message || "Unknown error";
        setValidationError(message as PathwayValidationErrorCodes);
      }
    },
    []
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const [c, tp, l, bd, ptp] = await Promise.all([
        fetchClientsFromZohoIDs(pathway.Client_Name.id),
        pathwayRequests.getTestProfiles(),
        pathwayRequests.getLabs(),
        pathwayRequests.getBloodDrawers(),
        zohoServices.getRelatedRecords<ZohoPathwayTestProfile>({
          Entity: entity,
          RecordID: pathwayZohoId,
          RelatedList: TEST_PROFILES_RELATED_LIST,
        }),
      ]);
      setClient(c);

      const mappedTestProfiles = mapLabsInTestProfiles(tp).sort((a, b) => {
        return a.bmhObrCode.toLowerCase() < b.bmhObrCode.toLowerCase() ? -1 : 1;
      });

      setTestProfiles(mappedTestProfiles);
      setLabs(l);
      setBloodDrawers(
        bd.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        })
      );

      if (ptp) {
        setPathwayTestProfileRecords(ptp);
      }

      if (pathway.Diagnostic_Laboratory?.id && pathway.Blood_Drawer?.id) {
        const pathWayBloodDrawer = bd.find(
          (b) => b.zohoID === pathway.Blood_Drawer?.id
        );

        const pathWayLab = l.find(
          (d) => d.zohoID === pathway.Diagnostic_Laboratory?.id
        );

        if (pathWayBloodDrawer && pathWayLab) {
          await validateValues(
            pathwayZohoId,
            ptp
              ? mapLabsInTestProfiles(
                  getTestProfilesFromPathwayTestProfiles(
                    ptp,
                    mappedTestProfiles
                  )
                ).map((i) => i.id)
              : [],
            pathWayLab.id,
            pathWayBloodDrawer.id
          );
        }
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [
    entity,
    pathway.Client_Name.id,
    pathwayZohoId,
    validateValues,
    pathway.Diagnostic_Laboratory?.id,
    pathway.Blood_Drawer?.id,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onGuidedSpecifyingOfDetails = useMemo(() => {
    if (!pathway.Created_by_System) {
      return true;
    }

    if (
      !pathway.Blood_Drawer ||
      !pathway.Diagnostic_Laboratory?.id ||
      !pathwayTestProfileRecords.length ||
      validationError ===
        PathwayValidationErrorCodes.CODE_TEST_PROFILE_NOT_ASSOCIATED ||
      validationError ===
        PathwayValidationErrorCodes.CODE_BLOOD_DRAWER_NOT_CORRESPONDING
    ) {
      return true;
    }

    if (labs && pathway?.Diagnostic_Laboratory?.id) {
      const lab = labs.find(
        (l) => l.zohoID === pathway.Diagnostic_Laboratory!.id
      );

      if (lab) {
        if (
          !getIsCountryAllowed(
            client?.shippingAddress.country,
            lab.allowedCountries,
            lab.notAllowedCountries
          )
        ) {
          return true;
        }
      }
    }

    return false;
  }, [
    pathway,
    validationError,
    pathwayTestProfileRecords.length,
    labs,
    client?.shippingAddress.country,
  ]);

  if ((!testProfiles || !labs || !bloodDrawers || !client) && !loading) {
    return (
      <Dialog fullScreen open>
        <ErrorMessage
          message="Failed to get the data necessary for the form, please retry."
          onButtonClick={getData}
        />
      </Dialog>
    );
  }

  if (
    !onGuidedSpecifyingOfDetails &&
    !loading &&
    testProfiles &&
    labs &&
    bloodDrawers &&
    client
  ) {
    return (
      <NonGuidedSpecifyingOfDetails
        isBlueprint={isBlueprint}
        labs={labs}
        bloodDrawers={bloodDrawers}
        client={client}
        testProfiles={testProfiles}
        pathwayTestProfileRecords={pathwayTestProfileRecords}
      />
    );
  }

  if (
    onGuidedSpecifyingOfDetails &&
    !loading &&
    testProfiles &&
    labs &&
    bloodDrawers &&
    client
  ) {
    return (
      <GuidedSpecifyingOfDetails
        labs={labs}
        bloodDrawers={bloodDrawers}
        client={client}
        testProfiles={testProfiles}
        pathwayTestProfileRecords={pathwayTestProfileRecords}
        isBlueprint={isBlueprint}
      />
    );
  }

  return (
    <Dialog fullScreen open>
      <Loader open isFixed />
    </Dialog>
  );
};
