import { silentClient, crhClient, n8nClient } from "apis";
import { AxiosError } from "axios";
import {
  formatDate,
  formatDateToTimeZone,
  formatDuration,
  formatTime,
} from "helpers";
import {
  GetTreatmentsResponse,
  GetCttpResponse,
  SigningRequestPayload,
  SigningResponsePayload,
  Prescription,
  Meeting,
  UserLocation,
  VenesectionSigningRequestPayload,
  SigningResponsePayloadAlt,
} from "types";

const BASE_URL = "/api/v1/db/data/v1/CustomBackend";

const getOneTreatmentQuery = (treatmentId: string) => {
  return `${BASE_URL}/Treatments?where=(ZohoCrmId,eq,${treatmentId})&fields=Id,ZohoCrmId,Name,Type,State,CTTPs List,&nested[CTTPs List][fields]=Id,Name,State,ZohoCrmId`;
};

const getCttpRecordQuery = (treatmentId: string) => {
  return `${BASE_URL}/CTTPs?where=(TreatmentId,eq,${treatmentId})&fields=Id,Name,State,ZohoCrmId`;
};

export async function getTreatment(treatmentId: string) {
  try {
    const response = await crhClient.post<GetTreatmentsResponse>("/crm", {
      url: getOneTreatmentQuery(treatmentId),
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list[0];
  } catch (error) {
    return null;
  }
}

export async function getTreatmentAlt(treatmentId: string) {
  try {
    const response = await crhClient.post<GetTreatmentsResponse>("/crm", {
      url: `${BASE_URL}/Treatments?where=(ZohoCrmId,eq,${treatmentId})&fields=Id,State,Type,ZohoCrmId`,
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list[0];
  } catch (error) {
    return null;
  }
}

export async function getCttpRecord(treatmentId: string) {
  try {
    const response = await crhClient.post<GetCttpResponse>("/crm", {
      url: getCttpRecordQuery(treatmentId),
      app: "NOCODB",
      method: "GET",
    });
    const cttp = response.data.data.list[0];
    if (cttp) {
      cttp.type = "Cttp";
      cttp.id = cttp.ZohoCrmId;
    }
    return cttp;
  } catch (error) {
    return null;
  }
}

export async function getSigningUrl(payload: SigningRequestPayload) {
  try {
    const response = await silentClient.post<SigningResponsePayload>(
      "zoho/crm/cttp-and-prescription-signing-link",
      payload
    );

    return {
      signingUrlResponse: response.data.data.signingUrlResponse,
      message: response.data.message,
      error: false,
    };
  } catch (error) {
    const err = error as AxiosError;
    const message =
      err.response?.data.message ||
      err.message ||
      "Unable to fetch the document at the moment. Please contact support for help.";
    return {
      signingUrlResponse: null,
      message,
      error: true,
    };
  }
}

export async function getSigningUrlAlt(payload: SigningRequestPayload) {
  try {
    const response = await n8nClient.post<SigningResponsePayloadAlt>(
      "/cttp-and-prescription-sign/crm",
      payload
    );

    return response.data;
  } catch (error) {
    return {
      signingUrlResponse: null,
      message:
        "Unable to fetch the document at the moment. Please contact support for help.",
      error: true,
    };
  }
}

export async function getVenesectionTreatmentSigningUrl(
  payload: VenesectionSigningRequestPayload
) {
  try {
    const response = await silentClient.post<SigningResponsePayload>(
      "/zoho/crm/venesection-signing-link",
      payload
    );

    return response.data.data;
  } catch (error) {
    return {
      signingUrlResponse: null,
      message:
        "Unable to fetch the document at the moment. Please contact support for help.",
      error: true,
    };
  }
}

export async function getPrescriptions(treatmentId: string) {
  try {
    const response = await n8nClient.get<{ data: Prescription[] }>(
      `/treatment-prescriptions/${treatmentId}`
    );
    const rawPrescriptions = response.data.data;
    const formattedPrescriptions = rawPrescriptions.map((p) => {
      let label = "Prescription";
      if (p.Prescription_Type === "Pharmacy Prescription") {
        label = `${p.Pharmacy?.name} Prescription`;
      } else if (p.Prescription_Type === "Self-Supplied") {
        label = `Self-Supplied Prescription`;
      }
      return {
        ...p,
        label,
        type: "Prescription",
      };
    });

    return formattedPrescriptions;
  } catch (error) {
    return [];
  }
}

export async function getPermissions(treatmentId: string, userId: string) {
  try {
    const response = await silentClient.get<{
      data: { hasPermissions: boolean };
    }>(`/zoho/check-user-permissions/${treatmentId}?userId=${userId}`);

    return response.data.data.hasPermissions;
  } catch (error) {
    return false;
  }
}

export async function getUpcomingConsultations(treatmentId: string) {
  try {
    const response = await n8nClient.get<{ meeting: Meeting }>(
      `/get-upcoming-consultation/${treatmentId}`
    );

    let consultationText = "";
    const meeting = response.data.meeting;

    if (meeting.StartTime && meeting.EndTime) {
      const timezone = meeting.timezone || "Europe/London";
      const startTime = formatDateToTimeZone(meeting.StartTime, timezone);
      const endTime = formatDateToTimeZone(meeting.EndTime, timezone);

      const formattedStartDate = formatDate(startTime);
      const formattedStartTime = formatTime(startTime);
      const duration = formatDuration(startTime, endTime);

      consultationText = `You have an upcoming consultation with the client on ${formattedStartDate} at ${formattedStartTime} for ${duration}`;
    }

    return consultationText;
  } catch (error) {
    return "";
  }
}

export function getDefaultLocation() {
  return {
    ip: "127.0.0.1",
    latitude: "38.8951",
    longitude: "-77.0364",
    country: "Nigeria",
  } as UserLocation;
}

export async function getLocationData() {
  try {
    const response = await fetch("https://ip-api.io/json");

    if (!response.ok) {
      return getDefaultLocation();
    }

    const {
      latitude,
      longitude,
      ip,
      country_name: country,
    } = (await response.json()) as UserLocation;

    return {
      ip,
      country,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
    } as UserLocation;
  } catch (err) {
    return getDefaultLocation();
  }
}
