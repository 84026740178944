import { FormikHelpers } from "formik";
import { notifications } from "services";
import { ZohoBMHDoctor, ZohoClientRecord } from "types";

export const getContactName = (contact: ZohoClientRecord) => {
  return (
    `${contact.First_Name || ""} ${contact.Last_Name || ""}`.trim() ||
    contact.Full_Name ||
    ""
  );
};

export const getDoctorName = (doctor: ZohoBMHDoctor) => {
  return (
    doctor.Prescription_Name ||
    `${doctor.First_Name || ""} ${doctor.Last_Name || ""}`.trim() ||
    doctor.Name ||
    ""
  );
};

export const clientRequiredFieldsLabelMap = {
  shippingStreet: "Shipping Street",
  shippingCity: "Shipping City",
  shippingState: "Shipping Province",
  shippingZip: "Shipping Post Code",
  shippingCountry: "Shipping Country",
  phone: "Phone",
};

type ClientRequiredFields = {
  shippingStreet: string | null;
  shippingCity: string | null;
  shippingState: string | null;
  shippingZip: string | null;
  shippingCountry: string | null;
  phone: string | null;
};

export const getMissingRequiredFields = (client: ZohoClientRecord | null) => {
  if (!client) {
    return [];
  }
  const requiredFields = {
    shippingStreet: client.Shipping_Street,
    shippingCity: client.Shipping_City,
    shippingState: client.Shipping_State,
    shippingZip: client.Shipping_Zip,
    shippingCountry: client.Shipping_Country,
    phone: client.Phone || client.Mobile,
  };
  return Object.entries(requiredFields).reduce<string[]>((total, [k, v]) => {
    if (v) {
      return total;
    }
    return total.concat(
      clientRequiredFieldsLabelMap[k as keyof ClientRequiredFields]
    );
  }, []);
};

export const validateTreatmentForm = (
  errors: any,
  setFieldTouched: FormikHelpers<any>["setFieldTouched"],
  name = ""
): boolean => {
  if (!errors) {
    return true;
  }
  if (typeof errors === "string" || typeof errors === "number") {
    let element: HTMLInputElement | null = null;
    if (name.includes("diagnosis")) {
      element = document.querySelector(`[name='diagnosis.0.selected']`);
      notifications.notifyError(errors);
    } else {
      element = document.querySelector(`[name='${name}']`);
      setFieldTouched(name, true);
    }
    if (element) {
      element.dispatchEvent(new Event("focus", { bubbles: true }));
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.focus();
    }
    return false;
  }

  if (Array.isArray(errors)) {
    for (let index = 0; index < errors.length; index += 1) {
      if (errors[index]) {
        return validateTreatmentForm(
          errors[index],
          setFieldTouched,
          `${name ? `${name}.` : ""}${index}`
        );
      }
    }
  }

  const errorsArray: any[] = Object.entries(errors);

  for (let index = 0; index < errorsArray.length; index += 1) {
    const [key, err] = errorsArray[index];
    return validateTreatmentForm(
      err,
      setFieldTouched,
      `${name ? `${name}.` : ""}${key}`
    );
  }
  return true;
};
