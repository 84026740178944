import { Box, Chip, TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import {
  getFieldName,
  getValueFromObject,
  isStringEqual,
  sortAlphabetically,
} from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption, ZohoTreatmentArea } from "types";
import { AutocompleteMultipleFreeSoloField } from "components/AutocompleteMultipleFreeSoloField";

interface Props {
  treatmentArea: ZohoTreatmentArea;
  name?: string;
}

export const MedicineTypesField = ({ treatmentArea, name }: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const medicineTypes = useMemo(() => treatmentArea.medicineType, [
    treatmentArea.medicineType,
  ]);

  const fieldName = useMemo(() => getFieldName("medicinesSelected", name), [
    name,
  ]);

  const fieldTouched = useMemo(
    () => getValueFromObject(fieldName, touched) === true,
    [fieldName, touched]
  );

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);
  const { options, otherOption } = useMemo(() => {
    return sortAlphabetically(
      medicineTypes.map((medicineType) => ({
        title: medicineType.Name,
        value: medicineType.id,
        manual: !!medicineType.Manual,
        usageNotes: medicineType.Default_Usage_Note || "",
        notesForMedicineSelection: "",
        dosingType: medicineType.Default_Dosing_Types?.id
          ? {
              title: medicineType.Default_Dosing_Types?.name,
              value: medicineType.Default_Dosing_Types?.id,
            }
          : null,
        dosingUnit: medicineType.Default_Dosing_Unit?.id
          ? {
              title: medicineType.Default_Dosing_Unit?.name,
              value: medicineType.Default_Dosing_Unit?.id,
            }
          : null,
        noDosageAmount: !!medicineType.No_Dosage_Amount,
      }))
    ).reduce<{
      options: AutoCompleteOption[];
      otherOption: AutoCompleteOption;
    }>(
      (total, current) => {
        if (isStringEqual(current.title, "other")) {
          return { ...total, otherOption: current };
        }
        return { ...total, options: total.options.concat(current) };
      },
      {
        options: [],
        otherOption: { title: "", value: "" },
      }
    );
  }, [medicineTypes]);

  const mapValues = useCallback((value: AutoCompleteOption[]) => {
    return value.map((v: any) => {
      return {
        ...v,
        specifyDosingInstructions: !!v.specifyDosingInstructions,
        usageNotes: v.usageNotes || "",
        notesForMedicineSelection: v.notesForMedicineSelection || "",
        customDosingInstruction: v.customDosingInstruction || "",
        dosingType: v.dosingType || null,
        dosingUnit: v.dosingUnit || null,
        dosingAmount: v.dosingAmount || null,
        noDosageAmount: !!v.noDosageAmount,
        manual: !!v.manual,
        administrationRoute: v.administrationRoute || null,
        bodyPart: v.bodyPart || "",
        frequencyOfUse: v.frequencyOfUse || null,
        numberOfUse: v.numberOfUse || null,
        timeOfDay: v.timeOfDay || [],
        patternOfUse: v.patternOfUse || null,
        numberOfDays: v.numberOfDays || null,
        cyclicalUseDays: v.cyclicalUseDays || null,
        cyclicalStopDays: v.cyclicalStopDays || null,
      };
    });
  }, []);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        const mappedValues = mapValues(
          value.map((v: any) => {
            if (typeof v === "string") {
              return {
                title: v,
                value: otherOption.value,
              };
            }
            return v;
          })
        );
        if (reason === "select-option") {
          setFieldValue(fieldName, mappedValues);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, mappedValues);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, mappedValues);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, []);
      }
    },
    [fieldName, setFieldValue, otherOption.value, mapValues]
  );

  return (
    <Box mb={2} maxWidth={500}>
      {isStringEqual(treatmentArea.Name, "other") ? (
        <AutocompleteMultipleFreeSoloField
          name={fieldName}
          label="Medicine Suggested"
          otherOptionValue={otherOption.value}
          mapValues={mapValues}
          options={options}
        />
      ) : (
        <Field
          required
          name={fieldName}
          multiple
          component={Autocomplete}
          options={options}
          getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
          onChange={onChange}
          onBlur={() => {
            setFieldTouched(fieldName, true);
          }}
          color="primary"
          renderTags={(value: any[], getTagProps: (param: any) => any) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                label={typeof option === "string" ? option : option.title}
                {...getTagProps({ index })}
              />
            ))
          }
          selectOnFocus
          clearOnBlur
          getOptionSelected={(option: any, value: any) => {
            return option.value === value.value && option.title === value.title;
          }}
          renderOption={(option: any) => option.title}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              required
              name={fieldName}
              variant="outlined"
              label=""
              helperText={
                fieldTouched && typeof fieldError === "string" && fieldError
                  ? fieldError
                  : undefined
              }
              error={
                fieldTouched && typeof fieldError === "string" && !!fieldError
              }
              size="small"
            />
          )}
        />
      )}
    </Box>
  );
};
