import { FormikNumberField } from "components/FormikNumberField";
import { Field } from "formik";
import { getFieldName } from "helpers";
import React from "react";

interface Props {
  name?: string;
}
export const TreatmentPeriodFields = ({ name }: Props) => {
  return (
    <Field
      component={FormikNumberField}
      name={getFieldName("treatmentPeriod", name)}
      label="Number of Months the Prescription Should be Used"
      required
      type="integer"
      size="small"
      fullWidth
      min={1}
      numberFormatProps={{
        fixedDecimalScale: true,
        decimalScale: 0,
      }}
    />
  );
};
