import React, { useCallback } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";

export const CustomCheckboxField = ({
  name,
  label = "",
  disabled = false,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
}) => {
  const { setFieldValue, values, isSubmitting } = useFormikContext();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.target.checked);
    },
    [name, setFieldValue]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={getIn(values, name)}
          onChange={onChange}
          disabled={isSubmitting || disabled}
          color="primary"
        />
      }
      label={label}
    />
  );
};
