import React, { useMemo } from "react";
import moment from "moment";
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import { TwoColumnData } from "components/TwoColumnData";
import { BasicTable } from "components/BasicTable";
import { Details } from "./TreatmentDetails";

interface SignedLegacyPrescription {
  id: string;
  Name: string;
  recordCrmLink: string;
  dateSent: string;
  dateCompleted: string;
}

interface PatientPrescriptionsProps {
  signedLegacyPrescriptions: SignedLegacyPrescription[];
  lastDoctorConsultation: string;
  treatmentStartOfPrescriptionDate: string;
  prescriptions: any[];
}

const useStyle = makeStyles(({ spacing: s }) => ({
  subHeading: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: s(1),
  },
  mb3: {
    marginBottom: s(3),
  },
}));

export const PatientPrescriptions: React.FC<PatientPrescriptionsProps> = ({
  lastDoctorConsultation,
  prescriptions,
  signedLegacyPrescriptions,
  treatmentStartOfPrescriptionDate,
}) => {
  const classes = useStyle();

  const details: Details[] = useMemo(
    () => [
      {
        title: "Treatment Start Of Prescription Date",
        value: (
          <strong>
            {moment(treatmentStartOfPrescriptionDate).format("DD/MM/YYYY")}
          </strong>
        ),
      },
      {
        title: "Last Doctor Consultation",
        value: lastDoctorConsultation ? (
          <strong>{moment(lastDoctorConsultation).format("DD/MM/YYYY")}</strong>
        ) : (
          "N/A"
        ),
      },
    ],
    [lastDoctorConsultation, treatmentStartOfPrescriptionDate]
  );

  const memoizedPrescriptions = useMemo(
    () =>
      prescriptions.map((prescription) => ({
        ...prescription,
        prescription: (
          <a
            href={prescription.recordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {prescription.prescription}
          </a>
        ),
        state: (
          <Chip
            label={prescription.state}
            color={prescription?.state === "Active" ? "primary" : "default"}
          />
        ),
        legacyPrescription: prescription.legacyPrescription ? (
          <a
            href={prescription.legacyRecordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {prescription.legacyPrescription}
          </a>
        ) : (
          "N/A"
        ),
        signingDate: moment(prescription.signingDate).format("DD/MM/YYYY"),
      })),
    [prescriptions]
  );

  const memoizedLegacyPrescriptions = useMemo(
    () =>
      signedLegacyPrescriptions.map((signedLegacyPrescription) => ({
        ...signedLegacyPrescription,
        name: signedLegacyPrescription.Name,
        Name: (
          <a
            href={signedLegacyPrescription.recordCrmLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {signedLegacyPrescription.Name}
          </a>
        ),
        dateSent: moment(signedLegacyPrescription.dateSent).format(
          "DD/MM/YYYY"
        ),
        dateCompleted: moment(signedLegacyPrescription.dateCompleted).format(
          "DD/MM/YYYY"
        ),
      })),
    [signedLegacyPrescriptions]
  );

  return (
    <>
      <Box className={classes.mb3}>
        <TwoColumnData details={details} />
      </Box>
      <Box className={classes.mb3}>
        <Typography variant="h2" className={classes.subHeading}>
          Prescriptions
        </Typography>
        <BasicTable
          tableHeaders={[
            { title: "Prescription", id: "prescription" },
            { title: "State", id: "state" },
            { title: "Legacy prescription", id: "legacyPrescription" },
            { title: "Signing date", id: "signingDate" },
          ]}
          tableData={memoizedPrescriptions}
          emptyMessage={
            memoizedPrescriptions.length === 0
              ? "No Related New Prescription (This means that no paid orders were migrated to the new system)"
              : ""
          }
        />
      </Box>
      <Box className={classes.mb3}>
        <Typography variant="h2" className={classes.subHeading}>
          Signed Legacy Prescriptions ({signedLegacyPrescriptions.length})
        </Typography>
        <BasicTable
          tableHeaders={[
            { title: "Prescription", id: "Name" },
            { title: "Date sent", id: "dateSent" },
            { title: "Date completed", id: "dateCompleted" },
          ]}
          tableData={memoizedLegacyPrescriptions}
          emptyMessage={
            memoizedLegacyPrescriptions.length === 0
              ? "No Signed Legacy Prescriptions Found"
              : ""
          }
        />
      </Box>
    </>
  );
};
