import { Button, Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getENText } from "helpers";
import React, { useCallback } from "react";

const useStyle = makeStyles(() => ({
  wrapper: {},
  alert: {},
}));

interface Props {
  onClose: (reload?: boolean) => void;
}

export const ConfirmKitPackingSuccess = ({ onClose }: Props) => {
  const classes = useStyle();

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Alert severity="success" className={classes.alert}>
            {getENText("confirmKitPacking.success")}
          </Alert>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            CLOSE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
