import clsx from "clsx";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Column, Data, RowActionsEnum, StatusEnum } from "types";
import { TableHeadCell } from "components/TableHeadCell";
import { debounce, isNumber, isString, orderBy as sortBy } from "lodash";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import TablePagination from "components/TablePagination";
import { Close, Search, Add } from "@material-ui/icons";
import { EditableRow, RowHandlers, TableEntity } from "../EditableRow";

const CONTAINER_PADDING = 48;

interface TableHeightProps {
  tableBarHeight?: number;
  pageHeaderHeight?: number;
  tableWidth?: number;
  hasStickyColumn?: boolean;
}

function getContainerHeight({ pageHeaderHeight }: TableHeightProps) {
  let containerHeightDiff = CONTAINER_PADDING;

  if (pageHeaderHeight) {
    containerHeightDiff += pageHeaderHeight;
  }

  return `calc(100vh - ${containerHeightDiff}px)`;
}

const useStyles = makeStyles<Theme, TableHeightProps>((theme) =>
  createStyles({
    tabPanel: {
      padding: 0,
    },
    tableContainer: {
      position: "relative",
      maxHeight: ({ pageHeaderHeight }) =>
        getContainerHeight({
          pageHeaderHeight,
        }),
      width: "100%",
      overflowX: "auto",
    },
    appBar: {
      position: "sticky",
      top: 0,
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 2),
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    searchField: {
      marginLeft: "auto",
      width: "220px",
    },
    clearSearchBtn: {
      padding: "10px",
    },
    header: {
      padding: theme.spacing(0, 3, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2, 3),
      },
    },
    table: {
      width: "100%",
      "& > * .MuiTableCell-body": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
        },
      },
      "& > * .MuiTableCell-head": {
        padding: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
        },
      },
    },
    inactiveContainer: {
      maxWidth: (props) => props.tableWidth,
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
    selectRows: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: theme.spacing(1),
      },
    },
    th: {
      top: (props) => (props.tableBarHeight ? props.tableBarHeight : 0),
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 3,
    },
    pagination: {
      position: "sticky",
      bottom: 0,
      right: 0,
      background: theme.palette.background.paper,
      zIndex: 1,
    },
  })
);

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50];

interface EditableTableProps<T extends Data> extends TableProps {
  columns: Column<T>[];
  rows: T[];
  validateCell: (
    row: T,
    val: any,
    dataKey: keyof T,
    required: boolean
  ) => {
    error: boolean;
    helperText: string;
  };
  actions: RowActionsEnum[];
  handlers: RowHandlers<T>;
  title: string;
  description: ReactNode;
  entity?: TableEntity;
  hasStatusFilter?: boolean;
  hasSearching?: boolean;
  hasSorting?: boolean;
  hasPagination?: boolean;
  inactiveText?: string;
  disableAddNewButton?: boolean;
}

function EditableTable<T extends Data>({
  actions,
  handlers,
  columns,
  rows,
  validateCell,
  title,
  description,
  entity,
  hasStatusFilter = false,
  hasSearching = false,
  hasSorting = false,
  hasPagination = false,
  inactiveText,
  disableAddNewButton,
  ...tableProps
}: EditableTableProps<T>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const tableBarRef = useRef<HTMLDivElement>(null);
  const pageHeaderRef = useRef<HTMLDivElement>(null);

  const [creating, setCreating] = useState(false);
  const toggleCreating = () => {
    setCreating((prev) => !prev);
  };
  const [shouldResize, setShouldResize] = useState(true);
  const [columnsWidths, setColumnsWidths] = useState<
    Partial<Record<Column<T>["key"], number | undefined>>
  >({});
  const tableWidth = useMemo(() => {
    if (Object.keys(columnsWidths).length && tableRef.current) {
      return tableRef.current.clientWidth;
    }
    return undefined;
  }, [columnsWidths]);

  const [pageHeaderHeight, setPageHeaderHeight] = useState<number | undefined>(
    undefined
  );
  const [tableBarHeight, setTableBarHeight] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (shouldResize) {
      if (pageHeaderRef.current) {
        setPageHeaderHeight(pageHeaderRef.current.clientHeight + 1);
      }
      if (tableBarRef.current) {
        setTableBarHeight(tableBarRef.current.clientHeight + 1);
      }
    }
  }, [shouldResize]);

  const hasStickyColumn = useMemo(
    () => columns.some((column) => column.stickyLeft),
    [columns]
  );

  const classes = useStyles({
    tableBarHeight,
    pageHeaderHeight,
    tableWidth,
    hasStickyColumn,
  });

  const onResize = useCallback(() => {
    if (rows.length && columns.length && tableRef.current) {
      const widths: Partial<Record<Column<T>["key"], number | undefined>> = {};
      tableRef.current.querySelectorAll("tr").forEach((tRow, i) => {
        if (i === 0) {
          Array.from(tRow.querySelectorAll("th"))
            .slice(0, -1)
            .forEach((tHead, j) => {
              const key = columns[j].key;
              const cellWidth = tHead.clientWidth;
              const prevWidth = (columnsWidths[key] || 0) as number;
              widths[key] = Math.max(prevWidth, cellWidth);
            });
        } else {
          Array.from(tRow.querySelectorAll("td"))
            .slice(0, -1)
            .forEach((tCell, j) => {
              const key = columns[j].key;
              const cellWidth = tCell.clientWidth;
              const prevWidth = (columnsWidths[key] || 0) as number;
              widths[key] = Math.max(prevWidth, cellWidth) + 1;
            });
        }
      });
      setColumnsWidths(widths);
      setShouldResize(false);
    }
  }, [columns, columnsWidths, rows.length]);

  useLayoutEffect(() => {
    const debouncedResetWidths = debounce(() => {
      setColumnsWidths({});
      setShouldResize(true);
    }, 250);

    window.addEventListener("resize", debouncedResetWidths);

    return () => {
      window.removeEventListener("resize", debouncedResetWidths);
    };
  }, []);

  useLayoutEffect(() => {
    if (shouldResize) {
      onResize();
    }
  }, [onResize, shouldResize]);

  const [status, setStatus] = useState<StatusEnum | boolean>(
    hasStatusFilter && StatusEnum.Active
  );
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState<Column<T>["key"]>(columns[0].key);
  const [order, setOrder] = useState<"desc" | "asc">("asc");

  const filteredRows = useMemo(() => {
    let r = rows;
    if (status) {
      r = r.filter((row) => row.status === status);
    }
    if (hasSearching && search) {
      r = r.filter((row) =>
        columns.some((col) => {
          const val = row[col.key];
          if (
            isString(val) &&
            (val as string).toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          }
          if (isNumber(val) && val === Number(search)) {
            return true;
          }
          return false;
        })
      );
    }
    if (hasSorting) {
      r = sortBy(
        r,
        (item) => {
          if (isNumber(item[orderBy])) {
            return item[orderBy];
          }

          if (!item[orderBy]) {
            if (order === "asc") {
              return undefined;
            }
            if (order === "desc") {
              return "";
            }
          }

          return item[orderBy];
        },
        order
      );
    }
    return r;
  }, [columns, hasSearching, hasSorting, order, orderBy, rows, search, status]);

  const rowsToDisplay = useMemo(() => {
    if (hasPagination) {
      return filteredRows.slice(page * size, page * size + size);
    }
    return filteredRows;
  }, [filteredRows, hasPagination, page, size]);

  const handleStatusChange = (
    _event: React.ChangeEvent<any>,
    newStatus: StatusEnum
  ) => {
    setStatus(newStatus);
    setPage(0);
  };

  const onChangePage = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSize(Number(e.target.value));
      setPage(0);
    },
    []
  );

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      setPage(0);
    },
    []
  );

  const onClearTextClick = useCallback(() => {
    setSearch("");
    setPage(0);
  }, []);

  const onSortClick = (key: Column<T>["key"]) => () => {
    setOrderBy(key);
    const isAsc = orderBy === key && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
  };

  const TableComponent = (
    <Table
      ref={tableRef}
      className={classes.table}
      stickyHeader
      {...tableProps}
    >
      <TableHead>
        <TableRow>
          {columns.map(({ header, info, align, key, stickyLeft }) => (
            <TableHeadCell
              key={header}
              className={clsx(classes.th, {
                [classes.stickyColumn]: stickyLeft,
              })}
              header={header}
              info={info}
              align={align}
              width={columnsWidths[key]}
              hasSorting={hasSorting}
              sortLabelProps={{
                active: orderBy === key,
                direction: orderBy === key ? order : "asc",
                onClick: onSortClick(key),
              }}
            />
          ))}
          <TableCell className={classes.th} style={{ width: "108px" }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredRows.length ? (
          rowsToDisplay.map((row) => {
            return (
              <EditableRow<T>
                actions={actions}
                handlers={handlers}
                key={row.id}
                columns={columns}
                validate={validateCell}
                row={row}
                columnsWidths={columnsWidths}
                entity={entity}
              />
            );
          })
        ) : (
          <>
            {!creating && (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  No records to display
                </TableCell>
              </TableRow>
            )}
          </>
        )}
        {creating && handlers.onRowCreate && (
          <EditableRow<T>
            actions={actions}
            handlers={handlers}
            columns={columns}
            validate={validateCell}
            row={columns.reduce((currentRow, col) => {
              return {
                ...currentRow,
                [col.key]: col.isBoolean ? false : "",
              };
            }, {} as T)}
            creating={creating}
            onCancel={toggleCreating}
            columnsWidths={columnsWidths}
            entity={entity}
          />
        )}
      </TableBody>
    </Table>
  );

  const SearchComponent = (
    <TextField
      className={classes.searchField}
      placeholder="Search"
      fullWidth={false}
      value={search}
      onChange={onSearchChange}
      InputProps={{
        margin: "dense",
        "aria-label": "Clear",
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: !!search && (
          <InputAdornment position="end">
            <IconButton
              className={classes.clearSearchBtn}
              aria-label="search"
              edge="end"
              onClick={onClearTextClick}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <>
      {title && (
        <div ref={pageHeaderRef} className={classes.header}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" component="h1" paragraph>
              {title}
            </Typography>
            {handlers.onRowCreate && (
              <Button
                onClick={toggleCreating}
                color="primary"
                disabled={creating}
              >
                {entity ? `Add New ${entity}` : "Add"}
              </Button>
            )}
          </Box>
          <Typography>{description}</Typography>
        </div>
      )}

      <Divider />
      <TableContainer component={Paper} className={classes.tableContainer}>
        {status ? (
          <TabContext value={status as string}>
            <AppBar
              ref={tableBarRef}
              className={classes.appBar}
              position="static"
              color="inherit"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TabList
                  onChange={handleStatusChange}
                  aria-label="status tabs"
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab label={StatusEnum.Active} value={StatusEnum.Active} />
                  <Tab
                    label={StatusEnum.Inactive}
                    value={StatusEnum.Inactive}
                  />
                </TabList>
                {hasSearching && SearchComponent}
              </Box>
            </AppBar>
            {inactiveText && status === StatusEnum.Inactive && (
              <>
                <div className={classes.inactiveContainer}>
                  <Typography variant="subtitle2" paragraph>
                    Inactive Explanation
                  </Typography>
                  <Typography variant="body2">{inactiveText}</Typography>
                </div>
                <Divider />
              </>
            )}
            <TabPanel className={classes.tabPanel} value={status as string}>
              {TableComponent}
            </TabPanel>
          </TabContext>
        ) : (
          <>
            {hasSearching && (
              <AppBar
                ref={tableBarRef}
                className={classes.appBar}
                position="static"
                color="inherit"
              >
                <div>{SearchComponent}</div>
              </AppBar>
            )}
            {TableComponent}
          </>
        )}
        {hasPagination && (
          <TablePagination
            className={classes.pagination}
            component="div"
            count={filteredRows.length}
            page={page}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={onChangePage}
            rowsPerPage={size}
            onChangeRowsPerPage={onChangeRowsPerPage}
            SelectProps={{
              fullWidth: false,
              className: classes.selectRows,
            }}
          />
        )}
      </TableContainer>
      {handlers.onRowCreate && (
        <Box mt={2} textAlign="right">
          <Button
            startIcon={<Add />}
            onClick={toggleCreating}
            color="primary"
            disabled={creating || disableAddNewButton}
          >
            New
          </Button>
        </Box>
      )}
    </>
  );
}

export default EditableTable;
