import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { DispenseOrderPages } from "../types";

interface CurrentViewProps {
  currentView: DispenseOrderPages;
}

export const CurrentView: React.FC<CurrentViewProps> = ({ currentView }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("currentPage", currentView);
  }, [currentView, setFieldValue]);

  return null;
};
