import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";

import React from "react";
import { RetestErrorEnum } from "../types";
import { InfoComponent } from "./InfoComponent";

interface Props {
  isFingerprick: boolean;
  variant: RetestErrorEnum;
}

export const FormComponent = ({ isFingerprick, variant }: Props) => {
  return (
    <>
      <Typography variant="subtitle2">
        Decision: Retest Required - {variant}
      </Typography>

      <Box mt={2}>
        <Field
          component={TextField}
          name="reason"
          label="Retest Decision Explanation and Failure Reason*"
          placeholder="Explain Retest Decision and Reason Selection"
          multiline
          rows={5}
        />
      </Box>
      {isFingerprick && (
        <Box mt={2}>
          <Field
            name="changeBloodTakingOptionFromFingerPrickToVenepuncture"
            component={CheckboxWithLabel}
            Label={{
              label:
                "Change Blood Taking option from Fingerprick to Venepuncture",
            }}
            color="primary"
          />
        </Box>
      )}
      <InfoComponent variant={variant} />
    </>
  );
};
