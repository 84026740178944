import { TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  createFilterOptions,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import {
  getFieldName,
  getValueFromObject,
  isStringEqual,
  sortAlphabetically,
} from "helpers";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  AutoCompleteOption,
  ZohoDosingAdminRoute,
  ZohoMedicineAdminRoute,
} from "types";

interface Props {
  name?: string;
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}

interface FieldOptionType extends AutoCompleteOption {
  inputValue?: string;
}

const filter = createFilterOptions<FieldOptionType>();

export const AdministrationRouteField = ({
  dosingAdminRoutes,
  name,
  medicineAdminRoutes,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("administrationRoute", name), [
    name,
  ]);

  const dosingTypeFieldName = useMemo(() => getFieldName("dosingType", name), [
    name,
  ]);

  const dosingTypeFieldValue = useMemo(
    () => getValueFromObject(dosingTypeFieldName, values),
    [values, dosingTypeFieldName]
  );

  const fieldValue: AutoCompleteOption = useMemo(
    () => getValueFromObject(fieldName, values),
    [values, fieldName]
  );

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const otherMedicineAdminRoutes = useMemo(() => {
    return medicineAdminRoutes.find((medicineAdminRoute) =>
      isStringEqual(medicineAdminRoute.Name, "other")
    );
  }, [medicineAdminRoutes]);

  const options = useMemo(() => {
    if (!dosingTypeFieldValue?.value) {
      return [];
    }
    return sortAlphabetically(
      dosingAdminRoutes
        .filter((d) => d.Dosing_Types?.id === dosingTypeFieldValue?.value)
        .map((dosingAdminRoute) => ({
          title: dosingAdminRoute.Administration_Routes.name,
          value: dosingAdminRoute.Administration_Routes.id,
          bodyPart: dosingAdminRoute.Body_Part,
        }))
    );
  }, [dosingAdminRoutes, dosingTypeFieldValue?.value]);

  const onChange = useCallback(
    (
      _: any,
      value: FieldOptionType | string,
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        const mappedValue = (() => {
          if (typeof value === "string") {
            return { title: value, value: otherMedicineAdminRoutes?.id };
          }
          if (value && value.inputValue) {
            return {
              title: value.inputValue,
              value: otherMedicineAdminRoutes?.id,
            };
          }
          return value;
        })();
        if (reason === "select-option") {
          setFieldValue(fieldName, mappedValue);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, mappedValue);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, mappedValue);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue, otherMedicineAdminRoutes?.id]
  );

  useEffect(() => {
    if (options.length === 1) {
      if (fieldValue?.value !== otherMedicineAdminRoutes?.id) {
        setFieldValue(fieldName, options[0]);
        setFieldTouched(fieldName, true);
      }
    } else {
      if (
        !fieldValue?.value ||
        fieldValue?.value === otherMedicineAdminRoutes?.id
      ) {
        return;
      }
      const valueIsInOptions = options.some(
        (o) => o.title === fieldValue?.title
      );
      if (!valueIsInOptions) {
        setFieldValue(fieldName, null);
        setFieldTouched(fieldName, false);
      }
    }
  }, [
    fieldValue?.title,
    fieldValue?.value,
    setFieldValue,
    fieldName,
    options,
    setFieldTouched,
    otherMedicineAdminRoutes?.id,
  ]);

  return (
    <Field
      required
      name={fieldName}
      freeSolo
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: FieldOptionType | string) => {
        if (typeof option === "string") {
          return option;
        }
        if (option?.inputValue) {
          return option?.inputValue || "";
        }
        return option?.title || "";
      }}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      color="primary"
      selectOnFocus
      clearOnBlur
      renderOption={(option: any) => option.title}
      getOptionSelected={(
        option: AutoCompleteOption,
        value: AutoCompleteOption
      ) => {
        return option.value === value.value && option.title === value.title;
      }}
      filterOptions={(fieldOptions: FieldOptionType[], params: any) => {
        const filtered = filter(fieldOptions, params);
        if (params.inputValue !== "") {
          const isExist = isStringEqual(fieldValue?.title, params.inputValue);
          if (!isExist) {
            filtered.push({
              inputValue: params.inputValue,
              value: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
        }
        return filtered;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={fieldName}
          variant="outlined"
          label="Administration Route"
          helperText={
            fieldTouched && fieldError
              ? fieldError
              : "Select from the list or enter new items."
          }
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
