import React, { useMemo } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormHelperText, OutlinedInput } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";

interface CustomMultiSelectProps {
  name: string;
  label?: string;
  helperText?: string;
  options: string[];
}

export function CustomMultiSelect({
  name,
  options,
  label,
  helperText = "",
}: CustomMultiSelectProps) {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext();

  const fieldTouched = useMemo(() => getIn(touched, name), [name, touched]);

  const fieldError = useMemo(() => getIn(errors, name), [name, errors]);
  const value = useMemo(() => getIn(values, name), [name, values]);

  const error = fieldTouched && !!fieldError;

  const helpText = error ? fieldError : helperText;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
      <InputLabel htmlFor="muilti-select">{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        variant="outlined"
        input={<OutlinedInput id="muilti-select" label={label} />}
        renderValue={(selected) => (selected as string[]).join(", ")}
      >
        {options.map((opt) => (
          <MenuItem key={opt} value={opt}>
            <Checkbox checked={value.indexOf(opt) > -1} />
            <ListItemText primary={opt} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helpText}</FormHelperText>
    </FormControl>
  );
}
