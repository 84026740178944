import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

interface Props {
  missingRequiredFields: string[];
}

export const BlockWidgetAlert = ({ missingRequiredFields }: Props) => {
  if (missingRequiredFields.length) {
    return (
      <Alert severity="error">
        <AlertTitle>
          This treatment cannot be completed without entering the following
          details for this Client:
        </AlertTitle>
        <Box component="ul" margin={0}>
          {missingRequiredFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Box>
      </Alert>
    );
  }
  return (
    <Alert severity="warning">
      The Treatment state must be <b>&apos;Pending Details&apos;</b> to use this
      button.
    </Alert>
  );
};
