import * as Yup from "yup";
import { simpleValidatePhoneNumber } from "@deep-consulting-solutions/dcs-web-ui";

import { getENText } from "./languages";
import { splitEmails } from "./email";

const emailValidation = Yup.string().email();

export const getMultipleEmailsValidation = ({
  isRequired,
  existingEmails,
}: {
  isRequired?: boolean;
  existingEmails?: string | string[];
} = {}) => {
  let obj = Yup.string();

  if (isRequired) {
    obj = obj.test(
      "Required",
      "At least 1 recipient email is required",
      (value) => {
        if (!value) return false;
        const emails = splitEmails(value);
        return !!emails.length;
      }
    );
  }

  return obj
    .test("Valid emails", "One or more email is not valid", (value) => {
      if (!value) return !isRequired;

      const emails = splitEmails(value);

      try {
        for (let i = 0, l = emails.length; i < l; i += 1) {
          const email = emails[i];
          emailValidation.validateSync(email);
        }
        return true;
      } catch (error) {
        return false;
      }
    })
    .test("Duplicate emails", "One or more email is duplicate", (value) => {
      if (!value) return !isRequired;
      const emails = splitEmails(value);

      const map: { [email: string]: true } = {};
      if (existingEmails) {
        const existings =
          typeof existingEmails === "string"
            ? [existingEmails]
            : existingEmails;
        existings.forEach((email) => {
          map[email] = true;
        });
      }

      for (let i = 0, l = emails.length; i < l; i += 1) {
        const email = emails[i];
        if (map[email]) return false;
        map[email] = true;
      }

      return true;
    });
};

export const VALIDATIONS = {
  email: Yup.string()
    .email(getENText("validation.email.invalid"))
    .required(getENText("validation.email.required")),
  emailNotRequired: Yup.string().email(getENText("validation.email.invalid")),
  firstName: Yup.string().required(getENText("validation.firstName.required")),
  firstNameNotRequired: Yup.string(),
  lastName: Yup.string().required(getENText("validation.lastName.required")),
  lastNameNotRequired: Yup.string(),
  phone: Yup.string()
    .required(getENText("validation.phone.required"))
    .test(
      "check validity",
      getENText("validation.phone.invalid"),
      simpleValidatePhoneNumber
    ),
  phoneNotRequired: Yup.string().test(
    "check validity",
    getENText("validation.phone.invalid"),
    simpleValidatePhoneNumber
  ),
  passwordLogin: Yup.string().required(
    getENText("validation.password.required")
  ),
  password: Yup.string()
    .min(8, getENText("validation.password.min"))
    .required(getENText("validation.password.required")),
  repassword: Yup.string()
    .required(getENText("validation.repassword.required"))
    .oneOf([Yup.ref("password")], getENText("validation.repassword.match")),
  senderName: Yup.string().required(
    getENText("validation.senderName.required")
  ),
  fromEmail: Yup.string().required(getENText("validation.fromEmail.required")),
  multipleEmailsString: getMultipleEmailsValidation({
    isRequired: true,
  }),
  street: Yup.string().required(getENText("validation.street.required")),
  city: Yup.string().required(getENText("validation.city.required")),
  region: Yup.string().required(getENText("validation.region.required")),
  other: Yup.string(),
  zip: Yup.string().required(getENText("validation.zip.required")),
  country: Yup.string().required(getENText("validation.country.required")),
};
