import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { getFieldName } from "helpers";
import React from "react";
import {
  FinalizeMatchingPackageProduct,
  ZohoMedUsageUnit,
  ZohoProduct,
} from "types";
import { TitleValue } from "components/TitleValue";
import { DoseVolume } from "components/Treatments";
import { MatchingProduct } from "./MatchingProduct";

interface Props {
  matchingProduct: FinalizeMatchingPackageProduct;
  name?: string;
  bacteriostaticProducts: ZohoProduct[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
}

const useStyles = makeStyles(({ palette: p }) => ({
  text: {
    color: p.primary.light,
  },
}));

export const MatchingPackageProductForms = ({
  matchingProduct,
  name = "",
  bacteriostaticProducts,
  medUsageUnits,
}: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.text}>
              {matchingProduct.medicineType}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TitleValue
              title="Dose Volume:"
              value={<DoseVolume doseVolume={matchingProduct.doseVolume} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TitleValue title="Dose:" value={matchingProduct.dose} />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={getFieldName("usageNotes", name)}
              label="Usage Notes"
              size="small"
              multiline
              rows={3}
              fullWidth
              helperText="Usage Notes are displayed below the dosing in the prescription"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name={`${name}.notesForMedicineSelection`}
              label="Notes for Medicine Selection"
              size="small"
              multiline
              rows={3}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <MatchingProduct
        product={matchingProduct.matchingMedProduct.matchingMedProduct}
        name={name}
        productUrl={matchingProduct.product.url}
        productName={matchingProduct.product.name}
        bacteriostaticProducts={bacteriostaticProducts}
        unitPrice={matchingProduct.unitPrice}
        billingCycle={matchingProduct.billingCycle}
        injectionBundles={matchingProduct.injectionBundles}
        medUsageUnits={medUsageUnits}
      />
    </Box>
  );
};
