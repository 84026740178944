import { COUNTRIES_BY_CODE } from "./countries";

export const getCountryCode = (() => {
  const COUNTRY_TO_CODE: { [countryName: string]: string } = {};

  Object.entries(COUNTRIES_BY_CODE).forEach(([code, { label }]) => {
    COUNTRY_TO_CODE[label.toLowerCase()] = code;
  });

  return (countryName?: string | null) => {
    if (!countryName) return null;
    return COUNTRY_TO_CODE[countryName.toLowerCase()] || null;
  };
})();
