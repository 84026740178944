import React from "react";
import { Box, Typography, Paper, Grid } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { CustomTextareaField } from "components/CustomTextareaField";
import { CustomRadioGroupField } from "components/CustomRadioGroupField";
import { CustomCheckboxField } from "components/CustomCheckboxField";
import { Alert } from "@material-ui/lab";
import { InitialValues } from "./types";
import { radioOptions } from "./constants";

export const CancelTreatmentForm = ({ infoText }: { infoText: string }) => {
  const { values }: { values: InitialValues } = useFormikContext();
  return (
    <Box minHeight="400px">
      {values.treatmentOrders.length === 0 && (
        <Alert severity="info" style={{ marginBottom: "16px" }}>
          Only cancel a treatment plan if the client is stopping treatment and
          dropping out. If the treatment plan gets cancelled, all unpaid
          invoices will be voided and the client will stop receiving their
          medication.
        </Alert>
      )}
      <Box marginBottom={2}>
        <Typography>{infoText}</Typography>
      </Box>
      <Box marginY={3}>
        <FieldArray name="orderItems">
          {() => (
            <>
              {values.treatmentOrders.length > 0 &&
                values.treatmentOrders.map((treatmentOrder, index) => (
                  <Paper
                    key={treatmentOrder.Id}
                    variant="outlined"
                    style={{
                      margin: "8px auto",
                      width: "100%",
                      padding: "16px",
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={3}>
                      <Grid item sm={12}>
                        <Box>
                          <Box mb={1}>
                            <Typography variant="body1">
                              The treatment order{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={treatmentOrder.url}
                              >
                                {treatmentOrder.Name}
                              </a>{" "}
                              is paid and in progress. Select if it should be
                              completed or cancelled.
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            gridColumnGap="32px"
                          >
                            <CustomRadioGroupField
                              name={`treatmentOrders.${index}.selectedAction`}
                              options={radioOptions}
                            />
                            {treatmentOrder.selectedAction === "cancel" && (
                              <CustomCheckboxField
                                name={`treatmentOrders.${index}.shouldCreditOrRefund`}
                                label="Credit or Refund the order"
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </>
          )}
        </FieldArray>
      </Box>
      <Box marginTop={2}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          Cancellation Reason *
        </Typography>
        <Box>
          <CustomTextareaField name="reason" rows={6} rowsMax={9} />
        </Box>
      </Box>
    </Box>
  );
};
