import { Box, Chip, TextField } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { getFieldName, getValueFromObject } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption, DBPharmacy, ZohoPharmacy } from "types";

interface Props {
  name?: string;
  dbPharmacies?: DBPharmacy[];
  zohoPharmacies?: ZohoPharmacy[];
  maxWidth?: number;
  helperText?: string;
}
export const PharmaciesField = ({
  name,
  dbPharmacies,
  zohoPharmacies,
  maxWidth,
  helperText,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("pharmacies", name), [name]);

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(() => {
    if (dbPharmacies) {
      return dbPharmacies.map((pharmacy) => ({
        title: pharmacy.Name,
        value: pharmacy.ZohoCrmId,
      }));
    }
    return (zohoPharmacies || []).map((pharmacy) => ({
      title: pharmacy.Name,
      value: pharmacy.id,
    }));
  }, [dbPharmacies, zohoPharmacies]);

  const onChange = useCallback(
    (
      _: any,
      value: (AutoCompleteOption | string)[],
      reason: AutocompleteChangeReason
    ) => {
      if (value) {
        if (reason === "select-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "create-option") {
          setFieldValue(fieldName, value);
        }
        if (reason === "remove-option") {
          setFieldValue(fieldName, value);
        }
      }

      if (reason === "clear") {
        setFieldValue(fieldName, []);
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Box mb={2} minWidth={200} maxWidth={maxWidth}>
      <Field
        required
        name={fieldName}
        multiple
        component={Autocomplete}
        options={options}
        getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
        onChange={onChange}
        onBlur={() => {
          setFieldTouched(fieldName, true);
        }}
        getOptionSelected={(option: any, value: any) => {
          return option.value === value.value && option.title === value.title;
        }}
        color="primary"
        renderTags={(value: any[], getTagProps: (param: any) => any) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              label={typeof option === "string" ? option : option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            required
            name={fieldName}
            variant="outlined"
            label="Pharmacies"
            helperText={
              (fieldTouched && typeof fieldError === "string" && fieldError) ||
              helperText
            }
            error={
              fieldTouched && typeof fieldError === "string" && !!fieldError
            }
            size="small"
          />
        )}
      />
    </Box>
  );
};
