import { crhClient, n8nClient } from "apis";
import {
  GetPharmacyResponse,
  GetTreatmentOrderResponse,
} from "widgets/CancelTreatmentOrder/types";

type CancelTreatmentOrderPayload = {
  treatmentOrderId: string;
  cancellationReason: string;
  creditOrRefundOrder: boolean;
};

const BASE_URL = "/api/v1/db/data/v1/CustomBackend";

const getTreatmentOrderQuery = (orderId: string) => {
  return `${BASE_URL}/TreatmentOrders?where=(ZohoCrmId,eq,${orderId})&fields=Id,Name,Type,ZohoCrmId,TreatmentId,OrderDate,PaymentStatus,State,PharmacyId,ReplacementCreated`;
};

const getPharmacyQuery = (pharmacyDbId: string) => {
  return `${BASE_URL}/Pharmacy?where=(Id,eq,${pharmacyDbId})&fields=Id,Name,ZohoCrmId`;
};

export async function getTreatmentOrder(orderId: string) {
  try {
    const response = await crhClient.post<GetTreatmentOrderResponse>("/crm", {
      url: getTreatmentOrderQuery(orderId),
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list[0];
  } catch (error) {
    return null;
  }
}

export async function getPharmacy(pharmacyId: string) {
  try {
    const response = await crhClient.post<GetPharmacyResponse>("/crm", {
      url: getPharmacyQuery(pharmacyId),
      app: "NOCODB",
      method: "GET",
    });
    return response.data.data.list[0];
  } catch (error) {
    return null;
  }
}

export const cancelTreatmentOrder = async (
  payload: CancelTreatmentOrderPayload
) => {
  const res = await n8nClient.post(`/treatment-orders/cancel`, payload);
  return res.data;
};
