import { crhClient, n8nClient } from "apis";

const BASE_URL = "/api/v1/db/data/v1/CustomBackend";

interface GetTreatmentClientIdResponse {
  data: {
    list: {
      Contact: { Id: string; ZohoID: string };
    }[];
  };
}

interface ResumeTreatmentPayload {
  reason: string;
  TreatmentID: string;
}

const getTreatmentClientIdQuery = (treatmentId: string) => {
  return `${BASE_URL}/Treatments?where=(ZohoCrmId,eq,${treatmentId})&fields=Contact,&nested[Contact][fields]=Id,ZohoID`;
};

export async function getTreatmentClientId(treatmentId: string) {
  try {
    const response = await crhClient.post<GetTreatmentClientIdResponse>(
      "/crm",
      {
        url: getTreatmentClientIdQuery(treatmentId),
        app: "NOCODB",
        method: "GET",
      }
    );
    return response.data.data.list[0].Contact.ZohoID;
  } catch (error) {
    return null;
  }
}

export const resumeTreatment = async (payload: ResumeTreatmentPayload) => {
  const res = await n8nClient.post(
    `/treatments/continuing-treatment-flow`,
    payload
  );
  return res.data;
};
