import { Box, Typography } from "@material-ui/core";
import { getContactName } from "helpers";
import React, { useMemo } from "react";
import { ZohoClientRecord } from "types";

interface Props {
  client: ZohoClientRecord | null;
}

export const ClientDetails = ({ client }: Props) => {
  const fullName = useMemo(() => (client ? getContactName(client) : ""), [
    client,
  ]);

  return (
    <Box mb={2}>
      <Typography>
        Client: <b>{fullName}</b>
      </Typography>
    </Box>
  );
};
