import { Box } from "@material-ui/core";
import {
  TreatmentMedicinesWarning,
  TreatmentTreatmentAreasMedicines,
  TreatmentTreatmentAreasField,
} from "components/Treatments";

import React from "react";
import {
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoTreatmentArea,
} from "types";

interface Props {
  treatmentAreas: ZohoTreatmentArea[];
  dosingTypes: ZohoDosingType[];
  dosingAdminRoutes: ZohoDosingAdminRoute[];
  medicineAdminRoutes: ZohoMedicineAdminRoute[];
}

export const UpdateTreatmentPlanTab = ({
  treatmentAreas,
  dosingTypes,
  dosingAdminRoutes,
  medicineAdminRoutes,
}: Props) => {
  return (
    <Box>
      <Box mb={4}>
        <Box mb={2} maxWidth={500}>
          <TreatmentTreatmentAreasField treatmentAreas={treatmentAreas} />
        </Box>
        <Box mb={2}>
          <TreatmentMedicinesWarning />
        </Box>
        <TreatmentTreatmentAreasMedicines
          treatmentAreas={treatmentAreas}
          dosingTypes={dosingTypes}
          dosingAdminRoutes={dosingAdminRoutes}
          medicineAdminRoutes={medicineAdminRoutes}
        />
      </Box>
    </Box>
  );
};
