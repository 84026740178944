import { n8nClient } from "apis";
import { zohoServices } from "services";
import { Decision } from "widgets/DispenseDrugs";

type Item = {
  Product_Name: string;
  Product_Type: string;
  Usage_Unit_Volume?: string;
  Usage_Unit?: string;
  Med_Usage_Unit?: {
    name: string;
  };
  Usage_Units_Per_Sellable_Unit?: string;
};

type TreatmentOrderItem = {
  id: string;
  Product_Type: "Medication" | "Supplementary" | "Injection Bundle";
  Item: Item;
  Quantity: number;
  Dispensed_Unit?: DispensedUnit[];
  Method_of_Disposing?: string;
  Missing_Quantity: number | null;
  Replacement: { id: string; name: string } | null;
  Replacement_Quantity: number | null;
  Decision: Decision | null;
};

type GetTreatmentOrderItemsResponse = {
  data: TreatmentOrderItem[];
};

type DispensedUnit = {
  Batch_Number: string;
  Expiry_Date: string;
};

type FormattedTreatmentOrderItem = {
  id: string;
  name: string;
  quantity: string;
  missingQuantity: number | null;
  batchAndExpiryDate: string[];
  methodOfDisposing: string;
};

type ProductItem = {
  Usage_Unit_Volume?: string;
  Usage_Unit?: string;
  Med_Usage_Unit?: { name: string };
  Usage_Units_Per_Sellable_Unit?: string;
};

type Product = {
  Item?: ProductItem;
  Quantity: number;
};

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatExpiryData(data: DispensedUnit[]): string[] {
  // Use reduce to create the batches object
  const batches = data.reduce<{ [key: string]: number }>((acc, item) => {
    const key = `${item.Batch_Number}, ${formatDate(item.Expiry_Date)}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  // Use Object.entries to iterate over the batches object and map to formattedData
  const formattedData = Object.entries(batches).map(([key, count]) =>
    count > 1 ? `${key} (${count})` : key
  );

  return formattedData;
}

function getQuantityString(productType: string, product: Product): string {
  let quantity = "";

  if (productType === "Medication" || productType === "Supplementary") {
    const volume = product.Item?.Usage_Unit_Volume || "";
    const unit =
      product.Item?.Usage_Unit || product.Item?.Med_Usage_Unit?.name || "";
    const sellableUnit = product.Item?.Usage_Units_Per_Sellable_Unit || "";
    quantity = `${product.Quantity} ${
      sellableUnit ? "x" : ""
    } ${sellableUnit} ${volume} ${unit}`;
  } else if (productType === "Injection Bundle") {
    quantity = `${product.Quantity} Bundle`;
  }

  return quantity;
}

export async function fetchProductDetails(productId: string) {
  const response = await zohoServices.getRecord<TreatmentOrderItem["Item"]>({
    Entity: "Products",
    RecordID: productId,
  });
  return response;
}

export async function formatTreatmentOrderItems(
  treatmentOrderItems: TreatmentOrderItem[]
): Promise<FormattedTreatmentOrderItem[]> {
  const formattedItemsPromises = treatmentOrderItems.map(
    async (i): Promise<FormattedTreatmentOrderItem | null> => {
      const batchAndExpiryDate = formatExpiryData(i.Dispensed_Unit || []);
      const productType = i.Product_Type;
      let quantity = "";
      let name = i.Item?.Product_Name;

      if (i.Missing_Quantity && i.Missing_Quantity > 0) {
        if (i.Decision === Decision.REPLACE) {
          name = i.Replacement?.name as string;
          const product = await fetchProductDetails(
            i.Replacement?.id as string
          );
          quantity = getQuantityString(product.Product_Type, {
            Item: product,
            Quantity: i.Replacement_Quantity as number,
          });
        } else if (
          i.Decision === Decision.PARTIAL_DISPENSE_MARK_REST_OWED ||
          i.Decision === Decision.PARTIAL_DISPENSE_SEND_TO_BMH
        ) {
          quantity = getQuantityString(productType as string, {
            Item: i.Item,
            Quantity: i.Quantity - i.Missing_Quantity,
          });
        } else if (
          i.Decision === Decision.MARK_AS_OWED ||
          i.Decision === Decision.SEND_TO_BMH
        ) {
          return null;
        }
      } else {
        quantity = getQuantityString(productType, {
          Item: i.Item,
          Quantity: i.Quantity,
        });
      }

      return {
        id: i.id,
        name,
        quantity,
        missingQuantity: i.Missing_Quantity,
        batchAndExpiryDate,
        methodOfDisposing: i.Method_of_Disposing || "Destroying",
      };
    }
  );

  const formattedItems = await Promise.all(formattedItemsPromises);

  return formattedItems.filter(
    (item): item is FormattedTreatmentOrderItem =>
      item !== null && item.name !== ""
  );
}

export const disposalMedication = async (payload: any) => {
  const res = await n8nClient.post("/treatment-order/dispose", payload);
  return res.data;
};

export const getTreatmentOrderItems = async (treatmentOrderId: string) => {
  try {
    const res = await n8nClient.get<GetTreatmentOrderItemsResponse>(
      `/treatment-order-items/${treatmentOrderId}`
    );
    const rawTreatmentOrderItems = res.data.data;
    const formatted = formatTreatmentOrderItems(rawTreatmentOrderItems);
    return formatted;
  } catch (error) {
    return [];
  }
};
