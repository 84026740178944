/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { FieldArray, useFormikContext } from "formik";
import { Box, Typography } from "@material-ui/core";
import { InitialValues, TableRowsProps } from "./types";
import { columns, DEFAULT_ROWS_PER_PAGE } from "./constants";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    width: "100%",
    margin: s(2, 0),
  },
  pagination: {
    "& .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot": {
      width: "85px",
    },
  },
}));

const TableRows = ({ columns, page, rowsPerPage }: TableRowsProps) => {
  const { values }: { values: InitialValues } = useFormikContext();
  return (
    <FieldArray name="treatmentOrderItems">
      {() => (
        <>
          {values.treatmentOrderItems
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id}>
                        {column.id === "batchAndExpiryDate" &&
                          Array.isArray(value) && (
                            <Box component="ul" padding="0" margin="0">
                              {value.map((s) => (
                                <Typography
                                  key={s}
                                  component="li"
                                  variant="body2"
                                  style={{ marginTop: "4px" }}
                                >
                                  {s}
                                </Typography>
                              ))}
                            </Box>
                          )}
                        {["name", "quantity"].includes(column.id) && (
                          <>{value}</>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </>
      )}
    </FieldArray>
  );
};

export function ConfirmOrderPackingTable({ rowCount }: { rowCount: number }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRows
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {rowCount > DEFAULT_ROWS_PER_PAGE && (
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
