import React from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { useFormikContext } from "formik";

const useStyle = makeStyles(({ palette: p }) => ({
  cancelButton: {
    background: p.error.main,
    color: "#fff",
    textTransform: "capitalize",
    minWidth: "120px",
    "&:hover": {
      background: p.error.main,
    },
  },
}));

export const ActionButtons = ({
  closeWidget,
  invalidState,
}: {
  closeWidget: () => void;
  invalidState: boolean;
}) => {
  const classes = useStyle();
  const { isValid, submitForm, isSubmitting } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Close
      </Button>
      {!invalidState && (
        <Button
          variant="contained"
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
          className={classes.cancelButton}
        >
          {isSubmitting ? "Cancelling..." : "Cancel"}
        </Button>
      )}
    </Box>
  );
};
