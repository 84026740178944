import { TreatmentAreaValue } from "components/Treatments";
import {
  AutoCompleteOption,
  ChildProduct,
  MedicineProduct,
  MedicineSelected,
  MigrateTreatmentData,
  SupplementaryProductValues,
  MigrateTreatmentFormValues,
  TreatmentFormValues,
  TreatmentMedicineFormValues,
  TreatmentMedicinesFormValues,
  TreatmentTreatmentAreaFormValues,
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoCurrentUser,
  ZohoDiagnosis,
  ZohoInvoice,
  ZohoMedicineAdminRoute,
  ZohoMedicineType,
  ZohoPharmacy,
  ZohoProduct,
  ZohoTreatment,
  ZohoTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
  DBPharmacyProduct,
  FinalizeMatchingProductMedicine,
  FinalizeZohoOtherTreatmentMedicine,
  FinalizeMatchingMedPackage,
  FinalizeMatchingProductDetails,
  FinalizeWidgetValues,
  ZohoSelectedProduct,
  ZohoMedUsageUnit,
  FinalizeWidgetValuesMatchingProductValues,
  FinalizeWidgetValuesMatchingProductsValues,
  FinalizeWidgetValuesMatchingPackagesValues,
  ZohoDiagnosisOption,
} from "types";
import * as Yup from "yup";
import { FormikErrors } from "formik";
import moment from "moment";
import { format } from "date-fns";
import { getDoctorName } from "./treatmentDetails";

export const getFieldName = (name: string | number, namePrefix = "") => {
  if (namePrefix) {
    return `${namePrefix}.${name}`;
  }
  return String(name);
};

export const sortAlphabetically = <T extends { title: string }>(
  list: T[]
): T[] => {
  return list.sort((a, b) => a.title.localeCompare(b.title));
};

export const getValueFromObject = (name: string | string[], vS: any): any => {
  const keys = Array.isArray(name) ? name : name.split(".");
  if (!vS) {
    return vS;
  }
  const newVs = vS[keys[0]];
  if (keys[1]) {
    return getValueFromObject(keys.slice(1), newVs);
  }
  return newVs;
};

export const diagnosisList: ZohoDiagnosis[] = [
  { label: "Functional Testosterone Deficiency", selected: false },
  { label: "Hypogonadism E23.0", selected: false },
  { label: "Hypothyroidism - unspecified E03.9", selected: false },
  {
    label: "Menopausal and female climacteric states N95.1",
    selected: false,
    forFemale: true,
  },
  {
    label: "Unspecified menopausal and peri-menopausal disorder N95.9",
    selected: false,
    forFemale: true,
  },
];

export const frequencyOfUseOptions: AutoCompleteOption[] = [
  {
    value: "Days Per Week",
    title: "Days Per Week",
  },
  {
    value: "Times Per Day",
    title: "Times Per Day",
  },
  {
    value: "Every 'X' Days",
    title: "Every 'X' Days",
  },
];

export const patternOfUseOptions: AutoCompleteOption[] = [
  {
    value: "cyclical",
    title: "Cyclical Use",
  },
  {
    value: "temporary",
    title: "Temporary Use",
  },
  {
    value: "required",
    title: "As Required / If Needed",
  },
];

export const timeOfDayOptions: AutoCompleteOption[] = [
  {
    value: "Morning",
    title: "Morning",
  },
  {
    value: "Afternoon",
    title: "Afternoon",
  },
  {
    value: "Evening",
    title: "Evening",
  },
];

export const isStringEqual = (textOne = "", textTwoString = "") => {
  return (
    (textOne || "").toLowerCase().trim() ===
    (textTwoString || "").toLowerCase().trim()
  );
};

export const getDiagnosisList = (
  diagnosisOptions: ZohoDiagnosisOption[],
  gender?: string
) => {
  return diagnosisOptions
    .map((d) => {
      const matchingDiagnosis = diagnosisList.find(
        (dL) => dL.label.toLowerCase() === d.display_value.toLowerCase()
      );
      return {
        label: d.display_value,
        id: d.id,
        value: d.display_value,
        forFemale: !!matchingDiagnosis?.forFemale,
      };
    })
    .filter((d) => {
      if (isStringEqual(gender, "female")) {
        return true;
      }
      return !d.forFemale;
    });
};

export const getDiagnosisDefaultValue = (
  treatmentDiagnosis: string[],
  diagnosisOptions: ZohoDiagnosisOption[],
  gender?: string
) => {
  const genderDiagnosisList = getDiagnosisList(diagnosisOptions, gender);
  return genderDiagnosisList.map((d) => {
    const isSelected = treatmentDiagnosis.includes(d.label);
    return {
      ...d,
      selected: !!isSelected,
    };
  });
};
export const doctorValidation = Yup.object()
  .required("Doctor is required")
  .typeError("Doctor is required")
  .test("Select Doctor", "Doctor is required", (value) => {
    return !!(value as any)?.value;
  });

export const dosingTypeValidation = Yup.object()
  .required("Dosing type is required")
  .typeError("Dosing type is required")
  .test("Select Dosing type", "Dosing type is required", (value) => {
    return !!(value as any)?.value;
  });

export const diagnosisValidation = Yup.array().test(
  "Selected one",
  "At least one diagnosis is required",
  (value) => {
    return (value as ZohoDiagnosis[])?.some((v) => v.selected);
  }
);

export const customDosingInstructionValidation = Yup.string().when(
  "specifyDosingInstructions",
  {
    is: true,
    then: Yup.string().required("Custom dosing instruction is required"),
    otherwise: Yup.string().nullable().optional(),
  }
);

export const dosingAmountValidation = Yup.number().when(
  ["specifyDosingInstructions", "noDosageAmount"],
  {
    is: (specifyDosingInstructions, noDosageAmount) =>
      !specifyDosingInstructions && !noDosageAmount,
    then: Yup.number()
      .min(0.01, "Dosing amount must be greater than zero")
      .typeError("Dosing amount is required")
      .required("Dosing amount is required"),
    otherwise: Yup.number().nullable().optional(),
  }
);

export const numberOfDaysValidation = Yup.number().when(
  ["specifyDosingInstructions", "patternOfUse"],
  {
    is: (specifyDosingInstructions, patternOfUse) =>
      !specifyDosingInstructions &&
      isStringEqual(patternOfUse?.value, "temporary"),
    then: Yup.number()
      .min(1, "Value must be greater or equal to 1")
      .integer("Value must be a whole number")
      .typeError("This field is required")
      .required("This field is required"),
    otherwise: Yup.number().nullable().optional(),
  }
);

export const dosingUnitValidation = Yup.object().when(
  ["specifyDosingInstructions", "noDosageAmount"],
  {
    is: (specifyDosingInstructions, noDosageAmount) =>
      !specifyDosingInstructions && !noDosageAmount,
    then: Yup.object()
      .required("Dosing unit is required")
      .typeError("Dosing unit is required")
      .test("Select Dosing unit", "Dosing unit is required", (value) => {
        return !!(value as any)?.value;
      }),
    otherwise: Yup.object().nullable().optional(),
  }
);

export const administrationRouteValidation = Yup.object().when(
  "specifyDosingInstructions",
  {
    is: false,
    then: Yup.object()
      .required("Administration route is required")
      .typeError("Administration route is required")
      .test(
        "Select Administration route",
        "Administration route is required",
        (value) => {
          return !!(value as any)?.value;
        }
      ),
    otherwise: Yup.object().nullable().optional(),
  }
);

export const frequencyOfUseValidation = Yup.object().when(
  "specifyDosingInstructions",
  {
    is: false,
    then: Yup.object()
      .required("Frequency of use is required")
      .typeError("Frequency of use is required")
      .test(
        "Select Frequency of use is required",
        "Frequency of use is required",
        (value) => {
          return !!(value as any)?.value;
        }
      ),
    otherwise: Yup.object().nullable().optional(),
  }
);

export const bodyPartValidation = Yup.string().when(
  ["specifyDosingInstructions", "administrationRoute"],
  {
    is: (specifyDosingInstructions, administrationRoute) =>
      !specifyDosingInstructions &&
      isStringEqual(administrationRoute?.bodyPart || "", "Required"),
    then: Yup.string()
      .typeError("Body part is required")
      .required("Body part is required"),
    otherwise: Yup.string().nullable().optional(),
  }
);

export const numberOfUseValidation = Yup.number().when(
  ["specifyDosingInstructions"],
  {
    is: false,
    then: Yup.number().when("frequencyOfUse", {
      is: (frequencyOfUse) =>
        isStringEqual(frequencyOfUse?.title || "", "Every 'X' Days"),
      then: Yup.number()
        .min(0.5, "Number of use must be greater than or equal to 0.5")
        .test(
          "Check decimal",
          "Number of use must be a positive number in steps of 0.5 e.g. (3 or 3.5)",
          (value) => {
            if (!value) {
              return true;
            }
            const division = value / 0.5;
            return Math.floor(division) === division;
          }
        )
        .required("Number of use is required")
        .typeError("Number of use is required"),
      otherwise: Yup.number()
        .integer("Number of use must be a whole number")
        .min(1, "Number of use must be greater than or equal to 1")
        .when("frequencyOfUse", {
          is: (frequencyOfUse) =>
            isStringEqual(frequencyOfUse?.title || "", "Days Per Week"),
          then: Yup.number()
            .max(7, "Number of use must be less than or equal to 7")
            .required("Number of use is required")
            .typeError("Number of use is required"),
          otherwise: Yup.number()
            .required("Number of use is required")
            .typeError("Number of use is required"),
        }),
    }),
    otherwise: Yup.number().nullable().optional(),
  }
);

export const timeOfDayValidation = Yup.array().when(
  ["specifyDosingInstructions", "frequencyOfUse"],
  {
    is: (specifyDosingInstructions, frequencyOfUse) =>
      !specifyDosingInstructions &&
      isStringEqual(frequencyOfUse?.title || "", "Times Per Day"),
    then: Yup.array().test({
      name: "Must match number of use for times per day",
      message:
        "The number of selected times of day must not exceed the number of Times Per Day.",
      test(value: any) {
        const valueLength = value?.length || 0;
        if (!valueLength) {
          return true;
        }
        const numberOfUse = Number((this as any)?.parent?.numberOfUse || 0);
        return valueLength <= numberOfUse;
      },
    }),
    otherwise: Yup.array().nullable().optional(),
  }
);

export const cyclicalUseDaysValidation = Yup.number().when(
  ["specifyDosingInstructions", "patternOfUse"],
  {
    is: (specifyDosingInstructions, patternOfUse) =>
      !specifyDosingInstructions &&
      isStringEqual(patternOfUse?.value, "cyclical"),
    then: Yup.number()
      .integer("Cyclical use must be a whole number")
      .min(1, "Cyclical use must be greater or equal to 1")
      .when("frequencyOfUse", {
        is: (frequencyOfUse) =>
          isStringEqual(frequencyOfUse?.title || "", "Days Per Week"),
        then: Yup.number()
          .typeError("Cyclical use is required")
          .required("Cyclical use is required"),
        otherwise: Yup.number()
          .typeError("Cyclical use is required")
          .required("Cyclical use is required"),
      }),
    otherwise: Yup.number().nullable().optional(),
  }
);

export const cyclicalStopDaysValidation = Yup.number().when(
  ["specifyDosingInstructions", "patternOfUse"],
  {
    is: (specifyDosingInstructions, patternOfUse) =>
      !specifyDosingInstructions &&
      isStringEqual(patternOfUse?.value, "cyclical"),
    then: Yup.number()
      .min(1, "Cyclical pause must be greater or equal to 1")
      .integer("Cyclical pause must be a whole number")
      .typeError("Cyclical pause is required")
      .required("Cyclical pause is required"),
    otherwise: Yup.number().nullable().optional(),
  }
);

export const medicinesSelectedValidation = Yup.array()
  .of(
    Yup.object({
      specifyDosingInstructions: Yup.boolean().nullable().optional(),
      customDosingInstruction: customDosingInstructionValidation,
      usageNotes: Yup.string().nullable().optional(),
      notesForMedicineSelection: Yup.string().nullable().optional(),
      dosingType: dosingTypeValidation,
      dosingAmount: dosingAmountValidation,
      dosingUnit: dosingUnitValidation,
      frequencyOfUse: frequencyOfUseValidation,
      numberOfUse: numberOfUseValidation,
      timeOfDay: timeOfDayValidation,
      numberOfDays: numberOfDaysValidation,
      cyclicalUseDays: cyclicalUseDaysValidation,
      cyclicalStopDays: cyclicalStopDaysValidation,
      administrationRoute: administrationRouteValidation,
      bodyPart: bodyPartValidation,
    })
  )
  .test("Selected one", "Medicine suggested is required", (value) => {
    return !!(value as [])?.length;
  });

export const treatmentTreatmentAreasValidation = Yup.array()
  .of(
    Yup.object({
      medicinesSelected: medicinesSelectedValidation,
    })
  )
  .test("Selected one", "Target treatment area is required", (value) => {
    return !!(value as [])?.length;
  });

const productValidation = Yup.object()
  .required("Product is required")
  .typeError("Product is required")
  .test("Select Product", "Product is required", (value) => {
    return !!(value as any)?.value;
  });
const quantityValidation = Yup.number()
  .min(1, "Quantity  must be greater than or equal to 1")
  .integer("Quantity must be a whole number")
  .typeError("Quantity is required")
  .required("Quantity is required");

const quantityValidationForChildProduct = Yup.number().when("product", {
  is: (val) => !!val?.value,
  then: quantityValidation,
  otherwise: Yup.number().nullable().optional(),
});

const customUnitPriceValidation = Yup.number()
  .min(0.01, "Custom unit price  must be greater than zero")
  .typeError("Custom unit price  is required")
  .nullable()
  .optional();

const getBillingCycleInDays = (
  billingCycleUnit: AutoCompleteOption,
  period: number
) => {
  if (isStringEqual(billingCycleUnit.value, "days")) {
    return period;
  }
  if (isStringEqual(billingCycleUnit.value, "weeks")) {
    return period * 7;
  }
  return period * 30;
};

const getPeriodValidation = (
  treatmentPeriodInMonths?: number,
  globalBillingCycleUnit?: AutoCompleteOption | null
) => {
  const validationCallback = (_: any, context: any) => {
    return Yup.number().when("onlyOnce", {
      is: true,
      then: Yup.number().nullable().optional().typeError(undefined),
      otherwise: Yup.number()
        .min(1, "Billing cycle period  must be greater than or equal to 1")
        .integer("Billing cycle period must be a whole number")
        .typeError("Billing cycle period  is required")
        .required("Billing cycle period  is required")
        .test(
          "treatment period max",
          `Billing cycle period should never be more than the Treatment Period of
      ${treatmentPeriodInMonths} month${
            (treatmentPeriodInMonths || 0) > 1 ? "s" : ""
          }. (1 Month equals 30 days)`,
          (value: unknown) => {
            if (!value || !treatmentPeriodInMonths) {
              return true;
            }
            const treatmentPeriodInDays = treatmentPeriodInMonths * 30;

            if (globalBillingCycleUnit) {
              const periodInDays = getBillingCycleInDays(
                globalBillingCycleUnit,
                value as number
              );
              return treatmentPeriodInDays >= periodInDays;
            }

            const contextParent = context.parent;
            const billingCycleUnit = contextParent.billingCycleUnit as AutoCompleteOption | null;
            if (!billingCycleUnit) {
              return true;
            }
            const periodInDays = getBillingCycleInDays(
              billingCycleUnit,
              value as number
            );
            return treatmentPeriodInDays >= periodInDays;
          }
        ),
    });
  };
  return Yup.lazy<any>(validationCallback as any);
};

export const getSupplementaryProductsValidationSchema = (
  treatmentPeriodInMonths: number,
  globalBillingCycleUnit: AutoCompleteOption | null
) =>
  Yup.array().of(
    Yup.object({
      quantity: quantityValidationForChildProduct,
      customUnitPrice: customUnitPriceValidation,
      period: getPeriodValidation(
        treatmentPeriodInMonths,
        globalBillingCycleUnit
      ),
    })
  );

const childProductValidationSchema = Yup.lazy((childProductValue: any) => {
  if (!childProductValue.show) {
    return Yup.object({});
  }
  return Yup.object({
    product: productValidation,
    quantity: quantityValidation,
    customUnitPrice: customUnitPriceValidation,
  });
});

export const getTreatmentMedicinesValidationSchema = (
  treatmentPeriodInMonths: number,
  globalBillingCycleUnit: AutoCompleteOption | null
) =>
  Yup.array().of(
    Yup.object({
      product: productValidation,
      quantity: quantityValidation,
      customUnitPrice: customUnitPriceValidation,
      period: getPeriodValidation(
        treatmentPeriodInMonths,
        globalBillingCycleUnit
      ),
      injectionBundle: childProductValidationSchema,
      bacteriostatic: childProductValidationSchema,
    })
  );

const treatmentValidationSchema = {
  doctor: doctorValidation,
  diagnosis: diagnosisValidation,
  instructions: Yup.string().nullable().optional(),
  treatmentPeriod: Yup.number()
    .min(
      1,
      "Number of months the prescription should be used must be greater than or equal to 1"
    )
    .max(
      24,
      "Number of months the prescription should be used must be less than or equal to 24"
    )
    .integer(
      "Number of months the prescription should be used must be a whole number"
    )
    .typeError("Number of months the prescription should be used is required")
    .required("Number of months the prescription should be used is required"),
};

const treatmentMedicineValidationSchema = {
  doctor: treatmentValidationSchema.doctor,
  diagnosis: treatmentValidationSchema.diagnosis,
  treatmentTreatmentAreas: treatmentTreatmentAreasValidation,
  instructions: treatmentValidationSchema.instructions,
  treatmentPeriod: treatmentValidationSchema.treatmentPeriod,
};

const getTreatmentProductValidationSchema = (
  treatmentPeriodInMonths: number
) => {
  const treatmentMedicinesValidation = (_: any, context: any) => {
    const contextParent = context.parent;
    const billingCycleUnit = contextParent.billingCycleUnit as AutoCompleteOption | null;
    return getTreatmentMedicinesValidationSchema(
      treatmentPeriodInMonths,
      billingCycleUnit
    );
  };

  const supplementaryProductsValidation = (_: any, context: any) => {
    const contextParent = context.parent;
    const billingCycleUnit = contextParent.billingCycleUnit as AutoCompleteOption | null;
    return getSupplementaryProductsValidationSchema(
      treatmentPeriodInMonths,
      billingCycleUnit
    );
  };

  return {
    ...treatmentValidationSchema,
    billingCycleUnit: Yup.object()
      .required("Billing cycle unit is required")
      .typeError("Billing cycle unit is required")
      .test(
        "Select Billing cycle unit",
        "Billing cycle unit is required",
        (value) => {
          return !!(value as any)?.value;
        }
      ),
    pharmacies: Yup.array().test(
      "Selected one",
      "At least, one pharmacy is required",
      (value) => {
        return !!(value as [])?.length;
      }
    ),
    treatmentMedicines: Yup.lazy(treatmentMedicinesValidation as any),
    supplementaryProducts: Yup.lazy(supplementaryProductsValidation as any),
  };
};
export const getTreatmentValidationSchema = () => {
  return Yup.object(treatmentMedicineValidationSchema);
};

export const getUpdateTreatmentValidationSchema = (
  activeTab: number,
  hasPaidTreatmentOrders: boolean,
  treatmentPeriodInMonths: number,
  valuesChangeCalculation: {
    requirePackageAndPharmacySelection: boolean;
    requirePrescriptionAndCttpChange: boolean;
    requirePrescriptionChange: boolean;
  }
): Yup.Schema<any, any> => {
  const {
    requirePackageAndPharmacySelection,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
  } = valuesChangeCalculation;

  return Yup.lazy((values) => {
    const requiresFirstPaymentDate =
      hasPaidTreatmentOrders &&
      (requirePackageAndPharmacySelection ||
        requirePrescriptionAndCttpChange ||
        requirePrescriptionChange) &&
      !(values as any).changeFirstInvoiceDateAfterSigningFieldValue;

    const firstPaymentDateValidation = requiresFirstPaymentDate
      ? Yup.string()
          .when("changeFirstInvoiceDateAfterSigning", {
            is: false,
            then: Yup.string()
              .typeError("First payment date is required")
              .required("First payment date is required"),
            otherwise: Yup.string().nullable().optional(),
          })
          .typeError("")
          .required()
      : Yup.string().typeError("");

    if (activeTab === 0 || requirePackageAndPharmacySelection) {
      return Yup.object({
        ...treatmentMedicineValidationSchema,
        firstPaymentDate: firstPaymentDateValidation,
      });
    }

    return Yup.object({
      ...getTreatmentProductValidationSchema(treatmentPeriodInMonths),
      firstPaymentDate: firstPaymentDateValidation,
    });
  });
};

export const getTreatmentMigrationValidationSchema = () => {
  const callbackFnc = (values: MigrateTreatmentFormValues) => {
    return Yup.object({
      ...treatmentMedicineValidationSchema,
      firstPaymentDate: Yup.date()
        .typeError("First payment date is required")
        .required("First payment date is required"),
      manualOrAutoSelection: Yup.string().required("Selection is required"),
      legacyCttp: Yup.string().when("manualOrAutoSelection", {
        is: "manual",
        then: Yup.string().required("CTTP is required"),
      }),
      nextCheckupType: Yup.object()
        .required("Next checkup type is required")
        .typeError("Next checkup type is required"),
      nextCheckupDate: Yup.date()
        .typeError("Next checkup date is required")
        .required("Next checkup date is required"),
      billingCycleUnit: Yup.object()
        .required("Billing cycle unit is required")
        .typeError("Billing cycle unit is required")
        .test(
          "Select Billing cycle unit",
          "Billing cycle unit is required",
          (value) => {
            return !!(value as any)?.value;
          }
        ),
      pharmacies: Yup.array().test(
        "Selected one",
        "At least, one pharmacy is required",
        (value) => {
          return !!(value as [])?.length;
        }
      ),
      treatmentMedicines: Yup.array().when("manualOrAutoSelection", {
        is: "manual",
        then: getTreatmentMedicinesValidationSchema(
          values.treatmentPeriod ? Number(values.treatmentPeriod) : 0,
          values.billingCycleUnit
        ),
        otherwise: Yup.array(),
      }),
      supplementaryProducts: getSupplementaryProductsValidationSchema(
        values.treatmentPeriod ? Number(values.treatmentPeriod) : 0,
        values.billingCycleUnit
      ),
      invoicesWithDecision: Yup.array().of(
        Yup.string().required("Select an option").min(1, "Select an option")
      ),
    });
  };
  return Yup.lazy(callbackFnc as any);
};

export const getManualMedicines = (
  treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"],
  otherMedicineTypeId: string
) => {
  return treatmentAreasValues
    .map((t) => {
      return (
        t.medicinesSelected
          ?.filter((m) => !!m.manual && m.value !== otherMedicineTypeId)
          ?.map((m) => m.title)
          ?.join(", ") || ""
      );
    })
    .filter((r) => !!r);
};

export const getSpecifyDosingInstructionsMedicines = (
  treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"],
  otherMedicineTypeId: string
) => {
  return treatmentAreasValues
    .map((t) => {
      return (
        t.medicinesSelected
          ?.filter(
            (m) =>
              !!m.specifyDosingInstructions && m.value !== otherMedicineTypeId
          )
          ?.map((m) => m.title)
          ?.join(", ") || ""
      );
    })
    .filter((r) => !!r);
};

export const transformNameIdToTitleValue = (
  value: {
    id: string;
    name: string;
  } | null
) => {
  if (!value) {
    return null;
  }
  return {
    title: value.name,
    value: value.id,
  };
};

const getDaysInFrequency = (
  value: number | null,
  frequency: AutoCompleteOption | null
) => {
  if (isStringEqual(frequency?.value, "Days Per Week") && value) {
    const newValue = Math.floor(value / 7);
    if (newValue < 1) {
      return 1; // min value
    }
    return newValue;
  }
  return value;
};

export const getCompleteTreatmentDefaultValues = (
  treatment: ZohoTreatment | null,
  doctors: ZohoBMHDoctor[],
  client: ZohoClientRecord | null,
  treatmentTreatmentAreas: ZohoTreatmentTreatmentArea[],
  suggestedMedicines: ZohoTreatmentMedicine[],
  treatmentAreas: ZohoTreatmentArea[],
  diagnosisOptions: ZohoDiagnosisOption[]
) => {
  const suggestedMedicinesTreatmentTreatmentAreaId = suggestedMedicines.reduce<
    Record<string, ZohoTreatmentMedicine[]>
  >((total, current) => {
    const key = current.Treatment_Area.id;
    return { ...total, [key]: (total[key] || []).concat(current) };
  }, {});

  const treatmentAreasById = treatmentAreas.reduce<
    Record<string, ZohoTreatmentArea>
  >((total, current) => {
    const key = current.id;
    return { ...total, [key]: current };
  }, {});

  const treatmentDoctor = doctors.find((d) => d.id === treatment?.Doctor?.id);

  const defaultTreatmentTreatmentAreas = treatmentTreatmentAreas.map(
    (treatmentTreatmentArea) => {
      const medicinesSelected =
        suggestedMedicinesTreatmentTreatmentAreaId[treatmentTreatmentArea.id] ||
        [];
      const treatmentArea =
        treatmentAreasById[treatmentTreatmentArea.Treatment_Area.id];
      const treatmentAreaMedicineTypes = treatmentArea.medicineType;
      const treatmentAreaMedicineTypesById = treatmentAreaMedicineTypes.reduce<
        Record<string, ZohoMedicineType>
      >((total, current) => {
        const key = current.id;
        return { ...total, [key]: current };
      }, {});
      return {
        title:
          treatmentTreatmentArea.Other_Treatment_Area ||
          treatmentTreatmentArea.Treatment_Area.name,
        value: treatmentTreatmentArea.Treatment_Area.id,
        medicinesSelected: medicinesSelected.map((medicineSelected) => {
          let patternOfUse = null;

          if (medicineSelected.Cyclical_Use_Days) {
            patternOfUse = {
              value: "cyclical",
              title: "Cyclical Use",
            };
          } else if (medicineSelected.As_Required_If_Needed) {
            patternOfUse = {
              value: "required",
              title: "As Required / If Needed",
            };
          } else if (medicineSelected.Temporary_Use_Days) {
            patternOfUse = {
              value: "temporary",
              title: "Temporary Use",
            };
          }

          const medicineType =
            treatmentAreaMedicineTypesById[medicineSelected.Medicine.id];

          const frequencyOfUse = medicineSelected.Frequency_of_Use
            ? {
                title: medicineSelected.Frequency_of_Use,
                value: medicineSelected.Frequency_of_Use,
              }
            : null;
          return {
            title:
              medicineSelected.Other_Medicine_Type ||
              medicineSelected.Medicine.name,
            value: medicineSelected.Medicine.id,
            id: medicineSelected.id,
            specifyDosingInstructions: !!medicineSelected.Specify_Custom_Dosing_Instructions,
            usageNotes: medicineSelected.Usage_Notes || "",
            notesForMedicineSelection:
              medicineSelected.Notes_For_Medicine_Selection ||
              medicineSelected.Notes_for_Medicine_Selection ||
              "",
            customDosingInstruction:
              medicineSelected.Custom_Dosing_Instructions || "",
            dosingType: transformNameIdToTitleValue(
              medicineSelected.Dosing_Type
            ),
            dosingUnit: transformNameIdToTitleValue(
              medicineSelected.Dosing_Unit
            ),
            dosingAmount: medicineSelected.Dosing_Amount || null,
            noDosageAmount: !!medicineType?.No_Dosage_Amount,
            manual: !!medicineType.Manual,
            administrationRoute: transformNameIdToTitleValue(
              medicineSelected.Administration_Route
                ? {
                    ...medicineSelected.Administration_Route,
                    name:
                      medicineSelected.Other_Administration_Route ||
                      medicineSelected.Administration_Route.name,
                  }
                : null
            ),
            bodyPart: medicineSelected.Body_Part || "",
            frequencyOfUse,
            numberOfUse: medicineSelected.Number_of_Use_X || null,
            timeOfDay:
              medicineSelected.Time_of_Day?.map((t) => ({
                title: t,
                value: t,
              })) || [],
            patternOfUse,
            numberOfDays: getDaysInFrequency(
              medicineSelected.Temporary_Use_Days,
              frequencyOfUse
            ),
            cyclicalUseDays: getDaysInFrequency(
              medicineSelected.Cyclical_Use_Days,
              frequencyOfUse
            ),
            cyclicalStopDays: getDaysInFrequency(
              medicineSelected.Cyclical_Stop_Days,
              frequencyOfUse
            ),
          };
        }),
      };
    }
  );
  return {
    doctor: treatmentDoctor?.id
      ? {
          title: getDoctorName(treatmentDoctor),
          value: treatmentDoctor?.id,
        }
      : null,
    diagnosis: getDiagnosisDefaultValue(
      treatment?.Diagnosis || [],
      diagnosisOptions,
      client?.Gender
    ),
    treatmentTreatmentAreas: defaultTreatmentTreatmentAreas,
    instructions: treatment?.Other_Important_Instructions || "",
    treatmentPeriod: treatment?.Treatment_Period_in_Months || null,
  };
};
export const billingCycleUnitOptions = [
  { title: "Every X Months", value: "Months" },
  { title: "Every X Weeks", value: "Weeks" },
  { title: "Every X Days", value: "Days" },
];

export const getShowInjectionBundle = (
  dosingTypeName?: string,
  treatmentAreaName?: string
) => {
  return (
    ["hcg", "testosterone"].includes(
      treatmentAreaName?.trim()?.toLowerCase() || ""
    ) && dosingTypeName?.trim()?.toLowerCase() === "injection"
  );
};

export const getShowBacteriostatic = (
  dosingTypeName?: string,
  treatmentAreaName?: string
) => {
  return (
    ["hcg"].includes(treatmentAreaName?.trim()?.toLowerCase() || "") &&
    dosingTypeName?.trim()?.toLowerCase() === "injection"
  );
};

export const getUpdateTreatmentDefaultValues = (
  treatment: ZohoTreatment | null,
  doctors: ZohoBMHDoctor[],
  client: ZohoClientRecord | null,
  treatmentTreatmentAreas: ZohoTreatmentTreatmentArea[],
  suggestedMedicines: ZohoTreatmentMedicine[],
  treatmentAreas: ZohoTreatmentArea[],
  pharmacies: ZohoPharmacy[],
  selectedSupplementaryProducts: ZohoSelectedProduct[],
  diagnosisOptions: ZohoDiagnosisOption[]
) => {
  let billingCycleUnit = "";

  const selectedPharmaciesIds: string[] = [];

  const treatmentTreatmentAreasById = treatmentTreatmentAreas.reduce<
    Record<string, ZohoTreatmentTreatmentArea>
  >((total, current) => {
    return { ...total, [current.id]: current };
  }, {});

  const treatmentMedicines = suggestedMedicines.map<TreatmentMedicinesFormValues>(
    (suggestedMedicine) => {
      const products = suggestedMedicine.products || [];
      const selectedProduct = products.find(
        (product) => product.id === suggestedMedicine.product?.Product?.id
      );

      if (!billingCycleUnit && suggestedMedicine.product?.Billing_Cycle_Unit) {
        billingCycleUnit = suggestedMedicine.product.Billing_Cycle_Unit;
      }
      if (selectedProduct && selectedProduct.Pharmacy?.id) {
        selectedPharmaciesIds.push(selectedProduct.Pharmacy.id);
      }
      const treatmentTreatmentArea =
        treatmentTreatmentAreasById[suggestedMedicine.Treatment_Area.id];
      return {
        id: suggestedMedicine.id,
        usageNotes: suggestedMedicine.Usage_Notes || "",
        notesForMedicineSelection:
          suggestedMedicine.Notes_For_Medicine_Selection ||
          suggestedMedicine.Notes_for_Medicine_Selection ||
          "",
        selectedProductId: suggestedMedicine.product?.id || null,
        product: selectedProduct
          ? {
              title: selectedProduct.Product_Name,
              value: selectedProduct.id,
              pharmacyZohoId: selectedProduct.Pharmacy?.id,
            }
          : null,
        selfSupply: !!suggestedMedicine.product?.Self_Supply,
        onlyOnce: !!suggestedMedicine.product?.Only_Once,
        period: suggestedMedicine.product?.Billing_Cycle_Period_X || null,
        quantity: suggestedMedicine.product?.Quantity || null,
        customUnitPrice: suggestedMedicine.product?.Custom_Unit_Price || null,
        delivered: !!suggestedMedicine.product?.delivered,
        injectionBundle: {
          product: suggestedMedicine.product?.injectionBundle?.Product?.id
            ? {
                title: suggestedMedicine.product?.injectionBundle.Product.name,
                value: suggestedMedicine.product?.injectionBundle.Product.id,
              }
            : null,
          show: getShowInjectionBundle(
            suggestedMedicine.Dosing_Type.name,
            treatmentTreatmentArea.Treatment_Area.name
          ),
          selfSupply: !!suggestedMedicine.product?.injectionBundle?.Self_Supply,
          customUnitPrice:
            suggestedMedicine.product?.injectionBundle?.Custom_Unit_Price ||
            null,
          quantity:
            suggestedMedicine.product?.injectionBundle?.Quantity || null,
          selectedProductId:
            suggestedMedicine.product?.injectionBundle?.id || null,
        },
        bacteriostatic: {
          show: getShowBacteriostatic(
            suggestedMedicine.Dosing_Type.name,
            treatmentTreatmentArea.Treatment_Area.name
          ),
          product: suggestedMedicine.product?.bacteriostatic?.Product?.id
            ? {
                title: suggestedMedicine.product?.bacteriostatic.Product.name,
                value: suggestedMedicine.product?.bacteriostatic.Product.id,
              }
            : null,
          selfSupply: !!suggestedMedicine.product?.bacteriostatic?.Self_Supply,
          customUnitPrice:
            suggestedMedicine.product?.bacteriostatic?.Custom_Unit_Price ||
            null,
          quantity: suggestedMedicine.product?.bacteriostatic?.Quantity || null,
          selectedProductId:
            suggestedMedicine.product?.bacteriostatic?.id || null,
        },
      };
    }
  );

  const pharmaciesValue = pharmacies.map((pharmacy) => ({
    value: pharmacy.id,
    title: pharmacy.Name,
  }));

  const selectedPharmacies = !selectedPharmaciesIds.length
    ? pharmaciesValue
    : pharmaciesValue.filter((p) => selectedPharmaciesIds.includes(p.value));

  const selectedSupplementaryProductsValues = selectedSupplementaryProducts.map(
    (selectedSupplementaryProduct) => {
      return {
        product: selectedSupplementaryProduct.Product
          ? {
              title: selectedSupplementaryProduct.Product.name,
              value: selectedSupplementaryProduct.Product.id,
            }
          : null,
        customUnitPrice: selectedSupplementaryProduct.Custom_Unit_Price || null,
        onlyOnce: !!selectedSupplementaryProduct.Only_Once,
        selfSupply: !!selectedSupplementaryProduct.Self_Supply,
        period: selectedSupplementaryProduct.Billing_Cycle_Period_X || null,
        quantity: selectedSupplementaryProduct.Quantity || null,
        keyId: selectedSupplementaryProduct.id,
        id: selectedSupplementaryProduct.id,
        delivered: !!selectedSupplementaryProduct.delivered,
      };
    }
  );

  return {
    ...getCompleteTreatmentDefaultValues(
      treatment,
      doctors,
      client,
      treatmentTreatmentAreas,
      suggestedMedicines,
      treatmentAreas,
      diagnosisOptions
    ),
    firstPaymentDate: null,
    changeFirstInvoiceDateAfterSigning: false,
    reasonForChanges: treatment?.Requested_Changes || "",
    pharmacies: selectedPharmacies,
    billingCycleUnit: billingCycleUnitOptions.find((b) =>
      isStringEqual(b.value, billingCycleUnit)
    ) || {
      value: "Months",
      title: "Every X Months",
    },
    supplementaryProducts: selectedSupplementaryProductsValues,
    treatmentMedicines,
  };
};

const getFrequencyInDays = (
  frequency: AutoCompleteOption | null,
  value: number | null
) => {
  if (isStringEqual(frequency?.value, "Days Per Week") && value) {
    return value * 7;
  }
  return value;
};

const getTreatmentMedicinePayload = (
  medicinesSelected: TreatmentMedicineFormValues[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  otherMedicineTypeId?: string
) => {
  const otherAdminRouteID = medicineAdminRoutes.find((t) => t.Name === "Other")
    ?.id;
  const defaultMedicineValues = {
    Custom_Dosing_Instructions: null,
    Other_Medicine_Type: null,
    Specify_Custom_Dosing_Instructions: null,
    Usage_Notes: null,
    Notes_for_Medicine_Selection: null,
    Dosing_Type: null,
    Dosing_Unit: null,
    Dosing_Amount: null,
    Administration_Route: null,
    Body_Part: null,
    Frequency_of_Use: null,
    Number_of_Use_X: null,
    Time_of_Day: [],
    Pattern_of_Use: null,
    Temporary_Use_Days: null,
    Cyclical_Use_Days: null,
    Cyclical_Stop_Days: null,
    As_Required_If_Needed: false,
  };

  return (medicinesSelected || []).map((m) => {
    return {
      id: m.value,
      suggestedMedicineId: m.id,
      ...defaultMedicineValues,
      Other_Medicine_Type: m.value === otherMedicineTypeId ? m.title : null,
      Specify_Custom_Dosing_Instructions: !!m.specifyDosingInstructions,
      Usage_Notes: m.usageNotes || "",
      Notes_for_Medicine_Selection: m.notesForMedicineSelection || "",
      Notes_For_Medicine_Selection: m.notesForMedicineSelection || "",
      Dosing_Type: m.dosingType?.value || null,
      ...(m.specifyDosingInstructions
        ? {
            Custom_Dosing_Instructions: m.customDosingInstruction || "",
          }
        : {
            ...(m.noDosageAmount
              ? {}
              : {
                  Dosing_Unit: m.dosingUnit?.value || null,
                  Dosing_Amount: m.dosingAmount || null,
                }),
            Administration_Route: m.administrationRoute?.value || null,
            Other_Administration_Route:
              otherAdminRouteID === m.administrationRoute?.value
                ? m.administrationRoute?.title
                : null,
            Body_Part: m.bodyPart || null,
            Frequency_of_Use: m.frequencyOfUse?.value || null,
            Number_of_Use_X: m.numberOfUse || null,
            Time_of_Day: m.timeOfDay?.map((tOD) => tOD.value) || [],
            Pattern_of_Use: m.patternOfUse?.value || null,
            ...(isStringEqual(m.patternOfUse?.value, "temporary")
              ? {
                  Temporary_Use_Days: getFrequencyInDays(
                    m.frequencyOfUse,
                    m.numberOfDays
                  ),
                }
              : {}),
            ...(isStringEqual(m.patternOfUse?.value, "cyclical")
              ? {
                  Cyclical_Use_Days: getFrequencyInDays(
                    m.frequencyOfUse,
                    m.cyclicalUseDays
                  ),
                  Cyclical_Stop_Days: getFrequencyInDays(
                    m.frequencyOfUse,
                    m.cyclicalStopDays
                  ),
                }
              : {}),
            As_Required_If_Needed: isStringEqual(
              m.patternOfUse?.value,
              "required"
            ),
          }),
    };
  });
};

const getTreatmentTreatmentAreaPayload = (
  treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  treatmentAreas: ZohoTreatmentArea[]
) => {
  const otherTreatmentArea = treatmentAreas.find((t) =>
    isStringEqual(t.Name, "Other")
  );

  const otherMedicineTypeId = otherTreatmentArea?.medicineType.find(
    (t) => t.Name === "Other"
  )?.id;

  return treatmentAreasValues.map((t) => {
    return {
      id: t.value,
      Other_Treatment_Area: t.value === otherTreatmentArea?.id ? t.title : null,
      medicineSelected: getTreatmentMedicinePayload(
        t.medicinesSelected,
        medicineAdminRoutes,
        otherMedicineTypeId
      ),
    };
  });
};

const getTreatmentState = (
  treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"],
  otherMedicineTypeId: string,
  finalize?: boolean,
  draft?: boolean
) => {
  const hasManual = !!getManualMedicines(
    treatmentAreasValues,
    otherMedicineTypeId
  ).length;
  const hasCustomInstruction = !!getSpecifyDosingInstructionsMedicines(
    treatmentAreasValues,
    otherMedicineTypeId
  ).length;
  if (draft) {
    return "Pending Details";
  }
  return hasManual || finalize || hasCustomInstruction
    ? "Pending Review and Finalization"
    : "Details Specified";
};

export const getCreateTreatmentPayload = (
  values: any,
  client: ZohoClientRecord,
  user: ZohoCurrentUser,
  doctor: ZohoBMHDoctor | null,
  treatmentAreas: ZohoTreatmentArea[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  otherMedicineTypeId: string,
  finalize?: boolean,
  draft?: boolean
) => {
  const treatmentAreasValues = values.treatmentTreatmentAreas as TreatmentFormValues["treatmentTreatmentAreas"];

  const diagnosis = values.diagnosis as ZohoDiagnosis[];

  const bodyData = {
    State: getTreatmentState(
      treatmentAreasValues,
      otherMedicineTypeId,
      finalize,
      draft
    ),
    Go_to_Finalization_Clicked: !!finalize,
    Contact: client.id,
    Owner: user?.id || null,
    Doctor: doctor?.id ? doctor.id : values.doctor?.value || null,
    Other_Important_Instructions: values.instructions || "",
    Treatment_Period_in_Months: values.treatmentPeriod || null,
    Diagnosis: diagnosis
      .filter((item) => item.selected)
      .map((c) => c.value || c.label),
    Target_Treatment_Areas: getTreatmentTreatmentAreaPayload(
      treatmentAreasValues,
      medicineAdminRoutes,
      treatmentAreas
    ),
  };
  return bodyData;
};

export const getCompleteTreatmentPayload = (
  treatmentID: string,
  values: any,
  client: ZohoClientRecord,
  user: ZohoCurrentUser,
  doctor: ZohoBMHDoctor | null,
  treatmentAreas: ZohoTreatmentArea[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  otherMedicineTypeId: string,
  finalize?: boolean,
  draft?: boolean
) => {
  const bodyData = {
    treatmentID,
    ...getCreateTreatmentPayload(
      values,
      client,
      user,
      doctor,
      treatmentAreas,
      medicineAdminRoutes,
      otherMedicineTypeId,
      finalize,
      draft
    ),
  };
  return bodyData;
};

const newChildProduct: ChildProduct = {
  billingCycleUnit: "",
  customUnitPrice: null,
  period: null,
  product: { title: "", value: "" },
  quantity: null,
  selfSupply: false,
};

export const checkIfInjectionBundle = (medicine: MedicineSelected) => {
  return (
    ["HCG", "Testosterone"].includes(medicine?.treatmentArea.name) &&
    medicine.dosingType?.title === "Injection"
  );
};
export const checkIfBacteriostaticBundle = (medicine: MedicineSelected) => {
  return (
    medicine?.treatmentArea.name === "HCG" &&
    medicine?.dosingType?.title === "Injection"
  );
};

export const updateTreatmentMedicineProducts = (
  treatmentAreasValues: TreatmentAreaValue[],
  medicineProductFieldValues: MedicineProduct[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
) => {
  const medicines = treatmentAreasValues.reduce(
    (acc: MedicineSelected[], treatmentAreaValue: TreatmentAreaValue) => {
      return acc.concat(
        treatmentAreaValue.medicinesSelected.map((medicineSelected) => ({
          ...medicineSelected,
          treatmentArea: {
            name: treatmentAreaValue.title,
            id: treatmentAreaValue.value,
          },
        }))
      );
    },
    []
  );
  const medicineProductFieldName = getFieldName("treatmentMedicines");

  const updatedMedicineProductList: MedicineProduct[] = [];
  for (let i = 0; i < medicines.length; i += 1) {
    const medicine = medicines[i];
    const medicineProduct = medicineProductFieldValues.find(
      (m) => m.medicineId === medicine.value
    );
    if (medicineProduct) {
      if (medicine.usageNotes)
        medicineProduct.usageNotes = medicine.usageNotes || "";
      updatedMedicineProductList.push(medicineProduct);
    } else {
      const newItem: MedicineProduct = {
        medicineId: medicine.value,
        medicineName: medicine.title,
        product: { title: "", value: "" },
        billingCycle: "",
        customUnitPrice: null,
        onlyOnce: false,
        selfSupply: false,
        quantity: null,
        usageNotes: medicine.usageNotes || "",
        period: null,
        injectionBundle: newChildProduct,
        bacteriostatic: newChildProduct,
      };

      updatedMedicineProductList.push(newItem);
    }
  }
  setFieldValue(medicineProductFieldName, updatedMedicineProductList);
};

const getTreatmentMedicinesProductsPayload = (
  medicinesSelected: MedicineSelected[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  treatmentArea: { id: string; name: string },
  otherMedicineTypeId?: string
) => {
  const otherAdminRouteID = medicineAdminRoutes.find((t) => t.Name === "Other")
    ?.id;
  const defaultMedicineValues = {
    treatmentArea: null,
    Custom_Dosing_Instructions: null,
    Other_Medicine_Type: null,
    Specify_Custom_Dosing_Instructions: null,
    Usage_Notes: null,
    Notes_for_Medicine_Selection: null,
    Dosing_Type: null,
    Dosing_Unit: null,
    Dosing_Amount: null,
    Administration_Route: null,
    Body_Part: null,
    Frequency_of_Use: null,
    Number_of_Use_X: null,
    Time_of_Day: [],
    Pattern_of_Use: null,
    Temporary_Use_Days: null,
    Cyclical_Use_Days: null,
    Cyclical_Stop_Days: null,
    As_Required_If_Needed: false,
  };

  return medicinesSelected.map((m) => {
    return {
      id: m.value,
      ...defaultMedicineValues,
      treatmentArea,
      medicineType: { id: m.value, name: m.title },
      Other_Medicine_Type: m.value === otherMedicineTypeId ? m.title : null,
      Specify_Custom_Dosing_Instructions: !!m.specifyDosingInstructions,
      Usage_Notes: m.usageNotes || "",
      Notes_for_Medicine_Selection: m.notesForMedicineSelection || "",
      Notes_For_Medicine_Selection: m.notesForMedicineSelection || "",
      Dosing_Type: m.dosingType?.value || null,
      ...(m.specifyDosingInstructions
        ? {
            Custom_Dosing_Instructions: m.customDosingInstruction || "",
          }
        : {
            ...(m.noDosageAmount
              ? {}
              : {
                  Dosing_Unit: m.dosingUnit?.value || null,
                  Dosing_Amount: m.dosingAmount || null,
                }),
            Administration_Route: m.administrationRoute?.value || null,
            Other_Administration_Route:
              otherAdminRouteID === m.administrationRoute?.value
                ? m.administrationRoute?.title
                : null,
            Body_Part: m.bodyPart || null,
            Frequency_of_Use: m.frequencyOfUse?.value || null,
            Number_of_Use_X: m.numberOfUse || null,
            Time_of_Day: m.timeOfDay?.map((tOD) => tOD.value) || [],
            Pattern_of_Use: m.patternOfUse?.value || null,
            ...(isStringEqual(m.patternOfUse?.value, "temporary")
              ? {
                  Temporary_Use_Days: m.numberOfDays || null,
                }
              : {}),
            ...(isStringEqual(m.patternOfUse?.value, "cyclical")
              ? {
                  Cyclical_Use_Days: m.cyclicalUseDays || null,
                  Cyclical_Stop_Days: m.cyclicalStopDays || null,
                }
              : {}),
            As_Required_If_Needed: isStringEqual(
              m.patternOfUse?.value,
              "required"
            ),
          }),
    };
  });
};

const getTreatmentMedicineProductsPayload = (
  treatmentAreasValues: MigrateTreatmentFormValues["treatmentTreatmentAreas"],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  treatmentAreas: ZohoTreatmentArea[]
) => {
  const otherTreatmentArea = treatmentAreas.find((t) =>
    isStringEqual(t.Name, "Other")
  );

  const otherMedicineTypeId = otherTreatmentArea?.medicineType.find(
    (t) => t.Name === "Other"
  )?.id;

  const treatmentAreasSelectedValues = treatmentAreasValues.map((t) => {
    return {
      id: t.value,
      Other_Treatment_Area: t.value === otherTreatmentArea?.id ? t.title : null,
      medicineSelected: getTreatmentMedicinesProductsPayload(
        t.medicinesSelected,
        medicineAdminRoutes,
        { id: t.value, name: t.title },
        otherMedicineTypeId
      ),
    };
  });
  return treatmentAreasSelectedValues;
};

export const getMigrateTreatmentPayload = (
  values: any,
  details: MigrateTreatmentData,
  invoices: ZohoInvoice[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  treatmentAreas: ZohoTreatmentArea[]
) => {
  const invoicesWithDecision = values.invoicesWithDecision || [];
  const sentInvoices = invoices.filter(
    (_, index) => invoicesWithDecision[index] === "void"
  );

  const treatmentAreasValues = values.treatmentTreatmentAreas as MigrateTreatmentFormValues["treatmentTreatmentAreas"];

  const structuredMedicinesData = getTreatmentMedicineProductsPayload(
    treatmentAreasValues,
    medicineAdminRoutes,
    treatmentAreas
  );

  const allMedicines = structuredMedicinesData.reduce(
    (acc: MedicineSelected[], treatmentArea: any) => {
      return acc.concat(treatmentArea.medicineSelected);
    },
    []
  );

  const allProducts = values.treatmentMedicines.concat(
    values.supplementaryProducts
  ) as MedicineProduct[];
  const billingCycleUnit = values.billingCycleUnit as AutoCompleteOption;
  const diagnosis = values.diagnosis as ZohoDiagnosis[];

  return {
    useExistingRecord: values.manualOrAutoSelection === "manual",
    current_user: { id: details.treatment?.Owner?.id },
    selectedCTTP: { id: values.legacyCttp },
    treatment: {
      id: details.treatment?.id,
      Diagnosis: diagnosis
        .filter((diag) => diag.selected)
        .map((d) => d.value || d.label),
      First_Payment_Date: moment(values.firstPaymentDate).format("YYYY-MM-DD"),
      Rejection_Reason: details.treatment?.Rejection_Reason,
      Other_Important_Instructions: values.instructions,
      Migrated: false,
      Doctor: {
        id: values.doctor.value,
        name: values.doctor.title,
      },
      Contact: {
        ...details.treatment?.Contact,
        Next_Check_up_Date: moment(values.nextCheckupDate).format("YYYY-MM-DD"),
        Next_Check_up_Type: values.nextCheckupType?.value,
      },
      Type: "Treatment Update",
      Treatment_Period_in_Months: values.treatmentPeriod,
      treatmentAreas: structuredMedicinesData.map((treatmentArea) => ({
        id: treatmentArea?.id,
        ...(treatmentArea.Other_Treatment_Area
          ? { Other_Treatment_Area: treatmentArea.Other_Treatment_Area }
          : {}),
      })),
      suggestedMedicines: allMedicines,
      selectedProducts: allProducts.map((product) => ({
        ...product,
        ...(product.injectionBundle?.product?.value ||
        product.bacteriostatic?.product?.value
          ? {
              childrenItems: [
                product.injectionBundle,
                product.bacteriostatic,
              ].filter((i) => !!i?.product?.value),
            }
          : { childrenItems: [] }),
        billingCycleUnit: billingCycleUnit.value,
      })),
    },
    nextCheckupType: values.nextCheckupType?.value,
    sentInvoices,
    nextCheckupDate: moment(values.nextCheckupDate).format("YYYY-MM-DD"),
    recurringInvoices: details.recurringInvoices,
    draftInvoice: invoices.filter((i) => i.status === "draft"),
  };
};

export const filterAndSortInvoices = (invoices: ZohoInvoice[]) =>
  invoices
    .filter((invoice) => !["paid", "draft", "void"].includes(invoice.status))
    .sort(
      (a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
    );

const createIdentifier = (a: AutoCompleteOption | null) => {
  return `${a?.value || ""}-${a?.title || ""}}`;
};

const compareValuesObj = (a: AutoCompleteOption, b: AutoCompleteOption) => {
  return isStringEqual(createIdentifier(a), createIdentifier(b));
};

const isNumbersSame = (a: number | null, b: number | null) => {
  if ((a === null || a === undefined) && (b === null || b === undefined)) {
    return true;
  }
  return Number(a) === Number(b);
};

const isBooleanSame = (a: boolean | null, b: boolean | null) => {
  if ((a === null || a === undefined) && (b === null || b === undefined)) {
    return true;
  }
  return !!a === !!b;
};

const isStringSame = (a: string | null, b: string | null) => {
  if ((a === null || a === undefined) && (b === null || b === undefined)) {
    return true;
  }
  return String(a).trim() === String(b).trim();
};

const isAutoCompleteSame = (
  a: AutoCompleteOption | null,
  b: AutoCompleteOption | null
) => {
  if ((a === null || a === undefined) && (b === null || b === undefined)) {
    return true;
  }
  return createIdentifier(a) === createIdentifier(b);
};

export const getUpdateTreatmentChanges = (
  values: any,
  initialValues: any,
  name: string,
  activeTab: number
) => {
  let requirePackageAndPharmacySelection = false;
  let requirePrescriptionAndCttpChange = false;
  let requirePrescriptionChange = false;
  let treatmentHasAnyChange = false;
  let treatmentHasCustomUnitPriceChanged = false;

  /**
   * REASON FOR CHANGES
   */
  const reasonForChangesFieldName = getFieldName("reasonForChanges", name);
  const initialReasonForChanges: string = getValueFromObject(
    reasonForChangesFieldName,
    initialValues
  );
  const reasonForChanges: string = getValueFromObject(
    reasonForChangesFieldName,
    values
  );
  if (!isStringSame(reasonForChanges, initialReasonForChanges)) {
    treatmentHasAnyChange = true;
  }

  /**
   * DOCTOR FIELD
   */
  const doctorFieldName = getFieldName("doctor", name);
  const initialDoctor: AutoCompleteOption = getValueFromObject(
    doctorFieldName,
    initialValues
  );
  const doctor: AutoCompleteOption = getValueFromObject(
    doctorFieldName,
    values
  );
  if (!isAutoCompleteSame(doctor, initialDoctor)) {
    requirePrescriptionAndCttpChange = true;
    treatmentHasAnyChange = true;
  }

  /**
   * DIAGNOSIS FIELD
   */
  const diagnosisFieldName = getFieldName("diagnosis", name);
  const initialDiagnosis: ZohoDiagnosis[] = getValueFromObject(
    diagnosisFieldName,
    initialValues
  );
  const diagnosis: ZohoDiagnosis[] = getValueFromObject(
    diagnosisFieldName,
    values
  );
  const getIsDiagnosisDifferent = (a: ZohoDiagnosis[], b: ZohoDiagnosis[]) => {
    const selectedA = a.filter((i) => i.selected);
    const selectedB = b.filter((i) => i.selected);
    if (selectedA.length !== selectedB.length) {
      return true;
    }
    const selectedALabels = selectedA.map((i) => i.label);
    const selectedBLabels = selectedB.map((i) => i.label);
    if (selectedALabels.some((i) => !selectedBLabels.includes(i))) {
      return true;
    }
    return false;
  };
  if (getIsDiagnosisDifferent(diagnosis, initialDiagnosis)) {
    treatmentHasAnyChange = true;
  }

  /**
   * BILLING CYCLE UNIT FIELD
   */
  const billingCycleUnitFieldName = getFieldName("billingCycleUnit", name);
  const initialBillingCycleUnit: AutoCompleteOption = getValueFromObject(
    billingCycleUnitFieldName,
    initialValues
  );
  const billingCycleUnit: AutoCompleteOption = getValueFromObject(
    billingCycleUnitFieldName,
    values
  );
  if (!isAutoCompleteSame(initialBillingCycleUnit, billingCycleUnit)) {
    requirePrescriptionAndCttpChange = true;
    treatmentHasAnyChange = true;
  }

  /**
   * INSTRUCTIONS FIELD
   */
  const instructionsFieldName = getFieldName("instructions", name);
  const initialInstructions: string = getValueFromObject(
    instructionsFieldName,
    initialValues
  );
  const instructions: string = getValueFromObject(
    instructionsFieldName,
    values
  );
  if (!isStringSame(initialInstructions, instructions)) {
    requirePrescriptionAndCttpChange = true;
    treatmentHasAnyChange = true;
  }

  /**
   * PERIOD FIELD
   */
  const treatmentPeriodFieldName = getFieldName("treatmentPeriod", name);
  const initialTreatmentPeriod: number = getValueFromObject(
    treatmentPeriodFieldName,
    initialValues
  );
  const treatmentPeriod: number = getValueFromObject(
    treatmentPeriodFieldName,
    values
  );
  if (!isNumbersSame(treatmentPeriod, initialTreatmentPeriod)) {
    requirePrescriptionChange = true;
    treatmentHasAnyChange = true;
  }

  /**
   * TREATMENT AREAS FIELD
   */
  const treatmentTreatmentAreasFieldName = getFieldName(
    "treatmentTreatmentAreas",
    name
  );
  const initialTreatmentAreaValue: TreatmentFormValues["treatmentTreatmentAreas"] =
    getValueFromObject(treatmentTreatmentAreasFieldName, initialValues) || [];
  const treatmentAreasValues: TreatmentFormValues["treatmentTreatmentAreas"] =
    getValueFromObject(treatmentTreatmentAreasFieldName, values) || [];

  const getIsMedicineTimeOfDaysDifferent = (
    a: AutoCompleteOption[],
    b: AutoCompleteOption[]
  ) => {
    /**
     * TIME OF DAY FIELD
     */
    if (a.length !== b.length) {
      requirePackageAndPharmacySelection = true;
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }
    const aValues = a.map((i) => i.value);
    const bValues = b.map((i) => i.value);
    if (aValues.some((i) => !bValues.includes(i))) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }
  };

  const getIsMedicineValuesDifferent = (
    a: TreatmentMedicineFormValues,
    b: TreatmentMedicineFormValues | null
  ) => {
    /**
     * MEDICINE FIELD
     */
    if (!b) {
      requirePrescriptionAndCttpChange = true;
      requirePackageAndPharmacySelection = true;
      treatmentHasAnyChange = true;
      return;
    }

    const isSpecifyCustomDosingInstructionsSame = isBooleanSame(
      a.specifyDosingInstructions,
      b.specifyDosingInstructions
    );

    const isUsageNotesSame = isStringSame(a.usageNotes, b.usageNotes);
    const isNotesForMedicineSelectionSame = isStringSame(
      a.notesForMedicineSelection,
      b.notesForMedicineSelection
    );

    const isCustomDosingInstructionsSame = isStringSame(
      a.customDosingInstruction,
      b.customDosingInstruction
    );
    const isDosingTypeSame = isAutoCompleteSame(a.dosingType, b.dosingType);

    if (!isSpecifyCustomDosingInstructionsSame) {
      requirePackageAndPharmacySelection = true;
      treatmentHasAnyChange = true;
      return;
    }

    if (a.specifyDosingInstructions) {
      if (!isCustomDosingInstructionsSame || !isDosingTypeSame) {
        requirePackageAndPharmacySelection = true;
        treatmentHasAnyChange = true;
      }
      if (!isUsageNotesSame || !isNotesForMedicineSelectionSame) {
        requirePrescriptionAndCttpChange = true;
        treatmentHasAnyChange = true;
      }
      return;
    }

    const dosingAmountSame = isNumbersSame(a.dosingAmount, b.dosingAmount);

    const isDosingUnitSame = isAutoCompleteSame(a.dosingUnit, b.dosingUnit);

    const isPatterOfUseSame = isAutoCompleteSame(
      a.patternOfUse,
      b.patternOfUse
    );

    const isFrequencyOfUseSame = isAutoCompleteSame(
      a.frequencyOfUse,
      b.frequencyOfUse
    );

    const isNumberOfUseSame = isNumbersSame(a.numberOfUse, b.numberOfUse);
    const isAdministrationRouteSame = isAutoCompleteSame(
      a.administrationRoute,
      b.administrationRoute
    );
    const isBodyPartSame = isStringSame(a.bodyPart, b.bodyPart);

    const isNumberOfDaysSame =
      a.patternOfUse?.value === "temporary"
        ? isNumbersSame(a.numberOfDays, b.numberOfDays)
        : true;

    const isCyclicalStopDaysSame =
      a.patternOfUse?.value === "cyclical"
        ? isNumbersSame(a.cyclicalStopDays, b.cyclicalStopDays)
        : true;
    const isCyclicalUseDaysSame =
      a.patternOfUse?.value === "cyclical"
        ? isNumbersSame(a.cyclicalUseDays, b.cyclicalUseDays)
        : true;

    /**
     * MEDICINE FIELDS
     */
    if (
      !isBodyPartSame ||
      !isAdministrationRouteSame ||
      !isNumberOfUseSame ||
      !isFrequencyOfUseSame ||
      !isPatterOfUseSame ||
      !isDosingUnitSame ||
      !isDosingTypeSame ||
      !isCustomDosingInstructionsSame ||
      !isSpecifyCustomDosingInstructionsSame ||
      !dosingAmountSame ||
      !isNumberOfDaysSame ||
      !isCyclicalStopDaysSame ||
      !isCyclicalUseDaysSame
    ) {
      requirePackageAndPharmacySelection = true;
      treatmentHasAnyChange = true;
    }

    const isRequiredPatterOfUseToggle =
      (a.patternOfUse?.value === "required" &&
        b.patternOfUse?.value !== "required") ||
      (b.patternOfUse?.value === "required" &&
        a.patternOfUse?.value !== "required");
    if (
      !isUsageNotesSame ||
      !isNotesForMedicineSelectionSame ||
      isRequiredPatterOfUseToggle
    ) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }

    getIsMedicineTimeOfDaysDifferent(a.timeOfDay || [], b.timeOfDay || []);
  };

  const getIsMedicineDifferent = (
    i: TreatmentMedicineFormValues,
    b: TreatmentMedicineFormValues[]
  ) => {
    /**
     * MEDICINE TYPES FIELD
     */
    if (!b.map((t) => createIdentifier(t)).includes(createIdentifier(i))) {
      requirePackageAndPharmacySelection = true;
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
      return;
    }

    // Check each medicine type
    getIsMedicineValuesDifferent(
      i,
      b.find((j) => compareValuesObj(i, j)) || null
    );
  };

  const getIsMedicinesDifferent = (
    a: TreatmentMedicineFormValues[],
    b: TreatmentMedicineFormValues[]
  ) => {
    /**
     * MEDICINES FILED
     */
    if (a.length !== b.length) {
      requirePackageAndPharmacySelection = true;
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
      return;
    }
    //  Check each medicine selected
    a.some((i) => getIsMedicineDifferent(i, b));
  };

  const getIsTreatmentAreaDifferent = (
    i: TreatmentTreatmentAreaFormValues,
    b: TreatmentTreatmentAreaFormValues[]
  ) => {
    /**
     * TREATMENT AREAS FILED
     */
    if (!b.map((t) => createIdentifier(t)).includes(createIdentifier(i))) {
      requirePackageAndPharmacySelection = true;
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
      return;
    }
    // Check medicines selected
    getIsMedicinesDifferent(
      i.medicinesSelected,
      b.find((j) => compareValuesObj(i, j))?.medicinesSelected || []
    );
  };

  const getIsTreatmentAreasDifferent = (
    a: TreatmentTreatmentAreaFormValues[],
    b: TreatmentTreatmentAreaFormValues[]
  ) => {
    /**
     * TREATMENT AREAS FILED
     */
    if (a.length !== b.length) {
      requirePackageAndPharmacySelection = true;
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
      return;
    }
    // Check each treatment area selected
    a.some((i) => getIsTreatmentAreaDifferent(i, b));
  };

  //  Check medicine products  selected
  if (activeTab === 0) {
    getIsTreatmentAreasDifferent(
      treatmentAreasValues,
      initialTreatmentAreaValue
    );
  }

  const getIsProductDifferent = (
    a: TreatmentMedicinesFormValues,
    b: TreatmentMedicinesFormValues
  ) => {
    const isProductSame = isAutoCompleteSame(a.product, b.product);
    const isQuantitySame = isNumbersSame(a.quantity, b.quantity);
    const isUsageNotesSame = isStringSame(a.usageNotes, b.usageNotes);
    const isNotesForMedicineSelectionSame = isStringSame(
      a.notesForMedicineSelection,
      b.notesForMedicineSelection
    );
    const isCustomUnitPriceSame = isNumbersSame(
      a.customUnitPrice,
      b.customUnitPrice
    );
    const isOnlyOnceSame = isBooleanSame(a.onlyOnce, b.onlyOnce);
    const isPeriodSame = isNumbersSame(a.period, b.period);

    const injectionBundleProductSame = isAutoCompleteSame(
      a.injectionBundle?.product,
      b.injectionBundle?.product
    );
    const injectionBundleQuantitySame = isNumbersSame(
      a.injectionBundle?.quantity,
      b.injectionBundle?.quantity
    );
    const injectionBundleCustomUnitPriceSame = isNumbersSame(
      a.injectionBundle?.customUnitPrice,
      b.injectionBundle?.customUnitPrice
    );
    const bacteriostaticProductSame = isAutoCompleteSame(
      a.bacteriostatic?.product,
      b.bacteriostatic?.product
    );
    const bacteriostaticQuantitySame = isNumbersSame(
      a.bacteriostatic?.quantity,
      b.bacteriostatic?.quantity
    );
    const bacteriostaticCustomUnitPriceSame = isNumbersSame(
      a.bacteriostatic?.customUnitPrice,
      b.bacteriostatic?.customUnitPrice
    );

    const isSelfSupplySame = isBooleanSame(a.selfSupply, b.selfSupply);
    const isIBSelfSupplySame = isBooleanSame(
      a.injectionBundle?.selfSupply,
      b.injectionBundle?.selfSupply
    );
    const isBactSelfSupplySame = isBooleanSame(
      a.bacteriostatic?.selfSupply,
      b.bacteriostatic?.selfSupply
    );

    if (
      !isProductSame ||
      !isQuantitySame ||
      !isUsageNotesSame ||
      !isNotesForMedicineSelectionSame ||
      !isOnlyOnceSame ||
      !isPeriodSame ||
      !injectionBundleProductSame ||
      !injectionBundleQuantitySame ||
      !bacteriostaticProductSame ||
      !bacteriostaticQuantitySame
    ) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }

    if (
      !injectionBundleCustomUnitPriceSame ||
      !bacteriostaticCustomUnitPriceSame ||
      !isCustomUnitPriceSame
    ) {
      treatmentHasCustomUnitPriceChanged = true;
      treatmentHasAnyChange = true;
    }

    if (!isSelfSupplySame || !isIBSelfSupplySame || !isBactSelfSupplySame) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }
  };

  const getIsSuggestedMedicineDifferent = (
    i: TreatmentMedicinesFormValues,
    b: TreatmentMedicinesFormValues[]
  ) => {
    return getIsProductDifferent(i, b.find((j) => j.id === i.id)!);
  };

  const getIsSuggestedMedicinesDifferent = (
    a: TreatmentFormValues["treatmentMedicines"],
    b: TreatmentFormValues["treatmentMedicines"]
  ) => {
    return a.some((i) => getIsSuggestedMedicineDifferent(i, b));
  };

  /**
   * TREATMENT MEDICINES FIELD
   */
  const treatmentMedicinesFieldName = getFieldName("treatmentMedicines", name);
  const initialTreatmentMedicinesValue: TreatmentFormValues["treatmentMedicines"] =
    getValueFromObject(treatmentMedicinesFieldName, initialValues) || [];
  const treatmentMedicinesValues: TreatmentFormValues["treatmentMedicines"] =
    getValueFromObject(treatmentMedicinesFieldName, values) || [];
  if (activeTab === 1) {
    getIsSuggestedMedicinesDifferent(
      initialTreatmentMedicinesValue,
      treatmentMedicinesValues
    );
  }

  const getIsSupplementaryProductValuesDifferent = (
    a: SupplementaryProductValues,
    b: SupplementaryProductValues
  ) => {
    const isProductSame = isAutoCompleteSame(a.product, b.product);
    const isQuantitySame = isNumbersSame(a.quantity, b.quantity);
    const isCustomUnitPriceSame = isNumbersSame(
      a.customUnitPrice,
      b.customUnitPrice
    );
    const isOnlyOnceSame = isBooleanSame(a.onlyOnce, b.onlyOnce);
    const isPeriodSame = isNumbersSame(a.period, b.period);

    if (!isProductSame || !isQuantitySame || !isOnlyOnceSame || !isPeriodSame) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
    }

    if (!isCustomUnitPriceSame) {
      treatmentHasCustomUnitPriceChanged = true;
      treatmentHasAnyChange = true;
    }
  };

  const getIsSupplementaryProductDifferent = (
    i: SupplementaryProductValues,
    b: SupplementaryProductValues[]
  ) => {
    return getIsSupplementaryProductValuesDifferent(
      i,
      b.find((j) => j.product?.value === i.product?.value)!
    );
  };

  const getIsSupplementaryProductsDifferent = (
    a: TreatmentFormValues["supplementaryProducts"],
    b: TreatmentFormValues["supplementaryProducts"]
  ) => {
    if (a.length !== b.length) {
      requirePrescriptionAndCttpChange = true;
      treatmentHasAnyChange = true;
      return true;
    }
    return a.some((i) => getIsSupplementaryProductDifferent(i, b));
  };
  /**
   * SUPPLEMENTARY PRODUCTS FIELD
   */
  const supplementaryProductsFieldName = getFieldName(
    "supplementaryProducts",
    name
  );
  const initialSupplementaryProductsValue: TreatmentFormValues["supplementaryProducts"] =
    getValueFromObject(supplementaryProductsFieldName, initialValues) || [];
  const supplementaryProductsValues: TreatmentFormValues["supplementaryProducts"] =
    getValueFromObject(supplementaryProductsFieldName, values) || [];
  if (activeTab === 1) {
    getIsSupplementaryProductsDifferent(
      initialSupplementaryProductsValue,
      supplementaryProductsValues
    );
  }
  return {
    requirePackageAndPharmacySelection,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
    treatmentHasCustomUnitPriceChanged,
    treatmentHasAnyChange:
      requirePackageAndPharmacySelection ||
      requirePrescriptionAndCttpChange ||
      requirePrescriptionChange ||
      treatmentHasCustomUnitPriceChanged ||
      treatmentHasAnyChange,
  };
};

export const getUpdateTreatmentPayload = (
  activeTab: number,
  treatment: ZohoTreatment,
  values: any,
  initialValues: any,
  client: ZohoClientRecord,
  user: ZohoCurrentUser,
  doctor: ZohoBMHDoctor | null,
  treatmentAreas: ZohoTreatmentArea[],
  medicineAdminRoutes: ZohoMedicineAdminRoute[],
  previousState: string,
  otherMedicineTypeId: string,
  finalize?: boolean
) => {
  const treatmentMedicines = values.treatmentMedicines as TreatmentFormValues["treatmentMedicines"];
  const billingCycleUnit = values.billingCycleUnit as AutoCompleteOption;
  const medicines = treatmentMedicines.map((item) => {
    const newData = {
      id: item.id,
      selectedProductId: item.selectedProductId || null,
      usageNotes: item.usageNotes || null,
      notesForMedicineSelection: item.notesForMedicineSelection || null,
      productId: item.product?.value || null,
      onlyOnce: !!item.onlyOnce,
      billingCycleUnit: billingCycleUnit.value || null,
      billingCyclePeriod: item.period || null,
      quantity: item.quantity || null,
      selfSupply: !!item.selfSupply,
      delivered: !!item.delivered,
      customUnitPrice: item.customUnitPrice
        ? Number(Number(item.customUnitPrice).toFixed(2))
        : null,
      ...(item.injectionBundle?.product?.value
        ? {
            injectionBundle: {
              selectedProductId:
                item.injectionBundle?.selectedProductId || null,
              id: item.injectionBundle?.product.value,
              productId: item.injectionBundle?.product.value,
              quantity: item.injectionBundle?.quantity,
              selfSupply: item.injectionBundle?.selfSupply,
              customUnitPrice: item.injectionBundle?.customUnitPrice
                ? Number(
                    Number(item.injectionBundle?.customUnitPrice).toFixed(2)
                  )
                : null,
            },
          }
        : {}),
      ...(item.bacteriostatic?.product?.value
        ? {
            bacteriostatic: {
              selectedProductId: item.bacteriostatic?.selectedProductId || null,
              id: item.bacteriostatic?.product?.value || null,
              productId: item.bacteriostatic?.product?.value || null,
              quantity: item.bacteriostatic?.quantity,
              selfSupply: item.bacteriostatic?.selfSupply,
              customUnitPrice: item.bacteriostatic?.customUnitPrice
                ? Number(
                    Number(item.bacteriostatic?.customUnitPrice).toFixed(2)
                  )
                : null,
            },
          }
        : {}),
    };
    return newData;
  });

  const supplementaryProducts = doctor
    ? []
    : (values.supplementaryProducts as SupplementaryProductValues[]).map(
        (item) => {
          const newData = {
            id: item.id || null,
            productId: item.product?.value,
            quantity: item.quantity || null,
            onlyOnce: !!item?.onlyOnce,
            delivered: !!item?.delivered,
            billingCycleUnit: billingCycleUnit.value,
            billingCyclePeriod: item.period || null,
            customUnitPrice: item.customUnitPrice
              ? Number(Number(item.customUnitPrice).toFixed(2))
              : null,
          };
          return newData;
        }
      );

  const createTreatmentValues = getCreateTreatmentPayload(
    values,
    client,
    user,
    doctor,
    treatmentAreas,
    medicineAdminRoutes,
    otherMedicineTypeId,
    finalize
  );
  const result = {
    treatmentZohoId: treatment.id,
    doctorZohoId: createTreatmentValues.Doctor,
    userId: user?.id || "267997000000237001",
    diagnosis: createTreatmentValues.Diagnosis,
    targetTreatmentAreas: createTreatmentValues.Target_Treatment_Areas.map(
      (treatmentArea) => ({
        id: treatmentArea.id,
        Other_Treatment_Area: treatmentArea.Other_Treatment_Area,
        medicineSuggested: treatmentArea.medicineSelected.map((medicine) => {
          const medicineTypes =
            treatmentAreas.find((t) => t.id === treatmentArea.id)
              ?.medicineType || [];
          const medicineType = medicineTypes.find((m) => m.id === medicine.id)!;
          return {
            ...medicine,
            id: medicine.suggestedMedicineId,
            Medicine: medicineType.id,
            ...(doctor?.id
              ? {
                  Selected_Products: [],
                }
              : {}),
            medicineType: {
              Manual: !!medicineType.Manual,
            },
          };
        }),
      })
    ),
    ...(doctor?.id ? {} : { supplementaryProducts, medicines }),
    importantInstruction: createTreatmentValues.Other_Important_Instructions,
    numberOfMonths: createTreatmentValues.Treatment_Period_in_Months,
    firstPaymentDate: values.chargeInvoiceAfterDocumentSigning
      ? format(values.firstPaymentDate, "YYYY-MM-DD")
      : null,
    chargeInvoiceAfterDocumentSigning: !!values.changeFirstInvoiceDateAfterSigning,
    ...(doctor?.id ? { reasonForChanges: values.reasonForChanges } : {}),
    form: activeTab === 0 ? "treatment" : "products",
  };

  const {
    requirePackageAndPharmacySelection,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
    treatmentHasAnyChange,
    treatmentHasCustomUnitPriceChanged,
  } = getUpdateTreatmentChanges(values, initialValues, "", activeTab);

  const bodyData = {
    data: result,
    Go_to_Finalization_Clicked: !!finalize,
    requirePharmacySelectionAndAPackageChange: requirePackageAndPharmacySelection,
    requirePrescriptionAndCttpChange,
    requirePrescriptionChange,
    treatmentHasAnyChange,
    treatmentHasCustomUnitPriceChanged,
    state: treatment.State,
    previousState,
    isBmhDoctor: !!doctor?.id,
  };
  return bodyData;
};

const getPeriod = (
  isGlobalBillingCycleUnit: boolean,
  period: number,
  billingCycleUnit?: AutoCompleteOption | null
) => {
  if (isGlobalBillingCycleUnit || !period || !billingCycleUnit) {
    return period;
  }
  return getBillingCycleInDays(billingCycleUnit, period);
};

const handleIsMultiple = () => {
  let isMultipleFailed = false;
  const checkedPeriods: {
    period: number | null;
    onlyOnce: boolean;
    billingCycleUnit?: AutoCompleteOption | null;
  }[] = [];

  const checkForIsMultiple = (
    item: {
      period: number | null;
      onlyOnce: boolean;
      billingCycleUnit?: AutoCompleteOption | null;
    },
    isGlobalBillingCycleUnit = true
  ) => {
    const checkedPeriodsLength = checkedPeriods.length;
    const prevCheckPeriod = checkedPeriods[checkedPeriodsLength - 1];
    if (!item.onlyOnce && item.period) {
      if (prevCheckPeriod?.period) {
        const itemPeriod = getPeriod(
          isGlobalBillingCycleUnit,
          item.period,
          item.billingCycleUnit
        );
        const prevPeriod = getPeriod(
          isGlobalBillingCycleUnit,
          prevCheckPeriod.period,
          prevCheckPeriod.billingCycleUnit
        );
        const [numerator, denominator] = [
          itemPeriod >= prevPeriod ? itemPeriod : prevPeriod,
          itemPeriod < prevPeriod ? itemPeriod : prevPeriod,
        ];
        const division = numerator / denominator;
        if (Math.floor(division) !== division) {
          isMultipleFailed = true;
        }
      }

      checkedPeriods.push({
        period: item.period,
        onlyOnce: item.onlyOnce,
        billingCycleUnit: item.billingCycleUnit,
      });
    }
  };

  const getIsMultipleFailed = () => {
    return isMultipleFailed;
  };

  return {
    getIsMultipleFailed,
    checkForIsMultiple,
  };
};

const handleIsSelfSupplySame = () => {
  let selfSupplyFailed = false;
  const checkedSelfSupplyPeriods: {
    period: number | null;
    onlyOnce: boolean;
    billingCycleUnit?: AutoCompleteOption | null;
  }[] = [];

  const checkForIsSelfSupplySame = (
    item: {
      selfSupply: boolean;
      period: number | null;
      onlyOnce: boolean;
      billingCycleUnit?: AutoCompleteOption | null;
      injectionBundle?: {
        selfSupply: boolean;
      };
      bacteriostatic?: {
        selfSupply: boolean;
      };
    },
    isGlobalBillingCycleUnit = true
  ) => {
    if (
      item?.selfSupply ||
      item?.injectionBundle?.selfSupply ||
      item?.bacteriostatic?.selfSupply
    ) {
      const checkedSelfSupplyPeriodsLength = checkedSelfSupplyPeriods.length;
      const prev = checkedSelfSupplyPeriods[checkedSelfSupplyPeriodsLength - 1];
      if (prev) {
        if (item.onlyOnce) {
          if (!isBooleanSame(item.onlyOnce, prev.onlyOnce)) {
            selfSupplyFailed = true;
          }
        } else if (item.period) {
          const itemPeriod = getPeriod(
            isGlobalBillingCycleUnit,
            item.period,
            item.billingCycleUnit
          );
          const prevPeriod = getPeriod(
            isGlobalBillingCycleUnit,
            prev.period || 0,
            prev.billingCycleUnit
          );
          if (!isNumbersSame(itemPeriod, prevPeriod)) {
            selfSupplyFailed = true;
          }
        }
      }
      checkedSelfSupplyPeriods.push({
        period: item.period,
        onlyOnce: item.onlyOnce,
        billingCycleUnit: item.billingCycleUnit,
      });
    }
  };

  const getIsSelfSuppliedFailed = () => {
    return selfSupplyFailed;
  };

  return {
    getIsSelfSuppliedFailed,
    checkForIsSelfSupplySame,
  };
};

export const getUpdateTreatmentBillingCycleValidation = (
  values: any,
  name: string
) => {
  const { getIsMultipleFailed, checkForIsMultiple } = handleIsMultiple();

  const {
    getIsSelfSuppliedFailed,
    checkForIsSelfSupplySame,
  } = handleIsSelfSupplySame();

  const treatmentMedicinesFieldName = getFieldName("treatmentMedicines", name);
  const supplementaryProductsFieldName = getFieldName(
    "supplementaryProducts",
    name
  );

  const treatmentMedicinesValues: TreatmentFormValues["treatmentMedicines"] =
    getValueFromObject(treatmentMedicinesFieldName, values) || [];
  const supplementaryProductsValues: TreatmentFormValues["supplementaryProducts"] =
    getValueFromObject(supplementaryProductsFieldName, values) || [];

  supplementaryProductsValues.forEach((supplementaryProductValues) => {
    checkForIsMultiple(supplementaryProductValues);
  });

  treatmentMedicinesValues.forEach((treatmentMedicineValues) => {
    checkForIsMultiple(treatmentMedicineValues);
    checkForIsSelfSupplySame(treatmentMedicineValues);
  });

  return {
    selfSupplyFailed: getIsSelfSuppliedFailed(),
    isMultipleFailed: getIsMultipleFailed(),
  };
};

export const arrayToSentence = (arr: string[]): string => {
  if (arr.length === 0) {
    return "";
  }
  if (arr.length === 1) {
    return arr[0];
  }
  if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`;
  }
  return `${arr.slice(0, -1).join(", ")}, and ${arr[arr.length - 1]}`;
};

export const mapZohoCrmProductsToDbProducts = (
  products: ZohoProduct[],
  medsUsageUnit: Record<string, ZohoMedUsageUnit>
) => {
  return (products || []).map((product) => ({
    ZohoCrmId: product.id,
    ProductName: product.Product_Name,
    UnitPrice: product.Unit_Price,
    EnoughFor: product.Enough_For,
    UsageUnitVolumeml: product.Usage_Unit_Volume_ml,
    PharmacyName: product.Pharmacy.name,
    DosagePerml: product.Dosage_Per_ml,
    SyringeVolumeml: product.Syringe_Volume_ml,
    MedsUsageUnit: {
      DiscardRemaining:
        product.Med_Usage_Unit?.id && medsUsageUnit
          ? !!medsUsageUnit[product.Med_Usage_Unit?.id]?.Discard_Remaining
          : false,
      Name: product.Med_Usage_Unit?.name || "",
    },
    unitsPerSellableUnit: product.Usage_Units_Per_Sellable_Unit,
    Description: product.Description,
    pharmacyZohoId: product.Pharmacy?.id,
  }));
};

export const venesectionTreatmentValidationSchema = Yup.object({
  doctor: doctorValidation,
  venesectionInstructions: Yup.string()
    .required("Venesection instructions is required")
    .test(
      "is-not-blank",
      "Venesection instructions cannot be empty spaces",
      (value) => (value || "").trim().length > 0
    ),
});

export const countryValidation = Yup.object()
  .required("Country is required")
  .typeError("Country is required")
  .test("Select Country", "Country is required", (value) => {
    return !!(value as any)?.value;
  });

export const manualOrderValidationSchema = Yup.object().shape({
  country: countryValidation,
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("Postal code is required"),
  street: Yup.string().required("Street is required"),
  apartment: Yup.string().nullable().optional(),
  orderItems: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().optional(),
      isSelected: Yup.boolean(),
      name: Yup.string().optional(),
      remainingQty: Yup.number(),
      quantity: Yup.number().when("isSelected", {
        is: true,
        then: Yup.number()
          .required("Quantity is required")
          .min(1, "Quantity must be at least 1")
          .max(
            Yup.ref("remainingQty"),
            ({ max }) => `Quantity must be at most ${max}`
          ),
      }),
      notes: Yup.string().when("isSelected", {
        is: true,
        then: Yup.string()
          .required("Usage Notes are required")
          .test(
            "is-not-blank",
            "Usage Notes cannot be empty spaces",
            (value) => (value || "").trim().length > 0
          ),
      }),
    })
  ),
});

export const pauseTreatmentValidationSchema = Yup.object().shape({
  resumptionDate: Yup.string().when("choice", {
    is: "YES",
    then: Yup.string().required("Resumption date is required"),
  }),
  reason: Yup.string().required("Reason for pausing treatment is required"),
  choice: Yup.mixed<"YES" | "NO">()
    .oneOf(["YES", "NO"])
    .required("This field is required"),
});

export const resumeTreatmentValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required("Reason for continuing treatment is required")
    .test(
      "is-not-blank",
      "Reason for continuing treatment cannot be empty spaces",
      (value) => (value || "").trim().length > 0
    ),
});

export const cancelTreatmentValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required("Reason for cancelling treatment is required")
    .test(
      "is-not-blank",
      "Reason for cancelling treatment cannot be empty spaces",
      (value) => (value || "").trim().length > 0
    ),
});

export const cancelTreatmentOrderValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required("Reason for cancelling treatment order is required")
    .test(
      "is-not-blank",
      "Reason for cancelling treatment order cannot be empty spaces",
      (value) => (value || "").trim().length > 0
    ),
});

export const replacementOrderValidationSchema = Yup.object().shape({
  replacementReason: Yup.string()
    .required("Replacement reason is required")
    .test(
      "is-not-blank",
      "Replacement reason cannot be empty spaces",
      (value) => (value || "").trim().length > 0
    ),
  replacementType: Yup.mixed<"full-payment" | "custom-payment" | "no-payment">()
    .oneOf(
      ["full-payment", "custom-payment", "no-payment"],
      "Invalid replacement type"
    )
    .required("Replacement type is required"),
  replacementPrice: Yup.number().when("replacementType", {
    is: "custom-payment",
    then: Yup.number()
      .required("Replacement price is required")
      .min(1, "Replacement price must be greater than or equal to 1"),
    otherwise: Yup.number().notRequired(),
  }),
});

export const getCrmTreatmentMedicinesFromDbMedicines = (
  dbMedicine: MedicineSelected[]
) => {
  return dbMedicine.map(
    (med) =>
      ({
        id: med.medicineType?.id,
        Medicine: {
          id: med.medicineType?.id,
          name: med.medicineType?.name,
        },
        Treatment_Area: {
          id: med.treatmentArea.id,
          name: med.treatmentArea.name,
        },
        Other_Medicine_Type:
          med.medicineType?.name === "other" ? med.title : undefined,
      } as ZohoTreatmentMedicine)
  );
};

export const getCrmProductsFromDbProducts = (
  dbProducts: DBPharmacyProduct[]
) => {
  return dbProducts.map(
    (prod) =>
      ({
        id: prod.ZohoCrmId,
        Product_Name: prod.ProductName,
        Unit_Price: prod.UnitPrice,
        Enough_For: prod.EnoughFor,
        Usage_Unit_Volume_ml: prod.UsageUnitVolumeml,
        Dosage_Per_ml: prod.DosagePerml,
        Syringe_Volume_ml: prod.SyringeVolumeml,
        Description: prod.Description,
      } as ZohoProduct)
  );
};

export const getUsageInstructions = (medicine: MedicineSelected) => {
  if (!medicine) {
    return "";
  }
  if (medicine.specifyDosingInstructions) {
    return medicine.customDosingInstruction;
  }
  const {
    cyclicalUseDays: crmCyclicUseDays,
    cyclicalStopDays: crmCyclicStop,
    dosingAmount: crmDosingAmount,
    dosingUnit: crmDosingUnit,
    dosingType: crmDosingType,
    administrationRoute: crmAdminRoute,
    bodyPart: crmBodyPart,
    numberOfUse: crmNumberOfUse,
    frequencyOfUse: crmFreqOfUse,
    timeOfDay: crmTimeOfDay,
    numberOfDays,
    patternOfUse,
  } = medicine;

  const doseUnit = crmDosingUnit?.title || "";

  let itemDosing = "";

  const administrationRoute = crmAdminRoute?.title || "";
  const freqOfUse = crmFreqOfUse?.title;

  const bodyPart = crmBodyPart ? ` (${crmBodyPart})` : ``;
  const frequencyOfUse = freqOfUse?.includes(`'X'`)
    ? `${freqOfUse
        .replace(`'X'`, `${crmNumberOfUse}`)
        .toLowerCase()
        .replace("day", (crmNumberOfUse || 0) > 1 ? "days" : "day")}`.replace(
        "dayss",
        "days"
      )
    : `${crmNumberOfUse || ""} ${freqOfUse || ""}`.toLowerCase();
  let timeOfDay = "";
  if (crmTimeOfDay?.length) {
    let timesString = "";
    if (crmTimeOfDay.length < 2) {
      timesString = crmTimeOfDay.map((i) => i.title).join(", ");
    } else {
      const lastDay = crmTimeOfDay[crmTimeOfDay.length - 1];
      const firstParts = crmTimeOfDay.slice(0, crmTimeOfDay.length - 1);
      timesString = `${firstParts.join(", ")} and ${lastDay}`;
    }
    timeOfDay = ` in the ${timesString}`.toLowerCase();
  }
  let useFor = "";
  if (patternOfUse?.value === "cyclical" && crmCyclicUseDays && crmCyclicStop) {
    useFor = ` Use for ${crmCyclicUseDays} day${
      crmCyclicUseDays > 1 ? "s" : ""
    } then Stop for ${crmCyclicStop} day${crmCyclicStop > 1 ? "s" : ""}`;
  } else if (patternOfUse?.value === "temporary" && numberOfDays) {
    useFor = ` For ${numberOfDays} day${numberOfDays > 1 ? "s" : ""}`;
  } else if (patternOfUse?.value === "required") {
    useFor = " As Required";
  }

  itemDosing = `${crmDosingAmount || ""}${
    doseUnit || ""
  } ${administrationRoute}${bodyPart}, ${
    crmDosingType?.title || ""
  } ${frequencyOfUse}${timeOfDay}.${useFor}`;

  return (
    itemDosing ||
    `${crmDosingAmount || ""}${doseUnit || ""} ${crmDosingType?.title} ${
      administrationRoute || ""
    } ${
      crmFreqOfUse?.title === "Every 'X' Days"
        ? `Every ${crmNumberOfUse || ""} Day(s)`
        : `${crmNumberOfUse || ""} ${crmFreqOfUse?.title || ""}`
    } ${crmTimeOfDay?.map((i) => i.title)?.join("|") || ""} ${
      crmCyclicUseDays ? `${crmCyclicUseDays} days use/ ` : ""
    }${crmCyclicStop ? `${crmCyclicStop} days break` : ""}`
  );
};

export const sortPackages = (
  matchingPackages: FinalizeMatchingMedPackage[],
  cheapestPackage: string,
  bestBillingPackage: string
) => {
  const { cheapest, bestBilling, others } = matchingPackages.reduce<{
    bestBilling: FinalizeMatchingMedPackage | null;
    cheapest: FinalizeMatchingMedPackage | null;
    others: FinalizeMatchingMedPackage[];
  }>(
    (total, current) => {
      if (cheapestPackage && cheapestPackage === current.id) {
        return {
          ...total,
          cheapest: current,
        };
      }
      if (bestBillingPackage && bestBillingPackage === current.id) {
        return {
          ...total,
          bestBilling: current,
        };
      }
      return {
        ...total,
        others: (total.others || []).concat(current),
      };
    },
    {
      bestBilling: null,
      cheapest: null,
      others: [],
    }
  );

  const sortedPackages = (bestBilling ? [bestBilling] : [])
    .concat(cheapest ? [cheapest] : [])
    .concat(others);

  return {
    cheapest,
    bestBilling,
    sortedPackages,
  };
};

export const sortProducts = (
  products: FinalizeMatchingProductDetails[],
  cheapestProduct: string,
  bestBillingProduct: string
) => {
  const {
    bestBillingProductDetails,
    cheapestProductDetails,
    otherProducts,
  } = products.reduce<{
    bestBillingProductDetails: FinalizeMatchingProductDetails | null;
    cheapestProductDetails: FinalizeMatchingProductDetails | null;
    otherProducts: FinalizeMatchingProductDetails[];
  }>(
    (total, current) => {
      if (cheapestProduct && cheapestProduct === current.id) {
        return {
          ...total,
          cheapestProductDetails: current,
        };
      }
      if (bestBillingProduct && bestBillingProduct === current.id) {
        return {
          ...total,
          bestBillingProductDetails: current,
        };
      }
      return {
        ...total,
        otherProducts: (total.otherProducts || []).concat(current),
      };
    },
    {
      bestBillingProductDetails: null,
      cheapestProductDetails: null,
      otherProducts: [],
    }
  );

  const sortedProducts = (bestBillingProductDetails
    ? [bestBillingProductDetails]
    : []
  )
    .concat(cheapestProductDetails ? [cheapestProductDetails] : [])
    .concat(otherProducts);

  return {
    bestBillingProductDetails,
    cheapestProductDetails,
    sortedProducts,
  };
};

export const getBestBillingAndCheapestPackage = (
  matchingPackages: FinalizeMatchingMedPackage[]
) => {
  let cheapestPackage = Infinity;
  let cheapestPackageIDs: string[] = [];
  const bestBillingCycle = {
    packageCost: Infinity,
    billingCyclePeriod: Infinity,
    packageID: "",
  };
  let bestBillingIds: string[] = [];

  matchingPackages.forEach((matchingPackage) => {
    let packageCost = 0;

    matchingPackage.products.forEach((product) => {
      packageCost =
        Number(packageCost) +
        Number(
          product.matchingMedProduct.matchingMedProduct.product.Unit_Price
        ) *
          Number(product.matchingMedProduct.matchingMedProduct.Quantity);
    });

    if (packageCost < cheapestPackage) {
      cheapestPackageIDs = [matchingPackage.id];
      cheapestPackage = packageCost;
    } else if (packageCost === cheapestPackage) {
      cheapestPackageIDs.push(matchingPackage.id);
    }

    const matchingPackageProduct = matchingPackage.products[0];

    const billingCyclePeriod =
      matchingPackageProduct.matchingMedProduct.matchingMedProduct
        .Billing_Cycle_Period_X;
    if (
      !isStringEqual(
        matchingPackageProduct.matchingMedProduct.matchingMedProduct
          .Billing_Cycle_Unit,
        "Months"
      ) ||
      billingCyclePeriod > 3
    ) {
      return;
    }

    if (
      !bestBillingCycle.packageID ||
      billingCyclePeriod < bestBillingCycle.billingCyclePeriod
    ) {
      bestBillingCycle.packageID = matchingPackage.id;
      bestBillingCycle.packageCost = packageCost;
      bestBillingCycle.billingCyclePeriod = billingCyclePeriod;
      bestBillingIds = [matchingPackage.id];
      return;
    }

    if (bestBillingCycle.billingCyclePeriod === billingCyclePeriod) {
      if (packageCost < bestBillingCycle.packageCost) {
        bestBillingCycle.packageID = matchingPackage.id;
        bestBillingCycle.packageCost = packageCost;
        bestBillingCycle.billingCyclePeriod = billingCyclePeriod;
        bestBillingIds = [matchingPackage.id];
        return;
      }
      if (packageCost === bestBillingCycle.packageCost) {
        bestBillingIds.push(matchingPackage.id);
      }
    }
  });

  return {
    bestBillingPackage: bestBillingIds[0] || "",
    cheapestPackage: cheapestPackageIDs[0] || "",
  };
};

export const getBestBillingAndCheapestProduct = (
  matchingProducts: FinalizeMatchingProductDetails[]
) => {
  let cheapestProductCost = Infinity;
  let cheapestProductIDs: string[] = [];
  const bestBillingCycle = {
    productCost: Infinity,
    billingCyclePeriod: Infinity,
    productID: "",
  };
  let bestBillingIds: string[] = [];

  matchingProducts.forEach((matchingProduct) => {
    const productCost = Number(
      matchingProduct.matchingProduct.product.Unit_Price *
        (matchingProduct.matchingProduct.Quantity || 0)
    );
    if (productCost < cheapestProductCost) {
      cheapestProductIDs = [matchingProduct.id];
      cheapestProductCost = productCost;
    } else if (productCost === cheapestProductCost) {
      cheapestProductIDs.push(matchingProduct.id);
    }

    const billingCyclePeriod =
      matchingProduct.matchingProduct.Billing_Cycle_Period_X;
    if (
      !isStringEqual(
        matchingProduct.matchingProduct.Billing_Cycle_Unit,
        "Months"
      ) ||
      billingCyclePeriod > 3
    ) {
      return;
    }

    if (
      !bestBillingCycle.productID ||
      billingCyclePeriod < bestBillingCycle.billingCyclePeriod
    ) {
      bestBillingCycle.productID = matchingProduct.id;
      bestBillingCycle.productCost = productCost;
      bestBillingCycle.billingCyclePeriod = billingCyclePeriod;
      bestBillingIds = [matchingProduct.id];
      return;
    }

    if (bestBillingCycle.billingCyclePeriod === billingCyclePeriod) {
      if (productCost < bestBillingCycle.productCost) {
        bestBillingCycle.productID = matchingProduct.id;
        bestBillingCycle.productCost = productCost;
        bestBillingCycle.billingCyclePeriod = billingCyclePeriod;
        bestBillingIds = [matchingProduct.id];
        return;
      }
      if (productCost === bestBillingCycle.productCost) {
        bestBillingIds.push(matchingProduct.id);
      }
    }
  });

  return {
    bestBillingProduct: bestBillingIds[0] || "",
    cheapestProduct: cheapestProductIDs[0] || "",
  };
};

export const getIsSelectedMatchingProductsDefaultBillingCyclesFailed = (
  matchingProducts: FinalizeMatchingProductMedicine[],
  selectedMatchingProducts: Record<string, string | null | undefined>
) => {
  if (matchingProducts.length <= 1) {
    return false;
  }
  const { getIsMultipleFailed, checkForIsMultiple } = handleIsMultiple();
  matchingProducts.forEach((matchingProduct) => {
    const matchingProductSelectedValue =
      selectedMatchingProducts[matchingProduct.id];
    const selectedMatchingProduct = matchingProduct.products.find(
      (p) => p.id === matchingProductSelectedValue
    );
    const mappedValues = {
      period:
        selectedMatchingProduct?.matchingProduct.Billing_Cycle_Period_X || null,
      billingCycleUnit: selectedMatchingProduct?.matchingProduct
        .Billing_Cycle_Unit
        ? {
            value: selectedMatchingProduct?.matchingProduct.Billing_Cycle_Unit,
            title: "",
          }
        : null,
      onlyOnce: !!selectedMatchingProduct?.matchingProduct.Only_Once,
      selfSupply: false,
      injectionBundle: {
        selfSupply: false,
      },
      bacteriostatic: {
        selfSupply: false,
      },
    };
    checkForIsMultiple(mappedValues, false);
  });
  return getIsMultipleFailed();
};

export const getMatchingProductsSelectedValues = (
  matchingProductsValues: FinalizeWidgetValuesMatchingProductsValues[]
) => {
  return matchingProductsValues.reduce<Record<string, string | null>>(
    (total, current) => {
      return { ...total, [current.id]: current.selectedProduct };
    },
    {}
  );
};

export const getFinalizeTreatmentDefaultValues = (
  pharmacies: ZohoPharmacy[],
  matchingProducts: FinalizeMatchingProductMedicine[],
  otherTreatmentMedicines: FinalizeZohoOtherTreatmentMedicine[],
  matchingPackages: FinalizeMatchingMedPackage[],
  suggestedMedicines: (ZohoTreatmentMedicine & {
    treatmentArea: ZohoTreatmentTreatmentArea;
  })[],
  bacteriostaticProducts: ZohoProduct[],
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>
) => {
  let billingCycleUnit = "";

  const productsPharmacies: AutoCompleteOption[] = [];

  const selectedPharmaciesIds: string[] = [];
  const {
    cheapestPackage,
    bestBillingPackage,
  } = getBestBillingAndCheapestPackage(matchingPackages);
  const selectedPackage = bestBillingPackage || cheapestPackage;
  const matchingPackagesValues = sortPackages(
    matchingPackages,
    cheapestPackage,
    bestBillingPackage
  ).sortedPackages.map((matchingPackage) => {
    return {
      id: matchingPackage.id,
      products: matchingPackage.products.map((product) => {
        if (
          !selectedPharmaciesIds.includes(
            product.matchingMedProduct.matchingMedProduct.product.Pharmacy.id
          )
        ) {
          selectedPharmaciesIds.push(
            product.matchingMedProduct.matchingMedProduct.product.Pharmacy.id
          );
          productsPharmacies.push({
            title:
              product.matchingMedProduct.matchingMedProduct.product.Pharmacy
                .name,
            value:
              product.matchingMedProduct.matchingMedProduct.product.Pharmacy.id,
          });
        }
        if (!billingCycleUnit && matchingPackage.id === selectedPackage) {
          if (
            product.matchingMedProduct.matchingMedProduct.Billing_Cycle_Unit
          ) {
            billingCycleUnit =
              product.matchingMedProduct.matchingMedProduct.Billing_Cycle_Unit;
          }
        }

        const supplementaryProducts = product.supplementaryProducts;
        const injectionBundle = (product.injectionBundles || []).find(
          (i) => i.id === supplementaryProducts?.injectionBundle?.product
        );

        const bacteriostatic = bacteriostaticProducts.find(
          (b) => b.id === supplementaryProducts?.bacteriostatic?.product
        );

        return {
          id:
            product.matchingMedProduct.matchingMedProduct.treatmentMedicine.id,
          usageNotes:
            product.matchingMedProduct.matchingMedProduct.treatmentMedicine
              .Usage_Notes || "",
          notesForMedicineSelection:
            product.matchingMedProduct.matchingMedProduct.treatmentMedicine
              .Notes_For_Medicine_Selection ||
            product.matchingMedProduct.matchingMedProduct.treatmentMedicine
              .Notes_for_Medicine_Selection ||
            "",
          onlyOnce: !!product.matchingMedProduct.matchingMedProduct.Only_Once,
          period:
            product.matchingMedProduct.matchingMedProduct
              .Billing_Cycle_Period_X,
          customUnitPrice:
            product.matchingMedProduct.matchingMedProduct.Custom_Unit_Price ||
            null,
          selfSupply: !!product.matchingMedProduct.matchingMedProduct
            .Self_Supply,
          injectionBundle: {
            show: getShowInjectionBundle(
              product.matchingMedProduct.matchingMedProduct.treatmentMedicine
                .Dosing_Type.name,
              product.matchingMedProduct.matchingMedProduct.packageMedicine
                .Treatment_Area.name
            ),
            product: injectionBundle
              ? {
                  title: injectionBundle.Product_Name,
                  value: injectionBundle.id,
                }
              : null,
            quantity: supplementaryProducts?.injectionBundle?.quantity || null,
            customUnitPrice: null,
            selfSupply: !!supplementaryProducts?.injectionBundle?.selfSupply,
          },
          bacteriostatic: {
            show: getShowBacteriostatic(
              product.matchingMedProduct.matchingMedProduct.treatmentMedicine
                .Dosing_Type.name,
              product.matchingMedProduct.matchingMedProduct.packageMedicine
                .Treatment_Area.name
            ),
            product: bacteriostatic
              ? {
                  title: bacteriostatic.Product_Name,
                  value: bacteriostatic.id,
                }
              : null,
            quantity: supplementaryProducts?.bacteriostatic?.quantity || null,
            customUnitPrice: null,
            selfSupply: !!supplementaryProducts?.bacteriostatic?.selfSupply,
          },
        };
      }),
    };
  });

  const matchingProductsValues = matchingProducts.map((matchingProduct) => {
    const {
      cheapestProduct,
      bestBillingProduct,
    } = getBestBillingAndCheapestProduct(matchingProduct.products);
    const selectedProduct = bestBillingProduct || cheapestProduct || null;
    return {
      id: matchingProduct.id,
      usageNotes: matchingProduct.Usage_Notes || "",
      notesForMedicineSelection:
        matchingProduct.Notes_For_Medicine_Selection ||
        matchingProduct.Notes_for_Medicine_Selection ||
        "",
      selectedProduct,
      products: sortProducts(
        matchingProduct.products,
        cheapestProduct,
        bestBillingProduct
      ).sortedProducts.map((product) => {
        if (
          !selectedPharmaciesIds.includes(
            product.matchingProduct.product.Pharmacy.id
          )
        ) {
          selectedPharmaciesIds.push(
            product.matchingProduct.product.Pharmacy.id
          );
          productsPharmacies.push({
            title: product.matchingProduct.product.Pharmacy.name,
            value: product.matchingProduct.product.Pharmacy.id,
          });
        }
        if (!billingCycleUnit && product.id === selectedProduct) {
          if (product.matchingProduct.Billing_Cycle_Unit) {
            billingCycleUnit = product.matchingProduct.Billing_Cycle_Unit;
          }
        }

        const supplementaryProducts = product.supplementaryProducts;
        const injectionBundle = (product.injectionBundles || []).find(
          (i) => i.id === supplementaryProducts?.injectionBundle?.product
        );

        const bacteriostatic = bacteriostaticProducts.find(
          (b) => b.id === supplementaryProducts?.bacteriostatic?.product
        );

        return {
          id: product.id,
          customUnitPrice: product.matchingProduct.Custom_Unit_Price || null,
          selfSupply: !!product.matchingProduct.Self_Supply,
          onlyOnce: !!product.matchingProduct.Only_Once,
          period: product.matchingProduct.Billing_Cycle_Period_X,
          quantity: product.matchingProduct.Quantity || null,
          billingCycleUnit: billingCycleUnitOptions.find((b) =>
            isStringEqual(b.value, product.matchingProduct.Billing_Cycle_Unit)
          ) || {
            value: "Months",
            title: "Every X Months",
          },
          injectionBundle: {
            product: injectionBundle
              ? {
                  title: injectionBundle.Product_Name,
                  value: injectionBundle.id,
                }
              : null,
            show: getShowInjectionBundle(
              product.treatmentMedicine.Dosing_Type.name,
              matchingProduct.treatmentArea.Treatment_Area.name
            ),
            quantity: supplementaryProducts?.injectionBundle?.quantity || null,
            customUnitPrice: null,
            selfSupply: !!supplementaryProducts?.injectionBundle?.selfSupply,
          },
          bacteriostatic: {
            show: getShowBacteriostatic(
              product.treatmentMedicine.Dosing_Type.name,
              matchingProduct.treatmentArea.Treatment_Area.name
            ),
            product: bacteriostatic
              ? {
                  title: bacteriostatic.Product_Name,
                  value: bacteriostatic.id,
                }
              : null,
            quantity: supplementaryProducts?.bacteriostatic?.quantity || null,
            customUnitPrice: null,
            selfSupply: !!supplementaryProducts?.bacteriostatic?.selfSupply,
          },
        };
      }),
    };
  });

  const otherTreatmentMedicinesValues = otherTreatmentMedicines.map(
    (otherTreatmentMedicine) => {
      return {
        id: otherTreatmentMedicine.id,
        usageNotes: otherTreatmentMedicine.Usage_Notes || "",
        notesForMedicineSelection:
          otherTreatmentMedicine.Notes_For_Medicine_Selection ||
          otherTreatmentMedicine.Notes_for_Medicine_Selection ||
          "",
        product: null,
        selfSupply: false,
        onlyOnce: false,
        period: null,
        quantity: null,
        customUnitPrice: null,
        billingCycleUnit: {
          value: "Months",
          title: "Every X Months",
        },
      };
    }
  );

  const pharmaciesValue = pharmacies.length
    ? pharmacies.map((pharmacy) => ({
        value: pharmacy.id,
        title: pharmacy.Name,
      }))
    : productsPharmacies;

  const selectedPharmacies = !selectedPharmaciesIds.length
    ? pharmaciesValue
    : pharmaciesValue.filter((p) => selectedPharmaciesIds.includes(p.value));

  const treatmentMedicines = suggestedMedicines.map<TreatmentMedicinesFormValues>(
    (suggestedMedicine) => {
      const products = suggestedMedicine.products || [];
      const selectedProduct = products.find(
        (product) => product.id === suggestedMedicine.product?.Product?.id
      );

      const treatmentTreatmentArea =
        treatmentTreatmentAreasById[suggestedMedicine.Treatment_Area.id];

      return {
        id: suggestedMedicine.id,
        selectedProductId: suggestedMedicine.product?.id || null,
        usageNotes: suggestedMedicine.Usage_Notes || "",
        notesForMedicineSelection:
          suggestedMedicine.Notes_For_Medicine_Selection ||
          suggestedMedicine.Notes_for_Medicine_Selection ||
          "",
        product: selectedProduct
          ? {
              title: selectedProduct.Product_Name,
              value: selectedProduct.id,
              pharmacyZohoId: selectedProduct.Pharmacy?.id,
            }
          : null,
        selfSupply: !!suggestedMedicine.product?.Self_Supply,
        onlyOnce: !!suggestedMedicine.product?.Only_Once,
        period: suggestedMedicine.product?.Billing_Cycle_Period_X || null,
        quantity: suggestedMedicine.product?.Quantity || null,
        customUnitPrice: suggestedMedicine.product?.Custom_Unit_Price || null,
        injectionBundle: {
          show: getShowInjectionBundle(
            suggestedMedicine.Dosing_Type.name,
            treatmentTreatmentArea.Treatment_Area.name
          ),
          product: suggestedMedicine.product?.injectionBundle?.Product?.id
            ? {
                title: suggestedMedicine.product?.injectionBundle.Product.name,
                value: suggestedMedicine.product?.injectionBundle.Product.id,
              }
            : null,
          selfSupply: !!suggestedMedicine.product?.injectionBundle?.Self_Supply,
          customUnitPrice:
            suggestedMedicine.product?.injectionBundle?.Custom_Unit_Price ||
            null,
          quantity:
            suggestedMedicine.product?.injectionBundle?.Quantity || null,
          selectedProductId:
            suggestedMedicine.product?.injectionBundle?.id || null,
        },
        bacteriostatic: {
          product: suggestedMedicine.product?.bacteriostatic?.Product?.id
            ? {
                title: suggestedMedicine.product?.bacteriostatic.Product.name,
                value: suggestedMedicine.product?.bacteriostatic.Product.id,
              }
            : null,
          show: getShowBacteriostatic(
            suggestedMedicine.Dosing_Type.name,
            treatmentTreatmentArea.Treatment_Area.name
          ),
          selfSupply: !!suggestedMedicine.product?.bacteriostatic?.Self_Supply,
          customUnitPrice:
            suggestedMedicine.product?.bacteriostatic?.Custom_Unit_Price ||
            null,
          quantity: suggestedMedicine.product?.bacteriostatic?.Quantity || null,
          selectedProductId:
            suggestedMedicine.product?.bacteriostatic?.id || null,
        },
      };
    }
  );

  return {
    pharmacies: selectedPharmacies,
    matchingProducts: matchingProductsValues,
    matchingPackages: matchingPackagesValues,
    otherTreatmentMedicines: otherTreatmentMedicinesValues,
    billingCycleUnit: billingCycleUnitOptions.find((b) =>
      isStringEqual(b.value, billingCycleUnit)
    ) || {
      value: "Months",
      title: "Every X Months",
    },
    supplementaryProducts: [],
    selectedPackage,
    treatmentMedicines,
  };
};

export const prescriptionFormValidationSchema = Yup.object().shape({
  requesterName: Yup.string().required("Requester Name is required"),
  requesterEmail: Yup.string()
    .email("Invalid email format")
    .required("Requester Email is required"),
  prescriptionDocument: Yup.mixed().required(
    "Prescription Document is required"
  ),
  numberOfRepetition: Yup.number().required("Number of Repetition is required"),
  doctor: Yup.object()
    .required("Doctor is required")
    .typeError("Doctor is required"),
  externalDoctorName: Yup.string().when("doctor.value", {
    is: "external",
    then: Yup.string().required("External Doctor Name is required"),
  }),
  patient: Yup.object()
    .required("Patient is required")
    .typeError("Patient is required"),
  patientFirstName: Yup.string().when("patient.Id", {
    is: "new",
    then: Yup.string().required("Patient First Name is required"),
  }),
  patientLastName: Yup.string().when("patient.Id", {
    is: "new",
    then: Yup.string().required("Patient Last Name is required"),
  }),
  patientFoodDrugAllergies: Yup.string().nullable().optional(),
  patientAllergies: Yup.string().nullable().optional(),
  prescriptionItems: Yup.array()
    .of(
      Yup.object().shape({
        product: Yup.object()
          .required("Product is required")
          .typeError("Product is required"),
        prescriptionTotalQuantity: Yup.number()
          .integer("Prescription Total Quantity must be an integer")
          .min(1, "Prescription Total Quantity must be greater than 0")
          .required("Prescription Total Quantity is required"),
        usageNotes: Yup.string()
          .required("Usage Notes are required")
          .test(
            "is-not-blank",
            "Usage Notes cannot be empty spaces",
            (value) => (value || "").trim().length > 0
          ),
      })
    )
    .required("Prescription Items are required"),
});

export const getFinalizeTreatmentValidationSchema = (
  isNoCombination: boolean,
  isMatchingProducts: boolean,
  isMatchingPackages: boolean,
  treatmentPeriodInMonths: number,
  matchingProducts: FinalizeMatchingProductMedicine[]
) => {
  const matchingProductsLength = matchingProducts.length;

  const callbackFnc = (values: FinalizeWidgetValues) => {
    const matchingProductsCallback = (
      productsValues: FinalizeWidgetValuesMatchingProductsValues
    ) => {
      const isSelectedMatchingProductsDefaultBillingCyclesFailed = getIsSelectedMatchingProductsDefaultBillingCyclesFailed(
        matchingProducts,
        getMatchingProductsSelectedValues(values.matchingProducts)
      );
      const selectedProduct = productsValues.selectedProduct;
      const matchingProductCallback = (
        productValues: FinalizeWidgetValuesMatchingProductValues
      ) => {
        if (
          selectedProduct !== productValues.id ||
          !isSelectedMatchingProductsDefaultBillingCyclesFailed
        ) {
          return Yup.object({});
        }
        return Yup.object({
          customUnitPrice: customUnitPriceValidation,
          period: getPeriodValidation(treatmentPeriodInMonths),
          quantity: quantityValidation,
          injectionBundle: childProductValidationSchema,
          bacteriostatic: childProductValidationSchema,
        });
      };
      return Yup.object({
        selectedProduct: Yup.string()
          .required("You must select a product for this medicine")
          .typeError("You must select a product for this medicine"),
        products: Yup.array().of(Yup.lazy(matchingProductCallback as any)),
      });
    };

    const matchingPackageCallback = (
      packageValues: FinalizeWidgetValuesMatchingPackagesValues
    ) => {
      if (values.selectedPackage !== packageValues.id) {
        return Yup.object({});
      }

      return Yup.object({
        products: Yup.array().of(
          Yup.object({
            customUnitPrice: customUnitPriceValidation,
            injectionBundle: childProductValidationSchema,
            bacteriostatic: childProductValidationSchema,
          })
        ),
      });
    };
    return Yup.object({
      matchingProducts: !isMatchingProducts
        ? Yup.array().nullable().optional()
        : Yup.array().of(Yup.lazy(matchingProductsCallback as any)),
      matchingPackages: !isMatchingPackages
        ? Yup.array().nullable().optional()
        : Yup.array().of(Yup.lazy(matchingPackageCallback as any)),
      selectedPackage: Yup.lazy(() => {
        if (!isMatchingPackages) {
          return Yup.string().optional().nullable();
        }
        return Yup.string()
          .required("You must select a package for this treatment")
          .typeError("You must select a product for this treatment");
      }),
      otherTreatmentMedicines: Yup.array().of(
        Yup.object({
          product: productValidation,
          period: getPeriodValidation(
            treatmentPeriodInMonths,
            isMatchingProducts && matchingProductsLength > 1
              ? null
              : values.billingCycleUnit
          ),
          quantity: quantityValidation,
          customUnitPrice: customUnitPriceValidation,
        })
      ),
      supplementaryProducts: getSupplementaryProductsValidationSchema(
        treatmentPeriodInMonths,
        isMatchingProducts && matchingProductsLength > 1
          ? null
          : values.billingCycleUnit
      ),
      treatmentMedicines: !isNoCombination
        ? Yup.array().nullable().optional()
        : getTreatmentMedicinesValidationSchema(
            treatmentPeriodInMonths,
            values.billingCycleUnit
          ),
    });
  };
  return Yup.lazy(callbackFnc as any);
};

export const getFinalizeTreatmentBillingCycleValidation = (
  values: any,
  name: string,
  isNoCombination: boolean,
  isMatchingProduct: boolean,
  isMatchingPackages: boolean,
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean,
  matchingProducts: FinalizeMatchingProductMedicine[]
) => {
  const { getIsMultipleFailed, checkForIsMultiple } = handleIsMultiple();
  const {
    getIsSelfSuppliedFailed,
    checkForIsSelfSupplySame,
  } = handleIsSelfSupplySame();

  const treatmentMedicinesFieldName = getFieldName("treatmentMedicines", name);
  const selectedPackageFieldName = getFieldName("selectedPackage", name);
  const supplementaryProductsFieldName = getFieldName(
    "supplementaryProducts",
    name
  );
  const matchingProductsFieldName = getFieldName("matchingProducts", name);
  const matchingPackagesFieldName = getFieldName("matchingPackages", name);
  const otherTreatmentMedicinesFieldName = getFieldName(
    "otherTreatmentMedicines",
    name
  );

  const otherTreatmentMedicinesValue: FinalizeWidgetValues["otherTreatmentMedicines"] =
    getValueFromObject(otherTreatmentMedicinesFieldName, values) || [];
  const matchingProductsValues: FinalizeWidgetValues["matchingProducts"] =
    getValueFromObject(matchingProductsFieldName, values) || [];
  const selectedPackageValue: string =
    getValueFromObject(selectedPackageFieldName, values) || [];
  const matchingPackagesValues: FinalizeWidgetValues["matchingPackages"] =
    getValueFromObject(matchingPackagesFieldName, values) || [];
  const treatmentMedicinesValues: TreatmentFormValues["treatmentMedicines"] =
    getValueFromObject(treatmentMedicinesFieldName, values) || [];
  const supplementaryProductsValues: TreatmentFormValues["supplementaryProducts"] =
    getValueFromObject(supplementaryProductsFieldName, values) || [];

  let isGlobalBillingCycleUnit = true;

  const matchingProductsLength = matchingProducts.length;
  if (isMatchingProduct) {
    isGlobalBillingCycleUnit = matchingProductsLength <= 1;
  }

  supplementaryProductsValues.forEach((supplementaryProductValues) => {
    checkForIsMultiple(supplementaryProductValues, isGlobalBillingCycleUnit);
  });

  otherTreatmentMedicinesValue.forEach((otherTreatmentMedicineValue) => {
    checkForIsMultiple(otherTreatmentMedicineValue, isGlobalBillingCycleUnit);
    checkForIsSelfSupplySame(
      otherTreatmentMedicineValue,
      isGlobalBillingCycleUnit
    );
  });

  if (isNoCombination) {
    treatmentMedicinesValues.forEach((treatmentMedicineValues) => {
      checkForIsMultiple(treatmentMedicineValues);
      checkForIsSelfSupplySame(treatmentMedicineValues);
    });
  }

  const matchingProductsObj = matchingProducts.reduce<
    Record<string, Record<string, FinalizeMatchingProductDetails>>
  >((total, current) => {
    return {
      ...total,
      [current.id]: current.products.reduce((t, p) => {
        return { ...t, [p.id]: p };
      }, {}),
    };
  }, {});

  if (isMatchingProduct) {
    matchingProductsValues.forEach((matchingProductsValue) => {
      const matchingProductSelectedValue = matchingProductsValue.products.find(
        (product) => product.id === matchingProductsValue.selectedProduct
      );
      const matchingProductObj =
        matchingProductsObj[matchingProductsValue.id][
          matchingProductsValue.selectedProduct!
        ];
      const mappedValues = {
        period: !isSelectedMatchingProductsDefaultBillingCyclesFailed
          ? matchingProductObj.matchingProduct.Billing_Cycle_Period_X || null
          : matchingProductSelectedValue?.period || null,
        billingCycleUnit: !isSelectedMatchingProductsDefaultBillingCyclesFailed
          ? {
              value: matchingProductObj.matchingProduct.Billing_Cycle_Unit,
              title: matchingProductObj.matchingProduct.Billing_Cycle_Unit,
            }
          : matchingProductSelectedValue?.billingCycleUnit || null,
        onlyOnce: !isSelectedMatchingProductsDefaultBillingCyclesFailed
          ? !!matchingProductObj.matchingProduct.Only_Once
          : !!matchingProductSelectedValue?.onlyOnce,
        selfSupply: !!matchingProductSelectedValue?.selfSupply,
        injectionBundle: {
          selfSupply: !!matchingProductSelectedValue?.injectionBundle
            .selfSupply,
        },
        bacteriostatic: {
          selfSupply: !!matchingProductSelectedValue?.bacteriostatic.selfSupply,
        },
      };
      checkForIsMultiple(mappedValues, false);
      checkForIsSelfSupplySame(mappedValues, false);
    });
  }

  if (isMatchingPackages) {
    const selectedMatchingPackageValues = matchingPackagesValues.find(
      (matchingPackageValues) =>
        matchingPackageValues.id === selectedPackageValue
    );
    selectedMatchingPackageValues?.products.forEach(
      (selectedMatchingPackageValue) => {
        const mappedValues = {
          period: selectedMatchingPackageValue?.period || null,
          onlyOnce: !!selectedMatchingPackageValue?.onlyOnce,
          selfSupply: !!selectedMatchingPackageValue?.selfSupply,
          injectionBundle: {
            selfSupply: !!selectedMatchingPackageValue?.injectionBundle
              .selfSupply,
          },
          bacteriostatic: {
            selfSupply: !!selectedMatchingPackageValue?.bacteriostatic
              .selfSupply,
          },
        };
        checkForIsMultiple(mappedValues);
        checkForIsSelfSupplySame(mappedValues);
      }
    );
  }
  const selfSupplyFailed = getIsSelfSuppliedFailed();
  return {
    selfSupplyFailed,
    isMultipleFailed: getIsMultipleFailed(),
  };
};

export const getFinalizeTreatmentPayload = (
  values: FinalizeWidgetValues,
  treatmentZohoId: string,
  userId: string,
  isNoCombination: boolean,
  isMatchingProduct: boolean,
  isMatchingPackages: boolean,
  matchingProducts: FinalizeMatchingProductMedicine[]
) => {
  const selectedMatchingPackage = values.matchingPackages.find(
    (p) => p.id === values.selectedPackage
  )!;

  const payload: Record<string, any> = {
    treatmentZohoId,
    owner: userId,
  };

  const isSelectedMatchingProductsDefaultBillingCyclesFailed = getIsSelectedMatchingProductsDefaultBillingCyclesFailed(
    matchingProducts,
    getMatchingProductsSelectedValues(values.matchingProducts)
  );
  if (isMatchingPackages) {
    payload.form = "packages";
    payload.selectedPackage = {
      matchingPackageID: values.selectedPackage,
      usageNotes: selectedMatchingPackage.usageNotes || "",
      notesForMedicineSelection:
        selectedMatchingPackage.notesForMedicineSelection || "",
      treatmentMedicines: selectedMatchingPackage.products.map((product) => {
        return {
          id: product.id,
          selfSupply: !!product.selfSupply,
          customUnitPrice: product.customUnitPrice || null,
          usageNotes: product.usageNotes || "",
          notesForMedicineSelection: product.notesForMedicineSelection || "",
          period: product.period || null,
          onlyOnce: !!product.onlyOnce,
          billingCycleUnit: values.billingCycleUnit.value,
          supplementaryProducts: {
            ...(product.injectionBundle.product?.value
              ? {
                  injectionBundle: {
                    product: product.injectionBundle?.product?.value || null,
                    selfSupply: !!product.injectionBundle?.selfSupply,
                    customUnitPrice:
                      product.injectionBundle?.customUnitPrice || null,
                    period: product.period || null,
                    onlyOnce: !!product.onlyOnce,
                    quantity: product.injectionBundle?.quantity || null,
                    billingCycleUnit: values.billingCycleUnit.value,
                  },
                }
              : {}),
            ...(product.bacteriostatic?.product?.value
              ? {
                  bacteriostatic: {
                    product: product.bacteriostatic?.product?.value || null,
                    customUnitPrice:
                      product.bacteriostatic?.customUnitPrice || null,
                    selfSupply: !!product.bacteriostatic?.selfSupply,
                    period: product.period || null,
                    onlyOnce: !!product.onlyOnce,
                    quantity: product.bacteriostatic?.quantity || null,
                    billingCycleUnit: values.billingCycleUnit.value,
                  },
                }
              : {}),
          },
        };
      }),
    };
  } else if (isMatchingProduct) {
    const treatmentMedicines: FinalizeWidgetValues["matchingProducts"] =
      getValueFromObject("matchingProducts", values) || [];
    payload.form = "products";
    payload.isMatchingProductsBillingCyclesChanged = isSelectedMatchingProductsDefaultBillingCyclesFailed;
    payload.isSupplAndOtherMedsBillingCyclesChanged =
      matchingProducts.length > 1;
    payload.treatmentMedicines = treatmentMedicines.map((treatmentMedicine) => {
      const product = treatmentMedicine.products.find(
        (p) => p.id === treatmentMedicine.selectedProduct
      )!;

      const billingCycleValue = product.billingCycleUnit.value;
      return {
        id: treatmentMedicine.id,
        usageNotes: treatmentMedicine.usageNotes || null,
        notesForMedicineSelection:
          treatmentMedicine.notesForMedicineSelection || null,
        selectedProduct: {
          id: product.id,
          selfSupply: !!product.selfSupply,
          customUnitPrice: product.customUnitPrice || null,
          period: product.period || null,
          billingCycleUnit: billingCycleValue,
          quantity: product.quantity || null,
          onlyOnce: !!product.onlyOnce,
          supplementaryProducts: {
            ...(product.injectionBundle.product?.value
              ? {
                  injectionBundle: {
                    product: product.injectionBundle?.product?.value || null,
                    selfSupply: !!product.injectionBundle?.selfSupply,
                    customUnitPrice:
                      product.injectionBundle?.customUnitPrice || null,
                    onlyOnce: !!product.onlyOnce,
                    quantity: product.injectionBundle?.quantity || null,
                    period: product.period || null,
                    billingCycleUnit: billingCycleValue,
                  },
                }
              : {}),
            ...(product.bacteriostatic?.product?.value
              ? {
                  bacteriostatic: {
                    product: product.bacteriostatic?.product?.value || null,
                    customUnitPrice:
                      product.bacteriostatic?.customUnitPrice || null,
                    selfSupply: !!product.bacteriostatic?.selfSupply,
                    onlyOnce: !!product.onlyOnce,
                    quantity: product.bacteriostatic?.quantity || null,
                    period: product.period || null,
                    billingCycleUnit: billingCycleValue,
                  },
                }
              : {}),
          },
        },
      };
    });
  } else if (isNoCombination) {
    const treatmentMedicines: FinalizeWidgetValues["treatmentMedicines"] =
      getValueFromObject("treatmentMedicines", values) || [];
    payload.form = "no-combination";
    payload.treatmentMedicines = treatmentMedicines.map((item) => {
      const newData = {
        id: item.id,
        selectedProductId: item.product?.value || null,
        usageNotes: item.usageNotes || null,
        notesForMedicineSelection: item.notesForMedicineSelection || null,
        product: item.product?.value || null,
        onlyOnce: !!item.onlyOnce,
        billingCycleUnit: values.billingCycleUnit.value || null,
        billingCyclePeriod: item.period || null,
        period: item.period || null,
        quantity: item.quantity || null,
        selfSupply: !!item.selfSupply,
        billingCycle: values.billingCycleUnit.value,
        customUnitPrice: item.customUnitPrice
          ? Number(Number(item.customUnitPrice).toFixed(2))
          : null,
        ...(item.injectionBundle?.product?.value
          ? {
              injectionBundle: {
                product: item.injectionBundle?.product?.value || null,
                id: item.injectionBundle?.product.value,
                quantity: item.injectionBundle?.quantity,
                selfSupply: item.injectionBundle?.selfSupply,
                billingCycle: values.billingCycleUnit.value,
                billingCycleUnit: values.billingCycleUnit.value || null,
                period: item.period || null,
                customUnitPrice: item.injectionBundle?.customUnitPrice
                  ? Number(
                      Number(item.injectionBundle?.customUnitPrice).toFixed(2)
                    )
                  : null,
              },
            }
          : {}),
        ...(item.bacteriostatic?.product?.value
          ? {
              bacteriostatic: {
                product: item.bacteriostatic?.product?.value,
                billingCycle: values.billingCycleUnit.value,
                id: item.bacteriostatic?.product?.value,
                billingCycleUnit: values.billingCycleUnit.value || null,
                quantity: item.bacteriostatic?.quantity,
                selfSupply: item.bacteriostatic?.selfSupply,
                period: item.period || null,
                customUnitPrice: item.bacteriostatic?.customUnitPrice
                  ? Number(
                      Number(item.bacteriostatic?.customUnitPrice).toFixed(2)
                    )
                  : null,
              },
            }
          : {}),
      };
      return newData;
    });
  }

  payload.otherTreatmentMedicines = values.otherTreatmentMedicines.map(
    (otherTreatmentMedicine) => {
      const billingCycleValue =
        isMatchingProduct && matchingProducts.length > 1
          ? otherTreatmentMedicine.billingCycleUnit?.value || null
          : values.billingCycleUnit.value;
      return {
        id: otherTreatmentMedicine.id,
        usageNotes: otherTreatmentMedicine.usageNotes || "",
        notesForMedicineSelection:
          otherTreatmentMedicine.notesForMedicineSelection || null,
        product: otherTreatmentMedicine.product.value,
        selfSupply: !!otherTreatmentMedicine.selfSupply,
        onlyOnce: !!otherTreatmentMedicine.onlyOnce,
        period: otherTreatmentMedicine.period || null,
        quantity: otherTreatmentMedicine.quantity || null,
        customUnitPrice: otherTreatmentMedicine.customUnitPrice || null,
        billingCycleUnit: billingCycleValue,
        billingCycle: billingCycleValue,
      };
    }
  );
  payload.supplementaryProducts = values.supplementaryProducts
    .filter((s) => !!s.product.value)
    .map((s) => {
      const billingCycleValue =
        isMatchingProduct && matchingProducts.length > 1
          ? s.billingCycleUnit?.value || null
          : values.billingCycleUnit.value;
      return {
        billingCycle: billingCycleValue,
        product: s.product.value,
        quantity: s.quantity,
        billingCycleUnit: billingCycleValue,
        period: s.period,
        onlyOnce: !!s.onlyOnce,
        customUnitPrice: s.customUnitPrice || null,
      };
    });
  return payload;
};

export const renewTreatmentValidationSchema = Yup.object().shape({
  treatmentPeriod: Yup.number()
    .required("Treatment Period is required")
    .min(1, "Treatment Period must be at least 1")
    .max(12, "Treatment Period must be at most 12"),
});

export const confirmPackingValidationSchema = Yup.object().shape({
  courier: Yup.string().when("courierOrderNumber", {
    is: (courierOrderNumber: string) =>
      courierOrderNumber && courierOrderNumber.trim().length > 0,
    then: Yup.string().required(
      "Courier is required when Courier Order Number is provided"
    ),
    otherwise: Yup.string(),
  }),
  courierOrderNumber: Yup.string(),
});

export const getFieldErrorNames = (formikErrors: FormikErrors<any>) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix = "",
    result: any = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;
      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });
    return result;
  };
  return transformObjectToDotNotation(formikErrors);
};

interface BillingCycleValues {
  onlyOnce: boolean;
  unit: string;
  period: number;
}

export const getIsAnyBillingCycleTooSmall = (
  billingCycleValues: BillingCycleValues[]
) => {
  return billingCycleValues.some(({ unit, onlyOnce, period }) => {
    if (onlyOnce) {
      return false;
    }
    const billingCycleInDays = getBillingCycleInDays(
      {
        value: unit,
        title: unit,
      },
      period
    );

    return billingCycleInDays < 15;
  });
};

export const getIsAnyManualSelectionBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string;
    period: number;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, period }) => ({
      unit: billingCycleUnit,
      onlyOnce,
      period,
    }))
  );
};

export const getIsAnyMatchingProductsSelectionBillingCycleTooSmall = (
  values: {
    selectedProduct: {
      billingCycleUnit: string;
      period: number;
      onlyOnce: boolean;
    };
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(
      ({ selectedProduct: { onlyOnce, billingCycleUnit, period } }) => ({
        unit: billingCycleUnit,
        onlyOnce,
        period,
      })
    )
  );
};

export const getIsAnyMatchingPackagesSelectionBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string;
    period: number;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, period }) => ({
      unit: billingCycleUnit,
      onlyOnce,
      period,
    }))
  );
};

export const getIsAnyOtherTreatmentMedicinesBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string;
    period: number;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, period }) => ({
      unit: billingCycleUnit,
      onlyOnce,
      period,
    }))
  );
};

export const getIsAnySupplementaryProductsBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string;
    period: number;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, period }) => ({
      unit: billingCycleUnit,
      onlyOnce,
      period,
    }))
  );
};

export const getIsAnyUpdateTreatmentSupplementaryProductsBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string | null;
    billingCyclePeriod: number | null;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, billingCyclePeriod }) => ({
      unit: billingCycleUnit || "",
      onlyOnce,
      period: billingCyclePeriod || 0,
    }))
  );
};

export const getIsAnyMedicinesProductsBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string | null;
    billingCyclePeriod: number | null;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values.map(({ onlyOnce, billingCycleUnit, billingCyclePeriod }) => ({
      unit: billingCycleUnit || "",
      onlyOnce,
      period: billingCyclePeriod || 0,
    }))
  );
};

export const getIsAnyMigrateTreatmentSupplementaryProductsBillingCycleTooSmall = (
  values: {
    billingCycleUnit: string | null;
    period: number | null;
    onlyOnce: boolean;
  }[]
) => {
  return getIsAnyBillingCycleTooSmall(
    values
      .filter(({ onlyOnce, period }) =>
        onlyOnce ? true : !Number.isNaN(Number(period))
      )
      .map(({ onlyOnce, billingCycleUnit, period }) => ({
        unit: billingCycleUnit || "",
        onlyOnce,
        period: period || 0,
      }))
  );
};
