import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

interface BillingCycleWarningProps {
  billingCycleWarningResolve: ((value: any) => void) | null;
  billingCycleWarningReject: ((reason: string) => void) | null;
}

export const BillingCycleWarning = ({
  billingCycleWarningReject,
  billingCycleWarningResolve,
}: BillingCycleWarningProps) => {
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    if (!billingCycleWarningReject) {
      return;
    }
    billingCycleWarningReject("closed");
    setOpen(false);
  }, [billingCycleWarningReject]);

  const onProceed = useCallback(() => {
    if (!billingCycleWarningResolve) {
      return;
    }
    billingCycleWarningResolve(true);
    setOpen(false);
  }, [billingCycleWarningResolve]);

  useEffect(() => {
    if (billingCycleWarningResolve && billingCycleWarningReject) {
      setOpen(true);
    }
  }, [billingCycleWarningResolve, billingCycleWarningReject]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Typography align="center">
          You are setting a billing cycle that is less than 15 days.
          <br />
          Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          size="small"
          color="primary"
        >
          BACK
        </Button>
        <Button onClick={onProceed} size="small" color="primary">
          PROCEED
        </Button>
      </DialogActions>
    </Dialog>
  );
};
