import React from "react";
import { useFormikContext } from "formik";
import { Box, Button } from "@material-ui/core";

export const ActionButtons = ({ closeWidget }: { closeWidget: () => void }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Confirm"}
      </Button>
    </Box>
  );
};
