import { useFormikContext } from "formik";
import { getDoctorName, getFieldName } from "helpers";
import React, { useEffect, useMemo } from "react";
import { ZohoBMHDoctor } from "types";
import { DoctorAutoCompleteField } from "./DoctorAutoCompleteField";

interface Props {
  doctor?: ZohoBMHDoctor | null;
  doctors: ZohoBMHDoctor[];
  name?: string;
  showLabel?: boolean;
}

export const DoctorField = ({
  doctors,
  doctor,
  name,
  showLabel = true,
}: Props) => {
  const { setFieldValue } = useFormikContext();

  const fieldName = useMemo(() => getFieldName("doctor", name), [name]);

  useEffect(() => {
    if (doctor?.id) {
      setImmediate(() => {
        setFieldValue(fieldName, {
          title: getDoctorName(doctor),
          value: doctor.id,
        });
      });
    }
  }, [doctor, setFieldValue, fieldName]);

  if (doctor?.id) {
    return null;
  }
  return <DoctorAutoCompleteField doctors={doctors} showLabel={showLabel} />;
};
