import { Alert, AlertTitle } from "@material-ui/lab";
import { AxiosError } from "axios";
import {
  PerformFinalChecksActions,
  PerformFinalChecksItems,
} from "components/Dispensing";
import Loader from "components/Loader";
import { Formik, FormikConfig } from "formik";
import * as Yup from "yup";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import {
  dispenseDrugsServices,
  notifications,
  confirmOrderPacking,
  zohoServices,
} from "services";
import { DispenseOrderDetails } from "../types";

const useStyles = makeStyles(() => ({
  actions: {
    width: "100%",
  },
}));

export const PerformFinalChecks: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");

  const [details, setDetails] = useState<DispenseOrderDetails>();

  const [id] = useSelector(zohoSelectors.getIds);

  const initialValues = useMemo(() => {
    return {
      requestedChanges: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      requestedChanges: Yup.string()
        .required("Enter changes to be made")
        .typeError("Enter changes to be made"),
    });
  }, []);

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    return "form";
  }, [fetchError]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [orderDetails, changeNotes] = await Promise.all([
        dispenseDrugsServices.fetchTreatmentOrders(id),
        confirmOrderPacking.getDeliveryChangeNotes(id),
      ]);

      const ogOrder = orderDetails.treatmentOrder.Original_Order?.id
        ? await dispenseDrugsServices.fetchTreatmentOrder(
            orderDetails.treatmentOrder.Original_Order.id
          )
        : undefined;

      const {
        crmLink: prescriptionLink,
      } = await dispenseDrugsServices.fetchRecordCrmLink(
        orderDetails.prescription.id,
        "Prescriptions"
      );
      orderDetails.prescription.crmLink = prescriptionLink;
      orderDetails.treatmentOrder.originalOrder = ogOrder;
      orderDetails.treatmentOrder.deliveryChangeNotes = changeNotes;

      setDetails(orderDetails);
    } catch (error) {
      setFetchError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const submitDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await dispenseDrugsServices.approveDispensedOrder(
        details?.treatmentOrder?.id || ""
      );
      if (res.error) {
        throw new Error(res.message);
      }
      zohoServices.closePopup(true);
    } catch (error) {
      const err = error as AxiosError;
      notifications.notifyError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [details?.treatmentOrder?.id]);

  const requestChanges = useCallback(
    async (changes: string) => {
      try {
        setLoading(true);
        const res = await dispenseDrugsServices.requestChanges({
          requestedChanges: changes,
          treatmentOrderId: details?.treatmentOrder?.id || "",
        });
        if (res.error) {
          throw new Error(res.message);
        }
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [details?.treatmentOrder?.id]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async ({ setSubmitting }) => {
      await submitDetails();
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  const replacementText = useMemo(() => {
    if (!details?.treatmentOrder?.Replacement) {
      return "";
    }
    if (details.treatmentOrder.originalOrder?.State === "Returned") {
      return "This is a replacement order and the original order is returned but not disposed yet.";
    }
    if (details.treatmentOrder.originalOrder?.State === "Disposed") {
      return "This is a replacement order and the original order is returned and disposed.";
    }
    return "This is a replacement order and the original order is not returned yet.";
  }, [details]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validationContext={initialValues}
      enableReinitialize
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle title="Perform Final Check" />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}
          {view === "form" ? (
            <>
              {details?.treatmentOrder?.Replacement && (
                <Alert severity="info">{replacementText}</Alert>
              )}
              <PerformFinalChecksItems orderDetails={details} />
            </>
          ) : null}
        </TreatmentWidgetContent>
        <TreatmentWidgetActions containerClass={classes.actions}>
          <PerformFinalChecksActions
            onSubmit={submitDetails}
            view={view}
            requestChanges={requestChanges}
          />
        </TreatmentWidgetActions>
      </TreatmentWidgetLayout>
    </Formik>
  );
};
