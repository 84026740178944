import React from "react";
import { Box, Button } from "@material-ui/core";
import { useFormikContext } from "formik";

export const ActionButtons = ({ closeWidget }: { closeWidget: () => void }) => {
  const { isValid, submitForm, isSubmitting } = useFormikContext();

  return (
    <Box
      marginTop={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap="8px"
    >
      <Button
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        color="primary"
        variant="outlined"
        onClick={closeWidget}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        style={{
          textTransform: "capitalize",
          minWidth: "120px",
        }}
        variant="contained"
        onClick={submitForm}
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting ? "Creating..." : "Create"}
      </Button>
    </Box>
  );
};
