import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  ZohoTreatmentTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoProduct,
  AutoCompleteOption,
  ZohoMedUsageUnit,
} from "types";
import { getFieldName } from "helpers";
import { TreatmentMedicineAndProduct } from "./TreatmentMedicineAndProduct";

interface Props {
  name?: string;
  suggestedMedicines: ZohoTreatmentMedicine[];
  treatmentTreatmentAreasById: Record<string, ZohoTreatmentTreatmentArea>;
  bacteriostaticProducts: ZohoProduct[];
  selectedPharmacies: AutoCompleteOption[];
  initialSelectedPharmacies: AutoCompleteOption[];
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
}

export const TreatmentMedicinesAndProducts = ({
  name,
  suggestedMedicines,
  treatmentTreatmentAreasById,
  bacteriostaticProducts,
  selectedPharmacies,
  initialSelectedPharmacies,
  medUsageUnits,
}: Props) => {
  const fieldName = useMemo(() => {
    return getFieldName("treatmentMedicines", name);
  }, [name]);

  return (
    <Box mb={2}>
      {suggestedMedicines.map((suggestedMedicine, index) => (
        <Box
          key={suggestedMedicine.id}
          mb={index === suggestedMedicines.length - 1 ? 0 : 2}
        >
          <TreatmentMedicineAndProduct
            suggestedMedicine={suggestedMedicine}
            name={getFieldName(index, fieldName)}
            treatmentTreatmentArea={
              treatmentTreatmentAreasById[suggestedMedicine.Treatment_Area.id]
            }
            bacteriostaticProducts={bacteriostaticProducts}
            initialSelectedPharmacies={initialSelectedPharmacies}
            selectedPharmacies={selectedPharmacies}
            medUsageUnits={medUsageUnits}
          />
        </Box>
      ))}
    </Box>
  );
};
