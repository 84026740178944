import {
  DialogActions,
  DialogActionsProps,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { getParentElement } from "helpers";
import { useTreatmentWidgetLayout } from "../useTreatmentWidgetLayout";

const useStyles = makeStyles(({ palette: p }) => ({
  wrapper: {
    borderTop: `1px solid ${p.grey[200]}`,
  },
}));

interface Props extends DialogActionsProps {
  children: any;
  containerClass?: string;
}

export const TreatmentWidgetActions = ({
  children,
  containerClass = "",
  ...props
}: Props) => {
  const classes = useStyles();
  const { setActionsDimension } = useTreatmentWidgetLayout();

  const elementID = useMemo(() => {
    return String(Math.random());
  }, []);

  const handleMutation = useCallback(
    (entries: MutationRecord[]) => {
      const entry = entries[0];

      const element = getParentElement(
        (entry.target as unknown) as HTMLElement,
        elementID
      );

      if (element) {
        setActionsDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
      }
    },
    [elementID, setActionsDimension]
  );

  const observer = useMemo(() => {
    return new MutationObserver(handleMutation);
  }, [handleMutation]);

  const wrapperRef = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        setActionsDimension({
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
        observer.observe(element, {
          childList: true,
          attributes: true,
          subtree: true,
        });
      }
    },
    [observer, setActionsDimension]
  );

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return (
    <DialogActions {...props} className={classes.wrapper}>
      <div id={elementID} className={containerClass} ref={wrapperRef}>
        {children || null}
      </div>
    </DialogActions>
  );
};
