import { Box, Grid } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import React, { useMemo } from "react";
import { DBPharmacyProduct } from "types";
import {
  DispenseOrderPages,
  MissingState,
  ParentOrderItem,
  TreatmentOrder,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import { NumberInputField } from "components/Treatments";
import { OrderItemDispensedUnits } from "../OrderItemDispensedUnits";
import { HandleMissingItemsFields } from "./HandleMissingItemsFields";

interface ChildBacteriostaticItemProps {
  childItem: TreatmentOrderItem;
  parentItem?: ParentOrderItem;
  index: number;
  manufacturers: string[];
  name: string;
  handleMissingItems?: boolean;
  treatmentOrder?: TreatmentOrder;
  replacements?: DBPharmacyProduct[];
}

export const ChildBacteriostaticItem: React.FC<ChildBacteriostaticItemProps> = ({
  childItem,
  name,
  index,
  treatmentOrder,
  replacements,
  handleMissingItems,
  manufacturers,
}) => {
  const { values } = useFormikContext();
  const fieldName = useMemo(() => `${name}.children.${index}`, [name, index]);

  const missingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [fieldName, values]
  );

  const missingQuantity = useMemo(
    () => getValueFromObject(`${fieldName}.missingQuantity`, values),
    [fieldName, values]
  );

  const checkMissingQuantity = useMemo(() => {
    return [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes((values as any).currentPage);
  }, [values]);

  return (
    <>
      <Box mb={2}>
        <Grid spacing={3} container>
          <Grid item xs={4}>
            <TitleValue title="Name" value={childItem.Prescription_Name} />
          </Grid>
          <Grid item xs={2}>
            <TitleValue title="Quantity" value={childItem.Quantity} />
          </Grid>
          <Grid item xs={3}>
            <TitleValue
              title="Unit Volume (ml)"
              value={childItem.productItem.Usage_Unit_Volume_ml}
            />
          </Grid>
          {handleMissingItems && (
            <Grid item xs={3}>
              <Box ml="auto" maxWidth={180}>
                <NumberInputField
                  label="Missing Quantity"
                  name={`${fieldName}.missingQuantity`}
                  required={false}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        {(![MissingState.DISPENSE_PARTIALLY, MissingState.REPLACE].includes(
          missingState
        ) ||
          !missingQuantity) &&
          checkMissingQuantity && (
            <OrderItemDispensedUnits
              quantity={childItem.Quantity}
              fieldName={fieldName}
              item={childItem}
              manufacturers={manufacturers}
            />
          )}
        {!checkMissingQuantity && (
          <OrderItemDispensedUnits
            quantity={childItem.Quantity}
            fieldName={fieldName}
            item={childItem}
            manufacturers={manufacturers}
          />
        )}
      </Box>

      {handleMissingItems && (
        <HandleMissingItemsFields
          manufacturers={manufacturers}
          item={childItem}
          fieldName={fieldName}
          treatmentOrder={treatmentOrder}
          replacements={replacements!}
        />
      )}
    </>
  );
};
