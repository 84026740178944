import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Alert } from "@material-ui/lab";
import { BloodDrawer } from "@deep-consulting-solutions/bmh-constants";
import { ZohoVariables } from "../types";
import { ShowCRMVariable } from "./ShowCRMVariable";

const useStyles = makeStyles(({ spacing: s }) =>
  createStyles({
    alert: {
      fontWeight: 500,
      padding: s(2, 4),
      "& .MuiAlert-icon": {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "flex",
      },
    },
    underline: {
      textDecoration: "underline",
    },
    subList: {
      paddingLeft: s(4),
    },
  })
);

interface Props {
  labName?: string;
  bloodDrawers: BloodDrawer[];
  variables: Record<ZohoVariables, string>;
}

export const OfferingNurseInstructions = ({
  labName,
  bloodDrawers,
  variables,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Alert severity="info" icon={false} className={classes.alert}>
        <Typography>
          {labName ? (
            <>
              In the client&apos;s country and for the selected test profiles
              and for <b>{labName}</b> the following <b>Prepaid Partner</b>{" "}
              blood drawers are available. Offer to use a BMH Partner blood
              drawer and explain the benefits.
            </>
          ) : (
            <>
              In the client&apos;s country and for the selected test profiles
              the following <b>Prepaid Partner</b> blood drawers are available.
              Offer to use a BMH Partner blood drawer and explain the benefits.
            </>
          )}
        </Typography>

        <Box mt={3}>
          <Typography variant="h5">
            <b>Benefits of different blood drawers</b>
          </Typography>
        </Box>

        <Box mt={3} className={classes.subList}>
          <Typography className={classes.underline}>
            <b>Prepaid Partner blood drawers:</b>
          </Typography>
          <ShowCRMVariable
            longText={variables[ZohoVariables.BD_General]}
            longText2={variables[ZohoVariables.BD_Prepaid_Yes]}
          />

          {bloodDrawers.some((bloodDrawer) => bloodDrawer.providesOwnKit) ? (
            <Box mt={2}>
              <Typography className={classes.underline}>
                <b>Benefits of blood drawers that provides their own kits:</b>
              </Typography>
              <ShowCRMVariable
                longText={variables[ZohoVariables.BD_Provides_Own_Kits_Yes]}
              />
            </Box>
          ) : null}

          {bloodDrawers.some((bloodDrawer) => !bloodDrawer.providesOwnKit) ? (
            <Box mt={2}>
              <Typography className={classes.underline}>
                <b>
                  Benefits of blood drawers that doesn&apos;t provide their own
                  kits:
                </b>
              </Typography>
              <ShowCRMVariable
                longText={variables[ZohoVariables.BD_Provides_Own_Kits_No]}
              />
            </Box>
          ) : null}

          {bloodDrawers.some((bloodDrawer) => bloodDrawer.takesSampleToLab) ? (
            <Box mt={2}>
              <Typography className={classes.underline}>
                <b>
                  Benefits of blood drawers that takes the sample to the lab:
                </b>
              </Typography>
              <ShowCRMVariable
                longText={
                  variables[ZohoVariables.BD_Takes_Sample_to_the_Lab_Yes]
                }
              />
            </Box>
          ) : null}

          {bloodDrawers.some((bloodDrawer) => !bloodDrawer.takesSampleToLab) ? (
            <Box mt={2}>
              <Typography className={classes.underline}>
                <b>
                  Benefits of blood drawers that doesn&apos;t take the sample to
                  the lab:
                </b>
              </Typography>
              <ShowCRMVariable
                longText={
                  variables[ZohoVariables.BD_Takes_Sample_to_the_Lab_No]
                }
              />
            </Box>
          ) : null}
        </Box>
      </Alert>
    </>
  );
};
