import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import React from "react";

interface Props {
  selfSupplyFailed: boolean;
  isMultipleFailed: boolean;
}

export const BillingCycleValidationInfo = ({
  selfSupplyFailed,
  isMultipleFailed,
}: Props) => {
  if (!selfSupplyFailed && !isMultipleFailed) {
    return null;
  }
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-start"
      mb={2}
      ml={2}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 999,
      }}
      width="100%"
      maxWidth={700}
    >
      <Alert severity="error">
        {isMultipleFailed ? (
          <span
            style={{
              display: "block",
            }}
          >
            The billing cycle of all selected products must be equal or
            multiples of each other.
          </span>
        ) : null}
        {selfSupplyFailed ? (
          <span
            style={{
              display: "block",
            }}
          >
            All self-supplied products must have the same billing cycle.
          </span>
        ) : null}
        Please change the billing cycle and/or quantity in those products to
        achieve this.
      </Alert>
    </Box>
  );
};
