import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomRadioGroupField } from "components/CustomRadioGroupField";
import { useFormikContext } from "formik";
import { InitialValues } from "../types";

export const StepThree = () => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <>
      {values.shouldSendDoctorInvite ? (
        <Box minHeight="430px" marginBottom={3}>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Please select the Doctor you want the client to book an appointment
            with
          </Typography>
          <Box>
            <CustomRadioGroupField name="doctor" options={values.doctors} />
          </Box>
        </Box>
      ) : (
        <Box minHeight="200px" marginBottom={2}>
          <Box
            maxWidth="680px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            margin="100px auto"
            gridRowGap="16px"
          >
            <Typography
              variant="body1"
              color="primary"
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              A new invoice for the repeat blood test will be automatically
              generated and sent to client to collect payment.
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Client will be sent an email to make payments for a new blood
              test.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
