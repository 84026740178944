import { Box } from "@material-ui/core";
import { AutocompleteMultipleFreeSoloField } from "components/AutocompleteMultipleFreeSoloField";

import { getFieldName, isStringEqual } from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption, ZohoTreatmentArea } from "types";

interface Props {
  treatmentAreas: ZohoTreatmentArea[];
  name?: string;
}
export const TreatmentTreatmentAreasField = ({
  treatmentAreas,
  name,
}: Props) => {
  const fieldName = useMemo(
    () => getFieldName("treatmentTreatmentAreas", name),
    [name]
  );

  const { options, otherOption } = useMemo(() => {
    return treatmentAreas.reduce<{
      options: AutoCompleteOption[];
      otherOption: AutoCompleteOption;
    }>(
      (total, treatmentArea) => {
        if (isStringEqual(treatmentArea.Name, "other")) {
          return {
            ...total,
            otherOption: {
              title: treatmentArea.Name,
              value: treatmentArea.id,
            },
          };
        }
        return {
          ...total,
          options: total.options.concat({
            title: treatmentArea.Name,
            value: treatmentArea.id,
          }),
        };
      },
      {
        options: [],
        otherOption: { title: "", value: "" },
      }
    );
  }, [treatmentAreas]);

  const mapValues = useCallback((value: AutoCompleteOption[]) => {
    return value.map((v) => {
      return {
        ...v,
        medicinesSelected: (v as any).medicinesSelected || [],
      };
    });
  }, []);

  return (
    <Box mb={2}>
      <AutocompleteMultipleFreeSoloField
        name={fieldName}
        label="Target Treatment Area"
        otherOptionValue={otherOption.value}
        mapValues={mapValues}
        options={options}
      />
    </Box>
  );
};
